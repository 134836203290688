import React, { useEffect, useState, useContext } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Button, Grid, useTheme, useMediaQuery } from '@material-ui/core'

import { useDispatch } from 'react-redux'

import { useForm } from 'react-hook-form'

import { InformationIcon } from '../../../../assets/Icons/Icons'
import { dashboardRoute, auth0Settings } from '../../../../utils/constants'

import { setTabCurrentRoute, setTabTitle } from '../../../../redux/actions/MultiTabs.actions'
import { clearQuoteDetailsForReQuote } from '../../../../redux/actions/QuoteDetails.actions'
import { fetchUserById, editUser, updateUserPhoto } from '../../../../redux/actions/Users.actions'
import { reqResetPassword } from '../../../../redux/requests/Users.requests'

import SessionContext from '../../../../context/session'

import sweetAlertModal from '../../../../components/SweetAlertModal'
import BasicInformation from '../../../users/newUser/components/BasicInformation'
import NotificationSettingsTable from '../../../users/newUser/components/NotificationSettingsTable'
import NotificationSettingsCard from '../../../users/newUser/components/NotificationSettingsCard'

import useStyles from './styles'

const MyProfileContainer = () => {
	const classes = useStyles()
	const theme = useTheme()
	const matches = useMediaQuery(theme.breakpoints.down('md'))
	const { handleSubmit, register, errors, control, setValue, getValues } = useForm()
	const dispatch = useDispatch()
	const location = useLocation()
	const history = useHistory()
	const { id } = useParams()
	const isEdit = !!id

	const { getUserData, getTokenUser, oktaAuth } = useContext(SessionContext)
	const { actualCompany } = getUserData()
	const token = getTokenUser()
	const config = {
		headers: {
			'X-Company-id': Number(actualCompany),
			Authorization: `Bearer ${token}`,
		},
	}

	const [userPhoto, setUserPhoto] = useState([])
	const [phoneNumber, setPhoneNumber] = useState('')
	const [email, setEmail] = useState('')
	const [childrenCompanies, setChildrenCompanies] = useState([])
	const [childrenCompaniesError, setChildrenCompaniesError] = useState(false)
	const [editUserChildrenCompanies, setEditUserChildrenCompanies] = useState([])
	const [sendBolByEmail, setSendBolByEmail] = useState(false)
	const [sendByEmail, setSendByEmail] = useState(false)
	const [sendBySms, setSendBySms] = useState(false)
	const [sendWhenBooked, setSendWhenBooked] = useState(false)
	const [sendWhenInTransit, setSendWhenInTransit] = useState(false)
	const [sendWhenDelivered, setSendWhenDelivered] = useState(false)
	const [errorPhoneNumber, setErrorPhoneNumber] = useState('')
	const [lastName, setLastName] = useState('')
	const [firstName, setFirstName] = useState('')

	useEffect(() => {
		const splittedPath = location.pathname.split('/')
		const currentRoute = `/${splittedPath[1]}/${id}`
		dispatch(setTabCurrentRoute(currentRoute))
		dispatch(setTabTitle('Profile'))
		dispatch(clearQuoteDetailsForReQuote(''))
	}, [dispatch, id, location])

	useEffect(() => {
		const fetchData = async () => {
			const { status, resp } = await dispatch(fetchUserById(id))
			if (status === 'error') {
				sweetAlertModal(status, 'There was an error fetching the user information!', null, 'Ok', true, false, null)
			} else {
				const userFirstName = resp.user.name.split(' ').slice(0, -1).join(' ')
				const userLastName = resp.user.name.split(' ').slice(-1).join(' ')
				setValue('firstName', userFirstName)
				setValue('lastName', userLastName)
				setEmail(resp.user.email)
				setValue('email', resp.user.email)
				setPhoneNumber(resp.user.phone_number ? resp.user.phone_number : '')
				setValue('phone_number', resp.user.phone_number ? resp.user.phone_number : '')
				setEditUserChildrenCompanies(resp.user.children_companies)
				setSendBolByEmail(resp.user.notification_send_bol ? resp.user.notification_send_bol : false)
				setSendByEmail(resp.user.notification_by_email ? resp.user.notification_by_email : false)
				setSendBySms(resp.user.notification_by_sms ? resp.user.notification_by_sms : false)
				setSendWhenBooked(resp.user.notification_booked ? resp.user.notification_booked : false)
				setSendWhenInTransit(resp.user.notification_intransit ? resp.user.notification_intransit : false)
				setSendWhenDelivered(resp.user.notification_delivered ? resp.user.notification_delivered : false)
				if (resp.user.photo) {
					try {
						const response = await fetch(resp.user.photo, {
							method: 'GET',
							cache: 'no-cache',
						})
						const data = await response.blob()
						const photo = new File([data], 'photo.jpg', {
							type: response.headers.get('content-type') || 'image/jpeg',
						})
						setUserPhoto([photo])
					} catch {
						sweetAlertModal('error', 'There was an error fetching the image!', null, 'Ok', true, false, null)
					}
				}
			}
		}
		if (isEdit && id) fetchData()
	}, [location, dispatch, setValue, isEdit, id])

	const handleSaveNewUser = async (data) => {
		const localDataPhoneNumber = data?.phone_number ?? ''
		if (
			!localDataPhoneNumber.match(/^([(]([0-9]{3})[)] ([0-9]{3})(-)([0-9]{4}))$/) &&
			localDataPhoneNumber.length > 0
		) {
			setErrorPhoneNumber('Please enter a valid phone number')
			return
		}
		const formData = new FormData()
		const childrenCompanyIds = childrenCompanies.map((company) => company.id)
		if (userPhoto.length) {
			formData.append('file', userPhoto[0])
		}
		formData.append('user[name]', `${data.firstName} ${data.lastName}`)
		formData.append('user[email]', data.email)
		formData.append('user[phone_number]', data.phone_number)
		childrenCompanyIds.forEach((childCompany, index) => {
			formData.append(`user[children_companies][${index}]`, childCompany)
		})
		formData.append('user[notification_send_bol]', sendBolByEmail)
		formData.append('user[notification_by_email]', sendByEmail)
		formData.append('user[notification_by_sms]', sendBySms)
		formData.append('user[notification_booked]', sendWhenBooked)
		formData.append('user[notification_intransit]', sendWhenInTransit)
		formData.append('user[notification_delivered]', sendWhenDelivered)
		const { status, resp } = await dispatch(editUser(id, formData, config))
		if (status === 'error') {
			sweetAlertModal(status, 'There was an error in the update profile process!', resp, 'Ok', true, false, null)
		} else {
			sweetAlertModal(status, 'Profile has been updated successfully!', null, 'Ok', true, false, null)
			if (userPhoto[0]) dispatch(updateUserPhoto(URL.createObjectURL(userPhoto[0])))
		}
	}

	const handleCancelEditProfile = () => {
		sweetAlertModal(
			'warning',
			'Are you sure you want to cancel the edit profile process?',
			null,
			'Yes',
			true,
			true,
			'No',
		).then((result) => {
			if (result.isConfirmed) {
				history.push(dashboardRoute)
			}
		})
	}

	const handleResetPassword = async () => {
		const isOktaAuth = await oktaAuth.isAuthenticated()

		if (isOktaAuth) {
			const oktaUser = await oktaAuth.getUser()
			const [error, data] = await oktaAuth
				.forgotPassword({ username: oktaUser.email, factorType: 'email' })
				.then((response) => [null, response])
				.catch((reqErr) => [reqErr, null])

			if (error) {
				sweetAlertModal('error', 'There was an error in the reset password process!', null, 'Ok', true, false, null)
			} else if (data) {
				sweetAlertModal(
					'success',
					'Please check your email to continue to password reset process.',
					null,
					'Ok',
					true,
					false,
					null,
				)
			}
		} else {
			const params = {
				email,
				connection: auth0Settings.databasePasswordAuthentication,
			}
			const [error, data] = await reqResetPassword(params)
			if (error) {
				sweetAlertModal('error', 'There was an error in the reset password process!', null, 'Ok', true, false, null)
			} else {
				sweetAlertModal('success', data.data, null, 'Ok', true, false, null)
			}
		}
	}

	useEffect(() => {
		if (phoneNumber.length === 0) {
			setErrorPhoneNumber('')
		}
	}, [phoneNumber])

	return (
		<form
			className="new-user-form"
			onSubmit={handleSubmit(handleSaveNewUser)}
			onChange={() => {
				const values = getValues()
				setFirstName(values?.firstName ?? '')
				setLastName(values?.lastName ?? '')
			}}
		>
			<div className={classes.newUserHeader}>
				<h1 className={classes.newUserTitle}>Profile</h1>
				<p className={classes.newUserSubtitle}>
					Review and update your personal information and notifications settings.
				</p>
			</div>
			<div>
				<div className={classes.sectionContainer}>
					<BasicInformation
						register={register}
						errors={errors}
						control={control}
						setValue={setValue}
						userPhoto={userPhoto}
						setUserPhoto={setUserPhoto}
						phoneNumber={phoneNumber}
						setPhoneNumber={setPhoneNumber}
						email={email}
						setEmail={setEmail}
						childrenCompanies={childrenCompanies}
						setChildrenCompanies={setChildrenCompanies}
						childrenCompaniesError={childrenCompaniesError}
						setChildrenCompaniesError={setChildrenCompaniesError}
						isEdit={isEdit}
						editUserChildrenCompanies={editUserChildrenCompanies}
						errorPhoneNumber={errorPhoneNumber}
						isEditProfile
					/>
				</div>
				<div className={classes.notificationsContainer}>
					<div className={classes.notificationsIconTitle}>
						<div className={classes.notificationsIconContainer}>
							<InformationIcon className={classes.notificationsIcon} />
						</div>
						<h3 className={classes.notificationsSectionTitle}>Notification Settings</h3>
					</div>
					{matches ? (
						<NotificationSettingsCard
							firstName={firstName}
							lastName={lastName}
							phoneNumber={phoneNumber}
							email={email}
							sendBolByEmail={sendBolByEmail}
							setSendBolByEmail={setSendBolByEmail}
							sendByEmail={sendByEmail}
							setSendByEmail={setSendByEmail}
							sendBySms={sendBySms}
							setSendBySms={setSendBySms}
							sendWhenBooked={sendWhenBooked}
							setSendWhenBooked={setSendWhenBooked}
							sendWhenInTransit={sendWhenInTransit}
							setSendWhenInTransit={setSendWhenInTransit}
							sendWhenDelivered={sendWhenDelivered}
							setSendWhenDelivered={setSendWhenDelivered}
							isEditProfile
						/>
					) : (
						<NotificationSettingsTable
							firstName={firstName}
							lastName={lastName}
							phoneNumber={phoneNumber}
							email={email}
							sendBolByEmail={sendBolByEmail}
							setSendBolByEmail={setSendBolByEmail}
							sendByEmail={sendByEmail}
							setSendByEmail={setSendByEmail}
							sendBySms={sendBySms}
							setSendBySms={setSendBySms}
							sendWhenBooked={sendWhenBooked}
							setSendWhenBooked={setSendWhenBooked}
							sendWhenInTransit={sendWhenInTransit}
							setSendWhenInTransit={setSendWhenInTransit}
							sendWhenDelivered={sendWhenDelivered}
							setSendWhenDelivered={setSendWhenDelivered}
							isEditProfile
						/>
					)}
				</div>
				<Grid container className={classes.buttonsRow}>
					<Button className={classes.cancelQuoteButton} onClick={handleCancelEditProfile}>
						Cancel
					</Button>
					<Button
						className={classes.cancelQuoteButton}
						style={{ color: theme.palette.secondary.main }}
						onClick={handleResetPassword}
					>
						Reset password
					</Button>
					<Button
						className={classes.button}
						variant="contained"
						color="secondary"
						type="submit"
						onMouseDown={() => {
							setErrorPhoneNumber('')
						}}
					>
						Save changes
					</Button>
				</Grid>
			</div>
		</form>
	)
}

export default MyProfileContainer
