import { axiosQuotes } from '../../utils/apiClient'

export const reqBookShipment = async (params, headers) => {
	const [error, data] = await axiosQuotes
		.post('/shipment', params, headers)
		.then((response) => [null, response])
		.catch((err) => [err, err?.err?.message])

	return [error, data]
}

export const reqShipmentList = async (params) => {
	const [error, data] = await axiosQuotes
		.get(`/shipment/list${params}`)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export const reqSendEmailForBookingConfirmation = async (shipmentId, emails) => {
	const [error, data] = await axiosQuotes
		.post(`/shipment/email/${shipmentId}/booked`, emails)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export const reqShipmentId = async (shipmentId) => {
	const [error, data] = await axiosQuotes
		.get(`/shipment/${shipmentId}`)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export const reqCloneShipment = async (shipmentId) => {
	const [error, data] = await axiosQuotes
		.post(`/shipment/${shipmentId}/clone`)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export const reqRemoveShipment = async (shipmentId) => {
	const [error, data] = await axiosQuotes
		.delete(`/shipment/${shipmentId}`)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export const reqUploadDocument = async (shipmentId, payload) => {
	const [error, data] = await axiosQuotes
		.put(`/shipment/${shipmentId}/documents`, payload)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export const reqDownloadDocumentBlob = async (shipmentId) => {
	const [error, data] = await axiosQuotes
		.get(`/shipment/bol/${shipmentId}?typeResponse=inline`, { responseType: 'blob' })
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export const reqDownloadDocumentLabel = async (shipmentId) => {
	const [error, data] = await axiosQuotes
		.get(`/shipment/labels/${shipmentId}?typeResponse=inline`, { responseType: 'blob' })
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export const reqDownloadShipmentDocument = async (shipmentId, params) => {
	const [error, data] = await axiosQuotes
		.get(`/shipment/${shipmentId}/document?responseType=inline&${params}`, { responseType: 'blob' })
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export const reqAddNotification = async (shipmentId, params) => {
	const [error, data] = await axiosQuotes
		.post(`/shipment/${shipmentId}/notification`, params)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export const reqRemoveNotification = async (shipmentId, notificationId) => {
	const [error, data] = await axiosQuotes
		.delete(`/shipment/${shipmentId}/notification/${notificationId}`)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export const reqVerifyRequiredReferences = async (params) => {
	try {
		const response = await axiosQuotes.post('/shipment/verify-references', params)
		return [null, response.data]
	} catch (error) {
		return [error, error]
	}
}

export const reqSendByMailDocuments = async (body, contentType = 'application/json') => {
	const [error, data] = await axiosQuotes
		.post('/shipment/send-file-by-email', body, {
			headers: {
				'Content-Type': contentType,
			},
		})
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export const reqImportShipmentToMG = async (shipmentId) => {
	const [error, data] = await axiosQuotes
		.post(`/shipment/import/${shipmentId}`)
		.then((response) => [null, response])
		.catch((err) => [err, err.message])

	return [error, data]
}

export default reqBookShipment
