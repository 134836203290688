import React from 'react'
import PropTypes from 'prop-types'
import { FormControlLabel, Checkbox } from '@material-ui/core'
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined'
import { useSelector } from 'react-redux'
import { setFPAdminCheckboxesValues } from '../../../utils/helpers'

import useStyles from '../styles'

const FPModule = (props) => {
	const { fPAdminModule, setFPAdminModule, setCheckboxesValues, checkboxesValues, onChangeCheckboxValue, userRole } =
		props
	const classes = useStyles()
	const userProfile = useSelector((state) => state.profile.profile)
	const disabledCheck = userRole !== 'FREIGHT_PROS_ADMIN' || userProfile?.role !== 'FREIGHT_PROS_ADMIN'
	return (
		<div>
			<FormControlLabel
				className={classes.checkBoxLabel}
				disabled={disabledCheck}
				control={
					<Checkbox
						indeterminate={fPAdminModule}
						indeterminateIcon={<IndeterminateCheckBoxOutlinedIcon />}
						color="secondary"
						className={classes.checkBox}
						checked={fPAdminModule}
						onChange={(event) => {
							const { checked } = event.target
							setFPAdminModule(checked)
							setCheckboxesValues((prevState) => ({
								...prevState,
								...setFPAdminCheckboxesValues({ checked }),
							}))
						}}
					/>
				}
				label="FreightPros Admin Module"
			/>
			<div className={classes.checkBoxesColumn}>
				<FormControlLabel
					className={classes.checkBoxLabel}
					disabled={disabledCheck}
					control={
						<Checkbox
							color="secondary"
							className={classes.checkBox}
							checked={checkboxesValues.createAccounts}
							onChange={(event) => onChangeCheckboxValue(event, 'createAccounts')}
						/>
					}
					label="Create new accounts"
				/>
				<FormControlLabel
					className={classes.checkBoxLabel}
					disabled={disabledCheck}
					control={
						<Checkbox
							color="secondary"
							className={classes.checkBox}
							checked={checkboxesValues.editAccounts}
							onChange={(event) => onChangeCheckboxValue(event, 'editAccounts')}
						/>
					}
					label="Edit accounts"
				/>
				<FormControlLabel
					className={classes.checkBoxLabel}
					disabled={disabledCheck}
					control={
						<Checkbox
							color="secondary"
							className={classes.checkBox}
							checked={checkboxesValues.deactivateAccounts}
							onChange={(event) => onChangeCheckboxValue(event, 'deactivateAccounts')}
						/>
					}
					label="Activate/Deactivate account"
				/>
				<FormControlLabel
					className={classes.checkBoxLabel}
					disabled={disabledCheck}
					control={
						<Checkbox
							color="secondary"
							className={classes.checkBox}
							checked={checkboxesValues.createUsers}
							onChange={(event) => onChangeCheckboxValue(event, 'createUsers')}
						/>
					}
					label="Create new users"
				/>
				<FormControlLabel
					className={classes.checkBoxLabel}
					disabled={disabledCheck}
					control={
						<Checkbox
							color="secondary"
							className={classes.checkBox}
							checked={checkboxesValues.editUsers}
							onChange={(event) => onChangeCheckboxValue(event, 'editUsers')}
						/>
					}
					label="Edit users"
				/>
				<FormControlLabel
					className={classes.checkBoxLabel}
					disabled={disabledCheck}
					control={
						<Checkbox
							color="secondary"
							className={classes.checkBox}
							checked={checkboxesValues.deactivateUsers}
							onChange={(event) => onChangeCheckboxValue(event, 'deactivateUsers')}
						/>
					}
					label="Activate/Deactivate users"
				/>
				<FormControlLabel
					className={classes.checkBoxLabel}
					disabled={disabledCheck}
					control={
						<Checkbox
							color="secondary"
							className={classes.checkBox}
							checked={checkboxesValues.createCarriers}
							onChange={(event) => onChangeCheckboxValue(event, 'createCarriers')}
						/>
					}
					label="Create new carriers"
				/>
				<FormControlLabel
					className={classes.checkBoxLabel}
					disabled={disabledCheck}
					control={
						<Checkbox
							color="secondary"
							className={classes.checkBox}
							checked={checkboxesValues.editCarriers}
							onChange={(event) => onChangeCheckboxValue(event, 'editCarriers')}
						/>
					}
					label="Edit carriers"
				/>
				<FormControlLabel
					className={classes.checkBoxLabel}
					disabled={disabledCheck}
					control={
						<Checkbox
							color="secondary"
							className={classes.checkBox}
							checked={checkboxesValues.deactivateCarriers}
							onChange={(event) => onChangeCheckboxValue(event, 'deactivateCarriers')}
						/>
					}
					label="Activate/Deactivate carriers"
				/>
				<FormControlLabel
					className={classes.checkBoxLabel}
					disabled={disabledCheck}
					control={
						<Checkbox
							color="secondary"
							className={classes.checkBox}
							checked={checkboxesValues.showCarriersRate}
							onChange={(event) => onChangeCheckboxValue(event, 'showCarriersRate')}
						/>
					}
					label="Show carriers rate"
				/>
			</div>
		</div>
	)
}

FPModule.propTypes = {
	fPAdminModule: PropTypes.bool.isRequired,
	setFPAdminModule: PropTypes.func.isRequired,
	setCheckboxesValues: PropTypes.func.isRequired,
	checkboxesValues: PropTypes.objectOf(PropTypes.any).isRequired,
	onChangeCheckboxValue: PropTypes.func.isRequired,
	userRole: PropTypes.func.isRequired,
}

export default FPModule
