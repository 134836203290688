import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	paper: {
		borderBottomWidth: 6,
		borderBottomStyle: 'solid',
		borderBottomColor: theme.palette.secondary.main,
	},
	dialogTitle: {
		padding: theme.spacing(3, 2),
		textAlign: 'center',
		fontSize: 22,
		fontWeight: theme.typography.fontWeightBold,
		color: theme.palette.secondary.main,
		lineHeight: 1,
		[theme.breakpoints.up('sm')]: {
			padding: theme.spacing(3, 4, 2),
			fontSize: 34,
		},
	},
	dialogContent: {
		padding: theme.spacing(0, 2, 2),
		[theme.breakpoints.up('sm')]: {
			padding: theme.spacing(0, 4, 3),
		},
	},
	dialogActions: {
		display: 'block',
		padding: theme.spacing(0, 2, 2),
		[theme.breakpoints.up('sm')]: {
			padding: theme.spacing(0, 4, '15px'),
		},
	},
	dialogButton: {
		display: 'block',
		padding: '13px 16px',
		width: '100%',
		marginBottom: theme.spacing(2),
		fontSize: 14,
		textTransform: 'none',
		fontWeight: theme.typography.fontWeightMedium,
		'&:last-of-type': {
			marginBottom: 0,
		},
		[theme.breakpoints.up('sm')]: {
			fontSize: 17,
		},
	},
	inputMessage: {
		textAlign: 'justify',
	},
	messageContainer: {
		marginTop: theme.spacing(4),
	},
}))

export default useStyles
