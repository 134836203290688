import React from 'react'
import PropTypes from 'prop-types'

import { TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, useTheme } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'

import { capitalizeFirstLetter } from '../../../../utils/helpers'

import { HazIcon, StackIcon } from '../../../../assets/Icons/Icons'

import { displayDetails } from '../../utils/helpers'

import useStyles, { CustomizedTooltip } from './styles'

const ShipmentItems = ({ items }) => {
	const classes = useStyles()
	const theme = useTheme()

	return (
		<div className="new-shipment-items-container">
			<TableContainer>
				<Table size="small" aria-label="purchases">
					<TableHead>
						<TableRow className={classes.root}>
							<TableCell className={classes.tableCellHead}>#</TableCell>
							<TableCell className={classes.tableCellHead}>Package type</TableCell>
							<TableCell className={classes.tableCellHead}>Quantity</TableCell>
							<TableCell className={classes.tableCellHead}>FreightClass</TableCell>
							<TableCell className={classes.tableCellHead}>NMFC code</TableCell>
							<TableCell className={classes.tableCellHead}>Weight</TableCell>
							<TableCell className={classes.tableCellHead}>Dimensions</TableCell>
							<TableCell className={classes.tableCellHead}>Product Description</TableCell>
							<TableCell className={classes.tableCellHead}>Details</TableCell>
							<TableCell className={classes.tableCellHead} />
						</TableRow>
					</TableHead>
					<TableBody>
						{items.map((item, index) => (
							<TableRow key={item.id}>
								<TableCell style={{ borderBottom: 'none', fontWeight: 700 }}>
									{String.fromCharCode(65 + index)}
								</TableCell>
								<TableCell style={{ borderBottom: 'none' }}>
									{capitalizeFirstLetter(item?.package_type ?? item?.unit_type ?? 'N/A')}
								</TableCell>
								<TableCell style={{ borderBottom: 'none' }}>{item.pieces_value}</TableCell>
								<TableCell style={{ borderBottom: 'none' }}>{item.class}</TableCell>
								<TableCell style={{ borderBottom: 'none' }}>
									{item.nmfc ? item.nmfc : '-'}
									{item.sub_nmfc ? `-${item.sub_nmfc}` : ''}
								</TableCell>
								<TableCell style={{ borderBottom: 'none' }}>{`${item.weight_value} lbs`}</TableCell>
								<TableCell style={{ borderBottom: 'none' }}>
									{`${item.dimensions_lenght}'' x ${item.dimensions_width}'' x ${item.dimensions_height}''`}
								</TableCell>
								<TableCell style={{ borderBottom: 'none' }}>{item.description}</TableCell>
								<TableCell style={{ borderBottom: 'none' }} className={classes.newShipmentItems}>
									<span className={classes.newShipmentItemsUnderlined}>
										{item.item_details && item.item_details.length > 0 && displayDetails(item.item_details)}
									</span>
									{item.item_details && item.item_details.length > 0 ? (
										<CustomizedTooltip title={displayDetails(item.item_details)}>
											<VisibilityIcon color="secondary" />
										</CustomizedTooltip>
									) : null}
								</TableCell>
								<TableCell style={{ borderBottom: 'none' }}>
									<div className="stepTwo-shipment-items-icons-container">
										<Tooltip title="Hazmat such as chemicals, acid, waste with a UN number">
											<span>
												<HazIcon
													htmlColor={item.is_haz_mat ? theme.palette.secondary.main : theme.palette.neutral.darkGray}
													className={classes.icon}
												/>
											</span>
										</Tooltip>
										<Tooltip title="Hazmat such as chemicals, acid, waste with a UN number">
											<span>
												<StackIcon
													htmlColor={item.stackable ? theme.palette.secondary.main : theme.palette.neutral.darkGray}
													className={classes.icon}
												/>
											</span>
										</Tooltip>
									</div>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	)
}

ShipmentItems.propTypes = {
	items: PropTypes.arrayOf(PropTypes.any).isRequired,
}

export default ShipmentItems
