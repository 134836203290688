import React from 'react'
import PropTypes from 'prop-types'

import Carousel from 'react-multi-carousel'

import { HazIcon, StackIcon } from '../../../../assets/Icons/Icons'

import { capitalizeFirstLetter } from '../../../../utils/helpers'

import { displayDetails } from '../../utils/helpers'

import 'react-multi-carousel/lib/styles.css'
import './ShipmentItemsSlider.scss'

const ShipmentItemsSlider = (props) => {
	const { items } = props

	const responsive = {
		tablet: {
			breakpoint: { max: 1024, min: 500 },
			items: 1,
			paritialVisibilityGutter: 50,
		},
		mobile: {
			breakpoint: { max: 500, min: 0 },
			items: 1,
			paritialVisibilityGutter: 30,
		},
	}

	return (
		<div className="shipment-items-slider">
			<Carousel
				ssr
				arrows={false}
				responsive={responsive}
				showDots
				draggable
				renderDotsOutside
				dotListClass="shipment-items-slider-dots"
			>
				{items.map((item, index) => (
					<div key={`id-${index + 1}`} className="shipment-items-slider-container">
						<div>
							<p className="shipment-items-slider-title" id="shipment-items-slider-title-id">
								<span>{`#${String.fromCharCode(65 + index)}`}</span>
							</p>
							<p className="shipment-items-slider-title">
								<span>Package type:</span>
								{` ${capitalizeFirstLetter(item?.package_type ?? item?.unit_type ?? 'N/A')}`}
							</p>
							<p className="shipment-items-slider-title">
								<span>Quantity:</span>
								{` ${item.pieces_value}`}
							</p>
							<p className="shipment-items-slider-title">
								<span>FreightClass:</span>
								{` ${item.class}`}
							</p>
							<p className="shipment-items-slider-title">
								<span>NMFC code:</span>
								{item.nmfc ? item.nmfc : '-'}
								{item.sub_nmfc ? `- ${item.sub_nmfc}` : ''}
							</p>
							<p className="shipment-items-slider-title">
								<span>Dimensions:</span>
								{` ${item.dimensions_lenght}'' x ${item.dimensions_width}'' x ${item.dimensions_height}''`}
							</p>
							<p className="shipment-items-slider-title">
								<span>Product Description:</span>
								{` ${item.description}`}
							</p>
							<p className="shipment-items-slider-title">
								<span>Details:</span>
								{item.item_details && item.item_details.length > 0 && ` ${displayDetails(item.item_details)}`}
							</p>
							<div className="stepTwo-shipment-items-icons-container" id="shipment-items-slider-icons">
								{item.is_haz_mat ? <HazIcon color="primary" /> : <HazIcon htmlColor="#CCD2D8" fontSize="32px" />}
								{item.stackable ? <StackIcon color="primary" /> : <StackIcon htmlColor="#CCD2D8" fontSize="24px" />}
							</div>
						</div>
					</div>
				))}
			</Carousel>
		</div>
	)
}

ShipmentItemsSlider.propTypes = {
	items: PropTypes.arrayOf(PropTypes.any).isRequired,
}

export default ShipmentItemsSlider
