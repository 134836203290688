import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { TableCell, TableHead, TableRow, Checkbox } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { AscendingIcon, DescendingIcon } from '../../assets/Icons/Icons'

import { savedQuotesCells, cubicCalculatorCells, locationCells, templateItemCells } from '../../utils/headCells'
import { setOrderByAndOrder } from '../../redux/actions/QuotesFilters.actions'
import useStyles from './styles'

const TableHeader = (props) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const theme = useTheme()
	const { onSelectAllClick, numSelected, rowCount, tableType, currentSort, setCurrentSort } = props

	const displayTableCheckBox = () => {
		if (tableType === 'savedQuotes') {
			return (
				<TableCell padding="checkbox" className={classes.tableCell}>
					<Checkbox
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{ 'aria-label': 'select all quotes' }}
						style={{
							color:
								(rowCount > 0 && numSelected === rowCount) || (numSelected > 0 && numSelected < rowCount)
									? theme.palette.secondary.main
									: theme.palette.primary.light,
						}}
					/>
				</TableCell>
			)
		}
		if (tableType === 'savedLocations') {
			return (
				<TableCell size="small" className={`${classes.tableCell} ${classes.tableCellModal}`}>
					<span />
				</TableCell>
			)
		}
		if (tableType === 'savedTemplateItems') {
			return (
				<TableCell size="small" className={`${classes.tableCell} ${classes.tableCellModal}`}>
					<span />
				</TableCell>
			)
		}
		return null
	}

	const handleCellClick = (cellClicked) => {
		const filterableFields = [
			'# QUOTE',
			'ORIGIN',
			'DESTINATION',
			'COMPANY',
			'CARRIER',
			'CUSTOMER RATE',
			'QUOTE DATE',
			'STATUS',
		]
		if (!filterableFields.includes(cellClicked)) return

		let newCurrentSort = {}
		if (currentSort.field === cellClicked) {
			newCurrentSort.field = cellClicked
			newCurrentSort.orderAsc = !currentSort.orderAsc
			setCurrentSort(newCurrentSort)
		} else {
			newCurrentSort = { field: cellClicked, orderAsc: true }
			setCurrentSort(newCurrentSort)
		}
		let orderBy = ''
		if (newCurrentSort.field === '# QUOTE') {
			orderBy = 'id'
		} else if (newCurrentSort.field === 'CUSTOMER RATE') {
			orderBy = 'rate'
		} else if (newCurrentSort.field === 'QUOTE DATE') {
			orderBy = 'date'
		} else {
			orderBy = newCurrentSort?.field?.toLowerCase()
		}
		const order = newCurrentSort.orderAsc ? 'asc' : 'desc'
		dispatch(setOrderByAndOrder({ orderBy, order }))
	}

	const displayTableCells = () => {
		let cells = []
		if (tableType === 'savedQuotes') {
			cells = savedQuotesCells.map((headCell) => (
				<TableCell
					key={headCell.id}
					className={classes.tableCell}
					onClick={() => {
						handleCellClick(headCell.label)
					}}
				>
					<div style={{ display: 'flex', alignItems: 'flex-end', cursor: 'pointer' }}>
						{headCell.label}
						{currentSort.field === headCell.label && currentSort.orderAsc && (
							<AscendingIcon style={{ alignSelf: 'center' }} />
						)}
						{currentSort.field === headCell.label && !currentSort.orderAsc && (
							<DescendingIcon style={{ alignSelf: 'center' }} />
						)}
					</div>
				</TableCell>
			))
		}
		if (tableType === 'cubicCalculator') {
			cells = cubicCalculatorCells.map((headCell) => (
				<TableCell key={headCell.id} className={classes.tableCell} align="center">
					{headCell.label}
				</TableCell>
			))
		}
		if (tableType === 'savedLocations') {
			cells = locationCells.map((headCell) => (
				<TableCell key={headCell.id} className={`${classes.tableCell} ${classes.tableCellModal}`} align="center">
					<div
						style={{
							display: 'flex',
							alignItems: 'flex-end',
							cursor: 'pointer',
						}}
					>
						{headCell.label}
					</div>
				</TableCell>
			))
		}

		if (tableType === 'savedTemplateItems') {
			cells = templateItemCells.map((headCell) => (
				<TableCell key={headCell.id} className={`${classes.tableCell} ${classes.tableCellModal}`} align="center">
					<div
						style={{
							display: 'flex',
							alignItems: 'flex-end',
							cursor: 'pointer',
						}}
					>
						{headCell.label}
					</div>
				</TableCell>
			))
		}

		return cells
	}

	return (
		<TableHead>
			<TableRow>
				{displayTableCheckBox()}
				{displayTableCells()}
			</TableRow>
		</TableHead>
	)
}

TableHeader.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	rowCount: PropTypes.number.isRequired,
	tableType: PropTypes.string.isRequired,
	currentSort: PropTypes.objectOf(PropTypes.any).isRequired,
	setCurrentSort: PropTypes.func.isRequired,
}

export default TableHeader
