import React, { useState, useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Button } from '@material-ui/core'

import { useHistory, Prompt } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { clearQuoteDetailsForReQuote } from '../../../../redux/actions/QuoteDetails.actions'
import { bookShipment, saveCreateShipmentData } from '../../../../redux/actions/Shipment.actions'

import { quotesListRoute, shipmentsListRoute, createQuotesRoute } from '../../../../utils/constants'
import SessionContext from '../../../../context/session/index'
import SimpleStepper from '../../../../components/SimpleStepper'
import ShipmentStepOne from '../containers/ShipmentStepOne'
import NewQuoteShipmentStepFour from '../../../newQuoteShipment/components/NewQuoteShipmentStepFour'
import NewQuoteShipmentStepFive from '../../../newQuoteShipment/components/NewQuoteShipmentStepFive'
import sweetAlertModal from '../../../../components/SweetAlertModal'

import useStyles from './styles'
import './NewShipment.scss'
import ShipmentModal from '../components/ShipmentEmailConfirmation/ShipmentModal'
import ShipmentSavedDialog from '../components/ShipmentBookedDialog'
import { capitalizeFirstLetter } from '../../../../utils/helpers'

const NewShipment = () => {
	const { handleSubmit, register, errors, watch } = useForm()
	const dispatch = useDispatch()
	const { getUserData, getTokenUser } = useContext(SessionContext)

	const classes = useStyles()
	const history = useHistory()
	const quoteDetails = useSelector((state) => state.quoteDetails.quoteDetails)
	const createShipmentInfo = useSelector((state) => state.shipments.createShipmentInfo)
	const [activeStep, setActiveStep] = useState(1)
	const [shipperData, setShipperData] = useState({})
	const [consigneeData, setConsigneeData] = useState({})
	const [referencesAdded, setReferencesAdded] = useState([])
	const [shipmentItems, setShipmentItems] = useState([])
	const [shipmentPayload, setShipmentPayload] = useState({})
	const steps = ['Shipment information', '', 'Shipment confirmation']
	const isEmptyForm = Object.keys(createShipmentInfo).length !== 0
	const [isPreviousClicked, setIsPreviousClicked] = useState(isEmptyForm)

	const [showSuccessModal, setShowSuccessModal] = useState(false)
	const [shipmentId, setShipmentId] = useState(0)
	const [emailToNotify, setEmailToNotify] = useState(null)
	const [showEmailModal, setShowEmailModal] = useState(false)
	const [confirmedNavigation, setConfirmedNavigation] = useState(false)
	const [lastLocation, setLastLocation] = useState(null)

	const [contactData, setContactData] = useState({})

	const { actualCompany } = getUserData()
	const token = getTokenUser()

	const alertUser = (e) => {
		e.preventDefault()
		e.returnValue = ''
	}

	useEffect(() => {
		dispatch(clearQuoteDetailsForReQuote(''))
		if (Object.keys(quoteDetails).length === 0) {
			setConfirmedNavigation(true)
			history.push(quotesListRoute)
		} else {
			window.addEventListener('beforeunload', alertUser)
			if (confirmedNavigation && lastLocation) {
				history.push(lastLocation.pathname)
			}
			setShipmentItems(quoteDetails.items)
			setReferencesAdded(quoteDetails.references)
			const originAccesorials = quoteDetails.accesorials.filter(
				(accesorial) => accesorial.origin_destination === 'ORIGIN',
			)
			const destinationAccesorials = quoteDetails.accesorials.filter(
				(accesorial) => accesorial.origin_destination === 'DESTINATION',
			)
			setShipperData({
				zipCode: quoteDetails.zipcode_origin,
				city: quoteDetails.city_origin,
				state: quoteDetails.state_origin,
				country: quoteDetails.country_origin,
				dropOffDate: quoteDetails.est_dropoff_date,
				accessorials: originAccesorials,
			})
			setConsigneeData({
				zipCode: quoteDetails.zipcode_destination,
				state: quoteDetails.state_destination,
				country: quoteDetails.country_destination,
				pickUpDate: quoteDetails.est_pickup_date,
				accessorials: destinationAccesorials,
			})
		}
	}, [dispatch, quoteDetails, history, confirmedNavigation, lastLocation])

	const onNextStep = (event) => {
		if (
			watch('shipperCompanyName') &&
			watch('shipperAddress') &&
			watch('shipperAddress2') &&
			watch('shipperContactName') &&
			watch('consigneeCompanyName') &&
			watch('consigneeAddress') &&
			watch('consigneeAddress2') &&
			watch('consigneeContactName') &&
			event.currentTarget &&
			event.currentTarget.id === 'new-shipment-nex-step-button'
		) {
			const isHazmatDataMissing = shipmentItems.find((item) => item.is_haz_mat && !item.haz_mat_number)
			if (isHazmatDataMissing && Object.keys(isHazmatDataMissing).length !== 0) {
				window.scrollTo(0, document.body.scrollHeight / 2)
				return
			}
			let shipperAccesorials = ''
			let consigneeAccesorials = ''
			quoteDetails.accesorials.map((d) => {
				let name
				if (d.origin_destination === 'ORIGIN') {
					name = capitalizeFirstLetter(d.name)
					shipperAccesorials += `${name}, `
				}
				if (d.origin_destination === 'DESTINATION') {
					name = capitalizeFirstLetter(d.name)
					consigneeAccesorials += `${name}, `
				}
				return name
			})
			const payload = {
				quoteId: quoteDetails.id,
				rateId: quoteDetails.rate_id,
				type: 'CONSIGNEE',
				shipper: {
					shipperId: null,
					companyName: watch('shipperCompanyName'),
					address1: watch('shipperAddress'),
					address2: watch('shipperAddress2'),
					contactName: watch('shipperContactName'),
					contactPhone: watch('shipperContactPhone'),
					contactPhoneExtension: watch('pickUpContactPhoneExtension'),
					contactEmail: watch('shipperContactEmail'),
					sendConfirmationEmail: watch('confirmationEmail'),
					zipCode: quoteDetails.zipcode_origin,
					city: quoteDetails.city_origin,
					state: quoteDetails.state_origin,
					country: quoteDetails.country_origin,
					pickUpDate: quoteDetails.est_dropoff_date,
					accesorials: shipperAccesorials,
				},
				consignee: {
					consigneeId: null,
					companyName: watch('consigneeCompanyName'),
					address1: watch('consigneeAddress'),
					address2: watch('consigneeAddress2'),
					contactName: watch('consigneeContactName'),
					contactPhone: watch('consigneeContactPhone'),
					contactEmail: watch('consigneeContactEmail'),
					zipCode: quoteDetails.zipcode_destination,
					city: quoteDetails.city_destination,
					state: quoteDetails.state_destination,
					country: quoteDetails.country_destination,
					pickUpDate: quoteDetails.est_pickup_date,
					accesorials: consigneeAccesorials,
				},
				items: shipmentItems,
				specialIntructions: {
					origin: watch('specialInstructionsOrigin'),
					destination: watch('specialInstructionsDestination'),
					comments: watch('specialInstructionsComments'),
				},
				references: referencesAdded,
				carrierData: quoteDetails.rate,
				contractData: quoteDetails.contractInfo,
			}

			setShipmentPayload(payload)
			dispatch(saveCreateShipmentData(payload))
			setActiveStep(2)
		}
	}

	const onPrevStep = () => {
		setActiveStep(1)
		setIsPreviousClicked(true)
	}

	const handleCancelShipmentClick = () => {
		sweetAlertModal(
			'warning',
			'Canceling Shipment',
			'Are you sure you want to cancel the shipment?',
			'Yes',
			true,
			true,
			'No',
		).then((result) => {
			if (result.isConfirmed) {
				history.push(shipmentsListRoute)
			}
		})
	}

	const downloadBOL = (bookedShipmentId) => {
		const url = `${process.env.REACT_APP_BACKEND_QUOTES_BASE_URL}/shipment/bol/${bookedShipmentId}`

		const oReq = new XMLHttpRequest()
		oReq.open('GET', url, true)
		oReq.responseType = 'arraybuffer'
		oReq.setRequestHeader('X-Company-id', Number(actualCompany))
		oReq.setRequestHeader('Authorization', `Bearer ${token}`)

		oReq.onload = function download() {
			const blob = new Blob([oReq.response], { type: 'application/pdf' })
			const URL = window.URL || window.webkitURL
			const dataUrl = URL.createObjectURL(blob)
			const downloadLink = document.createElement('a')
			const fileName = `BOL-${bookedShipmentId}.pdf`

			downloadLink.href = dataUrl
			downloadLink.download = fileName
			downloadLink.click()
		}
		oReq.send()
	}

	const handleBookShipmentClick = async () => {
		const items = shipmentItems.map((item) => ({
			itemId: item.id,
			nmfc: item.nmfc,
			subNmfc: item.sub_nmfc,
			description: item.description,
			isStackable: item.stackable,
			isHazardous: item.is_haz_mat,
		}))

		const references = referencesAdded.map((reference) => ({
			type: reference.type,
			number: reference.value,
		}))

		const payload = {
			quoteId: shipmentPayload.quoteId,
			rateId: shipmentPayload.rateId,
			type: 'LTL',
			shipper: {
				shipperId: shipmentPayload.shipper.shipperId,
				companyName: shipmentPayload.shipper.companyName,
				address1: shipmentPayload.shipper.address1,
				address2: shipmentPayload.shipper.address2,
				contactName: shipmentPayload.shipper.contactName,
				contactPhone: shipmentPayload.shipper.contactPhone,
				contactEmail: shipmentPayload.shipper.contactEmail,
				saveShipper: false,
				sendConfirmationEmail: shipmentPayload.shipper.sendConfirmationEmail,
			},
			consignee: {
				consigneeId: shipmentPayload.consignee.consigneeId,
				companyName: shipmentPayload.consignee.companyName,
				address1: shipmentPayload.consignee.address1,
				address2: shipmentPayload.consignee.address2,
				contactName: shipmentPayload.consignee.contactName,
				contactPhone: shipmentPayload.consignee.contactPhone,
				saveConsignee: false,
				contactEmail: shipmentPayload.consignee.contactEmail,
			},
			items,
			specialIntructions: {
				origin: shipmentPayload.specialIntructions.origin,
				destination: shipmentPayload.specialIntructions.destination,
				comments: shipmentPayload.specialIntructions.comments,
			},
			references,
		}
		setEmailToNotify(payload.shipper.contactEmail)
		let contactInfo = []
		const shipper = {
			name: shipmentPayload.shipper.contactName,
			email: shipmentPayload.shipper.contactEmail,
			phone: shipmentPayload.shipper.contactPhone,
		}
		const consignee = {
			name: shipmentPayload.consignee.contactName,
			email: shipmentPayload.consignee.contactEmail,
			phone: shipmentPayload.consignee.contactPhone,
		}

		contactInfo = [shipper, consignee]

		const { status, resp } = await dispatch(bookShipment(payload))
		if (status === 'error') {
			sweetAlertModal(status, 'There was an error in the book shipment process!', resp, 'Ok', true, false, null).then(
				(result) => {
					if (result.isConfirmed) {
						history.push(quotesListRoute)
					}
				},
			)
		} else if (resp.imported === false) {
			sweetAlertModal(
				status,
				'Shipment created but not imported.',
				'Try to import it again.',
				'Ok',
				false,
				false,
				null,
			).then((result) => {
				if (result.isConfirmed) {
					setContactData(contactInfo)
					setShipmentId(resp.id)
					setActiveStep(3)
				}
			})
		} else {
			setShipmentId(resp.id)
			downloadBOL(resp.id)
			setShowSuccessModal(true)
			setContactData(contactInfo)
			setActiveStep(3)
			dispatch(saveCreateShipmentData({}))
		}
	}

	const handleOpenModal = () => {
		setShowEmailModal(true)
	}

	const handleBlockedNavigation = (nextLocation) => {
		if (!confirmedNavigation && Object.keys(quoteDetails).length !== 0) {
			setLastLocation(nextLocation)
			sweetAlertModal('warning', 'Are you sure you want to leave this page?', null, 'Yes', true, true, 'No').then(
				(result) => {
					if (result.isConfirmed) {
						setConfirmedNavigation(true)
						history.push(nextLocation.pathname)
						dispatch(clearQuoteDetailsForReQuote(''))
					}
				},
			)
			return false
		}
		return true
	}

	const handleRedirect = (redirection) => {
		history.push(redirection === 'shipment' ? shipmentsListRoute : createQuotesRoute)
	}

	return (
		<form className="new-quote-container" id="new-shipment-form" onSubmit={handleSubmit(onNextStep)}>
			<div className="new-quote-stepper-container">
				<h1 className="new-shipment-title">Shipment</h1>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam nec neque efficitur, rutrum ipsum vehicula,
					facilisis felis.
				</p>
				<Prompt message={handleBlockedNavigation} />
				<SimpleStepper activeStep={activeStep} steps={steps} />
			</div>
			{activeStep === 1 && (
				<ShipmentStepOne
					shipperData={shipperData}
					consigneeData={consigneeData}
					shipmentItems={shipmentItems}
					setShipmentItems={setShipmentItems}
					referencesAdded={referencesAdded}
					setReferencesAdded={setReferencesAdded}
					register={register}
					errors={errors}
					isPreviousClicked={isPreviousClicked}
				/>
			)}
			{activeStep === 2 && <NewQuoteShipmentStepFour isDetail={false} dataConfirm={shipmentPayload} />}
			{activeStep === 3 && <NewQuoteShipmentStepFive contactData={contactData} shipmentId={shipmentId} />}
			<div className="new-shipment-buttons">
				{activeStep !== 3 && (
					<Button
						id="new-shipment-cancel-button"
						style={{ marginLeft: '1em' }}
						className={classes.cancelShipmentButton}
						onClick={() => handleCancelShipmentClick()}
					>
						Cancel
					</Button>
				)}
				{activeStep === 1 && (
					<Button
						id="new-shipment-nex-step-button"
						style={{ marginLeft: '1em' }}
						className={classes.button}
						variant="contained"
						color="primary"
						type="submit"
						onClick={onNextStep}
					>
						Next Step
					</Button>
				)}
				{activeStep === 2 && (
					<Button
						id="new-shipment-previous-step-button"
						className={classes.previousButton}
						variant="contained"
						onClick={onPrevStep}
					>
						Previous
					</Button>
				)}
				{activeStep === 2 && (
					<>
						<Button
							id="new-shipment-book-now-button"
							className={classes.button}
							variant="contained"
							color="primary"
							onClick={handleBookShipmentClick}
						>
							Book now
						</Button>
						{showEmailModal && <ShipmentModal shipmentId={shipmentId} initialEmail={emailToNotify} />}
						{showSuccessModal && <ShipmentSavedDialog handleOpenModal={handleOpenModal} shipmentId={shipmentId} />}
					</>
				)}
				<div className="new-shipment-buttons-third-step">
					{activeStep === 3 && (
						<Button
							id="new-shipment-new-quote-button"
							className={classes.buttonQuote}
							variant="contained"
							onClick={() => handleRedirect('quote')}
						>
							Create a New Quote
						</Button>
					)}
					{activeStep === 3 && (
						<Button
							id="new-shipment-shipment-list-button"
							className={classes.buttonShipment}
							variant="contained"
							onClick={() => handleRedirect('shipment')}
						>
							Back to My Shipments
						</Button>
					)}
				</div>
			</div>
		</form>
	)
}

export default NewShipment
