import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	icon: {
		width: '24px',
	},
	tableContainer: {
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	paper: {
		border: '1px solid',
		borderColor: theme.palette.neutral.lightGray,
	},
	table: {
		minWidth: 700,
	},
	tableCellHead: {
		fontWeight: theme.typography.fontWeightMedium,
		backgroundColor: theme.palette.neutral.whiteGray,
		borderBottom: '2px solid #C9CFE4',
	},
	tableCellSecondHead: {
		fontWeight: theme.typography.fontWeightMedium,
		backgroundColor: theme.palette.neutral.whiteGray,
		borderBottom: '2px solid #C9CFE4',
	},
	tableVerticalLineRight: {
		borderRight: '1.5px solid #C9CFE4',
	},
	tableCell: {
		textAlign: 'center',
	},
	checkBox: {
		color: '#CCD2D8',
		'&.Mui-disabled.Mui-checked': {
			color: theme.palette.secondary.main,
		},
	},
}))

export default useStyles
