import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Avatar, Box } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Checkbox from '@material-ui/core/Checkbox'
import Chip from '@material-ui/core/Chip'
import IconButton from '@material-ui/core/IconButton'
import { useTheme } from '@material-ui/core/styles'
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined'
import MenuList from '../MenuList'
import useStyles from './styles'
import { reqUserChildrenCompanies } from '../../../../../redux/requests/Accounts.request'
import SessionContext from '../../../../../context/session'
import AccountChildrenList from '../AccountChildrenList'

const AccountListRow = ({ labelId, isItemSelected, data, selected, setSelected, canActivate }) => {
	const classes = useStyles()
	const theme = useTheme()
	const { getUserData } = useContext(SessionContext)
	const { actualCompany } = getUserData()
	const [openAccordion, setOpenAccordion] = React.useState(false)
	const [anchorEl, setAnchorEl] = useState(null)
	const [childrenLoading, setChildrendLoading] = useState(false)
	const [childrenCompanies, setChildrenCompanies] = useState([])
	const open = Boolean(anchorEl)
	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name)
		let newSelected = []

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name)
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1))
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1))
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
		}
		setSelected(newSelected)
	}

	const handleOpenMenu = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const fetchChildrenCompany = async () => {
		const config = {
			headers: { 'X-Company-id': Number(actualCompany) },
		}
		setChildrendLoading(true)
		const [error, response] = await reqUserChildrenCompanies(data.id, config)
		if (error === null) {
			setChildrenCompanies(response?.companies ?? [])
			setChildrendLoading(false)
		} else {
			setChildrenCompanies([])
			setChildrendLoading(false)
		}
	}

	const handleDisplayChildren = () => {
		setOpenAccordion(!openAccordion)
		if (!openAccordion) {
			fetchChildrenCompany()
		}
	}

	const isAccountCompleted = data.step1_complete && data.step2_complete && data.step4_complete

	return (
		<>
			<TableRow
				hover
				role="checkbox"
				aria-checked={isItemSelected}
				tabIndex={-1}
				key={data.id}
				selected={isItemSelected}
				className={classes.tableRow}
			>
				<TableCell padding="checkbox" align="center" style={{ width: '50px', paddingLeft: '10px' }}>
					{canActivate && (
						<Checkbox
							inputProps={{ 'aria-labelledby': labelId }}
							checked={isItemSelected}
							className={classes.tableCheckbox}
							onClick={(event) => handleClick(event, data.id)}
							size="small"
							style={{
								color: isItemSelected ? theme.palette.secondary.light : theme.palette.primary.main,
							}}
						/>
					)}
				</TableCell>
				<TableCell className={classes.tableCell}>
					<Box className={classes.cellAccountInfo}>
						{data.logo ? (
							<Link to={`/account-details/${data.id}`}>
								<img src={data.logo} alt="" className={classes.avatar} />
							</Link>
						) : (
							<Link to={`/account-details/${data.id}`}>
								<Avatar className={classes.avatar}>
									<SupervisorAccountOutlinedIcon ssize="small" color="inherit" />
								</Avatar>
							</Link>
						)}
						<Link className={classes.accountName} to={`/account-details/${data.id}`}>
							{data.name}
						</Link>
						{data.children_count && data.children_count > 0 ? (
							<IconButton aria-label="expand row" size="small" onClick={handleDisplayChildren}>
								{openAccordion ? <ExpandLessIcon /> : <ExpandMoreIcon />}
							</IconButton>
						) : null}
					</Box>
				</TableCell>
				<TableCell className={classes.tableCell}>{data.contact}</TableCell>
				<TableCell className={classes.tableCell}>
					{data.audit_creation_date && moment(data.audit_creation_date).format('MM/DD/YYYY')}
				</TableCell>
				<TableCell className={classes.tableCell}>
					{data.audit_update_date && moment(data.audit_update_date).format('MM/DD/YYYY')}
				</TableCell>
				<TableCell className={`${classes.tableCell} ${classes.statusContainer}`}>
					<div className={classes.statusAccount}>
						{data.is_active ? (
							<Chip size="small" className={classes.statusActive} label="Active" />
						) : (
							<Chip size="small" className={classes.statusInactive} label="Inactive" />
						)}
						{!isAccountCompleted ? (
							<Chip size="small" className={classes.stepStatusIncomplete} label="Incomplete" />
						) : null}
					</div>
				</TableCell>
				<TableCell className={classes.tableCell} align="left">
					<IconButton
						aria-label="toggle action menu"
						onClick={handleOpenMenu}
						onMouseDown={null}
						edge="start"
						size="small"
					>
						<MoreVertIcon style={{ color: '#2C2C2C' }} />
					</IconButton>
				</TableCell>
			</TableRow>
			<AccountChildrenList
				isOpen={openAccordion}
				isLoading={childrenLoading}
				data={childrenCompanies}
				selected={selected}
				setSelected={setSelected}
			/>
			<MenuList
				anchorEl={anchorEl}
				open={open}
				handleClose={handleClose}
				templateItemId={data.id}
				accountId={data.id}
				isActive={data.is_active}
			/>
		</>
	)
}

AccountListRow.defaultProps = {
	canActivate: false.isRequired,
}

AccountListRow.propTypes = {
	labelId: PropTypes.string.isRequired,
	isItemSelected: PropTypes.bool.isRequired,
	data: PropTypes.objectOf(PropTypes.any).isRequired,
	selected: PropTypes.instanceOf(Array).isRequired,
	setSelected: PropTypes.func.isRequired,
	canActivate: PropTypes.bool,
}

export default AccountListRow
