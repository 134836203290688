import {
	Avatar,
	Badge,
	Button,
	Checkbox,
	Container,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@material-ui/core'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'
import { pick, isNil } from 'lodash'
import EditRateAccessForm from 'modules/carriers/newCarrier/containers/components/EditRateAccessForm/EditRateAccessForm'
import React, { useEffect, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { InformationIcon, SpecialInstructionsIcon, TruckIcon } from '../../../../assets/Icons/Icons'
import ModuleHeader from '../../../../components/ModuleHeader'
import sweetAlertModal from '../../../../components/SweetAlertModal/SweetAlertModal'
import { createCarrier, editCarrier, fetchCarrierById } from '../../../../redux/actions/Carriers.actions'
import { setTabCurrentRoute, setTabTitle } from '../../../../redux/actions/MultiTabs.actions'
import { carriersListRoute, createCarrierRoute } from '../../../../utils/constants'
import { carrierOptions, contractTypeOptions, serviceTypeOptions } from '../../../../utils/dropDownOptions'
import CarrierLiabilitiesTable from '../../CarriersList/components/CarrierLiabilitiesTable/CarrierLiabilitiesTable'
import { classComparator } from '../../CarriersList/components/shipmentClassSorted'
import { contractTypesToUse, rateAccessLoop, serviceTypesToUse } from '../../utils/carrierDetailsRateAccessHelpers'
import useStyles from './styles'

/** Deconstructs an object into idividual string keyed properties and adds them to a formData object.
 * @param formData form data to add the object to
 * @param formDataBaseKey string key for the object
 * @param object object to be added to the formData
 */
export function addObjectToFormData(formData, formDataBaseKey, object) {
	Object.entries(object).forEach(([key, value]) => {
		if (!isNil(value)) {
			const formDataKey = `${formDataBaseKey}[${key}]`
			if (typeof value === 'object') {
				addObjectToFormData(formData, formDataKey, value)
			} else {
				formData.set(formDataKey, value)
			}
		}
	})
}

export function contractServiceTypesMap(contractServiceString) {
	const [contractType, serviceType] = contractServiceString.split(';')
	return { contract_type: contractType, service_type: serviceType }
}

const NewCarrierContainer = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const location = useLocation()
	const history = useHistory()
	const { id } = useParams()
	const isEdit = !!id
	const [existingCarrierPhotoUrl, setExistingCarrierPhotoUrl] = useState(null)
	const [carrierPhoto, setCarrierPhoto] = useState([])
	const [carrierGroup, setCarrierGroup] = useState('')
	const [liabilities, setLiabilities] = useState([])
	const [isSubmitting, setSubmitting] = useState(false)
	const formData = new FormData()
	const { register, errors, handleSubmit, control, setValue, getValues } = useForm()

	const splittedPath = location.pathname.split('/')
	useEffect(() => {
		const currentRoute = isEdit ? `/${splittedPath[1]}/${id}` : createCarrierRoute
		dispatch(setTabCurrentRoute(currentRoute))
		dispatch(setTabTitle(isEdit ? 'Edit Carrier' : 'New carrier'))
	}, [isEdit, dispatch, id, splittedPath])

	const formTexts = {
		title: isEdit ? 'Edit Carrier' : 'New Carrier',
		subtitle: isEdit
			? 'Updates made to carrier rules and restrictions on this page will apply to all customer carriers with the same SCAC.'
			: 'Insert the information for your new carrier.',
		submitButtonText: isEdit ? 'Update Item' : 'Save New Carrier',
		cancelButtonText: isEdit ? 'Cancel' : 'Cancel Carrier',
		errorSubmitText: isEdit
			? 'There was an error in the update carrier process!'
			: 'There was an error in the create carrier process!',
		successSubmitText: isEdit ? 'Carrier has been updated successfully!' : 'Carrier has been saved successfully!',
		photoMissing: 'A photo is required',
		carrierMissing: 'A carrier is required',
	}

	useEffect(() => {
		const fetchData = async () => {
			const { status, resp } = await dispatch(fetchCarrierById(`/${id}`))
			if (status === 'error') {
				sweetAlertModal(status, 'There was an error fetching the carrier information!', null, 'Ok', true, false, null)
			} else {
				let carrierIntegration = resp?.carrierIntegrations?.[0]
				carrierIntegration = carrierIntegration ?? {}
				/** response.carrierIntegration is actually a list of rate_access records. */
				const rateAccessRecords = resp?.carrierIntegrations

				if (carrierIntegration.carrier_logo) {
					setExistingCarrierPhotoUrl(carrierIntegration.carrier_logo)
					setCarrierPhoto([carrierIntegration.carrier_logo])
				}
				setValue('name', carrierIntegration.name ?? '')
				setValue('scac', carrierIntegration.scac ?? '')
				setValue('carrierGroupItem', carrierIntegration.carrier_group ?? '')

				rateAccessLoop((contractType, serviceType) => {
					let currentRateAccess = rateAccessRecords?.find((rateAccess) => {
						const contractTypesMatch = rateAccess.contract_type === contractType
						const serviceTypesMatch = rateAccess.service_type === serviceType
						return contractTypesMatch && serviceTypesMatch
					})
					if (!currentRateAccess && serviceType === 'LTL_STANDARD') {
						// use guaranteed records as a fallback for the standard records.
						currentRateAccess = rateAccessRecords?.find((rateAccess) => {
							const contractTypesMatch = rateAccess.contract_type === contractType
							const serviceTypesMatch = rateAccess.service_type.includes('GUARANTEED')
							return contractTypesMatch && serviceTypesMatch
						})
					}
					setValue(`${contractType}_${serviceType}_cubic_capacity`, currentRateAccess?.cubic_capacity ?? '')
					setValue(`${contractType}_${serviceType}_density`, currentRateAccess?.density ?? '')
					setValue(`${contractType}_${serviceType}_linear_footage_max`, currentRateAccess?.linear_footage_max ?? '')
					setValue(`${contractType}_${serviceType}_weight_max`, currentRateAccess?.weight_max ?? '')
				})

				if (resp?.liability_values) {
					const liabilityResponse = resp?.liability_values ?? []
					const sortedLiability = liabilityResponse.sort(classComparator)

					setLiabilities(sortedLiability)
				}
			}
		}
		if (isEdit && id) fetchData()
		else {
			setCarrierPhoto([])
			setValue('name', '')
			setValue('scac', '')
			setCarrierGroup('')
			setValue('contractServiceTypes', [])
			rateAccessLoop((contractType, serviceType) => {
				setValue(`${contractType}_${serviceType}_cubic_capacity`, '')
				setValue(`${contractType}_${serviceType}_density`, '')
				setValue(`${contractType}_${serviceType}_linear_footage_max`, '')
				setValue(`${contractType}_${serviceType}_weight_max`, '')
			})

			setValue('create_create_cubic_capacity', '')
			setValue('create_create_density', '')
			setValue('create_create_linear_footage_max', '')
			setValue('create_create_weight_max', '')
		}
	}, [location, dispatch, isEdit, id, setValue])

	const handleSelectImage = (e) => {
		const { files } = e.target
		if (files && files.length) {
			setExistingCarrierPhotoUrl(null)
			setCarrierPhoto(files)
		}
	}

	const handleSaveCarrier = async (data) => {
		if (carrierPhoto.length <= 0) {
			sweetAlertModal('error', formTexts.photoMissing, null, 'Ok', true, false)
			return
		}

		setSubmitting(true)
		addObjectToFormData(
			formData,
			'liabilities',
			liabilities.map((liability) => pick(liability, 'class', 'liability_per_pound', 'max', 'min')),
		)
		if (isEdit) {
			if (!existingCarrierPhotoUrl) {
				formData.append('file', carrierPhoto[0] ? carrierPhoto[0] : [])
			}
			formData.append('name', getValues('name'))
			formData.append('scac', getValues('scac'))
			formData.append('carrier_group', data.carrierGroupItem)

			rateAccessLoop((contractType, serviceType) => {
				if (getValues(`${contractType}_${serviceType}_cubic_capacity`) !== '') {
					formData.append(
						`rate_access_updates[${contractType}][${serviceType}][cubic_capacity]`,
						getValues(`${contractType}_${serviceType}_cubic_capacity`),
					)
				}
				if (getValues(`${contractType}_${serviceType}_density`) !== '') {
					formData.append(
						`rate_access_updates[${contractType}][${serviceType}][density]`,
						getValues(`${contractType}_${serviceType}_density`),
					)
				}
				if (getValues(`${contractType}_${serviceType}_linear_footage_max`) !== '') {
					formData.append(
						`rate_access_updates[${contractType}][${serviceType}][linear_footage_max]`,
						getValues(`${contractType}_${serviceType}_linear_footage_max`),
					)
				}
				if (getValues(`${contractType}_${serviceType}_weight_max`) !== '') {
					formData.append(
						`rate_access_updates[${contractType}][${serviceType}][weight_max]`,
						getValues(`${contractType}_${serviceType}_weight_max`),
					)
				}
				if (getValues('scac') !== '') {
					formData.append(`rate_access_updates[${contractType}][${serviceType}][scac]`, getValues('scac'))
				}
			})
		} else {
			formData.append('file', carrierPhoto[0] ? carrierPhoto[0] : [])
			formData.append('name', getValues('name'))
			formData.append('scac', getValues('scac'))
			formData.append('carrier_group', data.carrierGroupItem)
			addObjectToFormData(formData, 'contract_service_types', data.contractServiceTypes.map(contractServiceTypesMap))

			if (getValues('create_create_cubic_capacity') !== '') {
				formData.append('cubic_capacity', getValues('create_create_cubic_capacity') ?? null)
			}
			if (getValues('create_create_density') !== '') {
				formData.append('density', getValues('create_create_density') ?? null)
			}
			if (getValues('create_create_linear_footage_max') !== '') {
				formData.append('max_linear_footage', getValues('create_create_linear_footage_max') ?? null)
			}
			if (getValues('create_create_weight_max') !== '') {
				formData.append('max_weight', getValues('create_create_weight_max') ?? null)
			}
		}

		const { status, resp } = isEdit
			? await dispatch(editCarrier(id, formData))
			: await dispatch(createCarrier(formData))
		if (status === 'error') {
			sweetAlertModal('error', resp, null, 'Ok', true, false)
		} else {
			sweetAlertModal(status, formTexts.successSubmitText, null, 'Ok', true, false, null).then((result) => {
				if (result.isConfirmed) {
					history.push(carriersListRoute)
				}
			})
		}
		setSubmitting(false)
	}

	return (
		<>
			<FormProvider
				register={register}
				errors={errors}
				handleSubmit={handleSubmit}
				control={control}
				setValue={setValue}
				getValues={getValues}
			>
				<form autoComplete="off" onSubmit={handleSubmit(handleSaveCarrier)}>
					<Grid item className={classes.headerContainer}>
						<ModuleHeader title={formTexts.title} subtitle={formTexts.subtitle} />
					</Grid>
					<div className={!isEdit ? classes.sectionContainer : classes.sectionContainerLarge}>
						<div className={classes.sectionTitle}>
							<div className={classes.sectionTitleContainer}>
								<InformationIcon className={classes.sectionTitleIcon} />
							</div>
							<h3 className={classes.newUserSectionTitle}>Basic Information</h3>
						</div>
						<div className={classes.innerContainer}>
							<Grid container spacing={2}>
								<Grid item xs={12} md={2}>
									<div className={classes.inputFileContainer}>
										<input
											type="file"
											className={classes.dragAndDropUploader}
											onChange={handleSelectImage}
											accept="image/*"
										/>
										{!carrierPhoto.length ? (
											<Badge
												className={classes.badgePhoto}
												style={{ marginRight: '20px', cursor: 'pointer' }}
												badgeContent={<PhotoCameraIcon style={{ width: '12px' }} />}
											>
												<p className={classes.uploadPhotoTitle}>Upload Photo</p>
												<Avatar className={`${classes.avatar} ${classes.avatarBorder}`}>AT</Avatar>
											</Badge>
										) : (
											<Badge
												className={classes.badgePhoto}
												badgeContent={<PhotoCameraIcon style={{ width: '12px' }} />}
												color="secondary"
											>
												<p className={classes.uploadPhotoTitle}>Upload Photo</p>
												<>
													{existingCarrierPhotoUrl ? (
														<Avatar
															className={`${classes.avatar} ${classes.avatarBorder}`}
															src={existingCarrierPhotoUrl}
														/>
													) : (
														<Avatar
															className={`${classes.avatar} ${classes.avatarBorder}`}
															src={URL.createObjectURL(carrierPhoto[0])}
														/>
													)}
												</>
											</Badge>
										)}
									</div>
								</Grid>
								<Grid item xs={12} md={4}>
									<TextField
										size="small"
										name="name"
										type="text"
										label="Carrier Name *"
										className={classes.textField}
										inputRef={register({
											required: { value: true, message: 'This value is required' },
											maxLength: { value: 100, message: 'Maximum 100 characters allowed' },
										})}
										variant="outlined"
										FormHelperTextProps={{ classes: { root: classes.helperText } }}
										error={!!errors.name}
										helperText={errors.name ? errors.name.message : null}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</Grid>
								<Grid item xs={12} md={3}>
									<TextField
										size="small"
										name="scac"
										type="text"
										label="SCAC *"
										className={classes.textField}
										inputRef={register({
											required: { value: true, message: 'This value is required' },
											pattern: { value: /^[A-Z]{4}$/, message: 'Only 4 characters in uppercase allowed' },
											maxLength: { value: 4, message: 'Maximum 4 characters allowed' },
										})}
										variant="outlined"
										FormHelperTextProps={{ classes: { root: classes.helperText } }}
										error={!!errors.scac}
										helperText={errors.scac ? errors.scac.message : null}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</Grid>
								<Grid item xs={12} md={3}>
									<FormControl
										variant="outlined"
										className={classes.formControl}
										size="small"
										error={!!errors.carrierGroupItem}
									>
										<InputLabel htmlFor="company-dialog-select">Carrier Group *</InputLabel>
										<Controller
											render={({ onChange, value, name }) => (
												<Select
													className={classes.groupSelect}
													value={value}
													name={name}
													onChange={(e) => {
														onChange(e.target.value)
														setValue('carrierGroupItem', e.target.value, { shouldValidate: true, shouldDirty: true })
														setCarrierGroup(e.target.value)
													}}
													label="Carrier Group *"
												>
													{carrierOptions.map((item, index) => (
														<MenuItem value={item.value} key={`id-${index + 1}`}>
															{item.label}
														</MenuItem>
													))}
												</Select>
											)}
											name="carrierGroupItem"
											control={control}
											defaultValue={carrierGroup}
											rules={{ required: true, message: 'This value is required' }}
										/>
										{errors.carrierGroupItem && <FormHelperText>This value is required</FormHelperText>}
									</FormControl>
								</Grid>
							</Grid>

							{!isEdit && (
								<div>
									<div className={classes.iconTitle}>
										<TruckIcon />
										<h4>Contract and Service Types</h4>
									</div>
									<FormControl
										className={classes.contractServiceBox}
										component="fieldset"
										required
										error={!!errors.contractServiceTypes}
									>
										<legend>
											Select the Contract and Service Types you wanted propagated to your Customer accounts
										</legend>
										<Container className={classes.contractServiceFormGroupContainer}>
											{contractTypeOptions.map((contractOption) => (
												<FormGroup key={contractOption.value}>
													{serviceTypeOptions.map((serviceOption) => (
														<FormControlLabel
															key={serviceOption.value}
															control={<Checkbox />}
															label={`${contractOption.label} - ${serviceOption.label}`}
															value={`${contractOption.value};${serviceOption.value}`}
															name="contractServiceTypes"
															inputRef={register({
																required: 'Please select at least one.',
															})}
														/>
													))}
												</FormGroup>
											))}
										</Container>
										{errors.contractServiceTypes && (
											<FormHelperText>{errors.contractServiceTypes.message}</FormHelperText>
										)}
									</FormControl>
								</div>
							)}

							<div>
								<div className={classes.iconTitle}>
									<SpecialInstructionsIcon />
									<h4>Carrier Rules and Restrictions</h4>
								</div>
								{isEdit &&
									contractTypesToUse.map((contractType) => (
										<Grid container spacing={2} alignItems="center" key={contractType}>
											{serviceTypesToUse.map((serviceType) => (
												<Grid item xs={12} md={6} key={`${contractType}_${serviceType}`}>
													<EditRateAccessForm contractType={contractType} serviceType={serviceType} />
												</Grid>
											))}
										</Grid>
									))}
								{!isEdit && <EditRateAccessForm contractType="create" serviceType="create" />}
							</div>
						</div>
					</div>

					<div className={`${classes.sectionContainer} ${classes.sectionContainerGray}`}>
						<div className={classes.sectionTitle}>
							<div className={classes.sectionTitleContainer}>
								<AssignmentOutlinedIcon className={classes.listIcon} color="primary" alt="References" />
							</div>
							<h3 className={classes.newUserSectionTitle}>Carrier Liabilities</h3>
						</div>
						<div className={classes.innerContainer}>
							<CarrierLiabilitiesTable liabilities={liabilities} setLiabilities={setLiabilities} editMode />
						</div>
					</div>

					<Grid container className={classes.buttonsRow}>
						<Button
							className={`${classes.actionButton} ${classes.cancelButton}`}
							onClick={() => history.push(carriersListRoute)}
						>
							Cancel
						</Button>
						<Button
							className={`${classes.actionButton} ${classes.saveButton}`}
							variant="contained"
							color="secondary"
							type="submit"
							disabled={isSubmitting}
						>
							{formTexts.submitButtonText}
						</Button>
					</Grid>
				</form>
			</FormProvider>
		</>
	)
}

export default NewCarrierContainer
