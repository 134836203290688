import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'grid',
		gridTemplateRows: 'auto auto',
		rowGap: '4px',
		marginBottom: 15,
	},
	containerTittle: {
		display: 'flex',
		alignItems: 'center',
		columnGap: '16.99px',
	},
	dot: {
		height: '14px',
		width: '14px',
		borderRadius: '50%',
		backgroundColor: theme.palette.neutral.mainGray,
	},
	dotActive: {
		backgroundColor: theme.palette.secondary.main,
	},
	titleKey: {
		fontSize: 14,
		fontWeight: theme.typography.fontWeightBold,
		color: theme.palette.neutral.black,
	},
	titleValue: {
		fontSize: 14,
		color: theme.palette.neutral.black,
	},
	containerInformationVerticalLine: {
		display: 'flex',
		alignItems: 'center',
		columnGap: '16.99px',
		marginLeft: '5px',
	},
	verticalLine: {
		borderLeft: `1px dashed ${theme.palette.secondary.light}`,
		height: '100%',
	},
	finalVerticalLine: {
		borderLeft: `2.01px solid ${theme.palette.secondary.light}`,
	},
	containerDescriptionDateTime: {
		marginLeft: '7px',
		display: 'grid',
		gridTemplateRows: 'auto auto',
		rowGap: '16px',
		paddingBottom: '10px',
		paddingRight: '16px',
		[theme.breakpoints.up(theme.breakpoints.values.md)]: {
			paddingBottom: '20px',
		},
	},
	containerDateTime: {
		display: 'flex',
		columnGap: '32px',
	},
	containerLocationStopNumber: {
		display: 'none',
		[theme.breakpoints.up(theme.breakpoints.values.md)]: {
			display: 'block',
		},
	},
}))

export default useStyles
