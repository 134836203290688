import React, { useState, forwardRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useSelector, useDispatch } from 'react-redux'

import { InputAdornment, FormLabel } from '@material-ui/core'
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import SaveIconOutlined from '@material-ui/icons/SaveOutlined'
import AddIcon from '@material-ui/icons/Add'
import SearchIcon from '@material-ui/icons/Search'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'

import { Controller } from 'react-hook-form'
import NumberFormat from 'react-number-format'

import TimePicker from 'rc-time-picker'
import 'rc-time-picker/assets/index.css'

import moment from 'moment'
import {
	calculateTotalWeight,
	calculateTotalCubicFeet,
	calculateTotalDensity,
	calculateTotalItems,
} from '../../utils/helpers'
import { OriginIcon, DestinationIcon, ShipmentItemsIcon, SpecialInstructionsIcon } from '../../../../assets/Icons/Icons'

import RangeDatePicker from '../../../../components/RangeDatePicker'
import ShipmentItemsDetails from '../ShipmentItemsDetails'
import ShipmentItemsSliderNew from '../ShipmentItemsSliderNew'
import ReferencesForm from '../../../../components/ReferencesForm'

import useStyles from './styles'
import EditItemModal from '../EditItemModal'
import { fetchDataFromZipCode } from '../../utils/googleMapsQueries'
import { createLocation, editLocation } from '../../../../redux/actions/Locations.actions'
import { setTabReferences } from '../../../../redux/actions/MultiTabs.actions'
import sweetAlertModal from '../../../../components/SweetAlertModal/SweetAlertModal'
import SuggestionsModalZipCode from '../ModalSavedLocationsZipCode'
import removeZDate from '../../../../utils/removeZDate'

const NewQuoteShipmentStepThree = forwardRef(
	(
		{
			errors,
			register,
			shipperData,
			consigneeData,
			items,
			setShipmentItems,
			references,
			setReferencesAdded,
			setEarliestPickUpTime,
			setLatestPickUpTime,
			setEarliestDeliveryTime,
			setLatestDeliveryTime,
			isPreviousClicked,
			addInstructions,
			setAddInstructions,
			getValues,
			control,
			pickUpContactPhone,
			setPickUpContactPhone,
			deliveryContactPhone,
			setDeliveryContactPhone,
			disableOriginLocationButton,
			setDisableOriginLocationButton,
			disableDestinationLocationButton,
			setDisableDestinationLocationButton,
			updateLocationFromModal,
			linearFeet,
			selectedOriginLocation,
			selectedDestinationLocation,
		},
		ref,
	) => {
		const parseTimeToDate = (date) => {
			const parsedDate = moment(date, 'hh:00 A').toDate()
			return parsedDate
		}
		const dispatch = useDispatch()
		const { shipper, consignee } = useSelector((state) => state.shipments.createShipmentInfo)
		const [editItemData, setEditItemData] = useState({})
		const [openEditModal, setOpenEditModal] = useState(false)
		const [openModalLocation, setOpenModalLocation] = useState(false)
		const [zipCode, setZipCode] = useState('')
		const [typeLocation, setTypeLocation] = useState('')
		const [earliestPickUpTimeVal, setEarliestPickUpTimeVal] = useState(
			isPreviousClicked ? moment(parseTimeToDate(shipper.pickupEarliestTime)) : moment().set({ hour: 8, minute: 0 }),
		)
		const [latestPickUpTimeVal, setLatestPickUpTimeVal] = useState(
			isPreviousClicked ? moment(parseTimeToDate(shipper.pickupLatestTime)) : moment().set({ hour: 17, minute: 0 }),
		)
		const [earliestDeliveryTimeVal, setEarliestDeliveryTimeVal] = useState(
			isPreviousClicked ? moment(parseTimeToDate(consignee.dropOffEarliestTime)) : moment().set({ hour: 8, minute: 0 }),
		)
		const [latestDeliveryTimeVal, setLatestDeliveryTimeVal] = useState(
			isPreviousClicked ? moment(parseTimeToDate(consignee.dropOffLatestTime)) : moment().set({ hour: 17, minute: 0 }),
		)
		const [nmfcCode, setNmfcCode] = useState('')
		const [productDescription, setProductDescription] = useState('')
		const classes = useStyles()
		const handleOpen = (code, locationType) => {
			setZipCode(code)
			setOpenModalLocation(true)
			setTypeLocation(locationType)
		}
		useEffect(() => {
			if (selectedOriginLocation) {
				setEarliestPickUpTimeVal(
					selectedOriginLocation?.open ? moment(selectedOriginLocation.open) : moment().set({ hour: 8, minute: 0 }),
				)
				setLatestPickUpTimeVal(
					selectedOriginLocation?.close ? moment(selectedOriginLocation.close) : moment().set({ hour: 17, minute: 0 }),
				)
			}
			if (selectedDestinationLocation) {
				setEarliestDeliveryTimeVal(
					selectedDestinationLocation?.open
						? moment(selectedDestinationLocation.open)
						: moment().set({ hour: 8, minute: 0 }),
				)
				setLatestDeliveryTimeVal(
					selectedDestinationLocation?.close
						? moment(selectedDestinationLocation.close)
						: moment().set({ hour: 17, minute: 0 }),
				)
			}
		}, [selectedOriginLocation, selectedDestinationLocation])
		const handleClose = () => setOpenModalLocation(false)
		const openModalAndSetItemData = (item) => {
			setEditItemData(item)
			setNmfcCode(item.nmfc ? `${item.nmfc}${item.sub_nmfc ? `-${item.sub_nmfc}` : ''}` : '')
			setProductDescription(item.description ? item.description : '')
			setOpenEditModal(true)
		}

		const updateItem = (newItemData) => {
			const newItems = JSON.parse(JSON.stringify(items))
			newItems.filter((item, index) => {
				if (item.id === newItemData.id) {
					newItems[index].description = productDescription
					const nmfc = nmfcCode.substring(0, nmfcCode.indexOf('-'))
					if (nmfc) {
						newItems[index].nmfc = nmfc
					} else {
						newItems[index].nmfc = nmfcCode
					}
					newItems[index].sub_nmfc = nmfc ? nmfcCode.substring(nmfcCode.indexOf('-') + 1) : ''
					newItems[index].item_details = newItemData.item_details ? newItemData.item_details : []
				}
				return true
			})
			setShipmentItems(newItems)
			setOpenEditModal(false)
		}

		const handleUpdateLocation = async (event, origin, locationId) => {
			event.preventDefault()
			const isEdit = !!locationId
			const data = {}
			const [city, state] = getValues(`${origin}City`).split(', ')
			const [, respGeoCoder] = await fetchDataFromZipCode(getValues(`${origin}ZipCode`))
			const { longitude, latitude } = respGeoCoder[0]
			data.name = getValues(`${origin}Company`)
			if (!isEdit) {
				data.type = null
				data.locationAccessorials = origin === 'pickUp' ? shipperData.accessorialIds : consigneeData.accessorialIds
			}
			data.longitude = longitude
			data.latitude = latitude
			data.address = getValues(`${origin}AddressOne`)
			data.address2 = getValues(`${origin}AddressTwo`)
			data.city = city
			data.state = state
			data.zip_code = getValues(`${origin}ZipCode`)
			data.country = getValues(`${origin}Country`)
			data.contact = getValues(`${origin}ContactName`)
			data.phone = getValues(`${origin}ContactPhone`).replace(/\D+/g, '')
			data.email = getValues(`${origin}ContactEmail`)
			data.location_id = isEdit ? locationId.toString() : ''
			data.send_email = false
			data.send_sms = false
			if (origin === 'pickUp') {
				data.open_time = earliestPickUpTimeVal
					? moment(earliestPickUpTimeVal).format('hh:00 A')
					: moment().set({ hour: 8, minute: 0 })
				data.close_time = latestPickUpTimeVal
					? moment(latestPickUpTimeVal).format('hh:00 A')
					: moment().set({ hour: 17, minute: 0 })
			} else {
				data.open_time = earliestDeliveryTimeVal
					? moment(earliestDeliveryTimeVal).format('hh:00 A')
					: moment().set({ hour: 8, minute: 0 })
				data.close_time = latestDeliveryTimeVal
					? moment(latestDeliveryTimeVal).format('hh:00 A')
					: moment().set({ hour: 17, minute: 0 })
			}
			const { status, resp } = isEdit
				? await dispatch(editLocation(locationId, data))
				: await dispatch(createLocation(data))
			if (status === 'error') {
				sweetAlertModal(
					status,
					isEdit
						? 'There was an error in the update location process!'
						: 'There was an error in the save location process!',
					resp,
					'Ok',
					true,
					false,
					null,
				)
			} else {
				sweetAlertModal(
					status,
					isEdit ? 'Location has been updated successfully!' : 'Location has been saved successfully!',
					null,
					'Ok',
					true,
					false,
					null,
				)
				if (!isEdit && origin === 'pickUp') {
					setDisableOriginLocationButton(true)
				} else if (!isEdit && origin === 'delivery') {
					setDisableDestinationLocationButton(true)
				}
			}
		}

		const dispatchSetReferences = (reference) => {
			dispatch(setTabReferences(reference))
		}

		return (
			<div className={classes.newShipmentContainer}>
				<div className={classes.newShipmentContainerInformation}>
					<div className={classes.newShipmentInformationSection}>
						<div className={classes.newShipmentIconTitle}>
							<OriginIcon />
							<h5 className={classes.newShipmentTitle}>Origin Information</h5>
						</div>
						<div>
							<h4 className={classes.newShipmentSubtitle}>Pickup Instructions</h4>
							<div className={classes.newShipmentFormRow}>
								<div className={classes.newShipmentDatePicker}>
									<RangeDatePicker valueDate={consigneeData.pickUpDate} label="Pickup Date" isDisabled />
								</div>
								<div className={classes.newShipmentEarliestTimePicker}>
									<TextField
										name="earliestPickupTime"
										className={classes.root}
										type="text"
										label="Open *"
										variant="outlined"
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											inputComponent: () => (
												<div>
													<TimePicker
														name="earliestPickupTime"
														placeholder="Earliest Pickup Time"
														defaultValue={earliestPickUpTimeVal}
														value={earliestPickUpTimeVal}
														onChange={(value) => {
															if (value) {
																setEarliestPickUpTimeVal(value)
																setEarliestPickUpTime(moment(value).format('hh:mm A'))
															} else {
																setEarliestPickUpTimeVal('')
																setEarliestPickUpTime('')
															}
														}}
														className={classes.timePicker}
														popupClassName={classes.popupTimePicker}
														showSecond={false}
														use12Hours
														minuteStep={30}
														focusOnOpen
													/>
												</div>
											),
										}}
									/>
								</div>
								<div className={classes.newShipmentLatestTimePicker}>
									<TextField
										name="latestPickupTime"
										className={classes.root}
										type="text"
										label="Close *"
										variant="outlined"
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											inputComponent: () => (
												<div>
													<TimePicker
														name="latestPickupTime"
														defaultValue={latestPickUpTimeVal}
														value={latestPickUpTimeVal}
														onChange={(value) => {
															if (value) {
																setLatestPickUpTimeVal(value)
																setLatestPickUpTime(moment(value).format('hh:mm A'))
															} else {
																setLatestPickUpTimeVal('')
																setLatestPickUpTime('')
															}
														}}
														placeholder="Latest Pickup Time"
														className={classes.timePicker}
														popupClassName={classes.popupTimePicker}
														showSecond={false}
														use12Hours
														minuteStep={30}
														focusOnOpen
													/>
												</div>
											),
										}}
									/>
								</div>
							</div>
							<div className={classes.newShipmentFormItems}>
								<TextField
									name="pickUpComments"
									className={classes.root}
									size="small"
									inputRef={register({
										maxLength: { value: 100, message: 'Maximum 100 characters allowed' },
									})}
									error={!!errors.pickUpComments}
									helperText={errors.pickUpComments ? errors.pickUpComments.message : null}
									FormHelperTextProps={{ classes: { root: classes.helperText } }}
									label="Comments"
									variant="outlined"
									style={{ width: '100%' }}
								/>
							</div>
							<div className={classes.newShipmentFormItems} style={{ fontSize: '16px' }}>
								<span className={classes.newShipmentSubtitleBold}>Accessorials required:&nbsp;</span>
								<span>{shipperData.accessorialsNames}</span>
							</div>
						</div>
						<div className={classes.newShipmentForm}>
							<div className={classes.newShipmentSubtitleForm}>
								<h4 className={classes.newShipmentSubtitle}>Address</h4>
								<FormLabel display="flex" className={classes.triggerButton}>
									<SearchIcon
										className={classes.cursorPointer}
										onClick={() => handleOpen(getValues('pickUpZipCode'), 'origin')}
									/>
									<span
										onClick={() => handleOpen(getValues('pickUpZipCode'), 'origin')}
										onKeyPress={() => {}}
										role="button"
										tabIndex="0"
										className={classes.cursorPointer}
									>
										Search saved locations
									</span>
								</FormLabel>
							</div>
							<div className={classes.newShipmentFormItems}>
								<TextField
									name="pickUpCompany"
									className={classes.root}
									size="small"
									inputRef={register({
										required: { value: true, message: 'This value is required' },
										maxLength: { value: 100, message: 'Maximum 100 characters allowed' },
									})}
									error={!!errors.pickUpCompany}
									helperText={errors.pickUpCompany ? errors.pickUpCompany.message : null}
									FormHelperTextProps={{ classes: { root: classes.helperText } }}
									label="Company Name *"
									variant="outlined"
									style={{ width: '100%' }}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</div>
							<div className={classes.newShipmentFormRow}>
								<TextField
									name="pickUpAddressOne"
									size="small"
									className={`${classes.root} ${classes.newShipmentAddressOne}`}
									inputRef={register({
										required: { value: true, message: 'This value is required' },
										maxLength: { value: 100, message: 'Maximum 100 characters allowed' },
									})}
									error={!!errors.pickUpAddressOne}
									helperText={errors.pickUpAddressOne ? errors.pickUpAddressOne.message : null}
									FormHelperTextProps={{ classes: { root: classes.helperText } }}
									label="Address Line 1 *"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
								/>
								<TextField
									name="pickUpAddressTwo"
									size="small"
									className={`${classes.root} ${classes.newShipmentAddressTwo}`}
									inputRef={register({
										maxLength: { value: 100, message: 'Maximum 100 characters allowed' },
									})}
									error={!!errors.pickUpAddressTwo}
									helperText={errors.pickUpAddressTwo ? errors.pickUpAddressTwo.message : null}
									FormHelperTextProps={{ classes: { root: classes.helperText } }}
									label="Address Line 2"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</div>
							<div className={classes.newShipmentFormItems}>
								<TextField
									name="pickUpCity"
									className={`${classes.root} ${classes.newShipmentCityField}`}
									disabled
									size="small"
									inputRef={register({
										maxLength: { value: 100, message: 'Maximum 100 characters allowed' },
									})}
									error={!!errors.pickUpCity}
									helperText={errors.pickUpCity ? errors.pickUpCity.message : null}
									FormHelperTextProps={{ classes: { root: classes.helperText } }}
									label="City/State"
									variant="outlined"
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<RoomOutlinedIcon className={classes.icon} />
											</InputAdornment>
										),
									}}
								/>
								<TextField
									name="pickUpZipCode"
									className={`${classes.root} ${classes.newShipmentZipCode}`}
									disabled
									size="small"
									inputRef={register({
										minLength: 5,
										maxLength: 7,
									})}
									error={!!errors.pickUpZipCode}
									helperText={errors.pickUpZipCode ? errors.pickUpZipCode.message : null}
									FormHelperTextProps={{ classes: { root: classes.helperText } }}
									label="Zip Code"
									variant="outlined"
								/>
								<TextField
									name="pickUpCountry"
									className={`${classes.root} ${classes.newShipmentInputOption}`}
									disabled
									size="small"
									inputRef={register({
										maxLength: { value: 100, message: 'Maximum 100 characters allowed' },
									})}
									error={!!errors.pickUpCountry}
									helperText={errors.pickUpCountry ? errors.pickUpCountry.message : null}
									FormHelperTextProps={{ classes: { root: classes.helperText } }}
									label="Country"
									variant="outlined"
									InputProps={{
										startAdornment: (
											<InputAdornment>
												{getValues('pickUpCountry') === 'CAN' ? (
													<img
														className="new-shipment-country-flag"
														src="https://i.postimg.cc/23RwvZxT/canadian-flag.jpg"
														border="0"
														alt="flag"
													/>
												) : (
													<img
														className="new-shipment-country-flag"
														src="https://i.postimg.cc/cCQwpqXN/flag-removebg-preview.png"
														border="0"
														alt="flag"
													/>
												)}
											</InputAdornment>
										),
									}}
								/>
							</div>
							<div className={classes.newShipmentFormItems}>
								<TextField
									name="pickUpContactName"
									size="small"
									className={`${classes.root} ${classes.newShipmentContactName}`}
									inputRef={register({
										required: { value: true, message: 'This value is required' },
										maxLength: { value: 100, message: 'Maximum 100 characters allowed' },
									})}
									error={!!errors.pickUpContactName}
									helperText={errors.pickUpContactName ? errors.pickUpContactName.message : null}
									FormHelperTextProps={{ classes: { root: classes.helperText } }}
									label="Contact Name *"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
								/>
								<Controller
									as={
										<NumberFormat
											customInput={TextField}
											name="pickUpContactPhone"
											format="(###) ###-####"
											mask="_"
											onValueChange={(v) => setPickUpContactPhone(v.value)}
										/>
									}
									name="pickUpContactPhone"
									type="text"
									size="small"
									className={`${classes.root} ${classes.newShipmentContactPhone}`}
									defaultValue={pickUpContactPhone}
									control={control}
									rules={{
										pattern: {
											value: /^([(]([0-9]{3})[)] ([0-9]{3})(-)([0-9]{4}))$/,
											message: 'Please enter a valid phone number',
										},
										required: { value: true, message: 'This value is required' },
									}}
									error={!!errors.pickUpContactPhone}
									helperText={errors.pickUpContactPhone ? errors.pickUpContactPhone.message : null}
									FormHelperTextProps={{ classes: { root: classes.helperText } }}
									label="Contact Phone *"
									variant="outlined"
								/>
								<TextField
									name="pickUpContactPhoneExtension"
									size="small"
									className={`${classes.root} ${classes.newShipmentContactExtension}`}
									inputRef={register({
										maxLength: { value: 5, message: 'Maximum 5 characters allowed' },
									})}
									error={!!errors.pickUpContactPhoneExtension}
									helperText={errors.pickUpContactPhoneExtension ? errors.pickUpContactPhoneExtension.message : null}
									FormHelperTextProps={{ classes: { root: classes.helperText } }}
									label="Extension"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</div>
							<div className={classes.newShipmentFormRow}>
								<TextField
									name="pickUpContactEmail"
									size="small"
									className={`${classes.root} ${classes.newShipmentContactEmail}`}
									inputRef={register({
										maxLength: { value: 100, message: 'Maximum 100 characters allowed' },
										pattern: {
											value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
											message: 'Enter a valid e-mail address',
										},
									})}
									error={!!errors.pickUpContactEmail}
									helperText={errors.pickUpContactEmail ? errors.pickUpContactEmail.message : null}
									FormHelperTextProps={{ classes: { root: classes.helperText } }}
									label="Contact Email"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
								/>
								<Button
									classes={{
										root: classes.newShipmentTextButton,
										startIcon: classes.newShipmentIcon,
									}}
									disabled={disableOriginLocationButton}
									onClick={(event) => handleUpdateLocation(event, 'pickUp', shipperData.locationId)}
									startIcon={<SaveIconOutlined />}
									size="small"
								>
									{shipperData.locationId ? 'Edit this location' : 'Save new location'}
								</Button>
							</div>
						</div>
					</div>
					<div className={classes.newShipmentInformationSection}>
						<div className={classes.newShipmentIconTitle}>
							<DestinationIcon />
							<h5 className={classes.newShipmentTitle}>Destination Information</h5>
						</div>
						<div>
							<h4 className={classes.newShipmentSubtitle}>Delivery Instructions</h4>
							<div className={classes.newShipmentFormRow}>
								<div className={classes.newShipmentDatePicker}>
									<RangeDatePicker
										valueDate={removeZDate(shipperData.dropOffDate)}
										label="Estimated Delivery Date"
										isDisabled
									/>
								</div>
								<div className={classes.newShipmentEarliestTimePicker}>
									<TextField
										name="earliestDeliveryTime"
										className={classes.root}
										type="text"
										label="Open *"
										variant="outlined"
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											inputComponent: () => (
												<div>
													<TimePicker
														name="earliestDeliveryTime"
														defaultValue={earliestDeliveryTimeVal}
														value={earliestDeliveryTimeVal}
														onChange={(value) => {
															if (value) {
																setEarliestDeliveryTimeVal(value)
																setEarliestDeliveryTime(moment(value).format('hh:mm A'))
															} else {
																setEarliestDeliveryTimeVal('')
																setEarliestDeliveryTime('')
															}
														}}
														placeholder="Earliest Delivery Time"
														className={classes.timePicker}
														popupClassName={classes.popupTimePicker}
														showSecond={false}
														use12Hours
														minuteStep={30}
														focusOnOpen
													/>
												</div>
											),
										}}
									/>
								</div>
								<div className={classes.newShipmentLatestTimePicker}>
									<TextField
										name="latestDeliveryTime"
										className={classes.root}
										type="text"
										label="Close *"
										variant="outlined"
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											inputComponent: () => (
												<div>
													<TimePicker
														name="latestDeliveryTime"
														defaultValue={latestDeliveryTimeVal}
														value={latestDeliveryTimeVal}
														onChange={(value) => {
															if (value) {
																setLatestDeliveryTimeVal(value)
																setLatestDeliveryTime(moment(value).format('hh:mm A'))
															} else {
																setLatestDeliveryTimeVal('')
																setLatestDeliveryTime('')
															}
														}}
														placeholder="Latest Delivery Time"
														className={classes.timePicker}
														popupClassName={classes.popupTimePicker}
														showSecond={false}
														use12Hours
														minuteStep={30}
														focusOnOpen
													/>
												</div>
											),
										}}
									/>
								</div>
							</div>
							<div className={classes.newShipmentFormItems}>
								<TextField
									name="deliveryComments"
									className={classes.root}
									size="small"
									inputRef={register({
										maxLength: { value: 100, message: 'Maximum 100 characters allowed' },
									})}
									error={!!errors.deliveryComments}
									helperText={errors.deliveryComments ? errors.deliveryComments.message : null}
									FormHelperTextProps={{ classes: { root: classes.helperText } }}
									label="Comments"
									variant="outlined"
									style={{ width: '100%' }}
								/>
							</div>
							<div className={classes.newShipmentFormItems} style={{ fontSize: '16px' }}>
								<span className={classes.newShipmentSubtitleBold}>Accessorials required:&nbsp;</span>
								<span>{consigneeData.accessorialsNames}</span>
							</div>
						</div>
						<div className={classes.newShipmentForm}>
							<div className={classes.newShipmentSubtitleForm}>
								<h4 className={classes.newShipmentSubtitle}>Address</h4>
								<FormLabel display="flex" className={classes.triggerButton}>
									<SearchIcon
										className={classes.cursorPointer}
										onClick={() => handleOpen(getValues('deliveryZipCode'), 'destination')}
									/>
									<span
										onClick={() => handleOpen(getValues('deliveryZipCode'), 'destination')}
										onKeyPress={() => {}}
										role="button"
										tabIndex="0"
										className={classes.cursorPointer}
									>
										Search saved locations
									</span>
								</FormLabel>
							</div>
							<div className={classes.newShipmentFormItems}>
								<TextField
									name="deliveryCompany"
									size="small"
									className={classes.root}
									inputRef={register({
										required: { value: true, message: 'This value is required' },
										maxLength: { value: 100, message: 'Maximum 100 characters allowed' },
									})}
									error={!!errors.deliveryCompany}
									helperText={errors.deliveryCompany ? errors.deliveryCompany.message : null}
									FormHelperTextProps={{ classes: { root: classes.helperText } }}
									label="Company Name *"
									variant="outlined"
									style={{ width: '100%' }}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</div>
							<div className={classes.newShipmentFormRow}>
								<TextField
									name="deliveryAddressOne"
									size="small"
									className={`${classes.root} ${classes.newShipmentAddressOne}`}
									inputRef={register({
										required: { value: true, message: 'This value is required' },
										maxLength: { value: 100, message: 'Maximum 100 characters allowed' },
									})}
									error={!!errors.deliveryAddressOne}
									helperText={errors.deliveryAddressOne ? errors.deliveryAddressOne.message : null}
									FormHelperTextProps={{ classes: { root: classes.helperText } }}
									label="Address Line 1 *"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
								/>
								<TextField
									name="deliveryAddressTwo"
									size="small"
									className={`${classes.root} ${classes.newShipmentAddressTwo}`}
									inputRef={register({
										maxLength: { value: 100, message: 'Maximum 100 characters allowed' },
									})}
									error={!!errors.deliveryAddressTwo}
									helperText={errors.deliveryAddressTwo ? errors.deliveryAddressTwo.message : null}
									FormHelperTextProps={{ classes: { root: classes.helperText } }}
									label="Address Line 2"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</div>
							<div className={classes.newShipmentFormItems}>
								<TextField
									name="deliveryCity"
									className={`${classes.root} ${classes.newShipmentCityField}`}
									disabled
									size="small"
									inputRef={register({
										maxLength: { value: 100, message: 'Maximum 100 characters allowed' },
									})}
									error={!!errors.deliveryCity}
									helperText={errors.deliveryCity ? errors.deliveryCity.message : null}
									FormHelperTextProps={{ classes: { root: classes.helperText } }}
									label="City/State"
									variant="outlined"
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<RoomOutlinedIcon className={classes.icon} />
											</InputAdornment>
										),
									}}
								/>
								<TextField
									name="deliveryZipCode"
									className={`${classes.root} ${classes.newShipmentZipCode}`}
									disabled
									size="small"
									inputRef={register({
										minLength: 5,
										maxLength: 7,
									})}
									error={!!errors.deliveryZipCode}
									helperText={errors.deliveryZipCode ? errors.deliveryZipCode.message : null}
									FormHelperTextProps={{ classes: { root: classes.helperText } }}
									label="Zip Code"
									variant="outlined"
								/>
								<TextField
									name="deliveryCountry"
									className={`${classes.root} ${classes.newShipmentInputOption}`}
									disabled
									size="small"
									inputRef={register({
										maxLength: { value: 100, message: 'Maximum 100 characters allowed' },
									})}
									error={!!errors.deliveryCountry}
									helperText={errors.deliveryCountry ? errors.deliveryCountry.message : null}
									FormHelperTextProps={{ classes: { root: classes.helperText } }}
									label="Country"
									variant="outlined"
									InputProps={{
										startAdornment: (
											<InputAdornment>
												{getValues('deliveryCountry') === 'CAN' ? (
													<img
														className="new-shipment-country-flag"
														src="https://i.postimg.cc/23RwvZxT/canadian-flag.jpg"
														border="0"
														alt="flag"
													/>
												) : (
													<img
														className="new-shipment-country-flag"
														src="https://i.postimg.cc/cCQwpqXN/flag-removebg-preview.png"
														border="0"
														alt="flag"
													/>
												)}
											</InputAdornment>
										),
									}}
								/>
							</div>
							<div className={classes.newShipmentFormItems}>
								<TextField
									name="deliveryContactName"
									size="small"
									className={`${classes.root} ${classes.newShipmentContactName}`}
									inputRef={register({
										required: { value: true, message: 'This value is required' },
										maxLength: { value: 100, message: 'Maximum 100 characters allowed' },
									})}
									error={!!errors.deliveryContactName}
									helperText={errors.deliveryContactName ? errors.deliveryContactName.message : null}
									FormHelperTextProps={{ classes: { root: classes.helperText } }}
									label="Contact Name *"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
								/>
								<Controller
									as={
										<NumberFormat
											customInput={TextField}
											name="deliveryContactPhone"
											format="(###) ###-####"
											mask="_"
											onValueChange={(v) => setDeliveryContactPhone(v.value)}
										/>
									}
									name="deliveryContactPhone"
									type="text"
									size="small"
									className={`${classes.root} ${classes.newShipmentContactPhone}`}
									defaultValue={deliveryContactPhone}
									control={control}
									rules={{
										pattern: {
											value: /^([(]([0-9]{3})[)] ([0-9]{3})(-)([0-9]{4}))$/,
											message: 'Please enter a valid phone number',
										},
										required: { value: true, message: 'This value is required' },
									}}
									error={!!errors.deliveryContactPhone}
									helperText={errors.deliveryContactPhone ? errors.deliveryContactPhone.message : null}
									FormHelperTextProps={{ classes: { root: classes.helperText } }}
									label="Contact Phone *"
									variant="outlined"
								/>
								<TextField
									name="deliveryContactPhoneExtension"
									size="small"
									className={`${classes.root} ${classes.newShipmentContactExtension}`}
									inputRef={register({
										maxLength: { value: 5, message: 'Maximum 5 characters allowed' },
									})}
									error={!!errors.deliveryContactPhoneExtension}
									helperText={
										errors.deliveryContactPhoneExtension ? errors.deliveryContactPhoneExtension.message : null
									}
									FormHelperTextProps={{ classes: { root: classes.helperText } }}
									label="Extension"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</div>
							<div className={classes.newShipmentFormRow}>
								<TextField
									name="deliveryContactEmail"
									size="small"
									className={`${classes.root} ${classes.newShipmentContactEmail}`}
									inputRef={register({
										maxLength: { value: 100, message: 'Maximum 100 characters allowed' },
										pattern: {
											value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
											message: 'Enter a valid e-mail address',
										},
									})}
									error={!!errors.deliveryContactEmail}
									helperText={errors.deliveryContactEmail ? errors.deliveryContactEmail.message : null}
									FormHelperTextProps={{ classes: { root: classes.helperText } }}
									label="Contact Email"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
								/>
								<Button
									classes={{
										root: classes.newShipmentTextButton,
										startIcon: classes.newShipmentIcon,
									}}
									disabled={disableDestinationLocationButton}
									onClick={(event) => handleUpdateLocation(event, 'delivery', consigneeData.locationId)}
									startIcon={<SaveIconOutlined />}
									size="small"
								>
									{consigneeData.locationId ? 'Edit this location' : 'Save new location'}
								</Button>
							</div>
						</div>
					</div>
				</div>
				<div className={classes.newShipmentContainerShipmentItems}>
					<div className={classes.newShipmentIconTitle}>
						<ShipmentItemsIcon />
						<h5 className={classes.newShipmentTitle}>Shipment Items</h5>
					</div>
					<div className={classes.newShipmentColumn}>
						<div className={classes.newShipmentItemsDetails}>
							<ShipmentItemsDetails shipmentItems={items} setEditItemData={openModalAndSetItemData} />
						</div>
						<div className={classes.newShipmentItemsSlider}>
							<ShipmentItemsSliderNew items={items} setEditItemData={openModalAndSetItemData} />
						</div>
						<div className={classes.newShipmentFormItemRow}>
							<p className={classes.newShipmentFormParagraph}>
								<span className={classes.newShipmentSubtitleBold}>Total # Items:&nbsp;</span>
								<span>{`${calculateTotalItems(items)}`}</span>
							</p>
							<p className={classes.newShipmentFormParagraph}>
								<span className={classes.newShipmentSubtitleBold}>Total Weight:&nbsp;</span>
								<span>{`${calculateTotalWeight(items)} lbs`}</span>
							</p>
							<p className={classes.newShipmentFormParagraph}>
								<span className={classes.newShipmentSubtitleBold}>Total Linear Feet:&nbsp;</span>
								<span>{linearFeet}</span>
							</p>
							<p className={classes.newShipmentFormParagraph}>
								<span className={classes.newShipmentSubtitleBold}>Total Cubic Feet:&nbsp;</span>
								<span>{calculateTotalCubicFeet(items)}</span>
							</p>
							<p className={classes.newShipmentFormParagraph}>
								<span className={classes.newShipmentSubtitleBold}>Total Density:&nbsp;</span>
								<span>{calculateTotalDensity(items)}</span>
							</p>
						</div>
					</div>
				</div>
				<div className={classes.newShipmentContainerReferences}>
					<div className={classes.newShipmentInformationSection}>
						<div className={classes.newShipmentIconTitle}>
							<AssignmentOutlinedIcon className={classes.newShipmentMenuIcon} color="primary" alt="References" />
							<h5 className={classes.newShipmentTitle}>References</h5>
						</div>
						<div>
							<ReferencesForm
								previousReferences={references}
								setReferencesAdded={setReferencesAdded}
								dispatchSetReferences={dispatchSetReferences}
								ref={ref}
							/>
						</div>
					</div>
					<div className={classes.newShipmentInformationSection}>
						<div className={classes.newShipmentIconTitle}>
							<SpecialInstructionsIcon />
							<h5 className={classes.newShipmentTitle}>Special Instructions for Carrier</h5>
						</div>
						<div className={classes.newShipmentFormCenter}>
							<Controller
								control={control}
								name="specialInstructions"
								defaultValue=""
								render={({ value, onChange }) =>
									addInstructions ? (
										<TextField
											size="small"
											className={`${classes.root} ${classes.newShipmentButton}`}
											value={value}
											onChange={onChange}
											error={!!errors.specialInstructions}
											helperText={errors?.specialInstructions?.message}
											FormHelperTextProps={{ classes: { root: classes.helperText } }}
											variant="outlined"
											multiline
											minRows={4}
										/>
									) : (
										<>
											<span className={classes.newShipmentGrayText}>
												Enter some instructions to facilitate the delivery of the shipment
											</span>
											<Button onClick={() => setAddInstructions(true)} className={classes.newShipmentBlackText}>
												<AddIcon className={classes.newShipmentIcon} />
												Add Instructions here
											</Button>
										</>
									)
								}
								rules={{
									required: { value: addInstructions, message: 'This value is required' },
									maxLength: { value: 800, message: 'Maximum 800 characters allowed' },
								}}
							/>
						</div>
					</div>
				</div>
				<SuggestionsModalZipCode
					open={openModalLocation}
					onClose={handleClose}
					zipCode={zipCode}
					type={typeLocation}
					updateLocationFromModal={updateLocationFromModal}
				/>
				<EditItemModal
					isOpen={openEditModal}
					setIsOpen={setOpenEditModal}
					itemData={editItemData}
					updateItem={updateItem}
					setNmfcCode={setNmfcCode}
					setProductDescription={setProductDescription}
					nmfcCode={nmfcCode}
				/>
			</div>
		)
	},
)

NewQuoteShipmentStepThree.propTypes = {
	errors: PropTypes.func.isRequired,
	register: PropTypes.func.isRequired,
	shipperData: PropTypes.objectOf(PropTypes.any).isRequired,
	consigneeData: PropTypes.objectOf(PropTypes.any).isRequired,
	items: PropTypes.arrayOf(PropTypes.any).isRequired,
	setShipmentItems: PropTypes.func.isRequired,
	references: PropTypes.arrayOf(PropTypes.any).isRequired,
	setReferencesAdded: PropTypes.func.isRequired,
	setEarliestDeliveryTime: PropTypes.func.isRequired,
	setEarliestPickUpTime: PropTypes.func.isRequired,
	setLatestDeliveryTime: PropTypes.func.isRequired,
	setLatestPickUpTime: PropTypes.func.isRequired,
	disableOriginLocationButton: PropTypes.bool.isRequired,
	setDisableOriginLocationButton: PropTypes.func.isRequired,
	disableDestinationLocationButton: PropTypes.bool.isRequired,
	setDisableDestinationLocationButton: PropTypes.func.isRequired,
	isPreviousClicked: PropTypes.bool.isRequired,
	addInstructions: PropTypes.bool.isRequired,
	setAddInstructions: PropTypes.func.isRequired,
	getValues: PropTypes.func.isRequired,
	control: PropTypes.func.isRequired,
	pickUpContactPhone: PropTypes.string.isRequired,
	setPickUpContactPhone: PropTypes.func.isRequired,
	deliveryContactPhone: PropTypes.string.isRequired,
	setDeliveryContactPhone: PropTypes.func.isRequired,
	updateLocationFromModal: PropTypes.func.isRequired,
	linearFeet: PropTypes.string.isRequired,
	selectedOriginLocation: PropTypes.objectOf(PropTypes.any).isRequired,
	selectedDestinationLocation: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default NewQuoteShipmentStepThree
