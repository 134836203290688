import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	cardHeader: {
		alignItems: 'flex-start',
		padding: theme.spacing(4, 4, 0),
	},
	cardHeaderTitle: {
		color: theme.palette.neutral.black,
		fontSize: 17,
		fontWeight: theme.typography.fontWeightBold,
	},
	cardHeaderAction: {
		margin: 0,
		'& .MuiSvgIcon-root': {
			fontSize: 16,
			[theme.breakpoints.up('md')]: {
				fontSize: 24,
			},
		},
	},
	cardHeaderActionButton: {
		color: theme.palette.neutral.darkGray,
	},
	cardMedia: {
		padding: theme.spacing(0, 3, 3),
	},
	chartWrapper: {
		height: 267,
	},
	formControl: {
		maxWidth: '100%',
		width: 389,
		marginTop: theme.spacing(2),
	},
}))

export default useStyles
