import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	tableRow: {
		height: 60,
		'&.Mui-selected, &.Mui-selected.MuiTableRow-hover': {
			backgroundColor: theme.palette.neutral.secondaryGrey,
		},
	},
	tableCell: {
		height: 'auto',
		padding: '0 16px',
		fontWeight: theme.typography.fontWeightRegular,
	},
	status: {
		fontSize: 11,
		fontWeight: theme.typography.fontWeightRegular,
		color: theme.palette.neutral.white,
		padding: '0 14px',
	},
	statusPurple: {
		backgroundColor: theme.palette.semantic.purple.light,
		color: theme.palette.semantic.purple.primary,
	},
	statusRed: {
		backgroundColor: theme.palette.error.main,
	},
	statusOrange: {
		backgroundColor: theme.palette.warning.light,
		color: theme.palette.warning.dark,
	},
	statusGreen: {
		color: theme.palette.success.main,
		backgroundColor: theme.palette.success.light,
	},
	linkPointer: {
		cursor: 'pointer',
		borderBottom: '1px solid',
		borderRadius: '0',
		fontSize: '14px',
		padding: '0',
	},
}))

export default useStyles
