import React from 'react'
import PropTypes from 'prop-types'

import { TextField } from '@material-ui/core'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'

import { useSelector } from 'react-redux'

import useStyles from '../../pages/styles'

const SpecialInstructions = (props) => {
	const { register, errors, isPreviousClicked } = props
	const createShipmentInfo = useSelector((state) => state.shipments.createShipmentInfo)
	const classes = useStyles()

	return (
		<div className="new-shipment-item-container">
			<div className="new-shipment-icon-title">
				<AssignmentOutlinedIcon className={classes.mainIcon} color="primary" />
				<h3 className="new-shipment-item-title">Special Instructions</h3>
			</div>
			<div className="new-shipment-inputs" id="new-shipment-inputs-row-3">
				<TextField
					InputLabelProps={{ classes: { root: classes.textFieldLabel } }}
					name="specialInstructionsOrigin"
					type="text"
					label="Origin"
					variant="outlined"
					defaultValue={isPreviousClicked ? createShipmentInfo.specialIntructions.origin : null}
					inputRef={register({ maxLength: { value: 120, message: 'Maximum 120 characters allowed' } })}
					error={!!errors.specialInstructionsOrigin}
					helperText={errors.specialInstructionsOrigin ? errors.specialInstructionsOrigin.message : null}
					FormHelperTextProps={{ classes: { root: classes.helperText } }}
				/>
				<TextField
					InputLabelProps={{ classes: { root: classes.textFieldLabel } }}
					name="specialInstructionsDestination"
					type="text"
					label="Destination"
					variant="outlined"
					defaultValue={isPreviousClicked ? createShipmentInfo.specialIntructions.destination : null}
					inputRef={register({ maxLength: { value: 120, message: 'Maximum 120 characters allowed' } })}
					error={!!errors.specialInstructionsDestination}
					helperText={errors.specialInstructionsDestination ? errors.specialInstructionsDestination.message : null}
					FormHelperTextProps={{ classes: { root: classes.helperText } }}
				/>
				<TextField
					InputLabelProps={{ classes: { root: classes.textFieldLabel } }}
					name="specialInstructionsComments"
					type="text"
					label="Comments"
					variant="outlined"
					multiline
					rows={2}
					defaultValue={isPreviousClicked ? createShipmentInfo.specialIntructions.comments : null}
					inputRef={register({ maxLength: { value: 120, message: 'Maximum 120 characters allowed' } })}
					error={!!errors.specialInstructionsComments}
					helperText={errors.specialInstructionsComments ? errors.specialInstructionsComments.message : null}
					FormHelperTextProps={{ classes: { root: classes.helperText } }}
				/>
			</div>
		</div>
	)
}

SpecialInstructions.propTypes = {
	register: PropTypes.func.isRequired,
	errors: PropTypes.objectOf(PropTypes.any).isRequired,
	isPreviousClicked: PropTypes.bool.isRequired,
}

export default SpecialInstructions
