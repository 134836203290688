import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
	accountInformationContainer: {
		backgroundColor: theme.palette.neutral.white,
		overflowX: 'hidden',
	},
	accountBasicInformationContainer: {
		margin: '0px 0px 0px -24px',
		padding: '16px 24px 16px 48px',
		display: 'flex',
		flexDirection: 'column',
	},
	accountBasicInformationContentContainer: {
		padding: '12px 16px 16px 16px',
		'& .MuiGrid-item': {
			paddingBottom: 0,
		},
		[theme.breakpoints.down('md')]: {
			padding: '12px 0px 0px 0px',
		},
	},
	accountBasicInformationTitle: {
		width: '100%',
		margin: '0',
		...theme.typography.body2,
		fontWeight: theme.typography.fontWeightLight,
		color: theme.palette.primary.light,
		'& span': {
			...theme.typography.body1,
			marginRight: '5px',
			color: theme.palette.primary.dark,
			fontWeight: theme.typography.fontWeightRegular,
		},
	},
	accountBasicInformationContent: {
		padding: '0px',
	},
	accountBasicInformationTitleContainer: {
		display: 'flex',
		columnGap: '12px',
		alignItems: 'center',
		[theme.breakpoints.down('md')]: {
			alignItems: 'center',
		},
	},
	accountBasicInformationIconContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '28px',
		height: '28px',
		borderRadius: '20px',
		backgroundColor: 'transparent',
		color: theme.palette.primary.main,
	},
	accountBasicInformationSectionTitle: {
		...theme.typography.subtitle1,
		color: theme.palette.primary.main,
		margin: 0,
		[theme.breakpoints.down('md')]: {
			fontSize: '15px',
		},
	},
	childAccountsInformationContainer: {
		margin: '0px 0px 0px -24px',
		padding: '16px 24px 16px 48px',
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: '#F7F8F8',
	},
	generalIcon: {
		width: '20px',
		height: '20px',
	},
	desktopTable: {
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},
	tableContainer: {
		margin: '20px 0px 30px 0px',
		maxHeight: '125px',
		'&::-webkit-scrollbar': {
			width: '5px',
		},
		'&::-webkit-scrollbar-track': {
			'-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
			borderRadius: '10px',
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: '10px',
			'-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.5)',
		},
	},
	table: {
		width: '97%',
		marginLeft: '20px',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginBottom: '10px',
			minWidth: 100,
		},
		[theme.breakpoints.down('sm')]: {
			marginLeft: '0px',
		},
	},
	tableCell: {
		fontSize: '14px',
		fontWeight: theme.typography.fontWeightRegular,
		borderBottomStyle: 'none',
		padding: '0px',
	},
	tableCellHead: {
		borderBottomStyle: 'none',
		fontSize: '14px',
		fontWeight: theme.typography.fontWeightMedium,
	},
	childAccountInformationSectionTitle: {
		display: 'flex',
		paddingBottom: '16px',
		columnGap: '12px',
		marginBottom: '5px',
		alignItems: 'center',
	},
	childAccountInformationSectionIconContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '28px',
		height: '28px',
		borderRadius: '20px',
		backgroundColor: 'transparent',
		color: theme.palette.primary.main,
	},
	childAccountInformationSectionIcon: {
		width: '20px',
		height: '20px',
	},
	childAccountsMessageContainer: {
		margin: '0 0 20px 0',
		color: theme.palette.neutral.darkGray,
	},
	childAccountsMessageTitle: {
		textAlign: 'center',
		margin: '10px 0',
		fontSize: '17px',
		fontWeight: theme.typography.fontWeightBold,
		[theme.breakpoints.down('md')]: {
			fontSize: '14px',
		},
	},
	accountBasicInformationLogoContainer: {
		display: 'flex',
		alignItems: 'center',
		padding: '16px 24px 0px 16px',
		[theme.breakpoints.down('md')]: {
			padding: '12px 24px 0px 0px',
		},
	},
	avatar: {
		width: 50,
		height: 25,
		marginRight: 8,
		objectFit: 'contain',
		'&.MuiAvatar-root': {
			backgroundColor: 'transparent',
			color: theme.palette.neutral.black,
		},
	},
}))
