export const allowedClassValuesSorted = [
	'50',
	'55',
	'60',
	'65',
	'70',
	'77.5',
	'85',
	'92.5',
	'100',
	'110',
	'125',
	'150',
	'175',
	'200',
	'250',
	'300',
	'400',
	'500',
	'Spot Quotes',
	'LTL Quotes',
	'Used Items',
	'Machinery',
	'Household Goods',
	'Scraps',
	'Furniture',
]

export function classComparator(a, b) {
	let aIndex = allowedClassValuesSorted.indexOf(a.class)
	let bIndex = allowedClassValuesSorted.indexOf(b.class)
	if (aIndex === -1) {
		aIndex = allowedClassValuesSorted.length
	}
	if (bIndex === -1) {
		bIndex = allowedClassValuesSorted.length
	}
	return aIndex - bIndex
}

export default allowedClassValuesSorted
