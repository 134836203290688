import React, { useState, useEffect, useMemo } from 'react'

import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Avatar } from '@material-ui/core'

import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined'
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import { useTheme } from '@material-ui/core/styles'
import { fetchAccountSettings } from '../../../../../redux/actions/Accounts.actions'

import { InformationIcon, SpecialInstructionsIcon, LaneInfoIcon } from '../../../../../assets/Icons/Icons'

import { capitalizeFirstLetter, displayDollarValue } from '../../../../../utils/helpers'
import SweetAlertModal from '../../../../../components/SweetAlertModal'
import TableElements from '../../../newAccount/components/TableElements'
import TableReferences from '../../../newAccount/components/TableReferences'
import useStyles from './styles'

const AccountSettingsContainer = () => {
	const classes = useStyles()
	const { id } = useParams()
	const theme = useTheme()
	const dispatch = useDispatch()

	const [accountSettings, setAccountSettings] = useState({})

	useEffect(() => {
		const fetchData = async () => {
			const config = {
				headers: { 'X-Company-id': id },
			}
			const { status, resp } = await dispatch(fetchAccountSettings(config))
			if (status === 'success') {
				const { company, references, settings } = resp
				const newNotification = []
				if (settings.createShipmentNotification?.value) {
					const newElement = {}
					newElement.notification = 'Notify when someone creates a new shipment'
					newElement.emailAddress = settings.createShipmentNotification.value.split(',')
					newNotification.push(newElement)
				}
				if (settings.createQuoteNotification?.value) {
					const newElement = {}
					newElement.notification = 'Notify when someone creates a new quote'
					newElement.emailAddress = settings.createQuoteNotification.value.split(',')
					newNotification.push(newElement)
				}
				const newReferences = []
				references.forEach((element) => {
					const obj = {}
					obj.id = element.id
					obj.name = element.name
					obj.value = element.is_required ? 'true' : 'false'
					newReferences.push(obj)
				})
				const payload = {
					notifications: newNotification,
					references: newReferences,
					specialInstructionsCarrier: company.special_instructions_carrier,
					replacementRequired: settings.enableMaxReplacement?.value ?? null,
					maxReplacementValue: settings.maxReplacementValue?.value ?? null,
					typeAmount: settings.typeAmount?.value ?? null,
					typeAmountValue: settings.typeAmountValue?.value ?? null,
					shiplifyService: settings.enableShiplifyService?.value ?? null,
					customerPriority: settings.customerPriorityLevel?.value ?? null,
					paymentService: settings.enablePaymentService?.value ?? 'Visible',
					enableInsuranceOptIn: settings.enableInsuranceOptIn?.value ?? 'true',
				}
				setAccountSettings(payload)
			} else {
				SweetAlertModal('error', 'There was an error fetching the data', resp, 'Ok', true, false, null)
			}
		}
		fetchData()
	}, [dispatch, id])

	const typeAmountValue = useMemo(() => {
		const standardizedTypeAmount = accountSettings?.typeAmount?.value?.toUppercase()
		switch (standardizedTypeAmount) {
			case 'MARGIN':
				return `${accountSettings.typeAmountValue}%`
			case 'FLAT':
				return `${displayDollarValue(accountSettings.typeAmountValue)} USD`
			case 'ADMIN_FEE':
				return 'Admin Fee'
			default:
				return ''
		}
	}, [accountSettings.typeAmount, accountSettings.typeAmountValue])

	return (
		<>
			<div className={classes.newAccountSettingsContainer}>
				<div className={classes.newAccountSettingsNotification}>
					<div className={classes.newAccountSettingsTitle}>
						<Avatar className={classes.newAccountIconContainer}>
							<InformationIcon className={classes.newAccountIconContainerIcon} />
						</Avatar>
						<h3 className={classes.newAccountSettingsSectionTitle}>Notifications</h3>
					</div>
					<div className={classes.newAccountSettingsNotificationTable}>
						{accountSettings.notifications && (
							<TableElements previousElements={accountSettings.notifications} isDeletable={false} />
						)}
					</div>
				</div>
				<div className={classes.newAccountSettingsReferences}>
					<div className={classes.newAccountSettingsReferencesForm}>
						<div className={classes.newAccountSettingsTitle}>
							<Avatar className={classes.newAccountIconContainer}>
								<AssignmentOutlinedIcon className={classes.newAccountIconContainerIcon} />
							</Avatar>
							<h3 className={classes.newAccountSettingsSectionTitle}>References</h3>
						</div>
						<div className={classes.newAccountSettingsReferencesTable}>
							{accountSettings.references && (
								<TableReferences previousElements={accountSettings.references} isDeletable={false} />
							)}
						</div>
					</div>
					<div className={classes.newAccountSettingsInstructionsForm}>
						<div className={classes.newAccountSettingsTitle}>
							<Avatar className={classes.newAccountIconContainer}>
								<SpecialInstructionsIcon className={classes.newAccountIconContainerIcon} />
							</Avatar>
							<h3 className={classes.newAccountSettingsSectionTitle}>Special Instructions for Carrier</h3>
						</div>
						<div className={classes.newAccountSettingsSpecialInstructions}>
							{accountSettings.specialInstructionsCarrier}
						</div>
					</div>
				</div>
				<div className={classes.newAccountSettingsThirdParty}>
					<div className={classes.newAccountSettingsThirdPartyContainer}>
						<div className={classes.newAccountSettingsTitle}>
							<Avatar className={classes.newAccountIconContainer}>
								<VerifiedUserOutlinedIcon className={classes.newAccountIconContainerIcon} />
							</Avatar>
							<h3 className={classes.newAccountSettingsSectionTitle}>Third-Party Insurance</h3>
						</div>
						<div className={classes.newAccountSettingsInsuranceFormRow}>
							<span className={classes.newAccountSettingTitle}>
								Is it required for this account?:
								<span className={classes.newAccountSettingText}>
									{` ${
										accountSettings.replacementRequired
											? capitalizeFirstLetter(accountSettings.replacementRequired)
											: ''
									}`}
								</span>
							</span>
							<span className={classes.newAccountSettingTitle}>
								Maximum Replacement Value:
								<span className={classes.newAccountSettingText}>{` ${accountSettings.maxReplacementValue || ''}`}</span>
							</span>
							<span className={classes.newAccountSettingTitle}>
								Type of uplift:
								<span className={classes.newAccountSettingText}>
									{` ${
										accountSettings.typeAmount ? capitalizeFirstLetter(accountSettings.typeAmount.toLowerCase()) : ''
									}`}
								</span>
							</span>
							<span className={classes.newAccountSettingTitle}>
								Amount:
								<span className={classes.newAccountSettingText}>{` ${typeAmountValue}`}</span>
							</span>
							<span className={classes.newAccountSettingTitle}>
								Default insurance:
								<span className={classes.newAccountSettingText}>
									{` ${
										accountSettings.enableInsuranceOptIn
											? capitalizeFirstLetter(accountSettings.enableInsuranceOptIn)
											: ''
									}`}
								</span>
							</span>
						</div>
					</div>
					<div className={classes.newAccountSettingsShiplifyContainer}>
						<div className={classes.newAccountSettingsTitle}>
							<Avatar className={classes.newAccountIconContainer}>
								<LaneInfoIcon className={classes.newAccountIconContainerIcon} />
							</Avatar>
							<h3 className={classes.newAccountSettingsSectionTitle}>Residential/Limited Access Address Validation</h3>
						</div>
						<p className={classes.newAccountSettingTitle}>
							Status:
							<span className={classes.newAccountSettingText}>
								{` ${accountSettings.shiplifyService ? capitalizeFirstLetter(accountSettings.shiplifyService) : ''}`}
							</span>
						</p>
						<div className={classes.newAccountSettingsTitle}>
							<Avatar className={classes.newAccountIconContainer}>
								<AttachMoneyIcon htmlColor={theme.palette.success.main} />
							</Avatar>
							<h3 className={classes.newAccountSettingsSectionTitle}>Make a Payment Button Visibility</h3>
						</div>
						<p className={classes.newAccountSettingTitle}>
							Status:
							{accountSettings.paymentService && (
								<span className={classes.newAccountSettingText}>
									{` ${accountSettings.paymentService === 'false' ? 'Hidden' : 'Visible'}`}
								</span>
							)}
						</p>
					</div>
					<div className={classes.newAccountSettingsCustomerContainer}>
						<div className={classes.newAccountSettingsTitle}>
							<Avatar className={classes.newAccountIconContainer}>
								<SupervisorAccountOutlinedIcon className={classes.newAccountIconContainerIcon} />
							</Avatar>
							<h3 className={classes.newAccountSettingsSectionTitle}>Customer Priority Level</h3>
						</div>
						<p className={classes.newAccountSettingTitle}>
							Status:
							<span className={classes.newAccountSettingText}>
								{` ${accountSettings.customerPriority ? capitalizeFirstLetter(accountSettings.customerPriority) : ''}`}
							</span>
						</p>
					</div>
				</div>
			</div>
		</>
	)
}

AccountSettingsContainer.propTypes = {}

export default AccountSettingsContainer
