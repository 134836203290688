import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	cardHeader: {
		alignItems: 'center',
		padding: theme.spacing(4, 4, 0),
	},
	cardHeaderTitle: {
		color: theme.palette.neutral.black,
		fontWeight: theme.typography.fontWeightBold,
	},
	cardHeaderAction: {
		margin: 0,
		fontSize: 24,
	},
	cardHeaderActionButton: {
		textTransform: 'none',
		fontWeight: theme.typography.fontWeightRegular,
		color: theme.palette.neutral.darkGray,
	},
	cardMedia: {
		padding: theme.spacing(0, 3, 3),
		marginTop: 23,
		[theme.breakpoints.up('lg')]: {
			display: 'flex',
			alignItems: 'center',
			padding: theme.spacing(0, 4, 4),
			maxWidth: 621,
			margin: '46px auto 0',
		},
	},
	chartWrapper: {
		height: 300,
		[theme.breakpoints.up('lg')]: {
			width: 'calc(100% - 200px)',
		},
	},
	legendWrapper: {
		[theme.breakpoints.up('lg')]: {
			width: 200,
			marginLeft: 51,
		},
	},
	legendList: {
		padding: 0,
		marginTop: 22,
		listStyle: 'none',
		fontSize: 16,
		textTransform: 'uppercase',
		[theme.breakpoints.up('md')]: {
			margin: 0,
		},
	},
	legendListItem: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: 16,
		'&:last-child': {
			marginBottom: 0,
		},
	},
	legendListBox: {
		height: 22,
		width: 22,
		marginRight: 10,
		backgroundColor: theme.palette.neutral.secondaryGrey,
	},
	legendListLabel: {
		marginRight: 10,
	},
	legendListValue: {
		fontWeight: theme.typography.fontWeightBold,
		marginLeft: 'auto',
	},
	tooltip: {
		padding: theme.spacing(1),
		borderRadius: 4,
		backgroundColor: theme.palette.neutral.secondaryGrey,
	},
}))

export default useStyles
