function findPos(obj) {
	let curtop = 0
	if (obj.offsetParent) {
		do {
			curtop += obj.offsetTop
			/* eslint-disable-next-line no-cond-assign */ /* eslint-disable-next-line no-param-reassign */
		} while ((obj = obj.offsetParent))
		return curtop
	}
	return curtop
}

export const scrollToElement = (ElementSelector, offset) => {
	try {
		document.querySelector('main').scrollTop = findPos(document.getElementById(ElementSelector)) + offset
	} catch (error) {
		throw new Error(error)
	}
}

export default scrollToElement
