import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	TextField,
	Typography,
} from '@material-ui/core'
import { setOpenAnnouncementModal, setAnnouncement } from '../../redux/actions/AnnouncementModal.actions'
import useStyles from './styles'
import { reqUpdateGeneralSettingByName } from '../../redux/requests/App.request'
import SessionContext from '../../context/session'
import sweetAlertModal from '../SweetAlertModal/SweetAlertModal'

const AnnouncementModal = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const { getUserData } = useContext(SessionContext)
	const { openAnnouncementModal, announcementMessage } = useSelector((state) => state.announcementModal)
	const [value, setValue] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [hasAllFields, setHasAllFields] = useState(false)

	const handleClose = () => dispatch(setOpenAnnouncementModal(false))

	const handleSendAnnouncement = async () => {
		const { actualCompany } = getUserData()
		const config = { headers: { 'X-Company-id': actualCompany } }
		if (!hasAllFields) {
			setIsLoading(true)
			const [error] = await reqUpdateGeneralSettingByName({ name: 'DASHBOARD_MESSAGE', value }, config)
			if (error === null) {
				setIsLoading(false)
				sweetAlertModal('success', 'Your general message was sent successfully', null, 'Ok', null, null, null)
				handleClose()
			} else {
				setIsLoading(false)
				sweetAlertModal(
					'warning',
					'Something went wrong',
					'Something went wrong, please send your general message again',
					'Ok',
					null,
					null,
					null,
				)
			}
		}
	}

	useEffect(() => {
		if (announcementMessage !== '') {
			setValue(announcementMessage)
		}
	}, [announcementMessage])

	useEffect(() => {
		setHasAllFields(value === '')
	}, [value])

	return (
		<Dialog
			classes={{ paper: classes.paper }}
			aria-labelledby="announcement-dialog-title"
			open={openAnnouncementModal}
			maxWidth="lg"
			onBackdropClick={handleClose}
			onEscapeKeyDown={handleClose}
			onExited={() => {
				dispatch(setAnnouncement(''))
				setValue('')
			}}
		>
			<DialogTitle id="announcement-dialog-title" className={classes.dialogTitle} disableTypography>
				General Message
			</DialogTitle>
			<DialogContent className={classes.dialogContent}>
				<Typography align="center" variant="body1">
					Please enter the general message
				</Typography>
				<Grid container className={classes.messageContainer}>
					<Grid item xs={12} />
					<Grid item xs={12}>
						<TextField
							id="support-mail-body"
							label="Message"
							variant="outlined"
							color="secondary"
							fullWidth
							size="small"
							multiline
							rows={6}
							placeholder="Type the message you want to share…"
							value={value}
							onChange={(e) => setValue(e.target.value)}
							InputLabelProps={{ shrink: true }}
							InputProps={{
								classes: { input: classes.inputMessage },
							}}
							onInput={(e) => {
								e.target.value = e.target.value.slice(0, 120)
							}}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions className={classes.dialogActions} disableSpacing>
				<Button
					className={classes.dialogButton}
					color="secondary"
					variant="contained"
					disableElevation
					disabled={isLoading || hasAllFields}
					onClick={handleSendAnnouncement}
				>
					{!isLoading ? 'Save' : <CircularProgress />}
				</Button>
				<Button className={classes.dialogButton} variant="contained" disableElevation onClick={handleClose}>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default AnnouncementModal
