import { isNil, isNumber } from 'lodash'
import Joi from 'joi'

const createNewTab = (
	id = 0,
	title = 'Dashboard',
	route = '/',
	origin = '',
	destination = '',
	details = {},
	active = id === 0,
	display = false,
) => {
	const newTab = {
		title,
		route,
		origin,
		destination,
		details,
		id,
		active,
		display,
	}
	return newTab
}

export const isEmailInput = (input) => {
	const regEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
	if (regEmail.test(input)) {
		return true
	}
	return false
}

export function capitalizeFirstLetter(str) {
	const captializedWord = str
		.split(' ')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
		.join(' ')
	return captializedWord
}

export function removeUnderScore(str) {
	const words = str[0].toUpperCase() + str.split('_').join(' ').slice(1).toLowerCase()
	return words
}

export const removeUnderscoreAndUppercase = (item) => {
	const itemWithoutUnderscore = item.replace('_', ' ')
	return itemWithoutUnderscore.charAt(0) + itemWithoutUnderscore.slice(1).toLowerCase()
}

const formatter = new Intl.NumberFormat()
export const displayFormattedValue = (amount) => formatter.format(amount)

export const displayDollarValue = (dollarAmount) => {
	if (isNil(dollarAmount) || dollarAmount === '') {
		return ''
	}
	let numeric = dollarAmount
	if (!isNumber(dollarAmount)) {
		numeric = Number(dollarAmount)
	}

	if (!Number.isNaN(numeric)) {
		return `$${displayFormattedValue(numeric.toFixed(2))}`
	}

	return ''
}

const jsonArray = Joi.extend({
	type: 'array',
	base: Joi.array(),
	coerce: {
		from: 'string',
		method(value) {
			if (typeof value !== 'string' || (value[0] !== '[' && !/^\s*\[/.test(value))) {
				return { value: [] }
			}

			try {
				return { value: JSON.parse(value) }
			} catch (ignoreErr) {
				return { value: [] }
			}
		},
	},
})

export const insuranceSettingsSchema = Joi.object({
	typeAmount: Joi.string().trim().valid('MARGIN', 'FLAT', 'ADMIN_FEE').required(),
	typeAmountValue: Joi.alternatives().conditional('typeAmount', {
		// Expect a number in string format when insurance type is "MARGIN" or "FLAT"
		is: Joi.valid('MARGIN', 'FLAT'),
		then: Joi.string()
			.trim()
			.regex(/^[1-9]\d*(\.\d+)?$/)
			.required(),
		// Otherwise, we'll assume typeAmount is "ADMIN_FEE"
		// And expect the insurance admin fee tier structure
		otherwise: jsonArray
			.array()
			.items(
				Joi.object({
					min: Joi.number().required(),
					max: Joi.number().required(),
					amount: Joi.number().required(),
					type: Joi.string().valid('MARGIN', 'FLAT').required(),
				}),
			)
			.min(1)
			.required(),
	}),
})

export const insuranceSettingsAreValid = (data) => {
	const { error } = insuranceSettingsSchema.validate(data)
	return !error
}

export default createNewTab
