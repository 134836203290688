import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { makeStyles, TextField, withStyles } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { setStatus, setCurrentPage } from '../../redux/actions/QuotesFilters.actions'

const options = [
	{ title: 'Active' },
	{ title: 'Canceled' },
	{ title: 'Converted' },
	{ title: 'Expired' },
	{ title: 'Pending' },
]

const useStyles = makeStyles(() => ({
	autocompleteInput: {
		width: '100%',
		minHeight: '56px',
		padding: '0',
		'& label': {
			color: '#2C2C2C',
		},
	},
}))

const CustomAutocomplete = withStyles({
	tag: {
		backgroundColor: '#EBEEF1',
		border: '1px solid #CCD2D8',
		color: '#2C2C2C',
		fontFamily: 'Open Sans',
		fontSize: '16px',
		fontWeight: '400',
		height: 24,
		position: 'relative',
		zIndex: 0,
		'& svg': {
			color: '#EC3514',
		},
	},
})(Autocomplete)

const AutocompleteInput = ({ values, setUpdate }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const handleRequestStatus = (newValue) => {
		let param = ''
		if (newValue.length > 0) {
			newValue.forEach((d, index) => {
				if (index === 0) {
					param += `${d.title.toUpperCase()}`
				} else {
					param += `,${d.title.toUpperCase()}`
				}
			})
			dispatch(setCurrentPage(0))
			dispatch(setStatus([param]))
		} else {
			dispatch(setCurrentPage(0))
			dispatch(setStatus([]))
		}
		setUpdate(newValue)
	}

	return (
		<CustomAutocomplete
			multiple
			id="size-small-outlined-multi"
			options={options}
			size="small"
			value={values}
			onChange={(event, newValue) => handleRequestStatus(newValue)}
			getOptionLabel={(option) => option.title}
			getOptionSelected={(option, value) => option.title === value.title}
			renderInput={(params) => (
				<TextField
					/* eslint-disable react/jsx-props-no-spreading */
					{...params}
					size="small"
					className={classes.autocompleteInput}
					variant="outlined"
					label="Filter by status"
				/>
			)}
		/>
	)
}

AutocompleteInput.propTypes = {
	values: PropTypes.arrayOf(PropTypes.any).isRequired,
	setUpdate: PropTypes.func.isRequired,
}

export default AutocompleteInput
