import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
	icon: {
		fontSize: 24,
	},
	listItemIcon: {
		minWidth: 'auto',
		marginRight: 7,
	},
}))

export default useStyles
