import { axiosInvoices } from '../../utils/apiClient'

const reqLoadTracking = async (boolId) => {
	const [error, data] = await axiosInvoices
		.get(`/tracking/${boolId}`)
		.then((response) => [null, response])
		.catch((err) => [err, err.response.data.err.message])
	return [error, data]
}

export default reqLoadTracking
