import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { useHistory } from 'react-router-dom'
import { Menu, MenuItem, withStyles, ListItemIcon, ListItemText } from '@material-ui/core'
import useStyles from './style'
import { updateCarrierRoute, permissionName, carrierDetailsRoute } from '../../../../../utils/constants'
import SessionContext from '../../../../../context/session'
import isPermit from '../../../../../utils/permission'

const StyledMenuItem = withStyles((theme) => ({
	root: {
		'&:hover': {
			backgroundColor: theme.palette.neutral.whiteGray,
		},
	},
}))(MenuItem)

const MenuListCarrier = (props) => {
	const classes = useStyles()
	const history = useHistory()
	const { anchorEl, open, handleClose, carrierId, carrierStatus, handleUpdateCarrierStatus, carrierIdIntegracion } =
		props
	const { getUserData } = useContext(SessionContext)
	const { permissions } = getUserData()

	const handleEditCarrier = () => {
		const splittedRoute = updateCarrierRoute.split('/')
		history.push({
			pathname: `/${splittedRoute[1]}/${carrierIdIntegracion}`,
		})
	}

	const goToCarrierDetails = () => {
		history.push(`${carrierDetailsRoute.replace(':id', carrierIdIntegracion)}`)
	}

	return (
		<Menu id="long-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
			<MenuItem dense onClick={() => goToCarrierDetails()}>
				<ListItemIcon className={classes.listItemIcon}>
					<VisibilityIcon color="secondary" size="small" />
				</ListItemIcon>
				<ListItemText primary="View carrier information" />
			</MenuItem>
			{isPermit(permissions, permissionName.FP_ADMIN_CARRIERS_UPDATE) && (
				<StyledMenuItem onClick={() => handleEditCarrier()}>
					<ListItemIcon className={classes.listItemIcon}>
						<CreateOutlinedIcon className={classes.icon} />
					</ListItemIcon>
					<ListItemText primary="Edit carrier information" className={classes.listItemText} />
				</StyledMenuItem>
			)}
			{isPermit(permissions, permissionName.FP_ADMIN_CARRIERS_DEACTIVATE) && carrierStatus ? (
				<StyledMenuItem onClick={() => handleUpdateCarrierStatus(carrierId, 'false')}>
					<ListItemIcon className={classes.listItemIcon}>
						<NotInterestedIcon className={classes.iconDanger} />
					</ListItemIcon>
					<ListItemText primary="Deactivate carrier" className={classes.listItemText} />
				</StyledMenuItem>
			) : (
				<StyledMenuItem onClick={() => handleUpdateCarrierStatus(carrierId, 'true')}>
					<ListItemIcon className={classes.listItemIcon}>
						<CheckCircleOutlineIcon className={classes.iconActivate} />
					</ListItemIcon>
					<ListItemText primary="Activate carrier" className={classes.listItemText} />
				</StyledMenuItem>
			)}
		</Menu>
	)
}

MenuListCarrier.defaultProps = {
	handleUpdateCarrierStatus: () => {},
	anchorEl: null,
}

MenuListCarrier.propTypes = {
	anchorEl: PropTypes.objectOf(PropTypes.any),
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	carrierId: PropTypes.number.isRequired,
	carrierIdIntegracion: PropTypes.number.isRequired,
	carrierStatus: PropTypes.bool.isRequired,
	handleUpdateCarrierStatus: PropTypes.func,
}

export default MenuListCarrier
