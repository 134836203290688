import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	paper: {
		border: `1px solid ${theme.palette.neutral.whiteLightGray}`,
		display: 'none',
		[theme.breakpoints.up('lg')]: {
			display: 'block',
		},
	},
	titleAndPayButton: {
		display: 'grid',
		gridAutoFlow: 'row',
		[theme.breakpoints.up('lg')]: {
			gridAutoFlow: 'column',
		},
	},
	filterButtonText: {
		color: theme.palette.neutral.black,
		fontWeight: theme.typography.fontWeightRegular,
		textTransform: 'none',
	},
	filterTriggerButton: {
		color: theme.palette.neutral.black,
		padding: 0,
		[theme.breakpoints.up('lg')]: {
			display: 'none',
		},
	},
	filterButtonModalText: {
		color: theme.palette.neutral.black,
		fontWeight: theme.typography.fontWeightRegular,
		textTransform: 'none',
	},
	filterFormWrapper: {
		padding: 15,
	},
	invoiceHistoryTitleButton: {
		alignItems: 'center',
		display: 'grid',
		gridAutoFlow: 'column',
		justifyContent: 'space-between',
	},
	invoiceHistoryTitle: {
		margin: '0 0 4px 0',
		fontSize: 22,
		fontWeight: 700,
		color: theme.palette.neutral.black,
		'@media only screen and (min-width: 1025px)': {
			fontSize: 34,
		},
	},
	invoiceHistoryDescription: {
		margin: '0 0 12px 0',
		color: theme.palette.neutral.black,
		fontSize: 16,
		fontWeight: 400,
	},
	makeAPaymentButton: {
		width: '140px',
		height: '24px',
		fontSize: '13px',
		fontWeight: 400,
		textTransform: 'none',
		padding: '0',
		marginBottom: '13px',
		'& span': {
			margin: '0 4px 0 0',
		},
		[theme.breakpoints.up('md')]: {
			justifySelf: 'end',
		},
		'@media only screen and (min-width: 768px) and (max-width: 1024px)': {
			display: 'none',
		},
	},
	makeAPaymentButton2: {
		width: '140px',
		height: '24px',
		fontSize: '13px',
		fontWeight: 400,
		textTransform: 'none',
		padding: '0',
		margin: '13px 0 0 0',
		'& span': {
			margin: '0 4px 0 0',
		},
		'@media only screen and (max-width: 767px)': {
			display: 'none',
		},
		'@media only screen and (min-width: 1025px)': {
			display: 'none',
		},
	},
}))

export default useStyles
