import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	tableRow: {
		height: 104,
	},
	tableCell: {
		height: 'auto',
		padding: '0 16px',
		fontWeight: theme.typography.fontWeightRegular,
	},
	tableCellItems: {
		lineHeight: '22px',
		'& span': {
			color: theme.palette.secondary.main,
			fontWeight: theme.typography.fontWeightMedium,
		},
	},
	viewIcon: {
		color: theme.palette.secondary.main,
		cursor: 'pointer',
	},
	invoiceCarrierImg: {
		display: 'inline-block',
		maxWidth: '40px',
	},
	blockInfo: {
		margin: '0',
	},
	packageType: {
		textTransform: 'lowercase',
	},
	containerToltip: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		rowGap: '3px',
	},
}))

export default useStyles
