import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { EmailOutlined } from '@material-ui/icons'
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { Menu, MenuItem, withStyles, ListItemIcon, ListItemText } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import useUsersFilters from '../../../../../hooks/useUsersFilters'
import sweetAlertModal from '../../../../../components/SweetAlertModal/SweetAlertModal'
import { deactivateUser, fetchUsers, activateUser } from '../../../../../redux/actions/Users.actions'
import SessionContext from '../../../../../context/session'
import { updateUserRoute, permissionName } from '../../../../../utils/constants'
import isPermit from '../../../../../utils/permission'
import { reqSendEmailPassword } from '../../../../../redux/requests/Users.requests'

import useStyles from './style'

const StyledMenuItem = withStyles((theme) => ({
	root: {
		'&:hover': {
			backgroundColor: theme.palette.neutral.whiteGray,
		},
	},
}))(MenuItem)

const MenuList = (props) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const history = useHistory()
	const [buildParamsFromState] = useUsersFilters()
	const filterParams = buildParamsFromState()
	const { getUserData } = useContext(SessionContext)
	const { permissions } = getUserData()
	const { anchorEl, open, handleClose, userId, userStatus, emailStatus } = props

	const handleEditUser = () => {
		const splittedRoute = updateUserRoute.split('/')
		history.push({
			pathname: `/${splittedRoute[1]}/${userId}`,
		})
	}

	const handleDeactivateUser = async () => {
		handleClose()
		const { status, resp } = await dispatch(deactivateUser(userId))
		if (status === 'error') {
			sweetAlertModal(
				'error',
				resp?.err?.message ?? 'There was an error trying to deactivate the user',
				null,
				'Ok',
				true,
				false,
			)
		} else {
			sweetAlertModal(status, resp.data.message, null, 'Ok', true, false, null)
			await dispatch(fetchUsers(filterParams))
		}
	}

	const handleActivateUser = async () => {
		handleClose()
		const { status, resp } = await dispatch(activateUser(userId))
		if (status === 'error') {
			sweetAlertModal('error', resp.err.message, null, 'Ok', true, false)
		} else {
			sweetAlertModal(status, resp.data.message, null, 'Ok', true, false, null)
			await dispatch(fetchUsers(filterParams))
		}
	}
	const sendEmailLoginInfoUser = async () => {
		await reqSendEmailPassword(userId, 'TRUE')
		await dispatch(fetchUsers(filterParams))
	}

	return (
		<Menu id="long-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
			{(isPermit(permissions, permissionName.FP_ADMIN_USERS_UPDATE) ||
				isPermit(permissions, permissionName.ADMIN_USERS_UPDATE)) && (
				<StyledMenuItem onClick={() => handleEditUser()}>
					<ListItemIcon className={classes.listItemIcon}>
						<CreateOutlinedIcon className={classes.icon} />
					</ListItemIcon>
					<ListItemText primary="Edit user information" className={classes.listItemText} />
				</StyledMenuItem>
			)}
			{isPermit(permissions, permissionName.FP_ADMIN_USERS_DEACTIVATE) &&
				(userStatus ? (
					<StyledMenuItem onClick={() => handleDeactivateUser()}>
						<ListItemIcon className={classes.listItemIcon}>
							<NotInterestedIcon className={classes.iconDeactivate} />
						</ListItemIcon>
						<ListItemText primary="Deactivate user" className={classes.listItemText} />
					</StyledMenuItem>
				) : (
					<StyledMenuItem onClick={() => handleActivateUser()}>
						<ListItemIcon className={classes.listItemIcon}>
							<CheckCircleOutlineIcon className={classes.iconActivate} />
						</ListItemIcon>
						<ListItemText primary="Activate user" className={classes.listItemText} />
					</StyledMenuItem>
				))}
			{(isPermit(permissions, permissionName.FP_ADMIN_USERS_UPDATE) ||
				isPermit(permissions, permissionName.ADMIN_USERS_UPDATE)) &&
				!emailStatus && (
					<StyledMenuItem onClick={() => sendEmailLoginInfoUser()}>
						<ListItemIcon className={classes.listItemIcon}>
							<EmailOutlined className={classes.icon} />
						</ListItemIcon>
						<ListItemText primary="Email login info to user" className={classes.listItemText} />
					</StyledMenuItem>
				)}
		</Menu>
	)
}

MenuList.propTypes = {
	anchorEl: PropTypes.objectOf(PropTypes.any),
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	userId: PropTypes.string.isRequired,
	userStatus: PropTypes.bool.isRequired,
	emailStatus: PropTypes.bool.isRequired,
}

MenuList.defaultProps = { anchorEl: null }

export default MenuList
