import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import TablePagination from '@material-ui/core/TablePagination'
import { CircularProgress } from '@material-ui/core'

import useShipmentsFilters from '../../../../../hooks/useShipmentsFilters'
import { savedShipmentsCells } from '../../../../../utils/headCells'
import { updatePaginator } from '../../../../../redux/actions/Shipment.actions'
import { setCurrentPage, setRowsPerPage } from '../../../../../redux/actions/ShipmentsFilters.actions'
import NoResultsFound from '../../../../../components/NoResultsFound'
import SortableTableHeader from '../../../../../components/SortableTableHeader'

import useStyles from './style'
import ShipmentRow from '../ShipmentRow'

const TableList = (props) => {
	const { pageReset } = props
	const shipments = useSelector((state) => state.shipments)
	const shipmentsFilters = useSelector((state) => state.shipmentsFilters)
	const classes = useStyles()
	const dispatch = useDispatch()
	const [page, setPage] = useState(Number(shipmentsFilters.currentPage / shipmentsFilters.rowsPerPage))
	const [buildParamsFromState] = useShipmentsFilters()

	const handleChangePage = (event, newPage) => {
		dispatch(setCurrentPage(newPage * shipmentsFilters.rowsPerPage))
		dispatch(updatePaginator(newPage * shipmentsFilters.rowsPerPage))
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		dispatch(setRowsPerPage(Number(event.target.value)))
		dispatch(setCurrentPage(0))
		setPage(0)
	}

	useEffect(() => {
		if (pageReset) setPage(0)
	}, [pageReset])

	const renderTableBody = () => {
		let data = []
		if (shipments.isLoading) {
			return (
				<TableRow>
					<TableCell align="center" colSpan={Object.keys(savedShipmentsCells).length}>
						<CircularProgress />
					</TableCell>
				</TableRow>
			)
		}
		data = shipments.list.map((row) => <ShipmentRow data={row} />)
		if (data.length === 0) {
			const shipmentsFiltersParams = buildParamsFromState()
			return (
				<TableRow>
					<TableCell align="center" colSpan={Object.keys(savedShipmentsCells).length}>
						<NoResultsFound type="Shipments" filters={shipmentsFiltersParams} />
					</TableCell>
				</TableRow>
			)
		}
		return data
	}
	return (
		<div className={classes.root}>
			<Paper className={classes.paper} elevation={0}>
				<TableContainer>
					<Table className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
						<TableHead>
							<TableRow>
								<SortableTableHeader
									columns={savedShipmentsCells}
									handleSortColumn={() => {}}
									filters={shipmentsFilters}
								/>
							</TableRow>
						</TableHead>
						<TableBody>{renderTableBody()}</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					classes={{
						root: classes.tablePaginator,
						toolbar: classes.tablePaginatorToolbar,
						actions: classes.tablePaginatorActions,
					}}
					rowsPerPageOptions={[10, 20, 25, 100]}
					component="div"
					count={shipments.totalRecords}
					rowsPerPage={Number(shipmentsFilters.rowsPerPage)}
					page={page}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
				/>
			</Paper>
		</div>
	)
}

TableList.propTypes = {
	pageReset: PropTypes.number.isRequired,
}

export default TableList
