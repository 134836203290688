import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	paperDialog: {
		width: '614px',
		maxWidth: '100%',
		borderBottom: '6px solid',
		borderBottomColor: theme.palette.secondary.main,
		padding: '32px 24px',
	},
	root: {
		'@media only screen and (max-width: 768px)': {
			fontSize: '10px',
		},
	},
	modalTitle: {
		margin: 0,
		marginBottom: theme.spacing(1),
		color: theme.palette.secondary.main,
		textAlign: 'center',
	},
	title: {
		fontSize: '16px',
		fontWeight: 400,
		margin: '0 0 24px 0',
	},
	uploadButton: {
		height: '48px',
		alignSelf: 'center',
		textTransform: 'capitalize',
		fontWeight: theme.typography.fontWeightRegular,
		lineHeight: '21px',
		padding: '13px 50px',
		boxShadow: 'none',
		'@media only screen and (min-width: 768px) and (max-width: 1023px)': {
			fontSize: '11px',
		},
	},
	cancelButton: {
		marginRight: '24px',
		height: '48px',
		alignSelf: 'center',
		backgroundColor: '#CCD2D8',
		color: '#2C2C2C',
		textTransform: 'capitalize',
		lineHeight: '21px',
		fontWeight: theme.typography.fontWeightBold,
		padding: '13px 50px',
		boxShadow: 'none',
		'&:hover': {
			backgroundColor: '#b1b7bd',
			color: '#2C2C2C',
		},
		'@media only screen and (min-width: 768px) and (max-width: 1023px)': {
			fontSize: '11px',
		},
	},
	itemIitle: {
		fontSize: '14px',
		fontWeight: 700,
		color: '#090E24',
		margin: '0 0 10px 0',
	},
	documentName: {
		fontSize: '14px',
		fontWeight: 600,
	},
}))

export default useStyles
