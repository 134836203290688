import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	titleText: {
		margin: '0 0 8px',
		fontSize: '2.5rem',
		color: theme.palette.neutral.black,
		[theme.breakpoints.down('md')]: {
			fontSize: 22,
		},
	},
	headerActionButton: {
		textTransform: 'none',
		fontWeight: theme.typography.body2.fontWeight,
		fontSize: theme.typography.body2.fontSize,
	},
	filterTriggerButton: {
		display: 'flex',
		marginLeft: 'auto',
		color: theme.palette.neutral.black,
	},
	filterResetButton: {
		textTransform: 'none',
		fontWeight: theme.typography.body2.fontWeight,
		fontSize: theme.typography.body2.fontSize,
		color: theme.palette.neutral.black,
	},
	drawer: {
		padding: theme.spacing(2),
	},
}))

export default useStyles
