import React, { useEffect, useRef, useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core'
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined'
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined'

import { useDispatch } from 'react-redux'
import { uploadShipmentDocument } from '../../../../redux/actions/Shipment.actions'

import DocumentsTable from '../DocumentsTable'
import DocumentsCard from '../../../../components/DocumentsCard'
import UploadDocumentsModal from '../UploadDocumentsModal'
import sweetAlertModal from '../../../../components/SweetAlertModal/SweetAlertModal'

import './DragAndDrop.scss'

const useStyles = makeStyles((theme) => ({
	icon: {
		width: '24px',
		color: theme.palette.secondary.main,
	},
}))

const DragAndDrop = ({ shipmentId }) => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const drop = useRef(null)
	const [allDocuments, setAllDocuments] = useState([])
	const [documents, setDocuments] = useState([])
	const [openModal, setOpenModal] = useState(false)
	const handleDragOver = (e) => {
		e.preventDefault()
		e.stopPropagation()
	}

	const handleDrop = useCallback((e) => {
		e.preventDefault()
		e.stopPropagation()

		const { files } = e.dataTransfer
		if (files && files.length) {
			const allFiles = Array.from(files).map((file) => file)
			setDocuments(allFiles)
			setOpenModal(true)
		}
	}, [])

	const handleSelectFiles = (e) => {
		const { files } = e.target
		if (files && files.length) {
			const allFiles = Array.from(files).map((file) => file)
			setDocuments(allFiles)
			setOpenModal(true)
		}
	}

	useEffect(() => {
		const ref = drop.current
		ref.addEventListener('dragover', handleDragOver)
		ref.addEventListener('drop', handleDrop)

		return () => {
			ref.removeEventListener('dragover', handleDragOver)
			ref.removeEventListener('drop', handleDrop)
		}
	}, [handleDrop])

	const uploadAllDocuments = async () => {
		const formData = new FormData()

		allDocuments.forEach((document, index) => {
			formData.append(`documents[${index}][name]`, document.name)
			formData.append(`documents[${index}][file]`, document.file)
			formData.append(`documents[${index}][type]`, document.type)
		})

		const { status } = await dispatch(uploadShipmentDocument(shipmentId, formData))
		if (status === 'error') {
			sweetAlertModal(status, 'There was an error fetching the quote details', null, 'Ok', true, false, null)
		} else {
			sweetAlertModal(status, 'The documents were successfully uploaded', '', 'OK', true, false, null)
		}
	}

	useEffect(() => {
		document.getElementById('file-selector').value = ''
	}, [allDocuments])

	return (
		<div className="drag-and-drop-container">
			<p className="drag-and-drop-added-title">Added</p>
			<div className="drag-and-drop-area-and-items">
				<div>
					<DocumentsTable documents={allDocuments} setDocuments={setAllDocuments} />
				</div>
				<DocumentsCard documents={allDocuments} setDocuments={setAllDocuments} />
				<div className="drag-and-drop-area" ref={drop}>
					<InsertDriveFileOutlinedIcon className={classes.icon} />
					<p className="drag-and-drop-title-mobile">Upload your files here</p>
					<p className="drag-and-drop-title-web">Drag & Drop your files here</p>
					<input
						type="file"
						multiple
						id="file-selector"
						className="drag-and-drop-uploader"
						onChange={handleSelectFiles}
					/>
				</div>
			</div>
			{allDocuments.length > 0 ? (
				<div className="drag-and-drop-icon-title">
					<div className="drag-and-drop-upload-documents">
						<CloudUploadOutlinedIcon color="secondary" style={{ width: '20px' }} />
						<p className="drag-and-drop-title" role="presentation" onClick={uploadAllDocuments}>
							Upload All Documents
						</p>
					</div>
				</div>
			) : null}
			<UploadDocumentsModal
				isOpen={openModal}
				setIsOpen={setOpenModal}
				documents={documents}
				allDocuments={allDocuments}
				setAllDocuments={setAllDocuments}
			/>
		</div>
	)
}

DragAndDrop.propTypes = {
	shipmentId: PropTypes.number.isRequired,
}

export default DragAndDrop
