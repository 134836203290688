import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		marginTop: '8px',
	},
	demo1: {
		backgroundColor: theme.palette.background.paper,
	},
	tabsWrapper: {
		boxSizing: 'border-box',
		padding: '0 24px',
		width: '100vw',
		minHeight: '68vh',
		backgroundColor: '#FFF',
		[theme.breakpoints.down('md')]: {
			padding: '0',
		},
	},
}))

export default useStyles
