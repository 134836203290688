import type {
	CreateQuoteRequest,
	EditQuoteRequest,
	PatchQuoteRequest,
	PatchQuoteResponse,
	SendEmailQuoteRequest,
	UpdateItemHazmatInfoRequest,
	UpdateQuoteStatusRequest,
} from './Quotes.types'
import { axiosQuotes } from '../../utils/apiClient'
import type { ApiResponse } from '../types/ApiResponse'

export const reqGetQuotesSearch = async (params: string) => {
	const [error, data] = await axiosQuotes
		.get(`/search${params}`)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

const reqGetQuotes = async (params: string) => {
	const [error, data] = await axiosQuotes
		.get(`/quote/list${params}`)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export const reqDownloadPDFQuote = async (params: string) => {
	const [error, data] = await axiosQuotes
		.get(`/quote/pdf/${params}`)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export const reqCreateQuote = async (params: CreateQuoteRequest) => {
	const [error, data] = await axiosQuotes
		.post('/quote', params)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export const reqEditQuote = async (quoteId: string | number, body: EditQuoteRequest) => {
	const [error, data] = await axiosQuotes
		.put(`/quote/${quoteId}`, body)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

/** patch quote endpoint, currently restricted to pickup/delivery dates */
export const reqEditQuoteUpdateDate = async (
	quoteId: number,
	body: PatchQuoteRequest,
): Promise<ApiResponse<PatchQuoteResponse>> => {
	const [error, data] = await axiosQuotes
		.patch<PatchQuoteResponse>(`/quote/${quoteId}`, body)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export const reqUpdateQuoteStatus = async (quoteId: string | number, body: UpdateQuoteStatusRequest) => {
	const [error, data] = await axiosQuotes
		.put(`/quote/status/${quoteId}`, body)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export const reqValidateQuoteExpiration = async (quoteId: string | number, rateId: string | number) => {
	const [error, data] = await axiosQuotes
		.get(`/quote/validate/${quoteId}/${rateId}`)
		.then((response) => [null, response])
		.catch((err) => [err, err.err.message])

	return [error, data]
}

export const reqUpdateItemHazmatInfo = async (
	quoteId: string | number,
	itemId: string | number,
	body: UpdateItemHazmatInfoRequest,
) => {
	const [error, data] = await axiosQuotes
		.put(`/quote/item/${quoteId}/${itemId}`, body)
		.then((response) => [null, response])
		.catch((err) => [err, err.response.data.err.message])

	return [error, data]
}

export const reqSendEmailQuote = async (
	quoteId: string | number,
	rateId: string | number,
	body: SendEmailQuoteRequest,
) => {
	const [error, data] = await axiosQuotes
		.post(`/quote/email/${quoteId}/${rateId}`, body)
		.then((response) => [null, response])
		.catch((err) => [err, err.response.data.message])

	return [error, data]
}

export default reqGetQuotes
