import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	textBoxWidth: {
		width: 50,
	},
	iconDivMargin: {
		marginLeft: '20px',
		marginBottom: '20px',
	},
	outerDiv: {
		borderColor: 'black',
		borderWidth: '1px',
		borderStyle: 'solid',
		borderRadius: '5px',
		padding: '15px',
	},
	sectionCarrierTitle: {
		fontWeight: theme.typography.fontWeightRegular,
		color: theme.palette.primary.main,
		margin: 0,
		lineHeight: '28px',
	},
	icon: {
		width: '20px',
		height: '20px',
		borderRadius: '20px',
		padding: '3px',
		alignSelf: 'center',

		'@media only screen and (min-width: 768px)': {
			width: '24px',
			height: '24px',
		},
	},
	iconTitle: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: '16px',
		'& svg': {
			fontSize: '32px',
			marginRight: '10px',
		},
	},
}))

export default useStyles
