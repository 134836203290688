import React from 'react'

import DashboardContainer from '../containers/dashboardContainer'

const Dashboard = () => (
	<>
		<DashboardContainer />
	</>
)

export default Dashboard
