import React from 'react'
import OktaNavBar from '../../NavBar/OktaNavBar'
import useStyles from './styles'

const PrivacyStatement = () => {
	const classes = useStyles()
	return (
		<>
			<OktaNavBar />
			<div className={classes.navbar}>
				<h1>Nolan Transportation Group Online Privacy Policy</h1>
				<p>
					<b>Effective Date:</b> January 1, 2022
				</p>
				<p>
					This Online Privacy Policy applies to personal information about you that Nolan Transportation Group, LLC (“
					<b>NTG</b>
					”, “<b>we</b>
					”, “<b>our</b>
					”, “<b>us</b>
					”) may obtain when you use our online web application, Transportation Management System Lite (the “<b>Site</b>
					”). This Online Privacy Policy describes how we may collect, use, share, or otherwise process personal
					information about you that we obtain from your use of the NTG Site; your choices regarding our use of your
					personal information; how we safeguard such information; and how you may contact us regarding our privacy
					practices.
				</p>
				<h2>Collection of Personal Information</h2>
				<p>
					<b>Personal Information You Provide.</b> We may obtain personal information, information that can be used to
					identify you individually, from you directly when you interact with the Site (for example, when you express an
					interest in obtaining additional information from us, create a user profile, or similar features on our Site).
					The personal information you might provide to us in this context includes your name, company name and contact
					information, such as email address, physical address or phone number.
				</p>
				<p>
					<b>Information Collected Automatically.</b> We, and the vendors we work with, may use various tools and
					technologies like cookies and web server logs to collect certain information automatically about you when you
					visit our Site. This information may include IP addresses, device identifiers, browser characteristics,
					operating system details, Internet service provider details, language preferences, referring URLs, time and
					length of visits, and pages viewed. Please note that we, and other parties we work with, may collect personal
					information about your online activities over time and across different devices and online properties when you
					use the NTG Site.
				</p>
				<p>
					“Cookies” are small data files that your web browser stores on your computer. Cookies may allow a website to
					recognize a user’s device, record user preferences, track the pages a user visits on the website, and identify
					the referral website. Most web browsers accept cookies by default, but you may be able to adjust your browser
					settings to remove or reject cookies. Our Site uses both session ID cookies and tracking cookies. Session ID
					cookies make it easier for you to navigate the Site and expire when you close your browser. Tracking cookies
					help us understand how you use the Site and enhance your user experience. Please note that blocking cookies on
					our Site may affect the availability and functionality of the Site and other websites. Also, disabling cookies
					may invalidate opt outs that use cookies to recognize devices that have opted out.
				</p>
				<p>
					Your web browser may have settings that allow you to transmit a “Do Not Track” signal when you visit various
					websites or use online services. Like many websites, our Site is not designed to respond to “Do Not Track”
					signals received from browsers. To learn more about “Do Not Track” signals, visit
					<a href="https://www.allaboutdnt.com" target="_blank" rel="external nofollow noreferrer">
						www.allaboutdnt.com
					</a>
					.
				</p>
				<p>
					<b>Information Collected From Other Parties.</b> We may combine information that we collect about you on our
					Site, including information collected automatically, with information we obtain from others. To the extent we
					combine information that we collect automatically or that we obtain from others with personal information you
					provide to us, we will treat the combined information as personal information in accordance with this Online
					Privacy Policy. When you submit information to another party, you are solely subject to that party’s terms of
					use and privacy policies, for which we have no responsibility.
				</p>
				<h2>How We Collect Personal Information</h2>
				<p>
					NTG provides third-party logistical services for customers. Since we are primarily a business-to-business (“
					<b>B2B</b>
					”) offering the personal information we collect about individual consumers is very limited and generally
					collected only in the context of the consumer acting on behalf of their employer to do business with us. For
					example, an individual may fill out a form or request a quote on behalf of their employer.
				</p>
				<h2>Use of Personal Information</h2>
				<p>We may use personal information we collect through the Site for various purposes including:</p>
				<ul>
					<li>Providing the services you request;</li>
					<li>Facilitating and personalizing your user experience;</li>
					<li>Communicating with you, including responding to your requests or inquiries;</li>
					<li>Marketing and advertising our products and services;</li>
					<li>Monitoring and analyzing trends, usage, and activities of Site visitors and users;</li>
					<li>Improving the NTG Site;</li>
					<li>
						Preventing, investigating, identifying, or taking any other action with regard to any suspected or actual
						fraudulent or illegal activity or activity that violates our policies;
					</li>
					<li>
						Complying with applicable laws, rules, regulations, and legal processes as well as our company policies; and
					</li>
					<li>Any other purpose, with your consent.</li>
				</ul>
				<h2>Sharing Your Personal Information</h2>
				<p>
					We may share your personal information with our affiliates, business partners, and vendors that provide
					services on our behalf. We may also share your personal information as follows:
				</p>
				<ul>
					<li>To comply with the law, law enforcement, other legal process, or in response to a government request;</li>
					<li>To protect rights, property, life, health, security, and safety;</li>
					<li>
						In connection with any proposed or actual merger, purchase, sale, or any other type of acquisition or other
						transaction, including a transfer of all or a portion of our business to another company;
					</li>
					<li>
						For a purpose disclosed elsewhere in this Online Privacy Policy or at the time you provide your personal
						information;
					</li>
					<li>
						For any other purpose consistent with our statements in this Online Privacy Policy or otherwise allowed by
						applicable law; and
					</li>
					<li>With your consent or at your direction.</li>
				</ul>
				<h2>Linked Websites</h2>
				<p>
					The Site may include links to other websites that are not owned or operated by NTG. This Online Privacy Policy
					does not apply to those websites, which may have their own privacy policies or notices that you should review
					to understand how they may collect, use, or disclose your personal information. NTG is not responsible for the
					content or privacy practices of any linked websites that it does not control.
				</p>
				<h2>Social Features</h2>
				<p>
					Certain features of the Site may permit you to initiate interactions between the Site and other platforms like
					social networks operated by unaffiliated parties, for example, if you “like” or “follow” NTG on those
					platforms (“
					<b>Social Features</b>
					”). If you choose to “like” or share content or post information using Social Features, that information may
					be publicly displayed, and the party operating the other platform may receive information about you and your
					use of our Site. Similarly, if you interact with us through Social Features, we may have access to information
					about you from the social media platform. Please note that if you mention NTG, or comment about or in response
					to us, in your post on another platform, that platform may allow us to publish your post on our Site. In
					addition, we may receive information about you when that information is accessible through another user’s
					social media. For example, if a user on a social media platform gives us access to their profile and
					connections, and you are a connection of that user, we may obtain information about you. You should review the
					terms, policies, and settings of these platforms to learn more about their data practices and/or adjust your
					settings.
				</p>
				<h2>Security</h2>
				<p>
					We take reasonable measures to protect your personal information from loss, theft, misuse, and unauthorized
					access, disclosure, alteration, and destruction. Nevertheless, transmission via the Internet and online
					digital storage are not completely secure, so we cannot guarantee the security of your information collected
					through our Site.
				</p>
				<h2>Your Choices</h2>
				<p>
					<b>Communication Preferences and Marketing.</b> If you do not wish to receive marketing emails from us, you
					can opt out of future marketing emails by following the instructions provided in our marketing emails or by
					emailing us at
					<a href="mailto:legal@ntgfreight.com">legal@ntgfreight.com</a>. Opting out of marketing emails will not affect
					our administrative emails to you (for example, emails about your use of our products and services).
				</p>
				<p>
					<b>Accessing, Reviewing, and Changing Your Personal Information.</b>
					Registered users can review and change certain personal information (for example, name, password, email
					address, and phone number) at any time by accessing their account on the Site.
				</p>
				<h2>Information for Users Outside the United States</h2>
				<p>
					NTG is based in the United States. If you are visiting the NTG Site from outside the United States, please be
					aware that information we obtain about you will be transferred to and processed in the United States. By using
					the Site and providing your personal information, you acknowledge that your personal information may be
					transferred to and processed in jurisdictions outside your own and consent to the transfer and processing.
					Please be aware that the data protection laws and regulations that may apply to your personal information
					transferred to the United States or other countries may be different from the laws in your country of
					residence.
				</p>
				<h2>Children and Minors</h2>
				<p>
					Our Site is not intended for minors, and we do not knowingly collect personal information from visitors under
					the age of 18. If we learn that we have received personal information from someone under the age of 18, we
					will delete such information in accordance with applicable law.
				</p>
				<h2>Revisions to this Online Privacy Policy</h2>
				<p>
					This Online Privacy Policy is subject to change at our discretion. We will indicate changes to the Online
					Privacy Policy by updating the “Effective Date” at the beginning of the Online Privacy Policy. Your continued
					use of this Site after any update to this Online Privacy Policy will constitute your acceptance of our
					changes.
				</p>
				<h2>Contact Information</h2>
				<p>
					You may direct comments or questions regarding this Online Privacy Policy via email to &nbsp;
					<a href="mailto:legal@ntgfreight.com">legal@ntgfreight.com</a>.
				</p>
			</div>
		</>
	)
}

export default PrivacyStatement
