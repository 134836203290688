import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
	container: {
		paddingLeft: '16px',
	},
	table: {
		minWidth: 700,
	},
	tableRow: {
		'&.Mui-selected, &.Mui-selected:hover': {
			backgroundColor: '#FFFFFF',
			border: '1.5px solid #F3846F',
			boxShadow: '0 0 10px 0px #F9D2CA',
		},
	},
	tableCellHead: {
		fontFamily: 'Open Sans',
		fontSize: '16px',
		fontWeight: '700',
		backgroundColor: '#EBEEF1',
		color: '#090E24',
		borderBottom: '2px solid #C9CFE4',
	},
	tableCellSecondHead: {
		fontFamily: 'Open Sans',
		fontSize: '14px',
		fontWeight: '600',
		backgroundColor: '#EBEEF1',
		color: '#2C2C2C',
		borderBottom: '2px solid #C9CFE4',
	},
	tableCell: {
		fontFamily: 'Open Sans',
		fontSize: '14px',
		fontWeight: '700',
		color: '#2C2C2C',
		textAlign: 'start',
	},
	checkBox: {
		color: '#CCD2D8',
	},
	tableVerticalLineRight: {
		borderRight: '1.5px solid #C9CFE4',
	},
}))

export default useStyles
