/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { fetchUsersByCompany } from '../../../../../redux/actions/Users.actions'
import SearchBar from '../../../../../components/SearchBar'
import TableList from './components/TableList/TableList'
import useStyles from './styles'

const UsersListContainer = () => {
	const { id } = useParams()
	const classes = useStyles()
	const dispatch = useDispatch()
	const { users, error, totalUsers, isLoading } = useSelector((state) => state.users)
	const [filters, setFilters] = useState({
		search: '',
		rowsPerPage: 10,
		page: 0,
	})
	const config = {
		headers: { 'X-Company-id': +id },
	}
	const handleFilters = (value, key) => {
		let newFilters = { ...filters, [key]: value }
		if (key === 'search' || key === 'rowsPerPage') {
			newFilters = { ...newFilters, page: 0 }
		}
		setFilters(newFilters)
	}
	useEffect(() => {
		const { page, rowsPerPage, search } = filters
		let params = ''
		if (search.length >= 3) {
			params = `?searchValue=${search}`
		}
		dispatch(fetchUsersByCompany(`/${page * rowsPerPage}/${rowsPerPage}${params}`, config))
	}, [filters, dispatch])
	return (
		<Grid container className={classes.container}>
			{!error && (
				<>
					<Grid item xs={12}>
						<SearchBar
							value={filters.search}
							placeholder="Search"
							onChangeHandler={(e) => handleFilters(e.target.value, 'search')}
						/>
					</Grid>
					<Grid className={classes.containerTable} item xs={12}>
						<TableList
							totalData={totalUsers}
							isLoading={isLoading}
							data={users}
							config={filters}
							handleConfig={handleFilters}
						/>
					</Grid>
				</>
			)}
			{error && (
				<h3 className={classes.documentsMessageTitle}>
					{error?.response?.data?.err?.message ?? 'An error has occurred, please try again later'}
				</h3>
			)}
		</Grid>
	)
}

export default UsersListContainer
