import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core'
import { Send } from '@material-ui/icons'
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutlineOutlined'
import AddIcon from '@material-ui/icons/Add'
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined'
import DocumentsTable from '../../components/DocumentsTable'
import useStyles from './styles'
import DocumentsCard from '../../../../../components/DocumentsCard'
import {
	uploadShipmentDocument,
	downloadDocumentBlob,
	downloadDocumentLabel,
	downloadShipmentDocument,
} from '../../../../../redux/actions/Shipment.actions'
import sweetAlertModal from '../../../../../components/SweetAlertModal/SweetAlertModal'
import { documentsOptions } from '../../../../../utils/dropDownOptions'
import SendByMail from './components/sendByMail/sendByMail'
import { reqSendByMailDocuments } from '../../../../../redux/requests/Shipment.requests'

const ShipmentDocuments = ({ shipment }) => {
	const [carrierDocuments, setCarrierDocuments] = useState([])
	const [bolAndLabelDocments, setBolAndLabelDocuments] = useState([])
	const [userDocuments, setUserDocuments] = useState([])
	const [documentToUpload, setDocumentToUpload] = useState({})
	const [documentType, setDocumentType] = useState('INVOICE')
	const classes = useStyles()
	const dropArea = useRef()
	const dispatch = useDispatch()
	const { id } = useParams()
	const [openSendEmailManualUploads, setOpenSendEmailManualUploads] = useState(false)
	const [openSendEmailBol, setOpenSendEmailBol] = useState(false)
	const [openSendEmailLabel, setOpenSendEmailLabel] = useState(false)

	const sections = ['BOL & Pallet Label', 'Carrier Documents', 'Manual Uploads']

	const setDocumentsFromShipment = (shipmentDetails) => {
		const carrierDocs = shipmentDetails.carrier_documents?.map((carrierDoc) => ({
			url: carrierDoc.bucketUrl,
			type: carrierDoc.type,
			name: carrierDoc.name,
		}))
		const bolAndLabelDocs = []
		if (shipmentDetails.bol_url_file) {
			bolAndLabelDocs.push({ name: 'BOL', type: 'BOL & Pallet Label', url: shipmentDetails.bol_url_file })
		}
		if (shipmentDetails.label_url_file) {
			bolAndLabelDocs.push({ name: 'Label', type: 'BOL & Pallet Label', url: shipmentDetails.label_url_file })
		}
		const userDocs = shipmentDetails?.documents ? JSON.parse(shipmentDetails?.documents) : []
		setCarrierDocuments(carrierDocs)
		setBolAndLabelDocuments(bolAndLabelDocs)
		setUserDocuments(userDocs)
	}

	useEffect(() => {
		setDocumentsFromShipment(shipment)
	}, [shipment])

	const handleDragOver = (e) => {
		e.preventDefault()
		e.stopPropagation()
	}

	const handleDrop = useCallback(
		(e) => {
			e.preventDefault()
			e.stopPropagation()

			const { files } = e.dataTransfer

			if (files && files.length) {
				const allFiles = Array.from(files).map((file) => file)
				const doc = {
					file: allFiles[0],
					name: allFiles[0].name,
					type: documentType,
				}
				setDocumentToUpload(doc)
			}
		},
		[documentType],
	)

	const handleSelectFiles = (e) => {
		const { files } = e.target
		const allFiles = Array.from(files).map((file) => file)
		const doc = {
			file: allFiles[0],
			name: allFiles[0].name,
			type: documentType,
		}
		setDocumentToUpload(doc)
	}

	const uploadDocument = async () => {
		if (documentType === 'INVOICE') {
			sweetAlertModal('error', 'Select a document type, please.', '', 'OK', true, false, null)
		} else {
			const formData = new FormData()
			formData.append('documents[0][name]', documentToUpload.name)
			formData.append('documents[0][file]', documentToUpload.file)
			formData.append('documents[0][type]', documentType)
			const { status, resp } = await dispatch(uploadShipmentDocument(shipment.id, formData))
			if (status === 'success') {
				sweetAlertModal(status, 'The document was successfully uploaded', '', 'OK', true, false, null)
				const userDocs = [...userDocuments]
				userDocs.push({
					name: documentToUpload.name,
					type: documentType,
					url: resp.data.data[0].bucketUrl,
				})
				setUserDocuments(userDocs)
				setDocumentToUpload({})
			} else {
				sweetAlertModal('error', 'An error occurred while saving the Document', '', 'OK', true, false, null)
			}
		}
	}

	useEffect(() => {
		const dropAreaRef = dropArea.current
		dropAreaRef.addEventListener('dragover', handleDragOver)
		dropAreaRef.addEventListener('drop', handleDrop)

		return () => {
			dropAreaRef.removeEventListener('dragover', handleDragOver)
			dropAreaRef.removeEventListener('drop', handleDrop)
		}
	}, [handleDrop])

	const handleDownloadDocument = async (data) => {
		if (data?.url) {
			window.open(data?.url, '_blank').focus()
		} else {
			sweetAlertModal('error', 'No download URL available.', '', 'OK', true, false, null)
		}
	}

	const handleDownloadBlobLabelDocument = async (fileDocument) => {
		const { name } = fileDocument
		let result
		if (name === 'Label') {
			result = await dispatch(downloadDocumentLabel(id))
		} else {
			result = await dispatch(downloadDocumentBlob(id))
		}
		try {
			const {
				data: { data: blob },
			} = result
			const file = new Blob([blob], { type: 'application/pdf' })
			const fileUrl = URL.createObjectURL(file)
			const downloadLink = document.createElement('a')
			downloadLink.href = fileUrl
			downloadLink.download =
				name === 'Label' ? `LABEl-${shipment?.bol_identifier ?? ''}` : `BOL-${shipment?.bol_identifier ?? ''}`
			downloadLink.click()
		} catch (error) {
			sweetAlertModal('error', 'An error has occurred', error, 'Ok', true, false)
		}
	}

	const handleDownloadDocumentAdded = async (fileDocument) => {
		const params = `name=${fileDocument.name}&type=${fileDocument.type}`
		const result = await dispatch(downloadShipmentDocument(id, params))
		try {
			const {
				data: { data: blob },
			} = result
			const file = new Blob([blob], { type: 'application/pdf' })
			const fileUrl = URL.createObjectURL(file)
			const downloadLink = document.createElement('a')
			downloadLink.href = fileUrl
			downloadLink.download = fileDocument.name
			downloadLink.click()
		} catch (error) {
			sweetAlertModal('error', 'An error has occurred', error, 'Ok', true, false)
		}
	}

	const handleDeleteDocument = (doc) => {
		console.log(doc)
	}

	const handleSubmitSendEmailManualUploads = async (listEmails) => {
		const formData = new FormData()
		formData.append('fileName', documentToUpload.name)
		formData.append('file', documentToUpload.file)
		formData.append('emails', JSON.stringify(listEmails))
		const [errorService, data] = await reqSendByMailDocuments(formData, 'multipart/form-data')
		if (errorService) {
			return {
				status: false,
				message:
					data?.err?.message ?? data?.message ?? 'An error occurred while sending the email, please try again later',
			}
		}
		return {
			status: true,
		}
	}
	const handleSubmitSendEmailBol = async (listEmails) => {
		const body = {
			emails: listEmails,
			BOL: +id,
		}
		const [errorService, data] = await reqSendByMailDocuments(body)
		if (errorService) {
			return {
				status: false,
				message: data?.err?.message ?? 'An error occurred while sending the email, please try again later',
			}
		}
		return {
			status: true,
		}
	}
	const handleSubmitSendEmailLabel = async (listEmails) => {
		const body = {
			emails: listEmails,
			labelId: +id,
		}
		const [errorService, data] = await reqSendByMailDocuments(body)
		if (errorService) {
			return {
				status: false,
				message: data?.err?.message ?? 'An error occurred while sending the email, please try again later',
			}
		}
		return {
			status: true,
		}
	}

	return (
		<div style={{ marginLeft: '10px' }}>
			<DocumentsTable
				style={{ marginLeft: '10px' }}
				tableTitle={sections[0]}
				documents={bolAndLabelDocments}
				actionIcon={<SystemUpdateAltIcon color="secondary" fontSize="small" />}
				actionName="Download"
				actionHandler={handleDownloadBlobLabelDocument}
				isSendEmail
				sendEmail={(type) => {
					if (type === 'BOL') setOpenSendEmailBol(true)
					else setOpenSendEmailLabel(true)
				}}
			/>
			<DocumentsCard
				documents={bolAndLabelDocments}
				showPreviewButton
				showDownloadButton
				down
				showDeleteButton={false}
			/>
			<Divider />
			<DocumentsTable
				style={{ marginLeft: '10px' }}
				tableTitle={sections[1]}
				documents={carrierDocuments}
				actionIcon={<SystemUpdateAltIcon color="secondary" fontSize="small" />}
				actionName="View Document"
				actionHandler={handleDownloadDocument}
			/>
			<DocumentsCard documents={carrierDocuments} showPreviewButton showDownloadButton showDeleteButton={false} />
			<Divider />
			<Grid container style={{ display: 'flex', alignItems: 'flex-start' }} spacing={2}>
				<Grid container>
					<h3 className={classes.manualUploadsTitle}>{sections[2]}</h3>
				</Grid>
				<Grid item lg={6} xl={6} sm={6} md={6} xs={12}>
					<FormControl size="small" style={{ width: '100%' }} variant="outlined">
						<InputLabel className={classes.root}>Document Type *</InputLabel>
						<Select
							name="FreightClass"
							value={documentType}
							onChange={(e) => {
								setDocumentType(e.target.value)
							}}
							label="Document Type *"
							variant="outlined"
							placeholder="Document Type *"
						>
							{documentsOptions.map((option, index) => (
								<MenuItem value={option.value} key={`id-${index + 1}`}>
									{option.label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item lg={4} xl={4} md={6} sm={6} xs={12}>
					<div className={classes.dragAndDropArea} ref={dropArea}>
						<InsertDriveFileOutlinedIcon className={classes.dragAndDropIcon} />
						{documentToUpload && documentToUpload.name ? (
							<p className={classes.dragAndDropTitleWeb}>{documentToUpload.name}</p>
						) : (
							<>
								<p className={classes.dragAndDropTitleMobile}>Select the documents from your device here</p>
								<p className={classes.dragAndDropTitleWeb}>Drag & Drop your files here</p>
							</>
						)}
						<input type="file" multiple className={classes.dragAndDropUploader} onChange={handleSelectFiles} />
					</div>
				</Grid>
				<Grid item lg={2} xl={2} md={12} sm={12} xs={12} className={classes.dragAndDropActionButtonContainer}>
					<Button
						disabled={!documentToUpload.name}
						onClick={uploadDocument}
						type="button"
						className={classes.dragAndDropActionButton}
						aria-label="Add new detail"
					>
						<AddIcon color="secondary" />
						<span>Add Documents</span>
					</Button>
					<Button
						disabled={Object.keys(documentToUpload).length === 0}
						type="button"
						className={`${classes.dragAndDropActionButton} ${classes.containerSendEmail}`}
						onClick={() => setOpenSendEmailManualUploads(true)}
					>
						<Send color="secondary" fontSize="small" />
						<span>Send Email</span>
					</Button>
				</Grid>
			</Grid>
			<DocumentsTable
				style={{ marginLeft: '10px' }}
				tableTitle="Added"
				documents={userDocuments}
				actionIcon={<DeleteOutlineIcon color="error" fontSize="small" />}
				secondActionIcon={<SystemUpdateAltIcon color="secondary" fontSize="small" />}
				actionName="Delete"
				actionHandler={handleDeleteDocument}
				secondActionName="Download"
				secondActionHandler={handleDownloadDocumentAdded}
			/>
			<DocumentsCard documents={userDocuments} showPreviewButton showDownloadButton showDeleteButton={false} />
			{openSendEmailManualUploads && (
				<SendByMail
					open={openSendEmailManualUploads}
					handleClose={() => setOpenSendEmailManualUploads(false)}
					title="Email Document"
					description="Enter an email address to receive the document"
					onSubmit={handleSubmitSendEmailManualUploads}
				/>
			)}
			{openSendEmailBol && (
				<SendByMail
					open={openSendEmailBol}
					handleClose={() => setOpenSendEmailBol(false)}
					title="Email BOL"
					description="Enter an email address to receive the BOL"
					onSubmit={handleSubmitSendEmailBol}
				/>
			)}
			{openSendEmailLabel && (
				<SendByMail
					open={openSendEmailLabel}
					handleClose={() => setOpenSendEmailLabel(false)}
					title="Email Label"
					description="Enter an email address to receive the Label"
					onSubmit={handleSubmitSendEmailLabel}
				/>
			)}
		</div>
	)
}

ShipmentDocuments.propTypes = {
	shipment: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default ShipmentDocuments
