const packageTypeOptions = [
	'PALLET',
	'CRATE',
	'BOX',
	'TOTE',
	'CASE',
	'GAYLORD',
	'BUNDLE',
	'PAIL',
	'DRUM',
	'ROLL',
	'PIECE',
]

export const detailsPackageTypeOptions = ['BOX', 'CARTON', 'CRATE', 'DRUM', 'BAGS', 'OTHER']

export const freightClassOptions = [
	'50',
	'55',
	'60',
	'65',
	'70',
	'77.5',
	'85',
	'92.5',
	'100',
	'110',
	'125',
	'150',
	'175',
	'200',
	'250',
	'300',
	'400',
	'500',
]

export const originAccessorials = [
	{
		accessorialId: 4,
		name: 'LIFT GATE ORIGIN',
		internalCode: 'LFGO_FP',
		value: null,
		value_required: false,
		origin_destination: 'ORIGIN',
	},
	{
		accessorialId: 5,
		name: 'RESIDENTIAL PICKUP',
		internalCode: 'REPK_FP',
		value: null,
		value_required: false,
		origin_destination: 'ORIGIN',
	},
	{
		accessorialId: 10,
		name: 'LIMITED ACCESS PICKUP',
		internalCode: 'LMAP_FP',
		value: null,
		value_required: false,
		origin_destination: 'ORIGIN',
	},
	{
		accessorialId: 12,
		name: 'PROTECT FROM FREEZING',
		internalCode: 'PTFR_FP',
		value: null,
		value_required: false,
		origin_destination: 'ORIGIN',
	},
	{
		accessorialId: 8,
		name: 'INSIDE PICKUP',
		internalCode: 'INPI_FP',
		value: null,
		value_required: false,
		origin_destination: 'ORIGIN',
	},
	{
		accessorialId: 3,
		name: 'TRADE SHOW PICKUP',
		internalCode: 'TRSP_FP',
		value: null,
		value_required: false,
		origin_destination: 'ORIGIN',
	},
]

export const destinationAccessorials = [
	{
		accessorialId: 21,
		name: 'DELIVERY APPOINTMENT',
		internalCode: 'DESAP_FP',
		value: null,
		value_required: false,
		origin_destination: 'DESTINATION',
	},
	{
		accessorialId: 1,
		name: 'LIFT GATE DELIVERY',
		internalCode: 'LFGD_FP',
		value: null,
		value_required: false,
		origin_destination: 'DESTINATION',
	},
	{
		accessorialId: 2,
		name: 'RESIDENTIAL DELIVERY',
		internalCode: 'REDL_FP',
		value: null,
		value_required: false,
		origin_destination: 'DESTINATION',
	},
	{
		accessorialId: 9,
		name: 'LIMITED ACCESS DELIVERY',
		internalCode: 'LMAD_FP',
		value: null,
		value_required: false,
		origin_destination: 'DESTINATION',
	},
	{
		accessorialId: 11,
		name: 'NOTIFY CONSIGNEE',
		internalCode: 'NTCO_FP',
		value: null,
		value_required: false,
		origin_destination: 'DESTINATION',
	},
	{
		accessorialId: 13,
		name: 'SORT AND SEG DELIVERY',
		internalCode: 'SSEG_FP',
		value: null,
		value_required: false,
		origin_destination: 'DESTINATION',
	},
	{
		accessorialId: 7,
		name: 'INSIDE DELIVERY',
		internalCode: 'INDE_FP',
		value: null,
		value_required: false,
		origin_destination: 'DESTINATION',
	},
	{
		accessorialId: 14,
		name: 'TRADE SHOW DELIVERY',
		internalCode: 'TRSD_FP',
		value: null,
		value_required: false,
		origin_destination: 'DESTINATION',
	},
]

export const hazMatTypeOptions = [
	{
		value: 'UN',
	},
	{
		value: 'NA',
	},
]

export const hazMatGroupOptions = [
	{
		value: 'I',
	},
	{
		value: 'II',
	},
	{
		value: 'III',
	},
	{
		value: 'N/A',
	},
]

export const hazMatClassOptions = [
	{
		value: 'Class 1.1',
	},
	{
		value: 'Class 1.2',
	},
	{
		value: 'Class 1.3',
	},
	{
		value: 'Class 1.4',
	},
	{
		value: 'Class 1.5',
	},
	{
		value: 'Class 1.6',
	},
	{
		value: 'Class 2.1',
	},
	{
		value: 'Class 2.2',
	},
	{
		value: 'Class 2.3',
	},
	{
		value: 'Class 3',
	},
	{
		value: 'Class 4.1',
	},
	{
		value: 'Class 4.2',
	},
	{
		value: 'Class 4.3',
	},
	{
		value: 'Class 5.1',
	},
	{
		value: 'Class 5.2',
	},
	{
		value: 'Class 6.1',
	},
	{
		value: 'Class 6.2',
	},
	{
		value: 'Class 7',
	},
	{
		value: 'Class 8',
	},
	{
		value: 'Class 9',
	},
]

export const locationOptions = [
	{
		label: 'None',
		value: 'NONE',
	},
	{
		label: 'Origin',
		value: 'SHIPPER',
	},
	{
		label: 'Destination',
		value: 'CONSIGNEE',
	},
]

export const countryOptions = [
	{
		label: 'USA',
		value: 'USA',
	},
	{
		label: 'Canada',
		value: 'CAN',
	},
]

export const searchByOptions = [
	{
		label: 'Company Name',
		value: 'NAME',
	},
	{
		label: 'Address',
		value: 'ADDRESS',
	},
	{
		label: 'Contact Information',
		value: 'CONTACT',
	},
	{
		label: 'Accessorials',
		value: 'ACCESSORIALS',
	},
	{
		label: 'Location ID',
		value: 'LOCATION_ID',
	},
]

export const searchAccountByOptions = [
	{
		label: 'Active',
		value: 'active',
	},
	{
		label: 'Inactive',
		value: 'inactive',
	},
]

export const searchItemsByOptions = [
	{
		label: 'All',
		value: '',
	},
	{
		label: 'Description',
		value: 'DESCRIPTION',
	},
	{
		label: 'Freightclass',
		value: 'CLASS',
	},
	{
		label: 'NMFC',
		value: 'NMFC',
	},
	{
		label: 'Item ID',
		value: 'NAME',
	},
]

export const filterByStatusOptions = [
	{
		label: 'All',
		value: '',
	},
	{
		label: 'Active',
		value: 'true',
	},
	{
		label: 'Inactive',
		value: 'false',
	},
]

export const notificationsOptions = [
	{
		label: 'Notify when someone creates a new quote',
		value: 'NEW_QUOTE',
	},
	{
		label: 'Notify when someone creates a new shipment',
		value: 'NEW_SHIPMENT',
	},
]

export const initialReferences = [
	{
		name: 'PO_NUMBER',
		value: 'false',
	},
	{
		name: 'SALES_ORDER',
		value: 'false',
	},
	{
		name: 'CUSTOMER_REFERENCE',
		value: 'false',
	},
]

export const referencesTypesOptions = [
	{
		label: 'PO number',
		value: 'PO_NUMBER',
	},
	{
		label: 'Sales order',
		value: 'SALES_ORDER',
	},
	{
		label: 'Order number',
		value: 'ORDER_NUMBER',
	},
	{
		label: 'Release number',
		value: 'RELEASE_NUMBER',
	},
	{
		label: 'Must arrive by date',
		value: 'MUST_ARRIVE_BY_DATE',
	},
	{
		label: 'Customer reference',
		value: 'CUSTOMER_REFERENCE',
	},
]

export const statusOptions = [
	{
		label: 'Gold',
		value: 'GOLD',
	},
	{
		label: 'Platinum ',
		value: 'PLATINUM',
	},
	{
		label: 'Diamond',
		value: 'DIAMOND',
	},
	{
		label: '90 Day Onboarding',
		value: '90 DAY ONBOARDING',
	},
]

export const carrierOptions = [
	{
		label: 'All',
		value: '',
	},
	{
		label: 'Standard',
		value: 'STANDARD',
	},
	{
		label: 'Economy',
		value: 'ECONOMY',
	},
]

export const contractTypeOptions = [
	{
		label: 'Blanket',
		value: 'BLANKET',
	},
	{
		label: 'Csp',
		value: 'CSP',
	},
]

export const serviceTypeOptions = [
	{
		label: 'Ltl standard',
		value: 'LTL_STANDARD',
	},
	{
		label: 'Volume',
		value: 'VOLUME',
	},
	{
		label: 'Guaranteed 5pm',
		value: 'GUARANTEED_5PM',
	},
	{
		label: 'Guaranteed noon',
		value: 'GUARANTEED_NOON',
	},
	{
		label: 'Guaranteed 9am',
		value: 'GUARANTEED_9AM',
	},
]

export const documentsOptions = [
	{
		label: 'COMMERCIAL INVOICE',
		value: 'COMMERCIAL_INVOICE',
	},
	{
		label: 'DELIVERY ORDER',
		value: 'DELIVERY_ORDER',
	},
	{
		label: 'HAZARDOUS MATERIAL FORM',
		value: 'HAZARDOUS_MATERIAL_FORM',
	},
	{
		label: 'INTERNATIONAL / CUSTOM PAPERWORK',
		value: 'CUSTOM_PAPERWORK',
	},
	{
		label: 'PACKING LIST / PICK SHEET',
		value: 'PACKING_LIST',
	},
	{
		label: 'RELEASE FORM',
		value: 'RELEASE_FORM',
	},
	{
		label: 'SPEC SHEET',
		value: 'SPEC_SHEET',
	},
	{
		label: 'WEIGHT VERIFICATION',
		value: 'WEIGHT_VERIFICATION',
	},
	{
		label: 'OTHER',
		value: 'OTHER',
	},
]

export default packageTypeOptions
