import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	tableCell: {
		backgroundColor: theme.palette.neutral.whiteGray,
		fontSize: 12,
		fontWeight: theme.typography.fontWeightBold,
	},
	headCell: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		whiteSpace: 'nowrap',
	},
}))

export default useStyles
