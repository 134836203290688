import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'

import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TablePagination,
	TableRow,
	Paper,
	CircularProgress,
} from '@material-ui/core'

import { useSelector, useDispatch } from 'react-redux'
import { invoiceTableCells } from '../../../../utils/headCells'

import { updatePaginator } from '../../../../redux/actions/Invoices.actions'
import { setCurrentPage, setRowsPerPage } from '../../../../redux/actions/InvoicesFilters.actions'

import SessionContext from '../../../../context/session'
import { permissionName } from '../../../../utils/constants'
import isPermit from '../../../../utils/permission'

import TableToolbar from '../TableToolbar'
import TableHeader from '../TableHeader'
import InvoiceTableRow from '../InvoiceTableRow'
import NoResultsFound from '../../../../components/NoResultsFound'

import useStyles from './styles'

const TableList = (props) => {
	const { tableType, pageReset, handleSortColumn } = props
	const classes = useStyles()
	const dispatch = useDispatch()
	const invoices = useSelector((state) => state.invoices)
	const invoicesFilters = useSelector((state) => state.invoicesFilters)
	const [selected, setSelected] = useState([])
	const [page, setPage] = useState(0)
	const { getUserData } = useContext(SessionContext)
	const { permissions } = getUserData()
	const showActions =
		isPermit(permissions, permissionName.INVOICES_DOWNLOAD) ||
		isPermit(permissions, permissionName.INVOICES_SEND_BY_EMAIL)
	const permitInvoiceDownload = isPermit(permissions, permissionName.INVOICES_DOWNLOAD)
	const permitInvoiceSendEmail = isPermit(permissions, permissionName.INVOICES_SEND_BY_EMAIL)
	const permitInvoiceDetails = isPermit(permissions, permissionName.INVOICES_READ)

	const isSelected = (name) => selected.indexOf(name) !== -1

	const handleSelectAllClick = (event) => {
		const data = invoices.invoices

		if (event.target.checked) {
			const newSelecteds = data.map((item) => item.id)
			setSelected(newSelecteds)
			return
		}
		setSelected([])
	}

	const handleChangePage = (event, newPage) => {
		dispatch(setCurrentPage(newPage * invoicesFilters.rowsPerPage))
		dispatch(updatePaginator(newPage * invoicesFilters.rowsPerPage))
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		dispatch(setRowsPerPage(Number(event.target.value)))
		dispatch(setCurrentPage(0))
		setPage(0)
	}

	useEffect(() => {
		if (pageReset) setPage(0)
	}, [pageReset])

	const renderTableBody = () => {
		let data = []
		if (invoices.isLoading) {
			return (
				<TableRow>
					<TableCell align="center" colSpan={Object.keys(invoiceTableCells).length}>
						<CircularProgress />
					</TableCell>
				</TableRow>
			)
		}
		data = invoices.invoices.map((row, index) => {
			const isItemSelected = isSelected(row.id)
			const labelId = `enhanced-table-checkbox-${index}`

			return (
				<InvoiceTableRow
					key={row.id}
					labelId={labelId}
					isItemSelected={isItemSelected}
					data={row}
					selected={selected}
					setSelected={setSelected}
					permitInvoiceDownload={permitInvoiceDownload}
					permitInvoiceSendEmail={permitInvoiceSendEmail}
					permitInvoiceDetails={permitInvoiceDetails}
					showActions={showActions}
				/>
			)
		})
		if (data.length === 0) {
			return (
				<TableRow>
					<TableCell align="center" colSpan={Object.keys(invoiceTableCells).length + 2}>
						<NoResultsFound type="Invoices" />
					</TableCell>
				</TableRow>
			)
		}
		return data
	}

	return (
		<div className={classes.root}>
			<TableToolbar
				selected={selected}
				permitInvoiceDownload={permitInvoiceDownload}
				permitInvoiceSendEmail={permitInvoiceSendEmail}
				showActions={showActions}
			/>
			<Paper className={classes.paper} elevation={0}>
				<TableContainer>
					<Table className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
						<TableHeader
							numSelected={selected.length}
							onSelectAllClick={handleSelectAllClick}
							rowCount={invoices.invoices.length}
							tableType={tableType}
							selected={selected}
							handleSortColumn={handleSortColumn}
							showActions={showActions}
						/>
						<TableBody>{renderTableBody()}</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					classes={{
						root: classes.tablePaginator,
						toolbar: classes.tablePaginatorToolbar,
						actions: classes.tablePaginatorActions,
					}}
					rowsPerPageOptions={[10, 20, 50, 100]}
					component="div"
					count={invoices.totalInvoices}
					rowsPerPage={Number(invoicesFilters.rowsPerPage)}
					page={page}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
				/>
			</Paper>
		</div>
	)
}

TableList.propTypes = {
	tableType: PropTypes.string.isRequired,
	pageReset: PropTypes.string.isRequired,
	handleSortColumn: PropTypes.func.isRequired,
}

export default TableList
