import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
	newAccountRadioLabel: {
		fontSize: '12px',
		fontWeight: 400,
		color: '#2C2C2C',
	},
	newAccountSettingReferencesFormRow: {
		display: 'flex',
		flexDirection: 'row',
		marginLeft: '20px',
		marginBottom: '20px',
		alignItems: 'center',
		[theme.breakpoints.down('md')]: {
			display: 'flex',
			flexDirection: 'column',
			marginRight: '10px',
			marginLeft: '0px',
		},
	},
	newAccountsReferenceTypeField: {
		width: '45%',
		marginRight: '30px',
		[theme.breakpoints.down('md')]: {
			width: '98%',
			marginBottom: '20px',
		},
	},
	addReferenceButton: {
		height: '48px',
		fontSize: '13px',
		fontWeight: '500',
		color: '#141313',
		marginLeft: '2%',
		alignSelf: 'center',
		justifyContent: 'flex-end',
		textTransform: 'none',
		'&:hover': {},
		'@media only screen and (max-width: 768px)': {
			width: '140px',
		},
		[theme.breakpoints.down('md')]: {
			width: '90%',
			marginBottom: '10px',
			marginLeft: '20px',
		},
	},
	deleteIconButton: {
		color: theme.palette.secondary.main,
		fontSize: '25px',
	},
	radioButtonOptionLabel: {
		fontSize: '12px',
		marginRight: '10px',
	},
}))
