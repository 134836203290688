import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	tableCell: {
		height: 'auto',
		padding: '0 16px',
		fontSize: 14,
		fontWeight: theme.typography.fontWeightRegular,
	},
	tableCellItems: {
		lineHeight: '22px',
		'& span': {
			color: theme.palette.primary.main,
		},
	},
	viewIcon: {
		color: theme.palette.primary.main,
		cursor: 'pointer',
	},
	invoiceCarrierImg: {
		display: 'inline-block',
		maxWidth: '40px',
	},
	blockInfo: {
		margin: '0',
	},
	packageType: {
		textTransform: 'lowercase',
	},
	statusActive: {
		color: theme.palette.success.main,
		backgroundColor: theme.palette.success.light,
		fontSize: '11px',
		padding: '0 8px',
	},
	statusInactive: {
		color: theme.palette.neutral.white,
		backgroundColor: theme.palette.semantic.red.primary,
		fontSize: '11px',
		padding: '0 8px',
	},
	avatar: {
		marginRight: '5px',
		verticalAlign: 'middle',
		width: '40px',
	},
	tableRow: {
		'& td:first-child': {
			minWidth: '72px',
			textAlign: 'center',
		},
	},
	tableRowInside: {
		'& td:first-child': {
			textAlign: 'right',
		},
	},
	imgItem: {
		display: 'flex',
		justifyContent: 'left',
		alignItems: 'center',
		'& div': {
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			maxWidth: '300px',
			cursor: 'pointer',
		},
	},
	carrierLogo: {
		width: 37,
		height: 37,
		marginRight: 12,
		objectFit: 'contain',
	},
	capitalize: {
		textTransform: 'capitalize',
	},
}))

export default useStyles
