import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import useStyles from './styles'
import TabPanel from '../../../../../components/TabPanel'
import NewQuoteShipmentStepFour from '../../../../newQuoteShipment/components/NewQuoteShipmentStepFour'
import Tracking from '../../containers/Tracking/Tracking'
import AuditHistoryTab from '../AuditHistoryTab'
import ShipmentDocuments from '../../containers/ShipmentDocuments'

const AntTabs = withStyles((theme) => ({
	root: {
		borderBottom: '1px solid',
		borderBottomColor: theme.palette.neutral.darkGray,
	},
	indicator: {
		backgroundColor: theme.palette.secondary.main,
	},
	// eslint-disable-next-line react/jsx-props-no-spreading
}))((props) => <Tabs disableRipple {...props} />)

const AntTab = withStyles((theme) => ({
	root: {
		textTransform: 'none',
		fontSize: 16,
		minWidth: 72,
		fontWeight: 400,
		marginRight: theme.spacing(4),
		opacity: 1,
		color: '#2C2C2C',
		'&:hover': {
			color: theme.palette.secondary.main,
			opacity: 1,
		},
		'&$selected': {
			color: theme.palette.primary.main,
			fontWeight: theme.typography.fontWeightBold,
		},
		'&:focus': {
			color: theme.palette.primary.main,
			fontWeight: theme.typography.fontWeightBold,
		},
	},
	selected: {},
	// eslint-disable-next-line react/jsx-props-no-spreading
}))((props) => <Tab disableRipple {...props} />)
const MenuTabs = (props) => {
	const { shipment, handleChangeTab, value } = props
	const classes = useStyles()
	const handleChange = (event, newValue) => {
		handleChangeTab(newValue)
	}

	return (
		<>
			<div className={classes.tabsWrapper}>
				<div className={classes.root}>
					<div className={classes.demo1}>
						<AntTabs
							value={value}
							variant="scrollable"
							scrollButtons="auto"
							onChange={handleChange}
							aria-label="ant example"
						>
							<AntTab label="Shipment information" />
							<AntTab label="Tracking" />
							<AntTab label="Documents" />
							<AntTab label="Audit History" />
						</AntTabs>
						<Typography className={classes.padding} />
						<TabPanel value={value} index={0}>
							{shipment ? <NewQuoteShipmentStepFour isDetail dataConfirm={shipment} /> : null}
						</TabPanel>
						<TabPanel value={value} index={1}>
							{shipment ? <Tracking shipmentDetail={shipment} /> : null}
						</TabPanel>
						<TabPanel value={value} index={2}>
							<ShipmentDocuments shipment={shipment} />
						</TabPanel>
						<TabPanel value={value} index={3}>
							<AuditHistoryTab />
						</TabPanel>
					</div>
				</div>
			</div>
		</>
	)
}
MenuTabs.propTypes = {
	shipment: PropTypes.objectOf(PropTypes.any),
	handleChangeTab: PropTypes.func.isRequired,
	value: PropTypes.number.isRequired,
}
MenuTabs.defaultProps = {
	shipment: {},
}
export default MenuTabs
