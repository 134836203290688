/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import {
	reqUserCompanies,
	reqGetAccountInformation,
	reqCreateAccounts,
	reqCreateAccountSettings,
	reqGetAccountSettings,
	reqEditAccount,
	reqEditAccountStatus,
	reqBulkAccountsCreation,
	reqBulkAccountsStatusUpdate,
	reqEditAccountStepStatus,
} from '../requests/Accounts.request'

const userCompaniesSlice = createSlice({
	name: 'userCompaniesSlice',
	initialState: {
		accounts: [],
		isLoading: false,
		error: false,
		filterApplied: '',
		page: 0,
		limit: 10,
		records: 0,
		parentCompanyInUpdate: {},
		childrenCompaniesInUpdate: [],
	},
	reducers: {
		startLoading: (state) => {
			state.isLoading = true
		},
		hasError: (state, action) => {
			state.error = action.payload
			state.isLoading = false
		},
		updateRecords: (state, action) => {
			state.records = action.payload
		},
		userCommpaniesSuccess: (state, action) => {
			state.accounts = action.payload
			state.isLoading = false
		},
		accountSettingsSuccess: (state, action) => {
			state.accountSettings = action.payload
			state.isLoading = false
		},
		setParentCompanyInUpdate: (state, action) => {
			state.parentCompanyInUpdate = action.payload
		},
		setChildrenCompaniesInUpdate: (state, action) => {
			state.childrenCompaniesInUpdate = action.payload
		},
	},
})

const { actions, reducer } = userCompaniesSlice

export const {
	startLoading,
	hasError,
	userCommpaniesSuccess,
	accountSettingsSuccess,
	updateRecords,
	setParentCompanyInUpdate,
	setChildrenCompaniesInUpdate,
} = actions

export const fetchAccounts = (params, config) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqUserCompanies(params, config)
	if (error) {
		dispatch(hasError(error))
		return { status: 'error', resp: error }
	}
	dispatch(userCommpaniesSuccess(data.companies))
	dispatch(updateRecords(data.records))
	return { status: 'success' }
}

export const createAccounts = (body, config) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqCreateAccounts(body, config)
	if (error) {
		dispatch(hasError(data))
		return { status: 'error', resp: data }
	}
	return { status: 'success', resp: data }
}

export const createAccountSettings = (body, config) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqCreateAccountSettings(body, config)
	if (error) {
		dispatch(hasError(data))
		return { status: 'error', resp: data }
	}
	return { status: 'success', resp: data }
}

export const fetchAccountSettings = (id) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqGetAccountSettings(id)
	if (error) {
		dispatch(hasError(error))
		return { status: 'error', resp: error }
	}
	dispatch(accountSettingsSuccess(data.companies))
	return { status: 'success', resp: data.companies }
}

export const fetchAccountInformation = (config, id) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqGetAccountInformation(config, id)
	if (error) {
		dispatch(hasError(error))
		return { status: 'error', resp: error }
	}
	dispatch(accountSettingsSuccess(data.companies))
	return { status: 'success', resp: data.companies }
}

export const editAccount = (params, id) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqEditAccount(params, id)
	if (error) {
		dispatch(hasError(error))
		return { status: 'error', resp: data }
	}
	return { status: 'success', resp: data }
}

export const editAccountStatus = (params, config) => async (dispatch) => {
	const [error, data] = await reqEditAccountStatus(params, config)
	if (error) {
		dispatch(hasError(error))
		return { status: 'error', resp: error }
	}
	return { status: 'succes', resp: data }
}

export const editAccountStepStatus = (params, config) => async (dispatch) => {
	const [error, data] = await reqEditAccountStepStatus(params, config)
	if (error) {
		dispatch(hasError(error))
		return { status: 'error', resp: error }
	}
	return { status: 'succes', resp: data }
}

export const createAccountsBulk = (body, config) => async (dispatch) => {
	const [error, data] = await reqBulkAccountsCreation(body, config)
	if (error) {
		dispatch(hasError(data))
		return { status: 'error', resp: data }
	}
	return { status: 'success', resp: data }
}

export const editAccountEstatusBulk = (body, config) => async (dispatch) => {
	const [error, data] = await reqBulkAccountsStatusUpdate(body, config)
	if (error) {
		dispatch(hasError(data))
		return { status: 'error', resp: data }
	}
	return { status: 'success', resp: data }
}

export default reducer
