import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	root: { padding: 24 },
	detailsContainer: {
		margin: '-16px -24px',
		[theme.breakpoints.down(768)]: {
			margin: '-16px -8px',
		},
	},
	rootGray: { padding: 24, background: theme.palette.neutral.whiteGray },
	twoGrids: { display: 'grid', gridTemplateColumns: '1fr 1fr' },
	sectionTitle: {
		fontWeight: 'bold',
		fontSize: 17,
		color: '#EC3514',
		marginLeft: 10,
	},
	spanSubTitle: {
		fontWeight: 'bold',
		fontSize: 15,
		color: '#EC3514',
	},
	alignCenter: { display: 'flex', alignItems: 'center' },
	iconMargin: { marginRight: 12 },
	linkButton: {
		borderRadius: '4px',
		padding: '13px 50px',
		color: '#FFF',
		fontSize: '17px',
		fontWeight: theme.typography.fontWeightMedium,
		textDecoration: 'none',
		textAlign: 'center',
		lineHeight: '30px',
		cursor: 'pointer',
		[theme.breakpoints.down(768)]: {
			marginBottom: '10px',
		},
	},
	linkButtonIcon: {
		margin: '0 24px',
		textTransform: 'inherit',
		fontWeight: 600,
	},
	buttons: {
		display: 'flex',
		justifyContent: 'end',
		flexDirection: 'column',
		padding: '20px',
		width: '100%',
		background: theme.palette.neutral.white,
		[theme.breakpoints.up(768)]: {
			flexDirection: 'row-reverse',
		},
	},
	shipmentDetailContainer: {
		padding: '0px 24px 0px 10px',
		width: '100%',
		height: '100px',
		background: theme.palette.neutral.whiteGray,
	},
	shipmentHeader: {
		display: 'flex',
		justifyContent: 'space-between',
		marginLeft: 20,
		[theme.breakpoints.down('md')]: {
			marginLeft: 10,
		},
	},
	shipmentHeaderInformation: {
		display: 'flex',
		flexDirection: 'column',
	},
	shipmentSubtitle: {
		fontSize: 16,
		height: '50%',
	},
	shipmentTitleRow: {
		display: 'flex',
		alignItems: 'center',
		marginTop: theme.spacing(2),
	},
	shipmentOptions: {
		alignSelf: 'center',
		marginBottom: '20px',
	},
	status: {
		fontSize: 20,
		fontWeight: theme.typography.fontWeightRegular,
		color: theme.palette.neutral.white,
		padding: '0 14px 2px',
		marginLeft: 8,
		height: 28,
	},
	statusBlue: {
		backgroundColor: theme.palette.secondary.main,
	},
	statusRed: {
		backgroundColor: theme.palette.primary.main,
	},
	statusOrange: {
		backgroundColor: theme.palette.semantic.orange.primary,
	},
	statusGray: {
		backgroundColor: theme.palette.neutral.secondaryGrey,
		color: theme.palette.neutral.black,
	},
	statusGreen: {
		backgroundColor: theme.palette.semantic.green.primary,
	},
}))

export default useStyles
