import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { Button, makeStyles } from '@material-ui/core'

import SessionContext from '../../../../context/session/index'

const useStyles = makeStyles((theme) => ({
	button: {
		width: '175px',
		height: '48px',
		backgroundColor: '#FAFBFC',
		margin: theme.spacing(0, 0, 0),
		alignSelf: 'end',
		textTransform: 'none',
		'@media only screen and (max-width: 1024px)': {
			width: '100%',
			marginRight: 0,
		},
	},
}))

const DownloadRates = (props) => {
	const { quoteCreatedId } = props
	const classes = useStyles()
	const { getUserData, getTokenUser } = useContext(SessionContext)

	const { actualCompany } = getUserData()
	const token = getTokenUser()

	const handleDownloadRatesPDF = () => {
		const url = `${process.env.REACT_APP_BACKEND_QUOTES_BASE_URL}/rate/pdf/all/${quoteCreatedId}`

		const oReq = new XMLHttpRequest()
		oReq.open('GET', url, true)
		oReq.responseType = 'arraybuffer'
		oReq.setRequestHeader('X-Company-id', Number(actualCompany))
		oReq.setRequestHeader('Authorization', `Bearer ${token}`)

		oReq.onload = function download() {
			const blob = new Blob([oReq.response], { type: 'application/pdf' })
			const URL = window.URL || window.webkitURL
			const dataUrl = URL.createObjectURL(blob)
			const downloadLink = document.createElement('a')
			const fileName = `FreightPros Quote #${quoteCreatedId}.pdf`

			downloadLink.href = dataUrl
			downloadLink.download = fileName
			downloadLink.click()
		}
		oReq.send()
	}

	return (
		<Button className={classes.button} onClick={() => handleDownloadRatesPDF()}>
			Download rates
		</Button>
	)
}

DownloadRates.propTypes = {
	quoteCreatedId: PropTypes.number.isRequired,
}

export default DownloadRates
