/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import React, { useEffect, useState, useCallback, forwardRef } from 'react'

import PropTypes from 'prop-types'

import { useDispatch, useSelector } from 'react-redux'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import {
	FormControlLabel,
	RadioGroup,
	Radio,
	FormControl,
	FormLabel,
	useMediaQuery,
	Avatar,
	useTheme,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

import sweetAlertModal from '../../../../components/SweetAlertModal'
import ReferencesForm from '../../../../components/ReferencesForm'
import RangeDatePicker from '../../../../components/RangeDatePicker'
import ShiplifySuggestionModal from '../ShiplifySuggestionModal'
import ShipmentItems from '../ShipmentItems'
import { fetchDefaultLocations } from '../../../../redux/actions/Locations.actions'
import { fetchDataFromZipCode } from '../../utils/googleMapsQueries'
import { originAccessorials, destinationAccessorials } from '../../../../utils/dropDownOptions'
import SuggestionsModal from '../ModalSavedLocations'
import useStyles from './styles'
import { LaneInfoIcon } from '../../../../assets/Icons/Icons'
import { capitalizeFirstLetter } from '../../../../utils/helpers'
import ZipCodeSelector from '../../../../components/ZipCodeSelector'

const NewQuoteShipmentStepOne = forwardRef(
	(
		{
			setOriginData,
			setDestinationData,
			dispatchSetOrigin,
			dispatchSetDestination,
			hereMapsResponseOrigin,
			hereMapsResponseDestination,
			setHereMapsResponseDestination,
			setHereMapsResponseOrigin,
			pickUpDate,
			setPickUpDate,
			siteTypeOrigin,
			setSiteTypeOrigin,
			siteTypeDestination,
			setSiteTypeDestination,
			valueOriginAccs,
			setValueOriginAccs,
			originAccessorialsSelected,
			setOriginAccessorialsSelected,
			valueDestinationAccs,
			setValueDestinationAccs,
			destinationAccessorialsSelected,
			setDestinationAccessorialsSelected,
			overlengthValue,
			setOverlengthValue,
			CODValue,
			setCODValue,
			setReferencesAdded,
			items,
			isEdit,
			isReQuote,
			previousReferences,
			setPreviousReferences,
			valueOrigin,
			setValueOrigin,
			valueDestination,
			setValueDestination,
			inputValueOrigin,
			setInputValueOrigin,
			inputValueDestination,
			setInputValueDestination,
			dispatchSetOriginAccessorials,
			dispatchSetDestinationAccessorials,
			dispatchSetReferences,
			dispatchSetShipmentItemsStore,
			dispatchSetOriginData,
			dispatchSetDestinationData,
			referenceInputsRef,
			freightClassRef,
			linearFeet,
			setLinearFeet,
			refInfoItemsValues,
		},
		ref,
	) => {
		const theme = useTheme()
		const classes = useStyles()
		const currentTabs = useSelector((state) => state.multiTabs.tabs)
		const activeTab = currentTabs.find((tab) => tab.active === true)
		const quoteDetails = useSelector((state) => state.quoteDetails)
		const isCopyQuote = quoteDetails?.quoteDetailsCopy ?? false
		const isNewQuote = activeTab?.isNewQuote ?? true
		const dispatch = useDispatch()
		const [defaultValueOriginAccs, setDefaultValueOriginAccs] = useState(valueOriginAccs)
		const [defaultValueDestinationAccs, setDefaultValueDestinationAccs] = useState(valueDestinationAccs)
		const LocationsTypes = { ORIGIN: 'ORIGIN', DESTINATION: 'DESTINATION' }
		const [open, setOpen] = useState(false)
		const [typeLocation, setTypeLocation] = useState('')
		const [shipper, setShipper] = useState({})
		const [consignee, setConsignee] = useState({})
		const matches = useMediaQuery('(max-width:768px)')
		const handleOpen = (type) => {
			setTypeLocation(type)
			setOpen(true)
		}
		const handleClose = () => setOpen(false)

		useEffect(() => {
			const checkResidentialAccessorials = () => {
				const residentialOriginIsSelected = defaultValueOriginAccs.find((element) => element.accessorialId === 5) ?? {}
				if (Object.keys(residentialOriginIsSelected).length > 0) {
					setSiteTypeOrigin('residence')
				} else {
					setSiteTypeOrigin('business')
				}
				const residentialDestinationIsSelected =
					defaultValueDestinationAccs.find((element) => element.accessorialId === 2) ?? {}
				if (Object.keys(residentialDestinationIsSelected).length > 0) {
					setSiteTypeDestination('residence')
				} else {
					setSiteTypeDestination('business')
				}
			}
			checkResidentialAccessorials()
		}, [defaultValueOriginAccs, defaultValueDestinationAccs, setSiteTypeOrigin, setSiteTypeDestination])

		const setResidenceAccessorials = (type, siteType, selectedAccessorials) => {
			const residentialOrigin = originAccessorials.find((element) => element.accessorialId === 5)
			const residentialDestination = destinationAccessorials.find((element) => element.accessorialId === 2)
			const residentialOriginIsSelected = defaultValueOriginAccs.find((element) => element.accessorialId === 5) ?? []
			const residentialDestinationIsSelected =
				defaultValueDestinationAccs.find((element) => element.accessorialId === 2) ?? []
			const modalResidentialOriginIsSelected =
				selectedAccessorials?.find((element) => element.accessorialId === 5) ?? []
			const modalResidentialDestinationIsSelected =
				selectedAccessorials?.find((element) => element.accessorialId === 2) ?? []

			if (type === 'origin') {
				if (siteType === 'business') {
					const updatedAccs = defaultValueOriginAccs.filter(
						(elem) => elem.accessorialId !== residentialOriginIsSelected.accessorialId,
					)
					setDefaultValueOriginAccs([...updatedAccs])
					setOriginAccessorialsSelected([...updatedAccs])
					setValueOriginAccs([...updatedAccs])
				} else if (siteType === 'residence') {
					if (selectedAccessorials && modalResidentialOriginIsSelected.length > 0) {
						setDefaultValueOriginAccs([...selectedAccessorials])
						setOriginAccessorialsSelected([...selectedAccessorials])
						setValueOriginAccs([...selectedAccessorials])
					} else if (residentialOriginIsSelected.length === 0) {
						setDefaultValueOriginAccs([...defaultValueOriginAccs, residentialOrigin])
						setOriginAccessorialsSelected([...defaultValueOriginAccs, residentialOrigin])
						setValueOriginAccs([...defaultValueOriginAccs, residentialOrigin])
					}
				}
			}
			if (type === 'destination') {
				if (siteType === 'business') {
					const updatedAccs = defaultValueDestinationAccs.filter(
						(elem) => elem.accessorialId !== residentialDestinationIsSelected.accessorialId,
					)
					setDefaultValueDestinationAccs([...updatedAccs])
					setDestinationAccessorialsSelected([...updatedAccs])
					setValueDestinationAccs([...updatedAccs])
				} else if (siteType === 'residence') {
					if (selectedAccessorials && modalResidentialDestinationIsSelected.length > 0) {
						setDefaultValueDestinationAccs([...selectedAccessorials])
						setDestinationAccessorialsSelected([...selectedAccessorials])
						setValueDestinationAccs([...selectedAccessorials])
					} else if (residentialDestinationIsSelected.length === 0) {
						const validatedAccessorials = defaultValueDestinationAccs
						setDefaultValueDestinationAccs([...validatedAccessorials, residentialDestination])
						setDestinationAccessorialsSelected([...validatedAccessorials, residentialDestination])
						setValueDestinationAccs([...validatedAccessorials, residentialDestination])
					}
				}
			}
		}

		const handleChangeSetType = (event, type) => {
			if (type === 'origin') {
				setSiteTypeOrigin(event.target.value)
				setResidenceAccessorials('origin', event.target.value)
			}
			if (type === 'destination') {
				setSiteTypeDestination(event.target.value)
				setResidenceAccessorials('destination', event.target.value)
			}
		}

		useEffect(() => {
			if (!isEdit) {
				setOriginAccessorialsSelected(valueOriginAccs)
				setDefaultValueOriginAccs(valueOriginAccs)
			}
		}, [valueOriginAccs, setOriginAccessorialsSelected, isEdit])

		useEffect(() => {
			if (!isEdit) {
				setDestinationAccessorialsSelected(valueDestinationAccs)
				setDefaultValueDestinationAccs(valueDestinationAccs)
			}
		}, [valueDestinationAccs, setDestinationAccessorialsSelected, isEdit])

		const getDataFromZipCode = async (event, type) => {
			if (event.target.value.length > 4) {
				const [status, resp] = await fetchDataFromZipCode(event.target.value)
				if (status === 'error') {
					sweetAlertModal(
						'error',
						'There was an error fetching the data related to the zip code!',
						null,
						'Ok',
						true,
						false,
					)
				} else {
					if (type === 'origin') setHereMapsResponseOrigin(resp)
					if (type === 'destination') setHereMapsResponseDestination(resp)
				}
			}
		}

		const setSameAccessorials = () => {
			const mergedArray = [...defaultValueDestinationAccs, ...defaultValueOriginAccs]
			const accessorialIdIs8 = mergedArray.findIndex((element) => element.accessorialId === 8)
			if (accessorialIdIs8 !== -1) {
				mergedArray.splice(accessorialIdIs8, 1)
				const searchAccessorial = destinationAccessorials.find((elem) => elem.accessorialId === 7)
				mergedArray.push(searchAccessorial)
			}
			const accessorialIdIs4 = mergedArray.findIndex((element) => element.accessorialId === 4)
			if (accessorialIdIs4 !== -1) {
				mergedArray.splice(accessorialIdIs4, 1)
				const searchAccessorial = destinationAccessorials.find((elem) => elem.accessorialId === 1)
				mergedArray.push(searchAccessorial)
			}
			const accessorialIdIs10 = mergedArray.findIndex((element) => element.accessorialId === 10)
			if (accessorialIdIs10 !== -1) {
				mergedArray.splice(accessorialIdIs10, 1)
				const searchAccessorial = destinationAccessorials.find((elem) => elem.accessorialId === 9)
				mergedArray.push(searchAccessorial)
			}
			const accessorialIdIs5 = mergedArray.findIndex((element) => element.accessorialId === 5)
			if (accessorialIdIs5 !== -1) {
				mergedArray.splice(accessorialIdIs5, 1)
				const searchAccessorial = destinationAccessorials.find((elem) => elem.accessorialId === 2)
				mergedArray.push(searchAccessorial)
			}
			const accessorialIdIs3 = mergedArray.findIndex((element) => element.accessorialId === 3)
			if (accessorialIdIs3 !== -1) {
				mergedArray.splice(accessorialIdIs3, 1)
				const searchAccessorial = destinationAccessorials.find((elem) => elem.accessorialId === 14)
				mergedArray.push(searchAccessorial)
			}
			const filteredArray = mergedArray.reduce((acc, elem) => {
				if (!acc.some((item) => item.accessorialId === elem.accessorialId && item.internalCode === elem.internalCode)) {
					acc.push({
						...elem,
						origin_destination: 'DESTINATION',
					})
				}
				return acc
			}, [])
			setDefaultValueDestinationAccs(filteredArray)
			setDestinationAccessorialsSelected(filteredArray)
			setValueDestinationAccs(filteredArray)
		}

		const setAccessorials = (accessorials, zipCodeData, accessorialType) => {
			const updatedAccessorials = []
			accessorials.forEach((element) => {
				const { accessorial_id, name, origin_destination, code } = element
				const newElement = {}
				newElement.accessorialId = accessorial_id
				newElement.name = name
				newElement.value = name
				newElement.origin_destination = origin_destination
				newElement.internalCode = code
				if (accessorialType.toUpperCase() === origin_destination) {
					updatedAccessorials.push(newElement)
				}
			})
			if (accessorialType.toUpperCase() === 'ORIGIN' && !isReQuote && Object.keys(isCopyQuote).length === 0) {
				setOriginAccessorialsSelected(updatedAccessorials)
				setDefaultValueOriginAccs(updatedAccessorials)
				setValueOriginAccs(updatedAccessorials)
			} else if (
				accessorialType.toUpperCase() === 'DESTINATION' &&
				!isReQuote &&
				Object.keys(isCopyQuote).length === 0
			) {
				setDestinationAccessorialsSelected(updatedAccessorials)
				setDefaultValueDestinationAccs(updatedAccessorials)
				setValueDestinationAccs(updatedAccessorials)
			}
		}
		const DefaultLocationsProvider = () => {
			const callbackLocations = useCallback(async () => {
				if ((!isNewQuote && isEdit) || isReQuote || Object.keys(isCopyQuote).length > 0) return
				const [
					{
						value: { status, resp },
					},
					{
						value: { status: statusConsignee, resp: respConsignee },
					},
				] = await Promise.allSettled([
					dispatch(fetchDefaultLocations('SHIPPER')),
					dispatch(fetchDefaultLocations('CONSIGNEE')),
				])
				if (status === 'error' || statusConsignee === 'error') {
					sweetAlertModal(
						'error',
						'There was an error fetching the default locations',
						resp.err?.message || respConsignee.err?.message,
						'Ok',
						true,
						false,
					)
				} else {
					if (resp?.location.type === 'SHIPPER') {
						const {
							city,
							country,
							latitude,
							longitude,
							zip_code: postalCode,
							state,
							address,
							address2,
							email,
							name,
							id,
							contact,
							phone,
						} = resp.location
						const accessorials = resp.locationAccessorials
						const label = `${postalCode}, ${city}, ${state}, ${country}`
						const parsedLatitude = latitude ? Number(latitude) : null
						const parsedLongitude = latitude ? Number(longitude) : null
						const zipCodeData = {
							city,
							country,
							label,
							latitude: parsedLatitude,
							longitude: parsedLongitude,
							postalCode,
							state,
							address,
							address2,
							email,
							name,
							id,
							contact,
							phone,
						}
						setOriginData(zipCodeData)
						setValueOrigin(zipCodeData)
						dispatchSetOriginData(zipCodeData)
						setAccessorials(accessorials, zipCodeData, LocationsTypes.ORIGIN)
					}
					if (respConsignee?.location.type === 'CONSIGNEE') {
						const {
							city,
							country,
							latitude,
							longitude,
							zip_code: postalCode,
							state,
							address,
							address2,
							email,
							name,
							id,
							contact,
							phone,
						} = respConsignee.location
						const accessorials = respConsignee.locationAccessorials
						const label = `${postalCode}, ${city}, ${state}, ${country}`
						const parsedLatitude = Number(latitude)
						const parsedLongitude = Number(longitude)
						const zipCodeData = {
							city,
							country,
							label,
							latitude: parsedLatitude,
							longitude: parsedLongitude,
							postalCode,
							state,
							address,
							address2,
							email,
							name,
							id,
							contact,
							phone,
						}
						setDestinationData(zipCodeData)
						setValueDestination(zipCodeData)
						dispatchSetDestinationData(zipCodeData)
						setAccessorials(accessorials, zipCodeData, LocationsTypes.DESTINATION)
					}
				}
			}, [])

			return { callbackLocations }
		}

		const { callbackLocations } = DefaultLocationsProvider()

		useEffect(() => {
			callbackLocations()
		}, [callbackLocations])

		return (
			<div className="new-quote-step-container">
				<div className="new-quote-origin-container">
					{open ? (
						<SuggestionsModal
							open={open}
							onClose={handleClose}
							onLoad={getDataFromZipCode}
							type={typeLocation}
							fetchDataFromZipCode={fetchDataFromZipCode}
							setOriginData={setOriginData}
							setValueOrigin={setValueOrigin}
							setDestinationData={setDestinationData}
							setValueDestination={setValueDestination}
							setHereMapsResponseOrigin={setHereMapsResponseOrigin}
							setHereMapsResponseDestination={setHereMapsResponseDestination}
							setOriginAccessorialsSelected={setOriginAccessorialsSelected}
							setDefaultValueOriginAccs={setDefaultValueOriginAccs}
							setDestinationAccessorialsSelected={setDestinationAccessorialsSelected}
							setDefaultValueDestinationAccs={setDefaultValueDestinationAccs}
							setShipper={setShipper}
							setConsignee={setConsignee}
							dispatchSetOriginAccessorials={dispatchSetOriginAccessorials}
							dispatchSetDestinationAccessorials={dispatchSetDestinationAccessorials}
							dispatchSetOrigin={dispatchSetOrigin}
							dispatchSetOriginData={dispatchSetOriginData}
							dispatchSetDestination={dispatchSetDestination}
							dispatchSetDestinationData={dispatchSetDestinationData}
							setResidenceAccessorials={setResidenceAccessorials}
							siteTypeOrigin={siteTypeOrigin}
							siteTypeDestination={siteTypeDestination}
							setSiteTypeOrigin={setSiteTypeOrigin}
							setSiteTypeDestination={setSiteTypeDestination}
						/>
					) : null}
					<ShiplifySuggestionModal
						activeStep={1}
						shipper={shipper}
						consignee={consignee}
						setOriginAccessorialsSelected={setOriginAccessorialsSelected}
						setDefaultValueOriginAccs={setDefaultValueOriginAccs}
						dispatchSetOriginAccessorials={dispatchSetOriginAccessorials}
						setDestinationAccessorialsSelected={setDestinationAccessorialsSelected}
						setDefaultValueDestinationAccs={setDefaultValueDestinationAccs}
						dispatchSetDestinationAccessorials={dispatchSetDestinationAccessorials}
					/>
					<div className={classes.newQuoteIconTitle}>
						<Avatar className={classes.newQuoteIcon}>
							<LaneInfoIcon color="primary" fontSize="small" />
						</Avatar>
						<h5 className={classes.newQuoteTitle}>Lane Information</h5>
					</div>
					<div className={classes.newQuoteLaneForm}>
						<div className={classes.newQuoteOriginDestination}>
							<h4 className={classes.newQuoteSubtitle}>Origin</h4>
							<div className={classes.newQuoteSiteType}>
								<RangeDatePicker
									minDate={new Date()}
									valueDate={pickUpDate}
									style={{ width: matches ? '95%' : '40%' }}
									label="Pickup Date"
									setValueDate={setPickUpDate}
								/>
								<div className={classes.newQuoteSiteTypeLabel}>
									<span>Site Type</span>
									<div className="new-quote-origin-form-site-type-checks">
										<RadioGroup
											style={{ flexDirection: 'row' }}
											aria-label="siteTypeOrigin"
											name="siteTypeOrigin"
											value={siteTypeOrigin}
											onChange={(event) => handleChangeSetType(event, 'origin')}
										>
											<FormControlLabel
												value="business"
												control={<Radio style={{ color: theme.palette.secondary.main }} />}
												label="Business"
											/>
											<FormControlLabel
												value="residence"
												control={<Radio style={{ color: theme.palette.secondary.main }} />}
												label="Residence"
											/>
										</RadioGroup>
									</div>
								</div>
							</div>
							<FormControl className={classes.newQuoteFormControl}>
								<FormLabel display="flex" className={classes.triggerButton}>
									<SearchIcon
										color="secondary"
										className={`${classes.icon} ${classes.cursorPointer}`}
										onClick={() => handleOpen('origin')}
									/>
									<span
										onClick={() => handleOpen('origin')}
										onKeyPress={() => {}}
										role="button"
										tabIndex="0"
										className={classes.cursorPointer}
									>
										Search saved locations
									</span>
								</FormLabel>
								<ZipCodeSelector
									autoSelect
									value={valueOrigin}
									setOptions={(resp) => {
										setHereMapsResponseOrigin(resp)
									}}
									onChange={(newValue) => {
										setOriginData(newValue)
										setValueOrigin(newValue)
										if (newValue) {
											const label = `${newValue.city}, ${newValue.state} ${newValue.postalCode}`
											dispatchSetOrigin(label)
											dispatchSetOriginData(newValue)
										} else {
											dispatchSetOrigin(null)
											dispatchSetOriginData('')
											setValueOrigin({ label: '' })
											setOriginData({})
											setShipper({})
										}
									}}
									getOptionLabel={(option) => (option.label === undefined ? '' : option.label)}
									inputValue={inputValueOrigin}
									inputLabel="Origin"
									onInputChange={(_, newInputValue) => {
										setInputValueOrigin(newInputValue)
									}}
									options={hereMapsResponseOrigin}
								/>
							</FormControl>
							<div>
								<Autocomplete
									size="small"
									className={classes.newQuoteFormControl}
									multiple
									options={originAccessorials}
									getOptionLabel={(option) => capitalizeFirstLetter(option.name)}
									value={defaultValueOriginAccs}
									onChange={(_, newValue) => {
										setDefaultValueOriginAccs(newValue)
										setOriginAccessorialsSelected(newValue)
										setValueOriginAccs(newValue)
										dispatchSetOriginAccessorials(newValue)
									}}
									getOptionSelected={(option, value) => option.accessorialId === value.accessorialId}
									renderInput={(props) => (
										<TextField
											// eslint-disable-next-line react/jsx-props-no-spreading
											{...props}
											label="Origin accessorials"
											margin="normal"
											size="small"
											variant="outlined"
										/>
									)}
								/>
								<Button
									style={{ margin: '10px 25px 0 0' }}
									variant="contained"
									onClick={setSameAccessorials}
									className="new-quote-origin-button-same-accessorials"
								>
									Use the same accessorials at destination
								</Button>
							</div>
						</div>
						<div className={classes.newQuoteOriginDestination}>
							<h4 className={classes.newQuoteSubtitle}>Destination</h4>
							<div className={classes.newQuoteSiteTypeDestination} style={{ alignItems: 'center' }}>
								<span>Site Type</span>
								<div className="new-quote-destination-form-site-type-checks">
									<RadioGroup
										style={{ flexDirection: 'row' }}
										aria-label="siteTypeDestination"
										name="siteTypeDestination"
										value={siteTypeDestination}
										onChange={(event) => handleChangeSetType(event, 'destination')}
									>
										<FormControlLabel
											value="business"
											control={<Radio style={{ color: theme.palette.secondary.main }} />}
											label="Business"
										/>
										<FormControlLabel
											value="residence"
											control={<Radio style={{ color: theme.palette.secondary.main }} />}
											label="Residence"
										/>
									</RadioGroup>
								</div>
							</div>
							<FormControl className={classes.newQuoteFormControl}>
								<FormLabel display="flex" justifyContent="flex-end" textAlign="right" className={classes.triggerButton}>
									<SearchIcon
										className={`${classes.icon} ${classes.cursorPointer}`}
										onClick={() => handleOpen('destination')}
									/>
									<span
										onClick={() => handleOpen('destination')}
										onKeyPress={() => {}}
										role="button"
										tabIndex="0"
										className={classes.cursorPointer}
									>
										Search saved locations
									</span>
								</FormLabel>
								<ZipCodeSelector
									autoSelect
									value={valueDestination}
									setOptions={(resp) => {
										setHereMapsResponseDestination(resp)
									}}
									onChange={(newValue) => {
										setDestinationData(newValue)
										setValueDestination(newValue)
										if (newValue) {
											const label = `${newValue.city}, ${newValue.state} ${newValue.postalCode}`
											dispatchSetDestination(label)
											dispatchSetDestinationData(newValue)
										} else {
											dispatchSetDestination(null)
											dispatchSetDestinationData('')
											setValueDestination({ label: '' })
											setDestinationData({})
											setConsignee({})
										}
									}}
									getOptionLabel={(option) => (option.label === undefined ? '' : option.label)}
									inputValue={inputValueDestination}
									inputLabel="Destination"
									onInputChange={(_, newInputValue) => {
										setInputValueDestination(newInputValue)
									}}
									options={hereMapsResponseDestination}
								/>
							</FormControl>
							<Autocomplete
								size="small"
								className={classes.newQuoteFormControl}
								multiple
								options={destinationAccessorials}
								getOptionLabel={(option) => capitalizeFirstLetter(option.name)}
								value={defaultValueDestinationAccs}
								onChange={(event, newValue) => {
									setDefaultValueDestinationAccs(newValue)
									setDestinationAccessorialsSelected(newValue)
									setValueDestinationAccs(newValue)
									dispatchSetDestinationAccessorials(newValue)
								}}
								getOptionSelected={(option, value) => option.accessorialId === value.accessorialId}
								renderInput={(props) => (
									<TextField
										// eslint-disable-next-line react/jsx-props-no-spreading
										{...props}
										label="Destination accessorials"
										size="small"
										margin="normal"
										variant="outlined"
									/>
								)}
							/>
						</div>
					</div>
					<Grid container alignItems="center" className={classes.grid}>
						<div />
						{originAccessorialsSelected.find(
							(element) =>
								element.name === 'OVERLENGTH CHARGE 08.00 FEET' ||
								element.name === 'OVERLENGTH CHARGE 12.00 FEET' ||
								element.name === 'OVERLENGTH CHARGE 20.00 FEET' ||
								element.name === 'OVERLENGTH CHARGE 28.00 FEET',
						) ? (
							<TextField
								label="Overlength value"
								variant="outlined"
								size="small"
								style={{ width: '98%' }}
								value={overlengthValue}
								onChange={(e) => setOverlengthValue(e.target.value)}
							/>
						) : (
							<div />
						)}
						{destinationAccessorialsSelected.find((element) => element.name === 'CASH ON DELIVERY') ? (
							<TextField
								label="Cash on delivery value"
								variant="outlined"
								size="small"
								style={{ width: '98%', marginLeft: '10px' }}
								value={CODValue}
								onChange={(e) => setCODValue(e.target.value)}
							/>
						) : null}
					</Grid>
				</div>
				<ShipmentItems
					items={items}
					dispatchSetShipmentItemsStore={dispatchSetShipmentItemsStore}
					ref={ref}
					freightClassRef={freightClassRef}
					linearFeet={linearFeet}
					setLinearFeet={setLinearFeet}
					refInfoItemsValues={refInfoItemsValues}
				/>
				<div className="new-quote-references-container">
					<div className={classes.newQuoteIconTitle}>
						<AssignmentOutlinedIcon className={classes.newQuoteMenuIcon} alt="References" />
						<h5 className={classes.newQuoteTitle}>References</h5>
					</div>
					<div className="new-quote-references-form-container">
						<ReferencesForm
							previousReferences={previousReferences}
							setPreviousReferences={setPreviousReferences}
							setReferencesAdded={setReferencesAdded}
							dispatchSetReferences={dispatchSetReferences}
							ref={referenceInputsRef}
						/>
					</div>
				</div>
			</div>
		)
	},
)

NewQuoteShipmentStepOne.propTypes = {
	setOriginData: PropTypes.func.isRequired,
	setDestinationData: PropTypes.func.isRequired,
	dispatchSetOrigin: PropTypes.func.isRequired,
	dispatchSetDestination: PropTypes.func.isRequired,
	hereMapsResponseOrigin: PropTypes.arrayOf(PropTypes.any).isRequired,
	hereMapsResponseDestination: PropTypes.arrayOf(PropTypes.any).isRequired,
	setHereMapsResponseOrigin: PropTypes.func.isRequired,
	setHereMapsResponseDestination: PropTypes.func.isRequired,
	pickUpDate: PropTypes.instanceOf(Date).isRequired,
	setPickUpDate: PropTypes.func.isRequired,
	siteTypeOrigin: PropTypes.string.isRequired,
	setSiteTypeOrigin: PropTypes.func.isRequired,
	siteTypeDestination: PropTypes.string.isRequired,
	setSiteTypeDestination: PropTypes.func.isRequired,
	valueOriginAccs: PropTypes.arrayOf(PropTypes.any).isRequired,
	setValueOriginAccs: PropTypes.func.isRequired,
	originAccessorialsSelected: PropTypes.arrayOf(PropTypes.any).isRequired,
	setOriginAccessorialsSelected: PropTypes.func.isRequired,
	valueDestinationAccs: PropTypes.arrayOf(PropTypes.any).isRequired,
	setValueDestinationAccs: PropTypes.func.isRequired,
	destinationAccessorialsSelected: PropTypes.arrayOf(PropTypes.any).isRequired,
	setDestinationAccessorialsSelected: PropTypes.func.isRequired,
	overlengthValue: PropTypes.number.isRequired,
	setOverlengthValue: PropTypes.func.isRequired,
	CODValue: PropTypes.number.isRequired,
	setCODValue: PropTypes.func.isRequired,
	setReferencesAdded: PropTypes.func.isRequired,
	items: PropTypes.arrayOf(PropTypes.any).isRequired,
	isEdit: PropTypes.bool.isRequired,
	isReQuote: PropTypes.bool.isRequired,
	previousReferences: PropTypes.arrayOf(PropTypes.any).isRequired,
	setPreviousReferences: PropTypes.func.isRequired,
	valueOrigin: PropTypes.objectOf(PropTypes.any),
	setValueOrigin: PropTypes.func.isRequired,
	inputValueOrigin: PropTypes.string.isRequired,
	setInputValueOrigin: PropTypes.func.isRequired,
	valueDestination: PropTypes.objectOf(PropTypes.any),
	setValueDestination: PropTypes.func.isRequired,
	inputValueDestination: PropTypes.string.isRequired,
	setInputValueDestination: PropTypes.func.isRequired,
	dispatchSetOriginAccessorials: PropTypes.func.isRequired,
	dispatchSetDestinationAccessorials: PropTypes.func.isRequired,
	dispatchSetReferences: PropTypes.func.isRequired,
	dispatchSetShipmentItemsStore: PropTypes.func.isRequired,
	dispatchSetOriginData: PropTypes.func.isRequired,
	dispatchSetDestinationData: PropTypes.func.isRequired,
	referenceInputsRef: PropTypes.objectOf(PropTypes.any),
	freightClassRef: PropTypes.objectOf(PropTypes.any).isRequired,
	linearFeet: PropTypes.string.isRequired,
	setLinearFeet: PropTypes.func.isRequired,
	refInfoItemsValues: PropTypes.objectOf(PropTypes.any),
}

NewQuoteShipmentStepOne.defaultProps = {
	valueOrigin: null,
	valueDestination: null,
	referenceInputsRef: {},
	refInfoItemsValues: {},
}

export default NewQuoteShipmentStepOne
