import React from 'react'
import PropTypes from 'prop-types'
import { Button, Grid, useMediaQuery, useTheme } from '@material-ui/core'
import { CreateOutlined, DeleteOutline } from '@material-ui/icons'

import useStyles from './styles'

const AccessorialCard = ({ accessorialData }) => {
	const classes = useStyles()

	const theme = useTheme()
	const matches = useMediaQuery(theme.breakpoints.down('md'))

	return (
		<div className={classes.accessorialCardContainer}>
			<Grid container direction={!matches ? 'row' : 'column'}>
				<Grid container sm={6} xs={12} direction="column">
					<span style={{ marginBottom: '10px' }}>
						<strong>Accessorial</strong> {accessorialData.name}
					</span>
					<span style={{ marginBottom: '10px' }}>
						<strong>Flat Fee</strong>
						{' $'}
						{accessorialData.uplift_accessorial}
					</span>
				</Grid>
				<Grid item md={6} sm={6} xs={12}>
					<span>
						<strong>Margin</strong>
						{` ${accessorialData.uplift_accessorial}%`}
					</span>
				</Grid>
			</Grid>
			<Grid container alignItems="flex-end" justify="flex-end" direction="row">
				<Button className={classes.accessorialCardActionButton} type="button">
					<CreateOutlined className={classes.accessorialCardIcon} color="secondary" />
					Edit
				</Button>
				<Button className={classes.accessorialCardActionButton} type="button">
					<DeleteOutline className={classes.accessorialCardIcon} color="error" />
					Delete
				</Button>
			</Grid>
		</div>
	)
}

AccessorialCard.propTypes = {
	accessorialData: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default AccessorialCard
