import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Button, Box } from '@material-ui/core'
import useStyles from './styles'
import FileDropZone from '../../../../../../admin/accountList/components/FileDropZone'
import sweetAlertModal from '../../../../../../../components/SweetAlertModal/SweetAlertModal'
import SessionContext from '../../../../../../../context/session'
import { createLocationsBulk, fetchLocations } from '../../../../../../../redux/actions/Locations.actions'
import useLocationsFilters from '../../../../../../../hooks/useLocationsFilters'

const UploadAccountsModal = ({ open, handleClose, isViewLocations }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const { getUserData, getTokenUser } = useContext(SessionContext)
	const { actualCompany } = getUserData()
	const token = getTokenUser()
	const [buildParamsFromState] = useLocationsFilters()
	const [hasValid, setHasValid] = useState(false)
	const [file, setFile] = useState({})

	const filterParams = buildParamsFromState()
	const setHasValidFile = (value) => setHasValid(value)

	const handleDownloadTemplate = () => {
		const url = `${process.env.REACT_APP_BACKEND_QUOTES_BASE_URL}/location/bulk/template`
		const oReq = new XMLHttpRequest()
		oReq.open('GET', url, true)
		oReq.responseType = 'arraybuffer'
		oReq.setRequestHeader('X-Company-id', Number(actualCompany))
		oReq.setRequestHeader('Authorization', `Bearer ${token}`)

		oReq.onload = function dowload() {
			const blob = new Blob([oReq.response], { type: 'application/xlsx' })
			const URL = window.URL || window.webkitURL
			const dataUrl = URL.createObjectURL(blob)
			const downloadLink = document.createElement('a')
			const fileName = 'bulk-locations-update.xlsx'

			downloadLink.href = dataUrl
			downloadLink.download = fileName
			downloadLink.click()
		}

		oReq.send()
	}

	const handleSubmit = async () => {
		const config = {
			headers: { 'X-Company-id': Number(actualCompany) },
		}
		const formData = new FormData()
		formData.append('file', file)
		const { status, resp } = await dispatch(createLocationsBulk(formData, config))
		if (status === 'error') {
			sweetAlertModal(
				'error',
				resp?.err?.message ?? 'An error occurred while saving locations An error occurred while saving locations',
				'',
				'Ok',
				true,
				null,
				false,
			)
		} else {
			const dataError = resp?.data?.data?.errorLocations ?? []
			if (dataError.length > 0) {
				let errorMessage = ''
				dataError.forEach((el) => {
					errorMessage += `error: ${el?.error ?? ''}, row: ${el?.row ?? ''} \n`
				})
				sweetAlertModal('error', '', errorMessage, 'Ok', true, null, false)
			} else {
				sweetAlertModal('success', 'Locations creation complete', '', 'Ok', true, null, false)
				if (isViewLocations) {
					const { status: status2 } = await dispatch(fetchLocations(filterParams))
					if (status2 === 'error')
						sweetAlertModal('error', 'There was an error fetching the locations', null, 'Ok', true, false)
				}
			}
		}
		handleClose()
	}

	return (
		<Dialog
			classes={{ paper: classes.paper }}
			arial-labelledby="update-accounts-modal-title"
			open={open}
			maxWidth="sm"
			disableBackdropClick
			disableEscapeKeyDown
		>
			<DialogTitle id="update-accounts-modal-title" className={classes.dialogTitle} disableTypography>
				Upload Multiple Locations
			</DialogTitle>
			<DialogContent className={classes.dialogContent}>
				<Typography align="center" variant="body1" paragraph>
					Upload an <strong>.xlsx</strong> or <strong>.csv</strong> file with the list of your locations. If you need a
					template to enter your locations, download{' '}
					<Box
						role="button"
						tabIndex="0"
						component="span"
						className={classes.dialogLink}
						onClick={() => handleDownloadTemplate()}
					>
						here
					</Box>
					.
				</Typography>
				<FileDropZone setHasValidFile={setHasValidFile} setFile={setFile} />
			</DialogContent>
			<DialogActions className={classes.dialogActions} disableSpacing>
				<Button
					className={classes.dialogButton}
					color="secondary"
					variant="contained"
					disableElevation
					onClick={handleSubmit}
					disabled={!hasValid}
				>
					Upload Your Locations
				</Button>
				<Button className={classes.dialogButton} variant="contained" disableElevation onClick={handleClose}>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	)
}

UploadAccountsModal.defaultProps = {
	isViewLocations: false,
}

UploadAccountsModal.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	isViewLocations: PropTypes.bool,
}

export default UploadAccountsModal
