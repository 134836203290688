import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, makeStyles, Popper, MenuList, MenuItem, Grow, Paper } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { AppSwitcherIcon, BeonLogoIcon } from '../../assets/Icons/Icons'

const useStyles = makeStyles(() => ({
	switcherMenuShow: {
		marginTop: '4px',
		marginLeft: '4px',
	},
	switcherIconHidden: {
		display: 'none',
	},
	switcherIcon: {
		marginTop: '-0.7px',
		color: '#FFFFFF',
		cursor: 'pointer',
		borderRadius: '4px',
		'&:hover': {
			backgroundColor: '#212121',
		},
	},
	icon: {
		width: '24px',
		color: '#EC3514',
	},
	switchLabel: {
		fontSize: '.7rem',
		color: 'rgb(82,82,82)',
		textTransform: 'uppercase',
		paddingLeft: '15px',
	},
	switcherMenu: {
		maxWidth: '280px',
		minWidth: '200px',
		zIndex: '4',
		marginTop: '-5px',
	},
	beonLogo: {
		background: '#ffeb00',
		marginRight: '5px',
	},
}))

const AppSwitcher = ({ isOpen }) => {
	const classes = useStyles()
	const history = useHistory()
	const [open, setOpen] = useState(false)
	const anchorRef = useRef(null)
	const beonAppLinks = []

	const handleClick = () => {
		setOpen((prevOpen) => !prevOpen)
	}

	const prevOpen = useRef(open)

	const handleClose = () => {
		if (anchorRef.current && prevOpen.current === false) {
			return
		}

		setOpen(false)
	}

	useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus()
		} else if (prevOpen.current === true && isOpen === false) {
			setOpen(false)
		}
		prevOpen.current = open
	}, [open, isOpen])

	if (process.env.REACT_APP_HUB_BEONSHIPPER) {
		beonAppLinks.push({ name: 'Beon Shipper', link: process.env.REACT_APP_HUB_BEONSHIPPER })
	}

	if (process.env.REACT_APP_HUB_BEONINSIGHT) {
		beonAppLinks.push({ name: 'Beon Insight', link: process.env.REACT_APP_HUB_BEONINSIGHT })
	}

	const handleBeonAppClick = (link) => {
		if (link) {
			window.location.replace(link)
		}
	}

	const handleBeonLTLClick = () => {
		history.push('/login')
		setOpen(false)
	}

	return (
		<div className={isOpen ? classes.switcherMenuShow : classes.switcherIconHidden} onMouseLeave={handleClose}>
			<Button
				ref={anchorRef}
				aria-controls={open ? 'switcher-menu' : undefined}
				aria-haspopup="true"
				onClick={handleClick}
			>
				<AppSwitcherIcon id="switcher-icon" viewBox="0 0 32 32" className={classes.switcherIcon} />
			</Button>
			<Popper
				className={classes.switcherMenu}
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
				anchororigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformorigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				{({ TransitionProps }) => (
					<Grow
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...TransitionProps}
					>
						<Paper>
							<MenuList autoFocusItem={open} id="switcher-menu">
								<span className={classes.switchLabel}>Switch To</span>
								{/* beonLinks. */}
								{beonAppLinks.map((app) => (
									<MenuItem
										onClick={() => {
											handleBeonAppClick(app.link)
											handleClose()
										}}
									>
										<BeonLogoIcon className={classes.beonLogo} />
										{app.name}
									</MenuItem>
								))}
								<MenuItem
									onClick={() => {
										handleBeonLTLClick()
										handleClose()
									}}
								>
									<BeonLogoIcon className={classes.beonLogo} />
									Beon LTL
								</MenuItem>
							</MenuList>
						</Paper>
					</Grow>
				)}
			</Popper>
		</div>
	)
}

AppSwitcher.defualtProps = {
	items: [],
}

AppSwitcher.propTypes = {
	isOpen: PropTypes.bool.isRequired,
}

export default AppSwitcher
