import React from 'react'
import PropTypes from 'prop-types'

const TabPanel = (props) => {
	const { children, value, index } = props
	return (
		<div hidden={value !== index} id={`panel-${index}`}>
			{value === index && children}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node.isRequired,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
}

export default TabPanel
