import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	noDataDiv: {
		padding: '64px 0',
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		alignItems: 'center',
	},
	noDataTitle: {
		fontSize: 17,
		fontWeight: theme.typography.fontWeightMedium,
		color: theme.palette.neutral.black,
	},
	noDataSubtitle: {
		fontSize: 15,
		fontWeight: theme.typography.fontWeightRegular,
		color: theme.palette.neutral.black,
		margin: 0,
	},
}))

export default useStyles
