import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	carriersListContainer: {
		marginTop: '1em',
		width: '100%',
		display: 'flex',
		padding: '0px 10px 0px 20px',
		alignItems: 'flex-end',
		[theme.breakpoints.down('md')]: {
			paddingRight: '0px',
			alignItems: 'center',
		},
	},
	filterButtonText: {
		color: theme.palette.neutral.black,
		fontWeight: theme.typography.fontWeightRegular,
		textTransform: 'none',
	},
	filterTriggerButton: {
		color: theme.palette.neutral.black,
		padding: 0,
		[theme.breakpoints.up('lg')]: {
			display: 'none',
		},
	},
	filterButtonModalText: {
		color: theme.palette.neutral.black,
		fontWeight: theme.typography.fontWeightRegular,
		textTransform: 'none',
	},
	filterFormWrapper: {
		padding: 15,
	},
	carriersTableContainer: {
		width: '100%',
		padding: '10px 20px 20px 20px',
		[theme.breakpoints.down('md')]: {
			padding: '0 20px',
			alignItems: 'center',
		},
	},
	carriersSliderContainer: {
		width: '100%',
		padding: '20px',
	},
}))

export default useStyles
