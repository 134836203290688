/* eslint no-param-reassign: "error" */
import React, { useState, useEffect, forwardRef, useImperativeHandle, useCallback } from 'react'
import PropTypes from 'prop-types'
import { TextField, IconButton, Tooltip } from '@material-ui/core'
import { CheckRounded, Edit } from '@material-ui/icons'
import useStyles from './styles'
import linearFootage from '../../../../../../utils/linearFootage'

const cubicInchesToCubicFeet = 0.0005787

const InfoItems = forwardRef(
	({ items, freightClassRef, linearFeet: globalLinearFeet, setLinearFeet: setGlobalLinearFeet, activeStep }, ref) => {
		const classes = useStyles()
		const [totalWeight, setTotalWeight] = useState(0)
		const [totalItems, setTotalItems] = useState(0)
		const [totalCubicFeet, setTotalCubicFeet] = useState(0)
		const [totalDensity, setTotalDensity] = useState(0)
		const [editLinearFeet, setEditLinearFeet] = useState(true)

		const { linearFootage: calculatedLinearFeet } = linearFootage(items)
		const [linearFeet, setLinearFeet] = useState('0')

		const calculateIndicators = useCallback(() => {
			let totalVolume = 0
			let totalWeightCalc = 0
			let localTotalItems = 0
			items.forEach((item) => {
				const length = Number(item.length)
				const width = Number(item.width)
				const height = Number(item.height)
				const quantity = Number(item.quantity)
				totalWeightCalc += Number(item.weight)
				localTotalItems += quantity
				const cubicInches = length * width * height * quantity
				const cubicFeet = cubicInches * cubicInchesToCubicFeet
				if (cubicFeet) totalVolume += cubicFeet
			})
			setTotalDensity((totalVolume ? totalWeightCalc / totalVolume : 0).toFixed(2))
			setTotalCubicFeet(totalVolume.toFixed(2))
			setTotalWeight(totalWeightCalc)
			setTotalItems(localTotalItems)
		}, [items])

		const displayClass = () => {
			let freightClass = 0
			if (totalDensity >= 30) freightClass = 60
			if (totalDensity >= 22.5 && totalDensity < 30) freightClass = 65
			if (totalDensity >= 15 && totalDensity < 22.5) freightClass = 70
			if (totalDensity >= 12 && totalDensity < 15) freightClass = 85
			if (totalDensity >= 10 && totalDensity < 12) freightClass = 92.5
			if (totalDensity >= 8 && totalDensity < 10) freightClass = 100
			if (totalDensity >= 6 && totalDensity < 8) freightClass = 125
			if (totalDensity >= 4 && totalDensity < 6) freightClass = 175
			if (totalDensity >= 2 && totalDensity < 4) freightClass = 250
			if (totalDensity >= 1 && totalDensity < 2) freightClass = 300
			if (totalDensity > 0 && totalDensity < 1) freightClass = 400
			freightClassRef.current = freightClass
			if (freightClass === 0) return '-'
			return freightClass
		}
		useImperativeHandle(ref, () => ({
			calculateValues() {
				calculateIndicators()
			},
		}))

		useEffect(() => {
			calculateIndicators()
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [items])

		useEffect(() => {
			if (calculatedLinearFeet !== linearFeet) setLinearFeet(calculatedLinearFeet.toFixed(2))
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [calculatedLinearFeet])

		useEffect(() => {
			if (globalLinearFeet !== linearFeet) setGlobalLinearFeet(linearFeet)
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [linearFeet])

		return (
			<div className={classes.shipmentItemsValuesContainer}>
				<p className={classes.shipmentItemsValue}>
					Total # of Items:
					<span>{` ${totalItems}`}</span>
				</p>
				<p className={classes.shipmentItemsValue}>
					Total Weight:
					<span>{` ${totalWeight}lbs`}</span>
				</p>
				<p className={classes.shipmentItemsValue}>
					Total Cubic Feet:
					<span>{` ${totalCubicFeet}`}</span>
				</p>
				<div className={classes.linearFeetContainer}>
					<p className={classes.shipmentItemsValue}>
						Total Linear Feet:
						{activeStep === 1 ? (
							<div className={classes.containerInput}>
								<TextField
									value={linearFeet}
									onChange={(e) => {
										const value = e?.target?.value
										if (/^-?\d*[.]?\d*$/.test(value)) {
											setLinearFeet(value)
										}
									}}
									name="feet"
									className={classes.root}
									disabled={editLinearFeet}
								/>
								<IconButton
									aria-label="edit-linear-feet"
									onClick={() => setEditLinearFeet((prev) => !prev)}
									size="small"
								>
									{editLinearFeet ? (
										<Edit color="secondary" fontSize="small" />
									) : (
										<CheckRounded color="secondary" fontSize="small" />
									)}
								</IconButton>
							</div>
						) : (
							<span className={classes.valueLinearFeet}>{` ${globalLinearFeet}`}</span>
						)}
					</p>
				</div>
				<p className={classes.shipmentItemsValue}>
					Total Density:
					<span> {totalDensity}</span>
				</p>
				<Tooltip
					title="This Freight Class is estimated based on the density of your freight.
         If you selected 'Not Sure' for 'Freight Class', the rates on the next screen will calculate using this estimated class.
         If the actual freight class differs, it may affect the final rate. For assistance classing your freight,
         please contact your NTG representative."
					interactive
				>
					<p className={classes.shipmentItemsValue}>
						Estimated Freight Class:
						<span>{` ${displayClass()}`}</span>
					</p>
				</Tooltip>
			</div>
		)
	},
)

InfoItems.propTypes = {
	items: PropTypes.arrayOf(PropTypes.any).isRequired,
	freightClassRef: PropTypes.objectOf(PropTypes.any),
	linearFeet: PropTypes.string.isRequired,
	setLinearFeet: PropTypes.func,
	activeStep: PropTypes.number,
}

InfoItems.defaultProps = {
	freightClassRef: {},
	setLinearFeet: () => {},
	activeStep: 1,
}

export default InfoItems
