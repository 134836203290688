import axiosUser from '../../utils/apiClient'

const reqCurrentUserData = async (params, config) => {
	const [error, data] = await axiosUser
		.get('/user', config)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export default reqCurrentUserData
