import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
	newAccountSettingsContainer: {
		backgroundColor: theme.palette.neutral.white,
		paddingBottom: '110px',
		[theme.breakpoints.down('md')]: {
			paddingBottom: '215px',
		},
	},
	newAccountSettingsNotification: {
		padding: '16px 24px',
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
			padding: '16px 8px',
			marginLeft: '10px',
		},
	},
	newAccountSettingsTitle: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: '2.5%',
		flexWrap: 'wrap',
		[theme.breakpoints.down('md')]: {
			marginTop: '2%',
		},
	},
	newAccountSettingsTitleJustify: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: '2.5%',
		flexWrap: 'wrap',
		[theme.breakpoints.down('md')]: {
			marginTop: '2%',
		},
	},
	newAccountSettingsTitlePrincipal: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	newAccountContainerSubTitle: {
		display: 'flex',
		alignItems: 'center',
	},
	newAccountIconContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '28px',
		height: '28px',
		borderRadius: '20px',
		color: theme.palette.primary.main,
	},
	newAccountIconContainerIcon: {
		fontSize: 20,
	},
	generalIcon: {
		width: '20px',
		height: '20px',
	},
	newAccountRadioLabel: {
		fontSize: '12px',
		fontWeight: 400,
		color: '#2C2C2C',
	},
	newAccountSettingsSectionTitle: {
		fontSize: '18px',
		fontWeight: theme.typography.fontWeightRegular,
		color: theme.palette.primary.main,
		margin: '0px 0px 0px 12px',
		lineHeight: '22px',
		[theme.breakpoints.down('md')]: {
			fontSize: '15px',
		},
	},
	newAccountSettingsNotificationTable: {
		width: '100%',
		marginLeft: '10px',
		[theme.breakpoints.down('md')]: {
			marginLeft: '0px',
		},
	},
	newAccountSettingNotificationFormRow: {
		display: 'flex',
		flexDirection: 'row',
		marginLeft: '10px',
		[theme.breakpoints.down('md')]: {
			display: 'flex',
			flexDirection: 'column',
			marginRight: '10px',
			marginLeft: '0px',
		},
	},
	newAccountsNotificationField: {
		marginRight: '2.5%',
		minWidth: '35%',
		marginTop: '16px',
		alignSelf: 'flex-start',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginRight: '0%',
			marginBottom: '2.5%',
		},
	},
	newAccountsCustomerStatus: {
		marginLeft: '2.5%',
		marginRight: '2.5%',
		marginTop: '5%',
		minWidth: '75%',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginLeft: '0%',
			marginRight: '0%',
		},
	},
	newAccountSpecialInstructionsField: {
		marginTop: '20px',
		backgroundColor: '#FFF',
	},
	newAccountsEmailField: {
		minWidth: '50%',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginTop: '2.5%',
		},
		'& > p': {
			marginTop: 0,
		},
	},
	marginZero: {
		marginTop: '0px',
		marginBottom: '0px',
		width: '50%',
	},
	newAccountSettingsReferences: {
		padding: '16px 24px',
		display: 'flex',
		flexDirection: 'row',
		backgroundColor: '#F7F8F8',
		[theme.breakpoints.down('md')]: {
			display: 'flex',
			flexDirection: 'column',
			padding: '16px 8px',
		},
	},
	newAccountSettingsReferencesTable: {
		width: '100%',
		marginLeft: '10px',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginTop: '10px',
			marginLeft: '0px',
		},
	},
	newAccountSettingsReferencesForm: {
		display: 'flex',
		flexDirection: 'column',
		width: '50%',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginLeft: '10px',
		},
	},
	newAccountSettingReferencesFormRow: {
		display: 'flex',
		flexDirection: 'row',
		marginLeft: '20px',
		[theme.breakpoints.down('md')]: {
			display: 'flex',
			flexDirection: 'column',
			marginRight: '10px',
			marginLeft: '0px',
		},
	},
	newAccountSettingsInsuranceFormRow: {
		display: 'flex',
		flexDirection: 'row',
		marginBottom: '20px',
		[theme.breakpoints.down('md')]: {
			display: 'flex',
			flexDirection: 'column',
			marginTop: '10px',
		},
	},
	newAccountsReferenceTypeField: {
		width: '45%',
		marginRight: '30px',
		[theme.breakpoints.down('md')]: {
			width: '98%',
			marginBottom: '20px',
		},
	},
	newAccountRadioContainer: {
		marginRight: '4%',
	},
	newAccountMaximumReplacement: {
		width: '45%',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginTop: '20px',
		},
	},
	newAccountAmount: {
		width: '45%',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginTop: '20px',
		},
	},
	newAccountSettingsInstructionsForm: {
		display: 'flex',
		flexDirection: 'column',
		width: '50%',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			paddingLeft: '10px',
			paddingRight: '10px',
			marginBottom: '10px',
		},
	},
	newAccountSettingsThirdParty: {
		padding: '16px 24px',
		display: 'flex',
		flexDirection: 'row',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
			padding: '16px 20px',
		},
	},
	newAccountSettingsThirdPartyContainer: {
		width: '50%',
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
	newAccountSettingsShiplifyContainer: {
		width: '25%',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginBottom: '20px',
		},
	},
	newAccountSettingsCustomerContainer: {
		width: '25%',
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
	newAccountSettingsButtons: {
		backgroundColor: theme.palette.neutral.white,
		display: 'flex',
		flexDirection: 'row-reverse',
		padding: '20px',
		position: 'fixed',
		bottom: 0,
		left: 0,
		right: 0,
		zIndex: 1,
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
			padding: '0px',
		},
	},
	addNotificationButton: {
		height: '48px',
		fontSize: '13px',
		fontWeight: '500',
		color: '#141313',
		marginLeft: '2%',
		marginBottom: '1%',
		alignSelf: 'center',
		justifyContent: 'flex-end',
		textTransform: 'none',
		'&:hover': {},
		'@media only screen and (max-width: 768px)': {
			width: '140px',
		},
		[theme.breakpoints.down('md')]: {
			width: '95%',
			marginBottom: '10px',
			marginLeft: '20px',
		},
	},
	addReferenceButton: {
		height: '48px',
		fontSize: '13px',
		fontWeight: '500',
		color: '#141313',
		marginLeft: '2%',
		marginBottom: '3%',
		alignSelf: 'center',
		justifyContent: 'flex-end',
		textTransform: 'none',
		'&:hover': {},
		'@media only screen and (max-width: 768px)': {
			width: '140px',
		},
		[theme.breakpoints.down('md')]: {
			width: '90%',
			marginBottom: '10px',
			marginLeft: '20px',
		},
	},
	addIconButton: {
		color: theme.palette.secondary.main,
		fontSize: '25px',
	},
	containerButton: {
		justifyContent: 'flex-end',
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		padding: '20px',
		backgroundColor: theme.palette.neutral.white,
		[theme.breakpoints.up('md')]: {
			flexDirection: 'row-reverse',
			justifyContent: 'flex-start',
		},
	},
	nextStepButton: {
		width: '100%',
		marginBottom: '10px',
		marginLeft: '0',
		height: '48px',
		fontSize: '17px',
		fontWeight: theme.typography.fontWeightMedium,
		color: theme.palette.neutral.white,
		margin: theme.spacing(0, 0, 0),
		textTransform: 'none',
		[theme.breakpoints.up('md')]: {
			width: '180px',
			marginLeft: '1em',
		},
		[theme.breakpoints.down('md')]: {
			width: '90%',
			marginTop: '10px',
			marginBottom: '10px',
			marginLeft: '20px',
			marginRight: '20px',
		},
	},
	cancelButton: {
		height: '48px',
		width: '100%',
		backgroundColor: theme.palette.neutral.whiteGray,
		textTransform: 'none',
		color: theme.palette.primary.main,
		[theme.breakpoints.up('md')]: {
			width: '161px',
		},
		[theme.breakpoints.up('lg')]: {
			color: theme.palette.neutral.black,
		},
		[theme.breakpoints.down('md')]: {
			width: '90%',
			marginBottom: '10px',
			marginLeft: '20px',
			marginRight: '20px',
		},
	},
	previousButton: {
		width: '100%',
		marginBottom: '10px',
		marginLeft: '0',
		height: '48px',
		fontSize: '17px',
		fontWeight: theme.typography.fontWeightMedium,
		color: theme.palette.neutral.black,
		backgroundColor: theme.palette.neutral.mainGray,
		margin: theme.spacing(0, 0, 0),
		textTransform: 'none',
		[theme.breakpoints.up('md')]: {
			width: '171px',
			marginLeft: '1em',
			marginRight: '1em',
		},
		[theme.breakpoints.down('md')]: {
			width: '90%',
			marginTop: '10px',
			marginBottom: '10px',
			marginLeft: '20px',
			marginRight: '20px',
		},
	},
	nextButtonEdit: {
		height: '48px',
		width: '100%',
		backgroundColor: theme.palette.neutral.whiteGray,
		textTransform: 'none',
		color: theme.palette.primary.main,
		[theme.breakpoints.up('md')]: {
			width: '161px',
		},
	},
	radioButtonOptionLabel: {
		fontSize: '12px',
		marginRight: '10px',
	},
	headerActionButton: {
		textTransform: 'none',
		fontWeight: theme.typography.body2.fontWeight,
		fontSize: theme.typography.body2.fontSize,
		[theme.breakpoints.down('md')]: {
			marginRight: '1em',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '5px 1em 5px 0',
		},
	},
}))
