import type { Dispatch } from 'redux'
import { editQuoteDate } from '../../../../../../redux/actions/Quotes.actions'
import removeZDate from '../../../../../../utils/removeZDate'

function parseDate(dateString: string): Date {
	const midnight = '00:00:00'
	const parsedDate = new Date(removeZDate(`${dateString} ${midnight}`))
	parsedDate.setHours(0, 0, 0)
	return parsedDate
}

type QuoteRates = {
	rates: Array<{
		rateId: number
		smc3Information: {
			pickupDate: string
			estimatedDeliveryDate: string
		}
	}>
}

async function actionUpdateDeliveryDay(
	dispatch: Dispatch<any>,
	quote: { quoteRates: QuoteRates },
	selectedRateId: number,
	quoteId: number,
) {
	const selectedRate = quote.quoteRates.rates.find((rate) => rate.rateId === selectedRateId)

	const deliveryDateString = selectedRate?.smc3Information?.estimatedDeliveryDate ?? ''
	const deliveryDate = parseDate(deliveryDateString)

	try {
		const body = {
			deliveryDate,
		}
		const result = await dispatch(editQuoteDate(quoteId, body))
		return result
	} catch (error) {
		console.log(error)
		return {}
	}
}
export default actionUpdateDeliveryDay
