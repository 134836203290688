import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Menu, MenuItem, withStyles, ListItemIcon, ListItemText } from '@material-ui/core'

import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'

const useStyles = makeStyles(() => ({
	icon: {
		width: '24px',
		color: '#EC3514',
	},
	listItemIcon: {
		minWidth: 0,
		marginRight: '5px',
		color: '#2C2C2C',
	},
}))

const StyledMenuItem = withStyles(() => ({
	root: {
		'&:hover': {
			backgroundColor: '#F9D2CA',
		},
	},
}))(MenuItem)

const ActionsMenu = (props) => {
	const classes = useStyles()
	const { type, anchorEl, open, handleClose, handleDeleteClick, index } = props

	return (
		<Menu id="long-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
			<StyledMenuItem onClick={handleClose}>
				<ListItemIcon className={classes.listItemIcon}>
					<EditOutlinedIcon fontSize="small" />
				</ListItemIcon>
				<ListItemText primary={`Edit ${type}`} />
			</StyledMenuItem>
			<StyledMenuItem
				onClick={() => {
					handleClose()
					handleDeleteClick(index)
				}}
			>
				<ListItemIcon className={classes.listItemIcon}>
					<DeleteOutlineOutlinedIcon fontSize="small" className={classes.icon} />
				</ListItemIcon>
				<ListItemText primary={`Delete ${type}`} />
			</StyledMenuItem>
		</Menu>
	)
}

ActionsMenu.propTypes = {
	type: PropTypes.string.isRequired,
	anchorEl: PropTypes.objectOf(PropTypes.any),
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	handleDeleteClick: PropTypes.func.isRequired,
	index: PropTypes.number.isRequired,
}

ActionsMenu.defaultProps = { anchorEl: null }

export default ActionsMenu
