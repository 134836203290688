import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	auditTabContainer: {
		width: '25%',
		display: 'flex',
		flexDirection: 'row',
		marginTop: '16px',
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
	auditTabDivider: {
		width: '10%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		'& hr': {
			width: '4%',
			height: '70%',
			alignSelf: 'center',
			marginTop: '10px',
			[theme.breakpoints.down('md')]: {
				width: '7%',
			},
		},
		[theme.breakpoints.down('md')]: {
			width: '15%',
		},
	},
	auditTabAvatar: {
		backgroundColor: '#293F95',
		width: 24,
		height: 24,
		fontSize: 12,
		fontWeight: 600,
		alignSelf: 'center',
	},
	auditTabInformation: {
		display: 'flex',
		flexDirection: 'column',
		width: '90%',
	},
	auditTabInformationName: {
		fontSize: 14,
		fontWeight: 700,
		marginBottom: 2,
	},
	auditTabInformationHour: {
		fontSize: 14,
		fontWeight: 500,
		marginTop: 2,
	},
	auditTabActions: {
		margin: '16px 0',
		display: 'flex',
		flexDirection: 'column',
	},
	auditTabActionsElement: {
		fontSize: 14,
		fontWeight: 600,
	},
}))

export default useStyles
