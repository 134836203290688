import React, { useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined'

import useStyles from './styles'

const DragAndDropImage = ({ setAccountLogo, accountLogo }) => {
	const classes = useStyles()
	const drop = useRef(null)

	const handleDragOver = (e) => {
		e.preventDefault()
		e.stopPropagation()
	}
	const handleDrop = useCallback(
		(e) => {
			e.preventDefault()
			e.stopPropagation()
			const { files } = e.dataTransfer

			if (files && files.length) {
				const allFiles = Array.from(files).map((file) => file)
				setAccountLogo(allFiles)
			}
		},
		[setAccountLogo],
	)

	const handleSelectFiles = (e) => {
		const { files } = e.target
		if (files && files.length) {
			const allFiles = Array.from(files).map((file) => file)
			setAccountLogo(allFiles)
		}
	}

	useEffect(() => {
		const ref = drop.current
		ref.addEventListener('dragover', handleDragOver)
		ref.addEventListener('drop', handleDrop)

		return () => {
			ref.removeEventListener('dragover', handleDragOver)
			ref.removeEventListener('drop', handleDrop)
		}
	}, [handleDrop])

	return (
		<div className={classes.dragAndDropArea} ref={drop}>
			{!accountLogo.length ? (
				<>
					<InsertDriveFileOutlinedIcon className={classes.dragAndDropIcon} />
					<p className={classes.dragAndDropTitleMobile}>Upload your company logo here</p>
					<p className={classes.dragAndDropTitleWeb}>Drag & Drop company logo here</p>
				</>
			) : (
				<div className={classes.dragDropLogoContainer}>
					<img className={classes.dragDropLogo} src={URL.createObjectURL(accountLogo[0])} alt="company logo" />
				</div>
			)}
			<input type="file" className={classes.dragAndDropUploader} onChange={handleSelectFiles} accept="image/*" />
		</div>
	)
}

DragAndDropImage.propTypes = {
	setAccountLogo: PropTypes.func.isRequired,
	accountLogo: PropTypes.arrayOf(PropTypes.any).isRequired,
}

export default DragAndDropImage
