/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TablePagination,
	TableRow,
	Paper,
	Button,
} from '@material-ui/core'
import { fetchLocationsByZipCode, updatePaginator, resetLocations } from '../../../../redux/actions/Locations.actions'
import SearchBar from '../../../../components/SearchBar'
import TableHeader from '../../../../components/TableHeader'
import TableRowModal from './ModalSavedLocationsZipCodeTableRow/TableRowModalZipCode'
import NoResultsFoundModal from '../../../../components/NoResultsFoundModal'
import sweetAlertModal from '../../../../components/SweetAlertModal'
import useStyles from './styles'
import './SuggestionsModalZipCode.scss'

const buildParamsFromState = (searchParams) => {
	let queryParams = ''
	if (searchParams !== '') {
		queryParams += `search_bar=${searchParams}`
	}
	return queryParams
}

const SuggestionsModalZipCode = ({ open, onClose, zipCode, type, updateLocationFromModal }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const [searchBar, setSearchBar] = useState('')
	const [search, setSearch] = useState(searchBar)
	const [selected, setSelected] = useState({ id: null, zipCode: null })
	const [page, setPage] = useState(0)
	const [isSearching, setIsSearching] = useState(false)
	const [backupPage, setBackupPage] = useState(0)
	const locationsListReducer = useSelector((state) => state.locations.locationsZipCode)
	const isLoadingReducer = useSelector((state) => state.locations.isLoading)
	const totalRecordsReducer = useSelector((state) => state.locations.totalRecordsZipCode)
	const pagSkipReducer = useSelector((state) => state.locations.pagSkip)
	const isSelected = (id) => selected.id === id
	const tableType = 'savedLocations'
	const idleTime = 0.5
	const rowsPerPage = 10

	const cleanParams = (params) =>
		params && typeof params === 'string' && params.endsWith('//?') ? params.replace('//?', '/?') : params

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (search.length >= 2) {
				setSearchBar(encodeURIComponent(search))
			} else if (search.length === 0) {
				setSearchBar('')
			}
		}, idleTime * 1000)
		return () => clearTimeout(timeOutId)
	}, [search])

	useEffect(() => {
		const getLocations = async (offset, limit) => {
			const filterParams = buildParamsFromState(searchBar)
			const params = `/${offset}/${limit}/${zipCode}/?${filterParams}`
			const { status } = await dispatch(fetchLocationsByZipCode(cleanParams(params)))
			if (status === 'error')
				sweetAlertModal('error', 'There was an error fetching the locations', null, 'Ok', true, false)
		}
		getLocations(pagSkipReducer, rowsPerPage)
	}, [searchBar, dispatch, pagSkipReducer, open, zipCode])

	const getDataFromZipCode = async (locationType) => {
		updateLocationFromModal(locationType, selected)
		return true
	}

	const renderTableBody = () => {
		const data = locationsListReducer.map((row, index) => (
			<TableRowModal
				key={row.id}
				labelId={`enhanced-table-checkbox-${index}`}
				isSelected={isSelected(row.id)}
				data={row}
				setSelected={setSelected}
				accessorialType={type}
			/>
		))

		if (isLoadingReducer && data.length === 0) {
			return <div className={classes.circularProgressTemplateItemsModal} />
		}
		if (!isLoadingReducer && data.length === 0) {
			const locationFiltersParams = buildParamsFromState(searchBar)
			return <NoResultsFoundModal type="Locations" filters={locationFiltersParams} />
		}
		return data
	}

	const handleSearchBarChangedTimeout = (event) => {
		const { value } = event.target
		if (!value && value.length === 0 && isSearching) {
			setPage(backupPage)
			setIsSearching(false)
			dispatch(updatePaginator(backupPage * rowsPerPage))
		}
		if (value && value.length !== 0 && !isSearching) {
			setPage(0)
			setIsSearching(true)
			dispatch(updatePaginator(0))
		}
		setSearch(value)
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
		if (!isSearching) {
			setBackupPage(newPage)
		}
		dispatch(updatePaginator(newPage * rowsPerPage))
	}

	const handleCancel = () => {
		setSelected({ id: null, zipCode: null })
		setSearch('')
		setPage(0)
		setBackupPage(0)
		setIsSearching(false)
		onClose()
		dispatch(updatePaginator(0))
		dispatch(resetLocations())
	}

	const handleSelect = async () => {
		if (await getDataFromZipCode(type)) {
			handleCancel()
		} else {
			if (!selected || !selected.id) {
				sweetAlertModal('error', 'No location has been selected', null, 'Ok', true, false)
			}
			if (selected.id) {
				sweetAlertModal('error', 'The zip code was not found', null, 'Ok', true, false)
			}
		}
	}

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			handleSelect()
		} else if (event.key === 'Escape') {
			handleCancel()
		}
	}

	const emptyRows =
		!isLoadingReducer && locationsListReducer.length === 0
			? 0
			: rowsPerPage - Math.min(rowsPerPage, locationsListReducer.length * rowsPerPage)
	return (
		<Dialog
			className={classes.modalDialog}
			classes={{ paper: classes.modalDialog }}
			onClose={handleCancel}
			open={open}
			maxWidth="lg"
			onKeyDown={handleKeyDown}
		>
			<DialogTitle disableTypography className={classes.titleDialog}>
				<h2>Search Saved Locations</h2>
			</DialogTitle>
			<form>
				<div className={classes.searchBarContainer}>
					<SearchBar
						value={search}
						autoFocus
						placeholder="Search for location id, company name, address, zip code, city, state, country..."
						onChangeHandler={handleSearchBarChangedTimeout}
					/>
				</div>
				<Paper>
					<TableContainer className="tableContainer modalDialog">
						<Table className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
							<TableHeader
								classes={classes}
								numSelected={1}
								rowCount={locationsListReducer.length}
								tableType={tableType}
							/>
							<TableBody>
								{renderTableBody()}
								{emptyRows > 0 ? (
									<TableRow style={{ height: 48 * emptyRows }}>
										<TableCell colSpan={6} style={{ borderBottom: 'none' }} />
									</TableRow>
								) : null}
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
				<TablePagination
					style={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}
					rowsPerPageOptions={[]}
					component="div"
					count={totalRecordsReducer}
					rowsPerPage={rowsPerPage}
					page={page}
					onChangePage={handleChangePage}
					backIconButtonProps={{ disabled: isLoadingReducer || pagSkipReducer === 0 }}
					nextIconButtonProps={{
						disabled: isLoadingReducer || locationsListReducer.length !== rowsPerPage,
					}}
				/>
				<Grid className={classes.gridMenu} container justify="flex-end" alignItems="center">
					<Button
						style={{ marginLeft: '1em' }}
						className={classes.previousButton}
						variant="contained"
						onClick={handleCancel}
					>
						Cancel
					</Button>
					<Button
						style={{ marginLeft: '1em' }}
						className={classes.button}
						variant="contained"
						color="primary"
						onClick={handleSelect}
					>
						Select Location
					</Button>
				</Grid>
			</form>
		</Dialog>
	)
}

SuggestionsModalZipCode.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	zipCode: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	updateLocationFromModal: PropTypes.func.isRequired,
}

export default SuggestionsModalZipCode
