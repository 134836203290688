import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	tableCell: {
		backgroundColor: theme.palette.neutral.whiteGray,
		fontSize: 12,
		fontWeight: theme.typography.fontWeightBold,
	},
	tableCellModal: {
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		width: '350px',
		maxWidth: '350px',
		minHeight: '60px',
	},
}))

export default useStyles
