import React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import { Menu, MenuItem, withStyles, ListItemIcon, ListItemText } from '@material-ui/core'
import { Star } from '@material-ui/icons'
import useTemplateItemsFilters from '../../../../../hooks/useTemplateItemsFilters'
import sweetAlertModal from '../../../../../components/SweetAlertModal/SweetAlertModal'
import {
	deleteTemplateItem,
	fetchTemplateItems,
	editTemplateItemDefault,
	fetchTemplateItemById,
	createTemplateItem,
} from '../../../../../redux/actions/TemplateItems.actions'
import useStyles from './style'

const StyledMenuItem = withStyles((theme) => ({
	root: {
		'&:hover': {
			backgroundColor: theme.palette.neutral.whiteGray,
		},
	},
}))(MenuItem)

const MenuList = (props) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const [buildParamsFromState] = useTemplateItemsFilters()
	const filterParams = buildParamsFromState()
	const { anchorEl, open, handleClose, handleEditItem, templateItemId, templateItemDefault } = props

	const handleDeleteTemplateItem = async () => {
		const message = `Are you sure you want to delete Item  ${templateItemId}?`
		sweetAlertModal(
			null,
			'<p class="custom-title"><span class="icon-title"></span><span>Delete item?</span></p>',
			message,
			'Delete item',
			false,
			true,
			'Go back to item',
		).then((result) => {
			if (result.isConfirmed) {
				;(async () => {
					const { status, resp } = await dispatch(deleteTemplateItem(templateItemId))
					if (status === 'error') {
						sweetAlertModal('error', resp.err.message, null, 'Ok', true, false)
					} else {
						sweetAlertModal(status, resp.data.message, null, 'Ok', true, false, null)
						await dispatch(fetchTemplateItems(filterParams))
					}
				})()
			}
		})
	}

	const handleDefaultTemplateItem = async (type) => {
		handleClose()
		const { status, resp } = await dispatch(editTemplateItemDefault(templateItemId, type))
		if (status === 'error') {
			sweetAlertModal('error', resp.err.message, null, 'Ok', true, false)
		} else {
			await dispatch(fetchTemplateItems(filterParams))
		}
	}

	const notifyUserOfErrorWhileCopyingItem = (resp) => {
		sweetAlertModal(
			'error',
			'There was an error in the copy item process!',
			resp?.err?.message ?? 'There was an error copying the item',
			'Ok',
			true,
			false,
			null,
		)
	}

	const handleCopyItem = async () => {
		// We don't have knowledge of the template item details so we need to fetch the whole item anew
		const { resp: templateItemResponse, status: fetchTemplateItemStatus } = await dispatch(
			fetchTemplateItemById(templateItemId),
		)

		if (fetchTemplateItemStatus === 'error') {
			notifyUserOfErrorWhileCopyingItem(templateItemResponse)
			return
		}

		// Create item with the same data as the selected item
		const { status, resp } = await dispatch(
			createTemplateItem({
				name: templateItemResponse.name ? `New ${templateItemResponse.name}` : null,
				pieces: {
					value: templateItemResponse.pieces_value,
					unit: templateItemResponse.pieces_unit,
				},
				class: templateItemResponse.class,
				nmfc: templateItemResponse.nmfc,
				subNmfc: templateItemResponse.sub_nmfc,
				weight: {
					value: templateItemResponse.weight_value,
					unit: templateItemResponse.weight_unit,
				},
				dimensions: {
					lenght: templateItemResponse.dimensions_lenght,
					width: templateItemResponse.dimensions_width,
					height: templateItemResponse.dimensions_height,
					unit: templateItemResponse.dimensions_unit,
				},
				description: templateItemResponse.description,
				unitType: templateItemResponse.unit_type,
				isHazMat: templateItemResponse.is_haz_mat,
				hazMatData: templateItemResponse.is_haz_mat
					? {
							shippingName: templateItemResponse.haz_mat_shipping_name,
							hazMatNumberType: templateItemResponse.haz_mat_number_type,
							hazMatNumber: templateItemResponse.haz_mat_number,
							hazMatEMSNumber: templateItemResponse.haz_mat_ems_number,
							hazMatClass: templateItemResponse.haz_mat_class,
							hazMatDivision: templateItemResponse.haz_mat_division,
							hazMatGroup: templateItemResponse.haz_mat_group,
							contactInformation: {
								name: templateItemResponse.contact_info_name,
								phone: templateItemResponse.contact_info_phone,
								email: templateItemResponse.contact_info_email,
								address: templateItemResponse.contact_info_address,
								zipcode: templateItemResponse.contact_info_zipcode,
								city: templateItemResponse.contact_info_city,
								state: templateItemResponse.contact_info_state,
								country: templateItemResponse.contact_info_country,
							},
							additionalComments: templateItemResponse.haz_mat_additional_comments,
						}
					: null,
				stackable: templateItemResponse.stackable,
				itemDetails: templateItemResponse.item_details.map((itemDetail) => ({
					package_type: itemDetail.package_type,
					quantity: itemDetail.quantity,
					description: itemDetail.description,
				})),
			}),
		)

		if (status === 'error') {
			notifyUserOfErrorWhileCopyingItem(resp)
		} else {
			// Open the item in edit mode
			handleEditItem(null, resp.data.data.id)
		}
	}

	return (
		<Menu id="long-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
			<StyledMenuItem onClick={handleEditItem}>
				<ListItemIcon className={classes.listItemIcon}>
					<CreateOutlinedIcon className={classes.icon} />
				</ListItemIcon>
				<ListItemText primary="Edit item" />
			</StyledMenuItem>
			<StyledMenuItem onClick={handleCopyItem}>
				<ListItemIcon className={classes.listItemIcon}>
					<FileCopyOutlinedIcon className={classes.icon} />
				</ListItemIcon>
				<ListItemText primary="Copy item" />
			</StyledMenuItem>
			<StyledMenuItem
				onClick={() => {
					handleClose()
					handleDeleteTemplateItem()
				}}
			>
				<ListItemIcon className={classes.listItemIcon}>
					<DeleteOutlineIcon className={classes.iconDelete} />
				</ListItemIcon>
				<ListItemText primary="Delete item" />
			</StyledMenuItem>
			{templateItemDefault ? (
				<StyledMenuItem onClick={() => handleDefaultTemplateItem('FALSE')}>
					<ListItemIcon className={classes.listItemIcon}>
						<Star className={classes.iconYellow} />
					</ListItemIcon>
					<ListItemText primary="Remove as default item" />
				</StyledMenuItem>
			) : (
				<StyledMenuItem onClick={() => handleDefaultTemplateItem('TRUE')}>
					<ListItemIcon className={classes.listItemIcon}>
						<Star className={classes.iconYellow} />
					</ListItemIcon>
					<ListItemText primary="Set as default item" />
				</StyledMenuItem>
			)}
		</Menu>
	)
}

MenuList.propTypes = {
	anchorEl: PropTypes.objectOf(PropTypes.any),
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	handleEditItem: PropTypes.func.isRequired,
	templateItemId: PropTypes.string.isRequired,
	templateItemDefault: PropTypes.bool.isRequired,
}

MenuList.defaultProps = { anchorEl: null }

export default MenuList
