/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import reqGetInvoices, {
	reqCreateInvoice,
	reqUpdateInvoiceStatus,
	reqEditInvoice,
	reqDownloadBulkInvoices,
	reqSendBulkEMailInvoices,
} from '../requests/Invoices.requests'

const invoicesSlice = createSlice({
	name: 'invoicesSlice',
	initialState: {
		invoices: [],
		totalInvoices: 0,
		createInvoiceInfo: {},
		filterApplied: '',
		isLoading: false,
		error: false,
	},
	reducers: {
		startLoading: (state) => {
			state.isLoading = true
		},
		hasError: (state, action) => {
			state.error = action.payload
			state.isLoading = false
		},
		invoicesSuccess: (state, action) => {
			state.invoices = action.payload
			state.isLoading = false
		},
		updateTotalInvoices: (state, action) => {
			state.totalInvoices = action.payload
		},
		crateInvoiceInfoSuccess: (state, action) => {
			state.createInvoiceInfo = action.payload
		},
		updatePagSkip: (state, action) => {
			state.pagSkip = action.payload
		},
		updateFilterApplied: (state, action) => {
			state.filterApplied = action.payload
		},
	},
})

const { actions, reducer } = invoicesSlice

export const {
	startLoading,
	hasError,
	invoicesSuccess,
	updatePagSkip,
	crateInvoiceInfoSuccess,
	updateFilterApplied,
	updateTotalInvoices,
} = actions

export const fetchInvoices = (params) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqGetInvoices(params)
	if (error) {
		dispatch(hasError(error))
		return { status: 'error', resp: error }
	}
	dispatch(updateTotalInvoices(data.data.data.records))
	dispatch(invoicesSuccess(data.data.data.invoices))
	return { status: 'success' }
}

export const updatePaginator = (params) => async (dispatch) => {
	dispatch(updatePagSkip(params))
}

export const setCrateInvoiceInfo = (params) => async (dispatch) => {
	dispatch(crateInvoiceInfoSuccess(params))
}

export const createInvoice = (params) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqCreateInvoice(params)

	if (error) return { status: 'error', resp: error }

	return { status: 'success', resp: data.data.data }
}

export const editInvoice = (params, body) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqEditInvoice(params, body)

	if (error) return { status: 'error', resp: error }

	return { status: 'success', resp: data.data.data }
}

export const updateInvoiceStatus = (invoiceId, params) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqUpdateInvoiceStatus(invoiceId, params)

	if (error) return { status: 'error', resp: error }

	return { status: 'success', resp: data.data.message }
}

export const downloadBulkInvoices = (params) => async (dispatch) => {
	const [error, data] = await reqDownloadBulkInvoices(params)

	if (error) {
		dispatch(hasError(error))
		return { status: 'error', resp: error }
	}

	return { status: 'success', resp: data.data.data }
}

export const sendBulkEMailInvoices = (params) => async (dispatch) => {
	const [error, data] = await reqSendBulkEMailInvoices(params)

	if (error) {
		dispatch(hasError(error))
		return { status: 'error', resp: error }
	}

	return { status: 'success', resp: data.data.data }
}

export default reducer
