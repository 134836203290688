import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	childAccountsSlider: {
		width: '100%',
		marginBottom: '1em',
		[theme.breakpoints.up('lg')]: {
			display: 'none',
		},
	},
	childAccountsSliderContainer: {
		border: '1px solid #c9cfe4',
		borderRadius: '4px',
		padding: '15px',
		backgroundColor: theme.palette.neutral.white,
		'& #childAccountsSliderTitleId': {
			margin: '0 0 8px 0',
		},
	},
	childAccountsSliderTitle: {
		width: '100%',
		margin: '0',
		fontWeight: theme.typography.fontWeightMedium,
		fontSize: '13px',
		'& span': {
			fontWeight: theme.typography.fontWeightBold,
			fontSize: '13px',
		},
	},
	childAccountsSliderActionContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		width: '100%',
	},
	childAccountsSliderActionTitle: {
		fontWeight: theme.typography.fontWeightRegular,
		fontSize: '12px',
		color: theme.palette.primary.main,
		margin: '0',
	},
	childAccountSliderIcon: {
		width: '24px',
		height: '24px',
		color: theme.palette.primary.main,
	},
}))

export default useStyles
