import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment'

import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import Chip from '@material-ui/core/Chip'

import useStyles from './style'
import MenuList from '../MenuList'
import { addTab, setTabs } from '../../../../../redux/actions/MultiTabs.actions'
import createNewTab from '../../../../../utils/helpers'
import removeZDate from '../../../../../utils/removeZDate'

const ShipmentRow = (props) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const history = useHistory()
	const currentTabs = useSelector((state) => state.multiTabs.tabs)
	const [tabsState, setTabsState] = useState(currentTabs)
	const { data } = props
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)

	const handleOpenMenu = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const shipmentDetailsTab = () => {
		const tabs = [...tabsState]
		const newTab = createNewTab(
			tabs.length,
			'Shipment Details',
			`/shipment-details/${data.id}`,
			'',
			'',
			{ shipment: data },
			true,
			true,
		)
		const newTabs = tabs.map((tab) => ({
			...tab,
			active: false,
		}))
		dispatch(addTab(newTab))
		newTabs.push(newTab)
		setTabsState(newTabs)
		dispatch(setTabs(newTabs))
		history.push(newTab.route)
	}

	const renderStatus = (status) => {
		const statusesColors = {
			BOOKED: classes.statusGreen,
			CANCELED: classes.statusRed,
			CONVERTED: classes.statusPurple,
			EXPIRED: classes.statusRed,
			RATED: classes.statusGray,
			DELIVERED: classes.statusOrange,
			'IN TRANSIT': classes.statusPurple,
			'ON HOLD': classes.statusOrange,
		}
		return (
			status && (
				<Chip
					size="small"
					className={[classes.status, statusesColors[status]].join(' ')}
					label={status.charAt(0) + status.slice(1).toLowerCase()}
				/>
			)
		)
	}

	return (
		<>
			<TableRow className={classes.tableRow} role="checkbox" tabIndex={-1} key={data.id}>
				<TableCell className={classes.tableCell}>{data.name}</TableCell>
				<TableCell className={classes.tableCell}>
					{data.invoice_number && (
						<div>
							<span className={classes.ref}>INV</span>
							{data.invoice_number}
						</div>
					)}
					{data.pro_number && (
						<div>
							<span className={classes.ref}>PRO</span>
							{data.pro_number}
						</div>
					)}
					<div>
						<span className={classes.ref}>BOL</span>
						{data.bol_identifier}
					</div>
				</TableCell>
				<TableCell className={classes.tableCell}>
					<div>
						<span className={classes.ref}>P</span>
						{data?.pickup_date ? moment(removeZDate(data.pickup_date)).format('MM/DD/YYYY') : ''}
					</div>
					<div>
						<span className={classes.ref}>D</span>
						{data?.est_dropoff_date ? moment(removeZDate(data.est_dropoff_date)).format('MM/DD/YYYY') : ''}
					</div>
				</TableCell>
				<TableCell className={classes.tableCell}>
					<div className={classes.imgItem}>
						<img src={data.carrier_logo} alt={`${data.carrier_name} logo`} className={classes.carrierLogo} />
						{data.carrier_name}
					</div>
				</TableCell>
				<TableCell className={classes.tableCell}>
					<div>{`${data.city_origin}, ${data.state_origin}, ${data.zipcode_origin}`}</div>
					{`${data.city_destination}, ${data.state_destination}, ${data.zipcode_destination}`}
				</TableCell>
				<TableCell>{renderStatus(data.status)}</TableCell>
				<TableCell className={classes.tableCell}>
					<IconButton aria-label="toggle action menu" onClick={handleOpenMenu} onMouseDown={null}>
						<MoreVertIcon style={{ color: '#2C2C2C' }} />
					</IconButton>
				</TableCell>
			</TableRow>
			<MenuList
				anchorEl={anchorEl}
				open={open}
				handleClose={handleClose}
				shipmentId={data.id}
				shipmentStatus={data.status}
				bolIdentifier={data.bol_identifier}
				openDetails={shipmentDetailsTab}
			/>
		</>
	)
}

ShipmentRow.propTypes = {
	data: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default ShipmentRow
