import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	filterButtonText: {
		color: theme.palette.neutral.black,
		fontWeight: theme.typography.fontWeightRegular,
		textTransform: 'none',
		fontSize: 13,
	},
	filterTriggerButton: {
		color: theme.palette.neutral.black,
		padding: 0,
		[theme.breakpoints.up('lg')]: {
			display: 'none',
		},
	},
	filterButtonModalText: {
		color: theme.palette.neutral.black,
		fontWeight: theme.typography.fontWeightRegular,
		textTransform: 'none',
		fontSize: 13,
	},
	filterFormWrapper: {
		padding: 15,
	},
	locationsHistoryTitleButton: {
		alignItems: 'center',
		display: 'grid',
		gridAutoFlow: 'column',
		justifyContent: 'space-between',
	},
	locationsHistoryTitle: {
		margin: '0 10px 0 0',
		fontSize: '2.5rem',
		color: theme.palette.neutral.black,
		[theme.breakpoints.down('md')]: {
			fontSize: 22,
		},
	},
	locationsHistoryDescription: {
		margin: '0 0 24px',
		color: theme.palette.neutral.black,
		fontSize: 16,
	},
	headerActionButton: {
		display: 'none',
		[theme.breakpoints.up('lg')]: {
			textTransform: 'none',
			fontWeight: theme.typography.body2.fontWeight,
			fontSize: theme.typography.body2.fontSize,
			display: 'flex',
		},
	},
	headerActionButton2: {
		margin: '5px 0',
		textTransform: 'none',
		fontWeight: theme.typography.body2.fontWeight,
		fontSize: theme.typography.body2.fontSize,
		[theme.breakpoints.up('lg')]: {
			display: 'none',
		},
	},
}))

export default useStyles
