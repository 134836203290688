import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	button: {
		height: '48px',
		boxShadow: 'none',
		backgroundColor: '#FAFBFC',
		textTransform: 'none',
		marginLeft: '1em',
		'@media only screen and (max-width: 1023px)': {
			width: '100%',
			marginLeft: 0,
		},
	},
	nextStepButton: {
		width: '175px',
		height: '48px',
		fontSize: '17px',
		fontWeight: '600',
		color: theme.palette.neutral.white,
		backgroundColor: theme.palette.secondary.main,
		margin: theme.spacing(0, 0, 0),
		marginLeft: '1em',
		boxShadow: 'none',
		alignSelf: 'end',
		textTransform: 'none',
		'&:hover': {
			backgroundColor: theme.palette.secondary.dark,
		},
		'@media only screen and (max-width: 768px)': {
			width: '140px',
		},
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginTop: '10px',
			marginBottom: '10px',
			marginLeft: '0em',
		},
	},
	shipmentButton: {
		width: '250px',
		height: '48px',
		fontSize: '17px',
		fontWeight: '600',
		color: '#FFF',
		margin: theme.spacing(0, 0, 0),
		marginLeft: '1em',
		alignSelf: 'end',
		boxShadow: 'none',
		textTransform: 'none',
		'@media only screen and (max-width: 768px)': {
			width: '140px',
		},
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginTop: '10px',
			marginBottom: '10px',
			marginLeft: '0em',
		},
	},
	previousButton: {
		width: '175px',
		height: '48px',
		fontSize: '17px',
		boxShadow: 'none',
		fontWeight: '600',
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.neutral.mainGray,
		margin: theme.spacing(0, 0, 0),
		marginLeft: '1em',
		alignSelf: 'end',
		textTransform: 'none',
		'@media only screen and (max-width: 768px)': {
			width: '140px',
		},
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginTop: '10px',
			marginBottom: '10px',
			marginLeft: '0em',
		},
	},
	saveQuoteButton: {
		height: '48px',
		textTransform: 'none',
		boxShadow: 'none',
		margin: '0 0 0 1em',
		'@media only screen and (max-width: 1023px)': {
			width: '100%',
			margin: '0',
		},
	},
	chipChecked: {
		border: '2px solid #EC3514',
		color: '#EC3514',
		fontWeight: 600,
	},
	grid: {
		display: 'grid',
		gridAutoFlow: 'column',
		gridTemplateColumns: '323px repeat(2, 1fr)',
		padding: '10px 120px 0 0',
	},
	container: {
		maxHeight: 440,
	},
	buttonsRow: {
		justifyContent: 'flex-end',
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		backgroundColor: theme.palette.neutral.white,
		position: 'fixed',
		padding: '20px 20px 20px 110px',
		bottom: 0,
		left: 0,
		right: 0,
		zIndex: 1010,
		[theme.breakpoints.down('md')]: {
			justifyContent: 'center',
			padding: '16px 16px 16px 114px',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '16px 16px',
		},
	},
}))

export default useStyles
