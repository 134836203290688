import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	shipmentItemsIconTitle: {
		display: 'flex',
		columnGap: '12px',
		marginBottom: '32px',
		alignItems: 'center',
	},
	shipmentItemsContainer: {
		padding: '20px',
		backgroundColor: theme.palette.neutral.whiteGray,
	},
	shipmentItemsTitle: {
		fontWeight: theme.typography.fontWeightRegular,
		color: theme.palette.primary.main,
		margin: 0,
		marginLeft: theme.spacing(0.5),
		lineHeight: '22px',
	},
	shipmentItemsValues: {
		padding: '15px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'start',
		[theme.breakpoints.down('sm')]: {
			padding: '10px 0',
		},
	},
	shipmentItemsValuesContainer: {
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
	},
	shipmentItemsValue: {
		marginRight: '10px',
		fontSize: '14px',
		fontWeight: theme.typography.fontWeightBold,
		color: theme.palette.neutral.darkGray,
		margin: '10px 0 0 0',
		'& span': {
			fontSize: '14px',
			color: theme.palette.neutral.darkGray,
			fontWeight: theme.typography.fontWeightRegular,
		},
	},
	shipmentItemsActions: {
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'flex-end',
		},

		'& span': {
			color: theme.palette.primary.light,
		},
	},
	shipmentItemsAction: {
		display: 'flex',
		alignItems: 'center',
		fontSize: '13px',
		cursor: 'pointer',
		fontWeight: theme.typography.fontWeightRegular,
		textTransform: 'capitalize',
		[theme.breakpoints.down('sm')]: {
			marginLeft: 0,
		},
	},
	root: {
		fontSize: '14px',
		'& .MuiFormLabel-root': {
			fontSize: '14px',
		},
		'& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
			display: 'none',
			margin: 80,
		},
		'& .MuiInputBase-root': {
			height: '50px',
		},
	},
	icon: {
		backgroundColor: '#FFF',
		'&:hover': {
			backgroundColor: '#FFF',
		},
	},
	helperText: {
		fontFamily: 'Open Sans',
		fontSize: '12px',
		fontWeight: '400',
		color: '#A1A1A1',
		marginBottom: '8px',
	},
	popper: {
		zIndex: 2,
	},
	rowContainer: {
		marginTop: '2em',
	},
	rowItemContainer: {
		padding: '0 15px !important',
	},
}))

export default useStyles
