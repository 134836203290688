import React from 'react'
import { Link, AppBar, Typography } from '@material-ui/core'
import AppSwitcher from '../../../../../components/AppSwitcher/AppSwitcher'
import useStyles from './styles'
import beonLtlLogoWhite from '../../../../../assets/img/beon-ltl-logo-white.png'

const OktaNavBar = () => {
	const classes = useStyles()
	return (
		<AppBar className={classes.tmstopnav}>
			<AppSwitcher isOpen />
			<img className={classes.sidebarLogo} src={beonLtlLogoWhite} alt="Freightpros logo" />
			<div className={classes.tmstopnavbarspacer}> </div>
			<Link className={classes.linkStyle} href="/privacy" target="_blank">
				<Typography className={classes.typography}>Privacy Policy</Typography>{' '}
			</Link>
			<div className={classes.tmstopnavbar}>
				<Link underlinehover="none" className={classes.linkStyle} href="/terms" target="_blank">
					<Typography className={classes.typography}>Terms of Use</Typography>{' '}
				</Link>
			</div>
		</AppBar>
	)
}

export default OktaNavBar
