const canAddItemToStack = (row, item) => {
	if (!item.stackable) return -1

	for (let i = 0; i < row.stacks.length; i += 1) {
		const stack = row.stacks[i]
		if (item.height <= stack.remainingHeight && stack.itemId === item.id) {
			return i
		}
	}

	return -1
}

const canAddItemToRow = (row, item) => !(row.remainingWidth < item.width)

const linearFootage = (items) => {
	const rows = []

	/*
    Sort the items by their width in descending order to fill up rows as efficiently as possible
    - Placing the widest items first leaves less empty space in the rows
    It also potentially reduce the number of rows required
    - Subsequent items are more likely to fit into the existing rows
    Rather than requiring a new row to be created
    - We make a minor tradeoff as far as efficiency so we can more accurately determine linear ft
    However, considering a truck load size is a finite amount it shouldn't be any concern
  */
	const sortedItems = [...items].sort((a, b) => b.width - a.width)

	// Iterate over each sorted item and insert them into rows
	// Trying to get as close to 96 inches of items per row as possible
	sortedItems.forEach((item) => {
		// Iterate through the quantity of each item
		for (let i = 0; i < item.quantity; i += 1) {
			let placed = false

			// Try to stack the item first before adding it side-by-side in a row (or creating a new row)
			// This does not increase linear footage so it should be done first
			rows.some((row, rowIndex) => {
				const stackIndex = canAddItemToStack(row, item)
				if (stackIndex !== -1) {
					placed = true
					rows[rowIndex].stacks[stackIndex].remainingHeight -= item.height
					return true
				}
				return false
			})

			// If the item can not be stacked, then try to add it to row before creating a new row
			if (!placed) {
				rows.some((row, rowIndex) => {
					if (canAddItemToRow(row, item)) {
						placed = true
						rows[rowIndex].remainingWidth -= item.width
						rows[rowIndex].stacks.push({
							remainingHeight: item.stackable ? 48 - item.height : 0,
							itemId: item.id,
						})
						rows[rowIndex].length = Math.max(row.length, item.length)
						return true
					}
					return false
				})
			}

			// If the item can't be stacked, and the item can't be added to a row, then create a new row
			if (!placed) {
				rows.push({
					remainingWidth: 96 - item.width,
					stacks: [
						{
							remainingHeight: item.stackable ? 48 - item.height : 0,
							itemId: item.id,
						},
					],
					length: item.length,
				})
			}
		}
	})

	// Add up the length of each row and convert from inches to feet
	return { linearFootage: rows.reduce((sum, row) => sum + row.length, 0) / 12 }
}

export default linearFootage
