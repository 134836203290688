import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Box, Button, Card, CardHeader, CardMedia, IconButton, Tooltip } from '@material-ui/core'
import { ResponsiveBar } from '@nivo/bar'
import { months } from '../../../../utils/constants'
import { DownloadIcon } from '../../../../assets/Icons/Icons'

import SessionContext from '../../../../context/session'
import useStyles from './styles'

const BarChart = ({ data, title, color, average, filters, selectedReport }) => {
	const classes = useStyles()
	const theme = useTheme()
	const matchesXL = useMediaQuery(theme.breakpoints.up('xl'))
	const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))
	const { getUserData, getTokenUser } = useContext(SessionContext)
	const { actualCompany } = getUserData()
	const token = getTokenUser()
	const [chartData, setChartData] = useState([])

	const chartOptions = {
		layout: 'vertical',
		valueScale: { type: 'linear' },
		minValue: 'auto',
		maxValue: !data.length ? 100 : 'auto',
		padding: matchesSM ? 0.2 : 0.55,
		enableLabel: false,
		enableGridY: true,
		isInteractive: true,
		colors: [color],
		margin: {
			top: matchesSM ? 25 : 50,
			right: 0,
			bottom: matchesSM ? 40 : 53,
			left: matchesSM ? 30 : 45,
		},
		borderRadius: 4,
		axisBottom: {
			tickSize: 0,
			tickPadding: matchesSM ? 10 : 24,
			tickRotation: matchesSM ? -45 : 0,
		},
		animate: true,
		theme: {
			fontSize: matchesSM ? 10 : 12,
			textColor: '#A1A1A1',
			grid: {
				line: {
					stroke: '#E4E7EB',
					strokeWidth: 1,
				},
			},
		},
	}

	const handleDownloadCSV = () => {
		const neededCarrier = () => (filters.carrier === 'all' || filters.carrier === '' ? '' : filters.carrier)
		const p = {
			initial_date: filters.initialDate,
			end_date: filters.endDate,
			scac: neededCarrier(),
			status: 'DELIVERED',
		}

		Object.keys(p).forEach((key) => p[key] === '' && delete p[key])

		const qs = Object.keys(p)
			.map((key) => `${key}=${p[key]}`)
			.join('&')
		const url = `${process.env.REACT_APP_BACKEND_REPORTS_BASE_URL}/report/shipment/dashboard-graphs?${qs}`

		const oReq = new XMLHttpRequest()
		oReq.open('GET', url, true)
		oReq.responseType = 'arraybuffer'
		oReq.setRequestHeader('X-Company-id', Number(actualCompany))
		oReq.setRequestHeader('Authorization', `Bearer ${token}`)

		oReq.onload = function dowload() {
			const blob = new Blob([oReq.response], { type: 'text/csv' })
			const URL = window.URL || window.webkitURL
			const dataUrl = URL.createObjectURL(blob)
			const downloadLink = document.createElement('a')
			const fileName = `${neededCarrier() === '' ? 'all-' : `${neededCarrier()}-`}${selectedReport.split('_').join('-')}-${filters.initialDate}-to-${filters.endDate}.csv`

			downloadLink.href = dataUrl
			downloadLink.download = fileName
			downloadLink.click()
		}

		oReq.send()
	}

	useEffect(() => {
		let computedData = []
		if (data.length) {
			if (average) {
				computedData = data.map(
					({
						// eslint-disable-next-line camelcase
						average_distance,
						average_weight,
						month,
						year,
					}) => ({
						month: matchesXL
							? `${months.find(({ id }) => id === month).value}-${year}`
							: `${months.find(({ id }) => id === month).value}-${`${year}`.slice(-2)}`,
						// eslint-disable-next-line camelcase
						value: Number(average_distance || average_weight || 0),
					}),
				)
			} else {
				computedData = data.map(({ count, month, year }) => ({
					month: matchesXL
						? `${months.find(({ id }) => id === month).value}-${year}`
						: `${months.find(({ id }) => id === month).value}-${`${year}`.slice(-2)}`,
					value: Number(count),
				}))
			}
			setChartData(computedData)
		} else {
			setChartData(
				months.map(({ value }) => ({
					month: value,
					value: 0,
				})),
			)
		}
	}, [average, data, matchesXL])

	return (
		<Card>
			<CardHeader
				classes={{
					root: classes.cardHeader,
					title: classes.cardHeaderTitle,
					action: classes.cardHeaderAction,
				}}
				title={title}
				action={
					!matchesSM ? (
						<Button
							size="small"
							className={classes.cardHeaderActionButton}
							startIcon={<DownloadIcon />}
							onClick={() => handleDownloadCSV()}
							disabled={!data.length}
						>
							Download as CSV
						</Button>
					) : (
						<Tooltip title="Download as CSV">
							<IconButton arial-label="Download CSV" size="small" className={classes.cardHeaderActionButton}>
								<DownloadIcon />
							</IconButton>
						</Tooltip>
					)
				}
			/>
			<CardMedia className={classes.cardMedia}>
				<Box className={classes.chartWrapper}>
					<ResponsiveBar
						data={chartData}
						keys={['value']}
						indexBy="month"
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...chartOptions}
					/>
				</Box>
			</CardMedia>
		</Card>
	)
}

BarChart.propTypes = {
	data: PropTypes.arrayOf(PropTypes.any).isRequired,
	title: PropTypes.string.isRequired,
	color: PropTypes.string,
	average: PropTypes.bool,
	filters: PropTypes.objectOf(PropTypes.any).isRequired,
	selectedReport: PropTypes.string.isRequired,
}

BarChart.defaultProps = {
	color: '#EC3514',
	average: false,
}

export default BarChart
