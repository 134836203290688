import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import isPermit from '../../../../utils/permission'
import { permissionName } from '../../../../utils/constants'
import SessionContext from '../../../../context/session'
import { displayFormattedValue } from '../../../../utils/helpers'

const ShipmentCarrierRatesWeb = (props) => {
	const { getUserData } = useContext(SessionContext)
	const { permissions } = getUserData()
	const isRatePermission = isPermit(permissions, permissionName.FP_ADMIN_CARRIERS_SHOW_RATE)
	const { rates } = props

	return (
		<div className="shipment-carrier-rates-web">
			<div className="shipment-carrier-rates">
				<div className="shipment-carrier-rates-row">
					<p className="shipment-carrier-rates-title">Detail</p>
					{isRatePermission && (
						<p className="shipment-carrier-rates-title" id="shipment-carrier-rate-title">
							Carrier Rate
						</p>
					)}
					<p className="shipment-carrier-rates-title" id="shipment-carrier-rate-title">
						Customer Rate
					</p>
				</div>
				<div className="shipment-carrier-rates-row">
					<p className="shipment-rate-title">Freight Quote</p>
					{isRatePermission && (
						<p className="shipment-rate-value">{`$${rates.total_carrier?.toFixed(2).replace(/[.,]00$/, '')} USD`}</p>
					)}
					<p className="shipment-rate-value">{`$${rates.total_customer?.toFixed(2).replace(/[.,]00$/, '')} USD`}</p>
				</div>
				<div className="shipment-carrier-rates-row">
					<p className="shipment-rate-title" id="shipment-rate-insurance">
						3rd Party Insurance
						<span>{` ($${displayFormattedValue(rates.declared_freight_value)} USD)`}</span>
					</p>
					{isRatePermission && <p className="shipment-rate-value">{`$${rates.insurance_cost} USD`}</p>}
					<p className="shipment-rate-value">{`$${rates.insurance_value} USD`}</p>
				</div>
				<div className="shipment-carrier-rates-row">
					<p className="shipment-rate-title-total">Total:</p>
					{isRatePermission && (
						<p className="shipment-rate-title-total">
							{`$${(Number(rates.total_carrier) + Number(rates.insurance_cost)).toFixed(2).replace(/[.,]00$/, '')} USD`}
						</p>
					)}
					<p className="shipment-rate-title-total">
						{`$${(Number(rates.total_customer) + Number(rates.insurance_value)).toFixed(2).replace(/[.,]00$/, '')} USD`}
					</p>
				</div>
			</div>
		</div>
	)
}

ShipmentCarrierRatesWeb.propTypes = {
	rates: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default ShipmentCarrierRatesWeb
