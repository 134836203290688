import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { clearQuoteDetailsForReQuote } from '../../../redux/actions/QuoteDetails.actions'

import Layout from '../containers/Layout'
import FreightProsApp from '../routes/index'

const Dashboard = ({ oktaAuth }) => {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(clearQuoteDetailsForReQuote(''))
	}, [dispatch])

	return (
		<div>
			<Layout oktaAuth={oktaAuth}>
				<FreightProsApp />
			</Layout>
		</div>
	)
}

Dashboard.propTypes = {
	oktaAuth: PropTypes.object.isRequired,
}

export default Dashboard
