import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Menu, MenuItem, withStyles, ListItemIcon, ListItemText, useTheme } from '@material-ui/core'
import RedoIcon from '@material-ui/icons/Redo'
import VisibilityIcon from '@material-ui/icons/Visibility'
import LoopIcon from '@material-ui/icons/Loop'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import SendIcon from '@material-ui/icons/Send'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { DownloadIcon } from '../../assets/Icons/Icons'
import sweetAlertModal from '../SweetAlertModal'
import {
	createQuotesRoute,
	expiredError,
	convertedShipmentError,
	createShipmentsRoute,
	permissionName,
} from '../../utils/constants'
import isPermit from '../../utils/permission'

import {
	updateQuoteStatus,
	fetchQuotes,
	validateQuoteExpiration,
	editQuoteDate,
} from '../../redux/actions/Quotes.actions'
import {
	fetchQuoteDetails,
	saveQuoteDetailsForReQuote,
	fetchQuoteDetailsCopy,
} from '../../redux/actions/QuoteDetails.actions'
import { saveCreateShipmentData } from '../../redux/actions/Shipment.actions'
import { setTabCurrentRoute, setTabs, setTabTitle, setTabIsNewQuote } from '../../redux/actions/MultiTabs.actions'
import SessionContext from '../../context/session/index'
import createNewTab from '../../utils/helpers'
import useStyles from './style'

const StyledMenuItem = withStyles((theme) => ({
	root: {
		'&:hover': {
			backgroundColor: theme.palette.neutral.whiteGray,
		},
	},
}))(MenuItem)

const MenuList = (props) => {
	const location = useLocation()
	const classes = useStyles()
	const theme = useTheme()
	const { getUserData, getTokenUser } = useContext(SessionContext)
	const dispatch = useDispatch()
	const history = useHistory()
	const currentTabs = useSelector((state) => state.multiTabs.tabs)
	const pagSkipQuotes = useSelector((state) => state.quotes.pagSkip)
	const filterApplied = useSelector((state) => state.quotes.filterApplied)

	const { anchorEl, open, handleClose, quoteId, quoteStatus, rateId, handleOpenDetailsClick, setShowEmailModal } = props

	const { actualCompany, permissions } = getUserData()
	const token = getTokenUser()

	const handleCopyQuote = async () => {
		const { status, data } = await dispatch(fetchQuoteDetailsCopy(`/${quoteId}`))
		if (status === 'error') {
			sweetAlertModal(status, 'There was an error fetching the quote details!', null, 'Ok', true, false, null)
		} else {
			const tabs = [...currentTabs]
			const newTab = createNewTab(tabs.length, 'New Quote', '/create-quote', '', '', { quoteSummary: data }, true, true)
			const index = tabs.findIndex((item) => item.route === location.pathname)
			tabs[index === -1 ? 0 : index] = newTab
			dispatch(setTabs(tabs))
			dispatch(setTabIsNewQuote(false))
			history.push(createQuotesRoute)
		}
	}

	const handleDownloadPDF = () => {
		const url = `${process.env.REACT_APP_BACKEND_QUOTES_BASE_URL}/quote/pdf/${quoteId}/${rateId}`

		const oReq = new XMLHttpRequest()
		oReq.open('GET', url, true)
		oReq.responseType = 'arraybuffer'
		oReq.setRequestHeader('X-Company-id', Number(actualCompany))
		oReq.setRequestHeader('Authorization', `Bearer ${token}`)

		oReq.onload = function download() {
			const blob = new Blob([oReq.response], { type: 'application/pdf' })
			const URL = window.URL || window.webkitURL
			const dataUrl = URL.createObjectURL(blob)
			const downloadLink = document.createElement('a')
			const fileName = `quote-${quoteId}.pdf`

			downloadLink.href = dataUrl
			downloadLink.download = fileName
			downloadLink.click()
		}
		oReq.send()
	}

	const handleConvertToShipmentClick = async () => {
		const { statusExpiration, resp } = await dispatch(validateQuoteExpiration(quoteId, rateId))
		if (statusExpiration === 'error') {
			if (resp === expiredError) {
				sweetAlertModal(statusExpiration, 'This quote has expired, please re-quote!', null, 'Ok', true, false, null)
			}
			if (resp === convertedShipmentError) {
				sweetAlertModal(
					statusExpiration,
					`Quote with ID: ${quoteId} already has a shipment`,
					null,
					'Ok',
					true,
					false,
					null,
				)
			}
			await dispatch(fetchQuotes(`/${pagSkipQuotes}/10${filterApplied}`))
		} else {
			const { status } = await dispatch(fetchQuoteDetails(`/${quoteId}`, true))
			if (status === 'error')
				sweetAlertModal(status, 'There was an error fetching the quote details!', null, 'Ok', true, false, null)
			dispatch(saveCreateShipmentData({}))
			dispatch(setTabCurrentRoute(createShipmentsRoute))
			dispatch(setTabTitle('New Shipment'))
			history.push(createShipmentsRoute)
		}
	}

	const handleCancelQuoteClick = async () => {
		sweetAlertModal('warning', 'Are you sure you want to cancel this quote?', null, 'Yes', true, true, 'No').then(
			(result) => {
				if (result.isConfirmed) {
					const params = { status: 'CANCELED' }
					;(async () => {
						const { status, resp } = await dispatch(updateQuoteStatus(quoteId, params))
						if (status === 'error') {
							sweetAlertModal(status, 'There was an error canceling the quote!', null, 'Ok', true, false, null)
						} else {
							sweetAlertModal(status, resp, null, 'Ok', true, false, null)
							await dispatch(fetchQuotes(`/${pagSkipQuotes}/10`))
						}
					})()
				}
			},
		)
	}

	const handleReQuoteClick = async () => {
		await dispatch(editQuoteDate(quoteId, { pickupDate: new Date() }))
		const { status } = await dispatch(saveQuoteDetailsForReQuote(`/${quoteId}`))
		if (status === 'error') {
			sweetAlertModal(status, 'There was an error fetching the quote details!', null, 'Ok', true, false, null)
		} else {
			history.push(createQuotesRoute)
			dispatch(setTabIsNewQuote(false))
		}
	}

	return (
		<Menu id="long-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
			{quoteStatus === 'ACTIVE' && isPermit(permissions, permissionName.QUOTES_CONVERT_TO_SHIPMENT) ? (
				<StyledMenuItem onClick={() => handleConvertToShipmentClick()}>
					<ListItemIcon className={classes.listItemIcon}>
						<RedoIcon fontSize="small" htmlColor={theme.palette.success.main} className={classes.icon} />
					</ListItemIcon>
					<ListItemText primary="Convert to shipment" />
				</StyledMenuItem>
			) : null}
			<StyledMenuItem
				onClick={() => {
					handleClose()
					handleOpenDetailsClick()
				}}
			>
				<ListItemIcon className={classes.listItemIcon}>
					<VisibilityIcon fontSize="small" color="secondary" className={classes.icon} />
				</ListItemIcon>
				<ListItemText primary="View quote" />
			</StyledMenuItem>
			{isPermit(permissions, permissionName.QUOTES_CREATE) && (
				<StyledMenuItem onClick={() => handleCopyQuote()}>
					<ListItemIcon className={classes.listItemIcon}>
						<FileCopyIcon fontSize="small" htmlColor={theme.palette.success.main} className={classes.icon} />
					</ListItemIcon>
					<ListItemText primary="Copy quote" />
				</StyledMenuItem>
			)}
			{(quoteStatus === 'ACTIVE' || quoteStatus === 'EXPIRED' || quoteStatus === 'PENDING') &&
			isPermit(permissions, permissionName.QUOTES_RE_QUOTE) ? (
				<StyledMenuItem onClick={() => handleReQuoteClick()}>
					<ListItemIcon className={classes.listItemIcon}>
						<LoopIcon fontSize="small" htmlColor={theme.palette.success.main} className={classes.icon} />
					</ListItemIcon>
					<ListItemText primary="Re-quote" />
				</StyledMenuItem>
			) : null}
			{(quoteStatus === 'ACTIVE' || quoteStatus === 'CONVERTED') &&
			isPermit(permissions, permissionName.QUOTES_DOWNLOAD) ? (
				<StyledMenuItem
					onClick={() => {
						handleClose()
						handleDownloadPDF()
					}}
				>
					<ListItemIcon className={classes.listItemIcon}>
						<DownloadIcon color="secondary" />
					</ListItemIcon>
					<ListItemText primary="Download quote" />
				</StyledMenuItem>
			) : null}
			{(quoteStatus === 'ACTIVE' || quoteStatus === 'PENDING') &&
			isPermit(permissions, permissionName.QUOTES_DELETE) ? (
				<StyledMenuItem
					onClick={() => {
						handleClose()
						handleCancelQuoteClick()
					}}
				>
					<ListItemIcon className={classes.listItemIcon}>
						<DeleteOutlineIcon fontSize="small" color="error" className={classes.icon} />
					</ListItemIcon>
					<ListItemText primary="Cancel quote" />
				</StyledMenuItem>
			) : null}
			{(quoteStatus === 'ACTIVE' || quoteStatus === 'CONVERTED') &&
			isPermit(permissions, permissionName.QUOTES_SEND_BY_EMAIL) ? (
				<StyledMenuItem
					onClick={() => {
						handleClose()
						setShowEmailModal(true)
					}}
				>
					<ListItemIcon className={classes.listItemIcon}>
						<SendIcon fontSize="small" color="secondary" className={classes.icon} />
					</ListItemIcon>
					<ListItemText primary="Email quote" />
				</StyledMenuItem>
			) : null}
		</Menu>
	)
}

MenuList.propTypes = {
	anchorEl: PropTypes.objectOf(PropTypes.any),
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	quoteId: PropTypes.number.isRequired,
	rateId: PropTypes.number.isRequired,
	quoteStatus: PropTypes.string.isRequired,
	handleOpenDetailsClick: PropTypes.func.isRequired,
	setShowEmailModal: PropTypes.func.isRequired,
}

MenuList.defaultProps = { anchorEl: null }

export default MenuList
