import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
	container: {
		padding: theme.spacing(1, 2, 27, 2),
		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(1, 2, 14, 2),
		},
	},
	containerTitle: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
	},
	containerTitleUsersAndNewUsers: {
		display: 'flex',
		alignItems: 'center',
		columnGap: 8,
	},
	containerButtonModal: {
		display: 'flex',
		alignItems: 'center',
		columnGap: 8,
		[theme.breakpoints.down('md')]: {
			margin: '5px 0',
			flexWrap: 'wrap',
		},
	},
	headerActionButton: {
		textTransform: 'none',
		fontWeight: theme.typography.body2.fontWeight,
	},
	containerClick: {
		cursor: 'pointer',
	},
	textTitle: {
		fontSize: '18px',
		fontWeight: theme.typography.fontWeightRegular,
		color: theme.palette.primary.main,
		margin: '0px 0px 0px 12px',
		lineHeight: '22px',
		[theme.breakpoints.down('md')]: {
			fontSize: '15px',
		},
	},
	textSubTitle: {
		marginTop: 16,
		color: theme.palette.neutral.black,
	},
	containerUsers: {
		marginTop: 16,
		display: 'grid',
		gridTemplateRows: 'auto auto',
		gridRowGap: 24,
		[theme.breakpoints.up('lg')]: {
			gridTemplateColumns: '90% 10%',
		},
	},
	containerAddUser: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		'&:hover': {
			cursor: 'pointer',
		},
		[theme.breakpoints.up('lg')]: {
			justifyContent: 'center',
		},
	},
	containerTable: {
		marginTop: 16,
	},
	containerButton: {
		justifyContent: 'flex-end',
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		padding: '16px',
		backgroundColor: theme.palette.neutral.white,
		position: 'fixed',
		bottom: 0,
		left: 0,
		right: 0,
		zIndex: 1,
		[theme.breakpoints.up('md')]: {
			flexDirection: 'row-reverse',
			justifyContent: 'flex-start',
		},
	},
	nextStepButton: {
		width: '100%',
		marginTop: '10px',
		marginBottom: '10px',
		marginLeft: '0',
		height: '48px',
		fontSize: '17px',
		fontWeight: theme.typography.fontWeightMedium,
		color: theme.palette.neutral.white,
		margin: theme.spacing(0, 0, 0),
		textTransform: 'none',
		[theme.breakpoints.up('md')]: {
			width: '180px',
			marginLeft: '1em',
		},
	},
	cancelButton: {
		height: '48px',
		width: '100%',
		backgroundColor: theme.palette.neutral.whiteGray,
		textTransform: 'none',
		color: theme.palette.primary.main,
		[theme.breakpoints.up('md')]: {
			width: '161px',
		},
		[theme.breakpoints.up('lg')]: {
			color: theme.palette.neutral.black,
		},
	},
	previousButton: {
		width: '100%',
		marginTop: '10px',
		marginBottom: '10px',
		marginLeft: '0',
		height: '48px',
		fontSize: '17px',
		fontWeight: theme.typography.fontWeightMedium,
		color: theme.palette.neutral.black,
		backgroundColor: theme.palette.neutral.mainGray,
		margin: theme.spacing(0, 0, 0),
		textTransform: 'none',
		[theme.breakpoints.up('md')]: {
			width: '171px',
			marginLeft: '1em',
		},
	},
	nextButtomEdit: {
		height: '48px',
		width: '100%',
		backgroundColor: theme.palette.neutral.whiteGray,
		textTransform: 'none',
		color: theme.palette.primary.main,
		[theme.breakpoints.up('md')]: {
			width: '161px',
			marginLeft: '1em',
		},
	},
	saveAccountButtomEdit: {
		height: '48px',
		width: '100%',
		textTransform: 'none',
		[theme.breakpoints.up('md')]: {
			width: '161px',
			marginLeft: '1em',
		},
	},
}))
