import { axiosQuotes } from '../../utils/apiClient'

const reqListAccesorial = async () => {
	const [error, data] = await axiosQuotes
		.get('/accessorial/')
		.then((response) => [null, response])
		.catch((err) => [err, err])
	return [error, data]
}

export default {
	reqListAccesorial,
}
