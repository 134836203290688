/* eslint-disable import/no-unresolved */
import React, { useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import { useDispatch, useSelector } from 'react-redux'
import useStyles from './styles'
import TableList from '../components/TableList/TableList'
import { fetchInvoiceDetails } from '../../../../redux/actions/InvoiceDetails.actions'
import InvoiceInfo from '../components/InvoiceInfo/InvoiceInfo'
import { invoicesListRoute } from '../../../../utils/constants'
import ModuleHeader from '../../../../components/ModuleHeader/index'

const InvoiceDetails = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const invoiceDetails = useSelector((state) => state.invoiceDetails.invoiceDetails)
	const { id } = useParams()

	const renderStatus = (status) => {
		const lowerCaseStatus = status.replace('_', ' ')
		const statusesColors = {
			Paid: classes.statusGreen,
			PAID: classes.statusGreen,
			Partially_paid: classes.statusOrange,
			'PARTIALLY PAID': classes.statusOrange,
			Unpaid: classes.statusRed,
			UNPAID: classes.statusRed,
		}
		return (
			status && (
				<Chip
					size="small"
					className={[classes.status, statusesColors[status]].join(' ')}
					label={lowerCaseStatus.charAt(0) + lowerCaseStatus.slice(1).toLowerCase()}
				/>
			)
		)
	}

	useEffect(() => {
		dispatch(fetchInvoiceDetails(id))
	}, [dispatch, id])

	return Object.keys(invoiceDetails).length > 0 ? (
		<div>
			<div className={classes.invoiceContainer}>
				<ModuleHeader
					title={
						<Grid container alignItems="center" spacing={2}>
							<Grid item>
								<h1>Invoice #{id}</h1>
							</Grid>
							<Grid item>{renderStatus(invoiceDetails.payment_status)}</Grid>
							<Grid item>
								{invoiceDetails.isMismatch ? (
									<Chip size="small" className={classes.statusMismatch} label="Mismatch" />
								) : null}
							</Grid>
						</Grid>
					}
					subtitle="Check this invoice information."
				/>
			</div>
			<TableList />
			<InvoiceInfo />
			<div className={classes.buttons}>
				<Link className={classes.linkButton} to={invoicesListRoute}>
					<span>Go back</span>
				</Link>
			</div>
		</div>
	) : null
}

InvoiceDetails.propTypes = {}

export default InvoiceDetails
