import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Tabs from 'react-draggable-tabs'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Chip from '@material-ui/core/Chip'
import { setTabShipmentDetail } from '../../redux/actions/TabShipmentDetail.actions'
import { addTab, setTabs } from '../../redux/actions/MultiTabs.actions'
import createNewTab from '../../utils/helpers'
import sweetAlertModal from '../SweetAlertModal/SweetAlertModal'
import useStyles from './styles'
import './MultiTab.scss'
import {
	createAccountRoute,
	createCarrierRoute,
	createItemRoute,
	createLocationRoute,
	createQuotesRoute,
	createShipmentsRoute,
	createUserRoute,
} from '../../utils/constants'

const MultiTab = (props) => {
	const classes = useStyles()
	const { openTabsMobile } = props
	const history = useHistory()
	const currentTabs = useSelector((state) => state.multiTabs.tabs)
	const tabShipmentDetails = useSelector((state) => state.tabShipmentDetail.tab)
	const [tabsState, setTabsState] = useState(currentTabs)
	const [lastTabClosed, setLastTabClosed] = useState(false)
	const [lastRouteClosed, setLastRouteClosed] = useState('')
	const activeTab = tabsState.filter((tab) => tab.active === true)

	const dispatch = useDispatch()

	useEffect(() => {
		if (currentTabs.length === 0) {
			const newTab = createNewTab()
			if (lastRouteClosed === '/account-details') {
				newTab.title = 'Account List'
				newTab.route = '/account-list'
			}
			dispatch(addTab(newTab))
		}
		const distinctUrl = [...new Set(currentTabs.map((i) => i.route))].map((route) =>
			currentTabs.find((a) => a.route === route),
		)
		if (distinctUrl.length !== currentTabs.length) {
			setTabsState(distinctUrl)
			dispatch(setTabs(distinctUrl))
		} else {
			setTabsState(currentTabs)
		}
		const currentTab = currentTabs.find((tab) => tab.active === true)
		if (currentTab?.route) {
			history.push(currentTab.route)
		}
	}, [currentTabs, dispatch, lastTabClosed, history, lastRouteClosed])

	const moveTab = (dragIndex, hoverIndex) => {
		const newTabs = [...tabsState]
		newTabs.splice(hoverIndex, 0, newTabs.splice(dragIndex, 1)[0])
		setTabsState(newTabs)
	}

	const isCreationalTab = () => {
		const { route } = activeTab[0] || {}
		const creationalRoutes = [
			createQuotesRoute,
			createShipmentsRoute,
			createUserRoute,
			createLocationRoute,
			createItemRoute,
			createAccountRoute,
			createCarrierRoute,
			'update',
		]

		return creationalRoutes.find((creationalRoute) => route.includes(creationalRoute))
	}

	const selectTab = (selectedIndex, selectedID) => {
		const changeTab = () => {
			const newTabs = tabsState.map((tab) => ({
				...tab,
				active: tab.id === selectedID,
			}))
			setTabsState(newTabs)
			dispatch(setTabs(newTabs))
			const currentActiveTab = newTabs.find((tab) => tab.id === selectedID)
			history.push(currentActiveTab.route)
		}

		if (isCreationalTab()) {
			sweetAlertModal(
				'warning',
				'Are you sure you want to switch this tab? You will lose all unsaved changes.',
				null,
				'Ok',
				true,
				true,
				'Cancel',
			).then((result) => {
				if (result.isConfirmed) {
					changeTab()
				}
			})
		} else {
			changeTab()
		}
	}

	const closedTab = (removedIndex) => {
		const newTabs = [...tabsState]

		const infoRemoveTab = newTabs[removedIndex]

		if (currentTabs.length === 1) {
			const lastRoute = `/${infoRemoveTab.route.split('/')[1]}`
			setLastRouteClosed(lastRoute)
		}

		if (infoRemoveTab) {
			const localTabShipmentDetail = [...tabShipmentDetails]
			const index = localTabShipmentDetail.findIndex((val) => val.route === infoRemoveTab.route)
			if (index !== -1) {
				localTabShipmentDetail.splice(index, 1)
				dispatch(setTabShipmentDetail(localTabShipmentDetail))
			}
		}
		newTabs.splice(removedIndex, 1)

		if (removedIndex === 0) {
			setLastTabClosed(true)
		}

		if (tabsState[removedIndex].active && newTabs.length !== 0) {
			const newActiveIndex = removedIndex === 0 ? 0 : removedIndex - 1
			const newActiveTab = { ...newTabs[newActiveIndex] }
			newActiveTab.active = true
			newTabs[newActiveIndex] = newActiveTab
			history.push(newActiveTab.route)
		}
		setTabsState(newTabs)
		dispatch(setTabs(newTabs))
	}

	const showStatus = (status) => {
		const statusesColors = {
			ACTIVE: classes.tabStatusGreen,
			CANCELED: classes.tabStatusOrange,
			CONVERTED: classes.tabStatusBlue,
			EXPIRED: classes.tabStatusRed,
			PENDING: classes.tabStatusGray,
		}
		return status && <Chip className={[classes.tabStatus, statusesColors[status]].join(' ')} />
	}

	const buildTabsElements = () => {
		const elements = tabsState.map((tab) => {
			const elem = {
				...tab,
				content: (
					<div className="app-tab">
						<div className="app-tab-title">
							{showStatus(tab.status)}
							{tab.title}
						</div>
						<div className={classes.appTabLocations}>
							{tab.origin && tab.destination ? (
								<p className="app-tab-origin">
									{tab.origin}
									<span> to </span>
									{tab.destination}
								</p>
							) : (
								<></>
							)}
						</div>
					</div>
				),
			}
			return elem
		})

		return elements
	}
	const renderTabs = () => {
		if (openTabsMobile || window.matchMedia('(min-width: 768px)').matches) {
			const tabsElements = buildTabsElements()
			return (
				<div className={classes.tabContainer}>
					<Tabs
						moveTab={moveTab}
						selectTab={selectTab}
						closeTab={closedTab}
						tabs={tabsElements}
						className={classes.tabsList}
					/>
					{activeTab.length !== 0 ? activeTab[0].display : ''}
				</div>
			)
		}
		return null
	}

	useEffect(() => {
		const allElements = document.querySelectorAll('.react-tabs-tab')
		for (let i = 0; i < allElements.length; i += 1) {
			const element = allElements[i]
			const value = element.querySelector('.app-tab-title')?.innerHTML ?? ''
			const removeCloseElement = element.querySelector('.react-tabs-tab-close')
			if (value !== 'Shipment Details' && tabsState.length > 1) {
				removeCloseElement.classList.add('hiddenClose')
			} else {
				removeCloseElement.classList.remove('hiddenClose')
			}
		}
	}, [tabsState])

	return <>{renderTabs()}</>
}

MultiTab.propTypes = {
	openTabsMobile: PropTypes.bool.isRequired,
}

export default MultiTab
