import React, { useState, useEffect, useMemo, useContext, useRef, forwardRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import {
	Select,
	MenuItem,
	RadioGroup,
	FormControlLabel,
	FormHelperText,
	FormControl,
	InputLabel,
	Button,
	TextField,
	Radio,
	FormLabel,
	Chip,
	Grid,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined'
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useTheme, withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import AddIcon from '@material-ui/icons/Add'
import { BackupOutlined } from '@material-ui/icons'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import { useForm, Controller } from 'react-hook-form'

import { DEFAULT_ADMIN_FEE } from '../../../../../utils/constants'
import {
	createAccountSettings,
	fetchAccountSettings,
	editAccountStepStatus,
} from '../../../../../redux/actions/Accounts.actions'
import {
	setTabAccountSettings,
	setTabAccountReferences,
	setTabAccountSpecialInstructions,
} from '../../../../../redux/actions/MultiTabs.actions'
import { InformationIcon, SpecialInstructionsIcon, LaneInfoIcon } from '../../../../../assets/Icons/Icons'

import { notificationsOptions, statusOptions, initialReferences } from '../../../../../utils/dropDownOptions'
import { insuranceSettingsAreValid, isEmailInput } from '../../../../../utils/helpers'
import SweetAlertModal from '../../../../../components/SweetAlertModal'
import TableElements from '../../components/TableElements'
import TableReferences from '../../components/TableReferences'
import AccountReferenceForm from '../../components/AccountReferenceForm'
import useStyles from './styles'
import SessionContext from '../../../../../context/session'

import USDFormat from '../../../../../components/USDFormat/USDFormat'
import PercentageFormat from '../../../../../components/PercentageFormat/PercentageFormat'
import UploadLocationsModal from '../../../../locations/myLocations/containers/LocationsContainer/components/UploadLocationsModal/UploadLocationsModal'

const getDefaultTypeAmountValue = (typeAmount) => {
	switch (typeAmount) {
		case 'ADMIN_FEE':
			return DEFAULT_ADMIN_FEE
		default:
			return '45'
	}
}

const SettingsStepper = forwardRef(
	({ setActiveStep, activeStep, isEditable, isPreviousStepTwo, setIsEdit, handleCancelAccountProcess }, refGlobal) => {
		const {
			handleSubmit: handleSubmitNotifications,
			errors: errorsNotifications,
			control: controlNotifications,
			setValue: setValueNotifications,
			reset: resetNotifications,
		} = useForm({
			mode: 'onBlur',
		})
		const { errors: errorsReferences, reset: resetReferences } = useForm({
			mode: 'onBlur',
		})
		const { handleSubmit, register, errors, control, setValue, reset } = useForm({
			mode: 'onBlur',
		})
		const classes = useStyles()
		const { id } = useParams()
		const theme = useTheme()
		const matches = useMediaQuery(theme.breakpoints.down('md'))
		const dispatch = useDispatch()
		const currentTabs = useSelector((state) => state.multiTabs.tabs)
		const accountTabs = currentTabs.find((item) => item.active) ?? {}
		const { parentCompanyId, childrenCompaniesIds } = accountTabs?.accounts ?? {}
		const { getUserData } = useContext(SessionContext)

		const globalConfig = useMemo(() => {
			const { actualCompany } = getUserData()
			const newConfig = {
				headers: {
					'X-Company-id': id || parentCompanyId || Number(actualCompany),
				},
			}
			return newConfig
		}, [id, parentCompanyId, getUserData])

		const CustomAutoComplete = withStyles({
			tag: {
				backgroundColor: '#EBEEF1',
				'& .MuiChip-deleteIcon': {
					color: theme.palette.secondary.main,
				},
			},
		})(Autocomplete)

		const [notificationDropDown, setNotificationDropDown] = useState('')
		const [emailAddressNotification, setEmailAddressNotification] = useState([])
		const [previousNotifications, setPreviousNotifications] = useState([])
		const [previousReferences, setPreviousReferences] = useState(initialReferences)
		const [specialInstructionsCarrier, setSpecialInstructionsCarrier] = useState('Do not stack.')
		const [enableMaxReplacement, setEnableMaxReplacement] = useState('false')
		// Todo: Clarify this: Should this be defaulted to $100k instead of $80k?
		// We were told $100k should be the global maximum declared value for insurance purposes
		// $80k was leftover and unchanged from year(s) ago
		const [maxReplacementValue, setMaxReplacementValue] = useState('80000')
		const [typeAmountValue, setTypeAmountValue] = useState(DEFAULT_ADMIN_FEE)
		const [typeAmount, setTypeAmount] = useState('ADMIN_FEE')
		const [enableInsuranceOptIn, setEnableInsuranceOptIn] = useState('true')
		const [enableShiplifyService, setEnableShiplifyService] = useState('active')
		const [accountStatusDropdown, setAccountStatusDropdown] = useState('GOLD')
		const [errorMaxReplacementValue, setErrorMaxReplacementValue] = useState('')
		const [errorTypeAmountValue, setErrorTypeAmountValue] = useState('')

		const [referenceForm, setReferenceForm] = useState([])
		const referenceInputsRef = useRef([])
		const referencesRef = useRef(previousReferences)
		const [modalOpenMassUploadLocations, setModalOpenMassUploadLocations] = useState(false)
		const [enablePaymentService, setEnablePaymentService] = useState('visible')

		useEffect(() => {
			referencesRef.current = previousReferences
		}, [previousReferences])

		const checkOptionNotification = (item) => {
			const isFound = previousNotifications.some((element) => element.notification === item)
			return isFound
		}

		const formTexts = {
			errorSubmitText:
				isEditable || isPreviousStepTwo
					? 'There was an error updating the request'
					: 'There was an error creating the request',
			successSubmitText:
				isEditable || isPreviousStepTwo
					? 'Settings has been updated successfully!'
					: 'Settings has been created successfully',
			nextStepButtonText: isEditable ? 'Save Account' : 'Next Step',
		}

		const handleAddNotification = (data) => {
			const newNotif = {}
			const filteredEmails = data.emailAddress?.filter((element) => isEmailInput(element)) ?? []
			if (filteredEmails.length > 0) {
				newNotif.notification = data.notifications
				newNotif.emailAddress = filteredEmails
				setPreviousNotifications([...previousNotifications, newNotif])
				resetNotifications({
					notifications: '',
					emailAddress: [],
				})
			} else {
				SweetAlertModal('error', 'Insert at least one valid email', null, 'Ok', true, false, null)
			}
		}

		const handleDeleteNotification = (index) => {
			const inputList = [...previousNotifications]
			inputList.splice(index, 1)
			setPreviousNotifications(inputList)
		}

		const handleDeleteReferences = (index) => {
			const inputList = [...previousReferences]
			inputList.splice(index, 1)
			setPreviousReferences(inputList)
		}

		const onNextStep = async (data) => {
			const localValidateMaxReplacementValue = data?.maxReplacementValue ?? ''
			const localValidateTypeAmountValue = data?.typeAmountValue ?? ''
			if (localValidateMaxReplacementValue.length === 0) {
				setErrorMaxReplacementValue('This value is required')
				return
			}
			if (localValidateTypeAmountValue.length === 0) {
				setErrorTypeAmountValue('This value is required')
				return
			}
			if (data.typeAmount === 'ADMIN_FEE' && !insuranceSettingsAreValid(localValidateTypeAmountValue)) {
				setErrorTypeAmountValue(
					'Invalid insurance admin fee tier structure. Toggle "Type of Uplift" to "Margin" or "Flat Fee" and back again to reset to the default admin fee tiers.',
				)
				return
			}
			const payload = {}
			const settings = {}
			payload.parentId = parentCompanyId
			payload.childs = childrenCompaniesIds
			payload.specialInstructionsCarrier = specialInstructionsCarrier
			settings.createQuoteNotification = previousNotifications
				.filter((element) => element.notification === 'Notify when someone creates a new quote')
				.map((element) => element.emailAddress.join(', '))
				.join(' ')
			settings.createShipmentNotification = previousNotifications
				.filter((element) => element.notification === 'Notify when someone creates a new shipment')
				.map((element) => element.emailAddress.join(', '))
				.join(' ')
			settings.customerPriorityLevel = data.customerPriorityLevel
			settings.enableShiplifyService = enableShiplifyService === 'active' ? 'true' : 'false'
			settings.enablePaymentService = enablePaymentService === 'visible' ? 'true' : 'false'
			settings.enableMaxReplacement = enableMaxReplacement
			settings.maxReplacementValue = data.maxReplacementValue
			settings.typeAmount = typeAmount
			settings.enableInsuranceOptIn = enableInsuranceOptIn

			if (settings.typeAmount === 'MARGIN' || settings.typeAmount === 'FLAT') {
				settings.typeAmountValue = data.typeAmountValue.replace(/[^0-9.]/g, '')
			} else {
				settings.typeAmountValue = DEFAULT_ADMIN_FEE
			}

			const newPreviousReferences = referencesRef.current.map((element) => ({
				...element,
				name: element.name.toUpperCase().replace(/\s/g, '_'),
			}))
			payload.references = newPreviousReferences
			payload.settings = settings
			const { status, resp } = await dispatch(createAccountSettings(payload, globalConfig))
			if (status === 'error') {
				SweetAlertModal('error', formTexts.errorSubmitText, resp, 'Ok', true, false, null)
			} else {
				const params = `?companyId=${parentCompanyId}&step=2&status=TRUE`
				const { status: stepStatus } = await dispatch(editAccountStepStatus(params, globalConfig))
				if (stepStatus === 'error') {
					SweetAlertModal(status, 'There was an error updating the account step status!', resp, 'Ok', true, false, null)
				}
				reset()
				resetNotifications()
				resetReferences()
				SweetAlertModal('success', formTexts.successSubmitText, null, 'Ok', true, false, null)
				dispatch(setTabAccountSettings(settings))
				dispatch(setTabAccountReferences(referencesRef.current))
				dispatch(setTabAccountSpecialInstructions(specialInstructionsCarrier))
				setActiveStep(activeStep + 1)
			}
		}

		const validateAccountForms = async (data) => {
			if (referenceInputsRef.current) {
				const referencesResponse = []
				referenceInputsRef.current.forEach((ref, index) => {
					if (ref) {
						setTimeout(() => {
							const response = ref.submitForm()
							referencesResponse[index] = response
						}, 1000)
					}
				})
				setTimeout(() => {
					if (referencesResponse.find((element) => element === 'error')) return
					onNextStep(data)
				}, 1000)
			}
		}

		useEffect(() => {
			const fetchData = async () => {
				const config = {
					headers: { 'X-Company-id': isEditable ? id : parentCompanyId },
				}
				const { status, resp } = await dispatch(fetchAccountSettings(config))
				if (status === 'success') {
					const { company, references, settings } = resp
					const newNotification = []
					if (settings.createShipmentNotification?.value) {
						const newElement = {}
						newElement.notification = 'Notify when someone creates a new shipment'
						newElement.emailAddress = settings.createShipmentNotification.value.split(',')
						newNotification.push(newElement)
					}
					if (settings.createQuoteNotification?.value) {
						const newElement = {}
						newElement.notification = 'Notify when someone creates a new quote'
						newElement.emailAddress = settings.createQuoteNotification.value.split(',')
						newNotification.push(newElement)
					}
					setPreviousNotifications(newNotification)
					const newReferences = []
					references.forEach((element) => {
						const obj = {}
						obj.id = element.id
						obj.name = element.name
						obj.value = element.is_required ? 'true' : 'false'
						newReferences.push(obj)
					})
					setPreviousReferences(newReferences.length > 0 ? newReferences : initialReferences)
					setSpecialInstructionsCarrier(company?.special_instructions_carrier ?? 'Do not stack.')
					setAccountStatusDropdown(settings?.customerPriorityLevel?.value ?? 'GOLD')
					setValue('customerPriorityLevel', settings?.customerPriorityLevel?.value ?? 'GOLD')

					// typeAmount and typeAmountValue need to be defaulted together
					// Or else they may fall into an incompatible state - IE. If typeAmount is MARGIN or FLAT
					// then DEFAULT_ADMIN_FEE for typeAmountValue is invalid, or visa-versa
					const newTypeAmount = settings?.typeAmount?.value ?? 'ADMIN_FEE'
					setTypeAmount(newTypeAmount)
					setTypeAmountValue(settings?.typeAmountValue?.value ?? getDefaultTypeAmountValue(newTypeAmount))

					// Todo: I'm not sure if this setting is being used anywhere
					// In the UI it says that it toggles whether or not 3rd party insurance is required
					// which seems to me to be something different altogether than what it's named and used for
					setEnableMaxReplacement(settings?.enableMaxReplacement?.value ?? 'false')
					// Todo: should this be defaulted to $100k instead of $80k?
					// We were told $100k should be the global maximum declared value for insurance purposes
					setMaxReplacementValue(settings?.maxReplacementValue?.value ?? '80000')
					setEnableInsuranceOptIn(settings?.enableInsuranceOptIn?.value ?? 'true')
					if (settings?.enableShiplifyService?.value) {
						setEnableShiplifyService(settings?.enableShiplifyService?.value === 'true' ? 'active' : 'inactive')
					} else {
						setEnableShiplifyService('active')
					}
					if (settings?.enablePaymentService?.value) {
						setEnablePaymentService(settings?.enablePaymentService?.value === 'true' ? 'visible' : 'hidden')
					} else {
						setEnablePaymentService('visible')
					}
				} else {
					SweetAlertModal('error', 'There was an error fetching the data', resp, 'Ok', true, false, null)
				}
			}
			if (isEditable || isPreviousStepTwo) fetchData()
		}, [isEditable, isPreviousStepTwo, id, parentCompanyId, dispatch, setValue])

		const deleteReferenceForm = (accountId) => {
			const newAccount = [...referenceForm]
			const filteredAccounts = newAccount.filter((item) => item.id !== accountId)
			setReferenceForm(filteredAccounts)
		}
		useImperativeHandle(refGlobal, () => ({
			onSubmit() {
				handleSubmit(validateAccountForms)()
			},
		}))

		const handleTypeAmountValueChange = (e) => {
			const newValue = e.target.value
			// If typeAmount is ADMIN_FEE then check that the format is valid
			if (typeAmount === 'ADMIN_FEE' && !insuranceSettingsAreValid(newValue)) {
				setErrorTypeAmountValue(
					'Invalid insurance admin fee tier structure. Toggle "Type of Uplift" to "Margin" or "Flat Fee" and back again to reset to the default admin fee tiers.',
				)
			} else {
				// ELSE the admin fee tier is valid, or we let the Material UI input handle validations
				// because it renders the number pretty, but returns a raw number value in string format
				setErrorTypeAmountValue('')
			}
			setTypeAmountValue(newValue)
		}

		const handleTypeAmountChange = (event) => {
			const newTypeAmount = event.target.value
			setTypeAmount(newTypeAmount)
			// We need need to change typeAmountValue if going to or from "ADMIN_FEE" because
			// the types of typeAmountValue for "ADMIN" and "MARGIN" are incompatible with "ADMIN_FEE"
			// It's easier and simpler just to reset it every time it's changed
			setTypeAmountValue(getDefaultTypeAmountValue(newTypeAmount))
		}

		// Dynamically determine input component type for typeAmountValue depending on typeAmount
		const typeAmountValueInputComponent = useMemo(() => {
			switch (typeAmount) {
				case 'MARGIN':
					// Forces a format of "x%"
					return PercentageFormat
				case 'FLAT':
					// Forces a format of $x
					return USDFormat
				default:
					// Use default InputComponent when typeAmount === 'ADMIN_FEE'
					// This leaves strings entered unformatted
					return undefined
			}
		}, [typeAmount])

		return (
			<>
				<div className={classes.newAccountSettingsContainer}>
					<div className={classes.newAccountSettingsNotification}>
						<div className={classes.newAccountSettingsTitleJustify}>
							<div className={classes.newAccountContainerSubTitle}>
								<div className={classes.newAccountIconContainer}>
									<InformationIcon className={classes.newAccountIconContainerIcon} />
								</div>
								<h3 className={classes.newAccountSettingsSectionTitle}>General Notifications</h3>
							</div>
							<Button
								className={classes.headerActionButton}
								startIcon={<BackupOutlined color="secondary" />}
								size="small"
								color="primary"
								onClick={() => setModalOpenMassUploadLocations(true)}
							>
								Upload Multiple Locations
							</Button>
						</div>
						<div className={classes.newAccountSettingsNotificationTable}>
							<TableElements
								previousElements={previousNotifications}
								handleDeleteNotification={handleDeleteNotification}
								isDeletable
							/>
						</div>
						<form
							onSubmit={handleSubmitNotifications(handleAddNotification)}
							className={classes.newAccountSettingNotificationFormRow}
						>
							<Grid container alignItems="center">
								<FormControl
									key="account-notifications"
									variant="outlined"
									className={[classes.root, classes.newAccountsNotificationField].join(' ')}
									error={!!errorsNotifications.notifications}
								>
									<InputLabel>Notification</InputLabel>
									<Controller
										render={({ onChange, value, name }) => (
											<Select
												name={name}
												value={value}
												onChange={(e) => {
													onChange(e.target.value)
													setValueNotifications('notifications', e.target.value, {
														shouldValidate: true,
														shouldDirty: true,
													})
													setNotificationDropDown(e.target.value)
												}}
												label="Notification"
												error={!!errorsNotifications.notifications}
												helperText={errorsNotifications.notifications?.message ?? ''}
											>
												{notificationsOptions.map((item, index) => (
													<MenuItem
														value={item.label}
														disabled={checkOptionNotification(item.label)}
														key={`id-${index + 1}`}
													>
														{item.label}
													</MenuItem>
												))}
											</Select>
										)}
										name="notifications"
										rules={{
											required: { value: true, message: 'Select one of the options.' },
										}}
										control={controlNotifications}
										defaultValue={notificationDropDown}
									/>
									<FormHelperText>
										{errorsNotifications.notifications ? errorsNotifications.notifications.message : null}
									</FormHelperText>
								</FormControl>
								<FormControl
									key="account-email"
									variant="outlined"
									className={[classes.root, classes.newAccountsEmailField].join(' ')}
									error={!!errorsNotifications.emailAddress}
								>
									<Controller
										render={({ onChange, value, name }) => (
											<CustomAutoComplete
												name={name}
												multiple
												freeSolo
												options={[]}
												value={value}
												onChange={(e, newValue) => {
													onChange(newValue)
													setValueNotifications('emailAddress', newValue, { shouldValidate: true, shouldDirty: true })
													setEmailAddressNotification(newValue)
												}}
												renderTags={(values, getTagProps) =>
													values.map((option, index) => {
														if (isEmailInput(option)) {
															// eslint-disable-next-line react/jsx-props-no-spreading
															return <Chip label={option} {...getTagProps({ index })} />
														}
														return false
													})
												}
												renderInput={(props) => (
													<TextField
														// eslint-disable-next-line react/jsx-props-no-spreading
														{...props}
														name={name}
														label="Add email"
														margin="normal"
														className={{
															marginNormal: classes.marginZero,
															root: [classes.root, classes.newAccountsEmailField].join(' '),
														}}
														variant="outlined"
														error={!!errorsNotifications.emailAddress}
													/>
												)}
											/>
										)}
										name="emailAddress"
										control={controlNotifications}
										defaultValue={emailAddressNotification}
									/>
									<FormHelperText>
										{errorsNotifications.emailAddress ? (
											errorsNotifications.emailAddress.message
										) : (
											<span>Press enter to confirm your email</span>
										)}
									</FormHelperText>
								</FormControl>
								<Button
									color="primary"
									type="submit"
									className={classes.addNotificationButton}
									disabled={previousNotifications.length >= 2}
								>
									<AddIcon className={classes.addIconButton} />
									Add
								</Button>
							</Grid>
						</form>
					</div>
					<div className={classes.newAccountSettingsReferences}>
						<div className={classes.newAccountSettingsReferencesForm}>
							<div className={classes.newAccountSettingsTitle}>
								<div className={classes.newAccountIconContainer}>
									<AssignmentOutlinedIcon className={classes.newAccountIconContainerIcon} />
								</div>
								<h3 className={classes.newAccountSettingsSectionTitle}>References</h3>
							</div>
							<div className={classes.newAccountSettingsReferencesTable}>
								<TableReferences
									previousElements={previousReferences}
									handleDeleteReferences={handleDeleteReferences}
									isDeletable
								/>
							</div>
							{referenceForm.map((item, index) => (
								<AccountReferenceForm
									key={`id-${index + 1}`}
									referenceItem={item}
									newReferences={referenceInputsRef}
									previousReferences={previousReferences}
									setPreviousReferences={setPreviousReferences}
									deleteReferenceForm={deleteReferenceForm}
									ref={(el) => {
										referenceInputsRef.current[index] = el
									}}
								/>
							))}
							<Button
								color="primary"
								type="submit"
								className={classes.addReferenceButton}
								onClick={() => {
									setReferenceForm((prevState) => [
										...prevState,
										prevState.length > 0 ? { id: Number(prevState.slice(-1)[0].id) + 1 } : { id: 0 },
									])
								}}
							>
								<AddIcon className={classes.addIconButton} />
								Add
							</Button>
						</div>
						<div className={classes.newAccountSettingsInstructionsForm}>
							<div className={classes.newAccountSettingsTitle}>
								<div className={classes.newAccountIconContainer}>
									<SpecialInstructionsIcon className={classes.newAccountIconContainerIcon} />
								</div>
								<h3 className={classes.newAccountSettingsSectionTitle}>Special Instructions for Carrier</h3>
							</div>
							<TextField
								name="specialInstructionsCarrier"
								multiline
								rows={matches ? 2 : 6}
								className={[classes.root, classes.newAccountSpecialInstructionsField].join(' ')}
								value={specialInstructionsCarrier}
								onChange={(e) => setSpecialInstructionsCarrier(e.target.value)}
								error={!!errorsReferences.specialInstructionsCarrier}
								helperText={
									errorsReferences.specialInstructionsCarrier
										? errorsReferences.specialInstructionsCarrier.message
										: null
								}
								FormHelperTextProps={{ classes: { root: classes.helperText } }}
								variant="outlined"
							/>
						</div>
					</div>
					<form onSubmit={handleSubmit(validateAccountForms)}>
						<div className={classes.newAccountSettingsThirdParty}>
							<div className={classes.newAccountSettingsThirdPartyContainer}>
								<div className={classes.newAccountSettingsTitle}>
									<div className={classes.newAccountIconContainer}>
										<VerifiedUserOutlinedIcon className={classes.newAccountIconContainerIcon} />
									</div>
									<h3 className={classes.newAccountSettingsSectionTitle}>Third-Party Insurance</h3>
								</div>
								<div className={classes.newAccountSettingsInsuranceFormRow}>
									<FormControl style={matches ? { width: '100%' } : { marginLeft: '10px', width: '42%' }}>
										<FormLabel className={classes.newAccountRadioLabel}>Is third party insurance required?</FormLabel>
										<RadioGroup
											style={{ flexDirection: 'row' }}
											aria-label="enableMaxReplacement"
											name="enableMaxReplacement"
											value={enableMaxReplacement}
											onChange={(event) => setEnableMaxReplacement(event.target.value)}
										>
											<FormControlLabel
												classes={{ label: classes.radioButtonOptionLabel }}
												value="false"
												control={<Radio color="secondary" />}
												label="No"
											/>
											<FormControlLabel
												classes={{ label: classes.radioButtonOptionLabel }}
												value="true"
												control={<Radio color="secondary" />}
												label="Yes"
											/>
										</RadioGroup>
									</FormControl>
									<TextField
										name="maxReplacementValue"
										type="text"
										className={[classes.root, classes.newAccountMaximumReplacement].join(' ')}
										value={maxReplacementValue}
										onChange={(e) => setMaxReplacementValue(e.target.value)}
										error={errorMaxReplacementValue.length > 0}
										helperText={errorMaxReplacementValue.length > 0 ? errorMaxReplacementValue : null}
										FormHelperTextProps={{ classes: { root: classes.helperText } }}
										label="Maximum Replacement Value *"
										variant="outlined"
										InputProps={{
											inputComponent: USDFormat,
										}}
										inputRef={register()}
									/>
								</div>
								<div className={classes.newAccountSettingsInsuranceFormRow}>
									<FormControl style={matches ? { width: '100%' } : { marginLeft: '10px', width: '42%' }}>
										<FormLabel className={classes.newAccountRadioLabel}>Default insurance?</FormLabel>
										<RadioGroup
											style={{ flexDirection: 'row' }}
											aria-label="enableInsuranceOptIn"
											name="enableInsuranceOptIn"
											value={enableInsuranceOptIn}
											onChange={(event) => setEnableInsuranceOptIn(event.target.value)}
										>
											<FormControlLabel
												classes={{ label: classes.radioButtonOptionLabel }}
												value="false"
												control={<Radio color="secondary" />}
												label="No"
											/>
											<FormControlLabel
												classes={{ label: classes.radioButtonOptionLabel }}
												value="true"
												control={<Radio color="secondary" />}
												label="Yes"
											/>
										</RadioGroup>
									</FormControl>
								</div>
								<div className={classes.newAccountSettingsInsuranceFormRow}>
									<FormControl style={matches ? { width: '100%' } : { marginLeft: '10px', width: '42%' }}>
										<FormLabel className={classes.newAccountRadioLabel}>Type of uplift</FormLabel>
										<RadioGroup
											style={{ flexDirection: 'row' }}
											aria-label="typeAmount"
											name="typeAmount"
											value={typeAmount}
											onChange={handleTypeAmountChange}
										>
											<FormControlLabel
												classes={{ label: classes.radioButtonOptionLabel }}
												value="ADMIN_FEE"
												control={<Radio color="secondary" />}
												label="Admin Fee"
											/>
											<FormControlLabel
												classes={{ label: classes.radioButtonOptionLabel }}
												value="MARGIN"
												control={<Radio color="secondary" />}
												label="Margin"
											/>
											<FormControlLabel
												classes={{ label: classes.radioButtonOptionLabel }}
												value="FLAT"
												control={<Radio color="secondary" />}
												label="Flat Fee"
											/>
										</RadioGroup>
									</FormControl>
									<TextField
										name="typeAmountValue"
										type="text"
										className={[classes.root, classes.newAccountAmount].join(' ')}
										value={typeAmountValue}
										onChange={handleTypeAmountValueChange}
										FormHelperTextProps={{ classes: { root: classes.helperText } }}
										label="Uplift Amount *"
										variant="outlined"
										InputProps={{
											inputComponent: typeAmountValueInputComponent,
										}}
										inputRef={register()}
										error={errorTypeAmountValue.length > 0}
										helperText={errorTypeAmountValue.length > 0 ? errorTypeAmountValue : null}
										multiline={typeAmount === 'ADMIN_FEE'}
									/>
								</div>
							</div>
							<div className={classes.newAccountSettingsShiplifyContainer}>
								<div className={classes.newAccountSettingsTitle}>
									<div className={classes.newAccountIconContainer}>
										<LaneInfoIcon className={classes.newAccountIconContainerIcon} />
									</div>
									<h3 className={classes.newAccountSettingsSectionTitle}>
										Residential/Limited Access Address Validation
									</h3>
								</div>
								<FormControl style={matches ? { marginTop: '20px' } : { marginTop: '20px', marginLeft: '10px' }}>
									<FormLabel className={classes.newAccountRadioLabel}>Status</FormLabel>
									<RadioGroup
										style={{ flexDirection: 'row' }}
										aria-label="enableShiplifyService"
										name="enableShiplifyService"
										value={enableShiplifyService}
										onChange={(event) => setEnableShiplifyService(event.target.value)}
									>
										<FormControlLabel
											classes={{ label: classes.radioButtonOptionLabel }}
											value="active"
											control={<Radio color="secondary" />}
											label="Active"
										/>
										<FormControlLabel
											classes={{ label: classes.radioButtonOptionLabel }}
											value="inactive"
											control={<Radio color="secondary" />}
											label="Inactive"
										/>
									</RadioGroup>
								</FormControl>
								<div className={classes.newAccountSettingsTitle}>
									<div className={classes.newAccountIconContainer}>
										<AttachMoneyIcon htmlColor={theme.palette.success.main} />
									</div>
									<h3 className={classes.newAccountSettingsSectionTitle}>Make a Payment Button Visibility</h3>
								</div>
								<FormControl style={matches ? { marginTop: '20px' } : { marginTop: '20px', marginLeft: '10px' }}>
									<FormLabel className={classes.newAccountRadioLabel}>Status</FormLabel>
									<RadioGroup
										style={{ flexDirection: 'row' }}
										aria-label="enablePaymentService"
										name="enablePaymentService"
										value={enablePaymentService}
										onChange={(event) => setEnablePaymentService(event.target.value)}
									>
										<FormControlLabel
											classes={{ label: classes.radioButtonOptionLabel }}
											value="visible"
											control={<Radio color="secondary" />}
											label="Visible"
										/>
										<FormControlLabel
											classes={{ label: classes.radioButtonOptionLabel }}
											value="hidden"
											control={<Radio color="secondary" />}
											label="Hidden"
										/>
									</RadioGroup>
								</FormControl>
							</div>
							<div className={classes.newAccountSettingsCustomerContainer}>
								<div className={classes.newAccountSettingsTitle}>
									<div className={classes.newAccountIconContainer}>
										<SupervisorAccountOutlinedIcon className={classes.newAccountIconContainerIcon} />
									</div>
									<h3 className={classes.newAccountSettingsSectionTitle}>Customer Priority Level</h3>
								</div>
								<FormControl
									key="account-status"
									variant="outlined"
									className={[classes.root, classes.newAccountsCustomerStatus].join(' ')}
									error={!!errors.customerPriorityLevel}
								>
									<InputLabel>Status *</InputLabel>
									<Controller
										render={({ onChange, value, name }) => (
											<Select
												name={name}
												value={value}
												onChange={(e) => {
													onChange(e.target.value)
													setValue('customerPriorityLevel', e.target.value, { shouldValidate: true, shouldDirty: true })
													setAccountStatusDropdown(e.target.value)
												}}
												label="Status *"
											>
												{statusOptions.map((item, index) => (
													<MenuItem value={item.value} key={`id-${index + 1}`}>
														{item.label}
													</MenuItem>
												))}
											</Select>
										)}
										name="customerPriorityLevel"
										defaultValue={accountStatusDropdown}
										control={control}
										rules={{ required: { value: true, message: 'This value is required' } }}
									/>
								</FormControl>
							</div>
						</div>
						<div className={classes.newAccountSettingsButtons}>
							<Button
								className={classes.nextStepButton}
								type="submit"
								variant="contained"
								color="secondary"
								onMouseDown={() => {
									setErrorMaxReplacementValue('')
									setErrorTypeAmountValue('')
								}}
							>
								{formTexts.nextStepButtonText}
							</Button>
							{isEditable && (
								<Button className={classes.nextButtonEdit} type="submit">
									Next
								</Button>
							)}
							<Button
								className={classes.previousButton}
								variant="contained"
								onClick={() => {
									setActiveStep(activeStep - 1)
									setIsEdit(true)
								}}
							>
								Previous
							</Button>
							<Button className={classes.cancelButton} onClick={() => handleCancelAccountProcess()}>
								Cancel
							</Button>
						</div>
					</form>
				</div>
				{modalOpenMassUploadLocations && (
					<UploadLocationsModal
						open={modalOpenMassUploadLocations}
						handleClose={() => setModalOpenMassUploadLocations(false)}
					/>
				)}
			</>
		)
	},
)

SettingsStepper.propTypes = {
	setActiveStep: PropTypes.func.isRequired,
	activeStep: PropTypes.number.isRequired,
	isEditable: PropTypes.bool.isRequired,
	isPreviousStepTwo: PropTypes.bool.isRequired,
	setIsEdit: PropTypes.func.isRequired,
	handleCancelAccountProcess: PropTypes.func.isRequired,
}

export default SettingsStepper
