/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import reqCurrentUserData from '../requests/Profile.request'

const profileSlice = createSlice({
	name: 'profileSlice',
	initialState: {
		profile: null,
		isLoading: false,
		error: false,
	},
	reducers: {
		startLoading: (state) => {
			state.isLoading = true
		},
		hasError: (state, action) => {
			state.error = action.payload
			state.isLoading = false
		},
		profileSuccess: (state, action) => {
			state.profile = action.payload
			state.isLoading = false
		},
	},
})

const { actions, reducer } = profileSlice

export const { startLoading, hasError, profileSuccess } = actions

export const fetchProfile = (config) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqCurrentUserData(config)
	if (error) {
		dispatch(hasError(data))
		return { status: 'error', resp: data }
	}
	dispatch(profileSuccess(data.data.data))
	return { status: 'success', resp: data.data.data }
}

export default reducer
