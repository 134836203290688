import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
	accountCarrierWrapper: {
		padding: theme.spacing(2, 3, 27, 3),
		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(2, 3, 14, 3),
		},
	},
	accountCarriersContainer: {
		padding: '16px 24px 8px 24px',
		width: 'calc(100% - 10px)',
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.down(theme.breakpoints.values.md)]: {
			width: '100%',
		},
	},
	containerTitle: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexWrap: 'wrap',
	},
	accountCarriersTitleContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	accountCarriersTitleIcon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '28px',
		height: '28px',
		borderRadius: '20px',
		padding: '2px',
		color: theme.palette.primary.main,
		marginRight: '10px',
	},
	accountCarriersTitleTex: {
		fontSize: '18px',
		fontWeight: theme.typography.fontWeightRegular,
		color: theme.palette.primary.main,
		margin: 0,
		lineHeight: '22px',
		[theme.breakpoints.down('md')]: {
			fontSize: '15px',
		},
	},
	accountCarriersTitleDescription: {
		fontSize: '16px',
		marginTop: '10px',
		[theme.breakpoints.down(theme.breakpoints.values.md)]: {
			display: 'none',
		},
	},
	accountCarriersSelectBy: {
		fontSize: '16px',
		padding: '5px 15px',
		display: 'flex',
		columnGap: '1em',
		alignItems: 'center',
		margin: '1em 0 0 0',
		[theme.breakpoints.down(theme.breakpoints.values.md)]: {
			flexDirection: 'column',
			alignItems: 'start',
			rowGap: '1em',
		},
		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			padding: '5px 0',
		},
	},
	accountCarriersCarrierGroup: {
		width: '190px',
		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			width: '230px',
		},
		[theme.breakpoints.down(theme.breakpoints.values.md)]: {
			width: '100%',
		},
	},
	accountCarriersContractType: {
		width: '290px',
		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			width: '230px',
		},
		[theme.breakpoints.down(theme.breakpoints.values.md)]: {
			width: '100%',
		},
	},
	accountCarriersApplyFiltersContainer: {
		[theme.breakpoints.down(theme.breakpoints.values.md)]: {
			width: '100%',
			display: 'flex',
			justifyContent: 'flex-end',
		},
	},
	accountCarriersApplyFilters: {
		display: 'flex',
		alignItems: 'center',
		textTransform: 'none',
		fontSize: '12px',
		fontWeight: theme.typography.fontWeightRegular,
	},
	accountCarriersConfigForm: {
		margin: '0 0 1em 0',
		padding: '0 0 0 15px',
		[theme.breakpoints.down(theme.breakpoints.values.md)]: {
			width: '100%',
		},
		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			padding: 0,
		},
	},
	accountCarriersConfigInputs: {
		width: '230px',
		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			padding: 0,
			width: '49%',
		},
		[theme.breakpoints.down(theme.breakpoints.values.md)]: {
			width: '100%',
			marginBottom: '2em',
		},
	},
	accountCarriersDivider: {
		margin: '1em 0 1em 15px',
		[theme.breakpoints.down(theme.breakpoints.values.md)]: {
			margin: '1em 0',
			width: '100%',
		},
	},
	accountCarrierAddCarrierButtonContainer: {
		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			width: '100%',
			display: 'flex',
			justifyContent: 'flex-end',
		},
		[theme.breakpoints.down(theme.breakpoints.values.md)]: {
			width: '100%',
			display: 'flex',
			justifyContent: 'flex-end',
		},
	},
	accountCarriersCustomizableInputs: {
		display: 'flex',
		alignItems: 'center',
		columnGap: '10px',
		padding: '0 0 0 15px',
		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			padding: 0,
		},
	},
	accountCarriersAddCarrier: {
		display: 'flex',
		alignItems: 'center',
		textTransform: 'none',
		fontSize: '12px',
		fontWeight: theme.typography.fontWeightRegular,
	},
	accountCarriersCustomization: {
		display: 'flex',
		alignItems: 'center',
		columnGap: '10px',
		fontSize: '16px',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
			alignItems: 'flex-start',
			marginBottom: '0.5em',
		},
	},
	accountCarriersFuelSurchargeMargin: {
		width: '20%',
		paddingLeft: '20px',
		margin: '1em 0',
		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			width: '48%',
			paddingLeft: 0,
		},
		[theme.breakpoints.down(theme.breakpoints.values.md)]: {
			width: '95%',
			paddingLeft: 0,
		},
	},
	accountCarriersApplyFiltersContainerTwo: {
		[theme.breakpoints.down(theme.breakpoints.values.md)]: {
			width: '98%',
			display: 'flex',
			justifyContent: 'flex-end',
		},
	},
	accountCarriersCustomizableInputsTitle: {
		width: '310px',
	},
	accountCarriersUpliftOptions: {
		display: 'flex',
		fontSize: '14px',
		'& .MuiFormControlLabel-label': {
			fontSize: '13px',
		},
	},
	accountCarriersAccessorialsTable: {
		width: '600px',
		[theme.breakpoints.down(theme.breakpoints.values.md)]: {
			display: 'none',
		},
	},
	accountCarriresTableHeads: {
		fontSize: '14px',
		fontWeight: theme.typography.fontWeightMedium,
		border: 'none',
		padding: 0,
	},
	accountCarriersTableCell: {
		fontSize: '14px',
		border: 'none',
		padding: '15px 0',
	},
	filterButtonText: {
		width: '80px',
		textTransform: 'none',
		color: 'black',
		fontWeight: theme.typography.fontWeightRegular,
		fontSize: '13px',
	},
	containerButton: {
		justifyContent: 'flex-end',
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		padding: '20px',
		backgroundColor: theme.palette.neutral.white,
		position: 'fixed',
		bottom: 0,
		left: 0,
		right: 0,
		zIndex: 1,
		[theme.breakpoints.up('md')]: {
			flexDirection: 'row-reverse',
			justifyContent: 'flex-start',
		},
	},
	nextStepButton: {
		width: '100%',
		marginTop: '10px',
		marginBottom: '10px',
		marginLeft: '0',
		height: '48px',
		fontSize: '17px',
		fontWeight: theme.typography.fontWeightMedium,
		color: theme.palette.neutral.white,
		boxShadow: 'none',
		margin: theme.spacing(0, 0, 0),
		textTransform: 'none',
		[theme.breakpoints.up('md')]: {
			width: '180px',
			marginLeft: '1em',
		},
	},
	cancelButton: {
		height: '48px',
		width: '100%',
		backgroundColor: theme.palette.neutral.whiteGray,
		textTransform: 'none',
		boxShadow: 'none',
		color: theme.palette.primary.main,
		[theme.breakpoints.up('md')]: {
			width: '161px',
		},
		[theme.breakpoints.up('lg')]: {
			color: theme.palette.neutral.black,
		},
	},
	previousButton: {
		width: '100%',
		marginTop: '10px',
		marginBottom: '10px',
		marginLeft: '0',
		boxShadow: 'none',
		height: '48px',
		fontSize: '17px',
		fontWeight: theme.typography.fontWeightMedium,
		color: theme.palette.neutral.black,
		backgroundColor: theme.palette.neutral.mainGray,
		margin: theme.spacing(0, 0, 0),
		textTransform: 'none',
		[theme.breakpoints.up('md')]: {
			width: '171px',
			marginLeft: '1em',
		},
	},
	tableContainer: {
		width: '600px',
		[theme.breakpoints.down(theme.breakpoints.values.md)]: {
			display: 'none',
		},
		margin: '20px 0px 0px',
		maxHeight: '260px',
		overflowX: 'hidden',
		'&::-webkit-scrollbar': {
			width: '5px',
		},
		'&::-webkit-scrollbar-track': {
			'-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
			borderRadius: '10px',
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: '10px',
			'-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.5)',
		},
	},
	headerActionButton: {
		textTransform: 'none',
		fontWeight: theme.typography.body2.fontWeight,
		fontSize: theme.typography.body2.fontSize,
		[theme.breakpoints.down('sm')]: {
			margin: '5px 0',
		},
	},
}))
