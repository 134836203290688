import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
		border: '1px solid',
		borderColor: theme.palette.neutral.lightGray,
		borderRadius: 6,
		overflow: 'hidden',
	},
	table: {
		minWidth: 750,
	},
	tablePaginator: {
		fontSize: 12,
		color: theme.palette.neutral.black,
	},
	tablePaginatorToolbar: {
		minHeight: 43,
	},
	tablePaginatorActions: {
		'& .MuiIconButton-root': {
			padding: 8,
		},
	},
	messageTable: {
		position: 'absolute',
		left: '15%',
		marginTop: '3em',
		fontSize: '16px',
		[theme.breakpoints.up('sm')]: {
			left: '50%',
			fontSize: '22px',
		},
	},
}))

export default useStyles
