import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react'
import PropTypes from 'prop-types'
import { Grid, Button, TextField } from '@material-ui/core'

import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'

import { useForm } from 'react-hook-form'

import PhoneNumberFormat from '../PhoneNumberFormat'

import useStyles from './styles'

const ChildAccountsForm = forwardRef((props, ref) => {
	const classes = useStyles()
	const { childAccounts, setChildAccounts, deleteAccountForm, item } = props

	const { handleSubmit, register, errors, watch, setValue } = useForm({
		defaultValues: {
			accountNumber: 'ML72552251',
		},
	})
	const [childAccountContactPhone, setChildAccountContactPhone] = useState('')
	const [childAccountId, setChildAccountId] = useState('')
	const [editableChildAcountId, setEditableChildAcountId] = useState(-1)

	const formRef = useRef(null)

	useEffect(() => {
		if (item.data) {
			setChildAccountId(item.data.id)
			setValue('salesForceNumber', item.data.salesForceNumber)
			setValue('name', item.data.name)
			setValue('accountNumber', item.data.accountNumber)
			setValue('contact', item.data.contact)
			setValue('contactPhoneExt', item.data.contactPhoneExt)
			setChildAccountContactPhone(item.data.phone)
			setValue('email', item.data.email)
			setEditableChildAcountId(item.id)
		}
	}, [item, setValue])

	const handleAddChildAccountClick = (data) => {
		if (editableChildAcountId !== -1) {
			const accountIndex = childAccounts.findIndex((account) => account.id === item.id)
			if (childAccounts[accountIndex]) {
				const newAccounts = JSON.parse(JSON.stringify(childAccounts))
				const newData = data
				newData.id = childAccountId
				newAccounts[accountIndex] = newData
				setChildAccounts(newAccounts)
			} else {
				const inputList = [...childAccounts]
				const newData = data
				newData.id = childAccountId
				inputList.splice(editableChildAcountId, 1, newData)
				setChildAccounts(inputList)
			}
			setEditableChildAcountId(-1)
		} else {
			const accountExists =
				childAccounts?.find(
					(element) =>
						element.name === data.name &&
						element.salesForceNumber === data.salesForceNumber &&
						element.accountNumber === data.accountNumber &&
						element.contact === data.contact &&
						element.phone === data.phone &&
						element.contactPhoneExt &&
						element.email === data.email,
				) ?? {}

			if (Object.entries(accountExists).length === 0) {
				setChildAccounts([...childAccounts, { ...data, id: '' }])
				setChildAccountId('')
				setEditableChildAcountId(-1)
			}
		}
	}

	useImperativeHandle(ref, () => ({
		submitForm() {
			formRef.current.dispatchEvent(new Event('submit', { cancelable: true }))

			if (
				!watch('name') ||
				watch('name').length > 45 ||
				!watch('salesForceNumber') ||
				watch('salesForceNumber').length > 45 ||
				!watch('accountNumber') ||
				watch('accountNumber').length > 45 ||
				watch('contact').length > 25 ||
				watch('contactPhoneExt') < 0 ||
				watch('contactPhoneExt').length > 5 ||
				(watch('phone') && !/^([(]([0-9]{3})[)] ([0-9]{3})(-)([0-9]{4}))$/.test(watch('phone'))) ||
				(watch('email') && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(watch('email')))
			) {
				return 'error'
			}
			return 'success'
		},
	}))

	return (
		<form ref={formRef} onSubmit={handleSubmit(handleAddChildAccountClick)} className={classes.formContainer}>
			<Grid container spacing={2} style={{ paddingBottom: '24px' }}>
				<Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
					<TextField
						name="name"
						className={classes.root}
						style={{ width: '100%' }}
						inputRef={register({ required: true, maxLength: { value: 45, message: 'Maximum 45 characters allowed' } })}
						error={!!errors.name}
						helperText={errors.name ? errors.name.message : null}
						FormHelperTextProps={{ classes: { root: classes.helperText } }}
						label="Account Name *"
						variant="outlined"
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
					<TextField
						name="salesForceNumber"
						className={classes.root}
						style={{ width: '100%' }}
						inputRef={register({ required: true, maxLength: { value: 45, message: 'Maximum 45 characters allowed' } })}
						error={!!errors.salesForceNumber}
						helperText={errors.salesForceNumber ? errors.salesForceNumber.message : null}
						FormHelperTextProps={{ classes: { root: classes.helperText } }}
						label="Salesforce Account Number *"
						variant="outlined"
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
					<TextField
						name="accountNumber"
						className={classes.root}
						style={{ width: '100%' }}
						inputRef={register({ required: true, maxLength: { value: 45, message: 'Maximum 45 characters allowed' } })}
						error={!!errors.accountNumber}
						helperText={errors.accountNumber ? errors.accountNumber.message : null}
						FormHelperTextProps={{ classes: { root: classes.helperText } }}
						label="TMS Account Number *"
						variant="outlined"
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
					<TextField
						name="contact"
						type="text"
						className={classes.root}
						style={{ width: '100%' }}
						inputRef={register({ maxLength: { value: 25, message: 'Maximum 25 characters allowed' } })}
						error={!!errors.contact}
						helperText={errors.contact ? errors.contact.message : null}
						FormHelperTextProps={{ classes: { root: classes.helperText } }}
						label="Contact Name"
						variant="outlined"
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
					<TextField
						name="phone"
						type="text"
						className={classes.root}
						style={{ width: '100%' }}
						value={childAccountContactPhone}
						onChange={(e) => setChildAccountContactPhone(e.target.value)}
						inputRef={register({
							pattern: {
								value: /^([(]([0-9]{3})[)] ([0-9]{3})(-)([0-9]{4}))$/,
								message: 'Enter a valid phone number format',
							},
						})}
						error={!!errors.phone}
						helperText={errors.phone ? errors.phone.message : null}
						InputProps={{
							inputComponent: PhoneNumberFormat,
						}}
						FormHelperTextProps={{ classes: { root: classes.helperText } }}
						label="Contact Phone"
						variant="outlined"
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
					<TextField
						name="contactPhoneExt"
						type="number"
						className={classes.root}
						style={{ width: '100%' }}
						inputRef={register({
							maxLength: { value: 5, message: 'Maximum 5 characters allowed' },
							min: { value: 0, message: 'Please enter a valid extension' },
						})}
						error={!!errors.contactPhoneExt}
						helperText={errors.contactPhoneExt ? errors.contactPhoneExt.message : null}
						FormHelperTextProps={{ classes: { root: classes.helperText } }}
						label="Ext"
						variant="outlined"
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
					<TextField
						name="email"
						className={classes.root}
						style={{ width: '100%' }}
						inputRef={register({
							pattern: {
								value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
								message: 'Please enter a valid email format',
							},
							maxLength: { value: 50, message: 'Maximum 50 characters allowed' },
						})}
						error={!!errors.email}
						helperText={errors.email ? errors.email.message : null}
						FormHelperTextProps={{ classes: { root: classes.helperText } }}
						label="Contact Email"
						variant="outlined"
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</Grid>
				<Grid item style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }} xs={12}>
					<Button color="primary" className={classes.button} onClick={() => deleteAccountForm(item.id)}>
						<DeleteOutlineOutlinedIcon className={classes.newChildAccountIcon} />
						Delete
					</Button>
				</Grid>
			</Grid>
		</form>
	)
})

ChildAccountsForm.propTypes = {
	item: PropTypes.objectOf(PropTypes.any),
	childAccounts: PropTypes.arrayOf(PropTypes.any).isRequired,
	setChildAccounts: PropTypes.func.isRequired,
	deleteAccountForm: PropTypes.func,
}

ChildAccountsForm.defaultProps = {
	item: {},
	deleteAccountForm: () => {},
}

export default ChildAccountsForm
