import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	root: {
		fontSize: '16px',
		'& .MuiFormLabel-root': {
			fontSize: '16px',
		},
		'& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
			display: 'none',
			margin: 80,
		},
	},
	checkBoxLabel: {
		fontFamily: 'Open Sans',
		fontSize: '12px',
		fontWeight: 400,
		color: '#2C2C2C',
	},
	disabled: {
		'& .MuiInputBase-root.Mui-disabled': {
			background: '#CCD2D8',
			color: 'black !important',
		},
	},
	disabledSelect: {
		background: '#CCD2D8',
		color: 'black !important',
	},
	disabledAutocomplete: {
		background: '#CCD2D8',
		'& .MuiAutocomplete-tag': {
			backgroundColor: '#EBEEF1',
			color: '#2C2C2C !important',
		},
		'& .MuiChip-label': {
			color: 'black !important',
		},
		'& .MuiChip-deleteIcon': {
			color: '#EC3514',
		},
	},
	mainIcon: {
		backgroundColor: '#F9D2CA',
		borderRadius: '20px',
	},
	icon: {
		color: '#EC3514',
	},
	textFieldLabel: {
		fontFamily: 'Open Sans',
		fontSize: '16px',
		fontWeight: 400,
	},
	helperText: {
		fontFamily: 'Open Sans',
		fontSize: '12px',
		fontWeight: '400',
		color: '#A1A1A1',
		marginBottom: '8px',
	},
	button: {
		width: '175px',
		height: '48px',
		fontFamily: 'Open Sans',
		fontSize: '17px',
		fontWeight: '600',
		color: '#FFFFFF',
		backgroundColor: '#FF3333',
		margin: theme.spacing(0, 0, 0),
		alignSelf: 'center',
		textTransform: 'none',
		'&:hover': {
			backgroundColor: '#F30000',
		},
		'@media only screen and (max-width: 768px)': {
			width: '140px',
		},
	},
	cancelButton: {
		width: '90px',
		height: '48px',
		alignSelf: 'center',
		backgroundColor: '#CCD2D8',
		color: '#2C2C2C',
		textTransform: 'unset',
		'&:hover': {
			backgroundColor: '#b1b7bd',
			color: '#2C2C2C',
		},
	},
	previousButton: {
		width: '175px',
		height: '48px',
		fontFamily: 'Open Sans',
		fontSize: '17px',
		fontWeight: '600',
		color: '#2C2C2C',
		backgroundColor: '#CCD2D8',
		margin: theme.spacing(0, 0, 0),
		alignSelf: 'end',
		textTransform: 'none',
		'@media only screen and (max-width: 768px)': {
			width: '140px',
		},
	},
	cancelShipmentButton: {
		color: '#F30000',
		cursor: 'pointer',
		fontWeight: '600',
		textTransform: 'none',
	},
	buttonQuote: {
		width: '266px',
		height: '48px',
		fontFamily: 'Open Sans',
		fontSize: '17px',
		fontWeight: '600',
		color: '#2C2C2C',
		backgroundColor: '#CCD2D8',
		margin: theme.spacing(0, 0, 0),
		alignSelf: 'center',
		textTransform: 'none',
	},
	buttonShipment: {
		width: '286px',
		height: '48px',
		fontFamily: 'Open Sans',
		fontSize: '17px',
		fontWeight: '600',
		color: '#FFFFFF',
		backgroundColor: '#EC3514',
		margin: theme.spacing(0, 0, 0),
		alignSelf: 'center',
		textTransform: 'none',
		'&:hover': {
			backgroundColor: '#F30000',
		},
	},
}))

export default useStyles
