/* eslint-disable react/no-unused-prop-types */
import React, { useState, forwardRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'

import { Controller } from 'react-hook-form'

import {
	Grid,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	TextField,
	Tooltip,
	IconButton,
	useTheme,
	Button,
} from '@material-ui/core'

import { DeleteOutline as DeleteOutlineIcon } from '@material-ui/icons'
import { HazIcon, StackIcon } from '../../../../../../assets/Icons/Icons'
import useStyles from './styles'

import packageTypeOptions, { freightClassOptions } from '../../../../../../utils/dropDownOptions'
import { capitalizeFirstLetter } from '../../../../../../utils/helpers'
import DimensionsInput from './dimension'
import HazmatModal from '../../../../../../components/HazmatModal'

const ShipmentItem = forwardRef(({ item, itemIndex, control, errors, remove, isWritableElem, trigger, watch }, ref) => {
	const [openHazmatModal, setOpenHazmatModal] = useState(false)
	const classes = useStyles()
	const theme = useTheme()

	const handleSetHazardous = (onChange, value) => {
		onChange(!value)
		if (!value) setOpenHazmatModal(true)
	}

	const validateInput = () => [
		trigger(`items.${itemIndex}.packageType`),
		trigger(`items.${itemIndex}.quantity`),
		trigger(`items.${itemIndex}.freightClass`),
		trigger(`items.${itemIndex}.nmfc`),
		trigger(`items.${itemIndex}.weight`),
		trigger(`items.${itemIndex}.length`),
		trigger(`items.${itemIndex}.width`),
		trigger(`items.${itemIndex}.height`),
		trigger(`items.${itemIndex}.commodity`),
	]

	useImperativeHandle(ref, () => ({
		validateRowInputs() {
			return validateInput()
		},
		getRowValues() {
			return watch(`items.${itemIndex}`)
		},
	}))

	return (
		<>
			<Grid container spacing={1} className={classes.rowContainer}>
				<Grid item xs={6} sm={6} lg={2} xl={2}>
					<FormControl size="small" style={{ width: '100%' }} variant="outlined" error={!!errors?.packageType}>
						<InputLabel className={classes.root}>Packaging *</InputLabel>
						<Controller
							render={({ onChange, value, name }) => (
								<Select
									name={name}
									value={value}
									onChange={(e) => {
										onChange(e.target.value)
									}}
									label="Packaging *"
									error={!!errors?.packageType}
									className={classes.shipmentItemFormControl}
								>
									{packageTypeOptions.map((option, index) => (
										<MenuItem value={option} key={`id-${index + 1}`}>
											{capitalizeFirstLetter(option)}
										</MenuItem>
									))}
								</Select>
							)}
							name={`items.${itemIndex}.packageType`}
							defaultValue={item.packageType}
							control={control}
							rules={{ required: { value: true, message: 'This value is required!' } }}
						/>
					</FormControl>
				</Grid>
				<Grid item xs={6} sm={6} lg={1} xl={1}>
					<Controller
						control={control}
						name={`items.${itemIndex}.quantity`}
						render={({ onChange, value, name }) => (
							<TextField
								name={name}
								className={classes.root}
								type="number"
								size="small"
								InputProps={{ inputProps: { min: 0 } }}
								error={!!errors?.quantity}
								FormHelperTextProps={{ classes: { root: classes.helperText } }}
								label="Quantity *"
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								value={value}
								onChange={onChange}
							/>
						)}
						defaultValue={item.quantity}
						rules={{
							required: { value: true, message: 'This value is required!' },
							pattern: /^(?:[1-9]|[1-9][0-9]|[1-8][0-9][0-9]|9[0-8][0-9]|99[0-9])$/,
						}}
					/>
				</Grid>
				<Grid item xs={6} sm={6} lg={2} xl={2}>
					<FormControl size="small" style={{ width: '100%' }} variant="outlined" error={!!errors?.freightClass}>
						<InputLabel className={classes.root}>Freight Class *</InputLabel>
						<Controller
							render={({ onChange, value, name }) => (
								<Select
									name={name}
									value={value}
									onChange={(e) => {
										onChange(e.target.value)
									}}
									label="Freight Class *"
									error={!!errors?.freightClass}
									className={classes.shipmentItemFormControl}
								>
									<MenuItem value="Not sure">Not sure</MenuItem>
									{freightClassOptions.map((option, index) => (
										<MenuItem value={option} key={`id-${index + 1}`}>
											{capitalizeFirstLetter(option)}
										</MenuItem>
									))}
								</Select>
							)}
							rules={{ required: { value: true, message: 'This value is required!' } }}
							name={`items.${itemIndex}.freightClass`}
							defaultValue={item.freightClass}
							control={control}
						/>
					</FormControl>
				</Grid>
				<Grid item xs={6} sm={6} lg={1} xl={1}>
					<Controller
						control={control}
						name={`items.${itemIndex}.nmfc`}
						defaultValue={item.nmfc ?? ''}
						render={({ name, value, onChange }) => (
							<TextField
								size="small"
								name={name}
								className={classes.root}
								error={!!errors?.nmfc}
								FormHelperTextProps={{ classes: { root: classes.helperText } }}
								helperText={errors?.nmfc?.message ?? ''}
								label="NMFC"
								variant="outlined"
								value={value}
								onChange={onChange}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={6} sm={6} lg={1} xl={1}>
					<Controller
						control={control}
						name={`items.${itemIndex}.weight`}
						defaultValue={item.weight}
						render={({ name, value, onChange }) => (
							<TextField
								size="small"
								name={name}
								type="number"
								className={classes.root}
								error={!!errors?.weight}
								helperText={errors?.weight?.message ?? ''}
								label={
									<Tooltip title="List the total weight for all items in this row. For example, if you have 2 pallets at 500lbs each, you should put 2 for Quantity and 1000 for Weight. Be sure to include the weight of the packaging!">
										<span>Weight (lbs) *</span>
									</Tooltip>
								}
								value={value}
								onChange={onChange}
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						)}
						rules={{
							required: { value: true, message: 'This value is required!' },
							min: {
								value: 0,
								message: 'Minimum 0',
							},
						}}
					/>
				</Grid>
				<Grid item xs={6} sm={6} lg={2} xl={2}>
					<DimensionsInput item={item} itemIndex={itemIndex} errors={errors} control={control} />
				</Grid>
				<Grid item xs={8} sm={6} lg={2} xl={2}>
					<Controller
						control={control}
						name={`items.${itemIndex}.commodity`}
						defaultValue={item.commodity}
						render={({ name, value, onChange }) => (
							<TextField
								name={name}
								className={classes.root}
								style={{ width: '100%' }}
								size="small"
								value={value}
								onChange={onChange}
								error={!!errors?.commodity}
								helperText={errors?.commodity?.message ?? ''}
								FormHelperTextProps={{ classes: { root: classes.helperText } }}
								label="Description of Goods *"
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						)}
						rules={{
							required: { value: true, message: 'This value is required!' },
							maxLength: { value: 100, message: 'Maximum 100 characters allowed' },
							minLength: 1,
						}}
					/>
				</Grid>
				<Grid item xs={4} lg={1} xl={1} style={{ paddingTop: 0 }}>
					<p className={classes.hazmatStackableLabel}>Hazmat/Stackable?</p>
					<div className={classes.shipmentItemsActionsContainer}>
						<Tooltip title="Hazmat such as chemicals, acid, waste with a UN number">
							<span>
								<Controller
									name={`items.${itemIndex}.hazardous`}
									control={control}
									defaultValue={item.hazardous}
									render={({ value, onChange }) => (
										<IconButton className={classes.actionButton} onClick={() => handleSetHazardous(onChange, value)}>
											<HazIcon
												className={classes.actionIcon}
												htmlColor={value ? theme.palette.secondary.main : theme.palette.neutral.darkGray}
											/>
										</IconButton>
									)}
								/>
							</span>
						</Tooltip>
						<Tooltip title="Items that can be stacked to reduce linear space">
							<span>
								<Controller
									name={`items.${itemIndex}.stackable`}
									control={control}
									defaultValue={item.stackable}
									render={({ value, onChange }) => (
										<IconButton className={classes.actionButton} onClick={() => onChange(!value)}>
											<StackIcon
												className={classes.actionIcon}
												htmlColor={value ? theme.palette.secondary.main : theme.palette.neutral.darkGray}
											/>
										</IconButton>
									)}
								/>
							</span>
						</Tooltip>
					</div>
				</Grid>
			</Grid>
			<Grid item container justifyContent="flex-end" className={classes.deleteButtonContainer}>
				<Grid item container justifyContent="flex-end" xs={12} md={2}>
					<Button className={classes.deleteButton} onClick={() => remove()}>
						<DeleteOutlineIcon color="error" />
						<span style={{ fontSize: '13px', marginRight: '2px' }}>Delete item</span>
					</Button>
				</Grid>
			</Grid>
			<Controller
				control={control}
				name={`items.${itemIndex}.hazmatData`}
				defaultValue={item.hazmatData}
				render={({ onChange, value }) => (
					<HazmatModal
						itemHazmat={value}
						isOpen={openHazmatModal && isWritableElem}
						setHazmatData={onChange}
						setOpenHazmatModal={setOpenHazmatModal}
					/>
				)}
			/>
		</>
	)
})

ShipmentItem.propTypes = {
	isWritableElem: PropTypes.bool.isRequired,
	item: PropTypes.objectOf(PropTypes.any),
	inputList: PropTypes.arrayOf(PropTypes.any),
	setInputList: PropTypes.func,
	setShipmentItems: PropTypes.func,
	dispatchSetShipmentItemsStore: PropTypes.func,
	deleteItem: PropTypes.func,
	itemIndex: PropTypes.number,
	deleteItemForm: PropTypes.func,

	shipmentItems: PropTypes.arrayOf(PropTypes.any).isRequired,
	control: PropTypes.func.isRequired,
	errors: PropTypes.objectOf(PropTypes.any).isRequired,
	watch: PropTypes.func.isRequired,
	remove: PropTypes.func.isRequired,
	trigger: PropTypes.func.isRequired,
}

ShipmentItem.defaultProps = {
	item: {},
	inputList: [],
	setInputList: undefined,
	setShipmentItems: undefined,
	dispatchSetShipmentItemsStore: undefined,
	deleteItem: () => {},
	itemIndex: 0,
	deleteItemForm: () => {},
}

export default ShipmentItem
