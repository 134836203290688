import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
	root: {
		'& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
			display: 'none',
		},
		backgroundColor: theme.palette.neutral.white,
	},
	containerButton: {
		justifyContent: 'flex-end',
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		padding: '20px',
		backgroundColor: theme.palette.neutral.white,
		marginTop: '16px',
		position: 'fixed',
		bottom: 0,
		left: 0,
		right: 0,
		zIndex: 1,
		[theme.breakpoints.up('md')]: {
			flexDirection: 'row-reverse',
			justifyContent: 'flex-start',
		},
	},
	nextStepButton: {
		width: '100%',
		marginTop: '10px',
		marginBottom: '10px',
		marginLeft: '0',
		height: '48px',
		fontSize: '17px',
		fontWeight: theme.typography.fontWeightMedium,
		color: theme.palette.neutral.white,
		backgroundColor: theme.palette.secondary.main,
		margin: theme.spacing(0, 0, 0),
		textTransform: 'none',
		[theme.breakpoints.up('md')]: {
			width: '180px',
			marginLeft: '1em',
		},
	},
	nextStepButtonInEdit: {
		width: '100%',
		marginTop: '10px',
		marginBottom: '10px',
		marginLeft: '0',
		height: '48px',
		fontSize: '17px',
		fontWeight: theme.typography.fontWeightMedium,
		color: theme.palette.neutral.black,
		backgroundColor: theme.palette.neutral.mainGray,
		margin: theme.spacing(0, 0, 0),
		textTransform: 'none',
		[theme.breakpoints.up('md')]: {
			width: '180px',
			marginLeft: '1em',
		},
	},
	cancelButton: {
		height: '48px',
		width: '100%',
		backgroundColor: theme.palette.neutral.whiteGray,
		textTransform: 'none',
		color: theme.palette.primary.main,
		[theme.breakpoints.up('md')]: {
			width: '161px',
		},
		[theme.breakpoints.up('lg')]: {
			color: theme.palette.neutral.black,
		},
	},
	newAccountInformationForm: {
		padding: '24px 0px 215px',
		[theme.breakpoints.up('md')]: {
			padding: '24px 0px 110px',
		},
	},
	newAccountInformationSectionTitle: {
		display: 'flex',
		paddingBottom: '16px',
		columnGap: '12px',
		marginBottom: '5px',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			padding: '0px 8px',
		},
	},
	newAccountContainerSubTitle: {
		display: 'flex',
		alignItems: 'center',
		columnGap: '.5em',
	},
	newAccountBasicInformationSection: {
		padding: '0px 8px 24px 16px',
		[theme.breakpoints.down('sm')]: {
			padding: '0px 0px 24px 16px',
		},
	},
	newAccountInformationDragnDrop: {
		padding: '5px 10px !important',
	},
	newAccountInformationSectionIconContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '28px',
		height: '28px',
		borderRadius: '20px',
		color: theme.palette.primary.main,
	},
	newAccountInformationSectionIcon: {
		width: '20px',
		height: '20px',
	},
	newAccountInformationSubtitleLabel: {
		fontSize: '18px',
		fontWeight: theme.typography.fontWeightRegular,
		color: theme.palette.primary.main,
		margin: 0,
		lineHeight: '22px',
		[theme.breakpoints.down('md')]: {
			fontSize: '15px',
		},
	},
	newAccountChildAccountsSection: {
		padding: '20px 16px 0px 16px',
		background: theme.palette.neutral.whiteGray,
		[theme.breakpoints.down('sm')]: {
			padding: '20px 8px 0px 16px',
		},
	},
	newChildAccountsForm: {
		padding: '0px 0px 0px 8px',
	},
	button: {
		height: '48px',
		fontSize: '14px',
		fontWeight: theme.typography.fontWeightMedium,
		color: '#141313',
		margin: theme.spacing(0, 0, 0),
		justifyContent: 'flex-end',
		textTransform: 'none',
		'&:hover': {},
		'@media only screen and (max-width: 768px)': {
			width: '140px',
		},
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginBottom: '10px',
			marginLeft: '0em',
		},
	},
	newChildAccountIcon: {
		color: theme.palette.secondary.main,
		fontSize: '25px',
	},
	headerActionButton: {
		textTransform: 'none',
		fontWeight: theme.typography.body2.fontWeight,
		fontSize: theme.typography.body2.fontSize,
		[theme.breakpoints.down('md')]: {
			marginRight: '1em',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '5px 1em 5px 0',
		},
	},
}))
