import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	accessorialCardContainer: {
		border: `1px solid ${theme.palette.secondary.light}`,
		borderRadius: '4px',
		padding: '15px',
		backgroundColor: '#FAFBFC',
	},
	accessorialCardActionButton: {
		textTransform: 'none',
		fontSize: '13px',
		fontWeight: theme.typography.fontWeightRegular,
		color: theme.palette.primary.main,
		display: 'flex',
		alignItems: 'center',
	},
	accessorialCardIcon: {
		fontSize: '25px',
	},
}))

export default useStyles
