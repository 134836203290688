/* eslint-disable prefer-destructuring */
import React from 'react'
import PropTypes from 'prop-types'
import Carousel from 'react-multi-carousel'
import { Grid, CircularProgress } from '@material-ui/core'
import NoResultsFound from '../../../../../../../components/NoResultsFound'
import destructuringName from '../../../../../../../utils/destructuringName'

import 'react-multi-carousel/lib/styles.css'
import useStyles from './styles'

const TableSlider = (props) => {
	const { childAccounts, isLoading } = props
	const classes = useStyles()

	const responsive = {
		tablet: {
			breakpoint: { max: 1024, min: 500 },
			items: 1,
			paritialVisibilityGutter: 50,
		},
		mobile: {
			breakpoint: { max: 500, min: 0 },
			items: 1,
			paritialVisibilityGutter: 30,
		},
	}

	return (
		<div className={classes.childAccountsSlider}>
			{isLoading && (
				<Grid container xs={12} justify="center">
					<CircularProgress />
				</Grid>
			)}
			{!isLoading && childAccounts.length > 0 && (
				<Carousel
					ssr
					arrows={false}
					responsive={responsive}
					showDots
					draggable
					renderDotsOutside
					dotListClass="shipment-items-slider-dots"
				>
					{childAccounts.map((item, index) => (
						<Grid container key={`id-${index + 1}`} className={classes.childAccountsSliderContainer}>
							<Grid item xs={12} md={6} className={classes.containerChildGroup}>
								<span className={classes.childAccountsSliderTitle}>First Name</span>
								<span>{item?.name ? destructuringName(item.name).name : ''}</span>
							</Grid>
							<Grid item xs={12} md={6} className={classes.containerChildGroup}>
								<span className={classes.childAccountsSliderTitle}>Last Name</span>
								<span>{item?.name ? destructuringName(item.name).lastName : ''}</span>
							</Grid>
							<Grid item xs={12} md={6} className={classes.containerChildGroup}>
								<span className={classes.childAccountsSliderTitle}>User ID</span>
								<span>{item?.id ?? ''}</span>
							</Grid>
							<Grid item xs={12} md={6} className={classes.containerChildGroup}>
								<span className={classes.childAccountsSliderTitle}>User Email</span>
								<span>{item?.email ?? ''}</span>
							</Grid>
							<Grid item xs={12} md={12} className={classes.containerChildGroup}>
								<span className={classes.childAccountsSliderTitle}>Profile</span>
								<span>{item?.role ?? ''}</span>
							</Grid>
						</Grid>
					))}
				</Carousel>
			)}
			{!isLoading && childAccounts.length === 0 && (
				<Grid container xs={12} justify="center">
					<NoResultsFound type="Users" />
				</Grid>
			)}
		</div>
	)
}

TableSlider.propTypes = {
	childAccounts: PropTypes.arrayOf(PropTypes.any).isRequired,
	isLoading: PropTypes.bool.isRequired,
}

export default TableSlider
