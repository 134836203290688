import React, { useState } from 'react'
import PropTypes from 'prop-types'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { TableRow, TableCell, Checkbox, Chip, IconButton } from '@material-ui/core'
import MenuList from '../MenuList'
import TooltipListUser from './components/ListNameCompaniesTooltip/index'
import truckImg from '../../../../../assets/img/truck.png'
import { removeUnderScore } from '../../../../../utils/helpers'
import useStyles from './styles'

const UsersListRow = (props) => {
	const classes = useStyles()
	const { isItemSelected, data, labelId, selected, setSelected, noActions, canActivate } = props
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)
	const handleOpenMenu = (event) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleClickCheckbox = (event, name) => {
		const selectedIndex = selected.indexOf(name)
		let newSelected = []

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name)
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1))
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1))
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
		}
		setSelected(newSelected)
	}

	return (
		<>
			<TableRow
				className={classes.tableRow}
				hover
				role="checkbox"
				aria-checked={isItemSelected}
				tabIndex={-1}
				key={data.id}
				selected={isItemSelected}
			>
				<TableCell padding="checkbox">
					{canActivate && (
						<Checkbox
							inputProps={{ 'aria-labelledby': labelId }}
							checked={isItemSelected}
							className={classes.tableCheckbox}
							onClick={(event) => handleClickCheckbox(event, data.id)}
							style={{
								color: isItemSelected ? '#007a8a' : '#818181',
							}}
						/>
					)}
				</TableCell>
				<TableCell className={classes.tableCell}>{data.name}</TableCell>
				<TableCell className={`${classes.tableCell} ${classes.capitalize}`}>{removeUnderScore(data.role)}</TableCell>
				<TableCell className={classes.tableCell}>
					<div className={classes.imgItem}>
						<img src={data.logo || truckImg} alt="logo" className={classes.carrierLogo} />
						<TooltipListUser data={data?.companies_names ? data.companies_names : ''}>
							<div>{data?.companies_names ? data.companies_names : ''}</div>
						</TooltipListUser>
					</div>
				</TableCell>
				<TableCell className={classes.tableCell}>{data.phone_number}</TableCell>
				<TableCell className={classes.tableCell}>{data.email}</TableCell>
				<TableCell className={classes.tableCell}>
					{data.is_active ? (
						<Chip size="small" className={classes.statusActive} label="Active" />
					) : (
						<Chip size="small" className={classes.statusInactive} label="Inactive" />
					)}
				</TableCell>
				{noActions && (
					<TableCell className={classes.tableCell}>
						<IconButton aria-label="toggle action menu" onClick={handleOpenMenu} onMouseDown={null}>
							<MoreVertIcon style={{ color: '#2C2C2C' }} />
						</IconButton>
					</TableCell>
				)}
			</TableRow>
			<MenuList
				anchorEl={anchorEl}
				open={open}
				handleClose={handleClose}
				userId={data.id}
				userStatus={data.is_active}
				emailStatus={data?.password_email_sent !== null ? data?.password_email_sent : true}
			/>
		</>
	)
}

UsersListRow.defaultProps = {
	noActions: false.isRequired,
	canActivate: false.isRequired,
}

UsersListRow.propTypes = {
	labelId: PropTypes.string.isRequired,
	isItemSelected: PropTypes.bool.isRequired,
	data: PropTypes.objectOf(PropTypes.any).isRequired,
	selected: PropTypes.instanceOf(Array).isRequired,
	setSelected: PropTypes.func.isRequired,
	noActions: PropTypes.bool,
	canActivate: PropTypes.bool,
}

export default UsersListRow
