import React, { useEffect, useMemo, useState } from 'react'
import { Button, Dialog, IconButton, TextField, DialogTitle } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import { Typography } from 'antd'
import { displayDollarValue } from '../../../../utils/helpers'
import useStyles from './styles'
import NumberFormatComponent from '../../../../components/NumberFormatComponent'

const ConfirmInsurance = ({
	applicableRate,
	defaultDeclaredValue,
	declaredFreightValue,
	withInsurance,
	setDeclaredFreightValue,
	maxReplacementValue,
	openConfirmInsurance,
	setOpenConfirmInsurance,
	enableInsuranceOptIn,
	onNextStepThree,
	customerRateWithInsurance,
}) => {
	// The business communicated this is the min and max declared freight value we should allow
	const minReplacementValue = 500
	const globalMaxReplacementValue = 100000

	const classes = useStyles()
	const [declaredValue, setDeclaredValue] = useState(declaredFreightValue)
	const [userConfirmedInsurance, setUserConfirmedInsurance] = useState(false)
	const closeDialogHandler = () => setOpenConfirmInsurance(false)

	const declaredValueError = useMemo(() => {
		if (Number.isNaN(declaredValue)) {
			return 'The declared value is not a number'
		}
		if (withInsurance && declaredValue < minReplacementValue) {
			return `The declared value is smaller than the minimum amount allowed for your account: ${displayDollarValue(minReplacementValue)}`
		}
		if (withInsurance && maxReplacementValue && declaredValue > maxReplacementValue) {
			return `The declared value is greater than the maximum amount allowed for your account: ${displayDollarValue(maxReplacementValue)}`
		}
		if (withInsurance && globalMaxReplacementValue && declaredValue > globalMaxReplacementValue) {
			return `The declared value is greater than the maximum amount allowed: ${displayDollarValue(globalMaxReplacementValue)}`
		}

		return ''
	}, [declaredValue, maxReplacementValue, withInsurance])

	useEffect(() => {
		setDeclaredFreightValue(Number(declaredValue.toFixed(2)))
	}, [declaredValueError, declaredValue, setDeclaredFreightValue])

	useEffect(() => {
		if (openConfirmInsurance && !withInsurance) {
			// Because no insurance was selected we need to set declared value
			// To zero so the insurance payload is constructed properly
			setDeclaredValue(0)
		}

		if (openConfirmInsurance && withInsurance) {
			// Reset the default declared value since insurance was selected
			setDeclaredValue(defaultDeclaredValue)
		}

		if (!openConfirmInsurance && !userConfirmedInsurance) {
			// Reset the default declared value
			// This ensures the prices with insurance in the TableRates component
			// Are displayed as we'd prefer: with $5000 worth of declared value
			// Just in case the user changes the declared value then cancels and goes back to that view
			setDeclaredValue(defaultDeclaredValue)
		}
	}, [openConfirmInsurance, withInsurance, defaultDeclaredValue, setDeclaredValue, userConfirmedInsurance])

	const continueWithInsuranceHandler = (e) => {
		e.preventDefault()
		if (declaredValueError) return
		closeDialogHandler()
		onNextStepThree(null, true)
		setUserConfirmedInsurance(true)
	}

	const continueWithoutInsuranceHandler = (e) => {
		if (e) e.preventDefault()
		closeDialogHandler()
		onNextStepThree(null, true)
		setUserConfirmedInsurance(true)
	}

	useEffect(() => {
		if (openConfirmInsurance && applicableRate && !withInsurance && !enableInsuranceOptIn) {
			continueWithoutInsuranceHandler()
		}
	}, [withInsurance, enableInsuranceOptIn, openConfirmInsurance, applicableRate])

	return (
		<Dialog
			open={openConfirmInsurance && applicableRate}
			onClose={closeDialogHandler}
			onClick={(event) => event.stopPropagation()}
			aria-labelledby="confirm-insurance-dialog-title"
			PaperProps={{
				className: classes.paperDialog,
			}}
		>
			<DialogTitle disableTypography className={classes.dialogTitle}>
				<IconButton aria-label="close" size="small" onClick={closeDialogHandler}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			{withInsurance && (
				<form className={classes.formContainer} onSubmit={continueWithInsuranceHandler}>
					<Typography className={classes.currentDeclaredValue}>
						This rate includes a{` ${displayDollarValue(declaredValue)} `}
						declared value of insurance.
					</Typography>
					<div className={classes.declaredInputGroup}>
						<TextField
							className={classes.declaredValueInput}
							name="declaredValue"
							defaultValue={declaredValue}
							onChange={(event) => {
								setDeclaredValue(Number(event.target.value))
							}}
							label="Change Declared Value"
							variant="outlined"
							inputMode="numeric"
							error={!!declaredValueError}
							InputProps={{
								inputComponent: NumberFormatComponent,
								endAdornment: 'USD',
								autoComplete: 'off',
							}}
						/>
						<Typography className={classes.declaredValueError}>{declaredValueError}</Typography>
					</div>
					<div>
						<Typography className={classes.totalCustomerInsuranceCost}>
							New Total Rate:
							{displayDollarValue(customerRateWithInsurance)}
						</Typography>
					</div>
					<div>
						<Button
							type="submit"
							color="secondary"
							variant="contained"
							disabled={!!declaredValueError}
							className={classes.continueWithInsuranceButton}
						>
							Continue
						</Button>
					</div>
				</form>
			)}
			{!withInsurance && enableInsuranceOptIn && (
				<form className={classes.formContainer} onSubmit={continueWithoutInsuranceHandler}>
					<p id="confirm-insurance-dialog-title">Are you sure you want to remove insurance?</p>
					<div>
						<Button
							type="submit"
							color="secondary"
							variant="contained"
							className={classes.continueWithoutInsuranceButton}
						>
							Yes
						</Button>
						<Button onClick={closeDialogHandler} color="secondary" variant="contained">
							No
						</Button>
					</div>
				</form>
			)}
		</Dialog>
	)
}

ConfirmInsurance.defaultProps = {
	maxReplacementValue: null,
}

ConfirmInsurance.propTypes = {
	applicableRate: PropTypes.bool.isRequired,
	defaultDeclaredValue: PropTypes.number.isRequired,
	declaredFreightValue: PropTypes.number.isRequired,
	setDeclaredFreightValue: PropTypes.func.isRequired,
	withInsurance: PropTypes.bool.isRequired,
	maxReplacementValue: PropTypes.number,
	openConfirmInsurance: PropTypes.bool.isRequired,
	setOpenConfirmInsurance: PropTypes.func.isRequired,
	enableInsuranceOptIn: PropTypes.bool.isRequired,
	onNextStepThree: PropTypes.func.isRequired,
	customerRateWithInsurance: PropTypes.number.isRequired,
}

export default ConfirmInsurance
