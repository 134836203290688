import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	carrierCardContainer: {
		border: '1px solid #c9cfe4',
		borderRadius: '4px',
		padding: '16px 16px 8px 16px',
		backgroundColor: theme.palette.neutral.white,
	},
	logoContainer: {
		display: 'flex',
		marginRight: '8px',
		'& img': {
			width: '40px',
			height: '40px',
			objectFit: 'contain',
		},
	},
	logoName: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'center',
		'& span': {
			marginBottom: '0.1em',
			fontWeight: 600,
			fontSize: '14px',
			marginLeft: 0,
		},
		'& span.add': {
			color: '#EC3514',
			textDecoration: 'underline',
			marginBottom: '1.5em',
		},
	},
	itemsContainer: {
		marginTop: '16px',
		'& > :last-child': {
			marginBottom: '0px',
		},
	},
	itemContainer: {
		display: 'flex',
		flexDirection: 'column',
		marginBottom: '14px',
		fontSize: '14px',
		fontWeight: theme.typography.fontWeightMedium,
	},
	actionsContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		width: '100%',
		'& .MuiIconButton-root': {
			padding: '8px',
		},
		'& > :last-child': {
			paddingRight: '0px',
		},
	},
	actionTitle: {
		fontWeight: theme.typography.fontWeightRegular,
		fontSize: '12px',
		color: theme.palette.primary.main,
		margin: '0px 0px 0px 5px',
	},
	itemContainerMiddle: {
		display: 'flex',
		flexDirection: 'column',
		marginBottom: 16,
		fontSize: '14px',
		marginTop: '50px',
	},
	itemTitle: {
		fontFamily: 'Open Sans',
		fontWeight: 700,
		fontSize: '12px',
		color: '#2C2C2C',
		marginBottom: '6px',
		'@media (min-width: 768px)': {
			marginBottom: '8px',
		},
	},
	tableCheckbox: {
		padding: '8px 16px 8px 0px',
	},
}))

export default useStyles
