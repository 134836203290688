import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import Button from '@material-ui/core/Button'
import { Grid, IconButton, FormControl, Select, InputLabel, MenuItem } from '@material-ui/core'
import { FilterList, Loop } from '@material-ui/icons'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { filterByStatusOptions } from '../../../../utils/dropDownOptions'

import { usersListRoute } from '../../../../utils/constants'
import {
	setCurrentPage,
	setRowsPerPage,
	setSearchBar,
	resetFilters,
	setFilterByStatus,
} from '../../../../redux/actions/UsersFilters.actions'
import { setTabCurrentRoute, setTabTitle } from '../../../../redux/actions/MultiTabs.actions'
import useUsersFilters from '../../../../hooks/useUsersFilters'
import { fetchUsers } from '../../../../redux/actions/Users.actions'
import sweetAlertModal from '../../../../components/SweetAlertModal/SweetAlertModal'
import SearchBar from '../../../../components/SearchBar'

import useStyles from './styles'
import TableList from '../components/TableList/TableList'

const UsersList = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const [buildParamsFromState] = useUsersFilters()
	const [open, setOpen] = useState(false)
	const filterBy = useSelector((state) => state.usersFilters.setFilterByStatus)
	const [filterByStatusValue, setFilterByStatusValue] = useState(filterBy)
	const { searchBar } = useSelector((state) => state.usersFilters)
	const [search, setSearch] = useState(decodeURIComponent(searchBar))

	const [pageReset, setPageReset] = useState('')
	const matches = useMediaQuery('(min-width:1024px)')

	const MyUsersProvider = () => {
		const callback = useCallback(async (params) => {
			const { status } = await dispatch(fetchUsers(params))
			if (status === 'error') sweetAlertModal('error', 'There was an error fetching the users', null, 'Ok', true, false)
		}, [])

		return { callback }
	}

	const { callback } = MyUsersProvider()
	const filterParams = buildParamsFromState()

	useEffect(() => {
		dispatch(setTabCurrentRoute(usersListRoute))
		dispatch(setTabTitle('Users List'))
	}, [dispatch])

	useEffect(() => {
		callback(filterParams)
	}, [callback, filterParams])

	useEffect(() => {
		const preservedFilters = localStorage.getItem('preserved-users-filters')
		if (preservedFilters) {
			const filterObj = preservedFilters.split('&')
			filterObj.forEach((filter) => {
				const filterKeyValue = filter.split('=')
				switch (filterKeyValue[0]) {
					case 'active':
						dispatch(setFilterByStatus(filterKeyValue[1]))
						setFilterByStatusValue(filterKeyValue[1])
						break
					case 'search_bar':
						dispatch(setSearchBar(filterKeyValue[1]))
						setSearch(decodeURIComponent(filterKeyValue[1]))
						break
					case 'page':
						dispatch(setCurrentPage(filterKeyValue[0].split(/[/?]/)[1]))
						dispatch(setRowsPerPage(filterKeyValue[0].split(/[/?]/)[2]))
						break
					default:
						break
				}
			})
		}
	}, [dispatch])

	const toggleDrawer = (openDrawer) => (event) => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return
		}
		setOpen(openDrawer)
	}

	const showFilters = () => {
		setOpen(true)
	}

	const handleSearchBarChanged = (event) => {
		setSearch(event.target.value)
		if (event.target.value.length >= 3) {
			dispatch(setCurrentPage(0))
			dispatch(setSearchBar(encodeURIComponent(event.target.value)))
			setPageReset(event.target.value)
		} else if (event.target.value.length === 0) {
			dispatch(setCurrentPage(0))
			dispatch(setSearchBar(''))
			setPageReset(event.target.value)
		}
	}

	const onKeyDown = (event) => {
		if (event.keyCode === 13) {
			if (event.target.value) {
				dispatch(setSearchBar(encodeURIComponent(event.target.value)))
				setSearch(event.target.value)
				setPageReset('resetPageSearch')
				dispatch(setCurrentPage(0))
			}
		} else if (!event.target.value) {
			dispatch(setSearchBar(''))
		}
	}

	const handleChangeStatus = (e) => {
		dispatch(setFilterByStatus(e.target.value))
		dispatch(setCurrentPage(0))
		setFilterByStatusValue(e.target.value)
		setPageReset(e.target.value)
	}

	const handleResetFilters = () => {
		dispatch(resetFilters())
		dispatch(setCurrentPage(0))
		setSearch('')
		setPageReset('pageResetFilters')
		setFilterByStatusValue('')
		localStorage.removeItem('preserved-item-filters')
	}

	const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

	return (
		<>
			<div className={classes.invoiceContainer}>
				<div className={classes.headerSection}>
					<div className={classes.headerTitle}>
						<h1 className={classes.titleText}>Users</h1>
						<div>
							<IconButton aria-label="Toggle Filters" onClick={showFilters} className={classes.filterTriggerButton}>
								<FilterList color="secondary" />
							</IconButton>
						</div>
					</div>
					<p className={classes.subtitleText}>Consult the users created in the system.</p>
					<SwipeableDrawer
						className={classes.drawer}
						anchor="top"
						open={open}
						onOpen={toggleDrawer(open, true)}
						onClose={toggleDrawer(false)}
						disableBackdropTransition={!iOS}
						disableDiscovery={iOS}
					>
						<div className={classes.filterFormWrapper} role="presentation">
							<Grid container spacing={2} alignItems="center" style={{ padding: '15px 8px 0 10px' }}>
								<Grid item xs={12} md={3}>
									<FormControl variant="outlined" style={{ width: '100%', marginBottom: '0' }}>
										<InputLabel className={classes.selectChildInput} htmlFor="company-dialog-select">
											Filter by status
										</InputLabel>
										<Select value={filterByStatusValue} label="filterByStatus" onChange={handleChangeStatus}>
											{filterByStatusOptions.map((item, index) => (
												<MenuItem value={item.value} key={`id-${index + 1}`}>
													{item.label}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} md={12}>
									<SearchBar value={search} autoFocus placeholder="Search..." onKeyDown={onKeyDown} />
								</Grid>
								<Grid item xs={12} style={{ padding: '20px 0 0 0' }}>
									<Button
										onClick={handleResetFilters}
										color="primary"
										startIcon={<Loop color="secondary" />}
										style={{ textTransform: 'none' }}
										className={classes.filterButtonModal}
									>
										<span className={classes.filterButtonText}>Reset Filters</span>
									</Button>
								</Grid>
							</Grid>
						</div>
					</SwipeableDrawer>
					<Grid container spacing={2} alignItems="center">
						<Grid item xs={12} md={12} lg={7}>
							<SearchBar value={search} placeholder="Search..." onChangeHandler={handleSearchBarChanged} size="small" />
						</Grid>
						{matches && (
							<>
								<Grid item xs={12} md={3}>
									<FormControl size="small" variant="outlined" style={{ width: '100%', marginBottom: '0' }}>
										<InputLabel className={classes.selectChildInput} htmlFor="company-dialog-select">
											Filter by status
										</InputLabel>
										<Select value={filterByStatusValue} label="filterByStatus" onChange={handleChangeStatus}>
											{filterByStatusOptions.map((item, index) => (
												<MenuItem value={item.value} key={`id-${index + 1}`}>
													{item.label}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
							</>
						)}
						{matches && (
							<Grid item xs={2} md={2}>
								<Button
									onClick={handleResetFilters}
									color="primary"
									startIcon={<Loop color="secondary" />}
									className={classes.filterButton}
								>
									<span className={classes.filterButtonText}>Reset filters</span>
								</Button>
							</Grid>
						)}
					</Grid>
				</div>
				<TableList pageReset={pageReset} />
			</div>
		</>
	)
}

export default UsersList
