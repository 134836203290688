/* eslint-disable max-len */
import React, { useState, useLayoutEffect, useMemo, useCallback } from 'react'
import propTypes from 'prop-types'
import i18n from 'i18next'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import { OktaAuth } from '@okta/okta-auth-js'
import { oktaAuthConfig } from '../../hooks/useOktaConfig'
import { AUTH0_SESSION_STRING } from '../../utils/constants'

export const SessionContext = React.createContext({
	authenticated: false,
	deleteSession: () => {},
	createSession: () => {},
	getSession: () => {},
	saveUserData: () => {},
	getUserData: () => {},
	saveActualCompanyId: () => {},
	getActualCompanyId: () => {},
	getTokenUser: () => {},
	cleanStorage: () => {},
	getExpirationTime: () => {},
	getCurrentLng: () => {},
	saveUserLng: () => {},
	postLoginFinished: false,
	updatePostLoginFinished: () => {},
	oktaAuth: () => {},
})
const SessionProvider = ({ children }) => {
	const [authenticated, setAuthenticated] = useState(false)
	const [postLoginFinished, setPostLoginFinished] = useState(false)
	const oktaAuth = useMemo(() => new OktaAuth(oktaAuthConfig), [])

	const cleanStorage = useCallback(() => {
		localStorage.removeItem('freightpros-user-session')
		localStorage.removeItem('freightpros-user-data')
		localStorage.removeItem('freightpros-user-companyId')
		localStorage.removeItem(AUTH0_SESSION_STRING)
	}, [])

	const deleteSession = useCallback(() => {
		cleanStorage()
		setAuthenticated(false)
	}, [cleanStorage, setAuthenticated])

	const isExpired = useCallback(
		(expiresAtInSeconds) => new Date(Number(expiresAtInSeconds) * 1000).getTime() - Date.now() <= 0,
		[],
	)

	const createSession = useCallback(
		(type) => {
			if (!postLoginFinished) {
				switch (type) {
					case 'Auth0': {
						const auth0LocalStorage = localStorage.getItem(AUTH0_SESSION_STRING)
						if (auth0LocalStorage !== null) {
							const { body } = JSON.parse(auth0LocalStorage)
							const jwt = jwt_decode(body.access_token)
							const expiresAt = jwt.exp
							// console.log('jwtExpr', expiresAt);
							const session = {
								tokenUser: body.access_token,
								expirationTime: body.expires_in,
								expiresAt,
							}
							if (isExpired(expiresAt)) {
								console.log('Auth0 Session expired!')
								deleteSession()
							} else {
								console.log('creating Auth0 session')
								localStorage.setItem('freightpros-user-session', JSON.stringify(session))
								setAuthenticated(true)
							}
						}
						break
					}
					case 'Okta': {
						console.log('okta debug')
						const storedToken = oktaAuth.tokenManager.getTokensSync()
						// console.log('stored token', storedToken);
						if (storedToken.accessToken !== undefined && !oktaAuth.tokenManager.hasExpired(storedToken.accessToken)) {
							const { accessToken } = storedToken
							console.log('creating Okta session')
							const session = {
								tokenUser: accessToken.accessToken,
								expiresAt: accessToken.expiresAt,
							}
							localStorage.setItem('freightpros-user-session', JSON.stringify(session))
							console.log('setting okta session')
							setAuthenticated(true)
						} else {
							console.log('Okta Session expired!')
							deleteSession()
						}
						break
					}
					default: {
						console.error('Invalid Session')
						break
					}
				}
			}
		},
		[deleteSession, isExpired, postLoginFinished, oktaAuth.tokenManager],
	)

	const updatePostLoginFinished = () => {
		setPostLoginFinished(true)
	}

	const getSession = useCallback(() => {
		if (localStorage.getItem(AUTH0_SESSION_STRING) !== null) {
			createSession('Auth0')
		}
		const userSession = JSON.parse(localStorage.getItem('freightpros-user-session'))
		return userSession
	}, [createSession])

	const getTokenUser = (isAuth0) => {
		let token
		if (isAuth0 || localStorage.getItem(AUTH0_SESSION_STRING) !== null) {
			const session = getSession()
			if (!session) {
				return null
			}
			// console.log('session', session);
			const { tokenUser } = session
			token = tokenUser
		} else {
			const { accessToken } = oktaAuth.tokenManager.getTokensSync()
			if (accessToken && accessToken.accessToken && !oktaAuth.tokenManager.hasExpired(accessToken)) {
				token = accessToken.accessToken
			}
		}
		return token
	}

	const getExpirationTime = () => {
		let expirationTime
		const { accessToken } = oktaAuth.tokenManager.getTokensSync()
		if (accessToken && accessToken.accessToken) {
			expirationTime = accessToken.expiresAt
		} else {
			const { expiresAt } = getSession()
			expirationTime = expiresAt
		}
		return expirationTime
	}

	const saveUserData = (data) => {
		const userData = {
			permissions: data.permissions,
			actualCompany: data.principalCompanyId,
			principalCompany: data.principalCompanyId,
			isChange: false,
		}
		localStorage.setItem('freightpros-user-data', JSON.stringify(userData))
	}

	const getUserData = () => {
		const userData = JSON.parse(localStorage.getItem('freightpros-user-data'))
		return userData
	}

	const saveActualCompanyId = (companyId, change) => {
		const userData = JSON.parse(localStorage.getItem('freightpros-user-data'))
		userData.actualCompany = +companyId
		userData.isChange = change
		localStorage.setItem('freightpros-user-data', JSON.stringify(userData))
	}

	const getCurrentLng = () => {
		if (!localStorage.getItem('i18nextLng')) {
			localStorage.setItem('i18nextLng', i18n.language)
		}
		return localStorage.getItem('i18nextLng')
	}

	const saveUserLng = (value) => {
		localStorage.setItem('i18nextLng', value)
	}

	useLayoutEffect(() => {
		const userSession = getSession()
		const storedToken = oktaAuth.tokenManager.getTokensSync()
		// console.log('userSession', userSession);
		// console.log('storedToken', storedToken);
		const auth0LocalStorage = localStorage.getItem(AUTH0_SESSION_STRING)
		// console.log('AUTH0_SESSION_STRING', AUTH0_SESSION_STRING);
		if (
			!auth0LocalStorage &&
			window.location.pathname !== process.env.REACT_APP_AUTH_LOGIN_PATH &&
			storedToken.accessToken !== undefined &&
			!oktaAuth.tokenManager.hasExpired(storedToken.accessToken)
		) {
			if (!userSession) {
				console.log('Valid Okta token without session detected, creating Okta session..')
				createSession('Okta')
			} else {
				setAuthenticated(true)
			}
			console.log('setAuthenticated okta', true)
			console.log('postLoginFinished okta', postLoginFinished)
		} else if (userSession !== null && !isExpired(userSession.expiresAt)) {
			setAuthenticated(true)
			console.log('setAuthenticated auth0', true)
			console.log('postLoginFinished auth0', postLoginFinished)
		} else {
			deleteSession()
		}
	}, [authenticated, deleteSession, createSession, getSession, oktaAuth, postLoginFinished, isExpired])

	return (
		<SessionContext.Provider
			value={{
				authenticated,
				createSession,
				deleteSession,
				getSession,
				saveUserData,
				getUserData,
				getTokenUser,
				cleanStorage,
				getExpirationTime,
				saveActualCompanyId,
				getCurrentLng,
				saveUserLng,
				postLoginFinished,
				updatePostLoginFinished,
				oktaAuth,
			}}
		>
			{children}
		</SessionContext.Provider>
	)
}

SessionProvider.defaultProps = {
	children: propTypes.node,
}

SessionProvider.propTypes = {
	children: propTypes.node,
}

export default SessionProvider
