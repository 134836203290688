import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
	newAccountStepperContainer: {
		margin: '-16px -24px',
	},
	newAccountStepperTitleContainer: {
		padding: '16px 24px',
		background: theme.palette.neutral.whiteGray,
	},
	newAccountStepperLabel: {
		color: theme.palette.neutral.black,
		margin: '0 0 6px 0',
		fontSize: '2.5rem',
	},
	newAccountStepperBody: {
		display: 'block',
		marginBottom: '16px',
		fontSize: '1rem',
		fontWeight: theme.typography.fontWeightRegular,
	},
	newAccountStepperTitle: {
		display: 'flex',
		flexDirection: 'row',
	},
	newAccountStepperChip: {
		backgroundColor: theme.palette.success.light,
		color: theme.palette.neutral.black,
		fontSize: '11px',
		height: '20px',
		alignSelf: 'center',
		marginLeft: '1%',
	},
}))
