import React, { useState } from 'react'
import PropTypes from 'prop-types'

import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import { useHistory } from 'react-router-dom'
import { Tooltip } from '@material-ui/core'
import { OriginPointIcon, DestinationPointIcon } from '../../../../../assets/Icons/Icons'

import { capitalizeFirstLetter } from '../../../../../utils/helpers'
import { updateLocationRoute } from '../../../../../utils/constants'

import MenuList from '../MenuList'

import useStyles from './style'

const LocationTableRow = (props) => {
	const classes = useStyles()
	const history = useHistory()
	const { data } = props
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)

	const handleOpenMenu = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleEditLocation = () => {
		const splittedRoute = updateLocationRoute.split('/')
		history.push({
			pathname: `/${splittedRoute[1]}/${data.id}`,
		})
	}

	return (
		<>
			<TableRow className={classes.tableRow} tabIndex={-1} key={data.id}>
				<TableCell className={`${classes.tableCell}, ${classes.tableCellCenter}`}>
					{data.type === 'CONSIGNEE' && (
						<Tooltip title="Default Destination Location">
							<span>
								<DestinationPointIcon />
							</span>
						</Tooltip>
					)}
					{data.type === 'SHIPPER' && (
						<Tooltip title="Default Origin Location">
							<span>
								<OriginPointIcon />
							</span>
						</Tooltip>
					)}
				</TableCell>
				<TableCell className={classes.tableCell}>{data.name}</TableCell>
				<TableCell
					className={classes.tableCell}
				>{`${data.address}, ${data.city}, ${data.state}, ${data.zip_code}`}</TableCell>
				<TableCell className={classes.tableCell}>
					{`${data.contact || ''}${data.phone ? `, ${data.phone}` : ''}${data.email ? `, ${data.email}` : ''}`}
				</TableCell>
				<TableCell className={classes.tableCell}>
					{data.accesorials.length > 0 && data.accesorials[0] !== null ? (
						data.accesorials.map((accesorial, index) => (
							<p key={`id-${index + 1}`} style={{ margin: 0 }}>
								{` ${capitalizeFirstLetter(accesorial)}${data.accesorials.length === index + 1 ? '' : ','} `}
							</p>
						))
					) : (
						<p>-</p>
					)}
				</TableCell>
				<TableCell className={classes.tableCellLocationID}>{data.location_id ? data.location_id : '-'}</TableCell>
				<TableCell className={classes.tableCell}>
					<IconButton aria-label="toggle action menu" onClick={handleOpenMenu} onMouseDown={null}>
						<MoreVertIcon style={{ color: '#2C2C2C' }} />
					</IconButton>
				</TableCell>
			</TableRow>
			<MenuList
				anchorEl={anchorEl}
				open={open}
				handleClose={handleClose}
				handleEditLocation={handleEditLocation}
				locationId={data.id}
				locationType={data.type}
			/>
		</>
	)
}

LocationTableRow.propTypes = {
	data: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default LocationTableRow
