import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, Grid } from '@material-ui/core'
import DocumentType from './DocumentType'
import sweetAlertModal from '../../../../components/SweetAlertModal'

import useStyles from './styles'

const UploadDocumentsModal = ({ isOpen, setIsOpen, documents, allDocuments, setAllDocuments }) => {
	const classes = useStyles()
	const [shipmentDocuments, setShipmentDocuments] = useState([])

	const handleUploadDocuments = () => {
		const copyDocuments = allDocuments
		if (shipmentDocuments.length === 0) {
			sweetAlertModal('error', 'Please enter a document type', null, 'Ok', true, false, null)
			return false
		}
		const docExists =
			allDocuments?.find(
				(element) => element.name === shipmentDocuments[0].name && element.type === shipmentDocuments[0].type,
			) ?? {}
		if (Object.entries(docExists).length > 0) {
			sweetAlertModal('error', 'Another document exists with the same name and type', null, 'Ok', true, false, null)
			return false
		}
		const newDocuments = copyDocuments.concat(...shipmentDocuments)
		setAllDocuments(newDocuments)
		setShipmentDocuments([])
		setIsOpen(false)
		return true
	}

	const handleCancelDocument = () => {
		setIsOpen(false)
	}

	return (
		<Dialog
			disableBackdropClick
			disableEscapeKeyDown
			open={isOpen}
			onClose={handleCancelDocument}
			onClick={(event) => event.stopPropagation()}
			aria-labelledby="form-dialog-title"
			PaperProps={{
				className: classes.paperDialog,
			}}
		>
			<div>
				<h2 className={classes.modalTitle}>Upload Documents</h2>
				<p className={classes.title}>Select the Document Type for your files:</p>
				<Grid container spacing={1} style={{ maxWidth: '100%' }}>
					<Grid item lg={6} xl={6} sm={6} md={6} xs={6}>
						<p className={classes.itemIitle}>Document Name</p>
					</Grid>
					<Grid item lg={6} xl={6} sm={6} md={6} xs={6}>
						<p className={classes.itemIitle}>Document Type</p>
					</Grid>
					{documents
						? documents.map((document, documentIndex) => (
								<DocumentType
									key={`id-${documentIndex + 1}`}
									document={document}
									allDocuments={shipmentDocuments}
									setAllDocuments={setShipmentDocuments}
								/>
							))
						: null}
				</Grid>
				<Grid item style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '2em' }}>
					<Button onClick={handleCancelDocument} className={classes.cancelButton} variant="contained">
						Cancel
					</Button>
					<Button
						onClick={handleUploadDocuments}
						className={classes.uploadButton}
						type="submit"
						color="secondary"
						variant="contained"
					>
						Upload Documents
					</Button>
				</Grid>
			</div>
		</Dialog>
	)
}

UploadDocumentsModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	documents: PropTypes.arrayOf(PropTypes.any).isRequired,
	allDocuments: PropTypes.arrayOf(PropTypes.any).isRequired,
	setAllDocuments: PropTypes.func.isRequired,
}

export default UploadDocumentsModal
