import React from 'react'
import PropTypes from 'prop-types'

import { Checkbox, FormControlLabel } from '@material-ui/core'

import checkEmail, { formatPhoneNumber } from '../../utils/helpers'

import useStyles from './styles'

const NotificationSettingsCard = (props) => {
	const {
		firstName,
		lastName,
		phoneNumber,
		email,
		sendBolByEmail,
		setSendBolByEmail,
		sendByEmail,
		setSendByEmail,
		sendBySms,
		setSendBySms,
		sendWhenBooked,
		setSendWhenBooked,
		sendWhenInTransit,
		setSendWhenInTransit,
		sendWhenDelivered,
		setSendWhenDelivered,
	} = props
	const classes = useStyles()

	return (
		<div className={classes.notificationsCardContainer}>
			<div className={classes.card}>
				<h3 className={classes.title}>Contact Information</h3>
				<p className={classes.info}>
					<span>Contact Name: </span>
					{firstName && lastName ? `${firstName} ${lastName}` : '-'}
				</p>
				<p className={classes.info}>
					<span>Email Address: </span>
					{checkEmail(email)}
				</p>
				<p className={classes.info}>
					<span>Cell Phone: </span>
					{formatPhoneNumber(phoneNumber)}
				</p>
				<hr className={classes.hr} />
				<FormControlLabel
					className={classes.checkBoxLabel}
					control={
						<Checkbox
							color="secondary"
							className={classes.checkBox}
							checked={sendBolByEmail}
							onChange={(event) => setSendBolByEmail(event.target.checked)}
						/>
					}
					label="Send BOL by Email"
				/>
				<hr className={classes.hr} />
				<h3 className={classes.title}>Method</h3>
				<div className={classes.row}>
					<FormControlLabel
						className={classes.checkBoxLabel}
						control={
							<Checkbox
								color="secondary"
								className={classes.checkBox}
								checked={sendByEmail}
								onChange={(event) => setSendByEmail(event.target.checked)}
							/>
						}
						label="Email"
					/>
					<FormControlLabel
						className={classes.checkBoxLabel}
						control={
							<Checkbox
								color="secondary"
								className={classes.checkBox}
								checked={sendBySms}
								onChange={(event) => setSendBySms(event.target.checked)}
							/>
						}
						label="SMS"
					/>
				</div>
				<hr className={classes.hr} />
				<h3 className={classes.title}>Tracking Notifications</h3>
				<div className={classes.row}>
					<FormControlLabel
						className={classes.checkBoxLabel}
						control={
							<Checkbox
								color="secondary"
								className={classes.checkBox}
								checked={sendWhenBooked}
								onChange={(event) => setSendWhenBooked(event.target.checked)}
							/>
						}
						label="Booked"
					/>
					<FormControlLabel
						className={classes.checkBoxLabel}
						control={
							<Checkbox
								color="secondary"
								className={classes.checkBox}
								checked={sendWhenInTransit}
								onChange={(event) => setSendWhenInTransit(event.target.checked)}
							/>
						}
						label="In Transit"
					/>
				</div>
				<FormControlLabel
					className={classes.checkBoxLabel}
					control={
						<Checkbox
							name="delivered"
							color="secondary"
							className={classes.checkBox}
							style={{ padding: '10px 7px 10px 9px' }}
							checked={sendWhenDelivered}
							onChange={(event) => setSendWhenDelivered(event.target.checked)}
						/>
					}
					label="Delivered"
				/>
			</div>
		</div>
	)
}

NotificationSettingsCard.propTypes = {
	firstName: PropTypes.string.isRequired,
	lastName: PropTypes.string.isRequired,
	phoneNumber: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
	sendBolByEmail: PropTypes.bool.isRequired,
	setSendBolByEmail: PropTypes.func.isRequired,
	sendByEmail: PropTypes.bool.isRequired,
	setSendByEmail: PropTypes.func.isRequired,
	sendBySms: PropTypes.bool.isRequired,
	setSendBySms: PropTypes.func.isRequired,
	sendWhenBooked: PropTypes.bool.isRequired,
	setSendWhenBooked: PropTypes.func.isRequired,
	sendWhenInTransit: PropTypes.bool.isRequired,
	setSendWhenInTransit: PropTypes.func.isRequired,
	sendWhenDelivered: PropTypes.bool.isRequired,
	setSendWhenDelivered: PropTypes.func.isRequired,
}

export default NotificationSettingsCard
