import type { CacheLocation } from '@auth0/auth0-react'

export const dashboardRoute = '/'
export const quotesListRoute = '/quotes-list'
export const createQuotesRoute = '/create-quote'
export const shipmentsListRoute = '/shipments-list'
export const createShipmentsRoute = '/create-shipment'
export const shipmentDetails = '/shipment-details/:id'
export const createUserRoute = '/create-user'
export const updateUserRoute = '/update-user/:id'
export const usersListRoute = '/users-list'
export const createLocationRoute = '/create-location'
export const updateLocationRoute = '/update-location/:id'
export const locationsListRoute = '/locations-list'
export const createItemRoute = '/create-item'
export const updateItemRoute = '/update-item/:itemTemplateId'
export const itemsListRoute = '/items-list'
export const invoicesListRoute = '/invoices-list'
export const invoiceDetailsRoute = '/invoice-details/:id'
export const createAccountRoute = '/create-account'
export const accountsListRoute = '/account-list'
export const accountDetailsRoute = '/account-details/:id'
export const accountEditRoute = '/update-account/:id'
export const createCarrierRoute = '/create-carrier'
export const carriersListRoute = '/carriers-list'
export const updateCarrierRoute = '/update-carrier/:id'
export const reportsRoute = '/reports'
export const updateProfileRoute = '/update-profile/:id'
export const TMS_ACCOUNT_NUMBER = 'ML72552251'
export const carrierDetailsRoute = '/carrier-details/:id'

export const auth0Settings = {
	domain: process?.env?.REACT_APP_AUTH0_DOMAIN ?? '',
	audience: process?.env?.REACT_APP_AUTH0_AUDIENCE ?? '',
	scope: process?.env?.REACT_APP_AUTH0_SCOPE ?? '',
	responseType: process?.env?.REACT_APP_AUTH0_RESPONSE_TYPE ?? '',
	cacheLocation: (process?.env?.REACT_APP_AUTH0_CACHE_LOCATION ?? '') as CacheLocation,
	clientId: process?.env?.REACT_APP_AUTH0_CLIENT_ID ?? '',
	redirectUri: process?.env?.REACT_APP_AUTH0_REDIRECT_URI ?? '',
	databasePasswordAuthentication: process?.env?.REACT_APP_AUTH0_DATABASE_USER_PASSWORD ?? '',
}

export const oktaSettings = {
	domain: process?.env?.REACT_APP_OKTA_DOMAIN ?? '',
	client: process?.env?.REACT_APP_OKTA_CLIENT ?? '',
	requestContext: process?.env?.REACT_APP_OKTA_REQUEST_CONTEXT ?? '',
}

export const languages = [
	{ icon: '🇺🇸', value: 'en', name: 'English' },
	// { icon: '🇪🇸', value: 'es', name: 'Español' },
]

export const months = [
	{ id: 1, value: 'JAN' },
	{ id: 2, value: 'FEB' },
	{ id: 3, value: 'MAR' },
	{ id: 4, value: 'APR' },
	{ id: 5, value: 'MAY' },
	{ id: 6, value: 'JUN' },
	{ id: 7, value: 'JUL' },
	{ id: 8, value: 'AUG' },
	{ id: 9, value: 'SEP' },
	{ id: 10, value: 'OCT' },
	{ id: 11, value: 'NOV' },
	{ id: 12, value: 'DEC' },
]

export const reportsList = [
	{ name: 'Carrier Usage %', key: 'carrier-usage' },
	{ name: 'Shipment Count', key: 'shipment-count' },
	{ name: 'Average Weight', key: 'average-weight' },
	{ name: 'Average Distance', key: 'average-distance' },
]

export const expiredError = 'Quote/Rate already expired'
export const convertedShipmentError = 'Invalid quote status to convert to shipments'
export const makeAPaymentURL = process?.env?.REACT_APP_REDIRECT_MAKE_PAYMENT_URL ?? ''
export const permissionName = {
	FP_ADMIN_ACCOUNTS_CREATE: 'FP_ADMIN_ACCOUNTS_CREATE',
	FP_ADMIN_ACCOUNTS_UPDATE: 'FP_ADMIN_ACCOUNTS_UPDATE',
	FP_ADMIN_ACCOUNTS_DEACTIVATE: 'FP_ADMIN_ACCOUNTS_DEACTIVATE',
	FP_ADMIN_USERS_CREATE: 'FP_ADMIN_USERS_CREATE',
	FP_ADMIN_USERS_UPDATE: 'FP_ADMIN_USERS_UPDATE',
	FP_ADMIN_USERS_DEACTIVATE: 'FP_ADMIN_USERS_DEACTIVATE',
	FP_ADMIN_CARRIERS_CREATE: 'FP_ADMIN_CARRIERS_CREATE',
	FP_ADMIN_CARRIERS_UPDATE: 'FP_ADMIN_CARRIERS_UPDATE',
	FP_ADMIN_CARRIERS_DEACTIVATE: 'FP_ADMIN_CARRIERS_DEACTIVATE',
	FP_ADMIN_CARRIERS_SHOW_RATE: 'FP_ADMIN_CARRIERS_SHOW_RATE',
	ADMIN_USERS_CREATE: 'ADMIN_USERS_CREATE',
	ADMIN_USERS_UPDATE: 'ADMIN_USERS_UPDATE',
	ADMIN_ACCOUNTS_CREATE: 'ADMIN_ACCOUNTS_CREATE',
	ADMIN_ACCOUNTS_UPDATE: 'ADMIN_ACCOUNTS_UPDATE',
	QUOTES_CREATE: 'QUOTES_CREATE',
	QUOTES_READ: 'QUOTES_READ',
	QUOTES_CONVERT_TO_SHIPMENT: 'QUOTES_CONVERT_TO_SHIPMENT',
	QUOTES_RE_QUOTE: 'QUOTES_RE_QUOTE',
	QUOTES_SEND_BY_EMAIL: 'QUOTES_SEND_BY_EMAIL',
	QUOTES_DOWNLOAD: 'QUOTES_DOWNLOAD',
	QUOTES_DELETE: 'QUOTES_DELETE',
	SHIPMENTS_READ: 'SHIPMENTS_READ',
	SHIPMENTS_DOWNLOAD_BOL: 'SHIPMENTS_DOWNLOAD_BOL',
	SHIPMENTS_DOWNLOAD_LABEL: 'SHIPMENTS_DOWNLOAD_LABEL',
	SHIPMENTS_CONTACT_SUPPORT: 'SHIPMENTS_CONTACT_SUPPORT',
	SHIPMENTS_CLONE: 'SHIPMENTS_CLONE',
	SHIPMENTS_DELETE: 'SHIPMENTS_DELETE',
	INVOICES_READ: 'INVOICES_READ',
	INVOICES_DOWNLOAD: 'INVOICES_DOWNLOAD',
	INVOICES_SEND_BY_EMAIL: 'INVOICES_SEND_BY_EMAIL',
	REPORTS_READ: 'REPORTS_READ',
	REPORTS_CREATE: 'REPORTS_CREATE',
	REPORTS_SEND_BY_EMAIL: 'REPORTS_SEND_BY_EMAIL',
}

export const serviceTypeHelpText = {
	standard:
		'STANDARD contracts: Transit times are estimates. Pickups are not guaranteed; If your shipment misses pickup the first day, the FreightPros team will get it rescheduled for the following business day. ',
	volume:
		'VOLUME contracts: Volume shipments move based on capacity and often run behind schedule. We do not recommend moving a time-sensitive shipment with this option. Pickups are not guaranteed; If your shipment misses pickup the first day, the FreightPros team will get it rescheduled for the following business day. ',
	guaranteed:
		'GUARANTEED BY 9AM, NOON or 5PM: By selecting a Guaranteed option, you are paying an additional fee for a service that prioritizes your freight while in transit. Most guaranteed shipments deliver as promised. If the carrier fails to deliver your shipment on time, the carrier will refund the additional guaranteed fee you paid. Pickups are not guaranteed; If your shipment misses pickup the first day, the FreightPros team will get it rescheduled for the following business day, and your guaranteed delivery date will be delayed by 1 day.',
}
export const AZURE_IMG = {
	AZURE_KEYS_CARRIER: process?.env?.REACT_APP_AZURE_KEYS_CARRIER ?? '',
	AZURE_KEYS_COMPANY: process?.env?.REACT_APP_AZURE_KEYS_COMPANY ?? '',
	AZURE_KEYS_USER: process?.env?.REACT_APP_AZURE_KEYS_USER ?? '',
}
export const AUTH0_SESSION_STRING = `@@auth0spajs@@::${auth0Settings.clientId}::${auth0Settings.audience}::openid profile email ${auth0Settings.scope}`

export const DEFAULT_ADMIN_FEE = `[
  {
    "min": 0,
    "max": 15,
    "type": "FLAT",
    "amount": 15
  },
  {
    "min": 15.01,
    "max": 30,
    "type": "FLAT",
    "amount": 20
  },
  {
    "min": 30.01,
    "max": 60,
    "type": "FLAT",
    "amount": 25
  },
  {
    "min": 60.01,
    "max": 90,
    "type": "FLAT",
    "amount": 30
  },
  {
    "min": 90.01,
    "max": 120,
    "type": "FLAT",
    "amount": 35
  },
  {
    "min": 120.01,
    "max": 150,
    "type": "FLAT",
    "amount": 45
  },
  {
    "min": 150.01,
    "max": 300,
    "type": "MARGIN",
    "amount": 30
  },
  {
    "min": 300.01,
    "max": 600,
    "type": "MARGIN",
    "amount": 25
  },
  {
    "min": 600.01,
    "max": 1500,
    "type": "MARGIN",
    "amount": 20
  }
]`
