/* eslint-disable consistent-return */
import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from '@material-ui/core/styles'
import { Provider as ReduxProvider } from 'react-redux'
import './i18n'
import { Auth0Provider } from '@auth0/auth0-react'
import App from './App'
import store from './redux/store'
import { AUTH0_SESSION_STRING, auth0Settings } from './utils/constants'
import { freightProsTheme } from './themes/freightpros'
import SessionProvider from './context/session/SessionContext'
import * as serviceWorker from './serviceWorker'
import { pusher, SocketContext } from './context/socket/SocketContext'
import ErrorBoundary from './errors/ErrorBoundary/ErrorBoundary'
import AnnouncementModal from './components/AnnouncementModal'
import SupportModal from './components/SupportModal'
import useAuth0Redirect from './hooks/useAuth0Redirect'

const AppContainer = () => {
	const { redirect } = useAuth0Redirect()
	// to handle browser hard refresh case
	const auth0Token = localStorage.getItem(AUTH0_SESSION_STRING)
	console.log('pathname: ', window.location.pathname)
	if (window.location.pathname === process.env.REACT_APP_AUTH_LOGIN_PATH || auth0Token) {
		console.log('Loading AUTH0 Provider')
		return (
			<React.StrictMode>
				<ReduxProvider store={store}>
					<ThemeProvider theme={freightProsTheme}>
						<SessionProvider>
							<Auth0Provider
								domain={auth0Settings.domain}
								clientId={auth0Settings.clientId}
								audience={auth0Settings.audience}
								redirectUri={redirect}
								responseType={auth0Settings.responseType}
								scope={auth0Settings.scope}
								cacheLocation={auth0Settings.cacheLocation}
							>
								<SocketContext.Provider value={pusher}>
									<ErrorBoundary>
										<App />
										<SupportModal />
										<AnnouncementModal />
									</ErrorBoundary>
								</SocketContext.Provider>
							</Auth0Provider>
						</SessionProvider>
					</ThemeProvider>
				</ReduxProvider>
			</React.StrictMode>
		)
	}
	console.log('Loading OKTA Provider')
	return (
		<React.StrictMode>
			<ReduxProvider store={store}>
				<ThemeProvider theme={freightProsTheme}>
					<SessionProvider>
						<SocketContext.Provider value={pusher}>
							<ErrorBoundary>
								<App />
								<SupportModal />
								<AnnouncementModal />
							</ErrorBoundary>
						</SocketContext.Provider>
					</SessionProvider>
				</ThemeProvider>
			</ReduxProvider>
		</React.StrictMode>
	)
}

ReactDOM.render(<AppContainer />, document.getElementById('root'))

serviceWorker.unregister()
