/* eslint-disable react/require-default-props */
import { TextField } from '@material-ui/core'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { getRateAccessSectionTitle } from '../../../../utils/carrierDetailsRateAccessHelpers'
import useStyles from './styles'

const EditRateAccessForm = ({ contractType, serviceType }) => {
	const { register, errors } = useFormContext() // retrieve all hook methods
	const classes = useStyles()
	const { sectionTitle, fieldPrefix } = getRateAccessSectionTitle(contractType, serviceType)

	return (
		<div className={classes.outerDiv}>
			{![contractType, serviceType].includes('create') && (
				<h4 className={classes.sectionCarrierTitle}>{sectionTitle}</h4>
			)}
			<div className={classes.iconTitle}>
				<AssignmentOutlinedIcon color="primary" className={classes.icon} />
				<h5 className={classes.sectionCarrierTitle}>Cubic Capacity Rules</h5>
			</div>
			<div className={clsx(classes.iconTitle, classes.iconDivMargin)}>
				<AssignmentOutlinedIcon color="primary" className={classes.icon} />
				<h6 className={classes.sectionCarrierTitle}>
					Don&apos;t display the carrier if Cubic Footage is over &nbsp;
					<TextField
						style={{ width: 50 }}
						size="small"
						name={`${fieldPrefix}cubic_capacity`}
						variant="standard"
						type="text"
						inputRef={register({
							pattern: { value: /^[0-9]*$/, message: 'Only number allowed' },
							maxLength: { value: 4, message: 'Maximum 4 digit allowed' },
						})}
						// FormHelperTextProps={{ classes: { root: classes.helperText } }}
						error={!!errors[`${fieldPrefix}cubic_capacity`]}
						helperText={errors[`${fieldPrefix}cubic_capacity`] ? errors[`${fieldPrefix}cubic_capacity`].message : null}
						InputLabelProps={{
							shrink: true,
						}}
					/>
					ft and Total Density in Under &nbsp;
					<TextField
						style={{ width: 50 }}
						size="small"
						name={`${fieldPrefix}density`}
						variant="standard"
						type="text"
						inputRef={register({
							pattern: { value: /^[0-9]*$/, message: 'Only number allowed' },
							maxLength: { value: 2, message: 'Maximum 2 digit allowed' },
						})}
						// FormHelperTextProps={{ classes: { root: classes.helperText } }}
						error={!!errors[`${fieldPrefix}density`]}
						helperText={errors[`${fieldPrefix}density`] ? errors[`${fieldPrefix}density`].message : null}
						InputLabelProps={{
							shrink: false,
						}}
					/>
					PCF
				</h6>
			</div>
			<div className={classes.iconTitle}>
				<AssignmentOutlinedIcon color="primary" className={classes.icon} />
				<h5 className={classes.sectionCarrierTitle}> Linear Footage Rules</h5>
			</div>
			<div className={clsx(classes.iconTitle, classes.iconDivMargin)}>
				<AssignmentOutlinedIcon color="primary" className={classes.icon} />
				<h6 className={classes.sectionCarrierTitle}>
					Don&apos;t display the carrier if Total Linear Footage is greater than &nbsp;
					<TextField
						className={classes.textBoxWidth}
						size="small"
						name={`${fieldPrefix}linear_footage_max`}
						variant="standard"
						type="text"
						inputRef={register({
							pattern: { value: /^[0-9]*$/, message: 'Only number allowed' },
							maxLength: { value: 2, message: 'Maximum 2 digit allowed' },
						})}
						// FormHelperTextProps={{ classes: { root: classes.helperText } }}
						error={!!errors[`${fieldPrefix}linear_footage_max`]}
						helperText={
							errors[`${fieldPrefix}linear_footage_max`] ? errors[`${fieldPrefix}linear_footage_max`].message : null
						}
						InputLabelProps={{
							shrink: false,
						}}
					/>
					ft
				</h6>
			</div>
			<div className={classes.iconTitle}>
				<AssignmentOutlinedIcon color="primary" className={classes.icon} />
				<h5 className={classes.sectionCarrierTitle}>Maximum Total Weight</h5>
			</div>
			<div className={clsx(classes.iconTitle, classes.iconDivMargin)}>
				<AssignmentOutlinedIcon color="primary" className={classes.icon} />
				<h6 className={classes.sectionCarrierTitle}>
					Don&apos;t display the carrier if Total Weight is greater than &nbsp;
					<TextField
						className={classes.textBoxWidth}
						size="small"
						name={`${fieldPrefix}weight_max`}
						variant="standard"
						type="text"
						inputRef={register({
							pattern: { value: /^[0-9]*$/, message: 'Only number allowed' },
							maxLength: { value: 5, message: 'Maximum 5 digit allowed' },
						})}
						// FormHelperTextProps={{ classes: { root: classes.helperText } }}
						error={!!errors[`${fieldPrefix}weight_max`]}
						helperText={errors[`${fieldPrefix}weight_max`] ? errors[`${fieldPrefix}weight_max`].message : null}
						InputLabelProps={{
							shrink: false,
						}}
					/>
					lbs
				</h6>
			</div>
		</div>
	)
}

EditRateAccessForm.propTypes = {
	contractType: PropTypes.oneOf(['CSP', 'BLANKET']),
	serviceType: PropTypes.oneOf([
		'LTL_STANDARD',
		'VOLUME',
		// these 3 are lumped under LTL_STANDARD for now.
		// 'GUARANTEED_5PM',
		// 'GUARANTEED_NOON',
		// 'GUARANTEED_9AM'
	]),
}

export default EditRateAccessForm
