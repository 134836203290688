/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const supportStatusSlice = createSlice({
	name: 'supportStatusSlice',
	initialState: {
		open: false,
		subject: '',
		disabledSubject: false,
	},
	reducers: {
		setOpen: (state, action) => {
			state.open = action.payload
		},
		setSubject: (state, action) => {
			state.subject = action.payload
		},
		setDisabledSubject: (state, action) => {
			state.disabledSubject = action.payload
		},
	},
})

const { actions, reducer } = supportStatusSlice

export const { setOpen, setSubject, setDisabledSubject } = actions

export default reducer
