import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	messageTable: {
		position: 'absolute',
		left: '15%',
		marginTop: '3em',
		fontSize: '16px',
		[theme.breakpoints.up('sm')]: {
			left: '50%',
			fontSize: '22px',
		},
	},
	invoiceInfo: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginBottom: '50px',
		[theme.breakpoints.down(768)]: {
			flexDirection: 'column',
		},
		'& > div:first-child': {
			paddingRight: '50px',
			[theme.breakpoints.down(768)]: {
				paddingRight: '0px',
			},
		},
	},
	invoiceInfoRow: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		'& b': {
			display: 'table-cell',
			paddingRight: '50px',
			fontWeight: 'normal',
			lineHeight: '22px',
		},
		'& span': {
			display: 'inline-block',
			textAlign: 'right',
		},
	},
	invoiceInfoRowBalance: {
		'& b': {
			fontWeight: 'bold',
		},
		'& span': {
			fontWeight: 'bold',
		},
	},
	invoiceInfoRowSpace: {
		paddingBottom: '15px',
	},
}))

export default useStyles
