import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import Carousel from 'react-multi-carousel'

import AccessorialCard from '../AccessorialCard'

import 'react-multi-carousel/lib/styles.css'

const useStyles = makeStyles((theme) => ({
	accessorialsSlider: {
		padding: '0 20px 0 0',
		marginBottom: '1em',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
		[theme.breakpoints.up('lg')]: {
			maxWidth: '100%',
		},
	},
}))

const AccessorialsSlider = (props) => {
	const classes = useStyles()
	const { accessorialsList } = props

	const responsive = {
		tablet: {
			breakpoint: { max: 1024, min: 500 },
			items: 1,
			paritialVisibilityGutter: 50,
		},
		mobile: {
			breakpoint: { max: 500, min: 0 },
			items: 1,
			paritialVisibilityGutter: 30,
		},
	}

	return (
		<div className={classes.accessorialsSlider}>
			<Carousel
				ssr
				arrows={false}
				responsive={responsive}
				showDots
				draggable
				renderDotsOutside
				dotListClass="shipment-items-slider-dots"
				focusOnSelect
			>
				{accessorialsList?.length > 0 &&
					accessorialsList.map((accessorial, index) => (
						<AccessorialCard key={`id-accessorial-${index + 1}`} accessorialData={accessorial} />
					))}
			</Carousel>
		</div>
	)
}

AccessorialsSlider.propTypes = {
	accessorialsList: PropTypes.arrayOf(PropTypes.any).isRequired,
}

export default AccessorialsSlider
