import React, { useEffect, useState, useContext, useCallback, useRef, forwardRef, useImperativeHandle } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Prompt, useHistory } from 'react-router-dom'
import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Button, Grid, CircularProgress } from '@material-ui/core'
import SessionContext from '../../../../context/session/index'

import NewQuoteShipmentStepFive from '../../components/NewQuoteShipmentStepFive'
import NewQuoteShipmentStepFour from '../../components/NewQuoteShipmentStepFour'
import NewQuoteShipmentStepThree from '../../components/NewQuoteShipmentStepThree'
import ShiplifySuggestionModal from '../../components/ShiplifySuggestionModal'

import useStyles from './styles'
import {
	clearQuoteDetailsForReQuote,
	clearQuoteDetailsForShipment,
} from '../../../../redux/actions/QuoteDetails.actions'
import { shipmentsListRoute, createShipmentsRoute, createQuotesRoute } from '../../../../utils/constants'
import sweetAlertModal from '../../../../components/SweetAlertModal/SweetAlertModal'
import { capitalizeFirstLetter } from '../../../../utils/helpers'
import { formatPhoneNumber } from '../../utils/helpers'
import { bookShipment, saveCreateShipmentData } from '../../../../redux/actions/Shipment.actions'
import { setTabOrigin, setTabDestination, setTabStatus, clearTab } from '../../../../redux/actions/MultiTabs.actions'
import { reqVerifyRequiredReferences } from '../../../../redux/requests/Shipment.requests'
import { resetShiplifyModalState, updateDataForm } from '../../../../redux/actions/Shiplify.actions'
import removeZDate from '../../../../utils/removeZDate'

const NewShipmentStepsContainer = forwardRef(
	({ activeStep, setActiveStep1, setActiveStep3, setActiveStep4, setActiveStep5, setStepTitle }, refGlobal) => {
		const { handleSubmit, errors, register, watch, setValue, getValues, control } = useForm()
		const { getUserData, getTokenUser } = useContext(SessionContext)
		const { actualCompany } = getUserData()
		const token = getTokenUser()
		const dispatch = useDispatch()
		const history = useHistory()
		const classes = useStyles()
		const { quoteDetailsToShipment: quoteDetails } = useSelector((state) => state.quoteDetails)
		const { dataForm } = useSelector((state) => state.shiplifySuggestions)
		const createShipmentInfo = useSelector((state) => state.shipments.createShipmentInfo)
		const isEmptyForm = Object.keys(createShipmentInfo).length !== 0

		const [earliestPickUpTime, setEarliestPickUpTime] = useState(
			isEmptyForm
				? createShipmentInfo.shipper.pickupEarliestTime
				: moment().set({ hour: 8, minute: 0 }).format('hh:mm A'),
		)
		const [latestPickUpTime, setLatestPickUpTime] = useState(
			isEmptyForm
				? createShipmentInfo.shipper.pickupLatestTime
				: moment().set({ hour: 17, minute: 0 }).format('hh:mm A'),
		)
		const [earliestDeliveryTime, setEarliestDeliveryTime] = useState(
			isEmptyForm
				? createShipmentInfo.consignee.dropOffEarliestTime
				: moment().set({ hour: 8, minute: 0 }).format('hh:mm A'),
		)
		const [latestDeliveryTime, setLatestDeliveryTime] = useState(
			isEmptyForm
				? createShipmentInfo.consignee.dropOffLatestTime
				: moment().set({ hour: 17, minute: 0 }).format('hh:mm A'),
		)

		const [shipmentItems, setShipmentItems] = useState([])
		const [shipperData, setShipperData] = useState({})
		const [consigneeData, setConsigneeData] = useState({})
		const [referencesAdded, setReferencesAdded] = useState([])

		const [quoteId, setQuoteId] = useState(0)
		// Steps 3 to 5
		const [shipmentId, setShipmentId] = useState(0)
		const [contactData, setContactData] = useState({})
		const [selectedOriginLocation, setSelectedOriginLocation] = useState(null)
		const [selectedDestinationLocation, setSelectedDestinationLocation] = useState(null)
		const [shipmentPayload, setShipmentPayload] = useState({})
		const [pickUpContactPhone, setPickUpContactPhone] = useState('')
		const [deliveryContactPhone, setDeliveryContactPhone] = useState('')
		const [bolIdentifier, setBolIdentifier] = useState('')

		const [isPreviousClicked, setIsPreviousClicked] = useState(false)
		const [addInstructions, setAddInstructions] = useState(false)
		const [disableOriginLocationButton, setDisableOriginLocationButton] = useState(false)
		const [disableDestinationLocationButton, setDisableDestinationLocationButton] = useState(false)
		const [watchedFields, setWatchedFields] = useState({
			deliveryAddressOne: '',
			deliveryCompany: '',
			pickUpAddressOne: '',
			pickUpCompany: '',
		})
		const LocationsTypes = { ORIGIN: 'ORIGIN', DESTINATION: 'DESTINATION' }
		const [isBookLoading, setIsBookLoading] = useState(false)

		const [isBookingLater, setIsBookingLater] = useState(false)

		// Shiplify logic
		const [comesFromSavedLocations] = useState(quoteDetails.location_destination_id && quoteDetails.location_origin_id)
		const [fieldsReady, setFieldsReady] = useState({
			state: false,
			count: 0,
		})
		const [shiplifyShipper, setShiplifyShipper] = useState({})
		const [shiplifyConsignee, setShiplifyConsignee] = useState({})

		const referenceInputsRef = useRef([])
		const referencesRef = useRef(referencesAdded)

		useEffect(() => {
			referencesRef.current = referencesAdded
		}, [referencesAdded])

		useEffect(() => {
			dispatch(clearQuoteDetailsForReQuote(''))
		}, [dispatch])

		useEffect(() => {
			setWatchedFields(watch(['pickUpCompany', 'pickUpAddressOne', 'deliveryCompany', 'deliveryAddressOne']))
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		const updateLocationFromModal = (type, location) => {
			if (type.toUpperCase() === LocationsTypes.ORIGIN) {
				setValue('pickUpCompany', location.name)
				setValue('pickUpAddressOne', location.address)
				setValue('pickUpAddressTwo', location.address2)
				setValue('pickUpContactName', location.contact)
				setValue('pickUpContactPhone', formatPhoneNumber(location.phone))
				setPickUpContactPhone(location.phone)
				setValue('pickUpContactEmail', location.email)
				setSelectedOriginLocation({
					open: location.open_time ? removeZDate(location.open_time) : null,
					close: location.close_time ? removeZDate(location.close_time) : null,
				})
				setEarliestPickUpTime(
					location.open_time
						? moment(removeZDate(location.open_time)).format('hh:00 A')
						: moment().set({ hour: 8, minute: 0 }).format('hh:00 A'),
				)
				setLatestPickUpTime(
					location.close_time
						? moment(removeZDate(location.close_time)).format('hh:00 A')
						: moment().set({ hour: 17, minute: 0 }).format('hh:00 A'),
				)
			}
			if (type.toUpperCase() === LocationsTypes.DESTINATION) {
				setValue('deliveryCompany', location.name)
				setValue('deliveryAddressOne', location.address)
				setValue('deliveryAddressTwo', location.address2)
				setValue('deliveryContactName', location.contact)
				setValue('deliveryContactPhone', formatPhoneNumber(location.phone))
				setDeliveryContactPhone(location.phone)
				setValue('deliveryContactEmail', location.email)
				setSelectedDestinationLocation({
					open: location.open_time ? removeZDate(location.open_time) : null,
					close: location.close_time ? removeZDate(location.close_time) : null,
				})
				setEarliestDeliveryTime(
					location.open_time
						? moment(removeZDate(location.open_time)).format('hh:00 A')
						: moment().set({ hour: 8, minute: 0 }).format('hh:00 A'),
				)
				setLatestDeliveryTime(
					location.close_time
						? moment(removeZDate(location.close_time)).format('hh:00 A')
						: moment().set({ hour: 17, minute: 0 }).format('hh:00 A'),
				)
			}
		}
		useEffect(() => {
			if (Object.keys(quoteDetails).length === 0) {
				history.goBack()
			} else {
				const originLabel = `${quoteDetails.city_origin}, ${quoteDetails.state_origin} ${quoteDetails.zipcode_origin}`
				const destinationLabel = `${quoteDetails.city_destination}, ${quoteDetails.state_destination} ${quoteDetails.zipcode_destination}`
				dispatch(setTabOrigin(originLabel))
				dispatch(setTabDestination(destinationLabel))
				dispatch(setTabStatus('ACTIVE'))
				setShipmentItems(quoteDetails.items)
				setReferencesAdded(quoteDetails.references)
				const originAccesorials = quoteDetails.accesorials.filter(
					(accesorial) => accesorial.origin_destination === 'ORIGIN',
				)
				const destinationAccesorials = quoteDetails.accesorials.filter(
					(accesorial) => accesorial.origin_destination === 'DESTINATION',
				)
				setShipperData({
					accessorialsNames: originAccesorials.map((element) => element.name).join(', '),
					accessorialIds: originAccesorials.map((element) => element.accessorial_id),
					dropOffDate: moment(removeZDate(quoteDetails.est_dropoff_date)),
					locationId: quoteDetails.location_origin_id,
				})
				setSelectedOriginLocation({
					open: quoteDetails.location_origin_open_time ? removeZDate(quoteDetails.location_origin_open_time) : null,
					close: quoteDetails.location_origin_close_time ? removeZDate(quoteDetails.location_origin_close_time) : null,
				})
				setEarliestPickUpTime(
					quoteDetails.location_origin_open_time
						? moment(removeZDate(quoteDetails.location_origin_open_time)).format('hh:00 A')
						: moment().set({ hour: 8, minute: 0 }).format('hh:00 A'),
				)
				setLatestPickUpTime(
					quoteDetails.location_origin_close_time
						? moment(removeZDate(quoteDetails.location_origin_close_time)).format('hh:00 A')
						: moment().set({ hour: 17, minute: 0 }).format('hh:00 A'),
				)
				setSelectedDestinationLocation({
					open: quoteDetails.location_destination_open_time
						? removeZDate(quoteDetails.location_destination_open_time)
						: null,
					close: quoteDetails.location_destination_close_time
						? removeZDate(quoteDetails.location_destination_close_time)
						: null,
				})
				setEarliestDeliveryTime(
					quoteDetails.location_destination_open_time
						? moment(removeZDate(quoteDetails.location_destination_open_time)).format('hh:00 A')
						: moment().set({ hour: 8, minute: 0 }).format('hh:00 A'),
				)
				setLatestDeliveryTime(
					quoteDetails.location_destination_close_time
						? moment(removeZDate(quoteDetails.location_destination_close_time)).format('hh:00 A')
						: moment().set({ hour: 17, minute: 0 }).format('hh:00 A'),
				)
				setConsigneeData({
					accessorialsNames: destinationAccesorials.map((element) => element.name).join(', '),
					accessorialIds: destinationAccesorials.map((element) => element.accessorial_id),
					pickUpDate: quoteDetails.est_pickup_date,
					locationId: quoteDetails.location_destination_id,
				})
				setQuoteId(quoteDetails.id)
				setValue('pickUpCity', `${quoteDetails.city_origin}, ${quoteDetails.state_origin}`)
				setValue('pickUpZipCode', quoteDetails.zipcode_origin)
				setValue('pickUpCountry', quoteDetails.country_origin)
				setValue('deliveryCity', `${quoteDetails.city_destination}, ${quoteDetails.state_destination}`)
				setValue('deliveryZipCode', quoteDetails.zipcode_destination)
				setValue('deliveryCountry', quoteDetails.country_destination)
				setValue('pickUpCompany', quoteDetails.company_origin)
				setValue('pickUpAddressOne', quoteDetails.address_origin)
				setValue('pickUpAddressTwo', quoteDetails.address2_origin)
				setValue('pickUpContactName', quoteDetails.contact_origin)
				if (quoteDetails.phone_origin) {
					setValue('pickUpContactPhone', formatPhoneNumber(quoteDetails.phone_origin))
					setPickUpContactPhone(quoteDetails.phone_origin)
				}
				setValue('pickUpContactPhoneExtension', quoteDetails.phone_extension_origin)
				setValue('pickUpContactEmail', quoteDetails.email_origin)
				setValue('deliveryCompany', quoteDetails.company_destination)
				setValue('deliveryAddressOne', quoteDetails.address_destination)
				setValue('deliveryAddressTwo', quoteDetails.address2_destination)
				setValue('deliveryContactName', quoteDetails.contact_destination)
				if (quoteDetails.phone_destination) {
					setValue('deliveryContactPhone', formatPhoneNumber(quoteDetails.phone_destination))
					setDeliveryContactPhone(quoteDetails.phone_destination)
				}
				setValue('deliveryContactPhoneExtension', quoteDetails.phone_extension_destination)
				setValue('deliveryContactEmail', quoteDetails.email_destination)

				if (quoteDetails.specialInstructionsForCarrier) {
					setValue('specialInstructions', quoteDetails.specialInstructionsForCarrier)
					setAddInstructions(true)
				}

				// enabled shiplify
				dispatch(resetShiplifyModalState())
				setFieldsReady({ state: true, count: fieldsReady.count + 1 })
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [dispatch, quoteDetails, history, setValue])

		useEffect(() => {
			if (isPreviousClicked) {
				setValue('pickUpComments', createShipmentInfo.specialInstructions.origin)
				setValue('pickUpCompany', createShipmentInfo.shipper.companyName)
				setValue('pickUpZipCode', createShipmentInfo.shipper.zipCode)
				setValue('pickUpCity', `${createShipmentInfo.shipper.city}, ${createShipmentInfo.shipper.state}`)
				setValue('pickUpCountry', createShipmentInfo.shipper.country)
				setValue('pickUpAddressOne', createShipmentInfo.shipper.address1)
				setValue('pickUpAddressTwo', createShipmentInfo.shipper.address2)
				setValue('pickUpContactName', createShipmentInfo.shipper.contactName)
				setValue('pickUpContactPhone', createShipmentInfo.shipper.contactPhone)
				setPickUpContactPhone(createShipmentInfo.shipper.contactPhone)
				setValue('pickUpContactPhoneExtension', createShipmentInfo.shipper.contactPhoneExtension)
				setValue('pickUpContactEmail', createShipmentInfo.shipper.contactEmail)
				setValue('deliveryComments', createShipmentInfo.specialInstructions.destination)
				setValue('deliveryCompany', createShipmentInfo.consignee.companyName)
				setValue('deliveryCity', `${createShipmentInfo.consignee.city}, ${createShipmentInfo.consignee.state}`)
				setValue('deliveryZipCode', createShipmentInfo.consignee.zipCode)
				setValue('deliveryCountry', createShipmentInfo.consignee.country)
				setValue('deliveryAddressOne', createShipmentInfo.consignee.address1)
				setValue('deliveryAddressTwo', createShipmentInfo.consignee.address2)
				setValue('deliveryContactName', createShipmentInfo.consignee.contactName)
				setValue('deliveryContactPhone', createShipmentInfo.consignee.contactPhone)
				setDeliveryContactPhone(createShipmentInfo.consignee.contactPhone)
				setValue('deliveryContactPhoneExtension', createShipmentInfo.consignee.contactPhoneExtension)
				setValue('deliveryContactEmail', createShipmentInfo.consignee.contactEmail)
				if (addInstructions) setValue('specialInstructions', createShipmentInfo.specialInstructions.comments)
			}
		}, [isPreviousClicked, createShipmentInfo, setValue, addInstructions])

		useEffect(() => {
			if (Object.keys(dataForm).length > 0) {
				setValue('pickUpCompany', dataForm?.company_origin ?? '')
				setValue('pickUpAddressOne', dataForm?.address_origin ?? '')
				setValue('deliveryCompany', dataForm?.company_destination ?? '')
				setValue('deliveryAddressOne', dataForm?.address_destination ?? '')
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		// eslint-disable-next-line react-hooks/exhaustive-deps
		const verifyFields = useCallback(
			debounce((inputData) => {
				const hasAllFields = !Object.keys(inputData).find((prop) => inputData[prop].trim() === '')
				if (!hasAllFields) setFieldsReady({ state: false, count: fieldsReady.count + 1 })
				if (hasAllFields) {
					dispatch(resetShiplifyModalState())
					setFieldsReady({ state: true, count: fieldsReady.count + 1 })
				}
			}, 2000),
			[],
		)

		useEffect(() => {
			if (!comesFromSavedLocations) verifyFields(watchedFields)
		}, [comesFromSavedLocations, watchedFields, verifyFields])

		useEffect(() => {
			if (fieldsReady.state && Object.keys(quoteDetails).length > 0) {
				const originAccesorials = quoteDetails.accesorials.filter(
					(accesorial) => accesorial.origin_destination === 'ORIGIN',
				)
				setShiplifyShipper({
					accessorials: originAccesorials,
					address: getValues('pickUpAddressOne'),
					city: quoteDetails.city_origin,
					name: getValues('pickUpCompany'),
					state: quoteDetails.state_origin,
					zip_code: quoteDetails.zipcode_origin,
				})
				const destinationAccesorials = quoteDetails.accesorials.filter(
					(accesorial) => accesorial.origin_destination === 'DESTINATION',
				)
				setShiplifyConsignee({
					accessorials: destinationAccesorials,
					address: getValues('deliveryAddressOne'),
					city: quoteDetails.city_destination,
					name: getValues('deliveryCompany'),
					state: quoteDetails.state_destination,
					zip_code: quoteDetails.zipcode_destination,
				})
			}
		}, [fieldsReady, quoteDetails, getValues])

		const validateTimes = () => {
			if (
				earliestPickUpTime === '' ||
				latestPickUpTime === '' ||
				earliestDeliveryTime === '' ||
				latestDeliveryTime === ''
			) {
				sweetAlertModal(
					'warning',
					'Invalid times',
					'Please enter valid hours for the pickup or delivery time',
					'Yes',
					true,
					false,
					'',
				)
				return true
			}
			return false
		}

		const checkReferences = async (references) => {
			const payload = references.map((reference) => ({
				type: reference?.type ?? reference.referenceType,
				number: reference.value,
			}))
			const [error] = await reqVerifyRequiredReferences({ references: [...payload] })
			if (error !== null) {
				sweetAlertModal('error', 'Missing references', error?.err?.message ?? error.message, 'Ok', true, false, null)
				return false
			}
			return true
		}

		const onNextStep = async () => {
			if (await checkReferences(referencesRef.current)) {
				if (validateTimes()) {
					return
				}
				if (
					watch('pickUpCompany') &&
					watch('pickUpAddressOne') &&
					watch('pickUpContactName') &&
					watch('deliveryCompany') &&
					watch('deliveryAddressOne') &&
					watch('deliveryContactName')
				) {
					const isHazmatDataMissing = shipmentItems.find((item) => item.is_haz_mat && !item.haz_mat_number)
					if (isHazmatDataMissing && Object.keys(isHazmatDataMissing).length !== 0) {
						window.scrollTo(0, document.body.scrollHeight / 2)
					}
					let shipperAccesorials = ''
					let consigneeAccesorials = ''
					quoteDetails.accesorials.map((accessorial, i, arr) => {
						let name
						const isLast = arr.length - 1 === i
						if (accessorial.origin_destination === 'ORIGIN') {
							name = capitalizeFirstLetter(accessorial.name)
							shipperAccesorials += isLast ? `${name}` : `${name}, `
						}
						if (accessorial.origin_destination === 'DESTINATION') {
							name = capitalizeFirstLetter(accessorial.name)
							consigneeAccesorials += isLast ? `${name}` : `${name}, `
						}
						return name
					})
					const payload = {
						quoteId: quoteDetails.id,
						rateId: quoteDetails.rate_id,
						type: 'CONSIGNEE',
						shipper: {
							shipperId: quoteDetails.location_origin_id || null,
							companyName: watch('pickUpCompany'),
							address1: watch('pickUpAddressOne'),
							address2: watch('pickUpAddressTwo'),
							contactName: watch('pickUpContactName'),
							contactPhone: watch('pickUpContactPhone'),
							contactPhoneExtension: watch('pickUpContactPhoneExtension'),
							contactEmail: watch('pickUpContactEmail'),
							pickupEarliestTime: earliestPickUpTime,
							pickupLatestTime: latestPickUpTime,
							sendConfirmationEmail: false,
							zipCode: quoteDetails.zipcode_origin,
							city: quoteDetails.city_origin,
							state: quoteDetails.state_origin,
							country: quoteDetails.country_origin,
							pickUpDate: moment(removeZDate(quoteDetails.est_pickup_date)),
							accesorials: shipperAccesorials,
						},
						consignee: {
							consigneeId: quoteDetails.location_destination_id || null,
							companyName: watch('deliveryCompany'),
							address1: watch('deliveryAddressOne'),
							address2: watch('deliveryAddressTwo'),
							contactName: watch('deliveryContactName'),
							contactPhone: watch('deliveryContactPhone'),
							contactEmail: watch('deliveryContactEmail'),
							contactPhoneExtension: watch('deliveryContactPhoneExtension'),
							dropOffEarliestTime: earliestDeliveryTime,
							dropOffLatestTime: latestDeliveryTime,
							zipCode: quoteDetails.zipcode_destination,
							city: quoteDetails.city_destination,
							state: quoteDetails.state_destination,
							country: quoteDetails.country_destination,
							pickUpDate: moment(removeZDate(quoteDetails.est_dropoff_date)),
							accesorials: consigneeAccesorials,
						},
						items: shipmentItems,
						specialInstructions: {
							origin: watch('pickUpComments'),
							destination: watch('deliveryComments'),
							comments: watch('specialInstructions'),
						},
						references: referencesRef.current,
						carrierData: quoteDetails.rate,
						contractData: quoteDetails.contractInfo,
					}
					setShipmentPayload(payload)
					dispatch(saveCreateShipmentData(payload))
					dispatch(updateDataForm({}))
					setActiveStep4()
					setShiplifyConsignee({})
					setShiplifyShipper({})
					setIsPreviousClicked(false)
				}
			}
		}

		const validateAccountForms = async (data) => {
			setSelectedOriginLocation(null)
			setSelectedDestinationLocation(null)
			if (referenceInputsRef.current) {
				const referencesResponse = []
				referenceInputsRef.current.forEach((ref, index) => {
					if (ref) {
						setTimeout(() => {
							const response = ref.submitForm()
							referencesResponse[index] = response
						}, 1000)
					}
				})
				setTimeout(() => {
					if (referencesResponse.find((element) => element === 'error')) return
					onNextStep(data)
				}, 1000)
			}
		}

		const handleCancelShipmentClick = () => {
			sweetAlertModal(
				'warning',
				'Canceling Shipment',
				'Are you sure you want to cancel the shipment?',
				'Yes',
				true,
				true,
				'No',
			).then((result) => {
				if (result.isConfirmed) {
					history.push(shipmentsListRoute)
				}
			})
		}

		const downloadBOL = (bookedShipmentId, bolNumber) => {
			const url = `${process.env.REACT_APP_BACKEND_QUOTES_BASE_URL}/shipment/bol/${bookedShipmentId}`

			const oReq = new XMLHttpRequest()
			oReq.open('GET', url, true)
			oReq.responseType = 'arraybuffer'
			oReq.setRequestHeader('X-Company-id', Number(actualCompany))
			oReq.setRequestHeader('Authorization', `Bearer ${token}`)

			oReq.onload = function download() {
				const blob = new Blob([oReq.response], { type: 'application/pdf' })
				const URL = window.URL || window.webkitURL
				const dataUrl = URL.createObjectURL(blob)
				const downloadLink = document.createElement('a')
				const fileName = `BOL-${bolNumber}.pdf`

				downloadLink.href = dataUrl
				downloadLink.download = fileName
				downloadLink.click()
			}
			oReq.send()
		}

		const handleBookShipmentClick = async ({ isOnHold }) => {
			setIsBookLoading(true)
			const items = shipmentItems.map((item) => ({
				itemId: item.id,
				nmfc: item.nmfc,
				subNmfc: item.sub_nmfc,
				description: item.description,
				isStackable: item.stackable,
				isHazardous: item.is_haz_mat,
				...(item.is_haz_mat && {
					hazMatData: {
						shippingName: item.haz_mat_shipping_name,
						hazMatNumberType: item.haz_mat_number_type,
						hazMatNumber: item.haz_mat_number,
						hazMatEMSNumber: item.haz_mat_ems_number,
						hazMatGroup: item.haz_mat_group,
						hazMatClass: item.haz_mat_class,
					},
				}),
				...(item?.item_details &&
					item?.item_details.length > 0 && {
						itemDetails: item?.item_details.map((itd) => ({ ...itd, quantity: +itd.quantity })),
					}),
			}))

			const references = referencesAdded.map((reference) => ({
				type: reference?.referenceType ?? reference?.type,
				number: reference.value,
			}))

			const payload = {
				quoteId: shipmentPayload.quoteId,
				rateId: shipmentPayload.rateId,
				type: 'LTL',
				shipper: {
					shipperId: shipmentPayload.shipper.shipperId,
					companyName: shipmentPayload.shipper.companyName,
					address1: shipmentPayload.shipper.address1,
					address2: shipmentPayload.shipper.address2,
					contactName: shipmentPayload.shipper.contactName,
					contactPhone: shipmentPayload.shipper.contactPhone,
					contactPhoneExtension: shipmentPayload.shipper.contactPhoneExtension,
					contactEmail: shipmentPayload.shipper.contactEmail,
					saveShipper: false,
					sendConfirmationEmail: shipmentPayload.shipper.sendConfirmationEmail,
					pickupEarliestTime: shipmentPayload.shipper.pickupEarliestTime,
					pickupLatestTime: shipmentPayload.shipper.pickupLatestTime,
				},
				consignee: {
					consigneeId: shipmentPayload.consignee.consigneeId,
					companyName: shipmentPayload.consignee.companyName,
					address1: shipmentPayload.consignee.address1,
					address2: shipmentPayload.consignee.address2,
					contactName: shipmentPayload.consignee.contactName,
					contactPhone: shipmentPayload.consignee.contactPhone,
					contactPhoneExtension: shipmentPayload.consignee.contactPhoneExtension,
					saveConsignee: false,
					contactEmail: shipmentPayload.consignee.contactEmail,
					dropOffEarliestTime: shipmentPayload.consignee.dropOffEarliestTime,
					dropOffLatestTime: shipmentPayload.consignee.dropOffLatestTime,
				},
				items,
				specialInstructions: {
					origin: shipmentPayload.specialInstructions.origin,
					destination: shipmentPayload.specialInstructions.destination,
					comments: shipmentPayload.specialInstructions.comments,
				},
				references,
				isOnHold,
			}

			let contactInfo = []

			const shipper = {
				name: `${shipmentPayload.shipper.contactName} (origin contact)`,
				email: shipmentPayload.shipper.contactEmail,
				phone: shipmentPayload.shipper.contactPhone,
				phoneExtension: shipmentPayload.shipper.contactPhoneExtension,
				notificationSendBol: false,
				notificationByEmail: false,
				notificationBySms: false,
				notificationBooked: false,
				notificationInTransit: false,
				notificationDelivered: false,
			}

			const consignee = {
				name: `${shipmentPayload.consignee.contactName} (destination contact)`,
				email: shipmentPayload.consignee.contactEmail,
				phone: shipmentPayload.consignee.contactPhone,
				phoneExtension: shipmentPayload.consignee.contactPhoneExtension,
				notificationSendBol: false,
				notificationByEmail: false,
				notificationBySms: false,
				notificationBooked: false,
				notificationInTransit: false,
				notificationDelivered: false,
			}

			contactInfo = [shipper, consignee]

			const { status, resp } = await dispatch(bookShipment(payload))
			if (status === 'error') {
				sweetAlertModal(status, 'There was an error in the book shipment process!', resp, 'Ok', true, false, null).then(
					(result) => {
						if (result.isConfirmed) {
							setIsBookLoading(false)
						}
					},
				)
			} else {
				setIsBookLoading(false)
				if (!isOnHold) {
					sweetAlertModal(
						status,
						'Your shipment has been booked successfully!',
						`BOL#: ${resp.bol_identifier}`,
						'Ok',
						true,
						false,
						null,
					)
					setShipmentId(resp.id)
					setBolIdentifier(resp.bol_identifier)
					setContactData(contactInfo)
					downloadBOL(resp.id, resp.bol_identifier)
					setIsPreviousClicked(false)
					dispatch(saveCreateShipmentData({}))
					setStepTitle(`Shipment #${resp.bol_identifier}`)
					setActiveStep5()
				} else {
					setIsBookingLater(true)
					setTimeout(() => {
						history.push(shipmentsListRoute)
					}, 200)
				}
			}
		}

		const onPrevStep = () => {
			setActiveStep3()
			setIsPreviousClicked(true)
			dispatch(resetShiplifyModalState())
		}

		const handleRedirect = (redirection) => {
			dispatch(resetShiplifyModalState())
			if (redirection === 'shipment') {
				history.push(shipmentsListRoute)
			} else {
				dispatch(clearTab())
				setActiveStep1()
			}
		}

		useImperativeHandle(refGlobal, () => ({
			onSubmitStep3() {
				handleSubmit(validateAccountForms)()
			},
			onSubmitStep4() {
				handleBookShipmentClick({ isOnHold: false })
			},
			onBackStepGoTo3() {
				onPrevStep()
			},
		}))

		useEffect(
			() => () => {
				if (Object.keys(quoteDetails).length !== 0) {
					dispatch(clearQuoteDetailsForShipment())
					dispatch(setTabOrigin(''))
					dispatch(setTabDestination(''))
				}
				// eslint-disable-next-line react-hooks/exhaustive-deps
			},
			[],
		)

		return (
			<>
				{[3, 4].includes(activeStep) && (
					<Prompt
						when={!isBookingLater}
						message={(location) =>
							Object.keys(quoteDetails).length !== 0 &&
							location.pathname !== createShipmentsRoute &&
							location.pathname !== createQuotesRoute
								? 'Canceling the shipment process in this step will cause data loss. Are you sure you want to leave the process?'
								: true
						}
					/>
				)}
				{[3, 4, 5].includes(activeStep) && (
					<>
						<div className="new-shipment-container">
							{activeStep === 3 ? (
								<>
									<ShiplifySuggestionModal
										activeStep={3}
										shipper={shiplifyShipper}
										consignee={shiplifyConsignee}
										quoteId={quoteId}
										setActiveStep1={setActiveStep1}
									/>
									<form
										id="form-step-three"
										onChange={() => {
											if (
												getValues('pickUpCompany') !== watchedFields.pickUpCompany ||
												getValues('pickUpAddressOne') !== watchedFields.pickUpAddressOne ||
												getValues('deliveryCompany') !== watchedFields.deliveryCompany ||
												getValues('deliveryAddressOne') !== watchedFields.deliveryAddressOne
											) {
												setWatchedFields(
													watch(['pickUpCompany', 'pickUpAddressOne', 'deliveryCompany', 'deliveryAddressOne']),
												)
											}
										}}
									>
										<NewQuoteShipmentStepThree
											setEarliestPickUpTime={setEarliestPickUpTime}
											setLatestPickUpTime={setLatestPickUpTime}
											setEarliestDeliveryTime={setEarliestDeliveryTime}
											setLatestDeliveryTime={setLatestDeliveryTime}
											disableOriginLocationButton={disableOriginLocationButton}
											setDisableOriginLocationButton={setDisableOriginLocationButton}
											disableDestinationLocationButton={disableDestinationLocationButton}
											setDisableDestinationLocationButton={setDisableDestinationLocationButton}
											shipperData={shipperData}
											consigneeData={consigneeData}
											items={shipmentItems}
											setShipmentItems={setShipmentItems}
											references={referencesAdded}
											setReferencesAdded={setReferencesAdded}
											errors={errors}
											register={register}
											isPreviousClicked={isPreviousClicked}
											addInstructions={addInstructions}
											setAddInstructions={setAddInstructions}
											getValues={getValues}
											control={control}
											setValue={setValue}
											pickUpContactPhone={pickUpContactPhone}
											setPickUpContactPhone={setPickUpContactPhone}
											deliveryContactPhone={deliveryContactPhone}
											setDeliveryContactPhone={setDeliveryContactPhone}
											ref={referenceInputsRef}
											updateLocationFromModal={updateLocationFromModal}
											linearFeet={quoteDetails?.linear_feet ? quoteDetails?.linear_feet.toString() : ''}
											selectedOriginLocation={selectedOriginLocation}
											selectedDestinationLocation={selectedDestinationLocation}
											setSelectedOriginLocation={setSelectedOriginLocation}
											setSelectedDestinationLocation={setSelectedDestinationLocation}
										/>
									</form>
								</>
							) : null}
							{activeStep === 4 ? (
								<NewQuoteShipmentStepFour
									isDetail={false}
									dataConfirm={shipmentPayload}
									linearFeet={quoteDetails?.linear_feet ? quoteDetails?.linear_feet.toString() : ''}
								/>
							) : null}
							{!isBookLoading ? null : (
								<div className={classes.loadingWrapper}>
									<CircularProgress />
								</div>
							)}
							{activeStep === 5 && (
								<NewQuoteShipmentStepFive
									contactData={contactData}
									setContactData={setContactData}
									shipmentId={shipmentId}
									bolIdentifier={bolIdentifier}
								/>
							)}
						</div>
						<Grid container className={classes.buttonsRow}>
							{activeStep !== 3 && activeStep !== 5 && (
								<Button
									id="new-shipment-cancel-button"
									style={{ marginLeft: '1em' }}
									className={classes.cancelShipmentButton}
									onClick={() => handleCancelShipmentClick()}
								>
									Cancel
								</Button>
							)}
							{activeStep === 3 ? (
								<Button
									className={classes.button}
									variant="contained"
									color="secondary"
									onClick={handleSubmit(validateAccountForms)}
									type="button"
								>
									Next Step
								</Button>
							) : null}
							{activeStep === 4 ? (
								<Button
									id="new-shipment-previous-step-button"
									className={classes.previousButton}
									variant="contained"
									onClick={onPrevStep}
								>
									Previous
								</Button>
							) : null}
							{activeStep === 4 ? (
								<>
									<Button
										id="new-shipment-book-now-button"
										className={classes.button}
										variant="contained"
										color="secondary"
										onClick={() => handleBookShipmentClick({ isOnHold: false })}
									>
										Book now
									</Button>
									<Button
										id="new-shipment-book-later-button"
										className={classes.bookItLater}
										color="primary"
										onClick={() => handleBookShipmentClick({ isOnHold: true })}
									>
										Book it later
									</Button>
								</>
							) : null}
							<div className="new-shipment-buttons-third-step">
								{activeStep === 5 ? (
									<>
										<Button
											id="new-shipment-new-quote-button"
											className={classes.buttonQuote}
											variant="contained"
											onClick={() => handleRedirect('quote')}
										>
											Create a New Quote
										</Button>
										<Button
											id="new-shipment-shipment-list-button"
											className={classes.buttonShipment}
											variant="contained"
											color="secondary"
											onClick={() => handleRedirect('shipment')}
										>
											Back to My Shipments
										</Button>
									</>
								) : null}
							</div>
						</Grid>
					</>
				)}
			</>
		)
	},
)

NewShipmentStepsContainer.propTypes = {
	activeStep: PropTypes.number.isRequired,
	setActiveStep1: PropTypes.func.isRequired,
	setActiveStep3: PropTypes.func.isRequired,
	setActiveStep4: PropTypes.func.isRequired,
	setActiveStep5: PropTypes.func.isRequired,
	setStepTitle: PropTypes.func.isRequired,
}

export default NewShipmentStepsContainer
