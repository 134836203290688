import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Menu, MenuItem, withStyles, ListItemIcon, ListItemText } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import useUsersFilters from '../../../../../hooks/useUsersFilters'
import { updateUserStatusBulk, fetchUsers } from '../../../../../redux/actions/Users.actions'
import sweetAlertModal from '../../../../../components/SweetAlertModal/SweetAlertModal'
import SessionContext from '../../../../../context/session'
import { permissionName } from '../../../../../utils/constants'
import isPermit from '../../../../../utils/permission'

import useStyles from './style'

const StyledMenuItem = withStyles(() => ({
	root: {
		'&:hover': {
			backgroundColor: '#F9D2CA',
		},
	},
}))(MenuItem)

const TableToolbar = (props) => {
	const { selected } = props
	const classes = useStyles()
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)
	const dispatch = useDispatch()
	const [buildParamsFromState] = useUsersFilters()
	const filterParams = buildParamsFromState()
	const { getUserData } = useContext(SessionContext)
	const { permissions } = getUserData()
	const handleOpenMenu = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleUserStatusUpdate = async (newStatus) => {
		const { status } = await dispatch(
			updateUserStatusBulk({ status: newStatus, userIds: selected.map((id) => Number(id)) }),
		)

		if (status === 'error') {
			sweetAlertModal('error', 'There was an error trying to update users', null, 'Ok', true, false)
		} else {
			sweetAlertModal(status, 'Users were updated', null, 'Ok', true, false, null)
			await dispatch(fetchUsers(filterParams))
		}
	}

	return (
		<div>
			{selected.length > 0 && (
				<div className={classes.toolBar}>
					{isPermit(permissions, permissionName.FP_ADMIN_USERS_DEACTIVATE) && (
						<>
							<p className={classes.toolbarSelected}>{`(${selected.length} selected)`}</p>
							<div
								className={classes.toolbarItemIcon}
								role="presentation"
								onClick={() => {
									handleUserStatusUpdate('true')
								}}
							>
								<CheckCircleOutlineOutlinedIcon className={classes.checkCircle} />
								<p style={{ margin: 0 }}>Activate users</p>
							</div>
							<div
								className={classes.toolbarItemIcon}
								role="presentation"
								onClick={() => {
									handleUserStatusUpdate('false')
								}}
							>
								<NotInterestedIcon color="error" fontSize="small" style={{ marginRight: 7 }} />
								<p style={{ margin: 0 }}>Deactivate users</p>
							</div>
						</>
					)}
					<MoreVertIcon style={{ color: '#2C2C2C' }} onClick={handleOpenMenu} className={classes.moreIcon} />
				</div>
			)}
			<Menu id="long-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
				<StyledMenuItem
					onClick={() => {
						handleClose()
					}}
				>
					<ListItemIcon className={classes.listItemIcon}>
						<CheckCircleOutlineOutlinedIcon className={classes.checkCircle} />
					</ListItemIcon>
					<ListItemText primary="Activate Accounts" />
				</StyledMenuItem>
				<StyledMenuItem>
					<ListItemIcon className={classes.listItemIcon}>
						<NotInterestedIcon color="primary" fontSize="small" style={{ marginRight: 7 }} />
					</ListItemIcon>
					<ListItemText primary="Remove Carriers" />
				</StyledMenuItem>
			</Menu>
		</div>
	)
}

TableToolbar.propTypes = {
	selected: PropTypes.arrayOf(PropTypes.any).isRequired,
}

export default TableToolbar
