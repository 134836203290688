/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'
import prepareDataForUpdateQuote from './utils/prepareDataForUpdateQuote'
import sweetAlertModal from '../../../../components/SweetAlertModal/SweetAlertModal'
import {
	resetShiplify,
	fetchShiplifySuggestions,
	updateUserIgnored,
	resetShiplifyModalState,
	updateDataForm,
} from '../../../../redux/actions/Shiplify.actions'
import { saveQuoteDetailsForReQuote, quoteForReQuote } from '../../../../redux/actions/QuoteDetails.actions'
import { editQuote } from '../../../../redux/actions/Quotes.actions'
import useStyle from './styles'
import SessionContext from '../../../../context/session'
import { reqGetSettingByName } from '../../../../redux/requests/App.request'
import { calculateTotalWeight, calculateTotalItems } from '../../utils/helpers'

const ShiplifySuggestionModal = ({
	activeStep,
	shipper,
	consignee,
	setOriginAccessorialsSelected,
	setDefaultValueOriginAccs,
	dispatchSetOriginAccessorials,
	setDestinationAccessorialsSelected,
	setDefaultValueDestinationAccs,
	dispatchSetDestinationAccessorials,
	quoteId,
	setActiveStep1,
}) => {
	const classes = useStyle()
	const dispatch = useDispatch()
	const { getUserData } = useContext(SessionContext)
	const { actualCompany } = getUserData()
	const { suggestions, ignoredByUser, acceptedByUser } = useSelector((state) => state.shiplifySuggestions)
	const [open, setOpen] = useState(false)
	const [step3Ready, setStep3Ready] = useState(false)
	const [shipperData, setShipperData] = useState([])
	const [consigneeData, setConsigneeData] = useState([])
	const [canUseShiplify, setCanUseShiplify] = useState(false)
	const quoteInfo = useSelector((state) => state.quoteDetails)
	const avgVal = quoteInfo.quoteDetailsToShipment.items
		? calculateTotalWeight(quoteInfo.quoteDetailsToShipment.items) /
			calculateTotalItems(quoteInfo.quoteDetailsToShipment.items)
		: 0

	const formatBody = (origin, destination) => ({
		quote: {
			consignee_city: destination.city,
			consignee_name: destination.name,
			consignee_postal_code: destination.zip_code,
			consignee_state: destination.state,
			consignee_street_address: destination.address,
			shipper_city: origin.city,
			shipper_name: origin.name,
			shipper_postal_code: origin.zip_code,
			shipper_state: origin.state,
			shipper_street_address: origin.address,
		},
	})

	const onExited = () => {
		if (step3Ready) {
			setActiveStep1()
		}
		setShipperData([])
		setConsigneeData([])
		dispatch(resetShiplify())
	}

	const handleIgnore = () => {
		if (activeStep === 3) dispatch(updateUserIgnored(true))
		setOpen(false)
	}

	const getAccessorials = (origin, destinationType) => {
		if (origin.accessorials.length > 0) {
			return origin.accessorials.filter(
				// eslint-disable-next-line camelcase
				({ origin_destination }) => origin_destination === destinationType,
			)
		}
		return false
	}

	const matchAccessorials = (current, incomming) =>
		incomming.filter(({ freightProsAccessorialId }) => !current.some(({ id }) => freightProsAccessorialId === id))

	const formatAccesorial = (accessorial, destinationType) => ({
		accessorialId: accessorial.freightProsAccessorialId || accessorial.accessorial_id,
		internalCode: accessorial.freightProsAccessorialCode || accessorial.internal_code,
		name: accessorial.freightProsAccessorialName || accessorial.name,
		origin_destination: destinationType,
		value: null,
		value_required: false,
	})

	const isValidObject = (value) => {
		if (typeof value === 'object' && value) {
			const isEmpty = Object.values(value).some((elem) => {
				let trimVar
				if (typeof elem === 'string') {
					trimVar = elem.trim()
				} else {
					trimVar = true
				}
				return elem === null || !trimVar
			})
			if (isEmpty) {
				return false
			}
			return Object.keys(value).length > 0
		}
		return false
	}

	const hasValidId = (value) => !value.find(({ freightProsAccessorialId }) => freightProsAccessorialId === 0)

	const removeDuplicatedId = (array) =>
		array.reduce((acc, cur) => {
			if (!acc.some((el) => el.freightProsAccessorialId === cur.freightProsAccessorialId)) acc.push(cur)
			return acc
		}, [])

	const removeCeroIdAccessorial = (array) =>
		array.filter(
			// eslint-disable-next-line camelcase
			({ freightProsAccessorialId }) => freightProsAccessorialId !== 0,
		)
	const isExistDistinctAccesorial = (originShiplify, destinationShiplify) => {
		let isExistDistinct = false
		const actualOrigin = shipper?.accessorials ?? []
		const actualDestination = consignee?.accessorials ?? []
		// origin
		originShiplify.forEach((oriShipliEle) => {
			const id = oriShipliEle.freightProsAccessorialId
			const index = actualOrigin.findIndex((ind) => id === ind.accessorial_id)
			if (index === -1) isExistDistinct = true
		})
		destinationShiplify.forEach((destShipliEle) => {
			const id = destShipliEle.freightProsAccessorialId
			const index = actualDestination.findIndex((ind) => id === ind.accessorial_id)
			if (index === -1) isExistDistinct = true
		})
		return isExistDistinct
	}

	const getDistinctAccesorialData = (accData, accName) => {
		const actualAcc = accName === 'Origin' ? shipper?.accessorials ?? [] : consignee?.accessorials ?? []
		const result = accData.filter(
			(val) =>
				!actualAcc.some((x) => x.accessorial_id === val.freightProsAccessorialId) && val.freightProsAccessorialId !== 0,
		)
		return result
	}

	const handleAccept = async () => {
		if (activeStep === 1) {
			if (getAccessorials(shipper, 'ORIGIN')) {
				if (matchAccessorials(shipper.accessorials, shipperData).length > 0) {
					const formatedAccessorials = matchAccessorials(shipper.accessorials, shipperData).map((accessorial) =>
						formatAccesorial(accessorial, 'ORIGIN'),
					)
					setOriginAccessorialsSelected([...formatedAccessorials])
					setDefaultValueOriginAccs([...formatedAccessorials])
					dispatchSetOriginAccessorials([...formatedAccessorials])
				}
			} else {
				const formatedAccessorials = shipperData.map((accessorial) => formatAccesorial(accessorial, 'ORIGIN'))
				setOriginAccessorialsSelected([...formatedAccessorials])
				setDefaultValueOriginAccs([...formatedAccessorials])
				dispatchSetOriginAccessorials([...formatedAccessorials])
			}
			if (getAccessorials(consignee, 'DESTINATION')) {
				if (matchAccessorials(consignee.accessorials, consigneeData).length > 0) {
					const formatedAccessorials = matchAccessorials(consignee.accessorials, consigneeData).map((accessorial) =>
						formatAccesorial(accessorial, 'DESTINATION'),
					)
					setDestinationAccessorialsSelected([...consignee.accessorials, ...formatedAccessorials])
					setDefaultValueDestinationAccs([...consignee.accessorials, ...formatedAccessorials])
					dispatchSetDestinationAccessorials([...consignee.accessorials, ...formatedAccessorials])
				}
			} else {
				const formatedAccessorials = consigneeData.map((accessorial) => formatAccesorial(accessorial, 'DESTINATION'))
				setDestinationAccessorialsSelected([...formatedAccessorials])
				setDefaultValueDestinationAccs([...formatedAccessorials])
				dispatchSetDestinationAccessorials([...formatedAccessorials])
			}
			setOpen(false)
		}
		if (activeStep === 3) {
			let suggestedAccessorials = []
			if (getAccessorials(shipper, 'ORIGIN')) {
				const formatedAccessorials = [...shipper.accessorials, ...shipperData].map((accessorial) =>
					formatAccesorial(accessorial, 'ORIGIN'),
				)
				suggestedAccessorials = [...suggestedAccessorials, ...formatedAccessorials]
			} else {
				const formatedAccessorials = shipperData.map((accessorial) => formatAccesorial(accessorial, 'ORIGIN'))
				suggestedAccessorials = [...suggestedAccessorials, ...formatedAccessorials]
			}
			if (getAccessorials(consignee, 'DESTINATION')) {
				const formatedAccessorials = [...consignee.accessorials, ...consigneeData].map((accessorial) =>
					formatAccesorial(accessorial, 'DESTINATION'),
				)
				suggestedAccessorials = [...suggestedAccessorials, ...formatedAccessorials]
			} else {
				const formatedAccessorials = consigneeData.map((accessorial) => formatAccesorial(accessorial, 'DESTINATION'))
				suggestedAccessorials = [...suggestedAccessorials, ...formatedAccessorials]
			}
			suggestedAccessorials = suggestedAccessorials.slice().map((item) => {
				const rItem = item
				if ('value_required' in rItem) {
					delete rItem.value_required
				}
				return rItem
			})
			const { status, resp: quoteDetails } = await dispatch(saveQuoteDetailsForReQuote(`/${quoteId}`))
			if (status === 'error') {
				sweetAlertModal(status, 'There was an error fetching the quote details!', null, 'Ok', true, false, null)
			} else {
				const payload = prepareDataForUpdateQuote(quoteDetails, suggestedAccessorials)
				const { status: status2, resp } = await dispatch(editQuote(quoteId, payload))
				if (status2 === 'error') {
					sweetAlertModal('error', 'There was an error in the saved accessorial!', resp, 'Ok', true, false)
				} else {
					const { status3, resp: quoteDetails2 } = await dispatch(saveQuoteDetailsForReQuote(`/${quoteId}`))
					if (status3 === 'error') {
						sweetAlertModal(status3, 'There was an error fetching the quote details!', null, 'Ok', true, false, null)
					} else {
						const newQuoteDetails = {
							...quoteDetails2,
							company_origin: shipper.name,
							address_origin: shipper.address,
							company_destination: consignee.name,
							address_destination: consignee.address,
						}
						dispatch(quoteForReQuote(newQuoteDetails))
						dispatch(
							updateDataForm({
								company_origin: shipper.name,
								address_origin: shipper.address,
								company_destination: consignee.name,
								address_destination: consignee.address,
							}),
						)
						setStep3Ready(true)
						setOpen(false)
					}
				}
			}
		}
		dispatch(resetShiplifyModalState())
	}

	const handleDelete = (accessorialId, origin) => () => {
		const filteredData = (data, deletionId) => data.filter((item) => item.freightProsAccessorialId !== deletionId)
		if (origin === 'ORIGIN') setShipperData(filteredData(shipperData, accessorialId))
		if (origin === 'DESTINATION') setConsigneeData(filteredData(consigneeData, accessorialId))
	}

	const SettingsProvider = () => {
		const callback = useCallback(async (params) => {
			const config = {
				headers: { 'X-Company-id': Number(actualCompany) },
			}
			const [error, data] = await reqGetSettingByName(params, config)
			if (error) return
			setCanUseShiplify(data?.value.toLowerCase() === 'true')
		}, [])
		return { callback }
	}
	const renderTypeAccesorial = () => {
		const localShipperData = Array.isArray(shipperData) ? shipperData : []
		const localConsigneeData = Array.isArray(consigneeData) ? consigneeData : []
		const allAccesorial = [...localShipperData, ...localConsigneeData]
		return allAccesorial.length === 1
			? `Our automated system has flagged one or both of these locations as likely requiring an additional service from the Carrier.
         We recommend adding the service listed below in order to avoid surprise charges after delivery.`
			: `Our automated system has flagged one or both of these locations as likely requiring additional services from the Carrier.
         We recommend adding the services listed below in order to avoid surprise charges after delivery.`
	}

	const { callback } = SettingsProvider()

	useEffect(() => {
		callback('ENABLE_SHIPLIFY_SERVICE')
	}, [callback])

	useEffect(() => {
		const config = {
			headers: { 'X-Company-id': Number(actualCompany) },
		}
		if (canUseShiplify && !ignoredByUser && !acceptedByUser) {
			const fetchShiplifySuggestion = async (body, conf) => {
				const { status, resp } = await dispatch(fetchShiplifySuggestions(body, conf))
				if (status === 'error') sweetAlertModal('error', 'Error', resp.message, 'Ok', true, null, false)
			}
			if (isValidObject(shipper) && isValidObject(consignee)) {
				const body = formatBody(shipper, consignee)
				body.quote.quote_id = quoteId
				fetchShiplifySuggestion(body, config)
			}
		}
	}, [dispatch, shipper, consignee, ignoredByUser, acceptedByUser, actualCompany, canUseShiplify, quoteId])

	useEffect(() => {
		if (isValidObject(suggestions)) {
			let shipperValue = suggestions?.shipper ?? []
			let consigneeValue = suggestions?.consignee ?? []
			if (shipperValue.length) {
				shipperValue.forEach((shipperEle) => {
					if (shipperEle.dockAccessValue === 'no' && shipperEle.forkliftValue === 'no' && avgVal > 50) {
						const actualOrigin = shipper?.accessorials ?? []
						const index = actualOrigin.findIndex((ind) => ind.accessorial_id === 4)
						if (index === -1) {
							shipperValue = [
								...shipperValue,
								{
									freightProsAccessorialId: 4,
									freightProsAccessorialName: 'LIFT GATE ORIGIN',
									freightProsAccessorialCode: 'LFGO_FP',
								},
							]
						}
					}
				})
			}
			if (consigneeValue.length) {
				consigneeValue.forEach((consigneeEle) => {
					if (consigneeEle.dockAccessValue === 'no' && consigneeEle.forkliftValue === 'no' && avgVal > 50) {
						const actualDestination = consignee?.accessorials ?? []
						const index = actualDestination.findIndex((ind) => ind.accessorial_id === 1)
						if (index === -1) {
							consigneeValue = [
								...consigneeValue,
								{
									freightProsAccessorialId: 1,
									freightProsAccessorialName: 'LIFT GATE DELIVERY',
									freightProsAccessorialCode: 'LFGD_FP',
								},
							]
						}
					}
				})
			}
			if (hasValidId(shipperValue) || hasValidId(consigneeValue)) {
				if (
					isExistDistinctAccesorial(
						removeDuplicatedId(removeCeroIdAccessorial(shipperValue)),
						removeDuplicatedId(removeCeroIdAccessorial(consigneeValue)),
					)
				) {
					setShipperData(removeDuplicatedId(getDistinctAccesorialData(shipperValue, 'Origin')))
					setConsigneeData(removeDuplicatedId(getDistinctAccesorialData(consigneeValue, 'Destination')))
					setOpen(true)
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [suggestions])

	return (
		<Dialog
			classes={{ paper: classes.paper }}
			aria-labelledby="shiplify-dialog-title"
			open={open}
			disableBackdropClick
			disableEscapeKeyDown
			maxWidth="sm"
			onExited={onExited}
		>
			<DialogTitle id="shiplify-dialog-title" className={classes.dialogTitle} disableTypography>
				<h2>Location Fee Warning</h2>
			</DialogTitle>
			<DialogContent className={classes.dialogContent}>
				<p>{renderTypeAccesorial()}</p>
				<Grid container>
					<Grid item md={6}>
						<div className={classes.dialogLabel}>Origin</div>
						<ul className={classes.chipList}>
							{shipperData.length > 0 ? (
								shipperData.map(({ freightProsAccessorialId, freightProsAccessorialName }) => (
									<li key={`shipper-id-${freightProsAccessorialId}`}>
										<Chip
											classes={{ root: classes.chip, deleteIcon: classes.chipDeleteIcon }}
											label={freightProsAccessorialName.toLowerCase()}
											onDelete={handleDelete(freightProsAccessorialId, 'ORIGIN')}
										/>
									</li>
								))
							) : (
								<Typography>No action needed</Typography>
							)}
						</ul>
					</Grid>
					<Grid item md={6}>
						<div className={classes.dialogLabel}>Destination</div>
						<ul className={classes.chipList}>
							{consigneeData.length > 0 ? (
								consigneeData.map(({ freightProsAccessorialId, freightProsAccessorialName }) => (
									<li key={`shipper-id-${freightProsAccessorialId}`}>
										<Chip
											classes={{ root: classes.chip, deleteIcon: classes.chipDeleteIcon }}
											label={freightProsAccessorialName.toLowerCase()}
											onDelete={handleDelete(freightProsAccessorialId, 'DESTINATION')}
										/>
									</li>
								))
							) : (
								<Typography>No action needed</Typography>
							)}
						</ul>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions className={classes.dialogActions} disableSpacing>
				<Button
					className={classes.dialogButton}
					color="secondary"
					variant="contained"
					disableElevation
					disabled={!(consigneeData.length > 0 || shipperData.length > 0)}
					onClick={handleAccept}
				>
					Add Accessorials
				</Button>
				<Button className={classes.dialogButton} variant="contained" disableElevation onClick={handleIgnore}>
					Ignore
				</Button>
			</DialogActions>
		</Dialog>
	)
}

ShiplifySuggestionModal.propTypes = {
	activeStep: PropTypes.number.isRequired,
	shipper: PropTypes.objectOf(PropTypes.any).isRequired,
	consignee: PropTypes.objectOf(PropTypes.any).isRequired,
	setOriginAccessorialsSelected: PropTypes.func,
	setDefaultValueOriginAccs: PropTypes.func,
	dispatchSetOriginAccessorials: PropTypes.func,
	setDestinationAccessorialsSelected: PropTypes.func,
	setDefaultValueDestinationAccs: PropTypes.func,
	dispatchSetDestinationAccessorials: PropTypes.func,
	setActiveStep1: PropTypes.func,
	quoteId: PropTypes.number,
}

ShiplifySuggestionModal.defaultProps = {
	quoteId: 0,
	setOriginAccessorialsSelected: () => {},
	setDefaultValueOriginAccs: () => {},
	dispatchSetOriginAccessorials: () => {},
	setDestinationAccessorialsSelected: () => {},
	setDefaultValueDestinationAccs: () => {},
	dispatchSetDestinationAccessorials: () => {},
	setActiveStep1: () => {},
}

export default ShiplifySuggestionModal
