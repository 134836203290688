import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Menu, MenuItem, withStyles, ListItemIcon, ListItemText } from '@material-ui/core'
import SendIcon from '@material-ui/icons/Send'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import moment from 'moment'

import { useSelector, useDispatch } from 'react-redux'

import { DownloadIcon } from '../../../../assets/Icons/Icons'

import { downloadBulkInvoices } from '../../../../redux/actions/Invoices.actions'
import sweetAlertModal from '../../../../components/SweetAlertModal/SweetAlertModal'
import SendInvoicesModal from '../SendInvoicesModal'

import useStyles from './style'

const StyledMenuItem = withStyles(() => ({
	root: {
		'&:hover': {
			backgroundColor: '#F9D2CA',
		},
	},
}))(MenuItem)

const TableToolbar = (props) => {
	const { selected, permitInvoiceSendEmail, permitInvoiceDownload, showActions } = props
	const dispatch = useDispatch()
	const classes = useStyles()
	const invoices = useSelector((state) => state.invoices)
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)
	const [showEmailModal, setShowEmailModal] = useState(false)

	const handleOpenMenu = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleDownloadInvoices = async () => {
		const ids = selected.map(Number)
		const documentIds = {
			invoiceIds: ids,
		}

		const { status, resp } = await dispatch(downloadBulkInvoices(documentIds))
		if (status === 'error') {
			sweetAlertModal('error', 'There was an error downloading the invoices', null, 'Ok', true, false)
		} else {
			resp.forEach((data) => {
				const bytes = new Uint8Array(data.pdfBuffer.data)
				const blob = new Blob([bytes], { type: 'application/pdf' })
				const link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				const fileName = `invoice-${data.invoiceId}.pdf`
				link.download = fileName
				link.click()
			})
		}
	}
	const DownloadInvoicesAsCsv = () => {
		const header = [
			'INVOICE NUMBER',
			'INVOICE DATE',
			'DUE DATE',
			'TOTAL AMOUNT',
			'BALANCE DUE AMOUNT',
			'STATUS',
			'DAYS PAST DUE',
		].join(',')
		const ids = selected.map(Number)
		const values = invoices.invoices.reduce((acc, invoice) => {
			const existInvoiceId = ids.find((idVal) => idVal === invoice.id)
			if (existInvoiceId) {
				acc.push(
					Object.values({
						invoice_number: invoice.invoice_number,
						invoice_date: moment(invoice.invoice_date).utc().format('MM-DD-YYYY'),
						invoice_due_date: moment(invoice.invoice_due_date).utc().format('MM-DD-YYYY'),
						total_amount: `$${invoice.total_amount} USD`,
						balance_due_amount: `$${invoice.balance_due_amount} USD`,
						payment_status: invoice.payment_status,
						days_past_due: invoice.days_past_due ? invoice.days_past_due : '-',
					}).join(','),
				)
			}
			return acc
		}, [])
		const csvData = [header, ...values].join('\n')
		const blob = new Blob([csvData], { type: 'text/csv' })
		const URL = window.URL || window.webkitURL
		const dataUrl = URL.createObjectURL(blob)
		const downloadLink = document.createElement('a')
		const fileName = 'Invoicelist.csv'
		downloadLink.href = dataUrl
		downloadLink.download = fileName
		downloadLink.click()
	}

	return (
		<div>
			{(selected.length && showActions) > 0 ? (
				<div className={classes.toolBar}>
					<p className={classes.toolbarSelected}>{`(${selected.length} selected)`}</p>
					{permitInvoiceDownload ? (
						<div className={classes.toolbarItemIcon} role="presentation" onClick={() => handleDownloadInvoices()}>
							<DownloadIcon color="secondary" fontSize="small" style={{ marginRight: 7 }} />
							<p style={{ margin: 0 }}>Download invoices</p>
						</div>
					) : null}
					{permitInvoiceDownload ? (
						<div className={classes.toolbarItemIcon} role="presentation" onClick={() => DownloadInvoicesAsCsv()}>
							<DownloadIcon color="secondary" fontSize="small" style={{ marginRight: 7 }} />
							<p style={{ margin: 0 }}>Download invoices as csv</p>
						</div>
					) : null}
					{permitInvoiceSendEmail ? (
						<div className={classes.toolbarItemIcon} role="presentation" onClick={() => setShowEmailModal(true)}>
							<SendIcon color="secondary" fontSize="small" style={{ marginRight: 7, width: '20px' }} />
							<p style={{ margin: 0 }}>Send by email</p>
						</div>
					) : null}
					<MoreVertIcon style={{ color: '#2C2C2C' }} onClick={handleOpenMenu} className={classes.moreIcon} />
				</div>
			) : (
				<div className={classes.toolBar}>
					<p className={classes.toolbarSelected}>(Select invoices for bulk actions)</p>
				</div>
			)}
			<Menu id="long-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
				<StyledMenuItem
					onClick={() => {
						handleDownloadInvoices()
						handleClose()
					}}
				>
					<ListItemIcon className={classes.listItemIcon}>
						<DownloadIcon className={classes.icon} />
					</ListItemIcon>
					<ListItemText primary="Download invoices" />
				</StyledMenuItem>
				<StyledMenuItem
					onClick={() => {
						DownloadInvoicesAsCsv()
						handleClose()
					}}
				>
					<ListItemIcon className={classes.listItemIcon}>
						<DownloadIcon className={classes.icon} />
					</ListItemIcon>
					<ListItemText primary="Download invoices as csv" />
				</StyledMenuItem>
				<StyledMenuItem onClick={() => setShowEmailModal(true)}>
					<ListItemIcon className={classes.listItemIcon}>
						<SendIcon className={classes.icon} />
					</ListItemIcon>
					<ListItemText primary="Send by email" />
				</StyledMenuItem>
			</Menu>
			<SendInvoicesModal showEmailModal={showEmailModal} setShowEmailModal={setShowEmailModal} invoicesIds={selected} />
		</div>
	)
}

TableToolbar.defaultProps = {
	permitInvoiceDownload: false.isRequired,
	permitInvoiceSendEmail: false.isRequired,
	showActions: false.isRequired,
}

TableToolbar.propTypes = {
	selected: PropTypes.arrayOf(PropTypes.any).isRequired,
	permitInvoiceDownload: PropTypes.func,
	permitInvoiceSendEmail: PropTypes.func,
	showActions: PropTypes.bool,
}

export default TableToolbar
