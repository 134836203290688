import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: 20,
		[theme.breakpoints.up('md')]: {
			flexWrap: 'nowrap',
		},
	},
	title: {
		marginTop: 0,
		marginBottom: 6,

		'& h1': {
			margin: 0,
		},
	},
}))

export default useStyles
