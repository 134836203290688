import React from 'react'
import { useSelector } from 'react-redux'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { CircularProgress } from '@material-ui/core'

import { invoiceDetailTableCells } from '../../../../../utils/headCells'
import NoResultsFound from '../../../../../components/NoResultsFound'

import useStyles from './style'
import InvoiceDetailsRow from '../InvoiceDetailsRow'

const TableList = () => {
	const invoiceDetails = useSelector((state) => state.invoiceDetails)
	const invoiceItems = useSelector((state) => state.invoiceDetails.invoiceDetails.items)

	const classes = useStyles()

	const renderTableBody = () => {
		let data = []
		if (invoiceDetails.isLoading) {
			return (
				<TableRow>
					<TableCell align="center" colSpan={Object.keys(invoiceDetailTableCells).length}>
						<CircularProgress />
					</TableCell>
				</TableRow>
			)
		}
		data = invoiceItems && invoiceItems.map((row, index) => <InvoiceDetailsRow key={`id-${index + 1}`} data={row} />)
		if (data.length === 0) {
			return (
				<TableRow>
					<TableCell align="center" colSpan={Object.keys(invoiceDetailTableCells).length}>
						<NoResultsFound type="Invoice Items" />
					</TableCell>
				</TableRow>
			)
		}
		return data
	}
	return (
		<div className={classes.root}>
			<Paper className={classes.paper} elevation={0}>
				<TableContainer>
					<Table className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
						<TableHead>
							<TableRow>
								{invoiceDetailTableCells.map((cell, index) => (
									<TableCell key={`id-${index + 1}`} className={classes.tableCell}>
										{cell.label}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>{renderTableBody()}</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</div>
	)
}

TableList.propTypes = {}

export default TableList
