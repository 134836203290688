/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
import React from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { Security, LoginCallback, SecureRoute } from '@okta/okta-react'
import PrivacyStatement from '../../modules/baseLayout/pages/OktaLoginWidget/ExternalPages/PrivacyStatement/PrivacyStatement'
import Terms from '../../modules/baseLayout/pages/OktaLoginWidget/ExternalPages/Terms/Terms'
import OktaLoginPage from '../../modules/baseLayout/pages/OktaLoginWidget/OktaLoginPage'
import { oktaAuthConfig, oktaSignInConfig } from '../../hooks/useOktaConfig'
import Dashboard from '../../modules/dashboard/pages/Dashboard'

const oktaAuth = new OktaAuth(oktaAuthConfig)

const publicRoutes = () => {
	const history = useHistory()
	const restoreOriginalUri = async (_oktaAuth, originalUri) => {
		history.replace(toRelativeUrl(originalUri, window.location.origin))
	}
	return (
		<Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
			<Switch>
				<Route path="/privacy">
					<PrivacyStatement />
				</Route>
				<Route path="/terms">
					<Terms />
				</Route>
				<Route path="/login/callback" component={LoginCallback} />
				<SecureRoute path="/dash">
					<Dashboard />
				</SecureRoute>
				<Route path="/">
					<OktaLoginPage config={oktaSignInConfig} />
				</Route>
			</Switch>
		</Security>
	)
}

export default publicRoutes
