/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	TablePagination,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { usersNewAccountTableCells } from '../../../../../../../../../utils/headCells'
import useStyles from './styles'
import UsersListRows from '../UsersListRows'

const TableList = ({
	list,
	// setRemoveSelected,
	// removeSelected,
	page,
	setPage,
	handleChangeRowsPerPage,
	numberForPage,
	allItems,
}) => {
	// const isSelected = (name) => removeSelected.indexOf(name) !== -1;
	const classes = useStyles()
	// const [isChecked, setIsChecked] = useState(false);

	// useEffect(() => {
	//   if (isChecked) {
	//     setRemoveSelected([...list].map((item) => item.id));
	//   } else {
	//     setRemoveSelected([]);
	//   }
	// }, [isChecked]);

	const renderTableBody = () => {
		let data = []
		data = list.map((row) => {
			const { id } = row
			// const isItemSelected = isSelected(row.id);
			// const labelId = `enhanced-table-checkbox-${index}`;
			return (
				<UsersListRows
					key={id}
					// labelId={labelId}
					// isItemSelected={isItemSelected}
					data={row}
				/>
			)
		})
		return data
	}
	return (
		<div className={classes.root}>
			<Paper className={classes.paper} elevation={0}>
				<TableContainer>
					<Table className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
						<TableHead>
							<TableRow className={classes.tableRow}>
								{/* <TableCell padding="checkbox" className={classes.tableCell}>
                  <Checkbox
                    inputProps={{ 'aria-label': 'select all items' }}
                    indeterminate
                    className={classes.checkboxAll}
                    checked={isChecked && list.length > 0 && list.length === removeSelected.length}
                    color="secondary"
                    onChange={() => setIsChecked(!isChecked)}
                  />
                </TableCell> */}
								{usersNewAccountTableCells.map((cell) => (
									<TableCell key={`id-${cell.id}`} className={classes.tableCell}>
										{cell.label}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>{renderTableBody()}</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					classes={{
						root: classes.tablePaginator,
						toolbar: classes.tablePaginatorToolbar,
						actions: classes.tablePaginatorActions,
					}}
					rowsPerPageOptions={[10, 20, 50, 100]}
					component="div"
					count={allItems.length}
					rowsPerPage={+numberForPage}
					page={page}
					onChangePage={(event, newPage) => setPage(newPage)}
					onChangeRowsPerPage={handleChangeRowsPerPage}
				/>
			</Paper>
		</div>
	)
}

TableList.propTypes = {
	list: PropTypes.arrayOf(PropTypes.any).isRequired,
	// setRemoveSelected: PropTypes.func.isRequired,
	// removeSelected: PropTypes.arrayOf(PropTypes.any).isRequired,
	page: PropTypes.number.isRequired,
	setPage: PropTypes.func.isRequired,
	handleChangeRowsPerPage: PropTypes.func.isRequired,
	numberForPage: PropTypes.number.isRequired,
	allItems: PropTypes.arrayOf(PropTypes.any).isRequired,
}

export default TableList
