import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
	makeStyles,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	IconButton,
	Tooltip,
} from '@material-ui/core'

import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined'
import { HazIcon } from '../../../../../assets/Icons/Icons'

import EditItem from '../EditItem'
import HazmatModal from '../../../../../components/HazmatModal'

const useStyles = makeStyles({
	table: {
		minWidth: 700,
	},
	tableRow: {
		'&.Mui-selected, &.Mui-selected:hover': {
			backgroundColor: '#FFFFFF',
			border: '1.5px solid #F3846F',
			boxShadow: '0 0 10px 0px #F9D2CA',
		},
	},
	tableCell: {
		fontFamily: 'Open Sans',
		fontSize: '14px',
		fontWeight: '600',
	},
	tableCellHead: {
		backgroundColor: '#F4F6F6',
	},
	tableCellNumber: {
		fontFamily: 'Open Sans',
		fontSize: '14px',
		fontWeight: '700',
	},
})

function ShipmentItemsTable(props) {
	const classes = useStyles()
	const { shipmentItems, setShipmentItems } = props
	const [editItem, setEditItem] = useState(false)
	const [shipItem, setShipItem] = useState({})
	const [openHazmatModal, setOpenHazmatModal] = useState(false)
	const [itemHazmat, setItemHazmat] = useState({})

	return (
		<div>
			<TableContainer component={Paper}>
				<Table className={classes.table} aria-label="customized table">
					<TableHead>
						<TableRow className={classes.tableRow}>
							<TableCell align="center" className={classes.tableCellHead}>
								#
							</TableCell>
							<TableCell align="center" className={classes.tableCellHead}>
								Package Type
							</TableCell>
							<TableCell align="center" className={classes.tableCellHead}>
								Quantity
							</TableCell>
							<TableCell align="center" className={classes.tableCellHead}>
								FreightClass
							</TableCell>
							<TableCell align="center" className={classes.tableCellHead}>
								NMFC code
							</TableCell>
							<TableCell align="center" className={classes.tableCellHead}>
								Weight(lb)
							</TableCell>
							<TableCell align="center" className={classes.tableCellHead}>
								Length(In)
							</TableCell>
							<TableCell align="center" className={classes.tableCellHead}>
								Width(In)
							</TableCell>
							<TableCell align="center" className={classes.tableCellHead}>
								Height(In)
							</TableCell>
							<TableCell align="center" className={classes.tableCellHead}>
								Product Description
							</TableCell>
							<TableCell align="center" className={classes.tableCellHead} />
							<TableCell align="center" className={classes.tableCellHead}>
								Actions
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{shipmentItems.map((item, index) => (
							<TableRow key={`id-${index + 1}`}>
								<TableCell className={classes.tableCellNumber} component="th" scope="row">
									{String.fromCharCode(65 + index)}
								</TableCell>
								<TableCell className={classes.tableCell} align="center">
									{item.unit_type ? item.unit_type : '-'}
								</TableCell>
								<TableCell className={classes.tableCell} align="center">
									{item.pieces_value ? item.pieces_value : '-'}
								</TableCell>
								<TableCell className={classes.tableCell} align="center">
									{item.class ? item.class : '-'}
								</TableCell>
								<TableCell className={classes.tableCell} align="center">
									{item.nmfc ? `${item.nmfc}-${item.sub_nmfc}` : '-'}
								</TableCell>
								<TableCell className={classes.tableCell} align="center">
									{item.weight_value ? item.weight_value : '-'}
								</TableCell>
								<TableCell className={classes.tableCell} align="center">
									{item.dimensions_lenght ? item.dimensions_lenght : '-'}
								</TableCell>
								<TableCell className={classes.tableCell} align="center">
									{item.dimensions_width ? item.dimensions_width : '-'}
								</TableCell>
								<TableCell className={classes.tableCell} align="center">
									{item.dimensions_height ? item.dimensions_height : '-'}
								</TableCell>
								<TableCell className={classes.tableCell} align="center">
									{item.description ? item.description : '-'}
								</TableCell>
								<TableCell className={classes.tableCell} align="center">
									{item.is_haz_mat ? (
										<div>
											<Tooltip title="Hazmat such as chemicals, acid, waste with a UN number">
												<button
													style={{
														background: 'none',
														border: 'none',
														outline: 'none',
														cursor: 'pointer',
													}}
													type="button"
													onClick={() => {
														setItemHazmat(item)
														setOpenHazmatModal(true)
													}}
													aria-label="Update hazmat information"
												>
													<HazIcon color="primary" style={{ width: '25px', height: '25px' }} />
												</button>
											</Tooltip>
											{!item.haz_mat_number && (
												<p
													style={{
														color: '#ee4627',
														fontSize: '12px',
														margin: '0 0 0 8px',
													}}
												>
													Hazmat information missing
												</p>
											)}
										</div>
									) : (
										<Tooltip title="Hazmat such as chemicals, acid, waste with a UN number">
											{/* Todo: This button appears to do nothing. Should it do something? */}
											<button
												style={{ background: 'none', border: 'none', outline: 'none' }}
												type="button"
												aria-label="hazmat"
											>
												<HazIcon htmlColor="#CCD2D8" style={{ width: '25px', height: '25px' }} />
											</button>
										</Tooltip>
									)}
								</TableCell>
								<TableCell className={classes.tableCell} align="center">
									<Tooltip title="Edit">
										<IconButton
											onClick={() => {
												setEditItem(true)
												setShipItem(item)
											}}
										>
											<CreateOutlinedIcon style={{ color: '#FF3333' }} />
										</IconButton>
									</Tooltip>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			{editItem ? (
				<EditItem
					shipItem={shipItem}
					setEditItem={setEditItem}
					shipmentItems={shipmentItems}
					setShipmentItems={setShipmentItems}
				/>
			) : null}
			<HazmatModal
				isOpen={openHazmatModal}
				setOpenHazmatModal={setOpenHazmatModal}
				updateItem
				itemHazmat={itemHazmat}
				shipmentItems={shipmentItems}
				setShipmentItems={setShipmentItems}
			/>
		</div>
	)
}

ShipmentItemsTable.propTypes = {
	shipmentItems: PropTypes.arrayOf(PropTypes.any).isRequired,
	setShipmentItems: PropTypes.func.isRequired,
}

export default ShipmentItemsTable
