import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import {
	Button,
	Fade,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	SwipeableDrawer,
	TextField,
	useMediaQuery,
	useTheme,
} from '@material-ui/core'

import { CalendarTodayOutlined, ExpandMore, FilterList, Loop } from '@material-ui/icons'
import MomentUtils from '@date-io/moment'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import SessionContext from '../../../context/session'
import { getCarriers } from '../../../redux/actions/Carriers.actions'
import { setTabCurrentRoute, setTabTitle } from '../../../redux/actions/MultiTabs.actions'
import { reportsList, reportsRoute } from '../../../utils/constants'
import BarChart from '../components/BarChart'
import PieChart from '../components/PieChart'
import useStyles from './styles'
import {
	fetchCarrierUsageReports,
	fetchPerMonthAndYearRreports,
	fetchAverageWeightReport,
	fetchAverageDistanceReport,
} from '../../../redux/actions/Reports.actions'
import useQuery from '../../../hooks/useQuery'
// eslint-disable-next-line import/no-unresolved
import ModuleHeader from '../../../components/ModuleHeader/index'

const ReportsContainer = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const query = useQuery()
	const reportQuery = query.get('report')
	const carrierQuery = query.get('carrier')
	const theme = useTheme()
	const applyButton = useRef()
	const { getUserData } = useContext(SessionContext)
	const { actualCompany } = getUserData()
	const { carriers } = useSelector((state) => state.carriers)
	const { carrierUsage, perMonthAndYear, averageWeight, averageDistance } = useSelector((state) => state.reports)
	const [open, setOpen] = useState(false)
	const [selectedInitialDate, setSelectedInitialDate] = useState(moment().subtract(365, 'days'))
	const [selectedEndDate, setSelectedEndDate] = useState(moment())
	const [selectedCarrier, setSelectedCarrier] = useState('all')
	const [selectedReport, setSelectedReport] = useState('carrier-usage')
	const [reportChanged, setReportChanged] = useState('false')
	const matchesMD = useMediaQuery(theme.breakpoints.up('md'))
	const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
	const useCarriers = ['average-distance', 'average-weight', 'shipment-count']

	const CarriersListProvider = () => {
		const fetchCarrierList = useCallback(async () => {
			const config = {
				headers: { 'X-Company-id': Number(actualCompany) },
			}
			const { status } = await dispatch(getCarriers(config))
			if (status === 'error') console.log(status)
		}, [])
		return { fetchCarrierList }
	}

	const { fetchCarrierList } = CarriersListProvider()

	const ReportsProvider = () => {
		const fetchCarrierUsage = useCallback(async (queryParams) => {
			const { status } = await dispatch(fetchCarrierUsageReports(`?${queryParams}`))
			if (status === 'error') console.log(status)
		}, [])
		const fetchShipmentCount = useCallback(async (queryParams) => {
			const { status } = await dispatch(fetchPerMonthAndYearRreports(`?${queryParams}`))
			if (status === 'error') console.log(status)
		}, [])
		const fetchAverageWeight = useCallback(async (queryParams) => {
			const { status } = await dispatch(fetchAverageWeightReport(`?${queryParams}`))
			if (status === 'error') console.log(status)
		}, [])
		const fetchAverageDistance = useCallback(async (queryParams) => {
			const { status } = await dispatch(fetchAverageDistanceReport(`?${queryParams}`))
			if (status === 'error') console.log(status)
		}, [])
		return {
			fetchCarrierUsage,
			fetchShipmentCount,
			fetchAverageWeight,
			fetchAverageDistance,
		}
	}

	const { fetchCarrierUsage, fetchShipmentCount, fetchAverageWeight, fetchAverageDistance } = ReportsProvider()

	const showFilters = () => {
		setOpen(true)
	}

	const toggleDrawer = (openDrawer) => (event) => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return
		}
		setOpen(openDrawer)
	}

	const handleApplyFilters = () => {
		const neededCarrier = () => {
			if (useCarriers.includes(selectedReport)) {
				return selectedCarrier === 'all' || selectedCarrier === '' ? '' : selectedCarrier
			}
			return null
		}

		const p = {
			initial_date: moment(selectedInitialDate).format('YYYY-MM-DD'),
			end_date: moment(selectedEndDate).add(1, 'day').format('YYYY-MM-DD'),
			scac: neededCarrier(),
			status: 'DELIVERED',
		}

		Object.keys(p).forEach((key) => p[key] === null && delete p[key])

		const qs = Object.keys(p)
			.map((key) => `${key}=${p[key]}`)
			.join('&')
		switch (selectedReport) {
			case 'carrier-usage':
				fetchCarrierUsage(qs)
				break
			case 'shipment-count':
				fetchShipmentCount(qs)
				break
			case 'average-weight':
				fetchAverageWeight(qs)
				break
			case 'average-distance':
				fetchAverageDistance(qs)
				break
			default:
				break
		}
	}

	const handleResetFilters = () => {
		setSelectedInitialDate(moment().subtract(365, 'days'))
		setSelectedEndDate(moment())
		setSelectedCarrier('all')
	}

	const handleInitialDateChange = (date) => {
		setSelectedInitialDate(date)
	}

	const handleEndDateChange = (date) => {
		setSelectedEndDate(date)
	}

	const handleCarrierChange = (event) => {
		setSelectedCarrier(event.target.value)
	}

	const handleReportChange = (event) => {
		setReportChanged(false)
		setTimeout(() => setSelectedReport(event.target.value), theme.transitions.duration.leavingScreen)
		setTimeout(() => setReportChanged(true), theme.transitions.duration.enteringScreen)
	}

	useEffect(() => {
		const p = {
			report: reportQuery || '',
			carrier: carrierQuery || '',
		}
		Object.keys(p).forEach((key) => p[key] === '' && delete p[key])
		const qs = Object.keys(p)
			.map((key) => `${key}=${p[key]}`)
			.join('&')
		dispatch(setTabCurrentRoute(`${reportsRoute}?${qs}`))
		dispatch(setTabTitle('Reports'))
	}, [carrierQuery, dispatch, reportQuery])

	useEffect(() => {
		if (reportQuery !== null) setSelectedReport(reportQuery)
		if (carrierQuery !== null) setSelectedCarrier(carrierQuery)
		setTimeout(() => {
			applyButton.current.click()
		}, 200)
	}, [reportQuery, carrierQuery])

	useEffect(() => {
		fetchCarrierList()
	}, [fetchCarrierList])

	useEffect(() => {
		const p = {
			initial_date: moment().subtract(365, 'days').format('YYYY-MM-DD'),
			end_date: moment().add(1, 'day').format('YYYY-MM-DD'),
			status: 'DELIVERED',
		}
		const qs = Object.keys(p)
			.map((key) => `${key}=${p[key]}`)
			.join('&')
		fetchCarrierUsage(qs)
		fetchShipmentCount(qs)
		fetchAverageWeight(qs)
		fetchAverageDistance(qs)
	}, [fetchAverageDistance, fetchAverageWeight, fetchCarrierUsage, fetchShipmentCount])

	return (
		<Grid container spacing={matchesMD ? 2 : 1}>
			<Grid item xs={12}>
				<Grid container spacing={2}>
					<Grid item xs={10} md={12}>
						<ModuleHeader title="Reports" subtitle="Generate different reports according to your needs." />
					</Grid>
					{!matchesMD && (
						<Grid item xs={2}>
							<IconButton
								aria-label="Toggle Filters"
								className={classes.filterTriggerButton}
								size="small"
								onClick={showFilters}
							>
								<FilterList />
							</IconButton>
							<SwipeableDrawer
								classes={{ paper: classes.drawer }}
								anchor="top"
								open={open}
								onOpen={toggleDrawer(open, true)}
								onClose={toggleDrawer(false)}
								disableBackdropTransition={!iOS}
								disableDiscovery={!iOS}
							>
								<Grid container spacing={2}>
									{useCarriers.includes(selectedReport) && (
										<Grid item xs={12}>
											<FormControl className={classes.formControl} variant="outlined" size="small" fullWidth>
												<InputLabel id="carrier-select-label">Select a Carrier</InputLabel>
												<Select
													labelId="carrier-select-labele"
													id="carrier-select"
													value={selectedCarrier}
													onChange={handleCarrierChange}
													label="Select a Carrier"
													// eslint-disable-next-line react/jsx-props-no-spreading
													IconComponent={(props) => <ExpandMore {...props} />}
												>
													<MenuItem value="all">All Carriers</MenuItem>
													{carriers.map(({ name, scac, id }) => (
														<MenuItem value={scac} key={id}>
															{name}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Grid>
									)}
									<MuiPickersUtilsProvider utils={MomentUtils}>
										<Grid item xs={12}>
											<DatePicker
												id="initial-date-picker"
												label="Filter by initial date"
												KeyboardButtonProps={{
													'aria-label': 'change initial date',
													edge: 'end',
												}}
												color="secondary"
												TextFieldComponent={(props) => (
													<TextField
														// eslint-disable-next-line react/jsx-props-no-spreading
														{...props}
														InputProps={{
															endAdornment: <CalendarTodayOutlined color="secondary" fontSize="small" />,
														}}
													/>
												)}
												format="MM/DD/YYYY"
												fullWidth
												inputVariant="outlined"
												margin="dense"
												value={selectedInitialDate}
												onChange={handleInitialDateChange}
											/>
										</Grid>
										<Grid item xs={12}>
											<DatePicker
												id="end-date-picker"
												label="Filter by end date"
												KeyboardButtonProps={{
													'aria-label': 'change end date',
													edge: 'end',
												}}
												color="secondary"
												TextFieldComponent={(props) => (
													<TextField
														// eslint-disable-next-line react/jsx-props-no-spreading
														{...props}
														InputProps={{
															endAdornment: <CalendarTodayOutlined color="secondary" fontSize="small" />,
														}}
													/>
												)}
												format="MM/DD/YYYY"
												fullWidth
												inputVariant="outlined"
												margin="dense"
												value={selectedEndDate}
												onChange={handleEndDateChange}
											/>
										</Grid>
									</MuiPickersUtilsProvider>
									<Grid item xs="auto">
										<Button
											className={classes.filterApplyButton}
											size="small"
											startIcon={<Loop color="secondary" />}
											onClick={handleResetFilters}
										>
											Reset filters
										</Button>
									</Grid>
									<Grid item xs="auto">
										<Button
											ref={applyButton}
											className={classes.filterApplyButton}
											size="small"
											startIcon={<FilterList color="secondary" />}
											onClick={handleApplyFilters}
										>
											Apply Filters
										</Button>
									</Grid>
								</Grid>
							</SwipeableDrawer>
						</Grid>
					)}
				</Grid>
			</Grid>
			<Grid item xs={12}>
				{!matchesMD && (
					<FormControl className={classes.formControl} variant="outlined" size="small" fullWidth>
						<InputLabel id="report-select-label">Select type of report</InputLabel>
						<Select
							labelId="report-select-labele"
							id="report-select"
							value={selectedReport}
							onChange={handleReportChange}
							label="Select type of report"
							// eslint-disable-next-line react/jsx-props-no-spreading
							IconComponent={(props) => <ExpandMore {...props} />}
						>
							{reportsList.map(({ name, key }) => (
								<MenuItem key={key} value={key}>
									{name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				)}
				{matchesMD && (
					<Grid container alignItems="center" spacing={2}>
						<Grid item md="auto" lg={2}>
							<FormControl className={classes.formControl} variant="outlined" size="small" fullWidth>
								<InputLabel id="report-select-label">Select type of report</InputLabel>
								<Select
									labelId="report-select-labele"
									id="report-select"
									value={selectedReport}
									onChange={handleReportChange}
									label="Select type of report"
									// eslint-disable-next-line react/jsx-props-no-spreading
									IconComponent={(props) => <ExpandMore {...props} />}
								>
									{reportsList.map(({ name, key }) => (
										<MenuItem key={key} value={key}>
											{name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						{useCarriers.includes(selectedReport) && (
							<Grid item md="auto" lg={2}>
								<FormControl className={classes.formControl} variant="outlined" size="small" fullWidth>
									<InputLabel id="carrier-select-label">Select a Carrier</InputLabel>
									<Select
										labelId="carrier-select-labele"
										id="carrier-select"
										value={selectedCarrier}
										onChange={handleCarrierChange}
										label="Select a Carrier"
										// eslint-disable-next-line react/jsx-props-no-spreading
										IconComponent={(props) => <ExpandMore {...props} />}
									>
										<MenuItem value="all">All Carriers</MenuItem>
										{carriers.map(({ name, scac, id }) => (
											<MenuItem value={scac} key={id}>
												{name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						)}
						<MuiPickersUtilsProvider utils={MomentUtils}>
							<Grid item md="auto" lg={2}>
								<DatePicker
									id="initial-date-picker"
									label="Filter by initial date"
									KeyboardButtonProps={{
										'aria-label': 'change initial date',
										edge: 'end',
									}}
									color="secondary"
									TextFieldComponent={(props) => (
										<TextField
											// eslint-disable-next-line react/jsx-props-no-spreading
											{...props}
											InputProps={{
												endAdornment: <CalendarTodayOutlined color="secondary" fontSize="small" />,
											}}
										/>
									)}
									format="MM/DD/YYYY"
									fullWidth
									inputVariant="outlined"
									margin="dense"
									value={selectedInitialDate}
									onChange={handleInitialDateChange}
								/>
							</Grid>
							<Grid item md="auto" lg={2}>
								<DatePicker
									id="end-date-picker"
									label="Filter by end date"
									KeyboardButtonProps={{
										'aria-label': 'change end date',
										edge: 'end',
									}}
									color="secondary"
									TextFieldComponent={(props) => (
										<TextField
											// eslint-disable-next-line react/jsx-props-no-spreading
											{...props}
											InputProps={{
												endAdornment: <CalendarTodayOutlined color="secondary" fontSize="small" />,
											}}
										/>
									)}
									format="MM/DD/YYYY"
									fullWidth
									inputVariant="outlined"
									margin="dense"
									value={selectedEndDate}
									onChange={handleEndDateChange}
								/>
							</Grid>
						</MuiPickersUtilsProvider>
						<Grid item md="auto">
							<Button
								className={classes.filterApplyButton}
								size="small"
								startIcon={<Loop color="secondary" />}
								onClick={handleResetFilters}
							>
								Reset filters
							</Button>
						</Grid>
						<Grid item md="auto">
							<Button
								ref={applyButton}
								className={classes.filterApplyButton}
								size="small"
								startIcon={<FilterList color="secondary" />}
								onClick={handleApplyFilters}
							>
								Apply Filters
							</Button>
						</Grid>
					</Grid>
				)}
			</Grid>
			<Fade in={reportChanged}>
				<Grid item xs={12}>
					{selectedReport === 'carrier-usage' ? (
						<PieChart
							data={carrierUsage?.data ?? []}
							title="Carrier Usage (%)"
							filters={{
								initialDate: moment(selectedInitialDate).format('YYYY-MM-DD'),
								endDate: moment(selectedEndDate).add(1, 'day').format('YYYY-MM-DD'),
							}}
						/>
					) : null}
					{selectedReport === 'shipment-count' ? (
						<BarChart
							data={perMonthAndYear?.data ?? []}
							title="Shipment Count"
							color={theme.palette.secondary.main}
							filters={{
								initialDate: moment(selectedInitialDate).format('YYYY-MM-DD'),
								endDate: moment(selectedEndDate).add(1, 'day').format('YYYY-MM-DD'),
								carrier: selectedCarrier,
							}}
							selectedReport={selectedReport}
						/>
					) : null}
					{selectedReport === 'average-weight' ? (
						<BarChart
							data={averageWeight?.data ?? []}
							title="Average Weight"
							color={theme.palette.semantic.purple.primary}
							average
							filters={{
								initialDate: moment(selectedInitialDate).format('YYYY-MM-DD'),
								endDate: moment(selectedEndDate).add(1, 'day').format('YYYY-MM-DD'),
								carrier: selectedCarrier,
							}}
							selectedReport={selectedReport}
						/>
					) : null}
					{selectedReport === 'average-distance' ? (
						<BarChart
							data={averageDistance?.data ?? []}
							title="Average Distance"
							color={theme.palette.secondary.main}
							average
							filters={{
								initialDate: moment(selectedInitialDate).format('YYYY-MM-DD'),
								endDate: moment(selectedEndDate).add(1, 'day').format('YYYY-MM-DD'),
								carrier: selectedCarrier,
							}}
							selectedReport={selectedReport}
						/>
					) : null}
				</Grid>
			</Fade>
		</Grid>
	)
}

export default ReportsContainer
