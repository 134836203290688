import React, { useEffect, useState, useContext, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import Chip from '@material-ui/core/Chip'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Grid from '@material-ui/core/Grid'
import { accountDetailsRoute, accountsListRoute, permissionName } from '../../../../../utils/constants'
import isPermit from '../../../../../utils/permission'
import MenuList from '../../components/MenuList'
import MenuTabs from '../../components/MenuTabs'
import useStyles from './styles'
import { setTabCurrentRoute, setTabTitle, setTabChildAccounts } from '../../../../../redux/actions/MultiTabs.actions'
import { clearQuoteDetailsForReQuote } from '../../../../../redux/actions/QuoteDetails.actions'
import { fetchAccountInformation, editAccountStatus } from '../../../../../redux/actions/Accounts.actions'

import SweetAlertModal from '../../../../../components/SweetAlertModal'
import SessionContext from '../../../../../context/session'
// eslint-disable-next-line import/no-unresolved
import ModuleHeader from '../../../../../components/ModuleHeader/index'

const AccountDetailsContainer = () => {
	const { id } = useParams()
	const classes = useStyles()
	const dispatch = useDispatch()
	const [anchorEl, setAnchorEl] = useState(null)
	const [valueTab, setValueTab] = useState(0)
	const [noActions, setNoActions] = useState(false)
	const handleChangeTab = (newValue) => {
		setValueTab(newValue)
	}
	const open = Boolean(anchorEl)
	const handleOpenMenu = (event) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	useEffect(() => {
		const setRoute = accountDetailsRoute.replace(':id', id)
		dispatch(setTabCurrentRoute(setRoute))
		dispatch(setTabTitle('Accounts Details'))
		dispatch(clearQuoteDetailsForReQuote(''))
	}, [dispatch, id])

	const [basicInformation, setBasicInformation] = useState({})
	const [childAccounts, setChildAccounts] = useState([])
	const { getUserData } = useContext(SessionContext)
	const { permissions } = getUserData()

	const config = useMemo(() => {
		const { actualCompany } = getUserData()
		const newConfig = {
			headers: {
				'X-Company-id': id || Number(actualCompany),
			},
		}
		return newConfig
	}, [getUserData, id])

	useEffect(() => {
		const fetchData = async () => {
			const { status, resp } = await dispatch(fetchAccountInformation(config, id))
			if (status === 'success') {
				const { parentCompany, childrenCompanies } = resp
				setBasicInformation(parentCompany)
				const newChildrenCompanies = childrenCompanies.map((childrenCompany) => ({
					...childrenCompany,
					accountNumber: childrenCompany.account_number,
					salesForceNumber: childrenCompany.sales_force_number,
				}))
				setChildAccounts(newChildrenCompanies)
				const childAccountsId = childrenCompanies.map((childrenCompany) => childrenCompany.id)
				const dataStore = {
					parentCompanyId: id,
					childrenCompaniesIds: childAccountsId,
				}
				dispatch(setTabChildAccounts(dataStore))
			} else {
				SweetAlertModal('error', 'There was an error fetching the data', resp, 'Ok', true, false, null)
			}
		}
		fetchData()
	}, [id, dispatch, config])

	useEffect(() => {
		setNoActions(
			!!(
				isPermit(permissions, permissionName.FP_ADMIN_ACCOUNTS_UPDATE) ||
				isPermit(permissions, permissionName.FP_ADMIN_ACCOUNTS_DEACTIVATE)
			),
		)
	}, [permissions, noActions])

	const handleAccountStatusEdit = async () => {
		const { status } = await dispatch(
			editAccountStatus({ id: Number(id), status: !basicInformation.is_active }, config),
		)
		if (status === 'error') {
			SweetAlertModal(status, 'There was an error updating the company status', null, 'Ok', true, false)
			handleClose()
		} else {
			const { status: statusFetch, resp } = await dispatch(fetchAccountInformation(config, id))
			if (statusFetch === 'success') {
				const { parentCompany, childrenCompanies } = resp
				setBasicInformation(parentCompany)
				const newChildrenCompanies = childrenCompanies.map((childrenCompany) => ({
					...childrenCompany,
					accountNumber: childrenCompany.account_number,
					salesForceNumber: childrenCompany.sales_force_number,
				}))
				setChildAccounts(newChildrenCompanies)
				SweetAlertModal(statusFetch, 'Account was successfully updated!', null, 'Ok', true, false)
			} else {
				SweetAlertModal(statusFetch, 'There was an error fetching the data', resp, 'Ok', true, false, null)
			}
			handleClose()
		}
	}

	return (
		<div className={classes.detailsContainer}>
			<Grid container spacing={2}>
				<Grid className={classes.accountDetailContainer}>
					<div className={classes.accountHeader}>
						<div className={classes.accountHeaderInformation}>
							<ModuleHeader
								title={
									<div className={classes.accountTitleRow}>
										<h1 className={classes.accountTitleText}>{basicInformation.name}</h1>
										<Chip
											className={basicInformation.is_active ? classes.chipActive : classes.chipInactive}
											label={basicInformation.is_active ? 'Active' : 'Inactive'}
										/>
									</div>
								}
								subtitle={`This is the ${basicInformation.name} information`}
							/>
						</div>
						{noActions && (
							<div className={classes.accountOptions}>
								<IconButton
									aria-label="toggle password visibility"
									onClick={handleOpenMenu}
									onMouseDown={null}
									edge="end"
								>
									<MoreVertIcon style={{ color: '#2C2C2C' }} />
								</IconButton>
								<MenuList
									anchorEl={anchorEl}
									open={open}
									handleClose={handleClose}
									handleAccountStatusEdit={handleAccountStatusEdit}
									isActive={basicInformation.is_active}
									activeStep={valueTab}
								/>
							</div>
						)}
					</div>
				</Grid>
				<MenuTabs
					handleChangeTab={(e) => handleChangeTab(e)}
					value={valueTab}
					accountInformationParent={basicInformation}
					accountInformationChilds={childAccounts}
				/>
				<div className={classes.buttons}>
					<Link className={classes.linkButton} to={accountsListRoute}>
						<span className="text-transform">Go back</span>
					</Link>
				</div>
			</Grid>
		</div>
	)
}

export default AccountDetailsContainer
