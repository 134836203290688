/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useContext } from 'react'
import { useParams, useHistory, Prompt, useLocation } from 'react-router-dom'
import Chip from '@material-ui/core/Chip'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Button from '@material-ui/core/Button'
import AutorenewIcon from '@material-ui/icons/Autorenew'
import Grid from '@material-ui/core/Grid'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { reqShipmentId, reqCloneShipment } from '../../../../redux/requests/Shipment.requests'
import { createQuotesRoute, shipmentsListRoute, permissionName, shipmentDetails } from '../../../../utils/constants'
import { capitalizeFirstLetter } from '../../../../utils/helpers'
import isPermit from '../../../../utils/permission'
import MenuList from '../components/MenuList'
import MenuTabs from '../components/MenuTabs'
import SessionContext from '../../../../context/session'
import sweetAlertModal from '../../../../components/SweetAlertModal/SweetAlertModal'
import useStyles from './style'
import { saveQuoteDetailsForReQuote } from '../../../../redux/actions/QuoteDetails.actions'
import { setTabs } from '../../../../redux/actions/MultiTabs.actions'
import { setTabShipmentDetail } from '../../../../redux/actions/TabShipmentDetail.actions'
import ModuleHeader from '../../../../components/ModuleHeader'
import removeZDate from '../../../../utils/removeZDate'

const getLocalTapShipmentDetail = (list, url) => {
	const data = list.find((item) => item.route === url)
	const result = data?.tab ?? 0
	return result
}

const ShipmentDetails = () => {
	const currentTabs = useSelector((state) => state.multiTabs.tabs)
	const tabShipmentDetails = useSelector((state) => state.tabShipmentDetail.tab)
	const classes = useStyles()
	const dispatch = useDispatch()
	const history = useHistory()
	const location = useLocation()
	const [anchorEl, setAnchorEl] = useState(null)
	const [shipment, setShipment] = useState(false)
	const [valueTab, setValueTab] = useState(+getLocalTapShipmentDetail(tabShipmentDetails, location.pathname))
	const [status, setStatus] = useState('')
	const [bolIdentifier, setBolIdentifier] = useState('')
	const [noActions, setNoActions] = useState(false)
	const [isExit, setIsExit] = useState(false)
	const { getUserData } = useContext(SessionContext)
	const { permissions } = getUserData()
	const handleChangeTab = (newValue) => {
		const localTabShipmentDetails = [...tabShipmentDetails]
		const index = localTabShipmentDetails.findIndex((val) => val.route === location.pathname)
		if (index === -1) {
			localTabShipmentDetails.push({ route: location.pathname, tab: newValue })
		} else {
			localTabShipmentDetails.splice(index, 1, { route: location.pathname, tab: newValue })
		}
		dispatch(setTabShipmentDetail(localTabShipmentDetails))
	}
	useEffect(() => {
		setValueTab(+getLocalTapShipmentDetail(tabShipmentDetails, location.pathname))
	}, [tabShipmentDetails, location.pathname])
	const open = Boolean(anchorEl)
	const { id } = useParams()
	const handleOpenMenu = (event) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}
	const parseItems = (items) => {
		const responseItems = []
		for (let index = 0; index < items.length; index += 1) {
			const element = items[index]
			responseItems.push({
				id: element.id,
				dimensions_lenght: Number(element.length),
				dimensions_width: Number(element.width),
				dimensions_height: Number(element.height),
				pieces_value: Number(element.pieces),
				weight_value: Number(element.weight),
				class: element.freight_class,
				nmfc: Number(element.nmfc),
				sub_nmfc: Number(element.sub_nmfc),
				description: element.commodity,
				details: element.commodity,
				is_haz_mat: element.is_hazardous,
				stackable: element.is_stackable,
				unit_type: element.units,
				package_type: element.pieces_units,
				item_details: element.item_details,
			})
		}
		return responseItems
	}
	const parseReferences = (references) => {
		const responseReferences = []
		for (let index = 0; index < references.length; index += 1) {
			const element = references[index]
			responseReferences.push({
				type: element.type,
				value: element.value,
			})
		}
		return responseReferences
	}
	useEffect(() => {
		async function fetchData() {
			const [error, data] = await reqShipmentId(id)
			if (!error) {
				const getShipment = data.data.data.shipment
				setStatus(getShipment.status)
				setBolIdentifier(getShipment.bol_identifier)
				const payload = {
					id: getShipment.id,
					quoteId: getShipment.quote_id,
					rateId: getShipment.rate_id,
					bol_identifier: getShipment?.bol_identifier ?? '',
					consigneeCoordinates: {
						latitude: getShipment?.consignee_latitude ?? 0,
						longitude: getShipment?.consignee_longitude ?? 0,
					},
					shipperCoordinates: {
						latitude: getShipment?.shipper_latitude ?? 0,
						longitude: getShipment?.shipper_longitude ?? 0,
					},
					type: getShipment.type,
					shipper: {
						shipperId: getShipment.shipper_id,
						companyName: getShipment.shipper_name,
						address1: getShipment.shipper_address,
						address2: getShipment?.shipper_address2 ?? '',
						contactName: getShipment.shipper_contact,
						contactPhone: getShipment.shipper_phone,
						contactPhoneExtension: getShipment.shipper_phone_extension,
						contactEmail: getShipment.shipper_email,
						sendConfirmationEmail: 'confirmationEmail',
						zipCode: getShipment.shipper_zip_code,
						city: getShipment.shipper_city,
						state: getShipment.shipper_state,
						country: getShipment.shipper_country,
						pickUpDate: getShipment.pickup_date,
						pickupEarliestTime: moment(removeZDate(getShipment.pickup_time_early)).format('hh:mm A'),
						pickupLatestTime: moment(removeZDate(getShipment.pickup_time_latest)).format('hh:mm A'),
						accesorials: getShipment.accesorials
							.filter((element) => element?.origin_destination === 'ORIGIN')
							.map((element) => capitalizeFirstLetter(element.name)),
					},
					consignee: {
						consigneeId: getShipment.consignee_id,
						companyName: getShipment.consignee_name,
						address1: getShipment.consignee_address,
						address2: getShipment?.consignee_address2 ?? '',
						contactName: getShipment.consignee_contact,
						contactPhone: getShipment.consignee_phone,
						contactPhoneExtension: getShipment.consignee_phone_extension,
						contactEmail: getShipment.consignee_email,
						zipCode: getShipment.consignee_zip_code,
						city: getShipment.consignee_city,
						state: getShipment.consignee_state,
						country: getShipment.consignee_country,
						pickUpDate: getShipment.drop_date,
						dropOffEarliestTime: moment(removeZDate(getShipment.drop_time_early)).format('hh:mm A'),
						dropOffLatestTime: moment(removeZDate(getShipment.drop_time_latest)).format('hh:mm A'),
						accesorials: getShipment.accesorials
							.filter((element) => element?.origin_destination === 'DESTINATION')
							.map((element) => capitalizeFirstLetter(element.name)),
					},
					items: parseItems(getShipment.items),
					specialInstructions: {
						comments: getShipment.special_instructions_comments ?? '',
					},
					references: parseReferences(getShipment.references),
					carrierData: getShipment.rate,
					contractData: {
						whoAmI: getShipment.rate.contract_type,
						contractId: getShipment.contract_company_id,
					},
					carrier_documents: getShipment.carrier_documents,
					bol_url_file: getShipment.bol_url_file,
					label_url_file: getShipment.label_url_file,
					documents: getShipment.documents,
				}
				setShipment(payload)
			}
		}
		fetchData()
	}, [id])

	useEffect(() => {
		setNoActions(
			!!(
				isPermit(permissions, permissionName.SHIPMENTS_DOWNLOAD_BOL) &&
				isPermit(permissions, permissionName.SHIPMENTS_DOWNLOAD_LABEL) &&
				isPermit(permissions, permissionName.SHIPMENTS_CONTACT_SUPPORT) &&
				isPermit(permissions, permissionName.SHIPMENTS_CLONE)
			),
		)
	}, [permissions, noActions])

	const renderStatus = (shipmentStatus) => {
		const statusesColors = {
			BOOKED: classes.statusGreen,
			CANCELED: classes.statusRed,
			CONVERTED: classes.statusBlue,
			EXPIRED: classes.statusRed,
			PENDING: classes.statusGray,
			DELIVERED: classes.statusOrange,
			'IN TRANSIT': classes.statusBlue,
			'ON HOLD': classes.statusOrange,
		}
		return (
			shipmentStatus && (
				<Chip
					size="small"
					className={[classes.status, statusesColors[status]].join(' ')}
					label={shipmentStatus.charAt(0) + shipmentStatus.slice(1).toLowerCase()}
				/>
			)
		)
	}

	const cloneShipmentToNewQuote = async () => {
		let quoteCloned = {}
		const [error, data] = await reqCloneShipment(id)
		if (!error) {
			const quoteId = data?.data?.data
			quoteCloned = {
				quoteId,
			}
		} else {
			sweetAlertModal('error', 'There was an error cloning the shipment', null, 'Ok', true, false)
		}
		return quoteCloned
	}

	const deletefirstTab = () => {
		const tabsFiltered = [...currentTabs].filter((item) => item.route.includes(shipmentDetails.replace(':id', '')))
		dispatch(setTabs(tabsFiltered))
		const localTabShipmentDetail = [...tabShipmentDetails]
		const index = localTabShipmentDetail.findIndex((val) => val.route === location.pathname)
		if (index !== -1) {
			localTabShipmentDetail.splice(index, 1)
			dispatch(setTabShipmentDetail(localTabShipmentDetail))
		}
	}

	const handleCloneShipment = async () => {
		const originalArrayTab = [...currentTabs]
		deletefirstTab()
		const dataReady = await cloneShipmentToNewQuote()
		if (Object.keys(dataReady).length > 0) {
			const { status: status2 } = await dispatch(saveQuoteDetailsForReQuote(`/${dataReady.quoteId}`))
			if (status2 === 'error') {
				sweetAlertModal(status2, 'There was an error fetching the quote details!', null, 'Ok', true, false, null)
				dispatch(setTabs(originalArrayTab))
			} else {
				history.push(createQuotesRoute)
			}
		}
	}
	const handleGoBack = () => {
		deletefirstTab()
		history.push(shipmentsListRoute)
	}
	return (
		<>
			<Prompt
				when
				message={(_location) => {
					const locationTabs = currentTabs.find((item) => item.route === `${_location.pathname}${_location.search}`)
					if (locationTabs || isExit) {
						return true
					}
					return false
				}}
			/>
			<div className={classes.detailsContainer}>
				<Grid container style={{ maxWidth: '100%', margin: '-5px' }} spacing={2}>
					<Grid className={classes.shipmentDetailContainer}>
						<div className={classes.shipmentHeader}>
							<ModuleHeader
								title={
									<div className={classes.shipmentTitleRow}>
										<h1>{`Shipment Detail ${bolIdentifier}`}</h1>
										{renderStatus(status)}
									</div>
								}
								subtitle="The information below corresponds to the status of your shipment."
							/>
							{!noActions ? (
								<div className={classes.shipmentOptions}>
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleOpenMenu}
										onMouseDown={null}
										edge="end"
										size="small"
									>
										<MoreVertIcon style={{ color: '#2C2C2C' }} />
									</IconButton>
									<MenuList
										anchorEl={anchorEl}
										open={open}
										handleClose={handleClose}
										handleCloneShipment={handleCloneShipment}
										shipmentId={id}
										bolIdentifier={bolIdentifier}
									/>
								</div>
							) : null}
						</div>
					</Grid>
					<MenuTabs shipment={shipment} handleChangeTab={(e) => handleChangeTab(e)} value={valueTab} />
					<div className={classes.buttons}>
						<Button
							color="secondary"
							variant="contained"
							className={classes.linkButton}
							onMouseDown={() => setIsExit(true)}
							onClick={() => handleGoBack()}
						>
							<span className="text-transform">Go back</span>
						</Button>
						{isPermit(permissions, permissionName.SHIPMENTS_CLONE) && (
							<Button
								className={classes.linkButtonIcon}
								variant="text"
								color="primary"
								size="large"
								startIcon={<AutorenewIcon />}
								onClick={handleCloneShipment}
								onMouseDown={() => setIsExit(true)}
							>
								<span className="text-transform">Clone the Shipment</span>
							</Button>
						)}
					</div>
				</Grid>
			</div>
		</>
	)
}

export default ShipmentDetails
