import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { useTheme } from '@material-ui/core/styles'
import { CircularProgress, Checkbox, TablePagination, TableSortLabel } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { accountsTableCells } from '../../../../../utils/headCells'
import NoResultsFound from '../../../../../components/NoResultsFound'
import TableToolbar from '../TableToolbar'

import SessionContext from '../../../../../context/session'
import { permissionName } from '../../../../../utils/constants'
import isPermit from '../../../../../utils/permission'

import useStyles from './styles'
import AccountListRow from '../AccountListRow'
import {
	setCurrentPage,
	setRowsPerPage,
	setOrderBy,
	setOrderType,
} from '../../../../../redux/actions/AccountsFilters.actions'

const TableList = ({ data, loading, count }) => {
	const classes = useStyles()
	const theme = useTheme()
	const dispatch = useDispatch()
	const accountsFilters = useSelector((state) => state.accountsFilters)
	const [selected, setSelected] = useState([])
	const [page, setPage] = useState(0)
	const [localRowsPerPage, setLocalRowsPerPage] = useState(25)
	const isSelected = (name) => selected.indexOf(name) !== -1
	const { getUserData } = useContext(SessionContext)
	const { permissions } = getUserData()

	const handleChangeRowsPerPage = (event) => {
		dispatch(setRowsPerPage(event.target.value))
		dispatch(setCurrentPage(0))
		setLocalRowsPerPage(event.target.value)
		setPage(0)
	}

	const handleChangePage = (event, newPage) => {
		const nextSet =
			page < newPage
				? accountsFilters.currentPage + accountsFilters.rowsPerPage
				: accountsFilters.currentPage - accountsFilters.rowsPerPage
		dispatch(setCurrentPage(nextSet))
		setPage(newPage)
	}

	const handleSelectAllClick = (event) => {
		const tempData = data

		if (event.target.checked) {
			const newSelecteds = tempData.map((item) => item.id)
			setSelected(newSelecteds)
			return
		}
		setSelected([])
	}

	const renderTableBody = () => {
		let tableData = []
		if (loading) {
			return (
				<TableRow>
					<TableCell align="center" colSpan={Object.keys(accountsTableCells).length + 1}>
						<CircularProgress />
					</TableCell>
				</TableRow>
			)
		}
		tableData = data.map((row, index) => {
			const isItemSelected = isSelected(row.id)
			const labelId = `accounts-table-checkbox-${index}`

			return (
				<AccountListRow
					labelId={labelId}
					isItemSelected={isItemSelected}
					data={row}
					selected={selected}
					setSelected={setSelected}
					canActivate={isPermit(permissions, permissionName.FP_ADMIN_ACCOUNTS_DEACTIVATE)}
				/>
			)
		})
		if (tableData.length === 0) {
			return (
				<TableRow>
					<TableCell align="center" colSpan={Object.keys(accountsTableCells).length + 1}>
						<NoResultsFound type="Accounts" />
					</TableCell>
				</TableRow>
			)
		}
		return tableData
	}

	return (
		<>
			<TableToolbar selected={selected} setSelected={setSelected} />
			<Paper className={classes.paper} elevation={0}>
				<TableContainer>
					<Table className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="Accounts Table">
						<TableHead>
							<TableRow className={classes.tableRow}>
								<TableCell padding="checkbox" className={classes.tableCell} style={{ paddingLeft: '10px' }}>
									{isPermit(permissions, permissionName.FP_ADMIN_ACCOUNTS_DEACTIVATE) && (
										<Checkbox
											indeterminate={selected.length > 0 && selected.length < data.length}
											checked={data.length > 0 && selected.length === data.length}
											onChange={handleSelectAllClick}
											inputProps={{ 'aria-label': 'select all accounts' }}
											style={{
												color:
													(data.length > 0 && selected.length === data.length) ||
													(selected.length > 0 && selected.length < data.length)
														? theme.palette.secondary.light
														: theme.palette.primary.main,
											}}
											size="small"
										/>
									)}
								</TableCell>
								{accountsTableCells.map(({ label, id, sortable }) => (
									<TableCell
										key={id}
										className={`${classes.tableCell} ${sortable ? classes.cursorPointer : ''}`}
										align={id === 'status' ? 'center' : 'left'}
										onClick={() => {
											if (sortable) {
												dispatch(setOrderBy(id))
												dispatch(setOrderType(accountsFilters.orderType === 'DESC' ? 'ASC' : 'DESC'))
											}
										}}
									>
										<TableSortLabel
											active={id === accountsFilters.orderBy}
											direction={accountsFilters.orderType.toLowerCase()}
										>
											{label}
										</TableSortLabel>
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>{renderTableBody()}</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					classes={{
						root: classes.tablePaginator,
						toolbar: classes.tablePaginatorToolbar,
						actions: classes.tablePaginatorActions,
						selectIcon: classes.tablePaginatorSelectIcon,
					}}
					rowsPerPageOptions={[10, 20, 25, 100]}
					component="div"
					count={count}
					rowsPerPage={localRowsPerPage}
					page={page}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
				/>
			</Paper>
		</>
	)
}

TableList.propTypes = {
	data: PropTypes.arrayOf(PropTypes.any).isRequired,
	loading: PropTypes.bool.isRequired,
	count: PropTypes.number.isRequired,
}

export default TableList
