import React from 'react'

import InvoicesContainer from '../containers/InvoicesContainer'

const MyInvoices = () => (
	<div>
		<InvoicesContainer />
	</div>
)

export default MyInvoices
