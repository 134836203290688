import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
	noDataTableRowModel: {
		minWidth: '100%',
		minHeight: '100%',
	},
	noDataTableCellModel: {
		minWidth: '100%',
		minHeight: '100%',
	},
	noDataDivModel: {
		minWidth: '100%',
		minHeight: '40vh',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		'@media only screen and (max-height: 1080px)': {
			minHeight: '58vh',
		},
	},
	noDataImageModel: {
		width: '200px',
		'@media only screen and (max-width: 768px)': {
			width: '100px',
		},
	},
	noDataTitleModel: {
		fontFamily: 'Open Sans',
		fontSize: '17px',
		fontWeight: '600',
		color: '#2C2C2C',
	},
	noDataSubtitleModel: {
		fontFamily: 'Open Sans',
		fontSize: '15px',
		fontWeight: '400',
		color: '#2C2C2C',
		margin: 0,
	},
}))

export default useStyles
