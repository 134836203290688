/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField, InputAdornment } from '@material-ui/core'
import LocationOnIcon from '@material-ui/icons/LocationOn'

import sweetAlertModal from '../SweetAlertModal/SweetAlertModal'
import { fetchDataFromZipCode } from '../../modules/newQuoteShipment/utils/googleMapsQueries'
import useStyles from './styles'

const ZipCodeSelector = ({
	value,
	setOptions,
	onChange,
	options,
	size,
	getOptionLabel,
	inputValue,
	inputLabel,
	inputPlaceholder,
	inputError,
	inputHelperText,
	inputSize,
	onInputChange,
	freeSolo,
	loading,
	loadingText,
	autoSelect,
}) => {
	const classes = useStyles()

	const handleChangeEvent = (newValue, latestPostalCode = '') => {
		if (
			newValue &&
			newValue.country === 'CAN' &&
			newValue.postalCode.replace(/ /g, '').length !== 6 &&
			(inputValue.replace(/ /g, '').length === 6 || // For when multiple options are available
				latestPostalCode.replace(/ /g, '').length === 6) // For when only one option is available auto-select occurs
		) {
			const correctedValue = { ...newValue }
			// Not sure why inputValue seems to be outdated by one character. IE. 5 digits instead of 6
			// I did a hacky workaround.. my theory is that it's likely only happening when there's
			// a single response from Google and the auto-select functionality kicks in and
			// react has not re-rendered yet. This was the easiest fix I could think of...
			const correctedPostalCode = latestPostalCode ?? inputValue
			correctedValue.postalCode = correctedPostalCode.replace(/ /g, '').toUpperCase()

			// When I tried calling onChange with corrected data, the internal material ui
			// functionality kept overriding what I sent in and replacing it with the old option
			// with a bad postal code. I realized that I needed to update the options array
			// for it to allow me to select an option with corrected data
			const optionsCopy = [...options]
			optionsCopy.splice(
				optionsCopy.findIndex((option) => option.label === correctedValue.label),
				1,
				correctedValue,
			)

			setOptions(optionsCopy)
			onChange(correctedValue)
		} else {
			// If the value goes nullish, then the reset button was clicked so reset the options
			// so old options don't show up when the text input is empty
			if (!newValue) setOptions([])

			onChange(newValue)
		}
	}

	const getDataFromZipCode = async (event) => {
		const newValue = event.target.value
		if (newValue.length >= 5) {
			const [status, resp] = await fetchDataFromZipCode(newValue)
			if (status === 'error') {
				sweetAlertModal(
					'error',
					'There was an error fetching the data related to the zip code!',
					null,
					'Ok',
					true,
					false,
				)
			} else {
				setOptions(resp)
				if (autoSelect && resp.length === 1) {
					handleChangeEvent(resp[0], newValue)
				}
			}
		}
	}

	return (
		<Autocomplete
			value={value}
			onChange={(_, newValue) => handleChangeEvent(newValue)}
			size={size}
			getOptionLabel={getOptionLabel}
			inputValue={inputValue}
			onInputChange={onInputChange}
			freeSolo={freeSolo}
			loading={loading}
			options={options}
			loadingText={loadingText}
			renderInput={(props) => (
				<TextField
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...props}
					error={inputError}
					placeholder={inputPlaceholder}
					label={inputLabel}
					margin="normal"
					helperText={inputHelperText}
					FormHelperTextProps={{ classes: { root: classes.helperText } }}
					style={{ margin: 0 }}
					onChange={(event) => getDataFromZipCode(event)}
					variant="outlined"
					size={inputSize}
					InputProps={{
						/* eslint-disable react/prop-types */
						...props.InputProps,
						startAdornment: (
							<InputAdornment position="start">
								<LocationOnIcon color="action" />
							</InputAdornment>
						),
					}}
				/>
			)}
		/>
	)
}

ZipCodeSelector.defaultProps = {
	size: 'small',
	inputValue: undefined,
	loadingText: 'Please enter a zip code',
	loading: true,
	freeSolo: true,
	getOptionLabel: () => {},
	inputLabel: '',
	inputPlaceholder: 'Please enter a zip code',
	inputError: false,
	inputHelperText: '',
	inputSize: 'small',
	autoSelect: false,
}

ZipCodeSelector.propTypes = {
	value: PropTypes.any.isRequired,
	onChange: PropTypes.func.isRequired,
	onInputChange: PropTypes.func.isRequired,
	options: PropTypes.array.isRequired,
	setOptions: PropTypes.func.isRequired,
	getOptionLabel: PropTypes.func,
	size: PropTypes.string,
	inputValue: PropTypes.string,
	inputLabel: PropTypes.string,
	inputPlaceholder: PropTypes.string,
	inputError: PropTypes.bool,
	inputHelperText: PropTypes.string,
	inputSize: PropTypes.string,
	loadingText: PropTypes.string,
	loading: PropTypes.bool,
	freeSolo: PropTypes.bool,
	autoSelect: PropTypes.bool,
}

export default ZipCodeSelector
