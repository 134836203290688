/* eslint no-param-reassign: "error" */
import React, { useState, useEffect, forwardRef } from 'react'
import PropTypes from 'prop-types'
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Grid,
	Button,
	IconButton,
	Tooltip,
} from '@material-ui/core'
import { useLocation } from 'react-router-dom'

import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import AddIcon from '@material-ui/icons/Add'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'

import ReferencesSlider from '../../modules/newQuoteShipment/components/ShipmentReferencesSlider'
import ReferenceInputs from './ReferenceInputs'
import { createQuotesRoute } from '../../utils/constants'

import ActionsMenu from '../ActionsMenu'

import useStyles from './styles'

const ReferencesForm = forwardRef((props, ref) => {
	const classes = useStyles()
	const theme = useTheme()
	const matches = useMediaQuery(theme.breakpoints.down('md'))
	const location = useLocation()

	const { setReferencesAdded, previousReferences, dispatchSetReferences, setPreviousReferences } = props

	const [references, setReferences] = useState([])

	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)

	const [referenceForm, setReferenceForm] = useState([])

	useEffect(() => {
		if (previousReferences !== undefined) {
			const changeSetReferences = previousReferences.map((reference) => {
				if (reference?.id) {
					return {
						referenceType: reference?.type ?? '',
						value: reference?.value ?? '',
					}
				}
				return reference
			})
			setReferences(changeSetReferences)
		}
	}, [previousReferences])

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleDeleteClick = (index) => {
		const inputList = [...references]
		inputList.splice(index, 1)
		setReferences(inputList)
		setReferencesAdded(inputList)
		setPreviousReferences(inputList)
	}

	const deleteReferenceForm = (accountId) => {
		const newAccount = [...referenceForm]
		const filteredAccounts = newAccount.filter((item) => item.id !== accountId)
		setReferenceForm(filteredAccounts)
	}

	return (
		<>
			{((references && references.length !== 0) || (referenceForm && referenceForm.length !== 0)) &&
			location?.pathname === createQuotesRoute ? (
				<h3 className={classes.newQuoteReferenceSubtitle}>Added</h3>
			) : null}
			<div className={classes.formContainer}>
				{references.length ? (
					<>
						<div className={classes.desktopTable}>
							<TableContainer className={classes.tableContainer}>
								<Table className={classes.table} aria-label="customized table" padding="none">
									<TableHead>
										<TableRow className={classes.tableRow}>
											<TableCell align="center" className={classes.tableCellHead}>
												#
											</TableCell>
											<TableCell align="center" className={classes.tableCellHead}>
												Reference Type
											</TableCell>
											<TableCell align="center" className={classes.tableCellHead}>
												Reference Number
											</TableCell>
											<TableCell align="center" className={classes.tableCellHead}>
												Actions
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{references.map((item, index) => (
											<TableRow key={`id-${index + 1}`} className={classes.tableRow}>
												<TableCell align="center" className={classes.tableCell}>
													{index + 1}
												</TableCell>
												<TableCell align="center" className={classes.tableCell}>
													{item.referenceType ? item.referenceType.split('_').join(' ') : ''}
													{item.type ? item.type.split('_').join(' ') : ''}
												</TableCell>
												<TableCell align="center" className={classes.tableCell}>
													{item.value ? item.value : ''}
												</TableCell>
												<TableCell align="center" className={classes.tableCell}>
													<Tooltip title="Delete">
														<IconButton aria-label="delete" color="primary" onClick={() => handleDeleteClick(index)}>
															<DeleteOutlineOutlinedIcon fontSize="small" />
														</IconButton>
													</Tooltip>
												</TableCell>
												<ActionsMenu
													type="reference"
													anchorEl={anchorEl}
													open={open}
													handleClose={handleClose}
													handleDeleteClick={handleDeleteClick}
													index={index}
												/>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</div>
						<ReferencesSlider references={references} />
					</>
				) : null}
				{referenceForm.map((item, index) => (
					<ReferenceInputs
						key={`id-${index + 1}`}
						referenceItem={item}
						setReferencesAdded={setReferencesAdded}
						setPreviousReferences={setPreviousReferences}
						previousReferences={previousReferences}
						dispatchSetReferences={dispatchSetReferences}
						deleteReferenceForm={deleteReferenceForm}
						ref={(el) => {
							ref.current[index] = el
						}}
					/>
				))}
				<Grid
					container
					direction={matches ? 'column' : 'row'}
					className={`
          ${classes.addButton}
          ${location?.pathname === createQuotesRoute ? classes.addButtonStep1 : ''}
          `}
				>
					<Button
						color="primary"
						className={`
            ${classes.button}
            ${location?.pathname === createQuotesRoute ? classes.leftButton : ''}
            `}
						onClick={() => {
							setReferenceForm((prevState) => [
								...prevState,
								prevState.length > 0 ? { id: Number(prevState.slice(-1)[0].id) + 1 } : { id: 0 },
							])
						}}
					>
						<AddIcon color="secondary" className={classes.newShipmentIcon} />
						Add
					</Button>
				</Grid>
			</div>
		</>
	)
})

ReferencesForm.defaultProps = {
	setPreviousReferences: () => {},
}

ReferencesForm.propTypes = {
	setReferencesAdded: PropTypes.func.isRequired,
	setPreviousReferences: PropTypes.func,
	previousReferences: PropTypes.arrayOf(PropTypes.any).isRequired,
	dispatchSetReferences: PropTypes.func.isRequired,
}

export default ReferencesForm
