import React from 'react'
import PropTypes from 'prop-types'

import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Checkbox,
	withStyles,
} from '@material-ui/core'

import checkEmail, { formatPhoneNumber } from '../../utils/helpers'

import useStyles from './styles'

const StyledTableCell = withStyles(() => ({
	root: {
		paddingTop: '5px',
		paddingBottom: '5px',
	},
}))(TableCell)

const NotificationSettingsTable = (props) => {
	const {
		firstName,
		lastName,
		phoneNumber,
		email,
		sendBolByEmail,
		setSendBolByEmail,
		sendByEmail,
		setSendByEmail,
		sendBySms,
		setSendBySms,
		sendWhenBooked,
		setSendWhenBooked,
		sendWhenInTransit,
		setSendWhenInTransit,
		sendWhenDelivered,
		setSendWhenDelivered,
		isEditProfile,
	} = props
	const classes = useStyles()

	return (
		<div className={classes.container}>
			<TableContainer component={Paper}>
				<Table className={classes.table} aria-label="customized table">
					<TableHead>
						<TableRow className={classes.tableRow}>
							<TableCell
								align="center"
								colSpan={3}
								className={`${classes.tableCellHead} ${classes.tableVerticalLineRight}`}
							>
								Contact Information
							</TableCell>
							<TableCell
								align="center"
								colSpan={3}
								className={`${classes.tableCellHead} ${classes.tableVerticalLineRight}`}
							>
								Method
							</TableCell>
							<TableCell
								align="center"
								colSpan={3}
								className={`${classes.tableCellHead} ${classes.tableVerticalLineRight}`}
							>
								Tracking Notifications
							</TableCell>
						</TableRow>
						<TableRow className={classes.tableRow}>
							{!isEditProfile && (
								<TableCell align="center" className={classes.tableCellSecondHead}>
									Contact Name
								</TableCell>
							)}
							<TableCell align="center" className={classes.tableCellSecondHead}>
								Email Address
							</TableCell>
							<TableCell align="center" className={`${classes.tableCellSecondHead} ${classes.tableVerticalLineRight}`}>
								Cell Phone
							</TableCell>
							<TableCell align="center" className={classes.tableCellSecondHead}>
								Send BOL by Email
							</TableCell>
							<TableCell align="center" className={classes.tableCellSecondHead}>
								Email
							</TableCell>
							<TableCell align="center" className={`${classes.tableCellSecondHead} ${classes.tableVerticalLineRight}`}>
								SMS
							</TableCell>
							<TableCell align="center" className={classes.tableCellSecondHead}>
								Booked
							</TableCell>
							<TableCell align="center" className={classes.tableCellSecondHead}>
								In Transit
							</TableCell>
							<TableCell align="center" className={classes.tableCellSecondHead}>
								Delivered
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow style={{ height: '10px' }}>
							{!isEditProfile && (
								<StyledTableCell align="center" className={classes.tableCell}>
									{firstName && lastName ? `${firstName} ${lastName}` : '-'}
								</StyledTableCell>
							)}
							<StyledTableCell align="center" className={classes.tableCell}>
								{checkEmail(email)}
							</StyledTableCell>
							<StyledTableCell align="center" className={`${classes.tableCell} ${classes.tableVerticalLineRight}`}>
								{formatPhoneNumber(phoneNumber)}
							</StyledTableCell>
							<StyledTableCell align="center">
								<Checkbox
									color="secondary"
									className={classes.checkBox}
									checked={sendBolByEmail}
									onChange={(event) => setSendBolByEmail(event.target.checked)}
								/>
							</StyledTableCell>
							<StyledTableCell align="center">
								<Checkbox
									color="secondary"
									className={classes.checkBox}
									checked={sendByEmail}
									onChange={(event) => setSendByEmail(event.target.checked)}
								/>
							</StyledTableCell>
							<StyledTableCell align="center" className={`${classes.tableCell} ${classes.tableVerticalLineRight}`}>
								<Checkbox
									color="secondary"
									className={classes.checkBox}
									checked={sendBySms}
									onChange={(event) => setSendBySms(event.target.checked)}
								/>
							</StyledTableCell>
							<StyledTableCell align="center">
								<Checkbox
									color="secondary"
									className={classes.checkBox}
									checked={sendWhenBooked}
									onChange={(event) => setSendWhenBooked(event.target.checked)}
								/>
							</StyledTableCell>
							<StyledTableCell align="center">
								<Checkbox
									color="secondary"
									className={classes.checkBox}
									checked={sendWhenInTransit}
									onChange={(event) => setSendWhenInTransit(event.target.checked)}
								/>
							</StyledTableCell>
							<StyledTableCell align="center">
								<Checkbox
									color="secondary"
									className={classes.checkBox}
									checked={sendWhenDelivered}
									onChange={(event) => setSendWhenDelivered(event.target.checked)}
								/>
							</StyledTableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	)
}

NotificationSettingsTable.propTypes = {
	firstName: PropTypes.string.isRequired,
	lastName: PropTypes.string.isRequired,
	phoneNumber: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
	sendBolByEmail: PropTypes.bool.isRequired,
	setSendBolByEmail: PropTypes.func.isRequired,
	sendByEmail: PropTypes.bool.isRequired,
	setSendByEmail: PropTypes.func.isRequired,
	sendBySms: PropTypes.bool.isRequired,
	setSendBySms: PropTypes.func.isRequired,
	sendWhenBooked: PropTypes.bool.isRequired,
	setSendWhenBooked: PropTypes.func.isRequired,
	sendWhenInTransit: PropTypes.bool.isRequired,
	setSendWhenInTransit: PropTypes.func.isRequired,
	sendWhenDelivered: PropTypes.bool.isRequired,
	setSendWhenDelivered: PropTypes.func.isRequired,
	isEditProfile: PropTypes.bool,
}

NotificationSettingsTable.defaultProps = { isEditProfile: false }

export default NotificationSettingsTable
