import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	listItemIcon: {
		minWidth: 32,
		'& .MuiSvgIcon-root': {
			fontSize: 24,
		},
	},
	iconRed: {
		color: theme.palette.primary.main,
	},
	iconGreen: {
		color: theme.palette.success.main,
	},
}))

export default useStyles
