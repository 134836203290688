/* eslint-disable no-console */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { Grid } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import {
	TruckIcon,
	InvoiceIcon,
	PastDueIcon,
	OutStandingBalanceIcon,
	PikingUpIcon,
	DeliveredIcon,
} from '../../../assets/Icons/Icons'

import { reqGeneralSettingByName } from '../../../redux/requests/App.request'
import {
	fetchCarrierUsageReports,
	fetchPerMonthAndYearRreports,
	perMonthAndYearReset,
} from '../../../redux/actions/Reports.actions'
import {
	fetchShipmentsReportsByCompany,
	fetchInvoiceReportsByCompany,
} from '../../../redux/actions/ReportsWithHeaders.actions'

import { getCarriers } from '../../../redux/actions/Carriers.actions'
import { formatAxiosErrorMessage } from '../../../utils/apiClient'
import SessionContext from '../../../context/session'

import PieChart from '../components/PieChart'
import BarChart from '../components/BarChart'
import DataChart from '../components/DataChart'

import UserAlert from '../components/UserAlert'
import { setTabCurrentRoute, setTabTitle } from '../../../redux/actions/MultiTabs.actions'
import { dashboardRoute } from '../../../utils/constants'
import ModuleHeader from '../../../components/ModuleHeader'

import useStyles from './styles'

const DashBoardContainer = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const { getUserData } = useContext(SessionContext)
	const { actualCompany } = getUserData()
	const [carrierUsageData, setCarrierUsageData] = useState([])
	const [carriersList, setCarrierList] = useState([])
	const [shipperFilter, setShipperFilter] = useState('')
	const [perMonthAndYearData, setPerMonthAndYearData] = useState([])
	const [dashboardSettings, setDashboardSettings] = useState({})
	const [shipmentsData, setShipmentsData] = useState({
		shipmentsInTransit: 0,
		shipmentsDelivered: 0,
		shipmentsPickingUpToday: 0,
	})
	const [invoicesData, setInvoicesData] = useState({
		unpaidInvoiceCount: 0,
		outstandingBalance: 0,
		pastDueBalance: 0,
	})
	const { carrierUsage, perMonthAndYear, shipments, invoices } = useSelector((state) => state.reports)
	const { carriers } = useSelector((state) => state.carriers)
	const userProfile = useSelector((state) => state.profile.profile)

	const setFilter = (scac) => {
		dispatch(perMonthAndYearReset())
		setShipperFilter(scac)
	}

	const dismissAlert = () => setDashboardSettings({})

	useEffect(() => {
		dispatch(setTabCurrentRoute(dashboardRoute))
		dispatch(setTabTitle('Dashboard'))
	}, [dispatch])

	const AlertProvider = () => {
		const alertCallback = useCallback(async (params) => {
			const config = {
				headers: { 'X-Company-id': Number(actualCompany) },
			}
			const [error, data] = await reqGeneralSettingByName(params, config)
			if (error) return
			setDashboardSettings(data)
		}, [])
		return { alertCallback }
	}

	const { alertCallback } = AlertProvider()

	useEffect(() => {
		alertCallback('DASHBOARD_MESSAGE')
	}, [alertCallback])

	useEffect(() => {
		const fetchData = async () => {
			const params = {
				initial_date: moment().subtract(365, 'days').format('YYYY-MM-DD'),
				end_date: moment().add(1, 'day').format('YYYY-MM-DD'),
				status: 'DELIVERED',
			}
			const qs = Object.keys(params)
				.map((key) => `${key}=${params[key]}`)
				.join('&')
			const { status } = await dispatch(fetchCarrierUsageReports(`?${qs}`))
			if (status === 'error') console.log(status)
		}
		fetchData()
	}, [dispatch])

	useEffect(() => {
		const fetchData = async () => {
			const params = {
				initial_date: moment().subtract(365, 'days').format('YYYY-MM-DD'),
				end_date: moment().add(1, 'day').format('YYYY-MM-DD'),
				scac: shipperFilter === 'all' || shipperFilter === '' ? '' : shipperFilter,
				status: 'DELIVERED',
			}
			const qs = Object.keys(params)
				.map((key) => `${key}=${params[key]}`)
				.join('&')
			const res = await dispatch(fetchPerMonthAndYearRreports(`?${qs}`))
			if (res.status === 'error') console.log(formatAxiosErrorMessage(res), res)
		}
		fetchData()
	}, [dispatch, shipperFilter])

	useEffect(() => {
		const fetchData = async () => {
			const { status } = await dispatch(fetchInvoiceReportsByCompany(actualCompany))
			if (status === 'error') console.log(status)
		}
		fetchData()
	}, [actualCompany, dispatch])

	useEffect(() => {
		const fetchData = async () => {
			const { status } = await dispatch(fetchShipmentsReportsByCompany(actualCompany))
			if (status === 'error') console.log(status)
		}
		fetchData()
	}, [actualCompany, dispatch])

	useEffect(() => {
		const fetchData = async () => {
			const config = {
				headers: { 'X-Company-id': Number(actualCompany) },
			}
			const { status } = await dispatch(getCarriers(config))
			if (status === 'error') console.log(status)
		}
		fetchData()
	}, [dispatch, actualCompany])

	useEffect(() => {
		setCarrierUsageData((prevState) => carrierUsage?.data ?? prevState)
		setPerMonthAndYearData((prevState) => perMonthAndYear?.data ?? prevState)
		setInvoicesData((prevState) => invoices?.data ?? prevState)
		setShipmentsData((prevState) => shipments?.data ?? prevState)
		setCarrierList((prevState) => carriers ?? prevState)
	}, [carrierUsage, carriers, perMonthAndYear, invoices, shipments])

	return (
		<>
			<UserAlert settings={dashboardSettings} dismissAlert={dismissAlert} />
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<ModuleHeader
						title={`Welcome, ${userProfile?.name ?? ''}`}
						subtitle="This is the current status of your account."
					/>
				</Grid>
				<Grid item xs={12} lg={6} xl={5}>
					<PieChart data={carrierUsageData} title="Carrier Usage (%)" />
				</Grid>
				<Grid item xs={12} lg={6} xl={7}>
					<BarChart data={perMonthAndYearData} selectData={carriersList} title="Shipment Count" setFilter={setFilter} />
				</Grid>
				<Grid item xs={12}>
					<div className={classes.dashboardLabel}>
						<div>
							<TruckIcon color="primary" className={classes.dashboardLabelIcon} />
						</div>
						<h5>Shipments</h5>
					</div>
				</Grid>
				<Grid item xs={12} sm={4}>
					<DataChart
						data={shipmentsData?.shipmentsInTransit ?? 0}
						title="Shipments in transit"
						icon={<TruckIcon htmlColor="#FFFFFF" />}
						color="main"
						reportStatus="IN TRANSIT"
					/>
				</Grid>
				<Grid item xs={12} sm={4}>
					<DataChart
						data={shipmentsData?.shipmentsPickingUpToday ?? 0}
						title="Shipments picking up today"
						icon={<PikingUpIcon htmlColor="#FFFFFF" />}
						color="main"
						reportStatus="PICKING UP TODAY"
					/>
				</Grid>
				<Grid item xs={12} sm={4}>
					<DataChart
						data={shipmentsData?.shipmentsDelivered ?? 0}
						title="Shipments delivered (Last 30 days)"
						icon={<DeliveredIcon htmlColor="#FFFFFF" />}
						color="main"
						reportStatus="DELIVERED"
					/>
				</Grid>
				<Grid item xs={12}>
					<div className={classes.dashboardLabel}>
						<div>
							<InvoiceIcon color="primary" className={classes.dashboardLabelIcon} />
						</div>
						<h5>Invoices</h5>
					</div>
				</Grid>
				<Grid item xs={12} sm={4}>
					<DataChart
						data={invoicesData?.unpaidInvoiceCount ?? 0}
						title="Unpaid invoices"
						icon={<InvoiceIcon htmlColor="#FFFFFF" />}
						color="green"
						reportStatus="UNPAID"
					/>
				</Grid>
				<Grid item xs={12} sm={4}>
					<DataChart
						data={invoicesData?.outstandingBalance ?? 0}
						currency
						title="outstanding balance"
						icon={<OutStandingBalanceIcon htmlColor="#FFFFFF" />}
						color="green"
						reportStatus="OUTSTANDING"
					/>
				</Grid>
				<Grid item xs={12} sm={4}>
					<DataChart
						data={invoicesData?.pastDueBalance ?? 0}
						currency
						title="past due balance"
						icon={<PastDueIcon htmlColor="#FFFFFF" />}
						color="green"
						reportStatus="PAST"
					/>
				</Grid>
			</Grid>
		</>
	)
}

export default DashBoardContainer
