import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	paper: {
		'& .MuiMenu-paper': {
			backgroundColor: theme.palette.neutral.whiteGray,
			width: '100%',
			padding: 17,
			[theme.breakpoints.up(theme.breakpoints.values.lg)]: {
				width: '414px',
			},
		},
		'& .MuiList-padding': {
			padding: 0,
		},
		'& .MuiMenuItem-root': {
			padding: 0,
		},
	},
	containerSearch: {
		borderBottom: `4px solid ${theme.palette.neutral.lightGray}`,
	},
	cancelButton: {
		height: 48,
		textTransform: 'none',
		color: theme.palette.primary.main,
		fontSize: 17,
	},
	containerList: {
		marginTop: 10,
		maxHeight: 217,
		overflowY: 'auto',
	},
}))

export default useStyles
