import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	newItemDetailContainer: {
		padding: '0 15px',
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			padding: '0',
		},
	},
	newItemHeaderDetailContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center',
		padding: '0px',
	},
	newItemHeaderLabelContainer: {
		display: 'flex',
		alignItems: 'center',
		fontSize: '18px',
		marginTop: '5px',
	},
	newItemActionLabel: {
		fontSize: '13px',
		marginLeft: '8px',
		fontWeight: theme.typography.fontWeightRegular,
		color: theme.palette.neutral.black,
	},
	field: {
		[theme.breakpoints.up('xs')]: {
			fontSize: '12px',
		},
		[theme.breakpoints.up('sm')]: {
			fontSize: '13px',
		},
		[theme.breakpoints.up('md')]: {
			fontSize: '15px',
		},
	},
	newItemDetailLabelContainer: {
		display: 'flex',
		alignItems: 'center',
		padding: '16px 0px 16px 16px !important',
	},
	newItemDetailLabel: {
		fontSize: '13px',
		marginRight: '4px',
		fontWeight: theme.typography.fontWeightRegular,
		color: theme.palette.neutral.black,
	},
	newItemRadioContainer: {
		display: 'inline',
		flexDirection: 'row',
		marginLeft: '24px',
		fontSize: '30px',
		[theme.breakpoints.down('sm')]: {
			marginLeft: '0px',
		},
	},
	newItemButtonsContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	newItemDetailButton: {
		textTransform: 'none',
		padding: '0px',
	},
	newItemDetailsInputContainer: {
		marginTop: '5px',
	},
	newItemButtonContainer: {
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('md')]: {
			justifyContent: 'flex-end',
		},
	},
	root: {
		height: '50px',
		fontSize: '14px',
		'& .MuiFormLabel-root': {
			fontSize: '14px',
		},
		'& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
			display: 'none',
			margin: 80,
		},
		'& .MuiInputBase-root': {
			height: '50px',
		},
	},
}))

export default useStyles
