/* eslint-disable no-unused-vars */
import type { AxiosRequestConfig } from 'axios'
import axiosUser from '../../utils/apiClient'

export const COMPANY_SETTINGS = [
	'CUSTOMER_PRIORITY_LEVEL',
	'ENABLE_INSURANCE_OPT_IN',
	'ENABLE_MAX_REPLACEMENT',
	'ENABLE_MERCURY_GATE',
	'ENABLE_PAYMENT_SERVICE',
	'ENABLE_SHIPLIFY_SERVICE',
	'IS_PDF',
	'MAX_REPACEMENT_VALUE',
	'MAX_REPACEMENTVALUE',
	'NEW_INVOICE_EMAIL',
	'NEW_QUOTE_EMAIL',
	'NEW_SHIPMENT_EMAIL',
	'NEW_TRACKING_EMAIL',
	'PAYMENT_TERMS',
	'PAYMENT_TYPE',
	'TYPE_AMOUNT',
	'TYPE_AMOUNT_VALUE',
] as const

export type CompanySetting = (typeof COMPANY_SETTINGS)[number]

export type UpdateSettingPayload = { name: CompanySetting; value: string }

const reqPrincipalPermission = async () => {
	const [error, data] = await axiosUser
		.get('/permission')
		.then((permissions) => [null, permissions.data.data])
		.catch((err) => {
			if (err.message?.data?.err?.message) {
				return [err.response.data.err.message, err.response.data.err.message]
			}
			return [err, err]
		})

	// console.log('data', data);
	return [error, data]
}

export const reqUserData = async () => {
	const [error, data] = await axiosUser
		.get('/user')
		.then((user) => [null, user])
		.catch((err) => {
			if (err.message?.data?.err?.message) {
				return [err.response.data.err.message, err.response.data.err.message]
			}
			return [err, err]
		})
	return [error, data]
}

export const reqUpdateGeneralSettingByName = async (params: UpdateSettingPayload, config: AxiosRequestConfig) => {
	const [error, data] = await axiosUser
		.put('/settings/general', params, config)
		.then((setting) => [null, setting.data.data.setting])
		.catch((err) => [err, err])
	return [error, data]
}

export const reqGeneralSettingByName = async (name: CompanySetting, config: AxiosRequestConfig) => {
	const [error, data] = await axiosUser
		.get(`/settings/general/${name}`, config)
		.then((setting) => [null, setting.data.data.setting])
		.catch((err) => [err, err])
	return [error, data]
}

export const reqGetSettingByName = async (name: CompanySetting, config: AxiosRequestConfig) => {
	const [error, data] = await axiosUser
		.get(`/settings/${name}`, config)
		.then((setting) => [null, setting.data.data.setting])
		.catch((err) => [err, err])
	return [error, data]
}

export default reqPrincipalPermission
