import React from 'react'
import PropTypes from 'prop-types'

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Avatar } from '@material-ui/core'
import moment from 'moment'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined'

import { InformationIcon } from '../../../../../assets/Icons/Icons'

import ChildAccountsSlider from '../../../newAccount/components/ChildAccountsSlider'
import useStyles from './styles'

const AccountInformationContainer = ({ accountInformationParent, accountInformationChilds }) => {
	const classes = useStyles()
	const userName = accountInformationParent.user?.name ?? ' '

	return (
		<>
			<div className={classes.accountInformationContainer}>
				<div className={classes.accountBasicInformationContainer}>
					<div className={classes.accountBasicInformationTitleContainer}>
						<div className={classes.accountBasicInformationIconContainer}>
							<InformationIcon className={classes.generalIcon} />
						</div>
						<h3 className={classes.accountBasicInformationSectionTitle}>Basic Information</h3>
					</div>
					<div className={classes.accountBasicInformationLogoContainer}>
						{accountInformationParent.logo ? (
							<img src={accountInformationParent.logo} alt="" className={classes.avatar} />
						) : (
							<Avatar className={classes.avatar}>
								<SupervisorAccountOutlinedIcon fontSize="small" color="inherit" />
							</Avatar>
						)}
					</div>
					<Grid container spacing={0} className={classes.accountBasicInformationContentContainer}>
						<Grid item xs={12} sm={4} lg={4} className={classes.accountBasicInformationContent}>
							<p className={classes.accountBasicInformationTitle}>
								<span>Account Name:</span>
								{accountInformationParent.name}
							</p>
						</Grid>
						<Grid item xs={12} sm={4} lg={4} className={classes.accountBasicInformationContent}>
							<p className={classes.accountBasicInformationTitle}>
								<span>Created by:</span>
								{userName}
							</p>
						</Grid>
						<Grid item xs={12} sm={4} lg={4} className={classes.accountBasicInformationContent}>
							<p className={classes.accountBasicInformationTitle}>
								<span>Contact Name:</span>
								{accountInformationParent.contact}
							</p>
						</Grid>
						<Grid item xs={12} sm={4} lg={4} className={classes.accountBasicInformationContent}>
							<p className={classes.accountBasicInformationTitle}>
								<span>Salesforce Account Number:</span>
								{accountInformationParent.sales_force_number}
							</p>
						</Grid>
						<Grid item xs={12} sm={4} lg={4} className={classes.accountBasicInformationContent}>
							<p className={classes.accountBasicInformationTitle}>
								<span>Created Date:</span>
								{moment(accountInformationParent.audit_creation_date).format('MM/DD/YYYY')}
							</p>
						</Grid>
						<Grid item xs={12} sm={4} lg={4} className={classes.accountBasicInformationContent}>
							<p className={classes.accountBasicInformationTitle}>
								<span>Contact Phone:</span>
								{accountInformationParent.phone}
								{accountInformationParent.phone_extension ? `, Ext ${accountInformationParent.phone_extension}` : ''}
							</p>
						</Grid>
						<Grid item xs={12} sm={4} lg={4} className={classes.accountBasicInformationContent}>
							<p className={classes.accountBasicInformationTitle}>
								<span>TMS Account Number:</span>
								{accountInformationParent.account_number}
							</p>
						</Grid>
						<Grid item xs={12} sm={4} lg={4} className={classes.accountBasicInformationContent}>
							<p className={classes.accountBasicInformationTitle}>
								<span>Last Modified Date:</span>
								{moment(accountInformationParent.audit_update_date).format('MM/DD/YYYY')}
							</p>
						</Grid>
						<Grid item xs={12} sm={4} lg={4} className={classes.accountBasicInformationContent}>
							<p className={classes.accountBasicInformationTitle}>
								<span>Contact Email:</span>
								{accountInformationParent.email}
							</p>
						</Grid>
					</Grid>
				</div>
				<div className={classes.childAccountsInformationContainer}>
					<Grid container className={classes.childAccountInformationSectionTitle}>
						<div className={classes.childAccountInformationSectionIconContainer}>
							<SupervisorAccountIcon className={classes.childAccountInformationSectionIcon} fontSize="small" />
						</div>
						<p className={classes.accountBasicInformationSectionTitle}>Child Accounts</p>
					</Grid>
					{accountInformationChilds.length ? (
						<>
							<Grid container className={classes.desktopTable}>
								<TableContainer className={classes.tableContainer}>
									<Table className={classes.table} aria-label="child accounts details" padding="none">
										<TableHead>
											<TableRow>
												<TableCell align="center" className={classes.tableCellHead}>
													#
												</TableCell>
												<TableCell align="center" className={classes.tableCellHead}>
													Child Name
												</TableCell>
												<TableCell align="center" className={classes.tableCellHead}>
													Salesforce Account Number
												</TableCell>
												<TableCell align="center" className={classes.tableCellHead}>
													TMS Account Number
												</TableCell>
												<TableCell align="center" className={classes.tableCellHead}>
													Contact Name
												</TableCell>
												<TableCell align="center" className={classes.tableCellHead}>
													Contact Phone
												</TableCell>
												<TableCell align="center" className={classes.tableCellHead}>
													Contact Email
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{accountInformationChilds.map((item, index) => (
												<TableRow key={`id-${index + 1}`} className={classes.tableRow}>
													<TableCell align="center" className={classes.tableCell}>
														{index + 1}
													</TableCell>
													<TableCell align="center" className={classes.tableCell}>
														{item.name ? item.name : ''}
													</TableCell>
													<TableCell align="center" className={classes.tableCell}>
														{item.salesForceNumber ? item.salesForceNumber : ''}
													</TableCell>
													<TableCell align="center" className={classes.tableCell}>
														{item.accountNumber ? item.accountNumber : ''}
													</TableCell>
													<TableCell align="center" className={classes.tableCell}>
														{item.contact ? item.contact : ''}
													</TableCell>
													<TableCell align="center" className={classes.tableCell}>
														{item.phone ? item.phone : ''}
														{item.phone_extension ? `, Ext ${item.phone_extension}` : ''}
													</TableCell>
													<TableCell align="center" className={classes.tableCell}>
														{item.email ? item.email : ''}
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</Grid>
							<ChildAccountsSlider childAccounts={accountInformationChilds} isDetail />
						</>
					) : (
						<div className={classes.childAccountsMessageContainer}>
							<h3 className={classes.childAccountsMessageTitle}>No child accounts added yet</h3>
						</div>
					)}
				</div>
			</div>
		</>
	)
}

AccountInformationContainer.propTypes = {
	accountInformationParent: PropTypes.objectOf(PropTypes.any).isRequired,
	accountInformationChilds: PropTypes.arrayOf(PropTypes.any).isRequired,
}

export default AccountInformationContainer
