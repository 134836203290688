/* eslint-disable react/destructuring-assignment */
import React from 'react'
import PropTypes from 'prop-types'

import NumberFormat from 'react-number-format'

const PhoneNumberFormat = (props) => {
	const { inputRef, onChange, ...other } = props

	return (
		<NumberFormat
			/* eslint-disable react/jsx-props-no-spreading */
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				})
			}}
			format="(###) ###-####"
			mask="_"
		/>
	)
}

PhoneNumberFormat.propTypes = {
	inputRef: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
}

export default PhoneNumberFormat
