/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import useStyles from './styles'
import axiosUser from '../../../../../../../utils/apiClient'
import SessionContext from '../../../../../../../context/session'

const MultiSelectedForm = ({ labelOutline, placesHolderText, setSelected, selected }) => {
	const classes = useStyles()
	const [options, setOptions] = useState([])
	const { getUserData } = useContext(SessionContext)
	const { actualCompany } = getUserData()
	const handleOnChangeText = (event) => {
		;(async () => {
			const config = {
				headers: { 'X-Company-id': Number(actualCompany) },
			}
			const { data, status } = await axiosUser.get(
				`/user/list/0/10${event.target.value === '' ? '' : `?name=${event.target.value}`}`,
				config,
			)
			if (status === 200) {
				const newOptions = data?.data?.users ?? []
				setOptions(newOptions)
			}
		})()
	}

	return (
		<Autocomplete
			id="MultiSelectedFormUserAccount"
			fullWidth
			className={classes.autoComplete}
			multiple
			options={options}
			onInputChange={handleOnChangeText}
			value={selected}
			getOptionLabel={(option) => option.name}
			onChange={(e, selectedList) => setSelected(selectedList)}
			renderInput={(params) => (
				<TextField
					{...params}
					label={labelOutline}
					variant="outlined"
					placeholder={placesHolderText}
					className={classes.textFiled}
				/>
			)}
		/>
	)
}

MultiSelectedForm.defaultProps = {
	labelOutline: '',
	placesHolderText: '',
}

MultiSelectedForm.propTypes = {
	labelOutline: PropTypes.string,
	placesHolderText: PropTypes.string,
	setSelected: PropTypes.func.isRequired,
	selected: PropTypes.arrayOf(PropTypes.any).isRequired,
}

export default MultiSelectedForm
