import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
	Box,
	Card,
	CardHeader,
	CardMedia,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Tooltip,
	useTheme,
	useMediaQuery,
} from '@material-ui/core'
import { ResponsiveBar } from '@nivo/bar'
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined'
import { Link } from 'react-router-dom'
import { months, reportsRoute } from '../../../../utils/constants'
import { DownloadIcon } from '../../../../assets/Icons/Icons'
import useStyles from './styles'
import SessionContext from '../../../../context/session'

const BarChart = ({ data, selectData, title, setFilter }) => {
	const classes = useStyles()
	const theme = useTheme()
	const matchesXL = useMediaQuery(theme.breakpoints.up('xl'))
	const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))
	const [carrier, setCarrier] = useState('all')
	const { getUserData, getTokenUser } = useContext(SessionContext)
	const { actualCompany } = getUserData()
	const token = getTokenUser()
	const [chartData, setChartData] = useState([])

	const handleChange = (event) => {
		setCarrier(event.target.value)
		setFilter(event.target.value)
	}

	const chartOptions = {
		layout: 'vertical',
		valueScale: { type: 'linear' },
		minValue: 'auto',
		maxValue: !data.length ? 100 : 'auto',
		padding: matchesSM ? 0.2 : 0.55,
		enableLabel: false,
		enableGridY: true,
		isInteractive: true,
		colors: [theme.palette.secondary.main],
		margin: {
			top: 25,
			right: 0,
			bottom: 23,
			left: 30,
		},
		borderRadius: 4,
		axisBottom: {
			tickSize: 0,
			tickPadding: 10,
		},
		animate: true,
		theme: {
			fontSize: matchesSM ? 8 : 11,
			textColor: theme.palette.neutral.darkGray,
			grid: {
				line: {
					stroke: theme.palette.neutral.lightGray,
					strokeWidth: 1,
				},
			},
		},
	}

	const handleDownloadCSV = () => {
		const neededCarrier = () => (carrier === 'all' || carrier === '' ? '' : carrier)
		const p = {
			initial_date: moment(new Date()).subtract(365, 'days').format('YYYY-MM-DD'),
			end_date: moment(new Date()).format('YYYY-MM-DD'),
			scac: neededCarrier(),
			status: 'DELIVERED',
		}

		Object.keys(p).forEach((key) => p[key] === '' && delete p[key])

		const qs = Object.keys(p)
			.map((key) => `${key}=${p[key]}`)
			.join('&')
		const url = `${process.env.REACT_APP_BACKEND_REPORTS_BASE_URL}/report/shipment/dashboard-graphs?${qs}`

		const oReq = new XMLHttpRequest()
		oReq.open('GET', url, true)
		oReq.responseType = 'arraybuffer'
		oReq.setRequestHeader('X-Company-id', Number(actualCompany))
		oReq.setRequestHeader('Authorization', `Bearer ${token}`)

		oReq.onload = function dowload() {
			const blob = new Blob([oReq.response], { type: 'text/csv' })
			const URL = window.URL || window.webkitURL
			const dataUrl = URL.createObjectURL(blob)
			const downloadLink = document.createElement('a')
			const fileName = `${neededCarrier() === '' ? 'all-' : `${neededCarrier()}-`}shipment-count.csv`

			downloadLink.href = dataUrl
			downloadLink.download = fileName
			downloadLink.click()
		}

		oReq.send()
	}

	useEffect(() => {
		if (data.length) {
			const computedData = data.map(({ count, month, year }) => ({
				month: matchesXL
					? `${months.find(({ id }) => id === month).value}-${year}`
					: `${months.find(({ id }) => id === month).value}-${`${year}`.slice(-2)}`,
				value: Number(count),
			}))
			setChartData(computedData)
		} else {
			setChartData(
				months.map(({ value }) => ({
					month: value,
					value: 0,
				})),
			)
		}
	}, [data, matchesXL])

	return (
		<Card>
			<CardHeader
				classes={{
					root: classes.cardHeader,
					title: classes.cardHeaderTitle,
					action: classes.cardHeaderAction,
				}}
				title={title}
				action={
					<>
						<Tooltip title="Go to Report">
							<Link to={`${reportsRoute}?report=shipment-count&carrier=${carrier}`} style={{ textDecoration: 'none' }}>
								<IconButton aria-label="Go to Report" size="small" className={classes.cardHeaderActionButton}>
									<AssessmentOutlinedIcon />
								</IconButton>
							</Link>
						</Tooltip>
						<Tooltip title="Download as CSV">
							<IconButton
								arial-label="Download CSV"
								size="small"
								className={classes.cardHeaderActionButton}
								disabled={!data.length}
								onClick={() => handleDownloadCSV()}
							>
								<DownloadIcon />
							</IconButton>
						</Tooltip>
					</>
				}
			/>
			<CardMedia className={classes.cardMedia}>
				<FormControl variant="outlined" className={classes.formControl} size="small">
					<InputLabel id="carrier-select-label">Select a Carrier</InputLabel>
					<Select
						labelId="carrier-select-label"
						id="carrier-select"
						value={carrier}
						onChange={handleChange}
						label="Select a Carrier"
					>
						<MenuItem value="all" key="select-all">
							All Carriers
						</MenuItem>
						{selectData.map(({ name, scac }) => (
							<MenuItem value={scac} key={scac}>
								{name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<Box className={classes.chartWrapper}>
					<ResponsiveBar
						data={chartData}
						keys={['value']}
						indexBy="month"
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...chartOptions}
					/>
				</Box>
			</CardMedia>
		</Card>
	)
}

BarChart.propTypes = {
	data: PropTypes.arrayOf(PropTypes.any).isRequired,
	selectData: PropTypes.arrayOf(PropTypes.any).isRequired,
	title: PropTypes.string.isRequired,
	setFilter: PropTypes.func.isRequired,
}

export default BarChart
