import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	confirmation: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		paddingBottom: '16px',
	},
	title: {
		color: theme.palette.secondary.main,
		textAlign: 'center',
		margin: 0,
	},
	text: {
		marginTop: 0,
		marginBottom: '38px',
		textAlign: 'center',
	},
	containerChip: {
		marginTop: 10,
		display: 'flex',
		flexWrap: 'wrap',
		columnGap: 5,
	},
	chip: {
		marginBottom: 5,
	},
	goButton: {
		width: '100%',
		height: '48px',
		alignSelf: 'center',
		textTransform: 'unset',
		fontWeight: '500',
		marginTop: 15,
		'&:hover': {
			backgroundColor: theme.palette.secondary.dark,
		},
		'&:first-of-type': {
			marginBottom: '1em',
		},
	},
	cancelButton: {
		width: '100%',
		height: '48px',
		alignSelf: 'center',
		backgroundColor: '#CCD2D8',
		color: '#2C2C2C',
		textTransform: 'unset',
		'&:hover': {
			backgroundColor: '#b1b7bd',
			color: '#2C2C2C',
		},
		'@media only screen and (min-width: 768px) and (max-width: 1023px)': {
			fontSize: '11px',
		},
	},
	dialogPaper: {
		minWidth: theme.spacing(75),
		borderBottom: '6px solid',
		borderBottomColor: theme.palette.secondary.main,
		padding: '20px 35px',
		overflowY: 'auto',
	},
}))

export default useStyles
