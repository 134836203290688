import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	confirmation: {
		display: 'flex',
		alignItems: 'center',
		padding: '18px 16px',
	},
	iconBg: {
		marginRight: '10px',
		borderRadius: '20px',
		padding: '2px 7px',
	},
	icon: {
		width: '16px',
		color: theme.palette.neutral.black,
	},
	title: {
		fontSize: '20px',
		fontWeight: theme.typography.fontWeightRegular,
		color: theme.palette.secondary.main,
		margin: 0,
	},
	divider: {
		width: '100%',
		height: '1px',
		background: theme.palette.primary.main,
	},
	text: {
		fontSize: '16px',
		fontWeight: 400,
		color: theme.palette.neutral.black,
		padding: '15px 16px 12px 16px',
		margin: 0,
		textAlign: 'left',
	},
	deleteLocationButton: {
		width: '92%',
		height: '48px',
		alignSelf: 'center',
		textTransform: 'unset',
		fontSize: '17px',
		fontWeight: '600',
		marginBottom: '16px',
	},
	goBack: {
		width: '92%',
		height: '48px',
		alignSelf: 'center',
		textTransform: 'unset',
		fontSize: '17px',
		fontWeight: '600',
		marginBottom: '16px',
		color: theme.palette.neutral.black,
	},
}))

export default useStyles
