import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	container: {
		padding: '22px 16px 0 16px',
	},
	containerTable: {
		marginTop: 17,
	},
	documentsMessageTitle: {
		fontSize: 17,
		fontWeight: theme.typography.fontWeightBold,
		color: theme.palette.neutral.darkGray,
	},
}))

export default useStyles
