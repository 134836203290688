import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import { Star } from '@material-ui/icons'
import { Tooltip } from '@material-ui/core'
import useStyles from './style'
import MenuList from '../MenuList'
import { HazIcon, StackIcon } from '../../../../../assets/Icons/Icons'
import { updateItemRoute } from '../../../../../utils/constants'

const TemplateItemsRow = (props) => {
	const classes = useStyles()
	const { data } = props
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)
	const history = useHistory()

	const handleOpenMenu = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleEditItem = (event, itemIdOverride = null) => {
		const splitRoute = updateItemRoute.split('/')
		history.push(`/${splitRoute[1]}/${itemIdOverride === null ? data.id : itemIdOverride}`)
	}

	return (
		<>
			<TableRow className={classes.tableRow} role="checkbox" tabIndex={-1} key={data.id}>
				<TableCell className={classes.tableCell} align="center">
					{data.default && <Star className={classes.iconYellow} />}
				</TableCell>
				<TableCell className={classes.tableCell}>{data.description}</TableCell>
				<TableCell className={classes.tableCell}>
					{data.is_haz_mat && (
						<Tooltip title="Hazmat such as chemicals, acid, waste with a UN number">
							<span className={classes.iconWrapper}>
								<HazIcon />
							</span>
						</Tooltip>
					)}
					{data.stackable && (
						<Tooltip title="Items that can be stacked to reduce linear space">
							<span className={classes.iconWrapper}>
								<StackIcon />
							</span>
						</Tooltip>
					)}
				</TableCell>
				<TableCell className={classes.tableCell}>{data.pieces_value || '-'}</TableCell>
				<TableCell align="left" className={classes.tableCell}>
					{data.pieces_unit || '-'}
				</TableCell>
				<TableCell align="left" className={classes.tableCell}>
					{data.weight_value || '-'}
				</TableCell>
				<TableCell align="left" className={classes.tableCell}>
					{data.class || '-'}
				</TableCell>
				<TableCell align="left" className={classes.tableCell}>
					{data.nmfc || '-'}
					{data.sub_nmfc ? <>-{data.sub_nmfc}</> : null}
				</TableCell>
				<TableCell align="left" className={classes.tableCell}>
					{data.dimensions_width || data.dimensions_height || data.dimensions_lenght
						? `${Number(data.dimensions_width)}" x ${Number(data.dimensions_height)}" x ${Number(data.dimensions_lenght)}"`
						: '-'}
				</TableCell>
				<TableCell align="left" className={classes.tableCell}>
					{data.name || '-'}
				</TableCell>
				<TableCell className={classes.tableCell}>
					<IconButton aria-label="toggle action menu" onClick={handleOpenMenu} onMouseDown={null}>
						<MoreVertIcon style={{ color: '#2C2C2C' }} />
					</IconButton>
				</TableCell>
			</TableRow>
			<MenuList
				anchorEl={anchorEl}
				open={open}
				handleClose={handleClose}
				handleEditItem={handleEditItem}
				templateItemId={data.id}
				templateItemDefault={data.default}
			/>
		</>
	)
}

TemplateItemsRow.propTypes = {
	data: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default TemplateItemsRow
