import { createSlice, Dispatch } from '@reduxjs/toolkit'
import type { EditCarrierRequest, IGetCarrierIntegrationResponseDto } from 'redux/requests/Carriers.actions.types'
import {
	reqGetAccountCarriers,
	reqGetCarriers,
	reqSaveAccountCarriersConfiguration,
	reqUpdateAccountCarriersConfiguration,
} from '../requests/Users.requests'
import {
	reqActiveDeactivateCarrier,
	reqBulkCarrierStatus,
	reqGetConfigCarriers,
	reqCreateCarrier,
	reqGetCarrierByCompany,
	reqEditCarrier,
} from '../requests/Carriers.requests'

/* eslint no-param-reassign: ["error", { "props": false }] */

const carriersSlice = createSlice({
	name: 'carriersSlice',
	initialState: {
		carriers: [],
		carriersList: [],
		filterApplied: '',
		pagSkip: '',
		isLoading: false,
		error: false,
		totalCarriers: 0,
	},
	reducers: {
		startLoading: (state) => {
			state.isLoading = true
		},
		hasError: (state, action) => {
			state.error = action.payload
			state.isLoading = false
		},
		carriersHasError: (state, action) => {
			state.error = action.payload
			state.isLoading = false
		},
		carriersSuccess: (state, action) => {
			state.carriers = action.payload
			state.isLoading = false
		},
		carriersListSuccess: (state, action) => {
			state.carriersList = action.payload
			state.isLoading = false
		},
		updatePagSkip: (state, action) => {
			state.pagSkip = action.payload
		},
		updateFilterApplied: (state, action) => {
			state.filterApplied = action.payload
		},
		updateTotalCarriers: (state, action) => {
			state.totalCarriers = action.payload
		},
	},
})

const { actions, reducer } = carriersSlice

export const {
	startLoading,
	hasError,
	carriersSuccess,
	updateFilterApplied,
	updatePagSkip,
	carriersListSuccess,
	updateTotalCarriers,
} = actions

export const getCarriers = (config: { headers: { 'X-Company-id': number } }) => async (dispatch: Dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqGetCarriers(config)
	if (error) {
		dispatch(hasError(data))
		return { status: 'error', resp: data }
	}
	dispatch(carriersSuccess(data.data.data))
	return { status: 'success', resp: data }
}

export const fetchCarriersList = (config, params) => async (dispatch: Dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqGetAccountCarriers(config, params)

	if (error) {
		dispatch(hasError(error))
		return { status: 'error', resp: error }
	}
	dispatch(updateTotalCarriers(data.data.data.records))
	dispatch(carriersListSuccess(data.data.data.carriers))
	return { status: 'success', resp: data.data.data.carriers }
}

export const fetchConfigCarriersList = (params) => async (dispatch: Dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqGetConfigCarriers(params)

	if (error) {
		dispatch(hasError(error))
		return { status: 'error', resp: error }
	}

	dispatch(updateTotalCarriers(data?.data?.data?.records ?? 0))
	dispatch(carriersListSuccess(data?.data?.data?.carriersRateAccess ?? []))
	return { status: 'success', resp: data?.data?.data?.carriersRateAccess ?? [] }
}

export const createCarrier = (params) => async (dispatch: Dispatch) => {
	const [error, data] = await reqCreateCarrier(params)
	if (error) {
		dispatch(hasError(data))
		return { status: 'error', resp: data }
	}
	return { status: 'success', resp: data }
}

export const saveAccountCarriersConfiguration = (body) => async (dispatch: Dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqSaveAccountCarriersConfiguration(body)

	if (error) {
		dispatch(hasError())
		return { status: 'error', resp: data.data.data }
	}
	return { status: 'success', resp: data.data.data }
}

export const updateAccountCarriersConfiguration = (body, id) => async (dispatch: Dispatch) => {
	const [error, data] = await reqUpdateAccountCarriersConfiguration(body, id)
	if (error) {
		dispatch(hasError())
		return { status: 'error', resp: data.message || data.err.message }
	}
	return { status: 'success', resp: data.data.data }
}

export const updatePaginator = (params) => async (dispatch: Dispatch) => {
	dispatch(updatePagSkip(params))
}

export const updateCarrierStatus = (params) => async (dispatch: Dispatch) => {
	const [error, data] = await reqActiveDeactivateCarrier(params)
	if (error) {
		dispatch(hasError(data))
		return { status: 'error', resp: data }
	}
	return { status: 'success', resp: data }
}

export const updateCarrierStatusBulk = (params) => async (dispatch: Dispatch) => {
	const [error, data] = await reqBulkCarrierStatus(params)
	if (error) {
		dispatch(hasError(data))
		return { status: 'error', resp: data }
	}
	return { status: 'success', resp: data }
}

export const fetchCarrierById =
	(carrierId: string) =>
	async (dispatch: Dispatch): Promise<{ status: 'error' | 'success'; resp: IGetCarrierIntegrationResponseDto }> => {
		dispatch(startLoading())
		const [error, data] = await reqGetCarrierByCompany(carrierId)

		if (error) {
			dispatch(hasError(error))
			return { status: 'error', resp: error }
		}
		return { status: 'success', resp: data.data.data }
	}

export const editCarrier =
	(carrierId: string, params: EditCarrierRequest) =>
	async (dispatch: any): Promise<{ status: 'error' | 'success'; resp: any }> => {
		dispatch(startLoading())
		const [error, data] = await reqEditCarrier(carrierId, params)

		if (error) {
			dispatch(hasError(data))
			return { status: 'error', resp: data }
		}
		return { status: 'success', resp: data }
	}

export default reducer
