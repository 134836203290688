import axios from 'axios'

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_GEOCODE_API_KEY

const isoCountryCodeMap = {
	CA: 'CAN',
	US: 'USA',
}

const cityNameOverridesByZipcode = {
	11788: ['Hauppauge'],
	13090: ['Liverpool'],
	14224: ['West Seneca'],
	20774: ['Marlboro', 'Upper Marlboro'],
	22603: ['Winchester'],
	33178: ['Miami'],
	45241: ['Cincinnati'],
	45246: ['Cincinnati'],
	46254: ['Indianapolis'],
	48710: ['University Center'],
	80112: ['Englewood'],
	91331: ['Pacoima'],
	94568: ['Dublin'],
}

export const chooseMostRelevantCityName = (cityCandidates, cityStateZipLabel) => {
	if (cityCandidates.length === 0) {
		return null
	}
	const valueInLabel = cityCandidates.filter((candidate) => {
		const labelContainsShortName = cityStateZipLabel.toLowerCase().indexOf(candidate.short_name.toLowerCase()) === 0
		return labelContainsShortName
	})

	if (valueInLabel.length > 0) {
		// pick longest one
		return valueInLabel
			.map((label) => label.short_name)
			.reduce((previous, current) => (previous.length > current.length ? previous : current), '')
	}

	// fall back to using the address component type
	// https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingAddressTypes
	const orderOfImportance = [
		'locality', // actual city name
		'neighborhood', // neighborhood name
		'administrative_area_level_3', // township
		'sublocality', // first-order civil entity below a locality
		'sublocality_level_1', // 1 is least specific
		'sublocality_level_2',
		'sublocality_level_3',
		'sublocality_level_4',
		'sublocality_level_5', // 5 is most specific

		'colloquial_area',
		'premise',
		'subpremise',

		'administrative_area_level_2', // county
		'administrative_area_level_4', // "Not all nations exhibit these administrative levels"
		'administrative_area_level_5', // "Not all nations exhibit these administrative levels"
		'administrative_area_level_6', // "Not all nations exhibit these administrative levels"
		'administrative_area_level_7', // "Not all nations exhibit these administrative levels"

		'postal_town',
		'natural_feature',
		'airport',
		'park',
		'point_of_interest',
		'establishment',
		'parking',
		'post_box',
		'bus_station',
		'train_station',
		'transit_station',
	]

	let city
	orderOfImportance.find((label) => {
		city = cityCandidates.find((candidate) => candidate.types.includes(label))
		return !!city
	})
	if (city) {
		return city.short_name
	}
	// at this point, cityCandidates do not contain known types.
	// this is valid because google's documentation says they can add new types at will.
	// pick random because at this point we have no way of determining correct one
	return cityCandidates[0].short_name
}

export const parseGoogleGeocodeData = (googleAddressData) => {
	const parsedResponse = {}

	const cityNameCandidates = []

	googleAddressData.address_components.forEach((component) => {
		if (component.types.includes('postal_code')) {
			parsedResponse.postalCode = component.short_name
		} else if (component.types.includes('administrative_area_level_1')) {
			parsedResponse.state = component.short_name
		} else if (component.types.includes('country')) {
			parsedResponse.country = isoCountryCodeMap[component.short_name] || component.short_name
		} else {
			cityNameCandidates.push(component)
		}
	})

	// maybe replace this label with city, state, country
	parsedResponse.latitude = googleAddressData.geometry.location.lat
	parsedResponse.longitude = googleAddressData.geometry.location.lng

	const mainCityName = chooseMostRelevantCityName(cityNameCandidates, googleAddressData.formatted_address)
	let alternateNames = googleAddressData.postcode_localities ?? []

	if (cityNameOverridesByZipcode[parsedResponse.postalCode]) {
		alternateNames = alternateNames.concat(cityNameOverridesByZipcode[parsedResponse.postalCode])
	}

	if (!alternateNames.includes(mainCityName)) {
		alternateNames.push(mainCityName)
	}

	return alternateNames.map((cityName) => ({
		...parsedResponse,
		city: cityName,
		label: `${cityName}, ${parsedResponse.state} ${parsedResponse.postalCode}, ${parsedResponse.country}`,
	}))
}

const parseGoogleApiZipCodeData = (googleResponse) => {
	const addresses = []
	googleResponse.forEach((response) => addresses.push(...parseGoogleGeocodeData(response)))
	return addresses.filter((address) => address.country === 'CAN' || address.country === 'USA')
}

const callGoogleApi = async (zipCode) => {
	const googleApiUrl = 'https://maps.googleapis.com/maps/api/geocode/json'

	delete axios.defaults.headers.common.Authorization
	delete axios.defaults.headers.common['x-company-id']
	delete axios.defaults.headers.common['X-Company-Id']

	const zipFilter = `components=postal_code:${zipCode}`
	const apiKey = `key=${GOOGLE_MAPS_API_KEY}`

	// search US (fixes some errors)
	let [error, data] = await axios
		.get(`${googleApiUrl}?${zipFilter}|country:US&${apiKey}`)
		.then((result) => [null, result])
		.catch((err) => [err])

	// search canada if US returns no issues
	if (data?.data?.results?.length === 0) {
		;[error, data] = await axios
			.get(`${googleApiUrl}?${zipFilter}|country:CA&${apiKey}`)
			.then((result) => [null, result])
			.catch((err) => [err])
	}

	if (
		localStorage.getItem(
			'@@auth0spajs@@::JaVrId5sFvfRTfLmoVRAd1S1qLl42oKP::freigthpros.dev.com::openid profile email auth0-store',
		)
	) {
		const { body } = JSON.parse(
			localStorage.getItem(
				'@@auth0spajs@@::JaVrId5sFvfRTfLmoVRAd1S1qLl42oKP::freigthpros.dev.com::openid profile email auth0-store',
			),
		)
		if (body.access_token) {
			axios.defaults.headers.common.Authorization = `Bearer ${body.access_token}`
			axios.defaults.headers.common['x-company-id'] = 1
			axios.defaults.headers.common['X-Company-Id'] = 1
		}
	}

	return { error, data }
}

export const fetchDataFromZipCode = async (zipCode) => {
	let zipToSearch = zipCode
	if (/[a-zA-Z]/g.test(zipCode)) {
		const zipArray = zipToSearch.split('')
		if (zipArray[3] && zipArray[3] !== ' ') {
			zipArray.splice(3, 0, ' ')
		}
		zipToSearch = zipArray.join('')
	}

	const { error, data } = await callGoogleApi(zipToSearch)

	let totalParsedData = []
	if (data.data.results.length > 0) {
		totalParsedData = parseGoogleApiZipCodeData(data.data.results)
	}

	return [error, totalParsedData]
}
