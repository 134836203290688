import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	paper: {
		width: '100%',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		border: `1px solid ${theme.palette.neutral.whiteLightGray}`,
		borderRadius: 6,
		overflow: 'hidden',
	},
	table: {
		minWidth: 750,
	},
	tableCell: {
		backgroundColor: theme.palette.neutral.lightGray,
		fontSize: 12,
		fontWeight: theme.typography.fontWeightBold,
	},
	tablePaginator: {
		fontSize: 12,
		color: theme.palette.neutral.black,
	},
	tablePaginatorToolbar: {
		minHeight: 43,
	},
	tablePaginatorActions: {
		'& .MuiIconButton-root': {
			padding: 8,
		},
	},
}))

export default useStyles
