import React, { useState } from 'react'
import { Dialog, Button } from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'
import { useHistory } from 'react-router-dom'
import { quotesListRoute } from '../../../../utils/constants'

import useStyles from './styles'

const QuoteSavedDialog = () => {
	const [open, setOpen] = useState(true)
	const classes = useStyles()
	const history = useHistory()

	const handleCreateAnotherQuote = () => {
		window.location.reload()
	}

	const handleGoToQuoteList = () => {
		history.push(quotesListRoute)
		setOpen(false)
	}

	return (
		<Dialog
			open={open}
			disableBackdropClick
			disableEscapeKeyDown
			onClick={(event) => event.stopPropagation()}
			aria-labelledby="form-dialog-title"
			PaperProps={{
				className: classes.dialogPaper,
			}}
		>
			<div className={classes.confirmation}>
				<GetAppIcon color="secondary" style={{ marginRight: '10px' }} fontSize="small" />
				<h1 className={classes.title}>Quote Saved</h1>
			</div>
			<div className={classes.divider} />
			<span className={classes.text}>
				Your quote has been saved successfully. When you&apos;re ready to schedule this shipment, click Saved Quotes to
				convert your quote to a shipment.
			</span>
			<Button className={classes.goButton} onClick={handleCreateAnotherQuote} color="secondary" variant="contained">
				Create another quote
			</Button>
			<Button className={classes.quoteButton} onClick={handleGoToQuoteList} variant="contained" color="action">
				Go to quote list
			</Button>
		</Dialog>
	)
}

export default QuoteSavedDialog
