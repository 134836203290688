import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { makeStyles, Dialog, Button } from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'

import { useHistory } from 'react-router-dom'
import { quotesListRoute, shipmentsListRoute } from '../../../../../utils/constants'

const useStyles = makeStyles(() => ({
	confirmation: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: '1em',
		padding: '10px 0',
	},
	title: {
		fontSize: '1em',
		color: 'gray',
	},
	divider: {
		width: '100%',
		height: '1px',
		background: 'gray',
	},
	text: {
		marginLeft: '1em !important',
		marginTop: '1em',
		marginBottom: '2em',
	},
	goButton: {
		width: '74%',
		height: '48px',
		alignSelf: 'center',
		textTransform: 'unset',
		fontWeight: '500',
		marginBottom: '1em',
		'&:hover': {
			backgroundColor: '#F30000',
		},
	},
}))

const ShipmentSavedDialog = (props) => {
	const { shipmentId, handleOpenModal } = props

	const [open, setOpen] = useState(true)
	const classes = useStyles()
	const history = useHistory()

	const handleGoToQuoteList = () => {
		history.push(quotesListRoute)
		setOpen(false)
	}

	const handleGoToShipmentList = () => {
		history.push(shipmentsListRoute)
		setOpen(false)
	}

	return (
		<Dialog
			open={open}
			disableBackdropClick
			disableEscapeKeyDown
			onClick={(event) => event.stopPropagation()}
			aria-labelledby="form-dialog-title"
			PaperProps={{
				style: {
					height: '360px',
					width: '400px',
					borderBottom: '6px solid #ff3333',
				},
			}}
		>
			<div className={classes.confirmation}>
				<GetAppIcon style={{ color: '#EC3514', marginRight: '10px' }} fontSize="small" />
				<h1 className={classes.title}>Shipment Booked</h1>
			</div>
			<div className={classes.divider} />
			<span className={classes.text}>{`Your Shipment has been booked successfully. Shipment id: ${shipmentId}`}</span>
			<Button className={classes.goButton} color="primary" variant="contained" onClick={handleOpenModal}>
				Send confirmation email
			</Button>
			<Button className={classes.goButton} onClick={handleGoToQuoteList} color="primary" variant="contained">
				Go to quote list
			</Button>
			<Button className={classes.goButton} onClick={handleGoToShipmentList} color="primary" variant="contained">
				Go to my shipments
			</Button>
		</Dialog>
	)
}

ShipmentSavedDialog.propTypes = {
	shipmentId: PropTypes.number.isRequired,
	handleOpenModal: PropTypes.func.isRequired,
}

export default ShipmentSavedDialog
