import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTheme } from '@material-ui/core/styles'
import { TableCell, TableHead, TableRow, Checkbox } from '@material-ui/core'
import { SortListIcon, AscendingIcon, DescendingIcon } from '../../../../../assets/Icons/Icons'

import { carrierTableCells, carrierListTableCells, carrierContractListTableCells } from '../../../../../utils/headCells'
import useStyles from './styles'

const TableHeader = (props) => {
	const classes = useStyles()
	const theme = useTheme()
	const {
		handleSortColumn,
		selected,
		handleSelectAllClick,
		rowCount,
		isSelectableList,
		isMainCarrierList,
		showRadios,
	} = props
	const carriersFilters = useSelector((state) => state.carriersFilters)

	const showIcon = (cell) => {
		if (cell.id === carriersFilters.orderBy && carriersFilters.order === 'asc') {
			return <AscendingIcon onClick={() => handleSortColumn(cell.id)} style={{ alignSelf: 'center' }} />
		}
		if (cell.id === carriersFilters.orderBy && carriersFilters.order === 'desc') {
			return <DescendingIcon onClick={() => handleSortColumn(cell.id)} style={{ alignSelf: 'center' }} />
		}
		return <SortListIcon onClick={() => handleSortColumn(cell.id)} style={{ alignSelf: 'center' }} />
	}
	const sortableColumns = (cell) => {
		if (cell.sortable) {
			return (
				<div
					onClick={() => handleSortColumn(cell.id)}
					onKeyPress={() => handleSortColumn(cell.id)}
					style={{ display: 'flex', alignItems: 'flex-end', cursor: 'pointer' }}
					aria-hidden="true"
				>
					{cell.label}
					{showIcon(cell)}
				</div>
			)
		}
		return <div style={{ display: 'flex', alignItems: 'flex-end' }}>{cell.label}</div>
	}

	const customHeader = () => {
		if (showRadios) {
			return (
				<>
					<TableCell className={classes.tableCell} />
					{carrierContractListTableCells.map((headCell, index) => (
						<TableCell key={headCell.id} className={classes.tableCell}>
							{carrierContractListTableCells.length !== index + 1 ? sortableColumns(headCell) : headCell.label}
						</TableCell>
					))}
				</>
			)
		}
		if (isMainCarrierList) {
			return (
				<>
					{carrierListTableCells.map((headCell, index) => (
						<TableCell key={headCell.id} className={classes.tableCell}>
							{carrierListTableCells.length !== index + 1 ? sortableColumns(headCell) : headCell.label}
						</TableCell>
					))}
				</>
			)
		}
		return (
			<>
				{carrierTableCells.map((headCell, index) => (
					<TableCell key={headCell.id} className={classes.tableCell}>
						{carrierTableCells.length !== index + 1 ? sortableColumns(headCell) : headCell.label}
					</TableCell>
				))}
			</>
		)
	}

	return (
		<TableHead>
			<TableRow>
				{isSelectableList && (
					<TableCell padding="checkbox" className={classes.tableCell} align="center">
						<Checkbox
							indeterminate={selected.length > 0 && selected.length < rowCount}
							checked={rowCount > 0 && selected.length === rowCount}
							onChange={handleSelectAllClick}
							inputProps={{ 'aria-label': 'select all accounts' }}
							style={{
								color:
									(rowCount > 0 && selected.length === rowCount) || (selected.length > 0 && selected.length < rowCount)
										? theme.palette.secondary.main
										: theme.palette.primary.light,
							}}
							size="small"
						/>
					</TableCell>
				)}
				{customHeader()}
				{isSelectableList && (
					<TableCell className={classes.tableCell} align="center">
						ACTIONS
					</TableCell>
				)}
			</TableRow>
		</TableHead>
	)
}

TableHeader.defaultProps = {
	showRadios: false,
}

TableHeader.propTypes = {
	handleSortColumn: PropTypes.func.isRequired,
	selected: PropTypes.arrayOf(PropTypes.any).isRequired,
	handleSelectAllClick: PropTypes.func.isRequired,
	rowCount: PropTypes.number.isRequired,
	isSelectableList: PropTypes.bool.isRequired,
	isMainCarrierList: PropTypes.bool.isRequired,
	showRadios: PropTypes.bool,
}

export default TableHeader
