/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initState = {
	currentPage: 0,
	rowsPerPage: 25,
	status: '',
	searchBar: '',
	orderBy: 'audit_creation_date',
	orderType: 'DESC',
}

const actionsFilters = createSlice({
	name: 'actionsFilters',
	initialState: {
		...initState,
	},
	reducers: {
		setCurrentPage: (state, action) => {
			state.currentPage = action.payload
		},
		setRowsPerPage: (state, action) => {
			state.rowsPerPage = action.payload
		},
		setStatus: (state, action) => {
			state.status = action.payload
		},
		setSearchBar: (state, action) => {
			state.searchBar = action.payload
		},
		setStartDate: (state, action) => {
			state.startDate = action.payload
		},
		setEndDate: (state, action) => {
			state.endDate = action.payload
		},
		setOrderBy: (state, action) => {
			state.orderBy = action.payload
		},
		setOrderType: (state, action) => {
			state.orderType = action.payload
		},
		resetFilters: (state) => {
			state.status = initState.status
			state.searchBar = initState.searchBar
			state.orderBy = initState.orderBy
			state.orderType = initState.orderType
		},
	},
})

export const { setCurrentPage, setRowsPerPage, setSearchBar, setStatus, setOrderBy, setOrderType, resetFilters } =
	actionsFilters.actions

const { reducer } = actionsFilters

export default reducer
