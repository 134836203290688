import React, { useState, useEffect, useContext, useMemo, useRef, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { Button, Grid, TextField } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import AddIcon from '@material-ui/icons/Add'
import { BackupOutlined } from '@material-ui/icons'
import { useParams, useLocation } from 'react-router-dom'
import { setTabChildAccounts } from '../../../../../redux/actions/MultiTabs.actions'
import { InformationIcon } from '../../../../../assets/Icons/Icons'
import {
	createAccounts,
	fetchAccountInformation,
	editAccountStepStatus,
	setParentCompanyInUpdate,
	setChildrenCompaniesInUpdate,
} from '../../../../../redux/actions/Accounts.actions'
import { fetchChildsCompanies } from '../../../../../redux/actions/Company.actions'
import DragAndDropImage from '../../components/DragAndDropImage'
import PhoneNumberFormat from '../../components/PhoneNumberFormat'
import SweetAlertModal from '../../../../../components/SweetAlertModal/SweetAlertModal'
import useStyles from './styles'
import ChildAccountsAdded from '../../components/ChildAccountsAdded'
import ChildAccountsForm from '../../components/ChildAccountsForm/ChildAccountsForm'
import SessionContext from '../../../../../context/session'
import UploadLocationsModal from '../../../../locations/myLocations/containers/LocationsContainer/components/UploadLocationsModal/UploadLocationsModal'
import { TMS_ACCOUNT_NUMBER } from '../../../../../utils/constants'

const AccountInformationStepper = forwardRef(
	({ setActiveStep, activeStep, isEdit, isEditable, handleCancelAccountProcess, setAccountNameTitle }, refGlobal) => {
		const classes = useStyles()
		const [accountLogo, setAccountLogo] = useState([])
		const [accountContactPhone, setAccountContactPhone] = useState('')
		const [childAccounts, setChildAccounts] = useState([])
		const currentTabs = useSelector((state) => state.multiTabs.tabs)
		const accountTabs = currentTabs.find((item) => item.active) ?? {}
		const location = useLocation()
		const currentstep = location?.state?.step
		const { parentCompanyId } = accountTabs.accounts ?? ''

		const [accountForm, setAccountForm] = useState([])
		const accountInputsRef = useRef([])
		const accountsRef = useRef(childAccounts)

		const { getUserData } = useContext(SessionContext)
		const dispatch = useDispatch()
		const { id } = useParams()
		const [modalOpenMassUploadLocations, setModalOpenMassUploadLocations] = useState(false)

		const config = useMemo(() => {
			const { actualCompany } = getUserData()
			const newConfig = {
				headers: {
					'X-Company-id': id || Number(actualCompany),
				},
			}
			return newConfig
		}, [id, getUserData])

		const { handleSubmit, register, errors, reset, setValue, watch } = useForm({
			defaultValues: {
				customerAccountNumber: TMS_ACCOUNT_NUMBER,
			},
		})

		useEffect(() => {
			accountsRef.current = childAccounts
		}, [childAccounts])

		const resetFields = () => {
			setValue('accountSalesForceNumber', '')
			setValue('accountName', '')
			setValue('customerAccountNumber', '')
			setValue('customerAccountNumberExtension', '')
			setValue('accountContactName', '')
			setAccountContactPhone('')
			setValue('accountContactEmail', '')
			setAccountLogo([])
		}

		const formTexts = {
			errorSubmitText:
				isEditable || isEdit
					? 'There was an error in the update account process!'
					: 'There was an error in the create account process!',
			successSubmitText:
				isEditable || isEdit ? 'Account has been updated successfully!' : 'Account has been saved successfully!',
			nextStepButtonText: isEditable || isEdit ? 'Save Account' : 'Next Step',
		}

		const onNextStep = async (data) => {
			const formData = new FormData()
			const parentId = id ?? parentCompanyId
			if (isEditable || isEdit) {
				formData.append('id', parentId)
			}
			if (accountLogo.length) {
				formData.append('file', accountLogo[0])
			}
			formData.append('name', data.accountName)
			formData.append('salesForceNumber', data.accountSalesForceNumber)
			formData.append('accountNumber', data.customerAccountNumber)
			formData.append('contactName', data.accountContactName ? data.accountContactName : '')
			formData.append('contactPhone', data.accountContactPhone ? data.accountContactPhone : '')
			formData.append('contactPhoneExt', data.accountContactPhoneExtension ? data.accountContactPhoneExtension : '')
			formData.append('contactEmail', data.accountContactEmail ? data.accountContactEmail : '')

			if (accountsRef.current.length) {
				accountsRef.current.forEach((childAccount, index) => {
					if ((isEditable || isEdit) && childAccount.id) {
						formData.append(`children[${index}][id]`, childAccount.id)
					}
					formData.append(`children[${index}][salesForceNumber]`, childAccount.salesForceNumber)
					formData.append(`children[${index}][name]`, childAccount.name)
					formData.append(`children[${index}][accountNumber]`, childAccount.accountNumber)
					formData.append(`children[${index}][contactName]`, childAccount.contact)
					formData.append(`children[${index}][contactPhone]`, childAccount.phone)
					formData.append(
						`children[${index}][contactPhoneExt]`,
						childAccount.contactPhoneExt ? childAccount.contactPhoneExt : '',
					)
					formData.append(`children[${index}][contactEmail]`, childAccount.email)
				})
			}

			const { status, resp } = await dispatch(createAccounts(formData, config))
			if (status === 'error') {
				SweetAlertModal(status, formTexts.errorSubmitText, resp, 'Ok', true, false, null)
			} else {
				await dispatch(fetchChildsCompanies(config.headers['X-Company-id']))
				const companyId = resp.companies.parentCompanyId
				const params = `?companyId=${companyId}&step=1&status=TRUE`
				const { status: stepStatus } = await dispatch(editAccountStepStatus(params, config))
				if (stepStatus === 'error') {
					SweetAlertModal(status, 'There was an error updating the account step status!', resp, 'Ok', true, false, null)
				}
				resetFields()
				reset()
				dispatch(setTabChildAccounts(resp.companies))
				SweetAlertModal('success', formTexts.successSubmitText, null, 'Ok', true, false, null)
				setActiveStep(activeStep + 1)
			}
		}

		useEffect(() => {
			const parentId = id ?? parentCompanyId
			const fetchData = async () => {
				const { status, resp } = await dispatch(fetchAccountInformation(config, parentId))
				if (status === 'success') {
					const { parentCompany, childrenCompanies } = resp
					dispatch(setParentCompanyInUpdate(parentCompany))
					dispatch(setChildrenCompaniesInUpdate(childrenCompanies))
					setAccountNameTitle(parentCompany.name)
					setValue('accountName', parentCompany.name)
					setValue('accountSalesForceNumber', parentCompany.sales_force_number)
					setValue('customerAccountNumber', parentCompany.account_number)
					setValue('accountContactName', parentCompany.contact)
					setValue('accountContactPhoneExtension', parentCompany.phone_extension)
					setAccountContactPhone(parentCompany.phone)
					setValue('accountContactEmail', parentCompany.email)
					const newChildrenCompanies = childrenCompanies.map((childrenCompany) => ({
						...childrenCompany,
						accountNumber: childrenCompany.account_number,
						salesForceNumber: childrenCompany.sales_force_number,
					}))
					setChildAccounts(newChildrenCompanies)
					const childAccountsId = childrenCompanies.map((childrenCompany) => childrenCompany.id)
					const dataStore = {
						parentCompanyId: parentId,
						childrenCompaniesIds: childAccountsId,
					}
					dispatch(setTabChildAccounts(dataStore))
				} else {
					SweetAlertModal('error', 'There was an error fetching the data', resp, 'Ok', true, false, null)
				}
			}
			if (isEditable || isEdit) fetchData()
		}, [isEditable, isEdit, id, parentCompanyId, dispatch, config, setAccountNameTitle, setValue])

		const validateAccountForms = async (data) => {
			if (accountInputsRef.current) {
				const accountsResponse = []
				accountInputsRef.current.forEach((ref, index) => {
					if (ref) {
						setTimeout(() => {
							const response = ref.submitForm()
							accountsResponse[index] = response
						}, 1000)
					}
				})
				setTimeout(() => {
					if (accountsResponse.find((element) => element === 'error')) return
					onNextStep(data)
				}, 1000)
			}
		}
		useEffect(() => {
			if (currentstep !== undefined) {
				setActiveStep(currentstep)
			}
		}, [setActiveStep, currentstep])

		const deleteAccountForm = (accountId) => {
			const newAccount = [...accountForm]
			const filteredAccounts = newAccount.filter((item) => item.id !== accountId)
			setAccountForm(filteredAccounts)
		}

		useImperativeHandle(refGlobal, () => ({
			onSubmit() {
				handleSubmit(validateAccountForms)()
			},
		}))

		return (
			<>
				<div className={classes.newAccountInformationForm}>
					<form id="basicInformationForm" autoComplete="off" onSubmit={handleSubmit(validateAccountForms)}>
						<Grid container spacing={2} className={classes.newAccountBasicInformationSection}>
							<Grid container className={classes.newAccountInformationSectionTitle} justifyContent="space-between">
								<div className={classes.newAccountContainerSubTitle}>
									<div className={classes.newAccountInformationSectionIconContainer}>
										<InformationIcon className={classes.newAccountInformationSectionIcon} />
									</div>
									<p className={classes.newAccountInformationSubtitleLabel}>Basic Information</p>
								</div>
								<Button
									className={classes.headerActionButton}
									startIcon={<BackupOutlined color="secondary" />}
									size="small"
									color="primary"
									onClick={() => setModalOpenMassUploadLocations(true)}
								>
									Upload Multiple Locations
								</Button>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={6} xl={6} className={classes.newAccountInformationDragnDrop}>
								<DragAndDropImage setAccountLogo={setAccountLogo} accountLogo={accountLogo} />
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								<TextField
									name="accountName"
									className={classes.root}
									style={{ width: '100%' }}
									inputRef={register({
										required: true,
										maxLength: {
											value: 45,
											message: 'Maximum 45 characters allowed',
										},
									})}
									error={!!errors.accountName}
									helperText={errors.accountName ? errors.accountName.message : null}
									FormHelperTextProps={{ classes: { root: classes.helperText } }}
									label="Account Name *"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								<TextField
									name="accountSalesForceNumber"
									className={classes.root}
									style={{ width: '100%' }}
									inputRef={register({
										required: true,
										maxLength: {
											value: 45,
											message: 'Maximum 45 characters allowed',
										},
									})}
									error={!!errors.accountSalesForceNumber}
									helperText={errors.accountSalesForceNumber ? errors.accountSalesForceNumber.message : null}
									FormHelperTextProps={{ classes: { root: classes.helperText } }}
									label="Salesforce Account Number *"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								<TextField
									name="customerAccountNumber"
									className={classes.root}
									style={{ width: '100%' }}
									inputRef={register({
										required: true,
										maxLength: {
											value: 45,
											message: 'Maximum 45 characters allowed',
										},
									})}
									error={!!errors.customerAccountNumber}
									helperText={
										(errors.customerAccountNumber ? errors.customerAccountNumber.message : null,
										watch('customerAccountNumber') !== TMS_ACCOUNT_NUMBER ? (
											<Alert variant="filled" severity="warning">
												Are you sure you want to change the TMS Account Number? This should only be changed for
												customers with CSP.
											</Alert>
										) : (
											'*Only change for CSP customers'
										))
									}
									FormHelperTextProps={{ classes: { root: classes.helperText } }}
									label="TMS Account Number *"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
								<TextField
									name="accountContactName"
									type="text"
									className={classes.root}
									style={{ width: '100%' }}
									inputRef={register({
										maxLength: {
											value: 25,
											message: 'Maximum 25 characters allowed',
										},
									})}
									error={!!errors.accountContactName}
									helperText={errors.accountContactName ? errors.accountContactName.message : null}
									FormHelperTextProps={{ classes: { root: classes.helperText } }}
									label="Contact Name"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
								<TextField
									name="accountContactPhone"
									type="text"
									className={classes.root}
									style={{ width: '100%' }}
									value={accountContactPhone}
									onChange={(e) => setAccountContactPhone(e.target.value)}
									inputRef={register({
										pattern: {
											value: /^([(]([0-9]{3})[)] ([0-9]{3})(-)([0-9]{4}))$/,
											message: 'Enter a valid phone number format',
										},
									})}
									error={!!errors.accountContactPhone}
									helperText={errors.accountContactPhone ? errors.accountContactPhone.message : null}
									InputProps={{
										inputComponent: PhoneNumberFormat,
									}}
									FormHelperTextProps={{ classes: { root: classes.helperText } }}
									label="Contact Phone"
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
								<TextField
									name="accountContactPhoneExtension"
									type="number"
									className={classes.root}
									style={{ width: '100%' }}
									inputRef={register({
										maxLength: {
											value: 5,
											message: 'Maximum 5 characters allowed',
										},
										min: { value: 0, message: 'Please enter a valid extension' },
									})}
									error={!!errors.accountContactPhoneExtension}
									helperText={errors.accountContactPhoneExtension ? errors.accountContactPhoneExtension.message : null}
									FormHelperTextProps={{ classes: { root: classes.helperText } }}
									label="Extension"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
								<TextField
									name="accountContactEmail"
									className={classes.root}
									style={{ width: '100%' }}
									inputRef={register({
										pattern: {
											value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
											message: 'Please enter a valid email format',
										},
										maxLength: {
											value: 50,
											message: 'Maximum 50 characters allowed',
										},
									})}
									error={!!errors.accountContactEmail}
									helperText={errors.accountContactEmail ? errors.accountContactEmail.message : null}
									FormHelperTextProps={{ classes: { root: classes.helperText } }}
									label="Contact Email"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
						</Grid>
					</form>
					<Grid container spacing={2} className={classes.newAccountChildAccountsSection}>
						<Grid container className={classes.newAccountInformationSectionTitle}>
							<div className={classes.newAccountInformationSectionIconContainer}>
								<SupervisorAccountIcon className={classes.newAccountInformationSectionIcon} fontSize="small" />
							</div>
							<p className={classes.newAccountInformationSubtitleLabel}>Child Accounts</p>
						</Grid>
						<Grid container className={classes.newChildAccountsForm}>
							<ChildAccountsAdded
								childAccounts={childAccounts}
								setChildAccounts={setChildAccounts}
								accountForm={accountForm}
								setAccountForm={setAccountForm}
								deleteAccountForm={deleteAccountForm}
							/>
						</Grid>
						<Grid container className={classes.newChildAccountsForm}>
							{accountForm.map((item, index) => (
								<ChildAccountsForm
									key={`id-${index + 1}`}
									accountIndex={index}
									item={item}
									childAccounts={childAccounts}
									setChildAccounts={setChildAccounts}
									deleteAccountForm={deleteAccountForm}
									ref={(el) => {
										accountInputsRef.current[index] = el
									}}
								/>
							))}
						</Grid>
						<Grid
							item
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'flex-end',
							}}
							xs={12}
						>
							<Button
								color="primary"
								className={classes.button}
								onClick={() => {
									setAccountForm((prevState) => [
										...prevState,
										prevState.length > 0 ? { id: Number(prevState.slice(-1)[0].id) + 1 } : { id: 0 },
									])
								}}
							>
								<AddIcon className={classes.newChildAccountIcon} />
								Add
							</Button>
						</Grid>
					</Grid>
					<Grid container className={classes.containerButton}>
						<Button
							className={classes.nextStepButton}
							type="submit"
							form="basicInformationForm"
							variant="contained"
							color="secondary"
						>
							{formTexts.nextStepButtonText}
						</Button>
						{(isEditable || isEdit) && (
							<Button
								className={classes.nextStepButtonInEdit}
								type="submit"
								form="basicInformationForm"
								variant="contained"
							>
								Next Step
							</Button>
						)}
						<Button className={classes.cancelButton} onClick={() => handleCancelAccountProcess()}>
							Cancel
						</Button>
					</Grid>
				</div>
				{modalOpenMassUploadLocations && (
					<UploadLocationsModal
						open={modalOpenMassUploadLocations}
						handleClose={() => setModalOpenMassUploadLocations(false)}
					/>
				)}
			</>
		)
	},
)

AccountInformationStepper.propTypes = {
	setAccountNameTitle: PropTypes.func.isRequired,
	setActiveStep: PropTypes.func.isRequired,
	activeStep: PropTypes.number.isRequired,
	isEdit: PropTypes.bool.isRequired,
	isEditable: PropTypes.bool.isRequired,
	handleCancelAccountProcess: PropTypes.func.isRequired,
}

export default AccountInformationStepper
