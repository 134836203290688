import React, { useCallback, useContext, useEffect, useState } from 'react'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import debounce from 'lodash/debounce'
import Button from '@material-ui/core/Button'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { Grid, IconButton, InputAdornment, OutlinedInput, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import BackupOutlined from '@material-ui/icons/BackupOutlined'
import Loop from '@material-ui/icons/Loop'
import Search from '@material-ui/icons/Search'
import FilterList from '@material-ui/icons/FilterList'
import TableList from '../components/TableList/TableList'
import { searchAccountByOptions } from '../../../../utils/dropDownOptions'
import useStyles from './styles'
import SessionContext from '../../../../context/session'
import sweetAlertModal from '../../../../components/SweetAlertModal/SweetAlertModal'
import { setTabCurrentRoute, setTabTitle } from '../../../../redux/actions/MultiTabs.actions'
import { accountsListRoute } from '../../../../utils/constants'
import { fetchAccounts } from '../../../../redux/actions/Accounts.actions'
import useAccountsFilters from '../../../../hooks/useAccountsFilters'
import {
	setCurrentPage,
	setOrderBy,
	setRowsPerPage,
	setSearchBar,
	setStatus,
	setOrderType,
} from '../../../../redux/actions/AccountsFilters.actions'
import UploadAccountsModal from '../components/UploadAccountsModal'

const AccountList = () => {
	const classes = useStyles()
	const theme = useTheme()
	const dispatch = useDispatch()
	const [buildParamsFromState] = useAccountsFilters()
	const { getUserData } = useContext(SessionContext)
	const { actualCompany } = getUserData()
	const { isLoading, accounts, records } = useSelector((state) => state.accounts)
	const [open, setOpen] = useState(false)
	const [inputValue, setInputValue] = useState('')
	const [selectValue, setSelectValue] = useState('')
	const [modalOpen, setModalOpen] = useState(false)
	const matchesMD = useMediaQuery(theme.breakpoints.up('md'))
	const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

	useEffect(() => {
		dispatch(setTabCurrentRoute(accountsListRoute))
		dispatch(setTabTitle('Accounts list'))
	}, [dispatch])

	useEffect(() => {
		const preservedFilters = localStorage.getItem('preserved-accounts-filters')
		if (preservedFilters) {
			const filterObj = preservedFilters.split('&')
			filterObj.forEach((filter) => {
				const filterKeyValue = filter.split('=')
				switch (filterKeyValue[0]) {
					case 'page':
						dispatch(setCurrentPage(Number(filterKeyValue[1])))
						break
					case 'limit':
						dispatch(setRowsPerPage(Number(filterKeyValue[1])))
						break
					case 'search_value':
						dispatch(setSearchBar(filterKeyValue[1]))
						setInputValue(decodeURIComponent(filterKeyValue[1]))
						break
					case 'is_active':
						dispatch(setStatus(filterKeyValue[1]))
						setSelectValue(filterKeyValue[1] ? 'active' : 'inactive')
						break
					case 'order_by':
						dispatch(setOrderBy(filterKeyValue[1]))
						break
					case 'order_type':
						dispatch(setOrderType(filterKeyValue[1]))
						break
					default:
						break
				}
			})
		}
	}, [dispatch])

	const AccountProvider = () => {
		const callback = useCallback(async (params) => {
			const config = {
				headers: { 'X-Company-id': Number(actualCompany) },
			}
			const { status } = await dispatch(fetchAccounts(params, config))
			if (status === 'error')
				sweetAlertModal('error', 'There was an error fetching the accounts', null, 'Ok', true, false)
		}, [])

		return { callback }
	}

	const { callback } = AccountProvider()
	const filterParfams = buildParamsFromState()

	useEffect(() => {
		callback(filterParfams)
	}, [callback, filterParfams])

	const toggleDrawer = (openDrawer) => (event) => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return
		}
		setOpen(openDrawer)
	}

	const showFilters = () => {
		setOpen(true)
	}

	const handleResetFilters = () => {
		dispatch(setSearchBar(''))
		dispatch(setStatus(''))
		setInputValue('')
		setSelectValue('')
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedSave = useCallback(
		debounce((newValue) => {
			if (newValue.length >= 3) {
				dispatch(setSearchBar(encodeURIComponent(newValue)))
			} else if (newValue.length === 0) {
				dispatch(setSearchBar(''))
			}
		}, 1000),
		[],
	)

	const handleSearch = (event) => {
		setInputValue(event.target.value)
		debouncedSave(event.target.value)
	}

	const handleSelect = (event) => {
		const isActive = event.target.value === 'active'
		setSelectValue(event.target.value)
		dispatch(setStatus(isActive))
	}

	const handleClose = () => setModalOpen(false)

	const handleOpen = () => setModalOpen(true)

	return (
		<>
			<UploadAccountsModal open={modalOpen} handleClose={handleClose} />
			<Grid spacing={1} container>
				<Grid item xs={12}>
					<Grid container spacing={2}>
						<Grid item xs={10} md={12}>
							<Grid container justify="space-between" spacing={1}>
								<Grid item xs={12} md="auto">
									<Typography variant="h1" className={classes.titleText}>
										Accounts
									</Typography>
								</Grid>
								<Grid item md="auto">
									<Button
										className={classes.headerActionButton}
										startIcon={<BackupOutlined color="secondary" />}
										size="small"
										color="primary"
										onClick={handleOpen}
									>
										Upload Multiple Accounts
									</Button>
								</Grid>
							</Grid>
						</Grid>
						{!matchesMD && (
							<Grid item xs={2}>
								<IconButton
									aria-label="Toggle Filters"
									className={classes.filterTriggerButton}
									size="small"
									onClick={showFilters}
								>
									<FilterList color="secondary" />
								</IconButton>
								<SwipeableDrawer
									classes={{ paper: classes.drawer }}
									anchor="top"
									open={open}
									onOpen={toggleDrawer(open, true)}
									onClose={toggleDrawer(false)}
									disableBackdropTransition={!iOS}
									disableDiscovery={iOS}
								>
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<FormControl variant="outlined" fullWidth>
												<InputLabel className={classes.selectChildInput} htmlFor="accounts-filter-by-status">
													Filter by Status
												</InputLabel>
												<Select
													id="accounts-filter-by-status"
													label="Filter by Status"
													value={selectValue}
													onChange={handleSelect}
												>
													{searchAccountByOptions.map(({ label, value }) => (
														<MenuItem value={value} key={value}>
															{label}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Grid>
										<Grid item xs={12}>
											<Button
												className={classes.filterResetButton}
												size="small"
												startIcon={<Loop color="secondary" />}
												onClick={handleResetFilters}
											>
												Reset filters
											</Button>
										</Grid>
									</Grid>
								</SwipeableDrawer>
							</Grid>
						)}
					</Grid>
				</Grid>
				<Grid item xs={12}>
					{!matchesMD && (
						<OutlinedInput
							value={inputValue}
							onChange={handleSearch}
							variant="outlined"
							placeholder="Search..."
							fullWidth
							startAdornment={
								<InputAdornment position="start">
									<Search color="action" />
								</InputAdornment>
							}
						/>
					)}
					{matchesMD && (
						<Grid container alignItems="center" spacing={2}>
							<Grid item xs={12} md={7}>
								<OutlinedInput
									value={inputValue}
									onChange={handleSearch}
									variant="outlined"
									placeholder="Search..."
									style={{ height: '42px' }}
									fullWidth
									startAdornment={
										<InputAdornment position="start">
											<Search color="action" />
										</InputAdornment>
									}
								/>
							</Grid>
							<Grid item md={3}>
								<FormControl size="small" variant="outlined" fullWidth>
									<InputLabel className={classes.selectChildInput} htmlFor="accounts-filter-by-status">
										Filter by Status
									</InputLabel>
									<Select
										id="accounts-filter-by-status"
										label="Filter by Status"
										value={selectValue}
										onChange={handleSelect}
									>
										{searchAccountByOptions.map(({ label, value }) => (
											<MenuItem value={value} key={value}>
												{label}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item md={2}>
								<Button
									className={classes.filterResetButton}
									size="small"
									startIcon={<Loop color="secondary" />}
									onClick={handleResetFilters}
								>
									Reset filter
								</Button>
							</Grid>
						</Grid>
					)}
				</Grid>
				<Grid item xs={12}>
					<TableList data={accounts || []} loading={isLoading} count={records} />
				</Grid>
			</Grid>
		</>
	)
}

AccountList.propTypes = {}

export default AccountList
