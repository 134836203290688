import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	filterTriggerButton: {
		display: 'flex',
		marginLeft: 'auto',
		color: theme.palette.neutral.black,
	},
	filterApplyButton: {
		textTransform: 'none',
		fontWeight: theme.typography.body2.fontWeight,
		fontSize: theme.typography.body2.fontSize,
		color: theme.palette.neutral.black,
	},
	drawer: {
		padding: theme.spacing(2),
	},
	formControl: {
		marginTop: 8,
		marginBottom: 4,
	},
}))

export default useStyles
