import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import clsx from 'clsx'
import {
	Box,
	Checkbox,
	Chip,
	CircularProgress,
	Collapse,
	IconButton,
	TableCell,
	TableRow,
	Typography,
	Avatar,
	useTheme,
} from '@material-ui/core'
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import MenuList from '../MenuList/MenuList'
import useStyles from './style'

const AccountChildrenList = ({ data, selected, setSelected, isOpen, isLoading }) => {
	const classes = useStyles()
	const theme = useTheme()
	const [delayedOpen, setDelayedOpen] = useState(isOpen)
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)
	const isSelected = (name) => selected.indexOf(name) !== -1
	const [infoItemMenu, setInfoItemMenu] = useState({
		id: '',
		is_active: false,
	})

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name)
		let newSelected = []

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name)
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1))
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1))
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
		}
		setSelected(newSelected)
	}

	const handleOpenMenu = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	useEffect(() => {
		setTimeout(() => {
			setDelayedOpen(isOpen)
		}, 100)
	}, [isOpen])
	return (
		<>
			{isLoading && (
				<TableRow>
					<TableCell align="center" className={classes.tableCell} colSpan={7}>
						<Collapse unmountOnExit in={isOpen} timeout="auto">
							<Box margin={2}>
								<CircularProgress />
							</Box>
						</Collapse>
					</TableCell>
				</TableRow>
			)}
			{data.length > 0 &&
				!isLoading &&
				data.map((item, i) => {
					const isItemSelected = isSelected(item.id)
					const labelId = `children-accounts-table-checkbox-${i}`
					const isAccountCompleted = item.step1_complete && item.step2_complete && item.step4_complete
					return (
						<TableRow
							className={clsx(classes.tableRow, { isHidden: !delayedOpen })}
							hover
							role="checkbox"
							aria-checked={isItemSelected}
							tabIndex={-1}
							key={item.id}
							selected={isItemSelected}
						>
							<TableCell padding="checkbox" align="right" style={{ width: '50px', paddingLeft: '10px' }} />
							<TableCell className={classes.tableCell} style={{ paddingLeft: 0 }}>
								<Collapse unmountOnExit in={delayedOpen} timeout="auto">
									<div className={classes.cellAccountInfo}>
										<Checkbox
											inputProps={{ 'aria-labelledby': labelId }}
											checked={isItemSelected}
											className={classes.tableCheckbox}
											onClick={(event) => handleClick(event, item.id)}
											size="small"
											style={{
												color: isItemSelected ? theme.palette.secondary.light : theme.palette.primary.main,
												marginRight: '10px',
											}}
										/>
										{item.logo ? (
											<img src={item.logo} alt="" className={classes.avatar} />
										) : (
											<Avatar className={classes.avatar}>
												<SupervisorAccountOutlinedIcon ssize="small" color="inherit" />
											</Avatar>
										)}
										<div style={{ marginLeft: '12px' }}>{item.name}</div>
									</div>
								</Collapse>
							</TableCell>
							<TableCell className={classes.tableCell}>
								<Collapse unmountOnExit in={delayedOpen} timeout="auto">
									{item.contact}
								</Collapse>
							</TableCell>
							<TableCell className={classes.tableCell}>
								<Collapse unmountOnExit in={delayedOpen} timeout="auto">
									{item.audit_creation_date && moment(item.audit_creation_date).format('MM/DD/YYYY')}
								</Collapse>
							</TableCell>
							<TableCell className={classes.tableCell}>
								<Collapse unmountOnExit in={delayedOpen} timeout="auto">
									{item.audit_update_date && moment(item.audit_update_date).format('MM/DD/YYYY')}
								</Collapse>
							</TableCell>
							<TableCell className={classes.tableCell}>
								<Collapse unmountOnExit in={delayedOpen} timeout="auto" className={classes.statusAccount}>
									<div>
										{item.is_active ? (
											<Chip size="small" className={classes.statusActive} label="Active" />
										) : (
											<Chip size="small" className={classes.statusInactive} label="Inactive" />
										)}
										{!isAccountCompleted ? (
											<Chip size="small" className={classes.stepStatusIncomplete} label="Incomplete" />
										) : null}
									</div>
								</Collapse>
							</TableCell>
							<TableCell className={classes.tableCell}>
								<Collapse unmountOnExit in={delayedOpen} timeout="auto">
									<IconButton
										aria-label="toggle action menu"
										onClick={(event) => {
											setInfoItemMenu({
												id: item.id,
												is_active: item.is_active,
											})
											handleOpenMenu(event)
										}}
										onMouseDown={null}
										edge="start"
										size="small"
									>
										<MoreVertIcon style={{ color: '#2C2C2C' }} />
									</IconButton>
								</Collapse>
							</TableCell>
						</TableRow>
					)
				})}
			{data.length === 0 && !isLoading && (
				<TableRow>
					<TableCell align="center" className={classes.tableCell} colSpan={7}>
						<Collapse unmountOnExit in={isOpen} timeout="auto">
							<Box margin={2}>
								<Typography variant="caption">Nothing found</Typography>
							</Box>
						</Collapse>
					</TableCell>
				</TableRow>
			)}
			{open && (
				<MenuList
					anchorEl={anchorEl}
					open={open}
					handleClose={handleClose}
					accountId={infoItemMenu.id}
					isActive={infoItemMenu.is_active}
				/>
			)}
		</>
	)
}

AccountChildrenList.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	data: PropTypes.arrayOf(PropTypes.any).isRequired,
	selected: PropTypes.instanceOf(Array).isRequired,
	setSelected: PropTypes.func.isRequired,
}

export default AccountChildrenList
