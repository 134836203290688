/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import {
	TextField,
	FormControlLabel,
	Select,
	MenuItem,
	Checkbox,
	FormControl,
	InputLabel,
	Button,
	Typography,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { useForm, Controller } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import TimePicker from 'rc-time-picker'
import 'rc-time-picker/assets/index.css'

import moment from 'moment'

import { withStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { InformationIcon } from '../../../../../assets/Icons/Icons'
import { locationsListRoute, createLocationRoute } from '../../../../../utils/constants'

import {
	originAccessorials,
	destinationAccessorials,
	locationOptions,
	countryOptions,
} from '../../../../../utils/dropDownOptions'

import ModuleHeader from '../../../../../components/ModuleHeader/index'
import sweetAlertModal from '../../../../../components/SweetAlertModal'
import PhoneNumberFormat from '../../../../../components/PhoneNumberFormat'

import { createLocation, fetchLocationById, editLocation } from '../../../../../redux/actions/Locations.actions'
import { setTabCurrentRoute, setTabTitle } from '../../../../../redux/actions/MultiTabs.actions'
import removeZDate from '../../../../../utils/removeZDate'

import useStyles from './styles'
import ZipCodeSelector from '../../../../../components/ZipCodeSelector'

/* eslint no-param-reassign: ["error", { "props": false }] */

const NewLocation = () => {
	const { handleSubmit, register, errors, control, setValue, reset } = useForm({
		mode: 'onBlur',
	})
	const dispatch = useDispatch()
	const classes = useStyles()
	const theme = useTheme()
	const matches = useMediaQuery(theme.breakpoints.down('md'))
	const history = useHistory()
	const location = useLocation()
	const { id } = useParams()
	const [zipCodeValue, setZipCodeValue] = useState('')
	const [inputCityValue, setInputCityValue] = useState('')
	const [inputZipCodeValue, setInputZipCodeValue] = useState('')
	const [hereMapsResponseZipCode, setHereMapsResponseZipCode] = useState([])
	const [accessorials, setAccessorials] = useState([])
	const [typeState, setTypeState] = useState('NONE')
	const [countryState, setCountryState] = useState('USA')
	const [sendEmailState, setSendEmailState] = useState(false)
	const [sendSMSState, setSendSMSState] = useState(false)
	const [phoneNumber, setPhoneNumber] = useState('')
	const [errorPhone, setErrorPhone] = useState('')
	const [openTime, setOpenTime] = useState(moment().set({ hour: 8, minute: 0 }))
	const [closeTime, setCloseTime] = useState(moment().set({ hour: 17, minute: 0 }))
	const isEdit = !!id

	const accessorialsOptions = useMemo(() => [...originAccessorials, ...destinationAccessorials], [])
	const splittedPath = location.pathname.split('/')
	useEffect(() => {
		const currentRoute = isEdit ? `/${splittedPath[1]}/${id}` : createLocationRoute
		dispatch(setTabCurrentRoute(currentRoute))
		dispatch(setTabTitle(isEdit ? 'Edit Location' : 'New Location'))
	}, [isEdit, dispatch, id, splittedPath])

	const formTexts = {
		title: isEdit ? 'Edit Location' : 'New Location',
		subtitle: isEdit
			? 'Change information about the address, contact person and accessorials for this location'
			: 'Insert information about the address, contact person and accessorials of your new location.',
		submitButtonText: isEdit ? 'Update Location' : 'Save New Location',
		cancelButtonText: isEdit ? 'Cancel' : 'Cancel Location',
		errorSubmitText: isEdit
			? 'There was an error in the update location process!'
			: 'There was an error in the create location process!',
		successSubmitText: isEdit ? 'Location has been updated successfully!' : 'Location has been saved successfully!',
	}

	const handleSubmitLocation = async (data) => {
		const localDataPhone = data?.phone ?? ''
		if (localDataPhone.length === 0) {
			setErrorPhone('This value is required')
			return
		}
		if (openTime === '' || closeTime === '') {
			sweetAlertModal(
				'warning',
				'Invalid times',
				'Please enter valid hours for the open or close time',
				'Yes',
				true,
				false,
				'',
			)
			return
		}
		if (localDataPhone.length > 0 && !localDataPhone.match(/^([(]([0-9]{3})[)] ([0-9]{3})(-)([0-9]{4}))$/)) {
			setErrorPhone('Enter a phone number')
			return
		}
		const accessorialsId = []
		const replacedPhone = data.phone.replace('(', '').replace(')', '').replace('-', '').replace(' ', '')
		data.longitude = data.zipCode.longitude
		data.latitude = data.zipCode.latitude
		data.accessorials.map((element) => accessorialsId.push(element.accessorialId))
		data.locationAccessorials = accessorialsId
		data.phone = replacedPhone
		data.city = data.zipCode.city
		data.state = data.zipCode.state
		data.zip_code = data.zipCode.postalCode
		data.open_time = openTime.format('hh:00 A')
		data.close_time = closeTime.format('hh:00 A')
		data.type = data.type === 'NONE' ? null : data.type
		delete data.accessorials
		delete data.zipCode
		if (isEdit) {
			data.idAccessorials = accessorialsId
			delete data.locationAccessorials
		}
		const { status, resp } = isEdit ? await dispatch(editLocation(id, data)) : await dispatch(createLocation(data))
		if (status === 'error') {
			sweetAlertModal(status, formTexts.errorSubmitText, resp, 'Ok', true, false, null)
		} else {
			sweetAlertModal(status, formTexts.successSubmitText, null, 'Ok', true, false, null)
			setPhoneNumber('')
			setInputCityValue('')
			setInputZipCodeValue('')
			setAccessorials([])
			setSendEmailState(false)
			setSendSMSState(false)
			setTypeState('NONE')
			setCountryState('')
			setOpenTime(moment().set({ hour: 8, minute: 0 }))
			setCloseTime(moment().set({ hour: 17, minute: 0 }))
			reset()
			history.push(locationsListRoute)
		}
	}

	const CustomAutoComplete = withStyles({
		tag: {
			backgroundColor: theme.palette.neutral.secondaryGrey,
			'& .MuiChip-deleteIcon': {
				color: theme.palette.secondary.main,
			},
		},
	})(Autocomplete)

	useEffect(() => {
		const fetchData = async () => {
			const { status, resp } = await dispatch(fetchLocationById(`/${id}`))
			if (status === 'error') {
				sweetAlertModal(status, 'There was an error fetching the location', null, 'Ok', true, false, null)
			} else {
				const {
					location: {
						name,
						address,
						address2,
						location_id,
						zip_code,
						contact,
						phone,
						country,
						email,
						type,
						send_email,
						send_sms,
						city,
						state,
						latitude,
						longitude,
						open_time,
						close_time,
					},
					locationAccessorials,
				} = resp
				const fetchedAccessorials = []
				locationAccessorials.forEach((item) => {
					const findAccessorial = accessorialsOptions.find(
						(elem) => elem.accessorialId === item.accessorial_id && elem.internalCode && item.code,
					)
					if (findAccessorial) fetchedAccessorials.push(findAccessorial)
				})
				const editedType = type || 'NONE'
				setValue('name', name)
				setValue('address', address)
				setValue('address2', address2)
				setValue('location_id', location_id)
				setZipCodeValue(zip_code)
				setValue('contact', contact)
				setPhoneNumber(phone)
				setValue('country', country)
				setValue('email', email)
				setValue('type', editedType)
				setValue('accessorials', fetchedAccessorials)
				setAccessorials(fetchedAccessorials)
				setValue('send_email', send_email)
				setValue('send_sms', send_sms)
				setSendEmailState(send_email)
				setSendSMSState(send_sms)
				setValue('state', state)
				setValue('zipCode', {
					latitude,
					longitude,
					state,
					zip_code,
					city,
				})
				setInputCityValue(`${city}, ${state}`)
				setInputZipCodeValue(zip_code)
				setOpenTime(open_time ? moment(removeZDate(open_time)) : openTime)
				setCloseTime(close_time ? moment(removeZDate(close_time)) : closeTime)
			}
		}
		if (isEdit && id) fetchData()
		else {
			setInputCityValue('')
			setInputZipCodeValue('')
			setPhoneNumber('')
			setAccessorials([])
			setSendEmailState(false)
			setSendSMSState(false)
			setTypeState('NONE')
			setOpenTime(moment().set({ hour: 8, minute: 0 }))
			setCloseTime(moment().set({ hour: 17, minute: 0 }))
			reset()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, dispatch, setValue, accessorialsOptions, isEdit, id])
	const disabledOptions = (option) => {
		if (accessorials.find((accessorial) => accessorial.name === 'RESIDENTIAL DELIVERY')) {
			return option.name === 'DELIVERY APPOINTMENT' || option.name === 'NOTIFY CONSIGNEE'
		}
		if (accessorials.find((accessorial) => accessorial.name === 'DELIVERY APPOINTMENT')) {
			return option.name === 'RESIDENTIAL DELIVERY'
		}
		if (accessorials.find((accessorial) => accessorial.name === 'NOTIFY CONSIGNEE')) {
			return option.name === 'RESIDENTIAL DELIVERY'
		}
		return null
	}

	return (
		<form className={classes.newLocationForm} onSubmit={handleSubmit(handleSubmitLocation)}>
			<div className={classes.newLocationHeader}>
				<ModuleHeader title={formTexts.title} subtitle={formTexts.subtitle} />
			</div>
			<div className={classes.newLocationSection}>
				<div className={classes.newLocationTitleRow}>
					<div className={classes.newLocationIconTitle}>
						<div className={classes.newLocationIconContainer}>
							<InformationIcon className={classes.newLocationIcon} />
						</div>
						<h3 className={classes.newLocationSectionTitle}>Location Information</h3>
					</div>
					<div className={classes.newLocationType}>
						{!matches && (
							<InputLabel className={classes.newLocationTypeLabel}>Set this location as Default for:</InputLabel>
						)}
						<FormControl
							key="location-default"
							variant="outlined"
							className={[classes.root, classes.newLocationDefault].join(' ')}
							error={!!errors.type}
						>
							{matches && <InputLabel>Set this location as Default for:</InputLabel>}
							<Controller
								render={({ onChange, value, name }) =>
									matches ? (
										<Select
											name={name}
											value={value}
											onChange={(e) => {
												onChange(e.target.value)
												setValue('type', e.target.value, { shouldValidate: true, shouldDirty: true })
												setTypeState(e.target.value)
											}}
											label="Set this locations as Default for:"
										>
											{locationOptions.map((item, index) => (
												<MenuItem value={item.value} key={`id-${index + 1}`}>
													{item.label}
												</MenuItem>
											))}
										</Select>
									) : (
										<Select
											name={name}
											value={value}
											onChange={(e) => {
												onChange(e.target.value)
												setValue('type', e.target.value, { shouldValidate: true, shouldDirty: true })
												setTypeState(e.target.value)
											}}
										>
											{locationOptions.map((item, index) => (
												<MenuItem value={item.value} key={`id-${index + 1}`}>
													{item.label}
												</MenuItem>
											))}
										</Select>
									)
								}
								name="type"
								control={control}
								defaultValue={typeState}
							/>
						</FormControl>
					</div>
				</div>
				<div className={classes.newLocationFormSection}>
					<div className={classes.newLocationFormSubtitle}>Address</div>
					<div className={classes.newLocationFormRow}>
						<TextField
							name="name"
							className={[classes.root, classes.newLocationCompanyName].join(' ')}
							inputRef={register({
								required: { value: true, message: 'This value is required' },
								maxLength: { value: 100, message: 'Maximum 100 characters allowed' },
							})}
							error={!!errors.name}
							helperText={errors.name ? errors.name.message : null}
							FormHelperTextProps={{ classes: { root: classes.helperText } }}
							label="Company name *"
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
						/>
						<TextField
							name="address"
							className={[classes.root, classes.newLocationAddressOne].join(' ')}
							inputRef={register({
								required: { value: true, message: 'This value is required' },
								maxLength: { value: 100, message: 'Maximum 100 characters allowed' },
							})}
							error={!!errors.address}
							helperText={errors.address ? errors.address.message : null}
							FormHelperTextProps={{ classes: { root: classes.helperText } }}
							label="Address Line 1 *"
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
						/>
						<TextField
							name="address2"
							className={[classes.root, classes.newLocationAddressTwo].join(' ')}
							inputRef={register({
								maxLength: { value: 100, message: 'Maximum 100 characters allowed' },
							})}
							error={!!errors.address2}
							helperText={errors.address2 ? errors.address2.message : null}
							FormHelperTextProps={{ classes: { root: classes.helperText } }}
							label="Address Line 2"
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</div>
					<div className={classes.newLocationFormRow}>
						<TextField
							name="location_id"
							className={[classes.root, classes.newLocationId].join(' ')}
							inputRef={register({
								pattern: {
									value: /^[\w\d\s-]+$/i,
									message: 'Please enter a valid location id',
								},
							})}
							error={!!errors.location_id}
							helperText={errors.location_id ? errors.location_id.message : null}
							FormHelperTextProps={{ classes: { root: classes.helperText } }}
							label="Location ID"
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
						/>
						<FormControl
							key="location-zipcode"
							variant="outlined"
							className={[classes.root, classes.newLocationZipCode].join(' ')}
						>
							<Controller
								render={({ onChange, value }) => (
									<ZipCodeSelector
										value={value}
										setOptions={(resp) => {
											setHereMapsResponseZipCode(resp)
										}}
										onChange={(newValue) => {
											onChange(newValue)
											if (newValue) {
												setValue('zipCode', newValue, { shouldValidate: true, shouldDirty: true })
												setZipCodeValue(newValue)
												const noZipCodeLabel = newValue.label.split(', ')
												const zipCode = noZipCodeLabel.shift()
												setInputZipCodeValue(zipCode)
												setInputCityValue(`${newValue.city}, ${newValue.state}`)
												setValue('country', newValue.country)
												setCountryState(newValue.country)
											} else {
												setValue('zipCode', '', { shouldValidate: true, shouldDirty: true })
												setZipCodeValue('')
												setInputZipCodeValue('')
												setInputCityValue('')
												setValue('country', '')
												setCountryState('')
											}
										}}
										getOptionLabel={(option) => (option.label === undefined ? '' : option.label)}
										size="medium"
										inputSize="medium"
										inputValue={inputZipCodeValue}
										inputLabel="Zip Code *"
										inputError={!!errors.zipCode}
										inputHelperText={errors.zipCode ? errors.zipCode.message : ''}
										onInputChange={(event, newInputValue) => {
											if (event) setInputZipCodeValue(newInputValue)
										}}
										options={hereMapsResponseZipCode}
									/>
								)}
								name="zipCode"
								control={control}
								defaultValue={zipCodeValue}
								rules={{
									required: { value: true, message: 'This value is required' },
									maxLength: { value: 5, message: 'Maximum 5 characters allowed' },
								}}
							/>
						</FormControl>
						<div className={classes.newLocationFormMultiControl}>
							<TextField
								disabled
								name="city"
								className={[classes.root, classes.newLocationCity].join(' ')}
								value={inputCityValue}
								onChange={(e) => setInputCityValue(e.target.value)}
								inputRef={register({
									required: { value: true, message: 'This value is required' },
								})}
								error={!!errors.city}
								helperText={errors.city ? errors.city.message : null}
								FormHelperTextProps={{ classes: { root: classes.helperText } }}
								label="City/State *"
								variant="outlined"
							/>
							<FormControl
								key="location-country"
								variant="outlined"
								className={[classes.root, classes.newLocationCountry].join(' ')}
								error={!!errors.country}
							>
								<InputLabel>Country *</InputLabel>
								<Controller
									render={({ onChange, value, name }) => (
										<Select
											name={name}
											value={value}
											disabled
											onChange={(e) => {
												onChange(e.target.value)
												setValue('country', e.target.value, { shouldValidate: true, shouldDirty: true })
												setCountryState(e.target.value)
											}}
											label="Country *"
										>
											{countryOptions.map((item, index) => (
												<MenuItem value={item.value} key={`id-${index + 1}`}>
													{item.label}
												</MenuItem>
											))}
										</Select>
									)}
									name="country"
									control={control}
									defaultValue={countryState}
									rules={{ required: { value: true, message: 'This value is required' } }}
								/>
							</FormControl>
						</div>
					</div>
				</div>
				<div className={classes.newLocationFormSection}>
					<div className={classes.newLocationFormSubtitle}>Accessorials</div>
					<div className={classes.newLocationFormRow}>
						<FormControl
							key="location-accessorials"
							variant="outlined"
							className={[classes.root, classes.newLocationAccessorials].join(' ')}
							error={!!errors.accessorials}
						>
							<Controller
								render={({ onChange, value, name }) => (
									<CustomAutoComplete
										name={name}
										className={[classes.root, classes.newLocationAccessorials].join(' ')}
										multiple
										options={accessorialsOptions}
										getOptionLabel={(option) => option.name}
										value={value}
										onChange={(e, newValue) => {
											onChange(newValue)
											setValue('accessorials', newValue, { shouldValidate: true, shouldDirty: true })
											setAccessorials(newValue)
										}}
										getOptionSelected={(option, valueSelected) => option.accessorialId === valueSelected.accessorialId}
										getOptionDisabled={(option) => disabledOptions(option)}
										renderInput={(props) => (
											<TextField
												// eslint-disable-next-line react/jsx-props-no-spreading
												{...props}
												label="Add accessorials"
												margin="normal"
												variant="outlined"
											/>
										)}
									/>
								)}
								name="accessorials"
								control={control}
								defaultValue={accessorials}
								rules={{ required: { value: true, message: 'This value is required' } }}
							/>
						</FormControl>
					</div>
				</div>
				<div className={classes.newLocationFormSection}>
					<div className={classes.newLocationFormSubtitle}>Hours of Operation</div>
					<div className={classes.newLocationFormRow}>
						<TextField
							name="open"
							margin="normal"
							className={classes.root}
							type="text"
							label="Open *"
							variant="outlined"
							value=""
							inputRef={register()}
							InputLabelProps={{
								shrink: true,
							}}
							InputProps={{
								inputComponent: () => (
									<div className={classes.newLocationOpenTimePicker}>
										<TimePicker
											placeholder=""
											value={openTime}
											defaultValue={openTime}
											onChange={(value) => {
												if (value) setOpenTime(value)
												else setOpenTime('')
											}}
											className={classes.timePicker}
											popupClassName={classes.popupTimePicker}
											showSecond={false}
											use12Hours
											showMinute={false}
											focusOnOpen
										/>
									</div>
								),
							}}
						/>
						<TextField
							name="close"
							margin="normal"
							className={[classes.root, classes.newLocationCloseTimePicker].join(' ')}
							type="text"
							label="Close *"
							variant="outlined"
							value=""
							inputRef={register()}
							InputLabelProps={{
								shrink: true,
							}}
							InputProps={{
								inputComponent: () => (
									<div className={classes.newLocationOpenTimePicker}>
										<TimePicker
											placeholder=""
											value={closeTime}
											defaultValue={closeTime}
											onChange={(value) => {
												if (value) setCloseTime(value)
												else setCloseTime('')
											}}
											className={classes.timePicker}
											popupClassName={classes.popupTimePicker}
											showSecond={false}
											use12Hours
											showMinute={false}
											focusOnOpen
										/>
									</div>
								),
							}}
						/>
					</div>
				</div>
			</div>
			<div className={classes.newLocationContactSection}>
				<div className={classes.newLocationIconTitle}>
					<div className={classes.newLocationIconContainer}>
						<InformationIcon className={classes.newLocationIcon} />
					</div>
					<h3 className={classes.newLocationSectionTitle}>Contact Information</h3>
				</div>
				<div className={classes.newLocationFormRow}>
					<TextField
						name="contact"
						className={[classes.root, classes.newLocationContactName].join(' ')}
						inputRef={register({
							required: { value: true, message: 'This value is required' },
							maxLength: { value: 100, message: 'Maximum 100 characters allowed' },
						})}
						error={!!errors.contact}
						helperText={errors.contact ? errors.contact.message : null}
						FormHelperTextProps={{ classes: { root: classes.helperText } }}
						label="Contact Name *"
						variant="outlined"
						InputLabelProps={{
							shrink: true,
						}}
					/>
					<TextField
						name="phone"
						className={[classes.root, classes.newLocationContactPhone].join(' ')}
						type="text"
						label="Contact Phone *"
						variant="outlined"
						value={phoneNumber}
						onChange={(e) => setPhoneNumber(e.target.value)}
						inputRef={register()}
						error={errorPhone.length > 0}
						helperText={errorPhone.length > 0 ? errorPhone : null}
						InputProps={{
							inputComponent: PhoneNumberFormat,
						}}
						FormHelperTextProps={{ classes: { root: classes.helperText } }}
					/>
					<TextField
						name="email"
						className={[classes.root, classes.newLocationContactEmail].join(' ')}
						inputRef={register({
							pattern: {
								value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
								message: 'Please enter a valid email format',
							},
							maxLength: { value: 100, message: 'Maximum 100 characters allowed' },
						})}
						error={!!errors.email}
						helperText={errors.email ? errors.email.message : null}
						FormHelperTextProps={{ classes: { root: classes.helperText } }}
						label="Contact Email"
						variant="outlined"
						InputLabelProps={{
							shrink: true,
						}}
					/>
					<div className={classes.newLocationNotifications}>
						<p className={classes.newLocationNotificationsSubtitle}>Tracking Notifications Settings:</p>
						<div className={classes.newLocationCheckboxContainer}>
							<FormControlLabel
								control={
									<Checkbox
										name="send_email"
										color="secondary"
										className={[classes.root, classes.checkbox].join(' ')}
										inputRef={register()}
										checked={sendEmailState}
										onChange={(event) => setSendEmailState(event.target.checked)}
									/>
								}
								label={<Typography style={matches ? { fontSize: '12px' } : { fontSize: '14px' }}>Via Email</Typography>}
							/>
							<FormControlLabel
								control={
									<Checkbox
										name="send_sms"
										color="secondary"
										className={[classes.root, classes.checkbox].join(' ')}
										inputRef={register()}
										checked={sendSMSState}
										onChange={(event) => setSendSMSState(event.target.checked)}
									/>
								}
								label={<Typography style={matches ? { fontSize: '12px' } : { fontSize: '14px' }}>Via SMS</Typography>}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className={classes.newLocationRowButtons}>
				<Button className={classes.cancelButton} onClick={() => history.push(locationsListRoute)}>
					{formTexts.cancelButtonText}
				</Button>
				<Button
					className={classes.saveButton}
					variant="contained"
					color="secondary"
					type="submit"
					onMouseDown={() => {
						setErrorPhone('')
					}}
				>
					{formTexts.submitButtonText}
				</Button>
			</div>
		</form>
	)
}

export default NewLocation
