const removeUntilColonAndUppercase = (item) => {
	const itemAfterColon = item.substring(item.indexOf(':') + 1)
	return itemAfterColon.charAt(1).toUpperCase() + itemAfterColon.slice(2).toLowerCase()
}

export const removeUnderscoreAndUppercase = (item) => {
	const itemWithourUnderscore = item.replace('_', ' ')
	return itemWithourUnderscore.charAt(0) + itemWithourUnderscore.slice(1).toLowerCase()
}

const cubicInchesToCubicFeet = 0.0005787

export const calculateTotalItems = (items) => {
	const totalItems = items.reduce((accumulator, item) => accumulator + Number(item.pieces_value), 0)
	return totalItems
}

export const calculateTotalWeight = (items) => {
	const totalWeight = items.reduce((accumulator, item) => accumulator + Number(item.weight_value), 0)
	return totalWeight
}

export const calculateTotalCubicFeet = (items) => {
	let totalCubicFeet = 0
	items.forEach((item) => {
		const cubicInches = item.dimensions_lenght * item.dimensions_width * item.dimensions_height * item.pieces_value
		const cubicFeet = cubicInches * cubicInchesToCubicFeet
		totalCubicFeet += cubicFeet
	})
	return totalCubicFeet.toFixed(2)
}

export const calculateTotalDensity = (listItems) => {
	let localDensity = 0
	if (Array.isArray(listItems)) {
		listItems.forEach((localItems) => {
			const length = localItems?.dimensions_lenght ?? 0
			const width = localItems?.dimensions_width ?? 0
			const height = localItems?.dimensions_height ?? 0
			const weight = localItems?.weight_value ?? 0
			const quantity = localItems?.pieces_value ?? 0
			const volume = (length * width * height * quantity) / 1728
			const densityElement = weight / volume
			localDensity += densityElement
		})
	}
	return localDensity.toFixed(2)
}

export const formatPhoneNumber = (number) => {
	const cleaned = ` ${number}`.replace(/\D/g, '')
	const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
	if (match) {
		return `(${match[1]}) ${match[2]}-${match[3]}`
	}
	return ''
}

export const displayDetails = (itemDetails) => {
	let details = []
	details = itemDetails.map(
		(detail, index) =>
			`${detail.quantity} ${detail.package_type.toLowerCase()} ${detail.description && `of ${detail.description}`}
      ${itemDetails.length === index + 1 ? '' : 'and'} `,
	)

	return details
}

export const displayPackageType = (piecesValue, piecesUnit) => {
	if (piecesUnit === 'BOX') return `${piecesUnit.toLowerCase()}${piecesValue === 1 ? '' : 'es'}`
	return `${piecesUnit.toLowerCase()}${piecesValue === 1 ? '' : 's'}`
}

export default removeUntilColonAndUppercase
