import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	ratesTable: {
		padding: '20px',
		'@media (max-width: 1024px)': {
			display: 'none',
		},
	},
	ratesNotFound: {
		textAlign: 'center',
		marginTop: '30px',
		'@media (min-width: 1025px)': {
			display: 'none',
		},
	},
	ratesSliderLoading: {
		textAlign: 'center',
		marginTop: '30px',
		'@media (min-width: 1025px)': {
			display: 'none',
		},
	},
	mapContainer: {
		width: '100%',
		'@media (min-width: 1024px)': {
			width: '50%',
		},
	},
	mapDescription: {
		fontWeight: 600,
		fontSize: '14px',
		fontStyle: 'italic',
		color: '#A1A1A1',
		margin: 0,
		textAlign: 'end',
		'@media (max-width: 767px)': {
			marginBottom: '16px',
		},
	},
	mainInformation: {
		width: '100%',
		'@media only screen and (min-width: 1024px)': {
			width: '45%',
			marginLeft: '1.5em',
		},
	},
	mainInformationBody: {
		margin: '12px 0',
		'@media (min-width: 768px)': {
			width: '100%',
			display: 'flex',
			columnGap: '24px',
		},
	},
	infoItem: {
		width: '50%',
	},
	infoItemEnd: {
		fontWeight: 400,
		fontSize: '16px',
		display: 'flex',
		flexDirection: 'row',
		'@media (min-width: 768px)': {
			justifyContent: 'flex-end',
		},
	},
	companyName: {
		fontWeight: theme.typography.fontWeightMedium,
	},
	itemDate: {
		fontWeight: theme.typography.fontWeightMedium,
		color: '#A1A1A1',
		fontStyle: 'italic',
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
	},
	quoteDataContainer: {
		display: 'flex',
		flexDirection: 'column',
		fontWeight: 400,
		fontSize: '16px',
		paddingBottom: '10px',
		'@media only screen and (min-width: 768px)': {
			flexDirection: 'row',
			columnGap: '24px',
		},
	},
	pickupDeliveryRows: {
		width: '50%',
		display: 'flex',
		flexDirection: 'column',
		'@media only screen and (max-width: 767px)': {
			width: '100%',
			'&:first-child': {
				marginBottom: '24px',
			},
		},
	},
	siteType: {
		'&::first-letter': {
			textTransform: 'uppercase',
		},
	},
	sort: {
		display: 'grid',
		gridAutoFlow: 'row',
		padding: '20px',
		'@media only screen and (min-width: 1025px)': {
			gridAutoFlow: 'column',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
	},
	sortInfoSection: {
		display: 'flex',
		alignItems: 'center',
	},
	sortInfoSectionIcon: {
		width: '32px',
		height: '32px',
		fontSize: '8px',
		borderRadius: '16px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginRight: '10px',
	},
	sortInfoSectionName: {
		fontWeight: theme.typography.fontWeightRegular,
		color: theme.palette.primary.main,
		margin: 0,
	},
	dropDown: {
		'@media only screen and (max-width: 1024px)': {
			justifySelf: 'end',
		},
	},
	sortSelect: {
		width: '210px',
		minWidth: '120px',
		margin: '2px',
		'@media only screen and (max-width: 1024px)': {
			marginTop: '27px',
		},
	},
	filtersContainer: {
		display: 'flex',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	itemShortCarousel: {
		padding: theme.spacing(1),

		'& img': {
			height: theme.spacing(8),
			width: theme.spacing(8),
			objectFit: 'contain',
		},
		'& span': {
			fontWeight: theme.typography.fontWeightMedium,
		},
	},
	itemSelected: {
		backgroundColor: theme.palette.neutral.white,
	},
	headerCell: {
		fontSize: '12px',
		fontWeight: 900,
		backgroundColor: theme.palette.neutral.whiteGray,
	},
	table: {
		border: '1px solid',
		borderColor: theme.palette.neutral.lightGray,
	},
	alertContainer: {
		marginTop: '30px',
	},
	alertList: {
		paddingLeft: '20px',
		margin: 0,
	},
	invalidRatesAlertContainer: {
		margin: '10px 30px 0 20px',
		[theme.breakpoints.up('sm')]: {
			maxWidth: '48%',
		},
	},
}))

export default useStyles
