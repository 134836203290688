import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, Button, Grid, TextField, Chip } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import useStyles from './styles'
import { sendBulkEMailInvoices } from '../../../../redux/actions/Invoices.actions'
import sweetAlertModal from '../../../../components/SweetAlertModal/SweetAlertModal'

const SendInvoicesModal = ({ showEmailModal, setShowEmailModal, invoicesIds }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const [recipientData, setRecipientData] = useState([])
	const { handleSubmit, errors, register, reset } = useForm()

	const onSubmit = (data) => {
		const index = recipientData.findIndex((item) => item.email === data.email)
		if (index === -1) setRecipientData([...recipientData, data])
		reset()
	}
	const handleDelete = (data) => () => {
		setRecipientData([...recipientData].filter((rec) => rec.email !== data.email))
	}

	const sendEmail = async () => {
		const ids = invoicesIds.map(Number)
		const payload = {
			invoiceIds: ids,
			recipientData,
		}
		const { status } = await dispatch(sendBulkEMailInvoices(payload))
		if (status === 'error') {
			sweetAlertModal(status, 'There was an error sending the emails!', null, 'Ok', true, false, null)
		} else {
			sweetAlertModal(status, 'The invoice PDFs were sent successfully!', null, 'Ok', true, false, null)
			setRecipientData([])
			setShowEmailModal(false)
		}
	}

	return (
		<Dialog
			open={showEmailModal}
			disableBackdropClick
			disableEscapeKeyDown
			onClick={(event) => event.stopPropagation()}
			aria-labelledby="form-dialog-title"
			PaperProps={{
				className: classes.dialogPaper,
			}}
			onExit={() => {
				setRecipientData([])
			}}
		>
			<div className={classes.confirmation}>
				<h2 className={classes.title}>Send Invoices</h2>
			</div>
			<p className={classes.text}>Enter an email to send the selected invoices </p>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid container alignItems="center" spacing={1}>
					<Grid item xs={5}>
						<TextField
							name="email"
							type="email"
							variant="outlined"
							label="Email *"
							size="small"
							inputRef={register({
								required: { value: true, message: 'This value is required!' },
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
									message: 'Please enter a valid email format',
								},
								maxLength: { value: 100, message: 'Maximum 100 characters allowed' },
							})}
							error={!!errors.email}
							helperText={errors.email ? errors.email.message : null}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Grid>
					<Grid item xs={5}>
						<TextField
							name="name"
							type="text"
							variant="outlined"
							label="Name *"
							size="small"
							inputRef={register({
								required: { value: true, message: 'This value is required' },
								maxLength: { value: 100, message: 'Maximum 100 characters allowed' },
							})}
							error={!!errors.name}
							helperText={errors.name ? errors.name.message : null}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Grid>
					<Grid item xs={2}>
						<Button type="submit" color="secondary" variant="contained">
							Add
						</Button>
					</Grid>
				</Grid>
			</form>
			<div className={classes.containerChip}>
				{recipientData.map((item) => (
					<Chip key={item.email} label={item?.email ?? ''} onDelete={handleDelete(item)} className={classes.chip} />
				))}
			</div>
			<Button className={classes.goButton} onClick={sendEmail} color="secondary" variant="contained">
				Send Email
			</Button>
			<Button
				className={classes.cancelButton}
				onClick={() => setShowEmailModal(false)}
				color="primary"
				variant="contained"
			>
				Cancel
			</Button>
		</Dialog>
	)
}

SendInvoicesModal.propTypes = {
	showEmailModal: PropTypes.bool.isRequired,
	setShowEmailModal: PropTypes.func.isRequired,
	invoicesIds: PropTypes.arrayOf(PropTypes.any).isRequired,
}

export default SendInvoicesModal
