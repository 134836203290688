import { axiosQuotes } from '../../utils/apiClient'

const reqGetLocations = async (params) => {
	const [error, data] = await axiosQuotes
		.get(`/location/list${params}`)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export const reqGetLocationById = async (params) => {
	const [error, data] = await axiosQuotes
		.get(`/location${params}`)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export const reqGetLocationByZipCode = async (params) => {
	const [error, data] = await axiosQuotes
		.get(`/location/list${params}`)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export const reqGetDefaultLocations = async (type) => {
	const [error, data] = await axiosQuotes
		.get(`/location/validate/${type}`)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export const reqVerifyLocation = async (id, type) => {
	const [error, data] = await axiosQuotes
		.get(`/shipment/location/validate/${id}`, {
			params: { type },
		})
		.then((response) => [null, response.data?.data])
		.catch((err) => [err, err.message])

	return [error, data]
}

export const reqCreateLocation = async (params) => {
	const [error, data] = await axiosQuotes
		.post('/location', params)
		.then((response) => [null, response])
		.catch((err) => [err, err.message])
	return [error, data]
}

export const reqEditLocation = async (params, body) => {
	const [error, data] = await axiosQuotes
		.put(`/location/${params}`, body)
		.then((response) => [null, response])
		.catch((err) => [err, err.message])
	return [error, data]
}

export const reqEditLocationType = async (locationId, params, status) => {
	const [error, data] = await axiosQuotes
		.put(`/location/${locationId}/default?type=${params}&status=${status}`)
		.then((response) => [null, response])
		.catch((err) => [err, err.response.data.message])
	return [error, data]
}

export const reqDeleteLocation = async (locationId) => {
	const [error, data] = await axiosQuotes
		.delete(`/location/${locationId}`)
		.then((response) => [null, response])
		.catch((err) => [err, err.response.data.err.message])

	return [error, data]
}

export const reqBulkLocationsCreation = async (body, config) => {
	const [error, data] = await axiosQuotes
		.post('/location/bulk', body, config)
		.then((response) => [null, response])
		.catch((err) => [err, err])
	return [error, data]
}

export default reqGetLocations
