import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	newShipmentContainer: {
		backgroundColor: theme.palette.neutral.white,
		[theme.breakpoints.down('md')]: {
			padding: '15px',
		},
	},
	newShipmentContainerInformation: {
		padding: '16px 24px',
		display: 'flex',
		flexDirection: 'row',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
		},
	},
	newShipmentInformationSection: {
		width: '50%',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginBottom: '20px',
		},
	},
	newShipmentContainerReferences: {
		padding: '16px 24px',
		display: 'flex',
		flexDirection: 'row',
		marginTop: '20px',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
			marginBottom: '20px',
		},
	},
	newShipmentContainerShipmentItems: {
		padding: '16px 24px',
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: theme.palette.neutral.whiteGray,
	},
	newShipmentIconTitle: {
		display: 'flex',
		columnGap: '12px',
		alignItems: 'center',
		marginBottom: '10px',
	},
	newShipmentIcon: {
		marginRight: 4,
		color: theme.palette.secondary.main,
		'& > :first-child': {
			fontSize: 24,
		},
	},
	newShipmentMenuIcon: {
		width: '25px',
		height: '25px',
		borderRadius: '20px',
		padding: '4px',
		alignSelf: 'center',
	},
	newShipmentTitle: {
		fontWeight: theme.typography.fontWeightRegular,
		color: theme.palette.primary.main,
		margin: 0,
		lineHeight: '22px',
	},
	newShipmentSubtitleForm: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	newShipmentSubtitle: {
		fontSize: '16px',
		color: theme.palette.common.black,
		fontWeight: theme.typography.fontWeightBold,
		margin: '25px 0',
	},
	newShipmentFormPickup: {
		width: '100%',
	},
	newShipmentFormItems: {
		width: '95%',
		marginBottom: '20px',
		marginLeft: '2%',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginLeft: '0%',
		},
	},
	newShipmentSubtitleBold: {
		fontWeight: theme.typography.fontWeightMedium,
	},
	newShipmentGrayText: {
		color: theme.palette.neutral.darkGray,
		width: '300px',
		textAlign: 'center',
	},
	newShipmentBlackText: {
		width: '300px',
		textAlign: 'center',
		textTransform: 'none',
		fontSize: '14px',
		fontWeight: '500',
	},
	newShipmentButton: {
		width: '95%',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginTop: '15px',
		},
	},
	newShipmentTextButton: {
		fontWeight: theme.typography.fontWeightRegular,
		fontSize: 13,
		color: theme.palette.neutral.black,
		textTransform: 'none',
	},
	newShipmentFormCenter: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		[theme.breakpoints.down('md')]: {
			height: 'auto',
			alignItems: 'start',
		},
	},
	newShipmentColumn: {
		display: 'flex',
		flexDirection: 'column',
		marginBottom: '10px',
		[theme.breakpoints.down('md')]: {
			marginTop: '5px',
		},
	},
	newShipmentFormRow: {
		display: 'flex',
		flexDirection: 'row',
		marginBottom: '20px',
		marginLeft: '2%',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
			marginLeft: '0%',
		},
	},
	triggerButton: {
		alignItems: 'center',
		color: theme.palette.primary.main,
		textAlign: 'right',
		display: 'flex',
		justifyContent: 'flex-end',
		fontSize: '14px',
		marginBottom: '12px',
		marginRight: '3.5%',
	},
	newShipmentFormItemRow: {
		display: 'flex',
		flexDirection: 'row',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
		},
	},
	newShipmentFormParagraph: {
		width: '15%',
		textAlign: 'center',
		color: '#A1A1A1',
		fontSize: '14px',
		fontWeight: '500',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			margin: '5px',
			textAlign: 'left',
		},
	},
	newShipmentItemsDetails: {
		display: 'flex',
		flexDirection: 'row',
		paddingLeft: '2%',
		paddingRight: '2%',
		marginTop: '0.5%',
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},
	newShipmentItemsSlider: {
		display: 'none',
		[theme.breakpoints.down('md')]: {
			display: 'block',
		},
	},
	newShipmentDatePicker: {
		width: '29%',
		marginRight: '3%',
		[theme.breakpoints.down('md')]: {
			marginBottom: '20px',
			width: '100%',
			marginRight: '0%',
		},
	},
	newShipmentEarliestTimePicker: {
		width: '29%',
		marginRight: '2%',
		marginLeft: '2%',
		[theme.breakpoints.down('md')]: {
			marginBottom: '20px',
			width: '100%',
			marginRight: '0%',
			marginLeft: '0%',
		},
	},
	newShipmentLatestTimePicker: {
		width: '29%',
		marginLeft: '3%',
		[theme.breakpoints.down('md')]: {
			marginBottom: '20px',
			marginTop: '20px',
			width: '100%',
			marginLeft: '0%',
		},
	},
	newShipmentAddressOne: {
		width: '46%',
		marginRight: '2.5%',
		[theme.breakpoints.down('md')]: {
			marginBottom: '20px',
			width: '100%',
			marginRight: '0%',
		},
	},
	newShipmentAddressTwo: {
		width: '46%',
		marginLeft: '2.5%',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginLeft: '0%',
		},
	},
	newShipmentCityField: {
		width: '47.5%',
		marginRight: '2.5%',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginRight: '0%',
			marginBottom: '20px',
		},
	},
	newShipmentZipCode: {
		width: '20%',
		marginRight: '2%',
		marginLeft: '2.5%',
		[theme.breakpoints.down('md')]: {
			width: '48%',
			marginRight: '2%',
			marginLeft: '0%',
		},
	},
	newShipmentInputOption: {
		width: '25%',
		marginLeft: '0.5%',
		[theme.breakpoints.down('md')]: {
			width: '48%',
			marginLeft: '2%',
		},
	},
	newShipmentContactName: {
		width: '47.5%',
		marginRight: '2.5%',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginRight: '0%',
			marginBottom: '5%',
		},
	},
	newShipmentContactPhone: {
		width: '26%',
		marginLeft: '2.5%',
		[theme.breakpoints.down('md')]: {
			width: '48%',
			marginLeft: '0%',
		},
	},
	newShipmentContactExtension: {
		width: '19%',
		marginLeft: '2.5%',
		[theme.breakpoints.down('md')]: {
			width: '48%',
			marginLeft: '4%',
		},
	},
	newShipmentContactEmail: {
		width: '46%',
		marginRight: '15px',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginRight: '0%',
		},
	},
	cursorPointer: {
		cursor: 'pointer',
		color: theme.palette.secondary.main,
	},
	timePicker: {
		width: '100%',
		height: '40px',
		padding: '0',
		'& .rc-time-picker-input': {
			height: '100%',
			fontSize: '17px',
			fontWeight: '500',
			border: 'none',
			outline: 'none',
		},
		'& .rc-time-picker-clear': {
			top: '9px',
		},
		'& .rc-time-picker-clear-icon::after': {
			fontSize: '20px',
		},
	},
	popupTimePicker: {
		'& .rc-time-picker-panel-input': {
			fontSize: '17px',
			fontWeight: '500',
		},
		'& .rc-time-picker-panel-inner': {
			marginTop: '20%',
		},
		'& .rc-time-picker-panel-select': {
			width: '50%',
			fontSize: '17px',
			fontWeight: '500',
			'&::-webkit-scrollbar': {
				width: '5px',
			},
			'&::-webkit-scrollbar-track': {
				'-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
				borderRadius: '10px',
			},
			'&::-webkit-scrollbar-thumb': {
				borderRadius: '10px',
				'-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.5)',
			},
		},
	},
}))

export default useStyles
