import { createSlice } from '@reduxjs/toolkit'
import reqLoadTracking from '../requests/Tracking.request'

/* eslint no-param-reassign: ["error", { "props": false }] */

const trackingSlice = createSlice({
	name: 'tracking',
	initialState: {
		isLoading: false,
		error: false,
		data: {},
	},
	reducers: {
		startLoading: (state) => {
			state.isLoading = true
		},
		hasError: (state, action) => {
			state.error = action.payload
			state.isLoading = false
		},
		hasSuccess: (state, action) => {
			state.isLoading = false
			state.data = action.payload
		},
		cleanData: (state) => {
			state.isLoading = false
			state.error = false
			state.data = {}
		},
	},
})

const { actions, reducer } = trackingSlice

const { startLoading, hasError, hasSuccess, cleanData } = actions

export const loadTracking = (boolId) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqLoadTracking(boolId)

	if (error) {
		dispatch(hasError(data))
	} else {
		dispatch(hasSuccess(data?.data?.data?.tracking ?? {}))
	}
}
export const cleanTracking = () => async (dispatch) => {
	dispatch(cleanData())
}

export default reducer
