import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	childAccountsSlider: {
		[theme.breakpoints.up('lg')]: {
			display: 'none',
		},
	},
	childAccountsSliderContainer: {
		border: '1px solid #c9cfe4',
		borderRadius: '4px',
		padding: '15px',
		backgroundColor: theme.palette.neutral.white,
		'& .MuiGrid-item': {
			paddingBottom: 8,
		},
	},
	containerChildGroup: {
		color: theme.palette.neutral.black,
		display: 'flex',
		flexDirection: 'column',
	},
	childAccountsSliderTitle: {
		fontWeight: theme.typography.fontWeightBold,
		fontSize: '12px',
	},
	childAccountsSliderContent: {
		fontWeight: theme.typography.fontWeightMedium,
		fontSize: '14px',
	},
	childAccountsSliderActionContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	childAccountsSliderActionTitle: {
		fontWeight: theme.typography.fontWeightRegular,
		fontSize: '12px',
		color: theme.palette.primary.main,
		margin: '0',
	},
	childAccountSliderIcon: {
		width: '24px',
		height: '24px',
		color: theme.palette.primary.main,
	},
}))

export default useStyles
