import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { TextField, Dialog, Button, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'

import { useForm, Controller } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { hazMatGroupOptions, hazMatClassOptions, hazMatTypeOptions } from '../../utils/dropDownOptions'

import { updateItemHazmatInfo } from '../../redux/actions/Quotes.actions'

import PhoneNumberFormat from '../PhoneNumberFormat'
import sweetAlertModal from '../SweetAlertModal/SweetAlertModal'

import './HazmatModal.scss'
import useStyles from './styles'

const HazmatModal = (props) => {
	const { isOpen, setHazmatData, setOpenHazmatModal, updateItem, itemHazmat, shipmentItems, setShipmentItems } = props
	const classes = useStyles()
	const dispatch = useDispatch()
	const { handleSubmit, register, errors, control, reset } = useForm()

	const [open, setOpen] = useState(false)
	const [hazMatNumberType, setHazMatNumberType] = useState('')
	const [hazMatGroup, setHazMatGroup] = useState('')
	const [hazMatClass, setHazMatClass] = useState('')

	useEffect(() => {
		setOpen(isOpen)
	}, [isOpen])

	const handleClose = () => {
		setOpen(false)
		if (updateItem || setOpenHazmatModal) setOpenHazmatModal(false)
	}

	const handleSaveHazmat = async (data) => {
		if (updateItem) {
			const allShipmentItems = JSON.parse(JSON.stringify(shipmentItems))
			allShipmentItems.filter((item, index) => {
				if (item.id === itemHazmat.id) {
					allShipmentItems[index].haz_mat_shipping_name = data.shippingName ? data.shippingName : null
					allShipmentItems[index].haz_mat_number_type = data.hazMatNumberType ? data.hazMatNumberType : null
					allShipmentItems[index].haz_mat_number = data.hazMatNumber ? data.hazMatNumber : null
					allShipmentItems[index].haz_mat_class = data.hazMatClass ? data.hazMatClass : null
					allShipmentItems[index].haz_mat_group = data.hazMatGroup ? data.hazMatGroup : null
					allShipmentItems[index].contact_info_name = data.name ? data.name : null
					allShipmentItems[index].contact_info_phone = data.phone ? data.phone : null
				}
				return true
			})
			setShipmentItems(allShipmentItems)
			const quoteId = itemHazmat.quote_id
			const itemId = itemHazmat.id
			const params = {
				shippingName: data.shippingName ? data.shippingName : null,
				hazMatNumberType: data.hazMatNumberType ? data.hazMatNumberType : null,
				hazMatNumber: data.hazMatNumber ? data.hazMatNumber : null,
				hazMatClass: data.hazMatClass ? data.hazMatClass : null,
				hazMatGroup: data.hazMatGroup ? data.hazMatGroup : null,
				contactInformation: {
					name: data.name ? data.name : null,
					phone: data.phone ? data.phone.replace(/[() -]/g, '') : null,
					zipcode: itemHazmat.contact_info_zipcode,
					state: itemHazmat.contact_info_state,
					country: itemHazmat.contact_info_country,
				},
			}
			const { status, resp } = await dispatch(updateItemHazmatInfo(quoteId, itemId, params))
			if (status === 'error') {
				sweetAlertModal(status, 'There was a problem editing the item!', resp, 'Ok', true, false, null)
			}
		} else {
			setHazmatData(data)
		}
		reset()
		setHazMatNumberType('')
		setHazMatGroup('')
		setHazMatClass('')
		setOpen(false)
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			onClick={(event) => event.stopPropagation()}
			aria-labelledby="form-dialog-title"
			PaperProps={{
				className: classes.paperDialog,
			}}
		>
			<form className="hazmat-modal-form" onSubmit={handleSubmit(handleSaveHazmat)}>
				<h2 className={classes.hazmatModalTitle}>Hazmat</h2>
				<p className={classes.hazmatModalSubtitle}>
					Please enter the Hazmat details for this freight. This information can be found in Section 14 of the Safety
					Data Sheet.
				</p>
				<div className="hazmat-modal-row">
					<TextField
						name="shippingName"
						type="text"
						label="Proper shipping name *"
						variant="outlined"
						InputLabelProps={{
							classes: {
								root: classes.field,
							},
						}}
						inputRef={register({ required: true, maxLength: { value: 100, message: 'Maximum 100 character allowed' } })}
						error={!!errors.shippingName}
						helperText={errors.shippingName ? errors.shippingName.message : null}
						FormHelperTextProps={{ classes: { root: classes.helperText } }}
					/>
					<FormControl variant="outlined" error={!!errors.hazMatNumberType}>
						<InputLabel className={classes.field}>HazMat type *</InputLabel>
						<Controller
							render={({ onChange, value, name }) => (
								<Select
									name={name}
									value={value}
									style={{ textAlign: 'left' }}
									onChange={(e) => {
										onChange(e.target.value)
										setHazMatNumberType(e.target.value)
									}}
									label="hazMatNumberType"
								>
									{hazMatTypeOptions.map((item, index) => (
										<MenuItem value={item.value} key={`id-${index + 1}`}>
											{item.value}
										</MenuItem>
									))}
								</Select>
							)}
							name="hazMatNumberType"
							control={control}
							defaultValue={hazMatNumberType}
							rules={{ required: true }}
						/>
					</FormControl>
					<TextField
						name="hazMatNumber"
						type="text"
						label="HazMat number *"
						variant="outlined"
						InputLabelProps={{
							classes: {
								root: classes.field,
							},
						}}
						inputRef={register({ required: true, maxLength: { value: 10, message: 'Maximum 10 character allowed' } })}
						error={!!errors.hazMatNumber}
						helperText={errors.hazMatNumber ? errors.hazMatNumber.message : null}
						FormHelperTextProps={{ classes: { root: classes.helperText } }}
					/>
					<FormControl variant="outlined" error={!!errors.hazMatGroup}>
						<InputLabel className={classes.field}>HazMat group *</InputLabel>
						<Controller
							render={({ onChange, value, name }) => (
								<Select
									name={name}
									value={value}
									style={{ textAlign: 'left' }}
									onChange={(e) => {
										onChange(e.target.value)
										setHazMatGroup(e.target.value)
									}}
									label="hazMatGroup"
								>
									{hazMatGroupOptions.map((item, index) => (
										<MenuItem value={item.value} key={`id-${index + 1}`}>
											{item.value}
										</MenuItem>
									))}
								</Select>
							)}
							name="hazMatGroup"
							control={control}
							defaultValue={hazMatGroup}
							rules={{ required: true }}
						/>
					</FormControl>
				</div>
				<div className="hazmat-modal-row">
					<FormControl variant="outlined" error={!!errors.hazMatClass}>
						<InputLabel className={classes.field}>HazMat class *</InputLabel>
						<Controller
							render={({ onChange, value, name }) => (
								<Select
									name={name}
									value={value}
									style={{ textAlign: 'left' }}
									onChange={(e) => {
										onChange(e.target.value)
										setHazMatClass(e.target.value)
									}}
									label="hazMatClass"
								>
									{hazMatClassOptions.map((item, index) => (
										<MenuItem value={item.value} key={`id-${index + 1}`}>
											{item.value}
										</MenuItem>
									))}
								</Select>
							)}
							name="hazMatClass"
							control={control}
							defaultValue={hazMatClass}
							rules={{ required: true }}
						/>
					</FormControl>
					<TextField
						name="name"
						type="text"
						label="24-hour contact name *"
						variant="outlined"
						InputLabelProps={{
							classes: {
								root: classes.field,
							},
						}}
						inputRef={register({ required: true, maxLength: { value: 25, message: 'Maximum 25 character allowed' } })}
						error={!!errors.name}
						helperText={errors.name ? errors.name.message : null}
						FormHelperTextProps={{ classes: { root: classes.helperText } }}
					/>
					<TextField
						name="phone"
						type="text"
						label="24-hour contact phone *"
						variant="outlined"
						InputLabelProps={{
							classes: {
								root: classes.field,
							},
						}}
						inputRef={register({
							required: true,
							pattern: {
								value: /^([(]([0-9]{3})[)] ([0-9]{3})(-)([0-9]{4}))$/,
								message: 'Enter a phone number',
							},
						})}
						error={!!errors.phone}
						helperText={errors.phone ? errors.phone.message : null}
						InputProps={{
							inputComponent: PhoneNumberFormat,
						}}
						FormHelperTextProps={{ classes: { root: classes.helperText } }}
					/>
					<div className="hazmat-modal-buttons-row">
						<Button className={classes.addButton} type="submit" color="secondary" variant="contained">
							Save Hazmat
						</Button>
						<Button className={classes.cancelButton} onClick={handleClose} variant="contained">
							Cancel
						</Button>
					</div>
				</div>
			</form>
		</Dialog>
	)
}

HazmatModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	setHazmatData: PropTypes.func,
	setOpenHazmatModal: PropTypes.func,
	updateItem: PropTypes.bool,
	itemHazmat: PropTypes.objectOf(PropTypes.any),
	shipmentItems: PropTypes.arrayOf(PropTypes.any),
	setShipmentItems: PropTypes.func,
}

HazmatModal.defaultProps = {
	setHazmatData: null,
	setOpenHazmatModal: null,
	updateItem: false,
	itemHazmat: null,
	shipmentItems: null,
	setShipmentItems: null,
}

export default HazmatModal
