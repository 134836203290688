import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
	dragAndDropIcon: {
		width: '24px',
		color: theme.palette.secondary.main,
	},
	dragAndDropArea: {
		width: '100%',
		height: '60px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexFlow: 'row nowrap',
		fontSize: '13px',
		fontWeight: 400,
		color: '#2c2c2c',
		border: '1px dashed #c9cfe4',
		borderRadius: '4px',
		backgroundColor: '#f7f8f8',
		position: 'relative',
		justifySelf: 'end',
		marginTop: '0px',
	},
	dragAndDropTitleMobile: {
		margin: '0 0 0 6px',
		fontWeight: 400,
		fontSize: '13px',
		alignSelf: 'center',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	dragAndDropTitleWeb: {
		margin: '0 0 0 6px',
		fontWeight: 400,
		fontSize: '13px',
		alignSelf: 'center',
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	dragAndDropUploader: {
		width: '100%',
		height: '100%',
		opacity: 0,
		position: 'absolute',
		zIndex: 1,
		left: 0,
		top: 0,
		cursor: 'pointer',
	},
	dragDropLogoContainer: {
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	dragDropLogo: {
		height: '40px',
		width: '70px',
		borderRadius: '5px',
	},
}))
