import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	tabContainer: {
		width: '100%',
		'& .react-tabs-container': {
			backgroundColor: 'transparent',
			borderColor: 'transparent',
			justifyContent: 'flex-start',
			minHeight: '57px',
			[theme.breakpoints.down('sm')]: {
				backgroundColor: 'rgba(0,0,0,0.3)',
				display: 'flex',
				flexDirection: 'column',
				height: '100vh',
				justifyContent: 'flex-start',
				left: 0,
				top: '55px',
				position: 'fixed',
				width: '100%',
				zIndex: 3,
			},
			'& .react-tabs-tab-content': {
				color: theme.palette.neutral.white,
				fontFamily: theme.typography.fontFamily,
				fontSize: '13px',
				fontWeight: 500,
				width: 'inherit',
			},
			'& .react-tabs-tab-close': {
				backgroundColor: 'transparent',
				border: 0,
				color: theme.palette.neutral.white,
				fontSize: '20px',
				padding: '0 5px 5px 5px',
				fontWeight: theme.typography.fontWeightMedium,
				visibility: 'visible',
				width: 'auto',
			},
		},
		'& .react-tabs-tab': {
			alignItems: 'center',
			backgroundColor: theme.palette.secondary.dark,
			borderColor: 'transparent',
			cursor: 'pointer',
			display: 'flex',
			maxWidth: 'initial',
			flexGrow: 'inherit',
			padding: '5px 12px',
			[theme.breakpoints.down('sm')]: {
				backgroundColor: '#FFF',
				borderBottom: '1px solid',
				borderBottomColor: theme.palette.primary.light,
				boxSizing: 'border-box',
				borderRadius: '0',
				height: '52px',
				padding: '7px 12px',
				width: '100%',
			},
			'&.react-tabs-active': {
				backgroundColor: theme.palette.secondary.light,
				borderLeft: '0',
				[theme.breakpoints.down('sm')]: {
					backgroundColor: '#FFF',
					borderRadius: '0',
				},
			},
			'& .app-tab': {
				paddingRight: '10px',
			},
		},
		'& .react-tabs-child': {
			marginLeft: 'inherit',
			[theme.breakpoints.down('sm')]: {
				padding: 0,
				width: '100%',
			},
			'& button': {
				alignItems: 'center',
				display: 'flex',
				border: 'none',
				backgroundColor: 'transparent',
				fontFamily: theme.typography.fontFamily,
				fontSize: '15px',
				height: '100%',
				outline: 'none',
				[theme.breakpoints.down('sm')]: {
					backgroundColor: '#FFF',
					height: '52px',
					width: '100%',
				},
			},
		},
	},
	tabAddButton: {
		cursor: 'pointer',
	},
	appTabLocations: {
		fontWeight: theme.typography.fontWeightBold,
	},
	tabStatus: {
		borderRadius: '50%',
		height: '8px',
		marginRight: '4px',
		width: '8px',
	},
	tabStatusBlue: {
		backgroundColor: theme.palette.secondary.main,
	},
	tabStatusRed: {
		backgroundColor: theme.palette.error.main,
	},
	tabStatusOrange: {
		backgroundColor: theme.palette.semantic.orange.primary,
	},
	tabStatusGray: {
		backgroundColor: theme.palette.neutral.secondaryGrey,
		color: theme.palette.neutral.black,
	},
	tabStatusGreen: {
		backgroundColor: theme.palette.success.light,
	},
}))

export default useStyles
