const prepareReferences = (listReferences) => {
	if (Array.isArray(listReferences)) {
		if (listReferences.length > 0) {
			return listReferences.slice().map((ref) => {
				const localRef = { ...ref }
				return {
					referenceType: localRef?.type,
					value: localRef?.value,
				}
			})
		}
	}
	return []
}
const uniqueAccesorial = (listaccesorials) => {
	const unique = [...new Set(listaccesorials.map((data) => data.accessorialId))].map((id) =>
		listaccesorials.find((a) => a.accessorialId === id),
	)
	return unique
}
const prepareAccesorials = (accesorials) =>
	accesorials?.map((acc) => {
		const accesorial = {
			accessorialId: acc?.accessorial_id ?? null,
			name: acc?.name ?? null,
			internalCode: acc?.internal_code ?? null,
			value: acc?.value ?? acc?.name,
			origin_destination: acc?.origin_destination ?? null,
		}
		return accesorial
	})

const prepareItemsData = (items) =>
	items?.map((item) => {
		const shipItems = {
			weight: {
				unit: item?.weight_unit ?? null,
				value: item?.weight_value ?? null,
			},
			unitType: item?.unit_type ?? null,
			pieces: {
				unit: item?.pieces_unit ?? null,
				value: item?.pieces_value ?? null,
			},
			class: item?.class ?? null,
			nmfc: item?.nmfc ?? null,
			subNmfc: item?.sub_nmfc ?? null,
			isHazMat: item?.is_haz_mat ?? null,
			stackable: item?.stackable ?? null,
			hazMatData: {
				shippingName: item?.haz_mat_shipping_name ?? null,
				hazMatNumberType: item?.haz_mat_number_type ?? null,
				hazMatNumber: item?.haz_mat_number ?? null,
				hazMatClass: item?.haz_mat_class ?? null,
				hazMatGroup: item?.haz_mat_group ?? null,
			},
			dimensions: {
				unit: item?.dimensions_unit ?? null,
				lenght: item?.dimensions_lenght ?? null,
				width: item?.dimensions_width ?? null,
				height: item?.dimensions_height ?? null,
			},
			description: item?.description ?? null,
		}
		return shipItems
	})
const prepareDataForUpdateQuote = (data, newAccesorials) => ({
	shipper: {
		locationId: data?.location_origin_id ?? null,
		companyName: data?.company_origin ?? null,
		address: data?.address_origin ?? null,
		address2: data?.address2_origin ?? null,
		contact: data?.contact_origin ?? null,
		phone: data?.phone_origin ?? null,
		...(data?.phone_extension_origin && { phoneExtension: data?.phone_extension_origin }),
		email: data?.email_origin ?? null,
		zipcode: data?.zipcode_origin ?? null,
		city: data?.city_origin ?? null,
		state: data?.state_origin ?? null,
		country: data?.country_origin ?? null,
		pickupDate: data?.est_pickup_date ?? null,
		latitude: data?.lat_origin ?? null,
		longitude: data?.lon_origin ?? null,
	},
	consignee: {
		locationId: data?.location_destination_id ?? null,
		companyName: data?.company_destination ?? null,
		address: data?.address_destination ?? null,
		address2: data?.address2_destination ?? null,
		contact: data?.contact_destination ?? null,
		phone: data?.phone_destination ?? null,
		...(data?.phone_extension_destination && { phoneExtension: data?.phone_extension_destination }),
		email: data?.email_destination ?? null,
		zipcode: data?.zipcode_destination ?? null,
		city: data?.city_destination ?? null,
		state: data?.state_destination ?? null,
		country: data?.country_destination ?? null,
		dropOffDate: data?.est_pickup_date ?? null,
		latitude: data?.lat_destination ?? null,
		longitude: data?.lon_destination ?? null,
	},
	type: data?.type ?? null,
	items: prepareItemsData(data?.items ?? []),
	accessorials: uniqueAccesorial([...prepareAccesorials(data?.accesorials ?? []), ...newAccesorials]),
	references: data?.references ? prepareReferences(data?.references) : null,
	linearFeet: data?.linear_feet ? data?.linear_feet : null,
})

export default prepareDataForUpdateQuote
