import { createSlice } from '@reduxjs/toolkit'
import reqCreateUser, {
	reqGetUsers,
	reqDeactiveUser,
	reqActiveUser,
	reqGetUsersByCompany,
	reqGetUserById,
	reqEditUser,
	reqBulkUserStatus,
	reqAudit,
} from '../requests/Users.requests'

/* eslint no-param-reassign: ["error", { "props": false }] */

const usersSlice = createSlice({
	name: 'usersSlice',
	initialState: {
		users: [],
		totalUsers: 0,
		isLoading: false,
		error: false,
		userPhoto: '',
	},
	reducers: {
		startLoading: (state) => {
			state.isLoading = true
		},
		hasError: (state, action) => {
			state.error = action.payload
			state.isLoading = false
		},
		usersSuccess: (state, action) => {
			state.users = action.payload
			state.isLoading = false
		},
		updateTotalUsers: (state, action) => {
			state.totalUsers = action.payload
		},
		updateUserPhoto: (state, action) => {
			state.userPhoto = action.payload
		},
	},
})

const { actions, reducer } = usersSlice

export const { startLoading, hasError, updateTotalUsers, usersSuccess, updateUserPhoto } = actions

export const fetchUsers = (params) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqGetUsers(params)
	if (error) {
		dispatch(hasError(error))
		return { status: 'error', resp: error }
	}
	dispatch(updateTotalUsers(data.data.data.records))
	dispatch(usersSuccess(data.data.data.users))
	return { status: 'success' }
}

export const fetchUsersByCompany = (params, config) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqGetUsersByCompany(params, config)
	if (error) {
		dispatch(hasError(error))
		return { status: 'error', resp: error }
	}
	dispatch(updateTotalUsers(data.data.data.records))
	dispatch(usersSuccess(data.data.data.users))
	dispatch(hasError(false))
	return { status: 'success' }
}

export const createUser = (params, config) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqCreateUser(params, config)

	if (error) {
		dispatch(hasError(data))
		return { status: 'error', resp: data }
	}
	return { status: 'success', resp: data }
}

export const fetchUserById = (userId) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqGetUserById(userId)

	if (error) {
		dispatch(hasError(error))
		return { status: 'error', resp: error }
	}
	return { status: 'success', resp: data.data.data }
}

export const editUser = (userId, params, config) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqEditUser(userId, params, config)

	if (error) {
		dispatch(hasError(data))
		return { status: 'error', resp: data }
	}
	return { status: 'success', resp: data }
}

export const deactivateUser = (userId) => async (dispatch) => {
	const [error, data] = await reqDeactiveUser(userId)
	if (error) {
		dispatch(hasError(data))
		return { status: 'error', resp: data }
	}

	return { status: 'success', resp: data }
}

export const activateUser = (userId) => async (dispatch) => {
	const [error, data] = await reqActiveUser(userId)
	if (error) {
		dispatch(hasError(data))
		return { status: 'error', resp: data }
	}

	return { status: 'success', resp: data }
}

export const updateUserStatusBulk = (params) => async (dispatch) => {
	const [error, data] = await reqBulkUserStatus(params)
	if (error) {
		dispatch(hasError(data))
		return { status: 'error', resp: data }
	}
	return { status: 'success', resp: data }
}

export const auditUserLogin = (config) => async (dispatch) => {
	// dispatch(startLoading());
	const [error, data] = await reqAudit(config)
	if (error) {
		dispatch(hasError(error))
		return { status: 'error', resp: error }
	}
	return { status: 'success', resp: data }
}

export default reducer
