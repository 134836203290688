import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			borderBottom: 'unset',
		},
	},
	containerButtonActions: {
		display: 'flex',
		flexDirection: 'column',
		rowGap: '5px',
		margin: '0 auto',
		justifyContent: 'center',
		width: '120px',
	},
	buttonActionItem: {
		display: 'flex',
		alignItems: 'center',
		columnGap: '5px',
		cursor: 'pointer',
		backgroundColor: 'transparent',
		border: 'none',
		outline: 'unset',
	},
	labelActionButton: {
		color: theme.palette.neutral.black,
		textAlign: 'left',
	},
	disabled: {
		color: theme.palette.neutral.mainGray,
	},
	helpCursor: {
		'&:hover': {
			cursor: 'help',
		},
	},
	icon: {
		color: theme.palette.success.main,
	},
}))

export default useStyles
