import React from 'react'
import PropTypes from 'prop-types'

import { NoDataIcon } from '../../assets/Icons/Icons'

import useStyles from './styles'

const NoResultsFound = (props) => {
	const { type, filters } = props
	const classes = useStyles()

	return (
		<div className={classes.noDataDiv}>
			<NoDataIcon style={{ width: 180, height: 180 }} />
			<h1 className={classes.noDataTitle}>{`No ${type} Found`}</h1>
			{filters ? (
				<>
					<p className={classes.noDataSubtitle}>No results were found in the search.</p>
					<p className={classes.noDataSubtitle}>Try again with a new search.</p>
				</>
			) : null}
		</div>
	)
}

NoResultsFound.propTypes = {
	type: PropTypes.string.isRequired,
	filters: PropTypes.string,
}

NoResultsFound.defaultProps = { filters: null }

export default NoResultsFound
