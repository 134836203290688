import React from 'react'
import NewItemContainer from '../containers/NewItemContainer'
import useStyles from './styles'

const NewItem = () => {
	const classes = useStyles()
	return (
		<div className={classes.newItemContainer}>
			<NewItemContainer />
		</div>
	)
}

export default NewItem
