import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	toolBar: {
		margin: theme.spacing(1, 0),
		[theme.breakpoints.up('md')]: {
			margin: theme.spacing(2, 0),
		},
	},
	toolBarText: {
		fontSize: 14,
		[theme.breakpoints.up('md')]: {
			fontSize: 16,
		},
	},
	toolBarBurton: {
		fontSize: 13,
		fontWeight: theme.typography.fontWeightRegular,
		textTransform: 'none',
		color: theme.palette.neutral.black,
	},
	toolBarButtonIcon: {
		'& > :first-child': {
			fontSize: 24,
		},
	},
	toolBarButtonIconMore: {
		'& .MuiSvgIcon-root': {
			fontSize: 24,
		},
	},
	iconRed: {
		color: theme.palette.primary.main,
	},
	iconGreen: {
		color: theme.palette.success.main,
	},
	listItemIcon: {
		minWidth: 32,
		'& .MuiSvgIcon-root': {
			fontSize: 24,
		},
	},
}))

export default useStyles
