/* eslint-disable import/no-unresolved */
import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as moment from 'moment'
import { Grid, IconButton } from '@material-ui/core'
import { FilterList, Loop } from '@material-ui/icons'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import Button from '@material-ui/core/Button'

import {
	setCurrentPage,
	setRowsPerPage,
	setStartDate,
	setEndDate,
	setSearchBar,
	resetFilters,
	setStatus,
	setOrderByAndOrder,
} from '../../../../redux/actions/QuotesFilters.actions'
import useQuotesFilters from '../../../../hooks/useQuotesFilters'
import { fetchQuotes } from '../../../../redux/actions/Quotes.actions'
import { clearQuoteDetailsForReQuote } from '../../../../redux/actions/QuoteDetails.actions'

import AutoCompleteInput from '../../../../components/AutocompleteInput'
import RangeDatePicker from '../../../../components/RangeDatePicker'
import SearchBar from '../../../../components/SearchBar'
import SavedQuotesTable from '../../../../components/TableList'

import { clearTab, setTabCurrentRoute, setTabTitle } from '../../../../redux/actions/MultiTabs.actions'

import sweetAlertModal from '../../../../components/SweetAlertModal/SweetAlertModal'
import { quotesListRoute } from '../../../../utils/constants'
import { capitalizeFirstLetter } from '../../../../utils/helpers'
import useQuery from '../../../../hooks/useQuery'
import useStyles from './styles'
import ModuleHeader from '../../../../components/ModuleHeader'

const getCapitalizedStatus = (stringStatus) => {
	if (!stringStatus.includes(',')) {
		return [
			{
				title: capitalizeFirstLetter(stringStatus),
			},
		]
	}
	const statuses = stringStatus.split(',')
	const capitalizedStatus = statuses.map((status) => ({
		title: capitalizeFirstLetter(status),
	}))
	return capitalizedStatus
}

const getArrayFromValues = (values) => [values]

const SavedQuotes = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const matches = useMediaQuery('(min-width:768px)')
	const query = useQuery()
	const queryParamsId = query.get('id')
	const [buildParamsFromState] = useQuotesFilters()
	const [startDate, setNewStartDate] = useState(null)
	const [endDate, setEndDates] = useState(null)
	const [open, setOpen] = useState(false)
	const { searchBar } = useSelector((state) => state.quotesFilters)
	const [search, setSearch] = useState(decodeURIComponent(searchBar))
	const [autoCompleteValues, setAutoComplete] = useState([])
	const [pageReset, setPageReset] = useState(false)
	const [currentSort, setCurrentSort] = useState({})
	const [orderBy, setOrderBy] = useState('')

	const SavedQuotesProvider = () => {
		const callback = useCallback(async (params) => {
			const { status } = await dispatch(fetchQuotes(params))
			if (status === 'error')
				sweetAlertModal('error', 'There was an error fetching the quotes', null, 'Ok', true, false)
		}, [])

		return { callback }
	}

	const { callback } = SavedQuotesProvider()
	const filterParams = buildParamsFromState()

	useEffect(() => {
		const setQueryParams = queryParamsId ? `?id=${queryParamsId}` : ''
		dispatch(clearTab())
		dispatch(setTabCurrentRoute(`${quotesListRoute}${setQueryParams}`))
		dispatch(setTabTitle('Saved Quotes'))
		dispatch(clearQuoteDetailsForReQuote(''))
	}, [dispatch, queryParamsId])

	useEffect(() => {
		callback(filterParams)
	}, [callback, filterParams])

	useEffect(() => {
		if (queryParamsId) {
			setSearch(queryParamsId)
			dispatch(setSearchBar(queryParamsId))
			setPageReset(true)
		}
	}, [dispatch, queryParamsId])

	useEffect(() => {
		const preservedFilters = localStorage.getItem('preserved-quote-filters')
		if (preservedFilters) {
			const filterObj = preservedFilters.split('&')
			filterObj.forEach((filter) => {
				const filterKeyValue = filter.split('=')
				switch (filterKeyValue[0]) {
					case 'status':
						dispatch(setStatus(getArrayFromValues(filterKeyValue[1])))
						setAutoComplete(getCapitalizedStatus(filterKeyValue[1]))
						break
					case 'search_bar':
						dispatch(setSearchBar(filterKeyValue[1]))
						setSearch(decodeURIComponent(filterKeyValue[1]))
						break
					case 'start_date':
						dispatch(setStartDate(filterKeyValue[1]))
						setNewStartDate(filterKeyValue[1])
						break
					case 'end_date':
						dispatch(setEndDate(filterKeyValue[1]))
						setEndDates(filterKeyValue[1])
						break
					case 'order_by':
						setOrderBy(filterKeyValue[1])
						break
					case 'order':
						dispatch(setOrderByAndOrder({ orderBy, order: filterKeyValue[1] }))
						break
					default:
						dispatch(setCurrentPage(filterKeyValue[0].split(/[/?]/)[1]))
						dispatch(setRowsPerPage(filterKeyValue[0].split(/[/?]/)[2]))
						break
				}
			})
		}
	}, [dispatch, orderBy])

	const toggleDrawer = (openDrawer) => (event) => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return
		}
		setOpen(openDrawer)
	}

	const showFilters = () => {
		setOpen(true)
	}

	const handleStartDate = (selectedDate) => {
		const newStartDate = moment(selectedDate).format('YYYY-MM-DD')
		if (moment(newStartDate).isAfter(new Date())) {
			sweetAlertModal('error', 'Please select a valid date range', null, 'Ok', true, false)
			dispatch(setStartDate(moment(new Date()).format('YYYY-MM-DD')))
			setNewStartDate(new Date())
			setPageReset(true)
		} else {
			setNewStartDate(selectedDate)
			dispatch(setStartDate(newStartDate))
			dispatch(setCurrentPage(0))
			setPageReset(true)
		}
	}

	const handleEndDate = async (selectedDate) => {
		const newEndDate = moment(selectedDate).format('YYYY-MM-DD')
		if (moment(startDate).isAfter(selectedDate)) {
			sweetAlertModal('error', 'Please select a valid date range', null, 'Ok', true, false)
			setEndDates(new Date())
			setPageReset(true)
		} else {
			setEndDates(selectedDate)
			dispatch(setEndDate(newEndDate))
			dispatch(setCurrentPage(0))
			setPageReset(true)
		}
	}

	const handleSearchBarChanged = (event) => {
		setSearch(event.target.value)
		if (event.target.value.length >= 3) {
			dispatch(setSearchBar(encodeURIComponent(event.target.value)))
			setPageReset(true)
		} else if (event.target.value.length === 0) {
			dispatch(setSearchBar(''))
			setPageReset(true)
		}
	}

	const handleResetFilters = () => {
		dispatch(resetFilters())
		dispatch(setEndDate(''))
		dispatch(setStartDate(''))
		setEndDates(null)
		setNewStartDate(null)
		setSearch('')
		setAutoComplete([])
		setPageReset(false)
		setCurrentSort({})
		localStorage.removeItem('preserved-quote-filters')
	}

	const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

	return (
		<>
			<ModuleHeader
				title={
					<div className={classes.quotesHistoryTitleButton}>
						<h1>Saved Quotes</h1>
						<IconButton aria-label="Toggle Filters" onClick={showFilters} className={classes.filterTriggerButton}>
							<FilterList />
						</IconButton>
					</div>
				}
				subtitle="Consult the quotes created in the system."
			/>
			<SwipeableDrawer
				className={classes.drawer}
				anchor="top"
				open={open}
				onClose={toggleDrawer(false)}
				disableBackdropTransition={!iOS}
				disableDiscovery={iOS}
			>
				<div className={classes.filterFormWrapper} role="presentation">
					<Grid container spacing={2} alignItems="center">
						<Grid item xs={12}>
							<AutoCompleteInput
								values={autoCompleteValues}
								setUpdate={(value) => {
									setPageReset(true)
									dispatch(setCurrentPage(0))
									setAutoComplete(value)
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<RangeDatePicker
								label="Filter by quote initial date"
								valueDate={startDate}
								setValueDate={handleStartDate}
							/>
						</Grid>
						<Grid item xs={12}>
							<RangeDatePicker label="Filter by quote final date" valueDate={endDate} setValueDate={handleEndDate} />
						</Grid>
						<Grid item xs={12}>
							<Button
								onClick={handleResetFilters}
								startIcon={<Loop color="secondary" />}
								className={classes.filterButtonModal}
							>
								<span className={classes.filterButtonModalText}>Reset Filters</span>
							</Button>
						</Grid>
					</Grid>
				</div>
			</SwipeableDrawer>
			<Grid container spacing={2}>
				{matches && (
					<>
						<Grid item xs={12} md={4}>
							<AutoCompleteInput
								values={autoCompleteValues}
								setUpdate={(value) => {
									setPageReset(true)
									dispatch(setCurrentPage(0))
									setAutoComplete(value)
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<RangeDatePicker
								label="Filter by quote initial date"
								valueDate={startDate}
								setValueDate={handleStartDate}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<RangeDatePicker label="Filter by quote final date" valueDate={endDate} setValueDate={handleEndDate} />
						</Grid>
					</>
				)}
				<Grid item xs={12} md={10}>
					<SearchBar
						value={search}
						placeholder="Search for quote number, quote id..."
						onChangeHandler={handleSearchBarChanged}
						size="small"
					/>
				</Grid>
				{matches ? (
					<Grid item xs={2}>
						<Button
							onClick={handleResetFilters}
							color="primary"
							startIcon={<Loop color="secondary" />}
							className={classes.filterButton}
						>
							<span className={classes.filterButtonText}>Reset Filters</span>
						</Button>
					</Grid>
				) : null}
			</Grid>
			<SavedQuotesTable
				tableType="savedQuotes"
				pageReset={pageReset}
				search={search}
				currentSort={currentSort}
				setCurrentSort={setCurrentSort}
			/>
		</>
	)
}

export default SavedQuotes
