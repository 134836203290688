import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	styledStepper: {
		backgroundColor: theme.palette.neutral.whiteGray,
		padding: 0,
		marginLeft: -8,
		pointerEvents: 'none',
		[theme.breakpoints.up('lg')]: {
			marginLeft: 0,
		},
	},
	stepperLabel: {
		color: theme.palette.secondary.main,
		marginBottom: 16,
		[theme.breakpoints.up('lg')]: {
			display: 'none',
		},
	},
	defaultCursor: { cursor: 'default' },
}))

export const styledStepperConnector = makeStyles((theme) => ({
	alternativeLabel: {
		left: 0,
		right: 0,
		transform: 'translateX(-100%) translateX(16px)',
		[theme.breakpoints.up('lg')]: {
			left: '-50%',
			right: '50%',
			transform: 'unset',
		},
	},
	active: {
		'& $line': {
			backgroundColor: theme.palette.neutral.secondaryGrey,
		},
	},
	completed: {
		'& $line': {
			backgroundColor: theme.palette.secondary.main,
		},
	},
	line: {
		height: 8,
		backgroundColor: theme.palette.neutral.secondaryGrey,
		border: 0,
		borderRadius: 1,
	},
}))

export const styledStepperIcon = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: theme.palette.neutral.darkGray,
		position: 'relative',
		width: 32,
		height: 32,
		borderRadius: '50%',
		zIndex: 1,
		fontWeight: theme.typography.fontWeightBold,
		fontSize: 20,
		color: theme.palette.neutral.white,
		lineHeight: 0,
	},
	active: {
		backgroundColor: theme.palette.neutral.darkGray,
	},
	completed: {
		backgroundColor: theme.palette.secondary.main,
	},
}))

export const styledStepperLabel = makeStyles((theme) => ({
	root: {
		alignItems: 'start',
		[theme.breakpoints.up('lg')]: {
			alignItems: 'center',
		},
	},
	label: {
		display: 'none',
		color: theme.palette.neutral.darkGray,
		[theme.breakpoints.up('lg')]: {
			display: 'block',
		},
		'&.MuiStepLabel-completed': {
			color: theme.palette.primary.main,
			fontWeight: theme.typography.fontWeightRegular,
		},
		'&.MuiStepLabel-active': {
			color: theme.palette.neutral.darkGray,
		},
		'&.MuiStepLabel-alternativeLabel': {
			marginTop: 8,
		},
	},
}))

export default useStyles
