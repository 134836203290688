import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	root: {
		'& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
			display: 'none',
		},
	},
	button: {
		height: '48px',
		fontSize: '14px',
		fontWeight: '500',
		color: '#141313',
		margin: theme.spacing(0, 0, 0),
		justifyContent: 'flex-end',
		textTransform: 'none',
		'&:hover': {},
		'@media only screen and (max-width: 768px)': {
			width: '140px',
		},
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginBottom: '10px',
			marginLeft: '0em',
		},
	},
	formContainer: {
		marginBottom: '10px',
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
	newShipmentIcon: {
		fontSize: '25px',
	},
}))

export default useStyles
