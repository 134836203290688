import { useSelector } from 'react-redux'

const useTemplateItemsFilters = () => {
	const { searchBar, searchByLabel, currentPage, rowsPerPage } = useSelector((state) => state.templateItemsFilters)
	const buildParamsFromState = () => {
		let queryParams = ''

		if (currentPage !== '') {
			queryParams += `?page=${currentPage}`
		}

		if (rowsPerPage && rowsPerPage !== '') {
			queryParams += `&limit=${rowsPerPage}`
		}

		if (searchBar !== '') {
			queryParams += `&search_bar=${searchBar}`
		}

		if (searchByLabel !== '') {
			queryParams += `&search_label=${searchByLabel}`
		}
		// Save the filters into the localStorage for future loads (When refreshing, changing tabs, etc)
		if (queryParams !== '' && queryParams !== '&') localStorage.setItem('preserved-item-filters', queryParams)
		return queryParams
	}

	return [buildParamsFromState]
}

export default useTemplateItemsFilters
