import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	tableRow: {
		height: 100,
	},
	tableCell: {
		height: 'auto',
		padding: '0 16px',
	},
	tableCellNumber: {
		textDecoration: 'underline',
		cursor: 'pointer',
	},
	imgItem: {
		display: 'flex',
		justifyContent: 'left',
		alignItems: 'center',
	},
	carrierLogo: {
		width: 37,
		height: 37,
		marginRight: 12,
		objectFit: 'contain',
	},
	status: {
		fontSize: 11,
		fontWeight: theme.typography.fontWeightRegular,
		color: theme.palette.neutral.white,
		padding: '0 14px',
	},
	statusPurple: {
		backgroundColor: theme.palette.semantic.purple.light,
		color: theme.palette.semantic.purple.primary,
	},
	statusRed: {
		backgroundColor: theme.palette.error.main,
	},
	statusOrange: {
		backgroundColor: theme.palette.warning.light,
		color: theme.palette.warning.dark,
	},
	statusGray: {
		backgroundColor: theme.palette.neutral.secondaryGrey,
		color: theme.palette.neutral.black,
	},
	statusGreen: {
		color: theme.palette.success.main,
		backgroundColor: theme.palette.success.light,
	},
	ref: {
		color: theme.palette.secondary.main,
		fontWeight: theme.typography.fontWeightMedium,
		marginRight: 4,
	},
}))

export default useStyles
