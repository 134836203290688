import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	root: {
		flexDirection: 'column',
		height: 0,
		margin: 0,
		padding: 0,
		borderRadius: 0,
		fontSize: 16,
		backgroundColor: theme.palette.warning.light,
		'&.isOpen': {
			height: 'auto',
			margin: '-16px -16px 24px',
			padding: theme.spacing(2),
			[theme.breakpoints.up('md')]: {
				flexDirection: 'row',
				margin: '-16px -24px 24px',
				padding: theme.spacing(2, 3),
			},
		},
	},
	action: {
		color: theme.palette.warning.main,
	},
	message: {
		padding: 0,
		marginTop: 3,
	},
	button: {
		fontSize: 13,
	},
}))

export default useStyles
