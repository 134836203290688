import React from 'react'
// import PropTypes from 'prop-types';
import { TableCell, TableHead, TableRow } from '@material-ui/core'
import useStyles from './styles'
import { usersNewAccountTableCells } from '../../../../../../../utils/headCells'

const TableHeader = () => {
	const classes = useStyles()
	return (
		<TableHead>
			<TableRow>
				{usersNewAccountTableCells.map((headCell) => (
					<TableCell key={headCell.id} className={classes.tableCell}>
						{headCell.label}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

TableHeader.propTypes = {}

export default TableHeader
