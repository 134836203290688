import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	Dashboard as DashboardIcon,
	SaveOutlined,
	ListOutlined,
	NoteAddOutlined,
	AssessmentOutlined,
	AssignmentOutlined,
	LocationOnOutlined,
} from '@material-ui/icons'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import { useTheme } from '@material-ui/core/styles'
import {
	quotesListRoute,
	shipmentsListRoute,
	dashboardRoute,
	createQuotesRoute,
	createUserRoute,
	usersListRoute,
	createLocationRoute,
	locationsListRoute,
	createItemRoute,
	itemsListRoute,
	createAccountRoute,
	accountsListRoute,
	invoicesListRoute,
	createShipmentsRoute,
	reportsRoute,
	carriersListRoute,
	createCarrierRoute,
	permissionName,
	shipmentDetails,
} from '../../utils/constants'
import { clearQuoteDetails, clearQuoteDetailsForReQuote } from '../../redux/actions/QuoteDetails.actions'
import useStyles from './styles'
import { HelpIcon } from '../../assets/Icons/Icons'
import { setOpen, setSubject } from '../../redux/actions/SupportModal.actions'
import SessionContext from '../../context/session'
import isPermit from '../../utils/permission'
import { setTabs } from '../../redux/actions/MultiTabs.actions'
import AppSwitcher from '../AppSwitcher'
import FreigthProsLogo from '../../assets/img/beonltl_lg_1cp_wht_rev.png'

const mapViews = {
	dashboard: 'Dashboard',
	quotesList: 'Saved Quotes',
	shipmentsList: 'My Shipments',
	new: 'New Quote',
	viewInvoices: 'Invoices',
	locations: 'Create New',
	items: 'My Items',
	reports: 'Reports',
	help: 'Get Help',
	support: 'My Support Request',
	createUser: 'Create User',
	updateLocation: 'Update Location',
	updateItem: 'Update Item',
	accountDetails: 'Account Details',
	accountEdit: 'Update Account',
}

const defaultSelected = {
	dashboard: false,
	quotesList: false,
	shipmentsList: false,
	new: false,
	viewInvoices: false,
	locations: false,
	items: false,
	reports: false,
	help: false,
	support: false,
	createUser: false,
	createLocation: false,
	updateLocation: false,
	createItem: false,
	createAccount: false,
	accounts: false,
	accountDetails: false,
	accountEdit: false,
	createCarrier: false,
	carriers: false,
}

// const FreigthProsLogo = 'https://stgfreightprosdevapp01.blob.core.windows.net/beon-logo-ltl/beonltl_lg_1cp_wht_rev.png';
// const FreigthPros = 'https://stgfreightprosdevapp01.blob.core.windows.net/beon-logo-ltl/beonltl_lg_1cp_wht_rev.png';

const Sidebar = ({ mobileOpen }) => {
	const { t: translator } = useTranslation('Sidebar')
	const tabs = useSelector((state) => state.multiTabs.tabs)
	const location = useLocation()

	const [isSelected, setIsSelected] = useState({ ...defaultSelected })
	const [routes, setRoutes] = useState('')

	const classes = useStyles()
	const theme = useTheme()
	const history = useHistory()
	const dispatch = useDispatch()
	const { getUserData } = useContext(SessionContext)
	const { permissions } = getUserData()

	const validateRoute = (name) => routes === name

	useEffect(() => {
		const activeTab = tabs.find((item) => (item?.active ? item.active : false))
		if (activeTab) {
			const currentView = Object.keys(mapViews).find((key) => mapViews[key] === activeTab.title)
			setIsSelected({ ...defaultSelected, [currentView]: true })
			setRoutes(activeTab.route)
		}
	}, [tabs])

	const handleChange = (target, route) => {
		if (location.pathname === createShipmentsRoute) {
			dispatch(clearQuoteDetailsForReQuote(''))
			dispatch(clearQuoteDetails(''))
		}
		if (!location.pathname.includes(shipmentDetails.replace(':id', ''))) {
			if (!isSelected[target]) {
				if (route === createQuotesRoute) {
					history.push({
						pathname: route,
						state: { isNewQuote: true },
					})
				} else {
					history.push(route)
				}
				setIsSelected({ ...defaultSelected, [target]: true })
			}
		} else {
			const principalTab = tabs?.find((tab) => !tab?.route.includes(shipmentDetails.replace(':id', '')))
			if (principalTab?.route) {
				if (principalTab.route === route) {
					const newTabs = tabs.map((tab) => ({
						...tab,
						active: tab?.route === route,
					}))
					dispatch(setTabs(newTabs))
					history.push(principalTab.route)
				}
			}
		}
	}

	return (
		<>
			<div className={classes.toolbar}>
				<AppSwitcher isOpen={mobileOpen} />
				<img
					className={mobileOpen ? classes.sidebarLogo : classes.sidebarClosedLogo}
					src={!mobileOpen ? FreigthProsLogo : FreigthProsLogo}
					alt="FreightPros logo"
				/>
			</div>
			<List disablePadding>
				<ListItem
					className={classes.sidebarItemList}
					onClick={() => handleChange('dashboard', dashboardRoute)}
					selected={isSelected.dashboard || validateRoute(dashboardRoute)}
					button
				>
					<ListItemIcon className={classes.listIcon}>
						<DashboardIcon fontSize="inherit" />
					</ListItemIcon>
					<ListItemText disableTypography className={classes.sidebarText} primary={translator('dashboard')} />
				</ListItem>
			</List>
			<List
				disablePadding
				subheader={
					<ListSubheader disableSticky className={classes.sidebarSubtitle}>
						<div className={clsx({ visible: mobileOpen })}>{translator('quotes_and_shipment')}</div>
						<div className={clsx({ visible: !mobileOpen })}>Q&S</div>
					</ListSubheader>
				}
			>
				{isPermit(permissions, permissionName.QUOTES_CREATE) && (
					<ListItem
						className={classes.sidebarItemList}
						onClick={() => handleChange('new', createQuotesRoute)}
						selected={validateRoute(createQuotesRoute) || validateRoute(createQuotesRoute)}
						button
					>
						<ListItemIcon className={classes.listIcon}>
							<NoteAddOutlined fontSize="inherit" />
						</ListItemIcon>
						<ListItemText disableTypography className={classes.sidebarText} primary={translator('create_new')} />
					</ListItem>
				)}
				<ListItem
					className={classes.sidebarItemList}
					onClick={() => handleChange('quotesList', quotesListRoute)}
					selected={validateRoute(quotesListRoute) || validateRoute(quotesListRoute)}
					button
				>
					<ListItemIcon className={classes.listIcon}>
						<SaveOutlined fontSize="inherit" />
					</ListItemIcon>
					<ListItemText disableTypography className={classes.sidebarText} primary={translator('saved_quotes')} />
				</ListItem>
				{isPermit(permissions, permissionName.SHIPMENTS_READ) && (
					<ListItem
						className={classes.sidebarItemList}
						onClick={() => handleChange('shipmentsList', shipmentsListRoute)}
						selected={validateRoute(shipmentsListRoute) || validateRoute(shipmentsListRoute)}
						button
					>
						<ListItemIcon className={classes.listIcon}>
							<ListOutlined fontSize="inherit" />
						</ListItemIcon>
						<ListItemText disableTypography className={classes.sidebarText} primary={translator('my_shipments')} />
					</ListItem>
				)}
			</List>

			<List
				disablePadding
				subheader={
					<ListSubheader disableSticky className={classes.sidebarSubtitle}>
						<div className={clsx({ visible: mobileOpen })}>{translator('users')}</div>
						<div className={clsx({ visible: !mobileOpen })}>{translator('users')}</div>
					</ListSubheader>
				}
			>
				{(isPermit(permissions, permissionName.FP_ADMIN_USERS_CREATE) ||
					isPermit(permissions, permissionName.ADMIN_USERS_CREATE)) && (
					<ListItem
						className={classes.sidebarItemList}
						onClick={() => handleChange('createUser', createUserRoute)}
						selected={validateRoute(createUserRoute) || validateRoute(createUserRoute)}
						button
					>
						<ListItemIcon className={classes.listIcon}>
							<NoteAddOutlined fontSize="inherit" />
						</ListItemIcon>
						<ListItemText disableTypography className={classes.sidebarText} primary={translator('create_user')} />
					</ListItem>
				)}
				<ListItem
					className={classes.sidebarItemList}
					onClick={() => handleChange('userList', usersListRoute)}
					selected={validateRoute(usersListRoute) || validateRoute(usersListRoute)}
					button
				>
					<ListItemIcon className={classes.listIcon}>
						<ListOutlined fontSize="inherit" />
					</ListItemIcon>
					<ListItemText disableTypography className={classes.sidebarText} primary={translator('my_users')} />
				</ListItem>
			</List>
			<List
				disablePadding
				subheader={
					<ListSubheader disableSticky className={classes.sidebarSubtitle}>
						<div className={clsx({ visible: mobileOpen })}>{translator('locations')}</div>
						<div className={clsx({ visible: !mobileOpen })}>{translator('locations')}</div>
					</ListSubheader>
				}
			>
				<ListItem
					className={classes.sidebarItemList}
					onClick={() => handleChange('createLocation', createLocationRoute)}
					selected={isSelected.createLocation || validateRoute(createLocationRoute)}
					button
				>
					<ListItemIcon className={classes.listIcon}>
						<NoteAddOutlined fontSize="inherit" />
					</ListItemIcon>
					<ListItemText disableTypography className={classes.sidebarText} primary={translator('create_new')} />
				</ListItem>
				<ListItem
					className={classes.sidebarItemList}
					onClick={() => handleChange('locations', locationsListRoute)}
					selected={isSelected.locations || validateRoute(locationsListRoute)}
					button
				>
					<ListItemIcon className={classes.listIcon}>
						<LocationOnOutlined fontSize="inherit" />
					</ListItemIcon>
					<ListItemText disableTypography className={classes.sidebarText} primary={translator('my_locations')} />
				</ListItem>
			</List>
			<List
				disablePadding
				subheader={
					<ListSubheader disableSticky className={classes.sidebarSubtitle}>
						<div className={clsx({ visible: mobileOpen })}>{translator('product_book')}</div>
						<div className={clsx({ visible: !mobileOpen })}>{translator('product_book')}</div>
					</ListSubheader>
				}
			>
				<ListItem
					className={classes.sidebarItemList}
					onClick={() => handleChange('createItem', createItemRoute)}
					selected={isSelected.createItem || validateRoute(createItemRoute)}
					button
				>
					<ListItemIcon className={classes.listIcon}>
						<NoteAddOutlined fontSize="inherit" />
					</ListItemIcon>
					<ListItemText disableTypography className={classes.sidebarText} primary={translator('create_new')} />
				</ListItem>
				<ListItem
					className={classes.sidebarItemList}
					onClick={() => handleChange('items', itemsListRoute)}
					selected={isSelected.items || validateRoute(itemsListRoute)}
					button
				>
					<ListItemIcon className={classes.listIcon}>
						<ListOutlined fontSize="inherit" />
					</ListItemIcon>
					<ListItemText disableTypography className={classes.sidebarText} primary={translator('my_items')} />
				</ListItem>
			</List>
			{isPermit(permissions, permissionName.INVOICES_READ) && (
				<List
					disablePadding
					subheader={
						<ListSubheader disableSticky className={classes.sidebarSubtitle}>
							<div className={clsx({ visible: mobileOpen })}>{translator('invoices')}</div>
							<div className={clsx({ visible: !mobileOpen })}>{translator('invoices')}</div>
						</ListSubheader>
					}
				>
					<ListItem
						className={classes.sidebarItemList}
						onClick={() => handleChange('viewInvoices', invoicesListRoute)}
						selected={isSelected.viewInvoices || validateRoute(invoicesListRoute)}
						button
					>
						<ListItemIcon className={classes.listIcon}>
							<AssignmentOutlined fontSize="inherit" />
						</ListItemIcon>
						<ListItemText disableTypography className={classes.sidebarText} primary={translator('my_invoices')} />
					</ListItem>
				</List>
			)}
			{isPermit(permissions, permissionName.REPORTS_READ) && (
				<List
					disablePadding
					subheader={
						<ListSubheader disableSticky className={classes.sidebarSubtitle}>
							<div className={clsx({ visible: mobileOpen })}>{translator('reports')}</div>
							<div className={clsx({ visible: !mobileOpen })}>{translator('reports')}</div>
						</ListSubheader>
					}
				>
					<ListItem
						className={classes.sidebarItemList}
						onClick={() => handleChange('reports', reportsRoute)}
						selected={isSelected.reports || validateRoute(reportsRoute)}
						button
					>
						<ListItemIcon className={classes.listIcon}>
							<AssessmentOutlined fontSize="inherit" />
						</ListItemIcon>
						<ListItemText disableTypography className={classes.sidebarText} primary={translator('my_reports')} />
					</ListItem>
				</List>
			)}
			<List
				disablePadding
				subheader={
					<ListSubheader disableSticky className={classes.sidebarSubtitle}>
						<div className={clsx({ visible: mobileOpen })}>{translator('accounts')}</div>
						<div className={clsx({ visible: !mobileOpen })}>{translator('accounts')}</div>
					</ListSubheader>
				}
			>
				{isPermit(permissions, permissionName.FP_ADMIN_ACCOUNTS_CREATE) && (
					<ListItem
						className={classes.sidebarItemList}
						onClick={() => handleChange('createAccount', createAccountRoute)}
						selected={isSelected.createAccount || validateRoute(createAccountRoute)}
						button
					>
						<ListItemIcon className={classes.listIcon}>
							<NoteAddOutlined fontSize="inherit" />
						</ListItemIcon>
						<ListItemText
							disableTypography
							className={classes.sidebarText}
							primary={translator('create_new_account')}
						/>
					</ListItem>
				)}
				<ListItem
					className={classes.sidebarItemList}
					onClick={() => handleChange('accounts', accountsListRoute)}
					selected={isSelected.accounts || validateRoute(accountsListRoute)}
					button
				>
					<ListItemIcon className={classes.listIcon}>
						<ListOutlined fontSize="inherit" />
					</ListItemIcon>
					<ListItemText disableTypography className={classes.sidebarText} primary={translator('my_accounts')} />
				</ListItem>
			</List>

			<List
				disablePadding
				subheader={
					<ListSubheader disableSticky className={classes.sidebarSubtitle}>
						<div className={clsx({ visible: mobileOpen })}>{translator('carriers')}</div>
						<div className={clsx({ visible: !mobileOpen })}>{translator('carriers')}</div>
					</ListSubheader>
				}
			>
				{isPermit(permissions, permissionName.FP_ADMIN_CARRIERS_CREATE) && (
					<ListItem
						className={classes.sidebarItemList}
						onClick={() => handleChange('createCarrier', createCarrierRoute)}
						selected={isSelected.createCarrier || validateRoute(createCarrierRoute)}
						button
					>
						<ListItemIcon className={classes.listIcon}>
							<NoteAddOutlined fontSize="inherit" />
						</ListItemIcon>
						<ListItemText
							disableTypography
							className={classes.sidebarText}
							primary={translator('create_new_carrier')}
						/>
					</ListItem>
				)}
				<ListItem
					className={classes.sidebarItemList}
					onClick={() => handleChange('carriers', carriersListRoute)}
					selected={isSelected.carriers || validateRoute(carriersListRoute)}
					button
				>
					<ListItemIcon className={classes.listIcon}>
						<ListOutlined fontSize="inherit" />
					</ListItemIcon>
					<ListItemText disableTypography className={classes.sidebarText} primary={translator('carriers_list')} />
				</ListItem>
			</List>
			<List
				disablePadding
				subheader={
					<ListSubheader disableSticky className={classes.sidebarSubtitle}>
						<div className={clsx({ visible: mobileOpen })}>{translator('support')}</div>
						<div className={clsx({ visible: !mobileOpen })}>{translator('support')}</div>
					</ListSubheader>
				}
			>
				<ListItem
					className={classes.sidebarItemList}
					onClick={() => {
						dispatch(setSubject(''))
						dispatch(setOpen(true))
						handleChange('help', '/')
					}}
					button
				>
					<ListItemIcon className={classes.listIcon}>
						<HelpIcon fontSize="inherit" color={theme.palette.action.main} />
					</ListItemIcon>
					<ListItemText disableTypography className={classes.sidebarText} primary={translator('get_help')} />
				</ListItem>
			</List>
		</>
	)
}

Sidebar.defualtProps = {
	items: [],
}

Sidebar.propTypes = {
	mobileOpen: PropTypes.bool.isRequired,
}

export default Sidebar
