import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	desktopTable: {
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},
	tableContainer: {
		margin: '20px 0px 30px 0px',
		'&::-webkit-scrollbar': {
			width: '5px',
		},
		'&::-webkit-scrollbar-track': {
			'-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
			borderRadius: '10px',
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: '10px',
			'-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.5)',
		},
	},
	table: {
		width: '97%',
		marginLeft: '20px',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginBottom: '10px',
			minWidth: 100,
		},
		[theme.breakpoints.down('sm')]: {
			marginLeft: '0px',
		},
	},
	tableCellHead: {
		borderBottomStyle: 'none',
		fontSize: '14px',
		fontWeight: theme.typography.fontWeightMedium,
	},
	noLiabilitiesMessageContainer: {
		margin: '0 0 20px 0',
		color: theme.palette.neutral.darkGray,
	},
	noLiabilitiesMessageTitle: {
		textAlign: 'center',
		margin: '10px 0',
		fontSize: '17px',
		fontWeight: theme.typography.fontWeightBold,
		[theme.breakpoints.down('md')]: {
			fontSize: '14px',
		},
	},
}))

export default useStyles
