import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, TextField, IconButton, InputAdornment } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

const useStyles = makeStyles(() => ({
	searchBar: {
		width: '100%',
		fontSize: '16px',
		height: '42px',
		padding: '0',
		'&::placeholder': {
			fontFamily: 'Open Sans',
			fontSize: '16px',
			fontWeight: '400',
			color: 'black',
			opacity: 1,
		},
	},
}))

const SearchBar = ({ placeholder, value, autoFocus, onKeyDown }) => {
	const classes = useStyles()
	const [search, setSearch] = useState(value)

	useEffect(() => {
		setSearch(value)
	}, [value])

	return (
		<TextField
			className={classes.searchBar}
			placeholder={placeholder}
			variant="outlined"
			autoFocus={autoFocus}
			size="small"
			value={search}
			onChange={(event) => setSearch(event.target.value)}
			onKeyDown={onKeyDown}
			InputProps={{
				classes: { input: classes.searchBar },
				startAdornment: (
					<InputAdornment>
						<IconButton edge="start">
							<SearchIcon color="action" />
						</IconButton>
					</InputAdornment>
				),
			}}
		/>
	)
}

SearchBar.propTypes = {
	placeholder: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	autoFocus: PropTypes.bool,
	onKeyDown: PropTypes.func.isRequired,
}

SearchBar.defaultProps = {
	autoFocus: false,
}

export default SearchBar
