import React from 'react'
import AccountDetailsContainer from '../containers/AccountDetailsContainer'
import useStyles from './styles'

const AccountDetails = () => {
	const classes = useStyles()
	return (
		<div className={classes.detailsContainer}>
			<AccountDetailsContainer />
		</div>
	)
}

export default AccountDetails
