import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	tableRow: {
		height: 60,
	},
	tableCell: {
		height: 'auto',
		padding: '0 16px',
		fontSize: 14,
		fontWeight: theme.typography.fontWeightRegular,
	},
	tableCellNumber: {
		textDecoration: 'underline',
		cursor: 'pointer',
	},
	imgItem: {
		display: 'flex',
		justifyContent: 'left',
		alignItems: 'center',
	},
	status: {
		fontSize: 11,
		fontWeight: theme.typography.fontWeightRegular,
		color: theme.palette.neutral.white,
		padding: '0 14px',
	},
	colorRed: {
		color: theme.palette.primary.main,
		marginRight: 4,
	},
	iconWrapper: {
		display: 'inline-block',
		padding: ' 0 5px',
		'& svg': {
			fontSize: '20px',
		},
	},
	iconYellow: {
		color: theme.palette.semantic.yellow.primary,
	},
}))

export default useStyles
