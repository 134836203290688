import React from 'react'
import PropTypes from 'prop-types'

import { FormControlLabel, Checkbox } from '@material-ui/core'
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined'

import { setQuoteCheckboxesValues } from '../../../utils/helpers'

import useStyles from '../styles'

const ViewQuoteModule = (props) => {
	const { viewQuoteModule, setViewQuoteModule, setCheckboxesValues, checkboxesValues, onChangeCheckboxValue } = props
	const classes = useStyles()

	return (
		<div>
			<FormControlLabel
				className={classes.checkBoxLabel}
				control={
					<Checkbox
						indeterminate={viewQuoteModule}
						indeterminateIcon={<IndeterminateCheckBoxOutlinedIcon />}
						color="secondary"
						className={classes.checkBox}
						checked={viewQuoteModule}
						onChange={(event) => {
							const { checked } = event.target
							setViewQuoteModule(checked)
							setCheckboxesValues((prevState) => ({
								...prevState,
								...setQuoteCheckboxesValues({ checked }),
							}))
						}}
					/>
				}
				label="View Quote Module"
			/>
			<div className={classes.checkBoxesColumn}>
				<FormControlLabel
					className={classes.checkBoxLabel}
					control={
						<Checkbox
							color="secondary"
							className={classes.checkBox}
							checked={checkboxesValues.createQuote}
							onChange={(event) => onChangeCheckboxValue(event, 'createQuote')}
						/>
					}
					label="Create new quote"
				/>
				<FormControlLabel
					className={classes.checkBoxLabel}
					control={
						<Checkbox
							color="secondary"
							className={classes.checkBox}
							checked={checkboxesValues.viewQuoteDetails}
							onChange={(event) => onChangeCheckboxValue(event, 'viewQuoteDetails')}
						/>
					}
					label="View quote details"
				/>
				<FormControlLabel
					className={classes.checkBoxLabel}
					control={
						<Checkbox
							color="secondary"
							className={classes.checkBox}
							checked={checkboxesValues.convertQuote}
							onChange={(event) => onChangeCheckboxValue(event, 'convertQuote')}
						/>
					}
					label="Convert to shipment"
				/>
				<FormControlLabel
					className={classes.checkBoxLabel}
					control={
						<Checkbox
							color="secondary"
							className={classes.checkBox}
							checked={checkboxesValues.reQuote}
							onChange={(event) => onChangeCheckboxValue(event, 'reQuote')}
						/>
					}
					label="Re-quote"
				/>
				<FormControlLabel
					className={classes.checkBoxLabel}
					control={
						<Checkbox
							color="secondary"
							className={classes.checkBox}
							checked={checkboxesValues.sendQuoteByEmail}
							onChange={(event) => onChangeCheckboxValue(event, 'sendQuoteByEmail')}
						/>
					}
					label="Send by mail"
				/>
				<FormControlLabel
					className={classes.checkBoxLabel}
					control={
						<Checkbox
							color="secondary"
							className={classes.checkBox}
							checked={checkboxesValues.downloadQuote}
							onChange={(event) => onChangeCheckboxValue(event, 'downloadQuote')}
						/>
					}
					label="Download quote"
				/>
				<FormControlLabel
					className={classes.checkBoxLabel}
					control={
						<Checkbox
							color="secondary"
							className={classes.checkBox}
							checked={checkboxesValues.deleteQuote}
							onChange={(event) => onChangeCheckboxValue(event, 'deleteQuote')}
						/>
					}
					label="Delete quote"
				/>
			</div>
		</div>
	)
}

ViewQuoteModule.propTypes = {
	viewQuoteModule: PropTypes.bool.isRequired,
	setViewQuoteModule: PropTypes.func.isRequired,
	setCheckboxesValues: PropTypes.func.isRequired,
	checkboxesValues: PropTypes.objectOf(PropTypes.any).isRequired,
	onChangeCheckboxValue: PropTypes.func.isRequired,
}

export default ViewQuoteModule
