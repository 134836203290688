import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	table: {
		minWidth: 750,
		'& tr:last-child td': {
			borderBottom: 0,
		},
	},
	tableRow: {
		'&.isHidden .MuiTableCell-root': {
			border: 'none',
		},
		'&.Mui-selected, &.Mui-selected:hover': {
			backgroundColor: theme.palette.neutral.secondaryGrey,
		},
	},
	tableCell: {
		padding: theme.spacing(0, 2),
		maxHeight: 52,
		fontSize: 14,
		fontWeight: theme.typography.fontWeightRegular,
	},
	cellAccountInfo: {
		display: 'flex',
		alignItems: 'center',
	},
	dataName: {
		minWidth: '135px',
	},
	avatar: {
		width: 38,
		height: 38,
		marginRight: 8,
		objectFit: 'contain',
		'&.MuiAvatar-root': {
			backgroundColor: 'transparent',
			color: theme.palette.neutral.black,
		},
	},
	statusActive: {
		color: theme.palette.neutral.black,
		backgroundColor: theme.palette.success.light,
		fontSize: '11px',
		padding: '0 6px',
	},
	statusInactive: {
		color: theme.palette.neutral.white,
		backgroundColor: theme.palette.semantic.red.primary,
		fontSize: '11px',
		padding: '0 6px',
	},
	statusAccount: {
		display: 'flex',
		justifyContent: 'start',
		alignItems: 'center',
		padding: '0px',
		textAlign: 'left',
	},
	stepStatusIncomplete: {
		color: theme.palette.neutral.black,
		backgroundColor: theme.palette.warning.light,
		fontSize: '11px',
		padding: '0 3px',
		marginLeft: '6px',
	},
}))

export default useStyles
