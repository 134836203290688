import React, { useState } from 'react'
import ChipInput from 'material-ui-chip-input'
import PropTypes from 'prop-types'
import { Dialog, Button } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import useStyles from './styles'
import { sendEmailForConfirmation } from '../../../../../redux/actions/Shipment.actions'
import sweetAlertModal from '../../../../../components/SweetAlertModal/SweetAlertModal'

const ShipmentModal = ({ shipmentId, initialEmail }) => {
	const [open, setOpen] = useState(true)
	const classes = useStyles()
	const dispatch = useDispatch()
	const [emails, setEmails] = useState([initialEmail])

	const sendEmail = async () => {
		const payload = { emails: [...emails] }
		const { status } = await dispatch(sendEmailForConfirmation(shipmentId, payload))
		if (status === 'error') {
			sweetAlertModal(status, 'There was an error in the book shipment process!', null, 'Ok', true, false, null)
		} else {
			setOpen(false)
		}
	}

	return (
		<Dialog
			open={open}
			disableBackdropClick
			disableEscapeKeyDown
			onClick={(event) => event.stopPropagation()}
			aria-labelledby="form-dialog-title"
			PaperProps={{
				style: {
					height: '400px',
					width: '500px',
					borderBottom: '6px solid #ff3333',
				},
			}}
		>
			<div className={classes.confirmation}>
				<h1 className={classes.title}>Email notification</h1>
			</div>
			<span className={classes.text}>
				Notify to more emails that need to be notified about the booking of the shipment.
			</span>
			<ChipInput
				style={{ width: '80%', borderRadius: '50%', margin: '1em auto' }}
				defaultValue={emails}
				onChange={(newEmails) => setEmails(newEmails)}
			/>
			<Button className={classes.goButton} onClick={sendEmail} color="primary" variant="contained">
				Send Email
			</Button>
		</Dialog>
	)
}

ShipmentModal.propTypes = {
	shipmentId: PropTypes.string.isRequired,
	initialEmail: PropTypes.string.isRequired,
}

export default ShipmentModal
