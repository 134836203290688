import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	newItemHeaderContainer: {
		padding: '16px 23px',
		backgroundColor: theme.palette.neutral.whiteGray,
		[theme.breakpoints.down('md')]: {
			padding: '16px',
		},
	},
	newItemContainer: {
		marginTop: '16px',
		padding: '16px 0px',
	},
	newItemInformationContainer: {
		padding: '16px 24px',
	},
	newItemGrayContainer: {
		padding: '16px 24px 24px 24px',
		marginBottom: '24px',
		backgroundColor: theme.palette.neutral.whiteGray,
	},
	newItemDetailContainer: {
		padding: '16px 24px 24px 24px',
	},
	newItemHeaderContainerLabel: {
		margin: 0,
		fontWeight: theme.typography.fontWeightBold,
	},
	newItemHeaderContainerBody: {
		display: 'block',
		marginTop: '4px',
		fontWeight: theme.typography.fontWeightRegular,
	},
	newItemInformationSubtitle: {
		display: 'flex',
		columnGap: '12px',
		marginBottom: '16px',
		alignItems: 'center',
	},
	newItemDetailSubtitle: {
		display: 'flex',
		columnGap: '12px',
		alignItems: 'center',
	},
	newItemInformationSubtitleLabel: {
		fontSize: '18px',
		fontWeight: theme.typography.fontWeightRegular,
		color: theme.palette.primary.main,
		margin: 0,
		lineHeight: '22px',
		[theme.breakpoints.down('md')]: {
			fontSize: '15px',
		},
	},
	newItemHazMatIconContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '28px',
		height: '28px',
		borderRadius: '20px',
		color: theme.palette.primary.main,
	},
	newItemHazMatIcon: {
		width: '20px',
		height: '20px',
	},
	newItemDetailsIconContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '28px',
		height: '28px',
		borderRadius: '20px',
		color: theme.palette.primary.main,
	},
	newItemDetailsIcon: {
		width: '20px',
		height: '20px',
	},
	saveButton: {
		marginRight: '39px',
		height: '48px',
		alignSelf: 'center',
		textTransform: 'unset',
		lineHeight: '21px',
		fontWeight: theme.typography.fontWeightMedium,
		[theme.breakpoints.down('sm')]: {
			margin: '0px 24px 16px 24px',
			width: '100%',
			order: 1,
		},
		[theme.breakpoints.up('md')]: {
			width: '222px',
		},
	},
	cancelButton: {
		marginRight: '24px',
		height: '48px',
		alignSelf: 'center',
		backgroundColor: theme.palette.neutral.whiteGray,
		color: theme.palette.neutral.black,
		textTransform: 'unset',
		fontWeight: theme.typography.fontWeightMedium,
		[theme.breakpoints.down('md')]: {
			width: '137px',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '0px 24px 16px 24px',
			width: '100%',
			order: 2,
		},
		[theme.breakpoints.up('lg')]: {
			width: '170px',
		},
	},
	newItemButtonsContainer: {
		fontSize: '17px',
		display: 'flex',
		justifyContent: 'flex-end',
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center',
			flexWrap: 'wrap',
		},
		[theme.breakpoints.up('md')]: {
			marginBottom: '24px',
		},
	},

	shipmentItemFormControl: {
		fontSize: '16px',
		height: '50px',
	},
	newItemInformationSubContainer: {
		padding: '0 15px',
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			padding: '0',
		},
	},
	root: {
		fontSize: '14px',
		'& .MuiFormLabel-root': {
			fontSize: '14px',
		},
		'& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
			display: 'none',
			margin: 80,
		},
		'& .MuiInputBase-root': {
			height: '50px',
		},
	},
	helperText: {
		fontSize: '8px',
		fontWeight: '400',
		marginBottom: '8px',
		[theme.breakpoints.up('md')]: {
			fontSize: '8px',
		},
		[theme.breakpoints.up('lg')]: {
			fontSize: '9px',
		},
		[theme.breakpoints.up('xl')]: {
			fontSize: '12px',
		},
	},
	popper: {
		zIndex: 2,
	},
	newItemsActionsContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-around',
		height: '50px',
	},
	newItemHazMaterialContainer: {
		padding: '0 15px',
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			padding: '0',
		},
	},
	field: {
		[theme.breakpoints.up('xs')]: {
			fontSize: '12px',
		},
		[theme.breakpoints.up('sm')]: {
			fontSize: '13px',
		},
		[theme.breakpoints.up('md')]: {
			fontSize: '15px',
		},
	},
	actionButton: {
		minWidth: '25px',
	},
}))

export default useStyles
