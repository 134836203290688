import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	paper: {
		borderBottomWidth: 6,
		borderBottomStyle: 'solid',
		borderBottomColor: theme.palette.secondary.main,
	},
	dialogTitle: {
		padding: theme.spacing(3, 4, 2),
		textAlign: 'center',
		fontSize: '2rem',
		lineHeight: 1.206,
		color: theme.palette.secondary.main,
		fontWeight: theme.typography.fontWeightLight,
	},
	dialogContent: {
		padding: theme.spacing(0, 4, 3),
	},
	dialogActions: {
		display: 'block',
		padding: theme.spacing(0, 4, '15px'),
	},
	dialogButton: {
		display: 'block',
		padding: '13px 16px',
		width: '100%',
		marginBottom: theme.spacing(2),
		fontSize: 17,
		textTransform: 'none',
		fontWeight: theme.typography.fontWeightMedium,
		'&:last-of-type': {
			marginBottom: 0,
		},
	},
	dialogLink: {
		fontWeight: theme.typography.fontWeightBold,
		textDecoration: 'underline',
		color: theme.palette.secondary.main,
		cursor: 'pointer',
	},
}))

export default useStyles
