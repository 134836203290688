import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Button, Box } from '@material-ui/core'
import FileDropZone from '../FileDropZone'
import SessionContext from '../../../../../context/session'
import useStyles from './styles'
import { createAccountsBulk, fetchAccounts } from '../../../../../redux/actions/Accounts.actions'
import sweetAlertModal from '../../../../../components/SweetAlertModal/SweetAlertModal'
import { fetchChildsCompanies } from '../../../../../redux/actions/Company.actions'

const UploadAccountsModal = ({ open, handleClose }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const { getUserData, getTokenUser } = useContext(SessionContext)
	const { actualCompany } = getUserData()
	const { accountsFilters } = useSelector((state) => state)
	const token = getTokenUser()
	const [hasValid, setHasValid] = useState(false)
	const [file, setFile] = useState({})

	const setHasValidFile = (value) => setHasValid(value)

	const handleDownloadTemplate = () => {
		const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/company/1/bulk-template`

		const oReq = new XMLHttpRequest()
		oReq.open('GET', url, true)
		oReq.responseType = 'arraybuffer'
		oReq.setRequestHeader('X-Company-id', Number(actualCompany))
		oReq.setRequestHeader('Authorization', `Bearer ${token}`)

		oReq.onload = function dowload() {
			const blob = new Blob([oReq.response], { type: 'application/xlsx' })
			const URL = window.URL || window.webkitURL
			const dataUrl = URL.createObjectURL(blob)
			const downloadLink = document.createElement('a')
			const fileName = 'bulk-account-update.xlsx'

			downloadLink.href = dataUrl
			downloadLink.download = fileName
			downloadLink.click()
		}

		oReq.send()
	}

	const handleSubmit = async () => {
		const config = {
			headers: { 'X-Company-id': Number(actualCompany) },
		}
		const formData = new FormData()
		formData.append('file', file)
		const { status, resp } = await dispatch(createAccountsBulk(formData, config))
		if (status === 'error') {
			sweetAlertModal('error', resp.error, resp.message, 'Ok', true, null, false)
			handleClose()
		} else if ((resp?.errorCompanies ?? []).length) {
			const message = `
        Companies created: ${resp.createdCompanies}
        ${resp.errorCompanies.map(({ error, row }) => [`${error} in row: ${row}\n`])}`
			sweetAlertModal('error', 'Error creating the accounts', message, 'Ok', true, null, false)
		} else {
			sweetAlertModal('success', 'Accounts creation complete', '', 'Ok', true, null, false)
			handleClose()
			const params = {
				offset: accountsFilters.currentPage,
				limit: accountsFilters.rowsPerPage,
				search_value: accountsFilters.searchBar,
				is_active: accountsFilters.status,
				order_by: accountsFilters.orderBy,
				order_type: accountsFilters.orderType,
			}
			Object.keys(params).forEach((key) => params[key] === '' && delete params[key])
			const qs = Object.keys(params)
				.map((key) => `${key}=${params[key]}`)
				.join('&')
			dispatch(fetchAccounts(`?${qs}`, config))
			await dispatch(fetchChildsCompanies(actualCompany))
		}
	}

	return (
		<Dialog
			classes={{ paper: classes.paper }}
			arial-labelledby="update-accounts-modal-title"
			open={open}
			maxWidth="sm"
			disableBackdropClick
			disableEscapeKeyDown
			onExited={() => setHasValid(false)}
		>
			<DialogTitle id="update-accounts-modal-title" className={classes.dialogTitle} disableTypography>
				Upload Multiple Accounts
			</DialogTitle>
			<DialogContent className={classes.dialogContent}>
				<Typography align="center" variant="body1" paragraph>
					Upload an <strong>.xlsx</strong> or <strong>.csv</strong> file with the list of your accounts. If you need a
					template to enter your accounts, download{' '}
					<Box
						role="button"
						tabIndex="0"
						component="span"
						className={classes.dialogLink}
						onClick={() => handleDownloadTemplate()}
					>
						here
					</Box>
					.
				</Typography>
				<FileDropZone setHasValidFile={setHasValidFile} setFile={setFile} />
			</DialogContent>
			<DialogActions className={classes.dialogActions} disableSpacing>
				<Button
					className={classes.dialogButton}
					color="secondary"
					variant="contained"
					disableElevation
					onClick={handleSubmit}
					disabled={!hasValid}
				>
					Upload Your Accounts
				</Button>
				<Button className={classes.dialogButton} variant="contained" disableElevation onClick={handleClose}>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	)
}

UploadAccountsModal.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
}

export default UploadAccountsModal
