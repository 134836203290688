import React, { useState, useEffect, useCallback, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import Button from '@material-ui/core/Button'
import { Grid, IconButton, FormControl, Select, InputLabel, MenuItem } from '@material-ui/core'
import { FilterList, Loop } from '@material-ui/icons'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { carrierOptions } from '../../../../utils/dropDownOptions'
import SessionContext from '../../../../context/session'
import { carriersListRoute, permissionName } from '../../../../utils/constants'
import {
	setCurrentPage,
	setRowsPerPage,
	setCarrierGroup,
	setSearchBar,
	resetFilters,
} from '../../../../redux/actions/CarriersFilters.actions'
import { setTabCurrentRoute, setTabTitle } from '../../../../redux/actions/MultiTabs.actions'
import useCarriersFilters from '../../../../hooks/useCarriersFilters'
import {
	fetchCarriersList,
	updateCarrierStatus,
	updateCarrierStatusBulk,
} from '../../../../redux/actions/Carriers.actions'
import sweetAlertModal from '../../../../components/SweetAlertModal/SweetAlertModal'
import SearchBar from '../../../../components/SearchBar'
import CarriersTable from '../../../admin/accountDetails/components/CarriersTableList'
import isPermit from '../../../../utils/permission'
// eslint-disable-next-line import/no-unresolved
import ModuleHeader from '../../../../components/ModuleHeader/index'

import useStyles from './styles'

const CarriersList = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const [buildParamsFromState] = useCarriersFilters()
	const [open, setOpen] = useState(false)
	const { searchBar } = useSelector((state) => state.carriersFilters)
	const [search, setSearch] = useState(decodeURIComponent(searchBar))
	const [carrierGroup, setCarrierGroupState] = useState('All')
	const { getUserData } = useContext(SessionContext)
	const { actualCompany } = getUserData()

	const { isLoading } = useSelector((state) => state.carriers)
	const [carriersList, setCarriersList] = useState([])

	const [pageReset, setPageReset] = useState('')
	const matches = useMediaQuery('(min-width:1024px)')

	const [selected, setSelected] = useState([])
	const { permissions } = getUserData()

	const CarriersListProvider = () => {
		const callback = useCallback(async (params) => {
			const config = {
				headers: { 'X-Company-id': Number(actualCompany) },
			}
			const { status, resp } = await dispatch(fetchCarriersList(config, params))
			if (status === 'error') {
				sweetAlertModal('error', 'There was an error fetching the carriers', null, 'Ok', true, false)
			} else {
				setCarriersList(resp)
			}
		}, [])

		return { callback }
	}

	const { callback } = CarriersListProvider()
	const filterParams = buildParamsFromState()

	useEffect(() => {
		dispatch(setTabCurrentRoute(carriersListRoute))
		dispatch(setTabTitle('Carriers List'))
	}, [dispatch])

	useEffect(() => {
		callback(filterParams)
	}, [callback, filterParams])

	useEffect(() => {
		const preservedFilters = localStorage.getItem('preserved-carriers-filters')
		if (preservedFilters) {
			const filterObj = preservedFilters.split('&')
			filterObj.forEach((filter) => {
				const filterKeyValue = filter.split('=')
				switch (filterKeyValue[0]) {
					case 'search_bar':
						dispatch(setSearchBar(filterKeyValue[1]))
						setSearch(decodeURIComponent(filterKeyValue[1]))
						break
					case 'carrier_group':
						dispatch(setCarrierGroup(filterKeyValue[1]))
						setCarrierGroupState(filterKeyValue[1])
						break
					case 'page':
						dispatch(setCurrentPage(filterKeyValue[0]))
						dispatch(setRowsPerPage(filterKeyValue[0]))
						break
					default:
						break
				}
			})
		}
	}, [dispatch])

	const toggleDrawer = (openDrawer) => (event) => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return
		}
		setOpen(openDrawer)
	}

	const showFilters = () => {
		setOpen(true)
	}

	const handleSearchBarChanged = (event) => {
		setSearch(event.target.value)
		if (event.target.value.length >= 3) {
			dispatch(resetFilters())
			dispatch(setSearchBar(encodeURIComponent(event.target.value)))
			setPageReset(event.target.value)
		} else if (event.target.value.length === 0) {
			dispatch(resetFilters())
			dispatch(setSearchBar(''))
			setPageReset(event.target.value)
		}
	}

	const onKeyDown = (event) => {
		if (event.keyCode === 13) {
			if (event.target.value) {
				dispatch(setSearchBar(encodeURIComponent(event.target.value)))
				setSearch(event.target.value)
				setPageReset('resetPageSearch')
				dispatch(setCurrentPage(0))
			}
		} else if (!event.target.value) {
			dispatch(setSearchBar(''))
		}
	}

	const handleResetFilters = () => {
		dispatch(resetFilters())
		setSearch('')
		setPageReset('pageResetFilters')
		setCarrierGroupState('All')
		localStorage.removeItem('preserved-item-filters')
	}

	const handleUpdateCarrierStatus = async (Id, isActive) => {
		const { status, resp } = await dispatch(updateCarrierStatus({ carrierAccesId: Id, isActive }))
		let ret = false
		if (status === 'error') {
			sweetAlertModal('error', resp?.response?.data?.message ?? 'An error has occurred', null, 'Ok', true, false)
		} else {
			sweetAlertModal(status, resp?.data?.message ?? 'Success', null, 'Ok', true, false, null)
			const carrierIndex = carriersList.findIndex((carrier) => carrier.id === Id)
			const carrierItem = carriersList.find((carrier) => carrier.id === Id)
			ret = true
			if (carrierIndex !== -1 && carrierItem !== undefined) {
				const inputList = [...carriersList]
				inputList.splice(carrierIndex, 1, {
					...carrierItem,
					status: isActive === 'true',
				})
				setCarriersList(inputList)
			}
		}
		return ret
	}

	const handleBulkUpdateStatus = async (newStatus) => {
		const { status } = await dispatch(
			updateCarrierStatusBulk({
				isActive: newStatus,
				accessCarrierArray: selected.map((id) => Number(id)),
			}),
		)
		if (status === 'error') {
			sweetAlertModal('error', 'There was an error trying to update carriers', null, 'Ok', true, false)
		} else {
			sweetAlertModal(status, 'Carriers were updated', null, 'Ok', true, false, null)
			callback(filterParams)
		}
	}

	const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

	return (
		<>
			<div className={classes.invoiceContainer}>
				<div className={classes.headerSection}>
					<ModuleHeader
						title={
							<>
								<h1 className={classes.titleText}>Carriers</h1>
								<div>
									<IconButton aria-label="Toggle Filters" onClick={showFilters} className={classes.filterTriggerButton}>
										<FilterList />
									</IconButton>
								</div>
							</>
						}
						subtitle="Consult the carriers created in the system."
					/>
					<SwipeableDrawer
						className={classes.drawer}
						anchor="top"
						open={open}
						onOpen={toggleDrawer(open, true)}
						onClose={toggleDrawer(false)}
						disableBackdropTransition={!iOS}
						disableDiscovery={iOS}
					>
						<div className={classes.filterFormWrapper} role="presentation">
							<Grid container spacing={2} alignItems="center" style={{ padding: '15px 8px 0 10px' }}>
								<Grid item xs={12} md={3}>
									<FormControl variant="outlined" style={{ width: '100%', marginBottom: '0' }}>
										<InputLabel className={classes.selectChildInput} htmlFor="company-dialog-select">
											Filter by status
										</InputLabel>
										<Select
											value={carrierGroup}
											label="Carrier Group"
											onChange={(e) => {
												setCarrierGroupState(e.target.value)
												dispatch(setCarrierGroup(e.target.value))
											}}
										>
											{carrierOptions.map((item, index) => (
												<MenuItem value={item.value} key={`id-mobile-${index + 1}`}>
													{item.label}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} md={12}>
									<SearchBar value={search} autoFocus placeholder="Search..." onKeyDown={onKeyDown} />
								</Grid>
								<Grid item xs={12} style={{ padding: '20px 0 0 0' }}>
									<Button
										onClick={handleResetFilters}
										color="secondary"
										startIcon={<Loop color="secondary" />}
										style={{ textTransform: 'none' }}
										className={classes.filterButtonModal}
									>
										<span className={classes.filterButtonText}>Reset Filters</span>
									</Button>
								</Grid>
							</Grid>
						</div>
					</SwipeableDrawer>
					<Grid container spacing={2} alignItems="center">
						<Grid item xs={12} md={12} lg={7}>
							<SearchBar value={search} placeholder="Search..." onChangeHandler={handleSearchBarChanged} size="small" />
						</Grid>
						{matches ? (
							<Grid item xs={12} md={3}>
								<FormControl size="small" className={classes.formGroupSelect} variant="outlined">
									<InputLabel className={classes.selectChildInput} htmlFor="company-dialog-select">
										Filter by carrier group
									</InputLabel>
									<Select
										value={carrierGroup}
										label="Carrier Group"
										onChange={(e) => {
											setCarrierGroupState(e.target.value)
											dispatch(setCarrierGroup(e.target.value))
										}}
									>
										{carrierOptions.map((item, index) => (
											<MenuItem value={item.value} key={`id-${index + 1}`}>
												{item.label}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						) : null}
						{matches && (
							<Grid item xs={2} md={2}>
								<Button
									onClick={handleResetFilters}
									color="primary"
									startIcon={<Loop color="secondary" />}
									className={classes.filterButton}
								>
									<span className={classes.filterButtonText}>Reset filters</span>
								</Button>
							</Grid>
						)}
					</Grid>
				</div>
				<Grid container alignItems="center" className={classes.carriersTableContainer}>
					<CarriersTable
						carriersList={carriersList}
						isLoading={isLoading}
						isSelectableList={
							isPermit(permissions, permissionName.FP_ADMIN_CARRIERS_UPDATE) &&
							isPermit(permissions, permissionName.FP_ADMIN_CARRIERS_DEACTIVATE)
						}
						isMainCarrierList
						tableType="carriers"
						pageReset={pageReset}
						handleUpdateCarrierStatus={handleUpdateCarrierStatus}
						handleBulkUpdateStatus={handleBulkUpdateStatus}
						selected={selected}
						setSelected={setSelected}
					/>
				</Grid>
			</div>
		</>
	)
}

export default CarriersList
