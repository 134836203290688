import React from 'react'
import PropTypes from 'prop-types'
import { DownloadIcon } from '../../assets/Icons/Icons'
import './TableToolbar.scss'

const TableToolbar = (props) => {
	const { numSelected } = props

	return (
		<div className="table-toolbar-div">
			{numSelected > 0 ? (
				<div className="table-toolbar-container">
					<p className="table-toolbar-selected">{`(${numSelected} selected)`}</p>
					<div className="table-toolbar-item-icon">
						<DownloadIcon color="primary" fontSize="small" style={{ marginRight: 7 }} />
						<p className="table-toolbar-item-title">Download quotes</p>
					</div>
				</div>
			) : (
				<div className="table-toolbar-container">
					<p className="table-toolbar-selected">(Select quotes for bulk update)</p>
				</div>
			)}
		</div>
	)
}

TableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
}

export default TableToolbar
