import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Grid,
	IconButton,
	Tooltip,
} from '@material-ui/core'

import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'

import { useForm } from 'react-hook-form'

import ChildAccountsSlider from '../ChildAccountsSlider'
import ActionsMenu from '../ActionsMenu'

import useStyles from './styles'

const ChildAccountsAdded = (props) => {
	const classes = useStyles()
	const { childAccounts, setChildAccounts, accountForm, setAccountForm, deleteAccountForm } = props

	const { setValue } = useForm({
		defaultValues: {
			accountNumber: 'ML72552251',
		},
	})

	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleDeleteClick = (index, data) => {
		const inputList = [...childAccounts]
		inputList.splice(index, 1)
		setChildAccounts(inputList)
		deleteAccountForm(data.id)
	}

	const handleEditClick = (index) => {
		setValue('salesForceNumber', childAccounts[index].salesForceNumber)
		setValue('name', childAccounts[index].name)
		setValue('accountNumber', childAccounts[index].accountNumber)
		setValue('contactPhoneExt', childAccounts[index].phone_extension)
		setValue('contact', childAccounts[index].contact)
		setValue('email', childAccounts[index].email)

		const data = {
			id: childAccounts[index].id,
			salesForceNumber: childAccounts[index].salesForceNumber,
			name: childAccounts[index].name,
			accountNumber: childAccounts[index].accountNumber,
			contact: childAccounts[index].contact,
			phone: childAccounts[index].phone,
			contactPhoneExt: childAccounts[index].phone_extension,
			email: childAccounts[index].email,
		}

		const accountExists =
			accountForm?.find(
				(element) =>
					element.data?.id === data.id &&
					element.data?.name === data.name &&
					element.data?.salesForceNumber === data.salesForceNumber &&
					element.data?.accountNumber === data.accountNumber &&
					element.data?.contact === data.contact &&
					element.data?.phone === data.phone &&
					element.data?.email === data.email,
			) ?? {}

		if (Object.entries(accountExists).length === 0) {
			setAccountForm((prevState) => [...prevState, { id: data.id, data }])
		}
	}

	return (
		<div className={classes.formContainer}>
			{childAccounts.length ? (
				<>
					<Grid container className={classes.desktopTable}>
						<Grid>
							<p className={classes.childAccountTitle}>Added</p>
						</Grid>
						<TableContainer className={classes.tableContainer}>
							<Table className={classes.table} aria-label="customized table" padding="none">
								<TableHead>
									<TableRow className={classes.tableRow}>
										<TableCell align="center" className={classes.tableCellHead}>
											#
										</TableCell>
										<TableCell align="center" className={classes.tableCellHead}>
											Sales Force Number
										</TableCell>
										<TableCell align="center" className={classes.tableCellHead}>
											Child Name
										</TableCell>
										<TableCell align="center" className={classes.tableCellHead}>
											Customer Account Number
										</TableCell>
										<TableCell align="center" className={classes.tableCellHead}>
											Contact Name
										</TableCell>
										<TableCell align="center" className={classes.tableCellHead}>
											Contact Phone
										</TableCell>
										<TableCell align="center" className={classes.tableCellHead}>
											Contact Email
										</TableCell>
										<TableCell align="center" className={classes.tableCellHead}>
											Actions
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{childAccounts.map((item, index) => (
										<TableRow key={`id-${index + 1}`} className={classes.tableRow}>
											<TableCell align="center" className={classes.tableCell}>
												{index + 1}
											</TableCell>
											<TableCell align="center" className={classes.tableCell}>
												{item.salesForceNumber ? item.salesForceNumber : ''}
											</TableCell>
											<TableCell align="center" className={classes.tableCell}>
												{item.name ? item.name : ''}
											</TableCell>
											<TableCell align="center" className={classes.tableCell}>
												{item.accountNumber ? item.accountNumber : ''}
											</TableCell>
											<TableCell align="center" className={classes.tableCell}>
												{item.contact ? item.contact : ''}
											</TableCell>
											<TableCell align="center" className={classes.tableCell}>
												{item.phone ? item.phone : ''}
												{item.phone_extension ? `, Ext ${item.phone_extension}` : ''}
											</TableCell>
											<TableCell align="center" className={classes.tableCell}>
												{item.email ? item.email : ''}
											</TableCell>
											<TableCell align="center" className={classes.tableCell}>
												<Tooltip title="Edit">
													<IconButton aria-label="edit" color="secondary" onClick={() => handleEditClick(index)}>
														<EditOutlinedIcon fontSize="small" />
													</IconButton>
												</Tooltip>
												<Tooltip title="Delete">
													<IconButton aria-label="delete" onClick={() => handleDeleteClick(index, item)}>
														<DeleteOutlineOutlinedIcon color="error" fontSize="small" />
													</IconButton>
												</Tooltip>
											</TableCell>
											<ActionsMenu
												type="child account"
												anchorEl={anchorEl}
												open={open}
												handleClose={handleClose}
												handleDeleteClick={handleDeleteClick}
												index={index}
											/>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
					<ChildAccountsSlider
						childAccounts={childAccounts}
						handleEditClick={handleEditClick}
						handleDeleteClick={handleDeleteClick}
					/>
				</>
			) : (
				<div className={classes.childAccountsMessageContainer}>
					<h3 className={classes.childAccountsMessageTitle}>No child accounts added yet</h3>
					<h4 className={classes.childAccountsMessage}>Add new child account here.</h4>
				</div>
			)}
		</div>
	)
}

ChildAccountsAdded.propTypes = {
	childAccounts: PropTypes.arrayOf(PropTypes.any).isRequired,
	setChildAccounts: PropTypes.func.isRequired,
	accountForm: PropTypes.arrayOf(PropTypes.any).isRequired,
	setAccountForm: PropTypes.func.isRequired,
	deleteAccountForm: PropTypes.func.isRequired,
}

export default ChildAccountsAdded
