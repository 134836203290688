import React, { useState, forwardRef, useRef, useImperativeHandle, useCallback } from 'react'
import PropTypes from 'prop-types'
import {
	Select,
	MenuItem,
	RadioGroup,
	FormControlLabel,
	FormHelperText,
	FormControl,
	InputLabel,
	Button,
	Radio,
	FormLabel,
} from '@material-ui/core'

import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'

import { useForm, Controller } from 'react-hook-form'

import { referencesTypesOptions } from '../../../../../utils/dropDownOptions'
import SweetAlertModal from '../../../../../components/SweetAlertModal'
import useStyles from './styles'

const AccountReferenceForm = forwardRef((props, ref) => {
	const { referenceItem, previousReferences, setPreviousReferences, deleteReferenceForm, newReferences } = props
	const {
		handleSubmit: handleSubmitReferences,
		errors: errorsReferences,
		control: controlReferences,
		setValue: setValueReferences,
		watch,
		getValues,
	} = useForm({
		mode: 'onBlur',
	})
	const classes = useStyles()

	const formRef = useRef(null)

	const [referenceTypeDropDown, setReferenceTypeDropDown] = useState('')
	const [referenceRequired, setReferenceRequired] = useState('no')

	const handleAddReference = (data) => {
		if (data.referenceType) {
			const referenceExists =
				previousReferences?.find(
					(element) =>
						element.name === data.referenceType && element.value === (referenceRequired === 'yes' ? 'true' : 'false'),
				) ?? {}

			if (Object.entries(referenceExists).length === 0) {
				const newRef = {}
				newRef.name = data.referenceType
				newRef.value = referenceRequired === 'yes' ? 'true' : 'false'
				setPreviousReferences([...previousReferences, newRef])
			}
		} else {
			SweetAlertModal('error', 'Please add one reference type', null, 'Ok', true, false, null)
		}
	}

	useImperativeHandle(ref, () => ({
		submitForm() {
			formRef.current.dispatchEvent(new Event('submit', { cancelable: true }))

			if (!watch('referenceType')) {
				return 'error'
			}
			return 'success'
		},
		getFormValues() {
			return getValues()
		},
	}))

	const checkOptionReferences = useCallback(
		(item) => {
			const isFoundPrevious = previousReferences.some((element) => element.name === item)
			const isFoundActual = newReferences.current.some((v) => (v ? v.getFormValues().referenceType === item : false))
			return isFoundPrevious || isFoundActual
		},
		[newReferences, previousReferences],
	)

	return (
		<form
			ref={formRef}
			onSubmit={handleSubmitReferences(handleAddReference)}
			className={classes.newAccountSettingReferencesFormRow}
		>
			<FormControl
				key="account-references"
				variant="outlined"
				className={[classes.root, classes.newAccountsReferenceTypeField].join(' ')}
				error={!!errorsReferences.referenceType}
			>
				<InputLabel>Reference type</InputLabel>
				<Controller
					render={({ onChange, value, name }) => (
						<Select
							name={name}
							value={value}
							onChange={(e) => {
								onChange(e.target.value)
								setValueReferences('referenceType', e.target.value, { shouldValidate: true, shouldDirty: true })
								setReferenceTypeDropDown(e.target.value)
							}}
							label="Reference type"
						>
							{referencesTypesOptions.map((item, index) => (
								<MenuItem value={item.label} disabled={checkOptionReferences(item.value)} key={`id-${index + 1}`}>
									{item.label}
								</MenuItem>
							))}
						</Select>
					)}
					name="referenceType"
					control={controlReferences}
					defaultValue={referenceTypeDropDown}
					rules={{ required: true }}
				/>
				<FormHelperText>
					{errorsReferences.referenceType ? errorsReferences.referenceType.message : null}
				</FormHelperText>
			</FormControl>
			<FormControl className={classes.newAccountRadioContainer}>
				<FormLabel className={classes.newAccountRadioLabel}>Reference Required</FormLabel>
				<RadioGroup
					style={{ flexDirection: 'row' }}
					aria-label="referenceRequired"
					name="referenceRequired"
					value={referenceRequired}
					onChange={(event) => setReferenceRequired(event.target.value)}
				>
					<FormControlLabel
						classes={{ label: classes.radioButtonOptionLabel }}
						value="yes"
						control={<Radio color="secondary" />}
						label="Yes"
					/>
					<FormControlLabel
						classes={{ label: classes.radioButtonOptionLabel }}
						value="no"
						control={<Radio color="secondary" />}
						label="No"
					/>
				</RadioGroup>
			</FormControl>
			<Button
				color="primary"
				className={classes.addReferenceButton}
				onClick={() => deleteReferenceForm(referenceItem.id)}
			>
				<DeleteOutlineOutlinedIcon className={classes.deleteIconButton} />
				Delete
			</Button>
		</form>
	)
})

AccountReferenceForm.propTypes = {
	referenceItem: PropTypes.objectOf(PropTypes.any).isRequired,
	newReferences: PropTypes.objectOf(PropTypes.any).isRequired,
	previousReferences: PropTypes.arrayOf(PropTypes.any).isRequired,
	setPreviousReferences: PropTypes.func.isRequired,
	deleteReferenceForm: PropTypes.func.isRequired,
}

export default AccountReferenceForm
