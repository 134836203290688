/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const tabShipmentDetailSlice = createSlice({
	name: 'tabShipmentDetailSlice',
	initialState: {
		tab: [],
	},
	reducers: {
		setTabShipmentDetail: (state, action) => {
			state.tab = action.payload
		},
	},
})

export const { setTabShipmentDetail } = tabShipmentDetailSlice.actions
export const { reducer } = tabShipmentDetailSlice
export default reducer
