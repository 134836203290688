import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react'
import PropTypes from 'prop-types'
import { Grid, FormControl, InputLabel, Select, MenuItem, TextField, Button } from '@material-ui/core'

import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'

import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { useForm, Controller } from 'react-hook-form'

import moment from 'moment'

import RangeDatePicker from '../../RangeDatePicker'
import { referencesTypesOptions } from '../../../utils/dropDownOptions'

import useStyles from './styles'

const ReferenceInputs = forwardRef((props, ref) => {
	const classes = useStyles()
	const theme = useTheme()
	const matches = useMediaQuery(theme.breakpoints.down('md'))

	const {
		referenceItem,
		setReferencesAdded,
		previousReferences,
		dispatchSetReferences,
		setPreviousReferences,
		deleteReferenceForm,
	} = props
	const { handleSubmit, control, register, errors, getValues, watch } = useForm()

	const [referenceType, setReferenceType] = useState('')
	const [referenceTypeError, setReferenceTypeError] = useState(false)
	const [date, setDate] = useState(null)
	const [dateError, setDateError] = useState(false)
	const [references, setReferences] = useState([])

	const formRef = useRef(null)

	const handleAddReferenceClick = () => {
		if (referenceType) {
			if (referenceType === 'MUST_ARRIVE_BY_DATE' && !date) {
				setDateError(true)
				return
			}
			const formattedDate = date ? moment(date).format('MM/DD/YYYY') : null
			const referenceExists =
				previousReferences?.find(
					(element) =>
						element.referenceType === referenceType &&
						(element.value === getValues('referenceNumber') || element.value === formattedDate),
				) ?? {}

			if (Object.entries(referenceExists).length === 0) {
				setReferences([
					...references,
					{
						referenceType,
						value: referenceType === 'MUST_ARRIVE_BY_DATE' ? formattedDate : getValues('referenceNumber'),
					},
				])
				setPreviousReferences([
					...previousReferences,
					{
						referenceType,
						value: referenceType === 'MUST_ARRIVE_BY_DATE' ? formattedDate : getValues('referenceNumber'),
					},
				])
				setReferencesAdded([
					...previousReferences,
					{
						referenceType,
						value: referenceType === 'MUST_ARRIVE_BY_DATE' ? formattedDate : getValues('referenceNumber'),
					},
				])
				dispatchSetReferences({
					referenceType,
					value: referenceType === 'MUST_ARRIVE_BY_DATE' ? formattedDate : getValues('referenceNumber'),
				})
			}
			setReferenceTypeError(false)
		} else {
			setReferenceTypeError(true)
		}
	}

	useImperativeHandle(ref, () => ({
		submitForm() {
			formRef.current.dispatchEvent(new Event('submit', { cancelable: true }))

			if (
				!watch('referenceType') ||
				(watch('referenceType') !== 'MUST_ARRIVE_BY_DATE' && !watch('referenceNumber')) ||
				(watch('referenceType') === 'MUST_ARRIVE_BY_DATE' && !date)
			) {
				return 'error'
			}
			return 'success'
		},
	}))

	return (
		<form onSubmit={handleSubmit(handleAddReferenceClick)} ref={formRef} className={classes.formContainer}>
			<Grid container direction={matches ? 'column' : 'row'} wrap="nowrap" spacing={1}>
				<Grid item xs={12} md={4}>
					<FormControl
						variant="outlined"
						size="small"
						fullWidth
						style={matches ? { marginBottom: '20px' } : {}}
						error={referenceTypeError || !!errors.referenceType}
					>
						<InputLabel id="demo-simple-select-outlined-label">Reference type</InputLabel>
						<Controller
							render={({ onChange, value, name }) => (
								<Select
									name={name}
									value={value}
									onChange={(e) => {
										onChange(e.target.value)
										setReferenceType(e.target.value)
									}}
									label="Reference Type"
									fullWidth
								>
									{referencesTypesOptions.map((item, index) => (
										<MenuItem value={item.value} key={`id-${index + 1}`}>
											{item.label}
										</MenuItem>
									))}
								</Select>
							)}
							name="referenceType"
							control={control}
							defaultValue={referenceType}
							rules={{ required: true }}
						/>
					</FormControl>
				</Grid>
				<Grid item xs={12} md={6}>
					{referenceType === 'MUST_ARRIVE_BY_DATE' ? (
						<RangeDatePicker
							name="referenceNumber"
							label="Date"
							style={{ marginRight: '13px' }}
							valueDate={date}
							setValueDate={setDate}
							minDate={new Date()}
							isDisableWeekends
							isError={dateError}
							setDateError={setDateError}
						/>
					) : (
						<TextField
							className={classes.root}
							size="small"
							name="referenceNumber"
							type="text"
							inputRef={register({
								required: true,
								maxLength: { value: 40, message: 'Maximum 40 characters allowed' },
							})}
							error={!!errors.referenceNumber}
							helperText={errors.referenceNumber ? errors.referenceNumber.message : null}
							label="Reference Number"
							variant="outlined"
							fullWidth
							style={matches ? { marginBottom: '5px' } : { marginRight: '1em' }}
						/>
					)}
				</Grid>
				<Grid item md={2}>
					<Button color="primary" className={classes.button} onClick={() => deleteReferenceForm(referenceItem.id)}>
						<DeleteOutlineOutlinedIcon color="error" className={classes.newShipmentIcon} />
						Delete
					</Button>
				</Grid>
			</Grid>
		</form>
	)
})

ReferenceInputs.propTypes = {
	referenceItem: PropTypes.objectOf(PropTypes.any).isRequired,
	setReferencesAdded: PropTypes.func.isRequired,
	setPreviousReferences: PropTypes.func,
	previousReferences: PropTypes.arrayOf(PropTypes.any).isRequired,
	dispatchSetReferences: PropTypes.func.isRequired,
	deleteReferenceForm: PropTypes.func.isRequired,
}

ReferenceInputs.defaultProps = {
	setPreviousReferences: () => {},
}

export default ReferenceInputs
