import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogActions, Button } from '@material-ui/core'
import ChipInput from 'material-ui-chip-input'
import SweetAlertModal from '../../../SweetAlertModal'
import { reqSendByMail } from '../../../../redux/requests/Rates.request'
import { isEmailInput } from '../../../../utils/helpers'
import useStyles from './styles'

const SendByMail = ({ open, handleClose, idRate }) => {
	const [emails, setEmails] = useState([])
	const [disabled, setDisabled] = useState(false)
	const [error, setError] = useState('')
	const classes = useStyles({ error })

	const sendEmail = async () => {
		setError('')
		setDisabled(true)
		const filterMails = emails.filter((filMail) => isEmailInput(filMail))
		if (filterMails.length === 0) {
			setDisabled(false)
			setError('Enter at least 1 email, please')
		} else {
			const [errorService] = await reqSendByMail(idRate, { emails: filterMails })
			if (errorService) {
				setDisabled(false)
				setError(errorService?.message ?? 'An error occurred while sending the email, please try again later')
			} else {
				setDisabled(false)
				handleClose()
				SweetAlertModal('success', 'Email has been sent successfully!', null, 'Ok', true, false, null)
			}
		}
	}
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="form-dialog-title"
			PaperProps={{ classes: { root: classes.root } }}
		>
			<div className={classes.containerForm}>
				<h2 className={classes.title}>Email Quote</h2>
				<p className={classes.description}>Enter an email address to receive the quote</p>
				<ChipInput
					label="Emails"
					variant="outlined"
					color="secondary"
					classes={{ chipContainer: classes.chipContainer }}
					className={classes.chipInput}
					newChipKeys={['Enter', 'Tab', ' ', ',']}
					value={emails}
					onAdd={(newEmail) => {
						if (isEmailInput(newEmail)) {
							const updatedEmails = [...emails, newEmail]
							setEmails(updatedEmails)
						}
					}}
					onDelete={(deletedEmail) => {
						const updatedEmails = emails.filter((state) => state !== deletedEmail)
						setEmails(updatedEmails)
					}}
				/>
				{error.length > 0 && <span className={classes.labelError}>{error}</span>}
			</div>
			<DialogActions className={classes.containerButton}>
				<Button
					disabled={disabled}
					className={classes.sendButton}
					type="button"
					color="secondary"
					variant="contained"
					onClick={() => sendEmail()}
				>
					Send email
				</Button>
				<Button className={classes.cancelButton} onClick={handleClose} variant="contained" disabled={disabled}>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	)
}

SendByMail.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	idRate: PropTypes.number.isRequired,
}

export default SendByMail
