import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	newCarrierContainer: {
		margin: '-16px -24px',
		paddingBottom: '110px',
		[theme.breakpoints.down('md')]: {
			paddingBottom: '200px',
		},
	},
}))

export default useStyles
