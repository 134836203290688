import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt'

import './DocumentsCard.scss'

const useStyles = makeStyles(() => ({
	icon: {
		width: '24px',
		color: '#CCD2D8',
	},
}))

const DocumentsCard = ({
	documents,
	showPreviewButton,
	previewAction,
	showDownloadButton,
	downloadAction,
	showDeleteButton,
	setDocuments,
}) => {
	const classes = useStyles()

	const deleteDocument = (documentIndex) => {
		const doc = [...documents]
		doc.splice(documentIndex, 1)
		setDocuments(doc)
	}

	return (
		<div className="documents-card-container">
			{documents &&
				documents.map((document, index) => (
					<div key={`id-${index + 1}`} className="documents-card-card">
						<h3 className="documents-card-title">{`#${index + 1}`}</h3>
						<div className="documents-card-info-container">
							<p className="documents-card-info">
								<span>Document Type: </span>
								{document.type}
							</p>
							<p className="documents-card-info">
								<span>Document Name: </span>
								{document.name}
							</p>
						</div>
						<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
							{showPreviewButton && (
								<div className="notifications-card-icon-title-delete">
									<VisibilityIcon onClick={previewAction} className={classes.icon} />
									<p style={{ marginLeft: '5px' }} className="notifications-card-title-delete">
										Preview
									</p>
								</div>
							)}
							{showDownloadButton && (
								<div style={{ marginLeft: '1em' }} className="notifications-card-icon-title-delete">
									<SystemUpdateAltIcon onClick={downloadAction} className={classes.icon} />
									<p style={{ marginLeft: '5px' }} className="notifications-card-title-delete">
										Download
									</p>
								</div>
							)}
							{showDeleteButton && (
								<div className="notifications-card-icon-title-delete">
									<DeleteOutlineOutlinedIcon className={classes.icon} />
									<p
										style={{ marginLeft: '5px' }}
										className="notifications-card-title-delete"
										role="presentation"
										onClick={() => deleteDocument(index)}
									>
										Delete
									</p>
								</div>
							)}
						</div>
					</div>
				))}
			{documents && documents.length === 0 && (
				<div className="documents-card-card">
					<h3 className="documents-card-title">#1</h3>
					<p className="documents-card-info">
						<span>Document Type: </span>-
					</p>
					<p className="documents-card-info">
						<span>Document Name: </span>-
					</p>
					<div className="notifications-card-icon-title-delete">
						<p className="notifications-card-title-delete">-</p>
					</div>
				</div>
			)}
		</div>
	)
}

DocumentsCard.propTypes = {
	documents: PropTypes.arrayOf(PropTypes.any).isRequired,
	showPreviewButton: PropTypes.bool,
	showDownloadButton: PropTypes.bool,
	showDeleteButton: PropTypes.bool,
	previewAction: PropTypes.func,
	downloadAction: PropTypes.func,
	setDocuments: PropTypes.func,
}

DocumentsCard.defaultProps = {
	showPreviewButton: false,
	showDownloadButton: false,
	showDeleteButton: true,
	previewAction: () => {},
	downloadAction: () => {},
	setDocuments: () => {},
}

export default DocumentsCard
