import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { InputLabel, MenuItem, FormControl, Select, TextField, Button, Checkbox } from '@material-ui/core'

import { useDispatch } from 'react-redux'

import packageTypeOptions, { freightClassOptions } from '../../../../../utils/dropDownOptions'

import { updateItemHazmatInfo } from '../../../../../redux/actions/Quotes.actions'

import sweetAlertModal from '../../../../../components/SweetAlertModal/SweetAlertModal'
import HazmatModal from '../../../../../components/HazmatModal'

import useStyles from '../../pages/styles'

const EditItem = (props) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const { shipItem, setEditItem, shipmentItems, setShipmentItems } = props

	const [packageType, setPackageType] = useState('')
	const [quantity, setQuantity] = useState('')
	const [freightClass, setFreightClass] = useState('')
	const [nmfc, setNmfc] = useState('')
	const [weight, setWeight] = useState('')
	const [length, setLength] = useState('')
	const [width, setWidth] = useState('')
	const [height, setHeight] = useState('')
	const [commodity, setCommodity] = useState('')
	const [stackable, setStackable] = useState(false)
	const [hazardous, setHazardous] = useState(false)
	const [openHazmatModal, setOpenHazmatModal] = useState(false)
	const [hazmatData, setHazmatData] = useState('')

	useEffect(() => {
		if (Object.keys(shipItem).length !== 0) {
			setPackageType(shipItem.unit_type ? shipItem.unit_type : '')
			setQuantity(shipItem.pieces_value)
			setFreightClass(shipItem.class ? shipItem.class : '')
			setNmfc(shipItem.nmfc ? `${shipItem.nmfc}-${shipItem.sub_nmfc}` : '')
			setWeight(shipItem.weight_value)
			setLength(shipItem.dimensions_lenght)
			setWidth(shipItem.dimensions_width)
			setHeight(shipItem.dimensions_height)
			setCommodity(shipItem.description ? shipItem.description : '')
			setStackable(shipItem.stackable)
			setHazardous(shipItem.is_haz_mat)
		}
	}, [shipItem])

	const handleSaveClick = async () => {
		const allShipmentItems = JSON.parse(JSON.stringify(shipmentItems))
		allShipmentItems.filter((item, index) => {
			if (item.id === shipItem.id) {
				allShipmentItems[index].nmfc = nmfc
				allShipmentItems[index].sub_nmfc = ''
				allShipmentItems[index].description = commodity
				allShipmentItems[index].stackable = stackable
				allShipmentItems[index].is_haz_mat = hazardous
				allShipmentItems[index].haz_mat_shipping_name = hazmatData.shippingName ? hazmatData.shippingName : null
				allShipmentItems[index].haz_mat_number_type = hazmatData.hazMatNumberType ? hazmatData.hazMatNumberType : null
				allShipmentItems[index].haz_mat_number = hazmatData.hazMatNumber ? hazmatData.hazMatNumber : null
				allShipmentItems[index].haz_mat_class = hazmatData.hazMatClass ? hazmatData.hazMatClass : null
				allShipmentItems[index].haz_mat_group = hazmatData.hazMatGroup ? hazmatData.hazMatGroup : null
				allShipmentItems[index].contact_info_name = hazmatData.name ? hazmatData.name : null
				allShipmentItems[index].contact_info_phone = hazmatData.phone ? hazmatData.phone : null
			}
			return true
		})
		setShipmentItems(allShipmentItems)
		const quoteId = shipItem.quote_id
		const itemId = shipItem.id
		const params = {
			shippingName: hazmatData.shippingName ? hazmatData.shippingName : null,
			hazMatNumberType: hazmatData.hazMatNumberType ? hazmatData.hazMatNumberType : null,
			hazMatNumber: hazmatData.hazMatNumber ? hazmatData.hazMatNumber : null,
			hazMatClass: hazmatData.hazMatClass ? hazmatData.hazMatClass : null,
			hazMatGroup: hazmatData.hazMatGroup ? hazmatData.hazMatGroup : null,
			contactInformation: {
				name: hazmatData.name ? hazmatData.name : null,
				phone: hazmatData.phone ? hazmatData.phone.replace(/[() -]/g, '') : null,
				zipcode: shipItem.contact_info_zipcode,
				state: shipItem.contact_info_state,
				country: shipItem.contact_info_country,
			},
		}
		const { status, resp } = await dispatch(updateItemHazmatInfo(quoteId, itemId, params))
		if (status === 'error') {
			sweetAlertModal(status, 'There was a problem editing the item!', resp, 'Ok', true, false, null)
		}
		setEditItem(false)
	}

	return (
		<div style={{ marginTop: '24px' }}>
			<div className="new-shipment-items-inputs-row-1">
				<FormControl variant="outlined">
					<InputLabel className={classes.root}>Package type *</InputLabel>
					<Select
						className={classes.disabledSelect}
						name="packageType"
						value={packageType}
						disabled
						label="Package Type *"
					>
						{packageTypeOptions.map((item, index) => (
							<MenuItem value={item} key={`id-${index + 1}`}>
								{item}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<TextField
					name="quantity"
					className={classes.disabled}
					type="number"
					value={quantity}
					helperText="Min. 1"
					FormHelperTextProps={{ classes: { root: classes.helperText } }}
					label="Quantity *"
					variant="outlined"
					disabled
				/>
				<FormControl variant="outlined">
					<InputLabel className={classes.root}>FreightClass *</InputLabel>
					<Select
						className={classes.disabledSelect}
						name="freightClass"
						value={freightClass}
						disabled
						label="FreightClass *"
					>
						{freightClassOptions.map((item, index) => (
							<MenuItem value={item} key={`id-${index + 1}`}>
								{item}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<TextField
					name="NMFC"
					value={nmfc}
					onChange={(e) => setNmfc(e.target.value)}
					helperText="NMFC - SUBNMFC, e.g. xxxxx-xx"
					FormHelperTextProps={{ classes: { root: classes.helperText } }}
					label="NMFC code"
					variant="outlined"
				/>
				<TextField
					name="weight"
					type="number"
					className={classes.disabled}
					value={weight}
					helperText="Max. 15.000"
					FormHelperTextProps={{ classes: { root: classes.helperText } }}
					label="Weight(Lb) *"
					variant="outlined"
					disabled
				/>
				<TextField
					name="length"
					type="number"
					className={classes.disabled}
					value={length}
					helperText="Max. 288"
					FormHelperTextProps={{ classes: { root: classes.helperText } }}
					label="Length(In) *"
					variant="outlined"
					disabled
				/>
				<TextField
					name="width"
					type="number"
					className={classes.disabled}
					value={width}
					helperText="Max. 96"
					FormHelperTextProps={{ classes: { root: classes.helperText } }}
					label="Width(In) *"
					variant="outlined"
					disabled
				/>
				<TextField
					name="height"
					type="number"
					className={classes.disabled}
					value={height}
					helperText="Max. 96"
					FormHelperTextProps={{ classes: { root: classes.helperText } }}
					label="Height(In) *"
					variant="outlined"
					disabled
				/>
			</div>
			<div className="new-shipment-items-inputs-row-2">
				<TextField
					name="commodity"
					className={classes.root}
					value={commodity}
					onChange={(e) => setCommodity(e.target.value)}
					label="Product description"
					variant="outlined"
				/>
				<div className="shipment-item-option-container">
					<Checkbox
						name="stackable"
						checked={stackable}
						onChange={(event) => setStackable(event.target.checked)}
						inputProps={{ 'aria-label': 'primary checkbox' }}
						style={{ color: '#EC3514' }}
					/>
					<div className="shipment-item-info">
						<span className="shipment-item-info-title">
							<strong>Stackable</strong>
						</span>
						<span className="shipment-item-info-description">Items that can be stacked to reduce linear space.</span>
					</div>
				</div>
				<div className="shipment-item-option-container">
					<Checkbox
						name="hazardous"
						checked={hazardous}
						onChange={(event) => {
							setHazardous(event.target.checked)
							setOpenHazmatModal(event.target.checked)
						}}
						inputProps={{ 'aria-label': 'primary checkbox' }}
						style={{ color: '#EC3514' }}
					/>
					<div className="shipment-item-info">
						<span className="shipment-item-info-title">
							<strong>Hazardous</strong>
						</span>
						<span className="shipment-item-info-description">
							Hazmat such as chemicals, acid, waste with a UN number.
						</span>
					</div>
				</div>
				<Button
					variant="contained"
					color="secondary"
					className={classes.cancelButton}
					onClick={() => setEditItem(false)}
				>
					Cancel
				</Button>
				<Button
					style={{ width: '90px' }}
					variant="contained"
					color="secondary"
					className={classes.button}
					onClick={() => handleSaveClick()}
				>
					Save
				</Button>
			</div>
			<HazmatModal isOpen={openHazmatModal} setHazmatData={setHazmatData} />
		</div>
	)
}

EditItem.propTypes = {
	shipItem: PropTypes.objectOf(PropTypes.any).isRequired,
	setEditItem: PropTypes.func.isRequired,
	shipmentItems: PropTypes.arrayOf(PropTypes.any).isRequired,
	setShipmentItems: PropTypes.func.isRequired,
}

export default EditItem
