import React from 'react'
import PropTypes from 'prop-types'

import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'

import ShipperInformation from '../ShipperInformation'
import ConsigneeInformation from '../ConsigneeInformation'
import SpecialInstructions from '../SpecialInstructions'
import ReferencesForm from '../../../../../components/ReferencesForm'
import ShipmentItemsTable from '../ShipmentItemsTable'

import useStyles from '../../pages/styles'
import '../../pages/NewShipment.scss'

const ShipmentStepOne = (props) => {
	const classes = useStyles()
	const {
		shipperData,
		consigneeData,
		shipmentItems,
		setShipmentItems,
		referencesAdded,
		setReferencesAdded,
		register,
		errors,
		isPreviousClicked,
	} = props

	return (
		<div className="new-shipment-shipment-information">
			<ShipperInformation
				shipperData={shipperData}
				register={register}
				errors={errors}
				isPreviousClicked={isPreviousClicked}
			/>
			<hr style={{ width: '95%', marginTop: 0 }} />
			<ConsigneeInformation
				consigneeData={consigneeData}
				register={register}
				errors={errors}
				isPreviousClicked={isPreviousClicked}
			/>
			<hr style={{ width: '95%', margin: '25px 0' }} />
			<div className="new-shipment-icon-title">
				<AssignmentOutlinedIcon className={classes.mainIcon} color="primary" />
				<h3 className="new-shipment-item-title">Shipment Items</h3>
			</div>
			<ShipmentItemsTable shipmentItems={shipmentItems} setShipmentItems={setShipmentItems} />
			<hr style={{ width: '95%', margin: '25px 0' }} />
			<SpecialInstructions register={register} errors={errors} isPreviousClicked={isPreviousClicked} />
			<hr style={{ width: '95%', margin: '25px 0' }} />
			<div className="new-shipment-icon-title">
				<AssignmentOutlinedIcon className={classes.mainIcon} color="primary" />
				<h3 className="new-shipment-item-title">References</h3>
			</div>
			<ReferencesForm setReferencesAdded={setReferencesAdded} previousReferences={referencesAdded} />
		</div>
	)
}

ShipmentStepOne.propTypes = {
	shipperData: PropTypes.objectOf(PropTypes.any).isRequired,
	consigneeData: PropTypes.objectOf(PropTypes.any).isRequired,
	shipmentItems: PropTypes.arrayOf(PropTypes.any).isRequired,
	setShipmentItems: PropTypes.func.isRequired,
	referencesAdded: PropTypes.arrayOf(PropTypes.any).isRequired,
	setReferencesAdded: PropTypes.func.isRequired,
	register: PropTypes.func.isRequired,
	errors: PropTypes.objectOf(PropTypes.any).isRequired,
	isPreviousClicked: PropTypes.bool.isRequired,
}

export default ShipmentStepOne
