/* eslint-disable prefer-destructuring */
import React from 'react'
import PropTypes from 'prop-types'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import useStyles from './styles'
import destructuringName from '../../../../../../../utils/destructuringName'

const TableRowBody = (props) => {
	const classes = useStyles()
	const { data } = props

	return (
		<TableRow className={classes.tableRow} hover key={data.id}>
			<TableCell className={classes.tableCell}>{data?.name ? destructuringName(data.name).name : ''}</TableCell>
			<TableCell className={classes.tableCell}>{data?.name ? destructuringName(data.name).lastName : ''}</TableCell>
			<TableCell className={classes.tableCell}>{data?.id ?? ''}</TableCell>
			<TableCell className={classes.tableCell}>{data?.email ?? ''}</TableCell>
			<TableCell className={classes.tableCell}>{data?.role ?? ''}</TableCell>
		</TableRow>
	)
}

TableRowBody.propTypes = {
	data: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default TableRowBody
