import React, { useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { TableCell, TableRow, Checkbox, IconButton, Chip, Tooltip, Button, useTheme } from '@material-ui/core'
import SendIcon from '@material-ui/icons/Send'
import moment from 'moment'
import { DownloadIcon } from '../../../../assets/Icons/Icons'
import SessionContext from '../../../../context/session'
import useStyles from './styles'
import { setTabs } from '../../../../redux/actions/MultiTabs.actions'
import createNewTab from '../../../../utils/helpers'
import SendInvoicesModal from '../SendInvoicesModal'

const InvoiceTableRow = (props) => {
	const theme = useTheme()
	const classes = useStyles()
	const {
		labelId,
		isItemSelected,
		data,
		selected,
		setSelected,
		permitInvoiceSendEmail,
		permitInvoiceDownload,
		showActions,
		permitInvoiceDetails,
	} = props
	const dispatch = useDispatch()
	const history = useHistory()
	const location = useLocation()
	const currentTabs = useSelector((state) => state.multiTabs.tabs)
	const [showEmailModal, setShowEmailModal] = useState(false)

	const { getUserData, getTokenUser } = useContext(SessionContext)
	const { actualCompany } = getUserData()
	const token = getTokenUser()

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name)
		let newSelected = []

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name)
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1))
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1))
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
		}
		setSelected(newSelected)
	}

	const openInvoiceDetails = () => {
		const tabs = [...currentTabs]
		const newTab = createNewTab(
			tabs.length,
			'Invoice Details',
			`/invoice-details/${data.id}`,
			'',
			'',
			{ invoice: data },
			true,
			true,
		)
		const index = tabs.findIndex((item) => item.route === location.pathname)
		tabs[index === -1 ? 0 : index] = newTab
		dispatch(setTabs(tabs))
		history.push(newTab.route)
	}

	const renderStatus = (status) => {
		const lowerCaseStatus = status.replace('_', ' ')
		const statusesColors = {
			PAID: classes.statusGreen,
			Partially_paid: classes.statusOrange,
			'PARTIALLY PAID': classes.statusOrange,
			UNPAID: classes.statusRed,
		}

		return status ? (
			<Chip
				size="small"
				className={[classes.status, statusesColors[status.toUpperCase()]].join(' ')}
				label={lowerCaseStatus.charAt(0) + lowerCaseStatus.slice(1).toLowerCase()}
			/>
		) : null
	}

	const handleDownloadInvoicePDF = () => {
		const url = `${process.env.REACT_APP_BACKEND_INVOICES_BASE_URL}/invoice/pdf/${data.id}`

		const oReq = new XMLHttpRequest()
		oReq.open('GET', url, true)
		oReq.responseType = 'arraybuffer'
		oReq.setRequestHeader('X-Company-id', Number(actualCompany))
		oReq.setRequestHeader('Authorization', `Bearer ${token}`)

		oReq.onload = function download() {
			const blob = new Blob([oReq.response], { type: 'application/pdf' })
			const URL = window.URL || window.webkitURL
			const dataUrl = URL.createObjectURL(blob)
			const downloadLink = document.createElement('a')
			const fileName = `invoice-${data.id}.pdf`

			downloadLink.href = dataUrl
			downloadLink.download = fileName
			downloadLink.click()
		}
		oReq.send()
	}

	return (
		<>
			<TableRow
				className={classes.tableRow}
				hover
				role="checkbox"
				aria-checked={isItemSelected}
				tabIndex={-1}
				key={data.id}
				selected={isItemSelected}
			>
				<TableCell padding="checkbox">
					{showActions ? (
						<Checkbox
							inputProps={{ 'aria-labelledby': labelId }}
							checked={isItemSelected}
							onClick={(event) => handleClick(event, data.id)}
							style={{
								color: isItemSelected ? theme.palette.secondary.main : '#818181',
							}}
						/>
					) : null}
				</TableCell>
				<TableCell component="th" id={labelId} scope="row" padding="none" className={classes.tableCell}>
					{permitInvoiceDetails ? (
						<Tooltip title="see details">
							<Button
								className={classes.linkPointer}
								onClick={() => {
									openInvoiceDetails()
								}}
							>
								{data.invoice_number}
							</Button>
						</Tooltip>
					) : (
						<span>{data.invoice_number}</span>
					)}
				</TableCell>
				<TableCell className={classes.tableCell}>{moment(data.invoice_date).utc().format('MM/DD/YYYY')}</TableCell>
				<TableCell className={classes.tableCell}>{moment(data.invoice_due_date).utc().format('MM/DD/YYYY')}</TableCell>
				<TableCell className={classes.tableCell}>{`$${data.total_amount} USD`}</TableCell>
				<TableCell className={classes.tableCell}>{`$${data.balance_due_amount} USD`}</TableCell>
				<TableCell>{renderStatus(data.payment_status)}</TableCell>
				<TableCell className={classes.tableCell}>{data.days_past_due ? data.days_past_due : '-'}</TableCell>
				{showActions && (
					<TableCell className={classes.tableCell}>
						<div style={{ display: 'flex' }}>
							{permitInvoiceDownload && (
								<Tooltip title="Download invoice">
									<IconButton
										aria-label="toggle action menu"
										onMouseDown={null}
										onClick={() => handleDownloadInvoicePDF()}
										style={{ padding: 5 }}
									>
										<DownloadIcon color="secondary" fontSize="small" />
									</IconButton>
								</Tooltip>
							)}
							{permitInvoiceSendEmail && (
								<Tooltip title="Send by email">
									<IconButton
										aria-label="toggle action menu"
										onMouseDown={null}
										style={{ padding: 5 }}
										onClick={() => setShowEmailModal(true)}
									>
										<SendIcon color="secondary" fontSize="small" style={{ width: '20px' }} />
									</IconButton>
								</Tooltip>
							)}
						</div>
					</TableCell>
				)}
			</TableRow>
			<SendInvoicesModal
				showEmailModal={showEmailModal}
				setShowEmailModal={setShowEmailModal}
				invoicesIds={[data.id]}
			/>
		</>
	)
}

InvoiceTableRow.defaultProps = {
	permitInvoiceDownload: false.isRequired,
	permitInvoiceSendEmail: false.isRequired,
	permitInvoiceDetails: false.isRequired,
	showActions: false.isRequired,
}

InvoiceTableRow.propTypes = {
	labelId: PropTypes.string.isRequired,
	isItemSelected: PropTypes.bool.isRequired,
	data: PropTypes.objectOf(PropTypes.any).isRequired,
	selected: PropTypes.instanceOf(Array).isRequired,
	setSelected: PropTypes.func.isRequired,
	permitInvoiceDownload: PropTypes.bool,
	permitInvoiceSendEmail: PropTypes.bool,
	permitInvoiceDetails: PropTypes.bool,
	showActions: PropTypes.bool,
}

export default InvoiceTableRow
