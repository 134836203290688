import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	newQuoteIconTitle: {
		display: 'flex',
		columnGap: '12px',
		alignItems: 'center',
	},
	newQuoteMenuIcon: {
		backgroundColor: theme.palette.neutral.white,
		color: theme.palette.primary.main,
		borderRadius: '20px',
		padding: '4px',
		alignSelf: 'center',
	},
	newQuoteTitle: {
		fontWeight: theme.typography.fontWeightRegular,
		color: theme.palette.primary.main,
		margin: 0,
		lineHeight: '22px',
	},
	newQuoteSubtitle: {
		fontSize: '16px',
		color: theme.palette.common.black,
		fontWeight: theme.typography.fontWeightBold,
		margin: '25px 0',
	},
	newQuoteLaneForm: {
		width: '100%',
		display: 'flex',
		columnGap: '2em',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
		},
	},
	newQuoteOriginDestination: {
		display: 'flex',
		flexDirection: 'column',
		width: '50%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	newQuoteSiteType: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		columnGap: '12px',
		padding: '0 15px',
		fontSize: '14px',
		height: '70px',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			height: 'auto',
			rowGap: '2em',
			padding: 0,
		},
	},
	newQuoteSiteTypeDestination: {
		width: '100%',
		display: 'flex',
		columnGap: '12px',
		padding: '0 15px',
		fontSize: '14px',
		height: '70px',
		[theme.breakpoints.down('sm')]: {
			padding: 0,
		},
	},
	newQuoteReferenceSubtitle: {
		fontSize: '17px',
		color: theme.palette.primary.main,
		fontWeight: theme.typography.fontWeightBold,
		margin: '25px 10px',
	},
	newQuoteSiteTypeLabel: {
		width: '60%',
		display: 'flex',
		alignItems: 'center',
		columnGap: '1em',
		[theme.breakpoints.down('sm')]: {
			width: 'auto',
		},
	},
	newQuoteFormControl: {
		width: '95%',
		marginLeft: '15px',
		marginTop: '25px',
		[theme.breakpoints.down('sm')]: {
			marginLeft: 0,
		},
	},
	grid: {
		display: 'grid',
		gridAutoFlow: 'column',
		gridTemplateColumns: '323px repeat(2, 1fr)',
		padding: '10px 120px 0 0',
	},
	triggerButton: {
		alignItems: 'center',
		color: theme.palette.primary.main,
		textAlign: 'right',
		display: 'flex',
		justifyContent: 'flex-end',
		fontSize: '14px',
		marginBottom: '12px',
	},
	icon: {
		fontSize: theme.typography.button.fontFamily,
		marginRight: '5px',
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		zIndex: '1400',
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
	newQuoteIcon: {
		height: 32,
		width: 32,
		backgroundColor: theme.palette.neutral.white,
	},
	cursorPointer: {
		cursor: 'pointer',
		color: theme.palette.secondary.main,
	},
}))

export default useStyles
