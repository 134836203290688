import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	container: {
		borderBottom: `4px solid ${theme.palette.neutral.secondaryGrey}`,
		'& .MuiGrid-item': {
			marginBottom: 8,
		},
	},
	title: {
		fontWeight: theme.typography.fontWeightBold,
		fontSize: 12,
		color: theme.palette.neutral.black,
	},
	label: {
		fontWeight: theme.typography.fontWeightBold,
		fontSize: 13,
		color: theme.palette.neutral.black,
	},
	border: {
		borderRight: `1px solid ${theme.palette.secondary.light}`,
	},
	link: {
		color: '#0000EE',
		cursor: 'pointer',
		textDecoration: 'underline',
	},
}))

export default useStyles
