/* eslint-disable no-param-reassign */
import React, { useEffect, useState, forwardRef } from 'react'
import PropTypes from 'prop-types'

import { useForm, useFieldArray } from 'react-hook-form'

import { Button, Grid } from '@material-ui/core'
import AddNewIcon from '@material-ui/icons/Add'
import AddSavedIcon from '@material-ui/icons/PlaylistAdd'
import { ShipmentItemsIcon } from '../../../../assets/Icons/Icons'

import ModalSavedItems from '../ModalSavedItems'
import useStyles from './styles'
import ShipmentItem from './components/ShipmentItem'
import InfoItems from '../NewQuoteShipmentStepTwo/components/InfoItemsValues/InfoItemsValues'

const emptyItem = {
	packageType: '',
	quantity: null,
	freightClass: '',
	nmfc: null,
	weight: null,
	length: null,
	width: null,
	height: null,
	commodity: '',
	hazardous: false,
	stackable: false,
	hazmatData: {},
	isWritableElem: true,
}

const defaultValuesForm = {
	items: [emptyItem],
}

const ShipmentItems = forwardRef(
	(
		{
			items: defaultItems,
			linearFeet,
			setLinearFeet,
			dispatchSetShipmentItemsStore,
			freightClassRef,
			refInfoItemsValues,
		},
		ref,
	) => {
		const { control, errors, watch, setValue, trigger } = useForm({
			defaultValues: defaultValuesForm,
			mode: 'onChange',
		})
		const { fields, append, remove } = useFieldArray({ control, name: 'items', keyName: 'key' })
		const formItems = watch('items', [])

		const [openModalSavedItems, setOpenModalSavedItems] = useState(false)
		const handleOpenModalSavedItems = () => setOpenModalSavedItems(true)
		const handleCloseModalSavedItems = () => setOpenModalSavedItems(false)

		const classes = useStyles()

		useEffect(() => {
			if (defaultItems && defaultItems.length > 0) {
				if (fields.length > 0) remove()
				append(defaultItems.map((el) => ({ ...el, isWritableElem: false })))
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [defaultItems, append])

		useEffect(() => {
			if (fields.length === 0) append(emptyItem)
		}, [append, fields])

		const removeItem = (index) => {
			remove(index)
		}

		return (
			<>
				<Grid container className={classes.shipmentItemsContainer}>
					<Grid item container alignItems="center">
						<ShipmentItemsIcon />
						<h5 className={classes.shipmentItemsTitle}>Shipment Items</h5>
					</Grid>
					<Grid item container spacing={1} className={classes.rowContainer}>
						{fields.map(({ key, isWritableElem, ...rest }, index) => (
							<Grid item container key={key} className={classes.rowItemContainer}>
								<ShipmentItem
									item={rest}
									itemIndex={index}
									isWritableElem={isWritableElem}
									control={control}
									errors={errors?.items?.[index]}
									watch={watch}
									setValue={setValue}
									remove={() => removeItem(index)}
									trigger={trigger}
									ref={(itemRef) => {
										ref.current[index] = itemRef
									}}
								/>
							</Grid>
						))}
					</Grid>
					<Grid item container className={classes.rowContainer}>
						<Grid container xs={12} md={10}>
							<InfoItems
								activeStep={1}
								items={formItems}
								linearFeet={linearFeet}
								setLinearFeet={setLinearFeet}
								freightClassRef={freightClassRef}
								ref={refInfoItemsValues}
							/>
						</Grid>
						<Grid item container xs={12} md={2}>
							<div className={classes.shipmentItemsActions}>
								<Button onClick={() => append(emptyItem)} className={classes.shipmentItemsAction}>
									<AddNewIcon style={{ marginRight: '5px' }} color="secondary" />
									<span>Add New</span>
								</Button>
								<Button onClick={handleOpenModalSavedItems} className={classes.shipmentItemsAction}>
									<AddSavedIcon style={{ marginRight: '5px' }} color="secondary" />
									<span>Search saved items</span>
								</Button>
							</div>
						</Grid>
					</Grid>
				</Grid>
				<ModalSavedItems
					open={openModalSavedItems}
					onClose={handleCloseModalSavedItems}
					setShipmentItems={append}
					dispatchSetShipmentItems={dispatchSetShipmentItemsStore}
					inputList={formItems}
				/>
			</>
		)
	},
)

ShipmentItems.propTypes = {
	items: PropTypes.arrayOf(PropTypes.any).isRequired,
	dispatchSetShipmentItemsStore: PropTypes.func.isRequired,
	freightClassRef: PropTypes.objectOf(PropTypes.any).isRequired,
	linearFeet: PropTypes.string.isRequired,
	setLinearFeet: PropTypes.func.isRequired,
	refInfoItemsValues: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default ShipmentItems
