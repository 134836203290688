import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import useStyles from './styles'

const Activities = ({
	isFinal,
	info: { date: actualDate, description, location_city: locationCity, location_state: locationState, status },
}) => {
	const classes = useStyles()

	return (
		<div className={classes.container}>
			<div className={classes.containerTittle}>
				<span className={`${classes.dot} ${isFinal ? classes.dotActive : ''}`} />
				<div>
					<span className={classes.titleKey}>Status: </span>
					<span className={classes.titleValue}>{status ?? 'N/A'}</span>
				</div>
			</div>
			<div className={classes.containerInformationVerticalLine}>
				<span className={`${classes.verticalLine} ${isFinal ? classes.finalVerticalLine : ''}`} />
				<div className={classes.containerDescriptionDateTime}>
					<div className={classes.containerDateTime}>
						<div>
							<span className={classes.titleKey}>Date: </span>
							<span className={classes.titleValue}>{actualDate ? moment(actualDate).format('MM/DD/YYYY') : 'N/A'}</span>
						</div>
						<div>
							<span className={classes.titleKey}>Time: </span>
							<span className={classes.titleValue}>{actualDate ? moment(actualDate).format('hh:mm A') : 'N/A'}</span>
						</div>
						<div className={classes.containerLocationStopNumber}>
							<span className={classes.titleKey}>Location: </span>
							<span className={classes.titleValue}>{`${locationCity ?? 'N/A'}, ${locationState ?? 'N/A'}`}</span>
						</div>
					</div>
					<div>
						<span className={classes.titleKey}>Description: </span>
						{description ?? 'N/A'}
					</div>
				</div>
			</div>
		</div>
	)
}

Activities.defaultProps = {
	isFinal: false,
}

Activities.propTypes = {
	isFinal: PropTypes.bool,
	info: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default Activities
