import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	dialogTitle: {
		display: 'flex',
		justifyContent: 'flex-end',
		padding: theme.spacing(1),
	},
	formContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		paddingTop: 0,
		paddingRight: theme.spacing(3),
		paddingBottom: theme.spacing(3),
		paddingLeft: theme.spacing(3),
	},
	currentDeclaredValue: {
		fontSize: 16,
		textAlign: 'center',
	},
	declaredInputGroup: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	paperDialog: {
		width: '100%',
	},
	declaredValueInput: {
		marginTop: theme.spacing(2),
		minWidth: 175,
	},
	declaredValueError: {
		color: theme.palette.error.main,
		fontSize: 12,
	},
	totalCustomerInsuranceCost: {
		marginTop: theme.spacing(1),
		fontSize: 16,
		fontWeight: 600,
	},
	continueWithInsuranceButton: {
		marginTop: theme.spacing(2),
	},
	continueWithoutInsuranceButton: {
		marginRight: theme.spacing(2),
	},
}))

export default useStyles
