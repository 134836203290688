import { axiosInvoices } from '../../utils/apiClient'

const reqGetInvoices = async (params) => {
	const [error, data] = await axiosInvoices
		.get(`/invoice/list${params}`)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export const reqGetInvoiceById = async (params) => {
	const [error, data] = await axiosInvoices
		.get(`/invoice/${params}`)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export const reqDownloadPDFInvoice = async (params) => {
	const [error, data] = await axiosInvoices
		.get(`/invoice/pdf/${params}`)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export const reqCreateInvoice = async (params) => {
	const [error, data] = await axiosInvoices
		.post('/salesforce', params)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export const reqEditInvoice = async (params, body) => {
	const [error, data] = await axiosInvoices
		.put(`/invoice/${params}`, body)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export const reqUpdateInvoiceStatus = async (invoiceId, params) => {
	const [error, data] = await axiosInvoices
		.put(`/invoice/status/${invoiceId}`, params)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export const reqDownloadBulkInvoices = async (params) => {
	const [error, data] = await axiosInvoices
		.post('/invoice/download-bulk', params)
		.then((response) => [null, response])
		.catch((err) => [err, err.response.data.message])

	return [error, data]
}

export const reqSendBulkEMailInvoices = async (params) => {
	const [error, data] = await axiosInvoices
		.post('/invoice/email-bulk', params)
		.then((response) => [null, response])
		.catch((err) => [err, err.response.data.message])

	return [error, data]
}

export default reqGetInvoices
