import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { setTabTitle, setTabCurrentRoute } from '../../../../redux/actions/MultiTabs.actions'
import { createAccountRoute, accountEditRoute } from '../../../../utils/constants'
import StepperContainer from '../containers/StepperContainer/StepperContainer'

const NewAccount = () => {
	const dispatch = useDispatch()
	const { id } = useParams()
	const isEditable = !!id

	useEffect(() => {
		dispatch(setTabCurrentRoute(isEditable ? accountEditRoute.replace(':id', id) : createAccountRoute))
		dispatch(setTabTitle(isEditable ? 'Edit Account' : 'New Account'))
	}, [dispatch, isEditable, id])
	return <StepperContainer isEditable={isEditable} />
}

export default NewAccount
