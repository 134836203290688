import React from 'react'
import PropTypes from 'prop-types'

import { FormControlLabel, Checkbox } from '@material-ui/core'
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined'

import { setAdminCustomerCheckboxesValues } from '../../../utils/helpers'

import useStyles from '../styles'

const AdminCustomerSettings = (props) => {
	const { adminCustomer, setAdminCustomer, setCheckboxesValues, checkboxesValues, onChangeCheckboxValue } = props
	const classes = useStyles()

	return (
		<div>
			<FormControlLabel
				className={classes.checkBoxLabel}
				control={
					<Checkbox
						indeterminate={adminCustomer}
						indeterminateIcon={<IndeterminateCheckBoxOutlinedIcon />}
						color="secondary"
						className={classes.checkBox}
						checked={adminCustomer}
						onChange={(event) => {
							const { checked } = event.target
							setAdminCustomer(checked)
							setCheckboxesValues((prevState) => ({
								...prevState,
								...setAdminCustomerCheckboxesValues({ checked }),
							}))
						}}
					/>
				}
				label="Admin Customer Settings"
			/>
			<div className={classes.checkBoxesColumn}>
				<FormControlLabel
					className={classes.checkBoxLabel}
					control={
						<Checkbox
							color="secondary"
							className={classes.checkBox}
							checked={checkboxesValues.createAccountsAdminCustomer}
							onChange={(event) => onChangeCheckboxValue(event, 'createAccountsAdminCustomer')}
						/>
					}
					label="Create new accounts"
				/>
				<FormControlLabel
					className={classes.checkBoxLabel}
					control={
						<Checkbox
							color="secondary"
							className={classes.checkBox}
							checked={checkboxesValues.editAccountsAdminCustomer}
							onChange={(event) => onChangeCheckboxValue(event, 'editAccountsAdminCustomer')}
						/>
					}
					label="Edit accounts"
				/>
				<FormControlLabel
					className={classes.checkBoxLabel}
					control={
						<Checkbox
							color="secondary"
							className={classes.checkBox}
							checked={checkboxesValues.createUsersAdminCustomer}
							onChange={(event) => onChangeCheckboxValue(event, 'createUsersAdminCustomer')}
						/>
					}
					label="Create new users"
				/>
				<FormControlLabel
					className={classes.checkBoxLabel}
					control={
						<Checkbox
							color="secondary"
							className={classes.checkBox}
							checked={checkboxesValues.editUsersAdminCustomer}
							onChange={(event) => onChangeCheckboxValue(event, 'editUsersAdminCustomer')}
						/>
					}
					label="Edit users"
				/>
			</div>
		</div>
	)
}

AdminCustomerSettings.propTypes = {
	adminCustomer: PropTypes.bool.isRequired,
	setAdminCustomer: PropTypes.func.isRequired,
	setCheckboxesValues: PropTypes.func.isRequired,
	checkboxesValues: PropTypes.objectOf(PropTypes.any).isRequired,
	onChangeCheckboxValue: PropTypes.func.isRequired,
}

export default AdminCustomerSettings
