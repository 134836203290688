import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import Tooltip from '@material-ui/core/Tooltip'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import { Radio, FormControlLabel, withStyles } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useStyles from './styles'
import { capitalizeFirstLetter, removeUnderScore } from '../../../../../utils/helpers'
import MenuList from '../MenuListCarrier'

const CarrierAccessorialsTooltip = withStyles(() => ({
	tooltip: {
		backgroundColor: '#f5f5f9',
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 400,
		fontSize: '0.75rem',
		border: '1px solid #dadde9',
	},
}))(Tooltip)

const CarrierTableRow = (props) => {
	const classes = useStyles()
	const theme = useTheme()
	const {
		labelId,
		isItemSelected,
		data,
		isSelectableList,
		setSelected,
		selected,
		handleDeleteClick,
		handleEditClick,
		isMainCarrierList,
		handleUpdateCarrierStatus,
		showRadios,
		isAccountCarrierList,
	} = props

	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)
	const handleOpenMenu = (event) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}
	const index = Number(labelId.split('-').pop())

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name)
		let newSelected = []

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name)
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1))
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1))
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
		}
		setSelected(newSelected)
	}

	const buildCarrierAccessorialsTooltip = (accessorials) => {
		const labels = accessorials.map((accessorial) => {
			const label =
				accessorial.uplift_type_accessorial === 'PERCENTAGE'
					? `${capitalizeFirstLetter(accessorial.name)}: ${accessorial.uplift_accessorial}% margin`
					: `${capitalizeFirstLetter(accessorial.name)}: $${
							accessorial.uplift_accessorial
						} ${accessorial.uplift_type_accessorial}`

			return <span>{label}</span>
		})

		return (
			<CarrierAccessorialsTooltip title={<div style={{ display: 'flex', flexDirection: 'column' }}>{labels}</div>}>
				<InfoIcon color="secondary" />
			</CarrierAccessorialsTooltip>
		)
	}

	const customTableRow = () => {
		if (showRadios) {
			return (
				<>
					<TableCell
						id={labelId}
						scope="row"
						padding="none"
						className={`${classes.tableCell} ${classes.tableCellCarrier}`}
					>
						<img src={data.carrier_logo} alt={data.scac} className={classes.carrierImg} />
						<span className={classes.linkPointer}>{data.name}</span>
					</TableCell>
					<TableCell className={classes.tableCell}>{data.scac}</TableCell>
					<TableCell className={classes.tableCell}>{removeUnderScore(data.service_type)}</TableCell>
				</>
			)
		}
		if (isMainCarrierList) {
			return (
				<>
					<TableCell
						id={labelId}
						scope="row"
						padding="none"
						className={`${classes.tableCell} ${classes.tableCellCarrier}`}
					>
						<Box className={classes.cellAccountInfo}>
							<Link className={classes.carrierName} to={`/carrier-details/${data.carrier_integration_id}`}>
								<img src={data.carrier_logo} alt={data.scac} className={classes.carrierImg} />
							</Link>
							<Link className={classes.carrierName} to={`/carrier-details/${data.carrier_integration_id}`}>
								<span className={classes.linkPointer}>{data.name}</span>
							</Link>
						</Box>
					</TableCell>
					<TableCell className={classes.tableCell}>{data.scac}</TableCell>
					<TableCell className={classes.tableCell}>{data.contract_type}</TableCell>
					<TableCell className={classes.tableCell}>{data.carrier_group}</TableCell>
					<TableCell className={classes.tableCell}>{removeUnderScore(data.service_type)}</TableCell>
					<TableCell className={classes.tableCell}>
						{data.status ? (
							<Chip size="small" className={`${classes.status} ${classes.statusGreen}`} label="Active" />
						) : (
							<Chip size="small" className={`${classes.status} ${classes.statusRed}`} label="Inactive" />
						)}
					</TableCell>
				</>
			)
		}
		return (
			<>
				<TableCell
					id={labelId}
					scope="row"
					padding="none"
					className={`${classes.tableCell} ${classes.tableCellCarrier}`}
				>
					<img src={data.carrier_logo} alt={data.scac} className={classes.carrierImg} />
					<span className={classes.linkPointer}>{data.name}</span>
				</TableCell>
				<TableCell className={classes.tableCell}>{data.scac}</TableCell>
				<TableCell className={classes.tableCell}>{capitalizeFirstLetter(data.carrier_group)}</TableCell>
				<TableCell className={classes.tableCell}>
					{capitalizeFirstLetter(removeUnderScore(data.contract_type))}
				</TableCell>
				<TableCell className={classes.tableCell}>
					{capitalizeFirstLetter(removeUnderScore(data.service_type))}
				</TableCell>
				<TableCell className={classes.tableCell}>
					{data.uplift_net_charge ? `${data.uplift_net_charge}%` : '0%'}
				</TableCell>
				<TableCell className={classes.tableCell}>{data.min_margin ? `$${data.min_margin}` : '0'}</TableCell>
				<TableCell className={classes.tableCell}>
					{data.accessorials?.length > 0 ? buildCarrierAccessorialsTooltip(data.accessorials) : 'Default'}
				</TableCell>
				<TableCell className={classes.tableCell}>
					{Number(data.uplift_fuel_surcharge) !== 0 ? `${data.uplift_fuel_surcharge}%` : 'Default'}
				</TableCell>
			</>
		)
	}

	return (
		<>
			<TableRow
				className={classes.tableRow}
				hover
				role="checkbox"
				aria-checked={isItemSelected}
				tabIndex={-1}
				key={data.id}
				selected={isItemSelected}
			>
				{isSelectableList && (
					<TableCell padding="checkbox" align="center" style={{ width: 72 }}>
						<Checkbox
							inputProps={{ 'aria-labelledby': labelId }}
							checked={isItemSelected}
							className={classes.tableCheckbox}
							onClick={(event) => handleClick(event, data.id)}
							size="small"
							style={{
								color: isItemSelected ? theme.palette.secondary.main : theme.palette.primary.light,
							}}
						/>
					</TableCell>
				)}
				{showRadios && (
					<TableCell padding="checkbox" align="center" style={{ width: 72 }}>
						<FormControlLabel className={classes.formRadio} value={data.id} control={<Radio />} />
					</TableCell>
				)}
				{customTableRow()}
				{isSelectableList && (
					<>
						{isMainCarrierList ? (
							<>
								<TableCell className={classes.tableCell}>
									<IconButton aria-label="toggle action menu" onClick={handleOpenMenu} onMouseDown={null}>
										<MoreVertIcon style={{ color: '#2C2C2C' }} />
									</IconButton>
								</TableCell>
								<MenuList
									anchorEl={anchorEl}
									open={open}
									handleClose={handleClose}
									carrierId={data.id}
									carrierIdIntegracion={data.carrier_integration_id}
									carrierStatus={data.status}
									handleUpdateCarrierStatus={(carrierId, isActive) => {
										const ret = handleUpdateCarrierStatus(carrierId, isActive)
										if (ret) handleClose()
									}}
								/>
							</>
						) : (
							<TableCell align="center" className={classes.tableCell}>
								<Box className={classes.boxCarrier}>
									<Tooltip title="Edit">
										<IconButton
											aria-label="edit"
											color="primary"
											onClick={() => handleEditClick(isAccountCarrierList ? data.id : index)}
										>
											<EditOutlinedIcon fontSize="small" color="secondary" />
										</IconButton>
									</Tooltip>
									<Tooltip title="Delete">
										<IconButton
											aria-label="delete"
											color="primary"
											onClick={() => {
												handleDeleteClick(isAccountCarrierList ? data.id : index)
											}}
										>
											<DeleteOutlineOutlinedIcon fontSize="small" color="error" />
										</IconButton>
									</Tooltip>
								</Box>
							</TableCell>
						)}
					</>
				)}
			</TableRow>
		</>
	)
}

CarrierTableRow.defaultProps = {
	handleDeleteClick: () => {},
	handleUpdateCarrierStatus: () => {},
	showRadios: false,
}

CarrierTableRow.propTypes = {
	labelId: PropTypes.string.isRequired,
	isItemSelected: PropTypes.bool.isRequired,
	data: PropTypes.objectOf(PropTypes.any).isRequired,
	isSelectableList: PropTypes.bool.isRequired,
	isMainCarrierList: PropTypes.bool.isRequired,
	isAccountCarrierList: PropTypes.bool.isRequired,
	selected: PropTypes.instanceOf(Array).isRequired,
	setSelected: PropTypes.func.isRequired,
	handleDeleteClick: PropTypes.func,
	handleEditClick: PropTypes.func.isRequired,
	handleUpdateCarrierStatus: PropTypes.func,
	showRadios: PropTypes.bool,
}

export default CarrierTableRow
