import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'grid',
		gridAutoFlow: 'row',
		rowGap: '30px',
	},
	newUserIconTitle: {
		display: 'flex',
		columnGap: '12px',
		alignItems: 'center',
		[theme.breakpoints.down('md')]: {
			alignItems: 'flex-start',
		},
	},
	newUserIconContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '28px',
		height: '28px',
	},
	newUserSectionTitle: {
		fontSize: '18px',
		fontWeight: theme.typography.fontWeightRegular,
		color: theme.palette.primary.main,
		margin: 0,
		lineHeight: '22px',
		[theme.breakpoints.down('md')]: {
			fontSize: '15px',
		},
	},
	formControl: {
		width: '49%',
		'& .MuiInputBase-root': {
			height: '50px',
		},
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
	profileInput: {
		'&.MuiInputLabel-outlined': {
			backgroundColor: '#ffffff',
		},
	},
	innerContainer: {
		display: 'grid',
		gridAutoFlow: 'row',
		rowGap: '30px',
		paddingLeft: '16px',
		[theme.breakpoints.down('md')]: {
			paddingLeft: '0',
		},
	},
	checkBox: {
		color: '#CCD2D8',
	},
	checkBoxLabel: {
		'& span': {
			fontSize: '13px',
			fontWeight: 400,
		},
	},
	checkBoxesColumn: {
		display: 'grid',
		gridAutoFlow: 'row',
		marginLeft: '16px',
	},
	checkBoxColumn: {
		marginLeft: '8%',
		[theme.breakpoints.down('md')]: {
			marginLeft: '0',
		},
	},
}))

export default useStyles
