import React from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'

import './ShipmentInformation.scss'
import removeZDate from '../../../../utils/removeZDate'

const ShipperInformation = ({ data, type }) => {
	const renderAccesorial = () => {
		if (Array.isArray(data?.accesorials)) {
			const newAaccesorials = [...new Set(data?.accesorials)]
			if (newAaccesorials.length !== 0) return newAaccesorials.join(', ')
		}
		if (typeof data?.accesorials === 'string') {
			if (data?.accesorials.length !== 0) return data?.accesorials.trim().replace(/,\s*$/, '')
		}
		return 'None'
	}
	return (
		<div className="shipper-main-div">
			<div className="shipper-container">
				<div className="shipper-container-cell">
					<p>
						<span>{type === 'origin' ? 'Pickup: ' : 'Estimated Delivery: '}</span>
						{`${moment(removeZDate(data.pickUpDate)).format('MM/DD/YYYY')}, ${type === 'origin' ? data.pickupEarliestTime : data.dropOffEarliestTime}, ${type === 'origin' ? data.pickupLatestTime : data.dropOffLatestTime}`}
					</p>
					<p>{data.companyName}</p>
					<p>{`${data.address1}${data.address2 && `, ${data.address2}`}`}</p>
					<p>{`${data.city}, ${data.state}, ${data.zipCode}, ${data.country}`}</p>
					<p>
						<span>Contact: </span>
						{`${data.contactName}${data.contactPhone ? `, ${data.contactPhone}` : ''}${data.contactPhoneExtension ? `, Ext ${data.contactPhoneExtension}` : ''}${data.contactEmail ? `, ${data.contactEmail}` : ''}`}
					</p>
				</div>
			</div>
			<p className="shipper-accesorials">
				<span>{type === 'origin' ? 'Pickup Accessorials: ' : 'Delivery Accessorials: '}</span>
				{renderAccesorial()}
			</p>
		</div>
	)
}

ShipperInformation.propTypes = {
	data: PropTypes.objectOf(PropTypes.any).isRequired,
	type: PropTypes.string.isRequired,
}

export default ShipperInformation
