import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	sectionTitle: {
		fontWeight: theme.typography.fontWeightRegular,
		color: theme.palette.primary.main,
		margin: 0,
		lineHeight: '22px',
	},
	icon: {
		width: '20px',
		height: '20px',
		borderRadius: '20px',
		padding: '4px',
		alignSelf: 'center',

		'@media only screen and (min-width: 768px)': {
			width: '24px',
			height: '24px',
		},
	},
	shipmentInfo: {
		padding: '20px',
		background: theme.palette.neutral.white,
	},
	shipmentItem: {
		marginBottom: '20px',
	},
	shipmentTitleRow: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	iconTitle: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: '16px',
		'& svg': {
			fontSize: '32px',
			marginRight: '10px',
		},
	},
	sectionWrapper: {
		width: '100%',
	},
	shipmentTextButton: {
		fontWeight: 500,
		fontSize: 13,
		textTransform: 'none',
		justifyContent: 'flex-end',
	},
	shipmentIcon: {
		fontSize: theme.typography.button.fontFamily,
		marginRight: '5px',
	},
	shipmentIconContainer: {
		marginRight: '5px',
		marginBottom: '16px',
	},
	specialInstructions: {
		padding: '0 16px',
		margin: 0,
	},
	shipmentItemsContainer: {
		backgroundColor: theme.palette.neutral.whiteGray,
		padding: '18px 16px 20px 16px',

		[theme.breakpoints.up(768)]: {
			padding: '20px 24px',
		},
	},
	carrierInfoSection: {
		backgroundColor: theme.palette.neutral.whiteGray,
		padding: '18px 16px 20px 16px',

		[theme.breakpoints.up(768)]: {
			padding: '20px 24px',
		},
	},
}))

export default useStyles
