import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	invoiceContainer: {
		background: theme.palette.neutral.whiteGray,
		margin: '-16px -24px 0 -24px',
		padding: '16px 16px 16px 24px',
		[theme.breakpoints.down(768)]: {
			margin: '-16px -24px 0 -16px',
		},
	},
	status: {
		fontSize: 11,
		fontWeight: theme.typography.fontWeightRegular,
		color: theme.palette.neutral.white,
		padding: '0 14px',
	},
	statusRed: {
		backgroundColor: theme.palette.error.main,
	},
	statusOrange: {
		backgroundColor: theme.palette.warning.light,
		color: theme.palette.warning.dark,
	},
	statusGreen: {
		color: theme.palette.success.main,
		backgroundColor: theme.palette.success.light,
	},
	buttons: {
		display: 'flex',
		justifyContent: 'flex-end',
		width: '100%',
		background: theme.palette.neutral.white,
		[theme.breakpoints.down(768)]: {
			flexDirection: 'column',
		},
	},
	linkButton: {
		backgroundColor: theme.palette.secondary.main,
		borderRadius: '4px',
		padding: '13px 50px',
		color: '#FFF',
		fontSize: '17px',
		fontWeight: theme.typography.fontWeightMedium,
		textDecoration: 'none',
		textAlign: 'center',
		lineHeight: '30px',
		maxWidth: '200px',
		[theme.breakpoints.down(768)]: {
			maxWidth: '100%',
		},
	},
}))

export default useStyles
