import React from 'react'
import PropTypes from 'prop-types'

import { Grid, useMediaQuery } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import SaveIconOutlined from '@material-ui/icons/SaveOutlined'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

import { useDispatch } from 'react-redux'
import ShipperInformation from '../ShipperInformationStepTwo'
import ShipmentItems from '../ShipmentItemsStepTwo'
import ShipmentItemsSlider from '../ShipmentItemsSlider'
import ShipmentReferences from '../ShipmentReferencesStepTwo'
import ShipmentReferencesSlider from '../ShipmentReferencesSlider'
import ShipmentCarrierInformation from '../ShipmentCarrierInformation'
import {
	calculateTotalWeight,
	calculateTotalCubicFeet,
	calculateTotalDensity,
	calculateTotalItems,
} from '../../utils/helpers'

import { createLocation, editLocation } from '../../../../redux/actions/Locations.actions'
import { fetchDataFromZipCode } from '../../utils/googleMapsQueries'

import sweetAlertModal from '../../../../components/SweetAlertModal'

import { OriginIcon, DestinationIcon, ShipmentItemsIcon, SpecialInstructionsIcon } from '../../../../assets/Icons/Icons'
import './NewQuoteShipmentStepFour.scss'
import useStyles from './styles'
import { reqVerifyLocation } from '../../../../redux/requests/Locations.request'

const NewQuoteShipmentStepFour = ({ dataConfirm, isDetail, linearFeet }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const matches = useMediaQuery('(min-width: 768px)')
	const handleSaveLocation = async (type) => {
		const dataConfirmSelected = type === 'shipper' ? dataConfirm.shipper : dataConfirm.consignee
		const accessorials = [1, 4, 6]
		const id = type === 'shipper' ? dataConfirmSelected.shipperId : dataConfirmSelected.consigneeId
		const data = {}
		data.name = dataConfirmSelected.companyName
		data.type = type.toUpperCase()
		data.address = dataConfirmSelected.address1
		data.address2 = dataConfirmSelected.address2
		data.zip_code = dataConfirmSelected.zipCode
		const [, respGeoCoder] = await fetchDataFromZipCode(data.zip_code)
		data.longitude = respGeoCoder[0].longitude
		data.latitude = respGeoCoder[0].latitude
		data.city = dataConfirmSelected.city
		data.contact = dataConfirmSelected.contactName
		data.state = dataConfirmSelected.state
		data.country = dataConfirmSelected.country
		if (dataConfirmSelected.contactPhone) {
			data.phone = dataConfirmSelected.contactPhone.replace(/\D+/g, '')
		}
		data.email = dataConfirmSelected.contactEmail
		data.send_email = false
		data.send_sms = false
		data.open_time =
			type === 'shipper' ? dataConfirmSelected.pickupEarliestTime : dataConfirmSelected.dropOffEarliestTime
		data.close_time = type === 'shipper' ? dataConfirmSelected.pickupLatestTime : dataConfirmSelected.dropOffLatestTime

		const action = id ? 'update' : 'create'

		let status = null
		let resp
		if (action === 'create') {
			data.locationAccessorials = accessorials

			const [, { shipmentlocation }] = await reqVerifyLocation(dataConfirm.id, type.toUpperCase())

			let alertAnswer = {}
			if (shipmentlocation) {
				alertAnswer = await sweetAlertModal(
					'warning',
					'Are you sure you want to duplicate the location?',
					null,
					'Yes',
					true,
					true,
					'Cancel',
				)
			}

			if (!shipmentlocation || alertAnswer.isConfirmed) {
				const response = await dispatch(createLocation(data))
				status = response.status
				resp = response.resp
			}
		} else {
			const response = await dispatch(editLocation(id, data))
			status = response.status
			resp = response.resp
		}

		if (status === 'success') {
			const confirmText = action === 'update' ? 'Location updated successfully' : 'Location saved successfully'
			sweetAlertModal(status, confirmText, null, 'Ok', true, false, null)
		} else if (status) {
			sweetAlertModal(
				status,
				'Information is incomplete, It is not possible to create the location',
				resp,
				'Ok',
				true,
				false,
				null,
			)
		}
	}

	return (
		<div className={classes.sectionWrapper}>
			<div className={classes.shipmentInfo}>
				<Grid container spacing={6}>
					<Grid className={classes.shipmentItem} item md={6}>
						<div className={classes.shipmentTitleRow}>
							<div className={classes.iconTitle}>
								<OriginIcon />
								<h5 className={classes.sectionTitle}>Origin Information</h5>
							</div>
							{isDetail ? (
								<div className={classes.shipmentIconContainer}>
									<Button
										color="secondary"
										className={classes.shipmentTextButton}
										onClick={() => handleSaveLocation('shipper')}
									>
										<SaveIconOutlined className={classes.shipmentIcon} />
										Save location
									</Button>
								</div>
							) : null}
						</div>
						<ShipperInformation data={dataConfirm.shipper} type="origin" />
					</Grid>
					<Grid className={classes.shipmentItem} item md={6}>
						<div className={classes.shipmentTitleRow}>
							<div className={classes.iconTitle}>
								<DestinationIcon />
								<h5 className={classes.sectionTitle}>Destination Information</h5>
							</div>
							{isDetail ? (
								<div className={classes.shipmentIconContainer}>
									<Button
										color="secondary"
										className={classes.shipmentTextButton}
										onClick={() => handleSaveLocation('consignee')}
									>
										<SaveIconOutlined className={classes.shipmentIcon} />
										Save location
									</Button>
								</div>
							) : null}
						</div>
						<ShipperInformation data={dataConfirm.consignee} type="destination" />
					</Grid>
				</Grid>
			</div>
			<div className={classes.shipmentItemsContainer}>
				<div className={classes.iconTitle}>
					<ShipmentItemsIcon />
					<h5 className={classes.sectionTitle}>Shipment Items</h5>
				</div>
				{matches ? <ShipmentItems items={dataConfirm.items} /> : <ShipmentItemsSlider items={dataConfirm.items} />}
				<div className="stepTwo-total-values">
					<p className="stepTwo-total-title">
						Total # items:
						<span>{` ${calculateTotalItems(dataConfirm.items)}`}</span>
					</p>
					<p className="stepTwo-total-title">
						Total Weight:
						<span>{` ${calculateTotalWeight(dataConfirm.items)} lbs`}</span>
					</p>
					<p className="stepTwo-total-title">
						Total Linear Feet:
						<span>{` ${linearFeet}`}</span>
					</p>
					<p className="stepTwo-total-title">
						Total Cubic Feet:
						<span>{` ${calculateTotalCubicFeet(dataConfirm.items)}`}</span>
					</p>
					<p className="stepTwo-total-title">
						Total Density:
						<span>{` ${calculateTotalDensity(dataConfirm.items)}`}</span>
					</p>
				</div>
			</div>
			<div className="stepTwo-container" id="stepTwo-container-references-instructions">
				<div>
					<div className={classes.iconTitle}>
						<AssignmentOutlinedIcon color="primary" className={classes.icon} />
						<h5 className={classes.sectionTitle}>References</h5>
					</div>
					<ShipmentReferences references={dataConfirm.references} />
					<ShipmentReferencesSlider references={dataConfirm.references} />
				</div>
				<div>
					<div className={classes.iconTitle}>
						<SpecialInstructionsIcon />
						<h5 className={classes.sectionTitle}>Special Instructions for Carrier</h5>
					</div>
					<p className={classes.specialInstructions}>
						{dataConfirm?.specialInstructions?.comments
							? dataConfirm?.specialInstructions?.comments
							: 'No special instructions added'}
					</p>
				</div>
			</div>
			<div>
				<div className={classes.carrierInfoSection}>
					<div className={classes.iconTitle}>
						<InfoOutlinedIcon color="primary" className={classes.icon} />
						<h5 className={classes.sectionTitle}>Carrier Information</h5>
					</div>
					<ShipmentCarrierInformation
						quoteId={dataConfirm.quoteId}
						carrierData={dataConfirm.carrierData}
						contractData={dataConfirm.contractData}
					/>
				</div>
			</div>
		</div>
	)
}

NewQuoteShipmentStepFour.propTypes = {
	dataConfirm: PropTypes.objectOf(PropTypes.any).isRequired,
	isDetail: PropTypes.bool,
	linearFeet: PropTypes.string,
}

NewQuoteShipmentStepFour.defaultProps = {
	isDetail: false,
	linearFeet: '',
}

export default NewQuoteShipmentStepFour
