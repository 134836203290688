/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import propTypes from 'prop-types'
import clsx from 'clsx'
import { StepButton, Stepper, Step, StepConnector, StepLabel } from '@material-ui/core'
import useStyles, { styledStepperConnector, styledStepperIcon, styledStepperLabel } from './styles'

const StyledStepperIcon = (props) => {
	const classes = styledStepperIcon()
	const { active, completed, icon, onClick } = props
	return (
		<StepButton onClick={() => onClick(icon)}>
			<div
				className={clsx(classes.root, {
					[classes.active]: active,
					[classes.completed]: completed,
				})}
			>
				{icon}
			</div>
		</StepButton>
	)
}

StyledStepperIcon.defaultProps = {
	onClick: () => {},
}

StyledStepperIcon.propTypes = {
	active: propTypes.bool.isRequired,
	completed: propTypes.bool.isRequired,
	icon: propTypes.number.isRequired,
	onClick: propTypes.func,
}

const SimpleStepper = ({ activeStep, steps, onClick }) => {
	const classes = useStyles()
	const styledConector = styledStepperConnector()
	const styledLabel = styledStepperLabel()
	return (
		<div className={classes.root}>
			<div className={classes.stepperLabel}>{steps[activeStep - 1]}</div>
			<Stepper
				className={classes.styledStepper}
				alternativeLabel
				activeStep={activeStep}
				connector={<StepConnector classes={styledConector} />}
			>
				{steps.map((label) => {
					const stepProps = {}
					const labelProps = {}
					return (
						<Step key={label} {...stepProps} className={classes.defaultCursor}>
							<StepLabel
								{...labelProps}
								classes={{
									root: styledLabel.root,
									label: styledLabel.label,
								}}
								StepIconComponent={(props) => <StyledStepperIcon {...props} onClick={onClick} />}
							>
								{label}
							</StepLabel>
						</Step>
					)
				})}
			</Stepper>
		</div>
	)
}

SimpleStepper.defaultProps = {
	onClick: () => {},
}

SimpleStepper.propTypes = {
	activeStep: propTypes.number.isRequired,
	steps: propTypes.arrayOf(propTypes.string).isRequired,
	onClick: propTypes.func,
}

export default SimpleStepper
