import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TablePagination,
	TableRow,
	Paper,
	Button,
} from '@material-ui/core'
import { fetchTemplateItems, updatePaginator } from '../../../../redux/actions/TemplateItems.actions'
import SearchBar from '../../../../components/SearchBar'
import TableHeader from '../../../../components/TableHeader'
import TableRowSavedItems from './ModalSavedItemsTableRow/TableRowSavedItems'
import NoResultsFoundModal from '../../../../components/NoResultsFoundModal'
import sweetAlertModal from '../../../../components/SweetAlertModal'
import useStyles from './styles'
import './ModalSavedItems.scss'

const buildParamsFromState = (page, limit, searchParam) => {
	let queryParams = `page=${page}&limit=${limit}`
	if (searchParam !== '') {
		queryParams += `&search_bar=${searchParam}`
	}
	return queryParams
}

const ModalSavedItems = ({ open, onClose, setShipmentItems, dispatchSetShipmentItems, inputList }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const [searchBar, setSearchBar] = useState('')
	const [search, setSearch] = useState(searchBar)
	const [selected, setSelected] = useState({ id: null })
	const [page, setPage] = useState(0)
	const [isSearching, setIsSearching] = useState(false)
	const [backupPage, setBackupPage] = useState(0)
	const templateItemsListReducer = useSelector((state) => state.templateItems.templateItems)
	const isLoadingReducer = useSelector((state) => state.templateItems.isLoading)
	const totalRecordsReducer = useSelector((state) => state.templateItems.totalRecords)
	const pagSkipReducer = useSelector((state) => state.templateItems.pagSkip)
	const isSelected = (id) => selected.id === id
	const tableType = 'savedTemplateItems'
	const idleTime = 0.5
	const rowsPerPage = 10

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (search.length >= 2) {
				setSearchBar(encodeURIComponent(search))
			} else if (search.length === 0) {
				setSearchBar('')
			}
		}, idleTime * 1000)
		return () => clearTimeout(timeOutId)
	}, [search])

	useEffect(() => {
		const getTemplateItems = async (offset, limit) => {
			const filterParams = buildParamsFromState(offset, limit, searchBar)
			const params = `?${filterParams}`
			const { status } = await dispatch(fetchTemplateItems(params))
			if (status === 'error') sweetAlertModal('error', 'There was an error fetching items', null, 'Ok', true, false)
		}
		getTemplateItems(pagSkipReducer, rowsPerPage)
	}, [searchBar, dispatch, pagSkipReducer, open])

	const renderTableBody = () => {
		const data = templateItemsListReducer.map((row, index) => (
			<TableRowSavedItems
				key={row.id}
				labelId={`enhanced-table-checkbox-${index}`}
				isItemSelected={isSelected(row.id)}
				data={row}
				selected={selected}
				setSelected={setSelected}
			/>
		))
		if (isLoadingReducer && data.length === 0) {
			return <div className={classes.circularProgressTemplateItemsModal} />
		}
		if (!isLoadingReducer && data.length === 0) {
			const templateItemFiltersParams = buildParamsFromState(searchBar)
			return <NoResultsFoundModal type="Template Items" filters={templateItemFiltersParams} />
		}
		return data
	}

	const addDataToShipmentList = (itemSelected) => {
		const newShipmentItem = {
			id: inputList.length > 0 ? inputList.slice(-1)[0]?.id + 1 : 0,
			packageType: itemSelected.pieces_unit ? itemSelected.pieces_unit : null,
			quantity: itemSelected.pieces_value ? itemSelected.pieces_value : null,
			freightClass: itemSelected.class ? itemSelected.class : null,
			nmfc: itemSelected.nmfc
				? `${itemSelected.nmfc}${itemSelected.sub_nmfc ? `-${itemSelected.sub_nmfc}` : ''}`
				: null,
			sub_nmfc: itemSelected.sub_nmfc ? itemSelected.sub_nmfc : null,
			weight: itemSelected.weight_value ? itemSelected.weight_value : null,
			length: itemSelected.dimensions_lenght ? itemSelected.dimensions_lenght : null,
			width: itemSelected.dimensions_width ? itemSelected.dimensions_width : null,
			height: itemSelected.dimensions_height ? itemSelected.dimensions_height : null,
			commodity: itemSelected.description ? itemSelected.description : null,
			stackable: itemSelected.stackable ? itemSelected.stackable : false,
			hazardous: itemSelected.is_haz_mat ? itemSelected.is_haz_mat : false,
			hazmatData: {
				shippingName: itemSelected.haz_mat_shipping_name ? itemSelected.haz_mat_shipping_name : null,
				hazMatNumberType: itemSelected.haz_mat_number_type ? itemSelected.haz_mat_number_type : null,
				hazMatNumber: itemSelected.haz_mat_number ? itemSelected.haz_mat_number : null,
				hazMatClass: itemSelected.haz_mat_class ? itemSelected.haz_mat_class : null,
				hazMatGroup: itemSelected.haz_mat_group ? itemSelected.haz_mat_group : null,
				name: itemSelected.contact_info_name ? itemSelected.contact_info_name : null,
				phone: itemSelected.contact_info_phone ? itemSelected.contact_info_phone : '(000)000-0000',
			},
		}
		setShipmentItems(newShipmentItem)
		dispatchSetShipmentItems(newShipmentItem)
	}

	const handleSearchBarChangedTimeout = (event) => {
		const { value } = event.target
		if (!value && value.length === 0 && isSearching) {
			setPage(backupPage)
			setIsSearching(false)
			dispatch(updatePaginator(backupPage * rowsPerPage))
		}
		if (value && value.length !== 0 && !isSearching) {
			setPage(0)
			setIsSearching(true)
			dispatch(updatePaginator(0))
		}
		setSearch(value)
	}

	const handleChangePage = async (event, newPage) => {
		setPage(newPage)
		if (!isSearching) {
			setBackupPage(newPage)
		}
		dispatch(updatePaginator(newPage * rowsPerPage))
	}

	const handleCancel = async () => {
		setSearch('')
		setSelected({ id: null })
		setPage(0)
		setBackupPage(0)
		dispatch(updatePaginator(0))
		setIsSearching(false)
		onClose()
	}

	const handleSelect = async () => {
		if (selected.id) {
			addDataToShipmentList({ ...selected })
			handleCancel()
		}
		if (!selected.id) {
			sweetAlertModal('error', 'No item has been selected', 'Please, select one item', 'Ok', true, false)
		}
	}

	const emptyRows =
		!isLoadingReducer && templateItemsListReducer.length === 0
			? 0
			: rowsPerPage - Math.min(rowsPerPage, templateItemsListReducer.length * rowsPerPage)

	return (
		<Dialog
			className="modalDialog"
			onClose={handleCancel}
			aria-labelledby="simple-dialog-title"
			open={open}
			maxWidth="lg"
		>
			<DialogTitle className={classes.titleDialog} disableTypography id="simple-dialog-title">
				<h2>Search saved items</h2>
			</DialogTitle>
			<div className={classes.tableContainer}>
				<SearchBar
					value={search}
					autoFocus
					placeholder="Search for package type, freight class, NMFC code, product description..."
					onChangeHandler={handleSearchBarChangedTimeout}
				/>
				<Paper className={classes.paper}>
					<TableContainer className="tableContainer modalDialog">
						<Table className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
							<TableHeader
								classes={classes}
								numSelected={1}
								rowCount={templateItemsListReducer.length}
								tableType={tableType}
							/>
							<TableBody>
								{renderTableBody()}
								{emptyRows > 0 && (
									<TableRow style={{ height: 53 * emptyRows }}>
										<TableCell colSpan={6} style={{ borderBottom: 'none' }} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
				<TablePagination
					style={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}
					rowsPerPageOptions={[]}
					component="div"
					count={totalRecordsReducer}
					rowsPerPage={rowsPerPage}
					page={page}
					onChangePage={handleChangePage}
					backIconButtonProps={{
						disabled: isLoadingReducer || pagSkipReducer === 0,
					}}
					nextIconButtonProps={{
						disabled: isLoadingReducer || templateItemsListReducer.length !== rowsPerPage,
					}}
					labelDisplayedRows={({ from, to, count }) =>
						`${templateItemsListReducer.length === 0 ? 0 : from}-${to} of ${count !== -1 ? count : to}`
					}
				/>
				<Grid className={classes.gridMenu} container justify="flex-end" alignItems="center">
					<Button
						style={{ marginLeft: '1em' }}
						className={classes.previousButton}
						variant="contained"
						onClick={handleCancel}
					>
						Cancel
					</Button>
					<Button
						style={{ marginLeft: '1em' }}
						className={classes.button}
						variant="contained"
						color="secondary"
						onClick={handleSelect}
					>
						Add item
					</Button>
				</Grid>
			</div>
		</Dialog>
	)
}

ModalSavedItems.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	setShipmentItems: PropTypes.func.isRequired,
	inputList: PropTypes.arrayOf(PropTypes.any).isRequired,
	dispatchSetShipmentItems: PropTypes.func.isRequired,
}

export default ModalSavedItems
