import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
	map: (props) => ({
		width: '100%',
		height: props.height,
		marginBottom: '1%',
	}),
}))

export default useStyles
