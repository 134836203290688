import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	dialogPaper: {
		minHeight: '330px',
		minWidth: '80%',
		borderBottom: '6px solid',
		borderBottomColor: theme.palette.secondary.main,
		padding: '15px',
	},
	editItemModalTitle: {
		textAlign: 'center',
		color: theme.palette.secondary.main,
	},
	editItemModalIconsContainer: {
		marginTop: '10px',
		display: 'flex',
		justifyContent: 'space-around',
	},
	editItemModalAddButton: {
		height: '48px',
		alignSelf: 'center',
		textTransform: 'capitalize',
		fontWeight: theme.typography.fontWeightRegular,
		lineHeight: '21px',
		padding: '13px 50px',
		boxShadow: 'none',
		'@media only screen and (min-width: 768px) and (max-width: 1023px)': {
			fontSize: '11px',
		},
	},
	editItemModalCancelButton: {
		marginRight: '24px',
		height: '48px',
		alignSelf: 'center',
		textTransform: 'capitalize',
		lineHeight: '21px',
		fontWeight: theme.typography.fontWeightBold,
		padding: '13px 50px',
		boxShadow: 'none',
		'&:hover': {
			boxShadow: 'none',
		},
		'@media only screen and (min-width: 768px) and (max-width: 1023px)': {
			fontSize: '11px',
		},
	},
	ediItemModalActionButton: {
		fontSize: '14px',
		fontWeight: theme.typography.fontWeightRegular,
		textTransform: 'none',
	},
	helperText: {
		fontSize: '11px',
	},
	inputNumber: {
		'& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
			display: 'none',
			margin: 80,
		},
	},
}))

export default useStyles
