import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip, withStyles } from '@material-ui/core'

const HtmlTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: '#f5f5f9',
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 220,
		maxHeight: 270,
		overflowY: 'auto',
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid #dadde9',
	},
}))(Tooltip)

const ListNameCompaniesTooltip = ({ data, children }) => (
	<HtmlTooltip title={<>{data}</>} arrow interactive>
		{children}
	</HtmlTooltip>
)

ListNameCompaniesTooltip.propTypes = {
	data: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
}

export default ListNameCompaniesTooltip
