import { createSlice } from '@reduxjs/toolkit'
import reqCompanyUserInfo, { reqCompanyChildren } from '../requests/Company.request'

/* eslint no-param-reassign: ["error", { "props": false }] */

const companysSlice = createSlice({
	name: 'companysSlice',
	initialState: {
		parents: [],
		childs: [],
		isLoading: false,
		error: false,
	},
	reducers: {
		startLoading: (state) => {
			state.isLoading = true
		},
		hasError: (state, action) => {
			state.error = action.payload
			state.isLoading = false
		},
		parentsSuccess: (state, action) => {
			state.parents = action.payload
			state.isLoading = false
		},
		childsSuccess: (state, action) => {
			state.childs = action.payload
		},
	},
})

const { actions, reducer } = companysSlice

export const { startLoading, hasError, parentsSuccess, childsSuccess } = actions

export const fetchParentCompany = (id) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqCompanyUserInfo(id)

	if (error) {
		dispatch(hasError(error))
		console.log('call fetchParentCompany: error')
		return { status: 'error', resp: error }
	}
	dispatch(parentsSuccess(data.company))
	console.log('call fetchParentCompany: success')
	return { status: 'success' }
}

export const fetchChildsCompanies = (id) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqCompanyChildren(id)

	if (error) {
		dispatch(hasError(error))
		console.log('call fetchChildsCompanies: error')
		return { status: 'error', resp: error }
	}
	dispatch(childsSuccess(data.companies))
	console.log('call fetchChildsCompanies: success')
	return { status: 'success' }
}

export default reducer
