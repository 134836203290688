import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
	newQuoteContainer: {
		paddingBottom: '160px',
		margin: '-16px -24px',
		[theme.breakpoints.down('md')]: {
			paddingBottom: '330px',
			margin: '-16px',
		},
	},
}))
