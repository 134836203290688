import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Box, Typography, Button, Checkbox, useTheme } from '@material-ui/core'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'

import Carousel from 'react-multi-carousel'
import sweetAlertModal from '../../../../../components/SweetAlertModal'
import CarrierCard from '../CarrierCard'

import 'react-multi-carousel/lib/styles.css'
import useStyles from './style'

const CarriersSlider = (props) => {
	const classes = useStyles()
	const theme = useTheme()
	const { carriersList, setCarriersList, selected, setSelected, handleEditClick, isAccountCarrierList } = props

	const isSelected = (name) => selected.indexOf(name) !== -1
	const rowCount = carriersList.length

	const handleSelectAllClick = (event) => {
		const tempData = carriersList

		if (event.target.checked) {
			const newSelecteds = tempData.map((item) => item.id)
			setSelected(newSelecteds)
			return
		}
		setSelected([])
	}

	const handleDeleteClick = (index) => {
		const inputList = [...carriersList]
		inputList.splice(index, 1)
		setCarriersList(inputList)
		setSelected([])
		sweetAlertModal('success', 'Carrier was remove successfully', null, 'Ok', true, false)
	}

	const handleBulkDelete = () => {
		const inputList = [...carriersList]
		selected.forEach((elem) => {
			const carrierIndex = inputList.findIndex((carrier) => carrier.id === elem)
			inputList.splice(carrierIndex, 1)
		})
		setCarriersList(inputList)
		setSelected([])
		sweetAlertModal('success', 'The carriers were removed successfully', null, 'Ok', true, false)
	}

	const responsive = {
		tablet: {
			breakpoint: { max: 1024, min: 500 },
			items: 1,
			paritialVisibilityGutter: 50,
		},
		mobile: {
			breakpoint: { max: 500, min: 0 },
			items: 1,
			paritialVisibilityGutter: 30,
		},
	}

	return (
		<div className={classes.carriersSlider}>
			{carriersList.length ? (
				<Grid item xs={12}>
					<Box className={classes.toolBar}>
						{selected.length > 1 ? (
							<Grid container alignItems="center">
								<Grid item style={{ padding: '0px' }}>
									<Checkbox
										indeterminate={selected.length > 0 && selected.length < rowCount}
										checked={rowCount > 0 && selected.length === rowCount}
										onChange={handleSelectAllClick}
										inputProps={{ 'aria-label': 'select all accounts' }}
										style={{
											color:
												(rowCount > 0 && selected.length === rowCount) ||
												(selected.length > 0 && selected.length < rowCount)
													? theme.palette.secondary.light
													: theme.palette.primary.main,
											padding: '9px 12px 8px 4px',
										}}
										size="small"
									/>
								</Grid>
								<Grid item>
									<Typography className={classes.toolBarText}>{`(${selected.length} selected)`}</Typography>
								</Grid>
								<Grid item style={{ paddingLeft: '16px' }}>
									<Button
										classes={{
											root: classes.toolBarButton,
											iconSizeSmall: [classes.toolBarButtonIcon, classes.iconRed].join(' '),
										}}
										startIcon={<DeleteOutlineIcon color="error" />}
										size="small"
										onClick={() => {
											handleBulkDelete()
										}}
									>
										Remove Carriers
									</Button>
								</Grid>
							</Grid>
						) : (
							<Grid container>
								<Grid item>
									<Typography className={classes.toolBarText}>(Select items for bulk actions)</Typography>
								</Grid>
							</Grid>
						)}
					</Box>
				</Grid>
			) : null}
			<Carousel
				ssr
				arrows={false}
				responsive={responsive}
				showDots
				draggable
				renderDotsOutside
				dotListClass="shipment-items-slider-dots"
				focusOnSelect
			>
				{carriersList?.length > 0 &&
					carriersList.map((carrier, index) => {
						const isItemSelected = isSelected(carrier.id)
						return (
							<CarrierCard
								key={`id-carrier-${index + 1}`}
								carrierData={carrier}
								selected={selected}
								setSelected={setSelected}
								isItemSelected={isItemSelected}
								handleEditClick={handleEditClick}
								handleDeleteClick={handleDeleteClick}
								isAccountCarrierList={isAccountCarrierList}
							/>
						)
					})}
			</Carousel>
		</div>
	)
}

CarriersSlider.defaultProps = {
	selected: [],
	setSelected: () => {},
	handleEditClick: () => {},
	setCarriersList: () => {},
	isAccountCarrierList: false,
}

CarriersSlider.propTypes = {
	carriersList: PropTypes.arrayOf(PropTypes.any).isRequired,
	selected: PropTypes.arrayOf(PropTypes.any),
	setSelected: PropTypes.func,
	handleEditClick: PropTypes.func,
	setCarriersList: PropTypes.func,
	isAccountCarrierList: PropTypes.bool,
}

export default CarriersSlider
