import React, { useEffect, useState, useContext } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import CircularProgress from '@material-ui/core/CircularProgress'
import Chip from '@material-ui/core/Chip'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import moment from 'moment'
import { fetchQuoteDetails } from '../../redux/actions/QuoteDetails.actions'
import MenuList from '../MenuList'
import sweetAlertModal from '../SweetAlertModal'
import truckImg from '../../assets/img/truck.png'
import useStyles from './styles'
import SendQuotesModal from '../../modules/quotes/components/SendQuotesModal'
import useQuery from '../../hooks/useQuery'
import isPermit from '../../utils/permission'
import { permissionName } from '../../utils/constants'
import SessionContext from '../../context/session'
import { displayFormattedValue } from '../../utils/helpers'
import './styles.scss'
import removeZDate from '../../utils/removeZDate'

const QuoteRow = (props) => {
	const { getUserData } = useContext(SessionContext)
	const { permissions } = getUserData()
	const isRatePermission = isPermit(permissions, permissionName.FP_ADMIN_CARRIERS_SHOW_RATE)
	const theme = useTheme()
	const classes = useStyles()
	const {
		labelId,
		isItemSelected,
		data,
		selected,
		setSelected,
		search,
		openDetails,
		setOpenDetails,
		idQuoteOpened,
		setIdQuoteOpened,
	} = props
	const query = useQuery()
	const queryParamsId = query.get('id')
	const [anchorEl, setAnchorEl] = useState(null)
	const [quoteData, setQuoteData] = useState({})
	const [showEmailModal, setShowEmailModal] = useState(false)
	const dispatch = useDispatch()
	const open = Boolean(anchorEl)

	const handleOpenMenu = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleOpenDetailsClick = async () => {
		if (openDetails && idQuoteOpened === data.id) setOpenDetails(false)
		else {
			setOpenDetails(true)
			setIdQuoteOpened(data.id)
			const { status, resp } = await dispatch(fetchQuoteDetails(`/${data.id}`))
			if (status === 'error') {
				sweetAlertModal(status, 'There was an error fetching the quote details', null, 'Ok', true, false, null)
				setOpenDetails(false)
			} else {
				setQuoteData(resp)
			}
		}
	}
	useEffect(() => {
		const fechtInitial = async () => {
			if (queryParamsId && +queryParamsId === data.id && search === queryParamsId) {
				setOpenDetails(true)
				const { status, resp } = await dispatch(fetchQuoteDetails(`/${queryParamsId}`))
				if (status === 'error') {
					sweetAlertModal(status, 'There was an error fetching the quote details', null, 'Ok', true, false, null)
				} else {
					setQuoteData(resp)
				}
			}
		}
		fechtInitial()
	}, [data.id, dispatch, queryParamsId, search, setOpenDetails])

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name)
		let newSelected = []

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name)
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1))
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1))
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
		}
		setSelected(newSelected)
	}

	const renderStatus = (status) => {
		const statusesColors = {
			ACTIVE: classes.statusGreen,
			CANCELED: classes.statusOrange,
			CONVERTED: classes.statusPurple,
			EXPIRED: classes.statusRed,
			PENDING: classes.statusGray,
		}
		return (
			status && (
				<Chip
					size="small"
					className={[classes.status, statusesColors[status]].join(' ')}
					label={status.charAt(0) + status.slice(1).toLowerCase()}
				/>
			)
		)
	}

	const removeUnderscoreAndUppercase = (item) => {
		const itemWithourUnderscore = item.replace('_', ' ')
		return itemWithourUnderscore.charAt(0) + itemWithourUnderscore.slice(1).toLowerCase()
	}

	return (
		<>
			<TableRow
				className={classes.tableRow}
				hover
				role="checkbox"
				aria-checked={isItemSelected}
				tabIndex={-1}
				key={data.id}
				selected={isItemSelected}
			>
				<TableCell padding="checkbox">
					<Checkbox
						inputProps={{ 'aria-labelledby': labelId }}
						checked={isItemSelected}
						onClick={(event) => handleClick(event, data.id)}
						style={{
							color: isItemSelected ? theme.palette.secondary.main : theme.palette.primary.light,
						}}
					/>
				</TableCell>
				<TableCell
					component="th"
					id={labelId}
					scope="row"
					padding="none"
					onClick={handleOpenDetailsClick}
					className={[classes.tableCell, classes.tableCellNumber].join(' ')}
				>
					<strong>{data.id}</strong>
				</TableCell>
				<TableCell className={classes.tableCell}>
					{`${data.city_origin}, ${data.state_origin}, ${data.zipcode_origin}`}
				</TableCell>
				<TableCell className={classes.tableCell}>
					{`${data.city_destination}, ${data.state_destination}, ${data.zipcode_destination}`}
				</TableCell>
				<TableCell className={classes.tableCell}>{data.company_name}</TableCell>
				<TableCell className={classes.tableCell}>
					<div className={classes.imgItem}>
						<img src={data.carrier_logo || truckImg} alt="logo" className={classes.carrierLogo} />
						{data.carrier_name || 'Not assigned yet'}
					</div>
				</TableCell>
				<TableCell className={classes.tableCell}>
					{data.customer_rate
						? `$${(Number(data.customer_rate) + Number(data.insurance_value)).toFixed(2).replace(/[.,]00$/, '')} USD`
						: 'Not assigned yet'}
				</TableCell>
				<TableCell className={classes.tableCell}>{moment(data.audit_creation_date).format('MM/DD/YYYY')}</TableCell>
				<TableCell>{renderStatus(data.status)}</TableCell>
				<TableCell className={classes.tableCell}>
					<IconButton aria-label="toggle action menu" onClick={handleOpenMenu} onMouseDown={null}>
						<MoreVertIcon style={{ color: '#2C2C2C' }} />
					</IconButton>
				</TableCell>
			</TableRow>

			<TableRow style={{ backgroundColor: theme.palette.neutral.whiteGray }}>
				<TableCell style={{ padding: '0 30px', borderBottomWidth: openDetails ? 1 : 0 }} colSpan={11}>
					<Collapse in={openDetails && idQuoteOpened === data.id} timeout="auto" unmountOnExit>
						{Object.keys(quoteData).length !== 0 ? (
							<>
								<div className="quote-row-container">
									<p className="quote-row-item-title">
										Created date:
										<span className="quote-row-item-content">{` ${moment(removeZDate(quoteData.audit_creation_date)).format('MMMM Do YYYY')}`}</span>
									</p>
									<p className="quote-row-item-title">
										Pickup date:
										<span className="quote-row-item-content">{` ${moment(removeZDate(quoteData.est_pickup_date)).format('MM/DD/YYYY')}`}</span>
									</p>
									<p className="quote-row-item-title">
										Created by:
										<span className="quote-row-item-content"> {quoteData?.user?.name ?? 'N/A'}</span>
									</p>
									<IconButton onClick={() => setOpenDetails(false)} style={{ padding: '7px 0 0 0' }}>
										<ExpandLessIcon />
									</IconButton>
								</div>
								<div className="quote-row-container">
									<p className="quote-row-item-title" id="quote-row-accessorials">
										Reference(s):
										{quoteData.references.length > 0 ? (
											quoteData.references.map((item, index) => (
												<span key={`id-${index + 1}`} className="quote-row-item-content">
													{` ${removeUnderscoreAndUppercase(item.type)} ${item.value}${quoteData.references.length === index + 1 ? '' : ','} `}
												</span>
											))
										) : (
											<span> There are no references assigned</span>
										)}
									</p>
									{isRatePermission && quoteData.rate && (
										<p className="quote-row-item-title" id="quote-row-carrier-quote-number">
											Carrier Quote Number: <span>{quoteData.rate.carrier_quote_id}</span>
										</p>
									)}
								</div>
								<p className="quote-row-item-title" id="quote-row-accessorials">
									Accessorials:
									{quoteData.accesorials.length > 0 ? (
										quoteData.accesorials.map((item, index) => (
											<span key={`id-${index + 1}`} className="quote-row-item-content">
												{` ${removeUnderscoreAndUppercase(item.name)}${quoteData.accesorials.length === index + 1 ? '' : ','} `}
											</span>
										))
									) : (
										<span> There are no accessorials assigned</span>
									)}
								</p>
								<hr className="quote-row-hr" />
								<div className="quote-row-items">
									<p className="quote-row-item-title">#</p>
									<p className="quote-row-item-title">Package type</p>
									<p className="quote-row-item-title">Quantity</p>
									<p className="quote-row-item-title">Weight(Lb)</p>
									<p className="quote-row-item-title">Length(In)</p>
									<p className="quote-row-item-title">Width(In)</p>
									<p className="quote-row-item-title">Height(In)</p>
									<p className="quote-row-item-title">Product Description</p>
									<p className="quote-row-item-title">FreightClass</p>
									<p className="quote-row-item-title">NMFC code</p>
								</div>
								{quoteData.items
									? quoteData.items.map((item, index) => (
											<div key={`id-${index + 1}`}>
												<div className="quote-row-items">
													<span className="quote-row-item-title">{String.fromCharCode(65 + index)}</span>
													<span className="quote-row-item-content">{removeUnderscoreAndUppercase(item.unit_type)}</span>
													<span className="quote-row-item-content">{item.pieces_value}</span>
													<span className="quote-row-item-content">{item.weight_value}</span>
													<span className="quote-row-item-content">{item.dimensions_lenght}</span>
													<span className="quote-row-item-content">{item.dimensions_width}</span>
													<span className="quote-row-item-content">{item.dimensions_height}</span>
													<span className="quote-row-item-content">{item.description ? item.description : '-'}</span>
													<span className="quote-row-item-content">{item.class}</span>
													<span className="quote-row-item-content">
														{item.nmfc ? `${item.nmfc} ${item.sub_nmfc ? ` - ${item.sub_nmfc}` : ''}` : '-'}
													</span>
												</div>
											</div>
										))
									: null}
								{quoteData.rate ? (
									<>
										<hr className="quote-row-hr" />
										<div className="quote-row-uplift-container">
											<p className="quote-row-item-title">Detail</p>
											{isRatePermission && <p className="quote-row-item-title">Carrier Rate</p>}
											<p className="quote-row-item-title">Customer Rate</p>
										</div>
										<div className="quote-row-uplift-container">
											<p className="quote-row-item-content">Freight Quote</p>
											{isRatePermission && (
												<p className="quote-row-item-content">{`$${quoteData.rate.total_carrier?.toFixed(2).replace(/[.,]00$/, '')} USD`}</p>
											)}
											<p className="quote-row-item-content">{`$${quoteData.rate.total_customer?.toFixed(2).replace(/[.,]00$/, '')} USD`}</p>
										</div>
										<div className="quote-row-uplift-container">
											<p className="quote-row-item-content">{`3rd Party Insurance ($${displayFormattedValue(quoteData.rate.declared_freight_value)} USD)`}</p>
											{isRatePermission && (
												<p className="quote-row-item-content">
													{quoteData.rate.insurance_cost ? `$${quoteData.rate.insurance_cost} USD` : '$0 USD'}
												</p>
											)}
											<p className="quote-row-item-content">
												{quoteData.rate.insurance_value ? `$${quoteData.rate.insurance_value} USD` : '$0 USD'}
											</p>
										</div>
										<div className="quote-row-uplift-container" id="quote-row-total-rates">
											<p className="quote-row-item-title">Total:</p>
											{isRatePermission && (
												<p className="quote-row-item-title">{`$${(Number(quoteData.rate.total_carrier) + Number(quoteData.rate.insurance_cost)).toFixed(2).replace(/[.,]00$/, '')} USD `}</p>
											)}
											{quoteData.rate.insurance_value ? (
												<p className="quote-row-item-title">
													{`$${(Number(quoteData.rate.total_customer) + Number(quoteData.rate.insurance_value)).toFixed(2).replace(/[.,]00$/, '')} USD `}
												</p>
											) : (
												<p className="quote-row-item-title">{`$${quoteData.rate.total_customer} USD`}</p>
											)}
										</div>
									</>
								) : null}
							</>
						) : (
							<div className="quote-row-circular-progress">
								<CircularProgress />
							</div>
						)}
					</Collapse>
				</TableCell>
			</TableRow>
			<MenuList
				anchorEl={anchorEl}
				open={open}
				handleClose={handleClose}
				setOpenDetails={setOpenDetails}
				quoteId={data.id}
				rateId={data.rate_id}
				quoteStatus={data.status}
				setQuoteData={setQuoteData}
				handleOpenDetailsClick={handleOpenDetailsClick}
				setShowEmailModal={setShowEmailModal}
			/>
			<SendQuotesModal
				showEmailModal={showEmailModal}
				setShowEmailModal={setShowEmailModal}
				quoteId={data.id}
				rateId={data.rate_id}
			/>
		</>
	)
}

QuoteRow.propTypes = {
	labelId: PropTypes.string.isRequired,
	isItemSelected: PropTypes.bool.isRequired,
	data: PropTypes.objectOf(PropTypes.any).isRequired,
	selected: PropTypes.instanceOf(Array).isRequired,
	setSelected: PropTypes.func.isRequired,
	search: PropTypes.func.isRequired,
	openDetails: PropTypes.bool.isRequired,
	setOpenDetails: PropTypes.func.isRequired,
	idQuoteOpened: PropTypes.number.isRequired,
	setIdQuoteOpened: PropTypes.func.isRequired,
}

export default QuoteRow
