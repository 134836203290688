import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	shipmentItemsValuesContainer: {
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'nowrap',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
	},
	shipmentItemsValue: {
		marginRight: '10px',
		fontSize: '14px',
		fontWeight: theme.typography.fontWeightBold,
		color: '#A1A1A1',
		margin: '10px 0 0 0',
		'& span': {
			fontSize: '14px',
			color: '#A1A1A1',
			fontWeight: theme.typography.fontWeightRegular,
		},
	},
	valueLinearFeet: {
		fontSize: '14px',
		color: '#A1A1A1',
		fontWeight: theme.typography.fontWeightRegular,
	},
	linearFeetContainer: {
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'nowrap',
	},
	containerInput: {
		marginLeft: 3,
		display: 'flex',
		flexWrap: 'nowrap',
		margin: '10px 10px 0 0',
	},
	root: {
		fontSize: '14px',
		maxWidth: 60,
		'& .MuiFormLabel-root': {
			fontSize: '14px',
		},
		'& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
			display: 'none',
			margin: 80,
		},
		'& .MuiInputBase-input': {
			padding: '6px 2px 7px 2px',
		},
		'& .MuiInput-underline.Mui-disabled:before': {
			border: 'none',
		},
		'& .MuiInput-underline.Mui-disabled:after': {
			border: 'none',
		},
	},
}))

export default useStyles
