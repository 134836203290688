import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		height: '390px',
		borderBottom: '6px solid',
		borderBottomColor: theme.palette.secondary.main,
		padding: '0 20px',
		[theme.breakpoints.up('lg')]: {
			width: '500px',
		},
	},
	containerForm: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
	},
	title: {
		color: theme.palette.secondary.main,
		margin: '10px 0 0 0',
	},
	description: {
		fontSize: '16px',
		color: '#090E24',
		margin: '5px 0 0 0',
	},
	autoComplete: {
		margin: '3em 0 0 0',
		'& .MuiChip-label': {
			color: theme.palette.neutral.black,
			fontSize: 16,
		},
	},
	textFiled: {
		borderColor: theme.palette.secondary.light,
	},
	labelError: {
		fontSize: 16,
		marginTop: 5,
		color: theme.palette.primary.main,
	},
	containerButton: (props) => ({
		margin: `${props.error.length > 0 ? '2em' : '3em'} 0 0 0`,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		rowGap: '10px',
		padding: 0,
	}),
	sendButton: {
		width: '100%',
		height: '48px',
		alignSelf: 'center',
		textTransform: 'unset',
	},
	cancelButton: {
		width: '100%',
		height: '48px',
		alignSelf: 'center',
		backgroundColor: theme.palette.neutral.mainGray,
		color: theme.palette.neutral.black,
		textTransform: 'unset',
		'&:hover': {
			backgroundColor: '#b1b7bd',
		},
	},
	chipInput: {
		width: '100%',
		borderRadius: '50%',
		margin: '3em 0 0 0',
		'& .MuiInputBase-root': {
			display: 'block',
			position: 'static',
			minHeight: '60px',
			maxHeight: '100px',
			overflowX: 'hidden',
			overflowY: 'auto',
			padding: '10px 20px',
		},
		'& label': {
			background: 'white',
			padding: '0 2px',
		},
	},
	chipContainer: {
		marginBottom: 0,
	},
}))

export default useStyles
