/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import { TableCell } from '@material-ui/core'
import React from 'react'
import { SortListIcon, AscendingIcon, DescendingIcon } from '../../assets/Icons/Icons'
import useStyles from './styles'

const SortableTableHeader = ({ filters, handleSortColumn, columns, alignCells }) => {
	const classes = useStyles()

	const validation = (type) => {
		const orderValue = type === 1 ? ['asc', 'ASC'] : ['desc', 'DESC']

		return (
			filters.order === orderValue[0] ||
			filters.order === orderValue[1] ||
			// This case applies in the 'Accounts' filter
			(!filters.order && filters.orderType === orderValue[1])
		)
	}

	const showIcon = (cell) => {
		if (cell.id === filters.orderBy && validation(1)) {
			return <AscendingIcon onClick={() => handleSortColumn(cell.id)} style={{ alignSelf: 'center' }} />
		}
		if (cell.id === filters.orderBy && validation(2)) {
			return <DescendingIcon onClick={() => handleSortColumn(cell.id)} style={{ alignSelf: 'center' }} />
		}
		return <SortListIcon onClick={() => handleSortColumn(cell.id)} style={{ alignSelf: 'center' }} />
	}

	const SortableColumns = ({ cell }) => {
		let component = <div style={{ display: 'flex', alignItems: 'flex-end' }}>{cell.label}</div>
		if (cell.sortable) {
			component = (
				<div
					onClick={() => handleSortColumn(cell.id)}
					onKeyPress={() => handleSortColumn(cell.id)}
					className={classes.headCell}
					aria-hidden="true"
				>
					{cell.label}
					{showIcon(cell)}
				</div>
			)
		}

		return component
	}

	const align = (cell) => {
		if (alignCells) {
			return typeof alignCells === 'string' ? alignCells : alignCells(cell)
		}
		return undefined
	}

	return (
		<>
			{columns.map((cell, index) => (
				<TableCell key={`id-head-${cell.id}-${index + 1}`} align={align(cell)} className={classes.tableCell}>
					<SortableColumns cell={cell} />
				</TableCell>
			))}
		</>
	)
}

export default SortableTableHeader
