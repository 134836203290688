/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initState = {
	currentPage: 0,
	rowsPerPage: 10,
	status: [],
	searchBar: '',
	startDate: '',
	endDate: '',
	orderBy: '',
	order: '',
}

const quotesFilters = createSlice({
	name: 'quotesFilters',
	initialState: {
		...initState,
	},
	reducers: {
		setCurrentPage: (state, action) => {
			state.currentPage = action.payload
		},
		setRowsPerPage: (state, action) => {
			state.rowsPerPage = action.payload
		},
		setStatus: (state, action) => {
			state.status = action.payload
		},
		setSearchBar: (state, action) => {
			state.searchBar = action.payload
		},
		setStartDate: (state, action) => {
			state.startDate = action.payload
		},
		setEndDate: (state, action) => {
			state.endDate = action.payload
		},
		setOrderByAndOrder: (state, action) => {
			const { orderBy, order } = action.payload
			state.orderBy = orderBy
			state.order = order
		},
		resetFilters: (state) => {
			state.currentPage = initState.currentPage
			state.rowsPerPage = initState.rowsPerPage
			state.status = initState.status
			state.searchBar = initState.searchBar
			state.startDate = initState.startDate
			state.endDate = initState.endDate
			state.orderBy = initState.orderBy
			state.order = initState.order
		},
	},
})

export const {
	setCurrentPage,
	setRowsPerPage,
	setStatus,
	setSearchBar,
	setEndDate,
	setStartDate,
	setOrderByAndOrder,
	resetFilters,
} = quotesFilters.actions

const { reducer } = quotesFilters
export default reducer
