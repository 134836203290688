import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	moreIcon: {
		'@media only screen and (min-width: 1024px)': {
			display: 'none',
		},
	},
	icon: {
		fontSize: 24,
		color: theme.palette.primary.main,
	},
	checkCircle: {
		color: theme.palette.semantic.green.primary,
		marginRight: 7,
	},
	listItemIcon: {
		minWidth: 'auto',
		marginRight: 7,
	},
	toolBar: {
		display: 'flex',
		alignItems: 'center',
		fontSize: '13px',
		margin: '17px 0 14px 0',
		'@media (max-width: 1024px)': {
			margin: '19px 0 8px 0',
			width: '100%',
			justifyContent: 'space-between',
		},
	},
	toolbarSelected: {
		fontSize: '14px',
		fontWeight: 400,
		textAlign: 'start',
		margin: 0,
		'@media only screen and (min-width: 1024px)': {
			fontSize: '16px',
		},
	},
	toolbarItemIcon: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: '24px',
		cursor: 'pointer',
		'@media only screen and (max-width: 1024px)': {
			display: 'none',
		},
	},
}))

export default useStyles
