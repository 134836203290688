import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Menu, MenuItem, withStyles, ListItemIcon, ListItemText } from '@material-ui/core'
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import { OriginPointIcon, DestinationPointIcon } from '../../../../../assets/Icons/Icons'
import sweetAlertModal from '../../../../../components/SweetAlertModal/SweetAlertModal'
import { editLocationType, fetchLocations } from '../../../../../redux/actions/Locations.actions'

import DeleteLocationModal from '../../containers/DeleteLocationModal'
import useLocationsFilters from '../../../../../hooks/useLocationsFilters'

import useStyles from './style'

const StyledMenuItem = withStyles((theme) => ({
	root: {
		'&:hover': {
			backgroundColor: theme.palette.neutral.whiteGray,
		},
	},
}))(MenuItem)

const MenuList = (props) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
	const [buildParamsFromState] = useLocationsFilters()
	const filterParams = buildParamsFromState()
	const { anchorEl, open, handleClose, locationId, locationType, handleEditLocation } = props

	const handleDeleteLocation = async () => {
		handleClose()
		setOpenConfirmationModal(true)
	}

	const handleDefaultOrigin = async (type, status) => {
		const originDestination = type === 'SHIPPER' ? 'origin' : 'destination'
		handleClose()
		sweetAlertModal(
			'warning',
			`Are you sure you want to override the current default ${originDestination} location?`,
			null,
			'Yes',
			true,
			true,
			'No',
		).then((result) => {
			if (result.isConfirmed) {
				;(async () => {
					const { status: statusRequest } = await dispatch(editLocationType(locationId, type, status))
					if (statusRequest === 'error') {
						sweetAlertModal('error', 'There was an error updating the location', null, 'Ok', true, false)
					} else {
						sweetAlertModal(statusRequest, 'Location has been updated successfully!', null, 'Ok', true, false, false)
						await dispatch(fetchLocations(filterParams))
					}
				})()
			}
		})
	}

	return (
		<>
			<Menu id="long-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
				<StyledMenuItem onClick={handleEditLocation}>
					<ListItemIcon className={classes.listItemIcon}>
						<CreateOutlinedIcon className={classes.icon} />
					</ListItemIcon>
					<ListItemText primary="Edit location" />
				</StyledMenuItem>
				<StyledMenuItem onClick={() => handleDeleteLocation()}>
					<ListItemIcon className={classes.listItemIcon}>
						<DeleteOutlinedIcon className={classes.iconDelete} />
					</ListItemIcon>
					<ListItemText primary="Delete location" />
				</StyledMenuItem>
				{locationType === 'SHIPPER' ? (
					<StyledMenuItem onClick={() => handleDefaultOrigin('SHIPPER', 'DEACTIVE')}>
						<ListItemIcon className={classes.listItemIcon}>
							<OriginPointIcon className={classes.icon} color="primary" />
						</ListItemIcon>
						<ListItemText primary="Deactivate as Default Origin" />
					</StyledMenuItem>
				) : (
					<StyledMenuItem onClick={() => handleDefaultOrigin('SHIPPER', 'ACTIVE')}>
						<ListItemIcon className={classes.listItemIcon}>
							<OriginPointIcon className={classes.icon} color="primary" />
						</ListItemIcon>
						<ListItemText primary="Set as Default Origin" />
					</StyledMenuItem>
				)}
				{locationType === 'CONSIGNEE' ? (
					<StyledMenuItem onClick={() => handleDefaultOrigin('CONSIGNEE', 'DEACTIVE')}>
						<ListItemIcon className={classes.listItemIcon}>
							<DestinationPointIcon className={classes.icon} color="primary" />
						</ListItemIcon>
						<ListItemText primary="Deactivate as Default Destination" />
					</StyledMenuItem>
				) : (
					<StyledMenuItem onClick={() => handleDefaultOrigin('CONSIGNEE', 'ACTIVE')}>
						<ListItemIcon className={classes.listItemIcon}>
							<DestinationPointIcon className={classes.icon} color="primary" />
						</ListItemIcon>
						<ListItemText primary="Set as Default Destination" />
					</StyledMenuItem>
				)}
			</Menu>
			<DeleteLocationModal
				openConfirmationModal={openConfirmationModal}
				setOpenConfirmationModal={setOpenConfirmationModal}
				locationId={locationId}
			/>
		</>
	)
}

MenuList.propTypes = {
	anchorEl: PropTypes.objectOf(PropTypes.any),
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	handleEditLocation: PropTypes.func.isRequired,
	locationId: PropTypes.number.isRequired,
	locationType: PropTypes.string.isRequired,
}

MenuList.defaultProps = { anchorEl: null }

export default MenuList
