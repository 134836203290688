/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

/* Tab content
  tab = {
    id,
    title,
    route,
    origin,
    destination,
    step: 2,
    details: {
      data: {
      }
    }
  }
*/

const multiTabsSlice = createSlice({
	name: 'multiTabsSlice',
	initialState: {
		tabs: [],
	},
	reducers: {
		addTab: (state, action) => {
			state.tabs = [...state.tabs, action.payload]
		},
		clearTab: (state) => {
			state.tabs = state.tabs.map((tab) => {
				if (tab.active) {
					tab.status = ''
					tab.id = ''
					tab.route = ''
					tab.origin = ''
					tab.destination = ''
					tab.step = 1
					tab.details = {}
				}
				return tab
			})
		},
		setTabStatus: (state, action) => {
			state.tabs.map((tab) => {
				if (tab.active) {
					tab.status = action.payload
				}
				return tab
			})
		},
		setTabChildAccounts: (state, action) => {
			state.tabs.map((tab) => {
				if (tab.active) {
					tab.accounts = action.payload
				}
				return tab
			})
		},
		setTabAccountSettings: (state, action) => {
			state.tabs.map((tab) => {
				if (tab.active) {
					tab.accounts.settings = action.payload
				}
				return tab
			})
		},
		setTabAccountReferences: (state, action) => {
			state.tabs.map((tab) => {
				if (tab.active) {
					tab.accounts.references = action.payload
				}
				return tab
			})
		},
		setTabAccountSpecialInstructions: (state, action) => {
			state.tabs.map((tab) => {
				if (tab.active) {
					tab.accounts.specialInstructions = action.payload
				}
				return tab
			})
		},
		setTabOrigin: (state, action) => {
			state.tabs.map((tab) => {
				if (tab.active) {
					tab.origin = action.payload
				}
				return tab
			})
		},
		setTabCurrentRoute: (state, action) => {
			state.tabs.map((tab) => {
				if (tab.active) {
					tab.route = action.payload
				}
				return tab
			})
		},
		setTabDestination: (state, action) => {
			state.tabs.map((tab) => {
				if (tab.active) {
					tab.destination = action.payload
				}
				return tab
			})
		},
		setTabQuoteId: (state, action) => {
			state.tabs.map((tab) => {
				if (tab.active) {
					tab.id = action.payload
				}
				return tab
			})
		},
		setTabTitle: (state, action) => {
			state.tabs.map((tab) => {
				if (tab.active) {
					tab.title = action.payload
				}
				return tab
			})
		},
		setTabContent: (state, action) => {
			state.tabs.map((tab) => {
				if (tab.active) {
					tab.details = action.payload
				}
				return tab
			})
		},
		setTabs: (state, action) => {
			state.tabs = action.payload
		},
		setTabRates: (state, action) => {
			state.tabs.map((tab) => {
				if (tab.active) {
					tab.details.rates = action.payload
				}
				return tab
			})
		},
		setTabStep: (state, action) => {
			state.tabs.map((tab) => {
				if (tab.active) {
					tab.step = action.payload
				}
				return tab
			})
		},
		setTabStatusData: (state, action) => {
			state.tabs.map((tab) => {
				if (tab.active) {
					tab.status = action.payload
				}
				return tab
			})
		},
		setTabOriginData: (state, action) => {
			state.tabs.map((tab) => {
				if (tab.active) {
					tab.details.originData = action.payload
				}
				return tab
			})
		},
		setTabDestinationData: (state, action) => {
			state.tabs.map((tab) => {
				if (tab.active) {
					tab.details.destinationData = action.payload
				}
				return tab
			})
		},
		setTabOriginAccessorials: (state, action) => {
			state.tabs.map((tab) => {
				if (tab.active) {
					tab.details.originAccessorials = action.payload
				}
				return tab
			})
		},
		setTabDestinationAccessorials: (state, action) => {
			state.tabs.map((tab) => {
				if (tab.active) {
					tab.details.destinationAccessorials = action.payload
				}
				return tab
			})
		},
		setTabShipmentItems: (state, action) => {
			state.tabs.map((tab) => {
				if (tab.active) {
					if (tab.details?.items?.length > 0) {
						tab.details?.items?.push(action.payload)
					} else {
						tab.details.items = [action.payload]
					}
				}
				return tab
			})
		},
		setTabDefaultShipmentItems: (state, action) => {
			state.tabs.map((tab) => {
				if (tab.active) {
					if (tab.details?.items?.length === 0 || Object.keys(tab.details).length === 0) {
						tab.details.items = action.payload
					}
				}
				return tab
			})
		},
		setTabIsNewQuote: (state, action) => {
			state.tabs.map((tab) => {
				if (tab.active) {
					tab.isNewQuote = action.payload
				}
				return tab
			})
		},
		setTabReferences: (state, action) => {
			state.tabs.map((tab) => {
				if (tab.active) {
					if (tab.details?.references?.length > 0) {
						tab.details?.references?.push(action.payload)
					} else {
						tab.details.references = [action.payload]
					}
				}
				return tab
			})
		},
	},
})

export const {
	addTab,
	setTabCurrentRoute,
	setTabDestination,
	setTabStatus,
	setTabOrigin,
	setTabTitle,
	tabClosed,
	setTabs,
	setTabContent,
	setAccessorials,
	setTabShipmentItems,
	setTabReferences,
	setTabStatusData,
	setTabOriginData,
	setTabDestinationData,
	setTabOriginAccessorials,
	setTabDestinationAccessorials,
	setTabQuoteId,
	setTabStep,
	setTabRates,
	setTabChildAccounts,
	clearTab,
	setTabAccountSettings,
	setTabAccountReferences,
	setTabAccountSpecialInstructions,
	setTabDefaultShipmentItems,
	setTabIsNewQuote,
} = multiTabsSlice.actions
export const { actions, reducer } = multiTabsSlice
export default reducer
