import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	table: {
		border: '1px solid',
		borderColor: theme.palette.neutral.lightGray,
	},
	tableRow: {
		'&.Mui-selected, &.Mui-selected:hover': {
			backgroundColor: '#FFFFFF',
			border: '1.5px solid',
			borderColor: theme.palette.secondary.light,
			boxShadow: `0 0 10px 0px ${theme.palette.secondary.main}`,
		},
	},
	tableCell: {
		fontSize: '14px',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		width: '350px',
		maxWidth: '350px',
		minHeight: '55px',
	},
	tableCellHeader: {
		backgroundColor: theme.palette.neutral.whiteGray,
		fontSize: 12,
		fontWeight: theme.typography.fontWeightBold,
		lineHeight: '18px',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		width: '350px',
		maxWidth: '350px',
		minHeight: '60px',
	},
	tableCellNumber: {
		textDecoration: 'underline',
		fontSize: '14px',
		cursor: 'pointer',
	},
	imgItem: {
		display: 'flex',
		justifyContent: 'left',
		gap: '12px',
	},
	carrierLogo: {
		width: '37px',
		height: '28px',
		alignSelf: 'center',
	},
	statusBlue: {
		fontSize: '11px',
		fontWeight: '400',
		color: '#FFFFFF',
		backgroundColor: '#293F95',
		borderRadius: '9.5px',
		padding: '3px 7px',
	},
	statusRed: {
		fontSize: '11px',
		fontWeight: '400',
		color: '#FFFFFF',
		backgroundColor: '#EC3514',
		borderRadius: '9.5px',
		padding: '3px',
	},
	statusOrange: {
		fontSize: '11px',
		fontWeight: '400',
		color: '#FFFFFF',
		backgroundColor: 'orange',
		borderRadius: '9.5px',
		padding: '3px',
	},
	statusGray: {
		fontSize: '11px',
		fontWeight: '400',
		backgroundColor: '#EBEEF1',
		borderRadius: '9.5px',
		padding: '3px 7px',
	},
	statusGreen: {
		fontSize: '11px',
		fontWeight: '400',
		color: '#FFFFFF',
		backgroundColor: '#00C48C',
		borderRadius: '9.5px',
		padding: '3px 0',
	},
	button: {
		width: '175px',
		height: '48px',
		fontSize: '17px',
		fontWeight: '600',
		color: '#FFFFFF',
		backgroundColor: theme.palette.secondary.main,
		margin: theme.spacing(0, 0, 0),
		alignSelf: 'end',
		textTransform: 'none',
		'&:hover': {
			backgroundColor: theme.palette.secondary.dark,
		},
		'@media only screen and (max-width: 768px)': {
			width: '140px',
		},
		[theme.breakpoints.down('sm')]: {
			minWidth: 175,
		},
	},
	previousButton: {
		width: '175px',
		height: '48px',
		fontSize: '17px',
		fontWeight: '600',
		color: '#2C2C2C',
		backgroundColor: '#CCD2D8',
		margin: theme.spacing(0, 0, 0),
		alignSelf: 'end',
		textTransform: 'none',
		'@media only screen and (max-width: 768px)': {
			width: '140px',
		},
		[theme.breakpoints.down('sm')]: {
			minWidth: 175,
		},
		[theme.breakpoints.down(583)]: {
			marginBottom: 10,
		},
	},
	cancelLocationButton: {
		color: '#F30000',
		cursor: 'pointer',
		fontWeight: '600',
	},
	chipChecked: {
		border: '2px solid #EC3514',
		color: '#EC3514',
		fontWeight: 600,
	},
	grid: {
		display: 'grid',
		gridAutoFlow: 'column',
		gridTemplateColumns: '323px repeat(2, 1fr)',
		padding: '10px 120px 0 0',
	},
	container: {
		maxHeight: 440,
	},
	gridMenu: {
		paddingTop: 0,
		paddingBottom: 20,
	},
	modalDialog: {
		borderRadius: '5px',
		borderBottom: '6px solid',
		borderBottomColor: theme.palette.secondary.main,
		padding: '0 50px',
		[theme.breakpoints.down('sm')]: {
			marginTop: '10px',
			padding: '20px 50px',
		},
	},
	titleDialog: {
		textAlign: 'center',
		'& h2': {
			margin: 0,
			color: theme.palette.secondary.main,
		},
	},
	searchBarContainer: {
		margin: theme.spacing(0.5, 0, 1),
	},
}))

export default useStyles
