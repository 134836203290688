import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
	Menu,
	MenuItem,
	ListItemIcon,
	ListItemText,
	Grid,
	Typography,
	Box,
	Button,
	useMediaQuery,
	IconButton,
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { useTheme } from '@material-ui/core/styles'
import useStyles from './style'

const TableToolbar = ({ selected, handleBulkDelete, isMainCarrierList, handleBulkUpdateStatus }) => {
	const classes = useStyles()
	const theme = useTheme()
	const matchesMD = useMediaQuery(theme.breakpoints.up('md'))
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)

	const handleOpenMenu = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	return (
		<>
			{matchesMD && (
				<Box className={classes.toolBar}>
					{selected.length > 1 ? (
						<Grid container alignItems="center" spacing={3}>
							<Grid item>
								<Typography className={classes.toolBarText}>{`(${selected.length} selected)`}</Typography>
							</Grid>
							{isMainCarrierList ? (
								<>
									<Grid item>
										<Button
											classes={{
												root: classes.toolBarBurton,
												iconSizeSmall: [classes.toolBarButtonIcon, classes.iconGreen].join(' '),
											}}
											startIcon={<CheckCircleOutlineOutlinedIcon />}
											size="small"
											onClick={() => {
												handleBulkUpdateStatus('true')
											}}
										>
											Activate carriers
										</Button>
									</Grid>
									<Grid item>
										<Button
											classes={{
												root: classes.toolBarBurton,
												iconSizeSmall: [classes.toolBarButtonIcon, classes.iconRed].join(' '),
											}}
											startIcon={<NotInterestedIcon />}
											size="small"
											onClick={() => {
												handleBulkUpdateStatus('false')
											}}
										>
											Deactivate carriers
										</Button>
									</Grid>
								</>
							) : (
								<Grid item>
									<Button
										classes={{
											root: classes.toolBarBurton,
											iconSizeSmall: [classes.toolBarButtonIcon, classes.iconRed].join(' '),
										}}
										startIcon={<DeleteOutlineIcon color="error" />}
										size="small"
										onClick={() => {
											handleBulkDelete()
										}}
									>
										Remove Carriers
									</Button>
								</Grid>
							)}
						</Grid>
					) : (
						<Grid container>
							<Grid item>
								<Typography className={classes.toolBarText}>(Select items for bulk actions)</Typography>
							</Grid>
						</Grid>
					)}
				</Box>
			)}
			{!matchesMD && (
				<Box className={classes.toolBar}>
					{selected.length > 1 ? (
						<Grid container alignItems="center" justify="space-between">
							<Grid item>
								<Typography className={classes.toolBarText}>{`(${selected.length} selected)`}</Typography>
							</Grid>
							<Grid item>
								<IconButton
									className={[classes.toolBarBurton, classes.toolBarButtonIconMore]}
									size="small"
									edge="end"
									onClick={handleOpenMenu}
								>
									<MoreVertIcon />
								</IconButton>
								<Menu id="accounts-bulk-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
									<MenuItem
										dense
										onClick={() => {
											handleBulkDelete()
										}}
									>
										<ListItemIcon className={classes.listItemIcon}>
											<DeleteOutlineIcon className={classes.iconRed} size="small" />
										</ListItemIcon>
										<ListItemText primary="Remove Carriers" />
									</MenuItem>
								</Menu>
							</Grid>
						</Grid>
					) : (
						<Grid container>
							<Grid item>
								<Typography className={classes.toolBarText}>(Select accounts for bulk actions)</Typography>
							</Grid>
						</Grid>
					)}
				</Box>
			)}
		</>
	)
}

TableToolbar.propTypes = {
	selected: PropTypes.arrayOf(PropTypes.any).isRequired,
	handleBulkDelete: PropTypes.func.isRequired,
	isMainCarrierList: PropTypes.bool.isRequired,
	handleBulkUpdateStatus: PropTypes.func.isRequired,
}

export default TableToolbar
