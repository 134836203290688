import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
// import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import useStyles from './styles'
import TabPanel from '../../../../../components/TabPanel'
import AccountInformationContainer from '../../containers/AccountInformationContainer'
import AccountSettingsContainer from '../../containers/AccountSettingsContainer'
import CarrierListContainer from '../../containers/CarrierListContainer/CarrierListContainer'
import UsersListContainer from '../../containers/UsersListContainer/UsersListContainer'

const AntTabs = withStyles((theme) => ({
	root: {
		borderBottom: '1px solid #e8e8e8',
		padding: '0px 24px',
		[theme.breakpoints.down('sm')]: {
			padding: '0px 16px',
		},
	},
	indicator: {
		backgroundColor: theme.palette.secondary.main,
	},
	// eslint-disable-next-line react/jsx-props-no-spreading
}))((props) => <Tabs disableRipple {...props} />)
const AntTab = withStyles((theme) => ({
	root: {
		textTransform: 'none',
		fontSize: 16,
		minWidth: 72,
		fontWeight: 400,
		marginRight: theme.spacing(4),
		fontFamily: 'Open Sans',
		opacity: 1,
		color: '#2C2C2C',
		'&:hover': {
			opacity: 1,
		},
		'&$selected': {
			fontWeight: theme.typography.fontWeightBold,
		},
		'&:focus': {
			fontWeight: theme.typography.fontWeightBold,
		},
	},
	selected: {},
	// eslint-disable-next-line react/jsx-props-no-spreading
}))((props) => <Tab disableRipple {...props} />)
const MenuTabs = (props) => {
	const { handleChangeTab, value, accountInformationParent, accountInformationChilds } = props
	const classes = useStyles()
	const handleChange = (event, newValue) => {
		handleChangeTab(newValue)
	}
	return (
		<>
			<div className={classes.tabsWrapper}>
				<div className={classes.root}>
					<div className={classes.demo1}>
						<AntTabs
							value={value}
							variant="scrollable"
							scrollButtons="auto"
							onChange={handleChange}
							aria-label="ant example"
						>
							<AntTab label="Account information" />
							<AntTab label="Settings" />
							<AntTab label="Users" />
							<AntTab label="Carriers" />
						</AntTabs>
						<Typography className={classes.padding} />
						<TabPanel value={value} index={0}>
							<AccountInformationContainer
								accountInformationParent={accountInformationParent}
								accountInformationChilds={accountInformationChilds}
							/>
						</TabPanel>
						<TabPanel value={value} index={1}>
							<AccountSettingsContainer />
						</TabPanel>
						<TabPanel value={value} index={2}>
							<UsersListContainer />
						</TabPanel>
						<TabPanel value={value} index={3}>
							<CarrierListContainer />
						</TabPanel>
					</div>
				</div>
			</div>
		</>
	)
}
MenuTabs.propTypes = {
	handleChangeTab: PropTypes.func.isRequired,
	value: PropTypes.number.isRequired,
	accountInformationParent: PropTypes.objectOf(PropTypes.any).isRequired,
	accountInformationChilds: PropTypes.arrayOf(PropTypes.any).isRequired,
}
export default MenuTabs
