import React, { useEffect, useState, createRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Chip from '@material-ui/core/Chip'
import SimpleStepper from '../../../../components/SimpleStepper'
import { setTabCurrentRoute, setTabTitle, setTabStep } from '../../../../redux/actions/MultiTabs.actions'
import { resetShiplifyModalState, updateDataForm } from '../../../../redux/actions/Shiplify.actions'
import { createShipmentsRoute } from '../../../../utils/constants'
import NewQuoteStepsContainer from '../NewQuoteStepsContainer/NewQuoteStepsContainer'
import NewShipmentStepsContainer from '../NewShipmentStepsContainer/NewShipmentStepsContainer'

import './StepperContainer.scss'
import useStyles from './styles'
// eslint-disable-next-line import/no-unresolved
import ModuleHeader from '../../../../components/ModuleHeader/index'

const newQuoteStep1Title = 'New Quote'
const newQuoteStep2Title = 'Rate Results'
const newShipmentStep3Title = 'New Shipment'
const newShipmentStep4Title = 'Review Shipment'
const newQuoteStep1Description = 'Insert information about the lane, items and references of your new quote.'
const newQuoteStep2Description = 'Please see below for rate results and estimated transit times for this shipment.'
const newShipmentStep3Description = "Set up the last details of your shipment, and you'd like to book it."
const newShipmentStep4Description = 'Confirm shipment details are accurate before it is booked. '
const newShipmentStep5Description = 'Your shipment has been processed.'
const steps = ['Quote information', 'Select Carrier', 'Shipment Details', 'Review & Payment', 'Confirmation']

const StepperContainer = () => {
	const classes = useStyles()
	const [activeStep, setActiveStep] = useState(1)
	const { quoteDetailsToShipment } = useSelector((state) => state.quoteDetails)
	const [stepTitle, setStepTitle] = useState(newQuoteStep1Title)
	const [stepDescription, setStepDescription] = useState(newQuoteStep1Description)
	const onSubmitStep12 = createRef()
	const onSubmitStep34 = createRef()

	const newQuoteSteps = [1, 2]
	const newShipmentSteps = [3, 4, 5]

	const dispatch = useDispatch()

	const setActiveStep1 = () => {
		setActiveStep(1)
		setStepTitle(newQuoteStep1Title)
		setStepDescription(newQuoteStep1Description)
	}

	const setActiveStep2 = () => {
		dispatch(setTabStep(2))
		setActiveStep(2)
		setStepTitle(newQuoteStep2Title)
		setStepDescription(newQuoteStep2Description)
	}

	const setActiveStep3 = () => {
		setActiveStep(3)
		setStepTitle(newShipmentStep3Title)
		setStepDescription(newShipmentStep3Description)
	}

	const setActiveStep4 = () => {
		setActiveStep(4)
		setStepTitle(newShipmentStep4Title)
		setStepDescription(newShipmentStep4Description)
	}

	const setActiveStep5 = () => {
		setActiveStep(5)
		setStepDescription(newShipmentStep5Description)
	}

	useEffect(() => {
		if (Object.keys(quoteDetailsToShipment).length !== 0) {
			setActiveStep3()
			dispatch(setTabCurrentRoute(createShipmentsRoute))
			dispatch(setTabTitle('New Shipment'))
		}
	}, [dispatch, quoteDetailsToShipment])

	useEffect(
		() => () => {
			dispatch(resetShiplifyModalState())
			dispatch(updateDataForm({}))
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[],
	)

	useEffect(() => {
		document.querySelector('main').scrollTop = 0
	}, [activeStep])

	const clickStep = (number) => {
		if (number < activeStep) {
			// back step
			switch (activeStep) {
				case 2:
					onSubmitStep12.current.onBackStepGoTo1()
					break
				case 4:
					onSubmitStep34.current.onBackStepGoTo3()
					break
				default:
					break
			}
		} else {
			// next step
			switch (activeStep) {
				case 1:
					onSubmitStep12.current.onSubmitStep1()
					break
				case 2:
					onSubmitStep12.current.onSubmitStep2()
					break
				case 3:
					onSubmitStep34.current.onSubmitStep3()
					break
				case 4:
					onSubmitStep34.current.onSubmitStep4()
					break
				default:
					break
			}
		}
	}
	return (
		<>
			<div className={classes.mainTitleSection}>
				<ModuleHeader
					title={
						<div className={classes.shipmentTitleRow}>
							<h1 className="new-quote-stepper-label">{stepTitle}</h1>
							{activeStep === 5 ? <Chip size="small" className={classes.chip} label="Booked" /> : null}
						</div>
					}
					subtitle={stepDescription}
				/>
				<SimpleStepper activeStep={activeStep} steps={steps} onClick={clickStep} />
			</div>
			{newQuoteSteps.includes(activeStep) && (
				<NewQuoteStepsContainer
					ref={onSubmitStep12}
					activeStep={activeStep}
					setActiveStep1={setActiveStep1}
					setActiveStep2={setActiveStep2}
					setActiveStep3={setActiveStep3}
				/>
			)}
			{newShipmentSteps.includes(activeStep) && (
				<NewShipmentStepsContainer
					ref={onSubmitStep34}
					activeStep={activeStep}
					setActiveStep1={setActiveStep1}
					setActiveStep3={setActiveStep3}
					setActiveStep4={setActiveStep4}
					setActiveStep5={setActiveStep5}
					setStepTitle={setStepTitle}
				/>
			)}
		</>
	)
}

export default StepperContainer
