const useAuth0Redirect = () => {
	const fullAddress = (location) =>
		`${location.hostname === 'localhost' ? 'http' : 'https'}://${location.host}${process.env.REACT_APP_AUTH_LOGIN_PATH}`

	return {
		redirect: fullAddress(window?.location),
	}
}

export default useAuth0Redirect
