import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import {
	Grid,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	TextField,
	FormHelperText,
	List,
	ListItem,
	OutlinedInput,
	Paper,
	Popper,
	Grow,
	ClickAwayListener,
	Tooltip,
	Button,
	Collapse,
	useTheme,
} from '@material-ui/core'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import packageTypeOptions, {
	freightClassOptions,
	hazMatGroupOptions,
	hazMatClassOptions,
	hazMatTypeOptions,
} from '../../../../../utils/dropDownOptions'
import { ShipmentItemsIcon, HazIcon, InformationIcon, StackIcon } from '../../../../../assets/Icons/Icons'
import { capitalizeFirstLetter } from '../../../../../utils/helpers'
import NewItemDetails from '../../components/NewItemDetails'
import { itemsListRoute, createItemRoute } from '../../../../../utils/constants'
import sweetAlertModal from '../../../../../components/SweetAlertModal'
import {
	editTemplateItem,
	createTemplateItem,
	fetchTemplateItemById,
} from '../../../../../redux/actions/TemplateItems.actions'
import { setTabCurrentRoute, setTabTitle } from '../../../../../redux/actions/MultiTabs.actions'
import ModuleHeader from '../../../../../components/ModuleHeader'
import PhoneNumberFormat from '../../components/PhoneNumberFormat'
import useStyles from './styles'

const NewItemContainer = () => {
	const classes = useStyles()
	const theme = useTheme()
	const [hazardous, setHazardous] = useState(false)
	const [isDetailed, setIsDetailed] = useState(false)
	const [detailsList, setDetailsList] = useState([])
	const [packageTypeError, setPackageTypeError] = useState(false)
	const [packageType, setPackageType] = useState('')
	const [freightClass, setFreightClass] = useState('')
	const [freightClassError, setFreightClassError] = useState(false)
	const [phone, setPhone] = useState('')
	const [length, setLength] = useState('')
	const [lengthError, setLengthError] = useState(false)
	const [width, setWidth] = useState('')
	const [widthError, setWidthError] = useState(false)
	const [height, setHeight] = useState('')
	const [heightError, setHeightError] = useState(false)
	const [dimensions, setDimensions] = useState({ length: 0, width: 0, height: 0 })
	const [dimensionsError, setDimensionsError] = useState(false)
	const [open, setOpen] = useState(false)
	const [stackable, setStackable] = useState(false)
	const [hazMatNumberType, setHazMatNumberType] = useState('')
	const [hazMatGroup, setHazMatGroup] = useState('')
	const [hazMatClass, setHazMatClass] = useState('')
	const [itemNameId, setItemNameId] = useState('')
	const [productDescription, setproductDescription] = useState('')
	const descCheck = ['only width', 'only length', 'only height']
	const { itemTemplateId } = useParams()
	const isEdit = !!itemTemplateId

	const { handleSubmit, register, errors, control, setValue, reset, getValues } = useForm()

	const formInfoRef = useRef(null)
	const anchorRef = useRef(null)

	const dispatch = useDispatch()
	const history = useHistory()
	const location = useLocation()

	const splittedPath = location.pathname.split('/')
	useEffect(() => {
		const currentRoute = isEdit ? `/${splittedPath[1]}/${itemTemplateId}` : createItemRoute
		dispatch(setTabCurrentRoute(currentRoute))
		dispatch(setTabTitle(isEdit ? 'Edit Item' : 'New Item'))
	}, [isEdit, dispatch, itemTemplateId, splittedPath])

	const formTexts = {
		title: isEdit ? 'Edit Item' : 'New Item',
		subtitle: isEdit ? 'Change the information for this item.' : 'Insert the information about your new item.',
		submitButtonText: isEdit ? 'Update Item' : 'Save New Item',
		cancelButtonText: isEdit ? 'Cancel' : 'Cancel Item',
		errorSubmitText: isEdit
			? 'There was an error in the update item process!'
			: 'There was an error in the create item process!',
		successSubmitText: isEdit ? 'Item has been updated successfully!' : 'Item has been saved successfully!',
	}

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) return
		setOpen(false)
	}

	const handleToggle = () => setOpen((prevOpen) => !prevOpen)

	const handleSubmitItem = async (data) => {
		const emptyDimensions = dimensions.length === 0 && dimensions.width === 0 && dimensions.height === 0
		if (!emptyDimensions) {
			const hasValidDimensions = dimensions.length !== 0 && dimensions.width !== 0 && dimensions.height !== 0
			if (!hasValidDimensions) {
				const isError = descCheck.includes(productDescription.toLocaleLowerCase())
					? (productDescription.toLowerCase() === 'only width' && dimensions.width === 0) ||
						(productDescription.toLowerCase() === 'only length' && dimensions.length === 0) ||
						(productDescription.toLowerCase() === 'only height' && dimensions.height === 0)
					: true
				if (isError) {
					setDimensionsError(true)
					return
				}
			}
		}
		if (isDetailed && !detailsList.length) {
			sweetAlertModal('warning', 'There must be at least one item detail added', null, 'Ok', true, false, null)
			return
		}

		let newTemplateItem = {
			name: itemNameId === data.newItemId ? null : data.newItemId,
			pieces: {
				unit: packageType || null,
				value: getValues('newItemQuantity') || null,
			},
			unitType: packageType || null,
			class: freightClass || null,
			nmfc: null,
			subNmfc: null,
			weight: {
				unit: 'LBS',
				value: data.newItemWeight || null,
			},
			dimensions: {
				unit: 'IN',
				lenght: dimensions.length || null,
				width: dimensions.width || null,
				height: dimensions.height || null,
			},
			description: data.newItemCommodity,
			stackable,
			isHazMat: hazardous,
			hazMatData: hazardous
				? {
						shippingName: data.newItemShippingName,
						hazMatNumberType: data.newItemHazMatNumberType,
						hazMatNumber: data.newItemHazMatNumber,
						hazMatClass: data.newItemHazMatClass,
						hazMatGroup: data.newItemHazMatGroup,
						contactInformation: {
							name: data.newItemNameContact,
							phone: data.newItemPhoneContact.replace(/[() -]/g, ''),
							zipcode: null,
							state: null,
							country: null,
						},
					}
				: null,
			itemDetails: isDetailed ? detailsList : null,
		}

		if (data.newItemNMFC) {
			newTemplateItem.nmfc = data.newItemNMFC.includes('-')
				? data.newItemNMFC.substr(0, data.newItemNMFC.indexOf('-'))
				: data.newItemNMFC

			newTemplateItem.subNmfc = data.newItemNMFC.includes('-')
				? data.newItemNMFC.substr(data.newItemNMFC.indexOf('-') + 1)
				: null
		}

		if (isEdit) {
			newTemplateItem = {
				...newTemplateItem,
				itemDetails: null,
			}
		}

		const { status, resp } = isEdit
			? await dispatch(editTemplateItem(itemTemplateId, newTemplateItem))
			: await dispatch(createTemplateItem(newTemplateItem))
		if (status === 'error') {
			sweetAlertModal(
				status,
				formTexts.errorSubmitText,
				resp?.err?.message ?? 'There was an error creating the item',
				'Ok',
				true,
				false,
				null,
			)
		} else {
			sweetAlertModal(status, formTexts.successSubmitText, null, 'Ok', true, false, null)
			setDetailsList([])
			setHazardous(false)
			setStackable(false)
			setIsDetailed(false)
			reset()
			history.push(itemsListRoute)
		}
	}

	const resetFields = () => {
		setproductDescription('')
		setValue('newItemCommodity', '')
		setHazardous(false)
		setStackable(false)
		setValue('newItemQuantity', '')
		setValue('newItemWeight', '')
		setLength('')
		setHeight('')
		setWidth('')
		setDimensions({ length: 0, width: 0, height: 0 })
		setValue('newItemNMFC', '')
		setValue('newItemId', '')
		reset({
			newItemFreightClass: '',
			newItemPackageType: '',
			newItemShippingName: '',
			newItemHazMatNumberType: '',
			newItemHazMatNumber: '',
			newItemHazMatClass: '',
			newItemHazMatGroup: '',
			newItemNameContact: '',
		})
		setPhone('')
		setIsDetailed(false)
		setDetailsList([])
	}

	const handleCancelSubmit = () => {
		resetFields()
		history.push(itemsListRoute)
	}

	useEffect(() => {
		const fetchData = async () => {
			const { status, resp } = await dispatch(fetchTemplateItemById(itemTemplateId))
			if (status === 'error') {
				sweetAlertModal(status, 'There was an error fetching the item', null, 'Ok', true, false, null)
			} else {
				const {
					description,
					is_haz_mat: isHazmat,
					stackable: isStackable,
					pieces_value: piecesValue,
					pieces_unit: piecesUnit,
					weight_value: weightValue,
					dimensions_lenght: dLenght,
					dimensions_height: dHeight,
					dimensions_width: dwidth,
					class: fClass,
					nmfc: nmfcCode,
					sub_nmfc: sNmfc,
					haz_mat_shipping_name: hMShippingName,
					name: nameId,
					haz_mat_number: hMNumber,
					haz_mat_number_type: hMNumbertype,
					haz_mat_group: hMGroup,
					haz_mat_class: hMClass,
					contact_info_name: cIName,
					contact_info_phone: cIPhone,
					item_details: itemDetails,
				} = resp
				if (description) {
					setValue('newItemCommodity', description)
				}
				setItemNameId(nameId)
				setValue('newItemCommodity', description)
				setproductDescription(description)
				setHazardous(isHazmat)
				setStackable(isStackable)
				setValue('newItemQuantity', piecesValue)
				setValue('newItemPackageType', piecesUnit)
				setPackageType(piecesUnit)
				setValue('newItemWeight', weightValue)
				setLength(dLenght !== null ? dLenght : '')
				setWidth(dwidth !== null ? dwidth : '')
				setHeight(dHeight !== null ? dHeight : '')
				setDimensions({ length: dLenght, width: dwidth, height: dHeight })
				setValue('newItemFreightClass', fClass)
				setFreightClass(fClass)
				if (nmfcCode) {
					setValue('newItemNMFC', sNmfc ? `${nmfcCode}-${sNmfc}` : nmfcCode)
				}
				setValue('newItemId', nameId)
				setValue('newItemShippingName', hMShippingName)
				setValue('newItemHazMatNumberType', hMNumbertype)
				setValue('newItemHazMatNumber', hMNumber)
				setValue('newItemHazMatClass', hMClass)
				setValue('newItemHazMatGroup', hMGroup)
				setValue('newItemNameContact', cIName)
				if (cIPhone) {
					setValue('newItemPhoneContact', `(${cIPhone.slice(0, 3)}) ${cIPhone.slice(3, 6)}-${cIPhone.slice(6, 10)}`)
					setPhone(`(${cIPhone.slice(0, 3)}) ${cIPhone.slice(3, 6)}-${cIPhone.slice(6, 10)}`)
				}
				if (itemDetails.length) {
					setIsDetailed(true)
					const newitemDetails = itemDetails.map((itemDetail) => {
						const newItemDetail = {
							id: itemDetail.id,
							packageType: itemDetail.package_type,
							quantity: itemDetail.quantity,
							description: itemDetail.description,
						}
						return newItemDetail
					})
					setDetailsList(newitemDetails)
				}
			}
		}
		if (isEdit && itemTemplateId) fetchData()
		else {
			resetFields()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, dispatch, setValue, isEdit, itemTemplateId])

	return (
		<>
			<form autoComplete="off" ref={formInfoRef} onSubmit={handleSubmit(handleSubmitItem)}>
				<Grid item className={classes.newItemHeaderContainer}>
					<ModuleHeader title={formTexts.title} subtitle={formTexts.subtitle} />
				</Grid>
				<Grid item className={classes.newItemInformationContainer}>
					<div className={classes.newItemInformationSubtitle}>
						<ShipmentItemsIcon />
						<h3 className={classes.newItemInformationSubtitleLabel}>Item Information</h3>
					</div>
					<div className={classes.newItemInformationSubContainer}>
						<Grid container spacing={2}>
							<Grid item xs={8} sm={8} md={10} lg={10} xl={5}>
								<TextField
									name="newItemCommodity"
									className={classes.root}
									style={{ width: '100%' }}
									inputRef={register({ required: true, maxLength: { value: 100, message: 'Maximum 100 characters' } })}
									error={!!errors.newItemCommodity}
									helperText={errors.newItemCommodity ? errors.newItemCommodity.message : null}
									FormHelperTextProps={{ classes: { root: classes.helperText } }}
									label="Product description *"
									onChange={(e) => setproductDescription(e.target.value)}
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
							<Grid item xs={4} sm={4} md={2} lg={2} xl={1}>
								<div className={classes.newItemsActionsContainer}>
									{hazardous ? (
										<Tooltip title="Hazmat such as chemicals, acid, waste with a UN number">
											<span>
												<Button
													className={classes.actionButton}
													type="button"
													aria-label="Hazardous On"
													onClick={() => setHazardous(false)}
												>
													<HazIcon className={classes.actionIcon} color="secondary" fontSize="small" />
												</Button>
											</span>
										</Tooltip>
									) : (
										<Tooltip title="Hazmat such as chemicals, acid, waste with a UN number">
											<span>
												<Button
													className={classes.actionButton}
													type="button"
													aria-label="Hazardous Off"
													onClick={() => setHazardous(true)}
												>
													<HazIcon
														className={classes.actionIcon}
														htmlColor={theme.palette.neutral.darkGray}
														fontSize="small"
													/>
												</Button>
											</span>
										</Tooltip>
									)}
									{stackable ? (
										<Tooltip title="Items that can be stacked to reduce linear space">
											<span>
												<Button
													className={classes.actionButton}
													type="button"
													aria-label="Stackable On"
													onClick={() => setStackable(false)}
												>
													<StackIcon className={classes.actionIcon} color="secondary" fontSize="small" />
												</Button>
											</span>
										</Tooltip>
									) : (
										<Tooltip title="Items that can be stacked to reduce linear space">
											<span>
												<Button
													className={classes.actionButton}
													type="button"
													aria-label="Stackable Off"
													onClick={() => setStackable(true)}
												>
													<StackIcon
														className={classes.actionIcon}
														htmlColor={theme.palette.neutral.darkGray}
														fontSize="small"
													/>
												</Button>
											</span>
										</Tooltip>
									)}
								</div>
							</Grid>
							<Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
								<TextField
									name="newItemQuantity"
									className={classes.root}
									type="number"
									inputRef={register({
										required: false,
										pattern: /^(?:[1-9]|[1-9][0-9]|[1-8][0-9][0-9]|9[0-8][0-9]|99[0-9])$/,
									})}
									error={!!errors.newItemQuantity}
									FormHelperTextProps={{ classes: { root: classes.helperText } }}
									label="Quantity"
									variant="outlined"
									style={{ width: '100%' }}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
							<Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
								<FormControl
									style={{ width: '100%' }}
									variant="outlined"
									error={packageTypeError || !!errors.newItemPackageType}
								>
									<InputLabel className={classes.root}>Package type</InputLabel>
									<Controller
										render={({ onChange, value, name }) => (
											<Select
												name={name}
												value={value}
												onChange={(e) => {
													onChange(e.target.value)
													setPackageType(e.target.value)
													setPackageTypeError(false)
												}}
												label="Package Type *"
												className={classes.shipmentItemFormControl}
											>
												{packageTypeOptions.map((option, index) => (
													<MenuItem value={option} key={`id-${index + 1}`}>
														{capitalizeFirstLetter(option)}
													</MenuItem>
												))}
											</Select>
										)}
										name="newItemPackageType"
										control={control}
										defaultValue={packageType}
										rules={{ required: false }}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
								<TextField
									name="newItemWeight"
									type="number"
									className={classes.root}
									inputRef={register({ required: false, min: 0 })}
									error={!!errors.newItemWeight}
									helperText={errors?.newItemWeight?.message ?? ''}
									FormHelperTextProps={{ classes: { root: classes.helperText } }}
									label="Weight (Lb)"
									variant="outlined"
									style={{ width: '100%' }}
									InputLabelProps={{
										shrink: true,
									}}
									inputProps={{ step: '.01' }}
								/>
							</Grid>
							<Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
								<FormControl style={{ width: '100%' }} variant="outlined" error={dimensionsError}>
									<InputLabel className={classes.root} htmlFor="shipment-dimesions">
										{descCheck.includes(productDescription.toLowerCase()) ? 'Dimensions(In) *' : 'Dimensions(In)'}
									</InputLabel>
									<OutlinedInput
										type="text"
										name="newItemShipmentDimensions"
										id="shipment-dimesions"
										ref={anchorRef}
										onClick={handleToggle}
										className={classes.shipmentItemFormControl}
										labelWidth={150}
										readOnly
										inputRef={register({
											required: descCheck.includes(productDescription.toLowerCase()),
										})}
										value={
											dimensions.length || dimensions.width || dimensions.height
												? `${Number(dimensions.length)}" x ${Number(dimensions.width)}" x ${Number(dimensions.height)}"`
												: ''
										}
									/>
									<FormHelperText className={classes.helperText}>Length x Width x Height</FormHelperText>
								</FormControl>
								<Popper
									className={classes.popper}
									open={open}
									anchorEl={anchorRef.current}
									role={undefined}
									transition
									disablePortal
								>
									{({ TransitionProps, placement }) => (
										<Grow
											// eslint-disable-next-line react/jsx-props-no-spreading
											{...TransitionProps}
											style={{
												transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
											}}
										>
											<Paper elevation={4}>
												<ClickAwayListener onClickAway={handleClose}>
													<List dense>
														<ListItem>
															<TextField
																name="newItemLength"
																type="number"
																className={classes.root}
																defaultValue={length}
																onChange={(e) => {
																	const value = Number(e.target.value)
																	if ((value >= 1 && value <= 288) || e.target.value === '') {
																		setLength(e.target.value)
																		setDimensions({ ...dimensions, length: e.target.value === '' ? 0 : e.target.value })
																		setLengthError(false)
																		setDimensionsError(false)
																	} else {
																		setLength(value)
																		setDimensions({ ...dimensions, length: 0 })
																		setLengthError(true)
																		setDimensionsError(true)
																	}
																}}
																error={lengthError}
																helperText="Max. 288"
																FormHelperTextProps={{ classes: { root: classes.helperText } }}
																label={
																	productDescription.toLowerCase() === 'only length' ? 'Length(In) *' : 'Length(In)'
																}
																variant="outlined"
															/>
														</ListItem>
														<ListItem>
															<TextField
																name="newItemWidth"
																type="number"
																className={classes.root}
																defaultValue={width}
																onChange={(e) => {
																	const value = Number(e.target.value)
																	if ((value >= 1 && value <= 96) || e.target.value === '') {
																		setWidth(e.target.value)
																		setDimensions({ ...dimensions, width: e.target.value === '' ? 0 : e.target.value })
																		setWidthError(false)
																		setDimensionsError(false)
																	} else {
																		setWidth(value)
																		setDimensions({ ...dimensions, width: 0 })
																		setWidthError(true)
																		setDimensionsError(true)
																	}
																}}
																error={widthError}
																helperText="Max. 96"
																FormHelperTextProps={{ classes: { root: classes.helperText } }}
																label={productDescription.toLowerCase() === 'only width' ? 'Width(In) *' : 'Width(In)'}
																variant="outlined"
															/>
														</ListItem>
														<ListItem>
															<TextField
																name="newItemHeight"
																type="number"
																className={classes.root}
																defaultValue={height}
																onChange={(e) => {
																	const value = Number(e.target.value)
																	if ((value >= 1 && value <= 96) || e.target.value === '') {
																		setHeight(e.target.value)
																		setDimensions({ ...dimensions, height: e.target.value === '' ? 0 : e.target.value })
																		setHeightError(false)
																		setDimensionsError(false)
																	} else {
																		setHeight(value)
																		setDimensions({ ...dimensions, height: 0 })
																		setHeightError(true)
																		setDimensionsError(true)
																	}
																}}
																error={heightError}
																helperText="Max. 96"
																FormHelperTextProps={{ classes: { root: classes.helperText } }}
																label={
																	productDescription.toLowerCase() === 'only height' ? 'Height(In) *' : 'Height(In)'
																}
																variant="outlined"
															/>
														</ListItem>
													</List>
												</ClickAwayListener>
											</Paper>
										</Grow>
									)}
								</Popper>
							</Grid>
							<Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
								<FormControl
									style={{ width: '100%' }}
									variant="outlined"
									error={freightClassError || !!errors.newItemFreightClass}
								>
									<InputLabel className={classes.root}>Freight Class</InputLabel>
									<Controller
										render={({ onChange, value, name }) => (
											<Select
												name={name}
												value={value}
												onChange={(e) => {
													onChange(e.target.value)
													setFreightClass(e.target.value)
													setFreightClassError(false)
												}}
												label="Freight Class"
												className={classes.shipmentItemFormControl}
											>
												{freightClassOptions.map((option, index) => (
													<MenuItem value={option} key={`id-${index + 1}`}>
														{option}
													</MenuItem>
												))}
											</Select>
										)}
										name="newItemFreightClass"
										defaultValue={freightClass}
										rules={{ required: false }}
										control={control}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
								<TextField
									name="newItemNMFC"
									className={classes.root}
									inputRef={register()}
									error={!!errors.newItemNMFC}
									helperText={errors.newItemNMFC ? errors.newItemNMFC.message : null}
									label="NMFC code"
									variant="outlined"
									style={{ width: '100%' }}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
							<Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
								<TextField
									name="newItemId"
									type="text"
									className={classes.root}
									inputRef={register({ maxLength: 50 })}
									error={!!errors.newItemId}
									FormHelperTextProps={{ classes: { root: classes.helperText } }}
									label="Item ID"
									variant="outlined"
									style={{ width: '100%' }}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
						</Grid>
					</div>
				</Grid>
				<Collapse in={hazardous} timeout="auto" unmountOnExit>
					<Grid item className={classes.newItemGrayContainer}>
						<div className={classes.newItemInformationSubtitle}>
							<div className={classes.newItemHazMatIconContainer}>
								<HazIcon className={classes.newItemHazMatIcon} />
							</div>
							<h3 className={classes.newItemInformationSubtitleLabel}>Haz Material</h3>
						</div>
						<div className={classes.newItemHazMaterialContainer}>
							<Grid container spacing={1}>
								<Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
									<TextField
										name="newItemShippingName"
										type="text"
										label="Proper shipping name*"
										variant="outlined"
										InputLabelProps={{
											classes: {
												root: classes.field,
											},
										}}
										inputRef={register({
											required: true,
											maxLength: { value: 100, message: 'Maximum 100 characters' },
										})}
										error={!!errors.newItemShippingName}
										helperText={errors.newItemShippingName ? errors.newItemShippingName.message : null}
										FormHelperTextProps={{ classes: { root: classes.helperText } }}
										style={{ width: '100%', backgroundColor: 'white' }}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
									<FormControl style={{ width: '100%' }} variant="outlined" error={!!errors.newItemHazMatNumberType}>
										<InputLabel className={classes.field}>HazMat number type*</InputLabel>
										<Controller
											render={({ onChange, value, name }) => (
												<Select
													name={name}
													value={value}
													style={{ textAlign: 'left', backgroundColor: 'white' }}
													onChange={(e) => {
														onChange(e.target.value)
														setHazMatNumberType(e.target.value)
													}}
													label="hazMatNumberType"
												>
													{hazMatTypeOptions.map((item, index) => (
														<MenuItem value={item.value} key={`id-${index + 1}`}>
															{item.value}
														</MenuItem>
													))}
												</Select>
											)}
											name="newItemHazMatNumberType"
											control={control}
											defaultValue={hazMatNumberType}
											rules={{ required: true }}
										/>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
									<TextField
										name="newItemHazMatNumber"
										type="text"
										label="HazMat number*"
										variant="outlined"
										InputLabelProps={{
											classes: {
												root: classes.field,
											},
										}}
										inputRef={register({
											required: true,
											pattern: { value: /^[a-z0-9]+$/i, message: 'Must only contain alpha-numeric characters' },
											maxLength: { value: 10, message: 'Maximum 10 characters' },
										})}
										helperText={errors.newItemHazMatNumber ? errors.newItemHazMatNumber.message : null}
										FormHelperTextProps={{ classes: { root: classes.helperText } }}
										style={{ width: '100%', backgroundColor: 'white' }}
									/>
								</Grid>
								<Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
									<FormControl style={{ width: '100%' }} variant="outlined" error={!!errors.newItemHazMatGroup}>
										<InputLabel className={classes.field}>HazMat group*</InputLabel>
										<Controller
											render={({ onChange, value, name }) => (
												<Select
													name={name}
													value={value}
													style={{ textAlign: 'left', backgroundColor: 'white' }}
													onChange={(e) => {
														onChange(e.target.value)
														setHazMatGroup(e.target.value)
													}}
													label="hazMatGroup"
												>
													{hazMatGroupOptions.map((item, index) => (
														<MenuItem value={item.value} key={`id-${index + 1}`}>
															{item.value}
														</MenuItem>
													))}
												</Select>
											)}
											name="newItemHazMatGroup"
											control={control}
											defaultValue={hazMatGroup}
											rules={{ required: true }}
										/>
									</FormControl>
								</Grid>
								<Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
									<FormControl style={{ width: '100%' }} variant="outlined" error={!!errors.newItemHazMatClass}>
										<InputLabel className={classes.field}>HazMat class*</InputLabel>
										<Controller
											render={({ onChange, value, name }) => (
												<Select
													name={name}
													value={value}
													style={{ textAlign: 'left', backgroundColor: 'white' }}
													onChange={(e) => {
														onChange(e.target.value)
														setHazMatClass(e.target.value)
													}}
													label="hazMatClass"
												>
													{hazMatClassOptions.map((item, index) => (
														<MenuItem value={item.value} key={`id-${index + 1}`}>
															{item.value}
														</MenuItem>
													))}
												</Select>
											)}
											name="newItemHazMatClass"
											control={control}
											defaultValue={hazMatClass}
											rules={{ required: true }}
										/>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
									<TextField
										name="newItemNameContact"
										type="text"
										label="24-hour contact name*"
										variant="outlined"
										InputLabelProps={{
											classes: {
												root: classes.field,
											},
										}}
										inputRef={register({ required: true, maxLength: { value: 25, message: 'Maximum 25 characters' } })}
										error={!!errors.newItemNameContact}
										helperText={errors.newItemNameContact ? errors.newItemNameContact.message : null}
										FormHelperTextProps={{ classes: { root: classes.helperText } }}
										style={{ width: '100%', backgroundColor: 'white' }}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
									<TextField
										name="newItemPhoneContact"
										type="text"
										label="24-hour contact phone*"
										variant="outlined"
										value={phone}
										onChange={(e) => setPhone(e.target.value)}
										InputLabelProps={{
											classes: {
												root: classes.field,
											},
										}}
										inputRef={register({
											required: true,
											pattern: {
												value: /^([(]([0-9]{3})[)] ([0-9]{3})(-)([0-9]{4}))$/,
												message: 'Enter a phone number',
											},
										})}
										error={!!errors.newItemPhoneContact}
										helperText={errors.newItemPhoneContact ? errors.newItemPhoneContact.message : null}
										InputProps={{
											inputComponent: PhoneNumberFormat,
										}}
										FormHelperTextProps={{ classes: { root: classes.helperText } }}
										style={{ width: '100%', backgroundColor: 'white' }}
									/>
								</Grid>
							</Grid>
						</div>
					</Grid>
				</Collapse>
				<Grid item className={hazardous ? classes.newItemDetailContainer : classes.newItemGrayContainer}>
					<div className={classes.newItemDetailSubtitle}>
						<div className={classes.newItemDetailsIconContainer}>
							<InformationIcon className={classes.newItemDetailsIcon} />
						</div>
						<h3 className={classes.newItemInformationSubtitleLabel}>Details</h3>
					</div>
					<div>
						<NewItemDetails
							isDetailed={isDetailed}
							setIsDetailed={setIsDetailed}
							detailsList={detailsList}
							setDetailsList={setDetailsList}
							templateItemId={itemTemplateId}
						/>
					</div>
				</Grid>
				<Grid item xs={12} sm={12} lg={12} xl={12} className={classes.newItemButtonsContainer}>
					<Button className={classes.cancelButton} onClick={() => handleCancelSubmit()}>
						{formTexts.cancelButtonText}
					</Button>
					<Button className={classes.saveButton} type="submit" color="secondary" variant="contained">
						{formTexts.submitButtonText}
					</Button>
				</Grid>
			</form>
		</>
	)
}

export default NewItemContainer
