import axios from 'axios'

const apiBackUsers = process.env.REACT_APP_BACKEND_BASE_URL
const apiBackQuotes = process.env.REACT_APP_BACKEND_QUOTES_BASE_URL
const apiBackInvoices = process.env.REACT_APP_BACKEND_INVOICES_BASE_URL
const apiBackReports = process.env.REACT_APP_BACKEND_REPORTS_BASE_URL
const apiBackAudit = process.env.REACT_APP_BACKEND_AUDIT_BASE_URL
const hereMapsUrlForZipCode = process.env.REACT_APP_HERE_MAPS_ZIP_URL

const axiosUser = axios.create({
	baseURL: apiBackUsers,
})

export const axiosUserCompany = axios.create({
	baseURL: apiBackUsers,
})

export const axiosQuotes = axios.create({
	baseURL: apiBackQuotes,
})

export const axiosInvoices = axios.create({
	baseURL: apiBackInvoices,
})

export const axiosAudit = axios.create({
	baseURL: apiBackAudit,
})

export const axiosHereMaps = axios.create({
	baseURL: hereMapsUrlForZipCode,
})

export const axiosReports = axios.create({
	baseURL: apiBackReports,
})

export const formatAxiosErrorMessage = (err) =>
	`Status: ${err.status}\nMessage: ${err.resp.message}\nURL: ${err.resp.config.baseURL}${err.resp.config.url}`

export default axiosUser
