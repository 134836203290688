import { axiosReports } from '../../utils/apiClient'

const requestWithHeader = async (params, headers) => {
	const [error, data] = await axiosReports
		.get(params, { headers })
		.then((response) => [null, response])
		.catch((err) => [err, err])
	return [error, data]
}

export default requestWithHeader
