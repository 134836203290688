import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	dashboardHeading: {
		color: theme.palette.neutral.black,
		marginBottom: 6,
		fontSize: 34,
		textTransform: 'capitalize',
	},
	dashboardBody: {
		color: theme.palette.neutral.black,
		fontSize: 16,
	},
	dashboardLabel: {
		display: 'flex',
		alignItems: 'center',
		'& > div': {
			position: 'relative',
			height: 32,
			width: 32,
			marginRight: theme.spacing(2),
			borderRadius: '50%',
		},

		'& h5': {
			fontWeight: theme.typography.fontWeightRegular,
			color: theme.palette.primary.main,
		},
	},
	dashboardLabelIcon: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		fontSize: 20,
	},
}))

export default useStyles
