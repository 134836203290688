import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useParams } from 'react-router-dom'
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined'
import { accountEditRoute, permissionName } from '../../../../../utils/constants'
import SessionContext from '../../../../../context/session'
import isPermit from '../../../../../utils/permission'
import useStyles from './styles'

const MenuList = (props) => {
	const history = useHistory()
	const { id } = useParams()
	const classes = useStyles()
	const { getUserData } = useContext(SessionContext)
	const { permissions } = getUserData()
	const goToAccountEdit = () => {
		const { activeStep } = props
		history.push({
			pathname: accountEditRoute.replace(':id', id),
			state: { step: activeStep + 1 },
		})
	}

	const { anchorEl, open, handleClose, isActive, handleAccountStatusEdit } = props

	return (
		<Menu id="long-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
			{isPermit(permissions, permissionName.FP_ADMIN_ACCOUNTS_UPDATE) && (
				<MenuItem dense onClick={goToAccountEdit}>
					<ListItemIcon className={classes.listItemIcon}>
						<Edit fontSize="small" className={classes.iconGreen} />
					</ListItemIcon>
					<ListItemText className={classes.menuText} primary="Edit account information" />
				</MenuItem>
			)}
			{isPermit(permissions, permissionName.FP_ADMIN_ACCOUNTS_DEACTIVATE) &&
				(isActive ? (
					<MenuItem dense onClick={handleAccountStatusEdit}>
						<ListItemIcon className={classes.listItemIcon}>
							<NotInterestedIcon className={classes.iconRed} size="small" />
						</ListItemIcon>
						<ListItemText className={classes.menuText} primary="Deactivate account" />
					</MenuItem>
				) : (
					<MenuItem dense onClick={handleAccountStatusEdit}>
						<ListItemIcon className={classes.listItemIcon}>
							<CheckCircleOutlinedIcon className={classes.iconGreen} size="small" />
						</ListItemIcon>
						<ListItemText className={classes.menuText} primary="Activate account" />
					</MenuItem>
				))}
		</Menu>
	)
}

MenuList.propTypes = {
	anchorEl: PropTypes.objectOf(PropTypes.any),
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	isActive: PropTypes.bool,
	handleAccountStatusEdit: PropTypes.func.isRequired,
	activeStep: PropTypes.number,
}

MenuList.defaultProps = {
	anchorEl: null,
	isActive: true,
	activeStep: 0,
}

export default MenuList
