import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	invoiceContainer: {},
	headerSection: {},
	headerTitle: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	subtitleText: {
		margin: '0 0 12px',
		[theme.breakpoints.up('md')]: {
			margin: '0 0 24px',
		},
	},
	titleText: {
		margin: '0 10px 0px 0',
		fontSize: theme.typography.h1.fontSize,
	},
	filterTriggerButton: {
		color: theme.palette.neutral.black,
		padding: 0,
		[theme.breakpoints.up('lg')]: {
			display: 'none',
		},
	},
	headerActionButton: {
		textTransform: 'initial',
		fontWeight: theme.typography.body2.fontWeight,
		fontSize: theme.typography.body2.fontSize,
	},
	filterButtonText: {
		color: theme.palette.neutral.black,
		textTransform: 'initial',
		fontWeight: theme.typography.body2.fontWeight,
		fontSize: theme.typography.body2.fontSize,
	},
	filterButtonModal: {
		marginBottom: '20px',
	},
	filterFormWrapper: {
		padding: '5px',
	},
	icon: {
		color: theme.palette.secondary.main,
	},
}))

export default useStyles
