import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import englishTranslation from './assets/i18n/translations/en.json'
import espanishTranslation from './assets/i18n/translations/es.json'

i18n.use(initReactI18next).init({
	resources: {
		en: englishTranslation,
		es: espanishTranslation,
	},
	lng: localStorage.getItem('i18nextLng') || 'en',
	fallbackLng: 'en',
	interpolation: {
		escapeValue: false,
		format: (value, format) => {
			if (format === 'uppercase') return value.toUpperCase()
			return value
		},
	},
})

export default i18n
