import React, { useContext } from 'react'
import { Button } from '@material-ui/core'
import PropTypes from 'prop-types'

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'

import SessionContext from '../../../../context/session'
import NotificationsTable from '../NotificationsTable'
import NotificationsCard from '../NotificationsCard'
import DragAndDrop from '../DragAndDrop'

import { ReactComponent as DownloadLabelIcon } from '../../../../assets/svg/Download_label.svg'
import useStyles from './styles'
import './NewQuoteShipmentStepFive.scss'

const NewQuoteShipmentStepFive = (props) => {
	const { contactData, setContactData, shipmentId, bolIdentifier } = props
	const classes = useStyles()
	const { getUserData, getTokenUser } = useContext(SessionContext)
	const { actualCompany } = getUserData()
	const token = getTokenUser()

	const handleDownloadBOL = () => {
		const url = `${process.env.REACT_APP_BACKEND_QUOTES_BASE_URL}/shipment/bol/${shipmentId}`

		const oReq = new XMLHttpRequest()
		oReq.open('GET', url, true)
		oReq.responseType = 'arraybuffer'
		oReq.setRequestHeader('X-Company-id', Number(actualCompany))
		oReq.setRequestHeader('Authorization', `Bearer ${token}`)

		oReq.onload = function download() {
			const blob = new Blob([oReq.response], { type: 'application/pdf' })
			const URL = window.URL || window.webkitURL
			const dataUrl = URL.createObjectURL(blob)
			const downloadLink = document.createElement('a')
			const fileName = `BOL-${bolIdentifier}.pdf`

			downloadLink.href = dataUrl
			downloadLink.download = fileName
			downloadLink.click()
		}
		oReq.send()
	}

	const handleDownloadPalletLabel = () => {
		const url = `${process.env.REACT_APP_BACKEND_QUOTES_BASE_URL}/shipment/labels/${shipmentId}`

		const oReq = new XMLHttpRequest()
		oReq.open('GET', url, true)
		oReq.responseType = 'arraybuffer'
		oReq.setRequestHeader('X-Company-id', Number(actualCompany))
		oReq.setRequestHeader('Authorization', `Bearer ${token}`)

		oReq.onload = function download() {
			const blob = new Blob([oReq.response], { type: 'application/pdf' })
			const URL = window.URL || window.webkitURL
			const dataUrl = URL.createObjectURL(blob)
			const downloadLink = document.createElement('a')
			const fileName = `LABEL-${bolIdentifier}.pdf`

			downloadLink.href = dataUrl
			downloadLink.download = fileName
			downloadLink.click()
		}
		oReq.send()
	}

	return (
		<div className="stepFive">
			<div className="stepFive-div">
				<div className="stepFive-icon-title">
					<InfoOutlinedIcon color="primary" className={classes.icon} />
					<p className="stepFive-text-instructions" style={{ fontSize: '16px', fontWeight: 400 }}>
						Thanks for shipping with NTG! We will schedule your shipment for pickup with the carrier. Please follow
						these instructions:
					</p>
				</div>
				<div className="stepFive-container-instructions">
					<p className={classes.instructions}>
						<span>1. </span>
						Make sure the shipment is properly palletized, crated or boxed.
					</p>
					<p className={classes.instructions}>
						<span>2. </span>
						Look over the Bill of Lading (BOL) and notify us if anything needs to be updated. If you don&#39;t see the
						BOL downloaded on this page, you can download it again here.
					</p>
					<p className={classes.instructions}>
						<span>3. </span>
						Print a copy of the BOL to hand to the driver at the time of pickup. If you are not at the origin location,
						make sure someone there does steps 1-4 before the driver shows up. Additional fees and/or delays can apply
						if an NTG BOL isn&#39;t handed to the driver at the time of pickup.
					</p>
					<p className={classes.instructions} id="stepFive-instructions-step-4">
						<span>4. </span>
						Attach another copy of the BOL to your shipment (recommended)
					</p>
					<p>
						Pickups generally take place between 12-5PM, but could happen sooner if the driver&#39;s route permits. Keep
						in mind, pickups are not guaranteed in the LTL industry. If your pickup misses, we&#39;ll get it rescheduled
						for the next business day.
					</p>
				</div>
				<div className={classes.downloadButton}>
					<Button role="presentation" color="secondary" onClick={() => handleDownloadPalletLabel()}>
						<DownloadLabelIcon className={classes.iconDownload} />
						<p className={classes.buttonLabel}>Download Pallet Label</p>
					</Button>
					<Button
						className={classes.buttonDownloadBOL}
						role="presentation"
						color="secondary"
						onClick={() => handleDownloadBOL()}
					>
						<AssignmentOutlinedIcon className={classes.iconDownload} />
						<p className={classes.buttonLabel}>Download BOL</p>
					</Button>
				</div>
			</div>
			<div className={classes.notificationsContainer}>
				<div className="stepFive-icon-title" id="stepFive-notifications-table-title">
					<InfoOutlinedIcon color="primary" className={classes.icon} />
					<h5 className={classes.sectionTitle}>Notification Settings</h5>
				</div>
				<NotificationsTable contactData={contactData} setContactData={setContactData} shipmentId={shipmentId} />
				<NotificationsCard contactData={contactData} />
			</div>
			<div className="stepFive-div" id="stepFive-div-documents">
				<div className="stepFive-icon-title" id="stepFive-documents-title">
					<InfoOutlinedIcon color="primary" className={classes.icon} />
					<h5 className={classes.sectionTitle}>Documents</h5>
				</div>
				<DragAndDrop shipmentId={shipmentId} />
			</div>
		</div>
	)
}

NewQuoteShipmentStepFive.propTypes = {
	contactData: PropTypes.arrayOf(PropTypes.any).isRequired,
	setContactData: PropTypes.func.isRequired,
	shipmentId: PropTypes.number.isRequired,
	bolIdentifier: PropTypes.string.isRequired,
}

export default NewQuoteShipmentStepFive
