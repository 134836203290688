import React from 'react'
import propTypes from 'prop-types'

class ErrorBoundary extends React.Component {
	constructor(props) {
		super()
		this.props = props
		this.state = {
			errorMessage: '',
		}
		this.consoleManager = console
	}

	static getDerivedStateFromError(error) {
		return { errorMessage: error.toString() }
	}

	componentDidCatch(error, info) {
		const { errorMessage } = this.state
		this.consoleManager.error(error.toString(), errorMessage, info.componentStack)
	}

	render() {
		const { children } = this.props
		return <>{children}</>
	}
}

ErrorBoundary.defaultProps = {
	children: propTypes.node,
}

ErrorBoundary.propTypes = {
	children: propTypes.node,
}

export default ErrorBoundary
