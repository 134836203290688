import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	manualUploadsTitle: {
		color: theme.palette.primary.main,
		fontSize: '17px',
		lineHeight: '22px',
		padding: '15px',
	},
	dragAndDropArea: {
		width: '100%',
		height: '140px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexFlow: 'row',
		fontSize: '13px',
		fontWeight: theme.typography.fontWeightRegular,
		color: theme.palette.neutral.black,
		border: '1px dashed #C9CFE4',
		borderRadius: '5px',
		backgroundColor: theme.palette.neutral.secondaryGrey,
		position: 'relative',
		justifySelf: 'end',
		'&:hover': {
			boxShadow: '0px 2px 10px 5px #ccc',
		},
		[theme.breakpoints.down('md')]: {
			height: '40px',
		},
	},
	dragAndDropCounter: {
		position: 'absolute',
		top: '-10px',
		left: '-10px',
	},
	dragAndDropIcon: {
		width: '24px',
		color: theme.palette.primary.main,
	},
	dragAndDropTitleMobile: {
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	dragAndDropTitleWeb: {
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	dragAndDropUploader: {
		width: '100%',
		height: '100%',
		opacity: '0',
		position: 'absolute',
		zIndex: '1',
		left: '0',
		top: '0',
		cursor: 'pointer',
	},
	dragAndDropActionButtonContainer: {
		display: 'flex',
		alignItems: 'start',
		flexDirection: 'column',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'row',
			alignItems: 'center',
		},
	},
	dragAndDropActionButton: {
		fontSize: '14px',
		fontWeight: theme.typography.fontWeightRegular,
		textTransform: 'none',
		display: 'flex',
	},
	containerSendEmail: {
		paddingLeft: 15,
	},
}))

export default useStyles
