import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	paper: {
		width: '500px',
		borderBottom: '6px solid',
		borderBottomColor: theme.palette.secondary.main,
		padding: '20px 35px',
	},
	confirmation: {
		display: 'flex',
		alignItems: 'center',
		paddingBottom: '16px',
	},
	title: {
		textAlign: 'center',
		fontSize: 34,
		color: theme.palette.secondary.main,

		'& h1': {
			margin: 0,
		},
	},
	text: {
		fontSize: '16px',
		fontWeight: 400,
		marginBottom: '38px',
		textAlign: 'center',
	},
	chipInput: {
		width: '100%',
		borderRadius: '50%',
		marginBottom: '25px',
		'& .MuiInputBase-root': {
			display: 'block',
			position: 'static',
			minHeight: '60px',
			maxHeight: '100px',
			overflowX: 'hidden',
			overflowY: 'auto',
			padding: '10px 20px',
		},
		'& label': {
			background: 'white',
			padding: '0 2px',
		},
	},
	chipContainer: {
		marginBottom: 0,
	},
	goButton: {
		width: '100%',
		height: '48px',
		alignSelf: 'center',
		textTransform: 'unset',
		fontWeight: '500',
		'&:first-of-type': {
			marginBottom: '1em',
		},
	},
	cancelButton: {
		width: '100%',
		height: '48px',
		alignSelf: 'center',
		backgroundColor: '#CCD2D8',
		color: '#2C2C2C',
		textTransform: 'unset',
		'&:hover': {
			backgroundColor: '#b1b7bd',
			color: '#2C2C2C',
		},
		'@media only screen and (min-width: 768px) and (max-width: 1023px)': {
			fontSize: '11px',
		},
	},
}))

export default useStyles
