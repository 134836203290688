import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { makeStyles, Checkbox, FormControlLabel, TextField } from '@material-ui/core'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import CancelIcon from '@material-ui/icons/Cancel'

import PhoneNumberFormat from '../../../../components/PhoneNumberFormat'

import './NotificationsCard.scss'

const useStyles = makeStyles((theme) => ({
	checkBoxLabel: {
		fontSize: '14px',
		fontWeight: 600,
	},
	checkBox: {
		color: theme.palette.secondary.main,
		padding: '0 7px 0 9px',
	},
	icon: {
		width: '24px',
	},
}))

const NotificationsCard = ({ contactData }) => {
	const classes = useStyles()
	const [isNewContactClicked, setIsNewContactClicked] = useState(false)

	return (
		<div className="notifications-card-container">
			{contactData.map((item, index) => (
				<div key={`id-${index + 1}`} className="notifications-card-card">
					<h3 className="notifications-card-title">Contact Information</h3>
					<p className="notifications-card-info">
						<span>Contact Name: </span>
						{item.name}
					</p>
					<p className="notifications-card-info">
						<span>Email Address: </span>
						{item.email ? item.email : '-'}
					</p>
					<p className="notifications-card-info">
						<span>Cell Phone: </span>
						{item.phone ? item.phone : '-'}
					</p>
					<hr className="notifications-card-hr" />
					<FormControlLabel
						className={classes.checkBoxLabel}
						control={<Checkbox name="sendBOLByEmail" color="secondary" />}
						label="Send BOL by Email"
					/>
					<hr className="notifications-card-hr" />
					<h3 className="notifications-card-title">Method</h3>
					<div className="notifications-card-row">
						<FormControlLabel
							className={classes.checkBoxLabel}
							control={<Checkbox name="email" color="secondary" />}
							label="Email"
						/>
						<FormControlLabel
							className={classes.checkBoxLabel}
							control={<Checkbox name="sms" color="secondary" />}
							label="SMS"
						/>
					</div>
					<hr className="notifications-card-hr" />
					<h3 className="notifications-card-title">Tracking Notifications</h3>
					<div className="notifications-card-row">
						<FormControlLabel
							className={classes.checkBoxLabel}
							control={<Checkbox name="booked" color="secondary" />}
							label="Booked"
						/>
						<FormControlLabel
							className={classes.checkBoxLabel}
							control={<Checkbox name="inTransit" color="secondary" />}
							label="In Transit"
						/>
					</div>
					<FormControlLabel
						className={classes.checkBoxLabel}
						control={<Checkbox name="delivered" color="secondary" style={{ padding: '10px 7px 10px 9px' }} />}
						label="Delivered"
					/>
					<div className="notifications-card-icon-title-delete">
						<DeleteOutlineOutlinedIcon color="error" className={classes.icon} />
						<p className="notifications-card-title-delete">Delete</p>
					</div>
				</div>
			))}
			{isNewContactClicked && (
				<div className="notifications-card-card">
					<h3 className="notifications-card-title" style={{ marginBottom: '5px' }}>
						Contact Information
					</h3>
					<div style={{ display: 'grid', gridAutoFlow: 'column' }}>
						<p className="notifications-card-info" style={{ alignSelf: 'end', margin: 0 }}>
							<span>Contact Name: </span>
						</p>
						<TextField
							style={{ marginLeft: '20px' }}
							InputProps={{
								className: classes.input,
							}}
						/>
					</div>
					<div style={{ display: 'grid', gridAutoFlow: 'column' }}>
						<p className="notifications-card-info" style={{ alignSelf: 'end', margin: 0 }}>
							<span>Email Address: </span>
						</p>
						<TextField
							style={{ marginLeft: '20px' }}
							InputProps={{
								className: classes.input,
							}}
						/>
					</div>
					<div style={{ display: 'grid', gridAutoFlow: 'column' }}>
						<p className="notifications-card-info" style={{ alignSelf: 'end', margin: 0 }}>
							<span>Cell Phone: </span>
						</p>
						<TextField
							name="phone"
							style={{ marginLeft: '20px' }}
							InputProps={{
								inputComponent: PhoneNumberFormat,
								className: classes.input,
							}}
						/>
					</div>
					<hr className="notifications-card-hr" />
					<FormControlLabel
						className={classes.checkBoxLabel}
						control={<Checkbox name="sendBOLByEmail" color="secondary" />}
						label="Send BOL by Email"
					/>
					<hr className="notifications-card-hr" />
					<h3 className="notifications-card-title">Method</h3>
					<div className="notifications-card-row">
						<FormControlLabel
							className={classes.checkBoxLabel}
							control={<Checkbox name="email" color="secondary" />}
							label="Email"
						/>
						<FormControlLabel
							className={classes.checkBoxLabel}
							control={<Checkbox name="sms" color="secondary" />}
							label="SMS"
						/>
					</div>
					<hr className="notifications-card-hr" />
					<h3 className="notifications-card-title">Tracking Notifications</h3>
					<div className="notifications-card-row">
						<FormControlLabel
							className={classes.checkBoxLabel}
							control={<Checkbox name="booked" color="secondary" />}
							label="Booked"
						/>
						<FormControlLabel
							className={classes.checkBoxLabel}
							control={<Checkbox name="inTransit" color="secondary" />}
							label="In Transit"
						/>
					</div>
					<FormControlLabel
						className={classes.checkBoxLabel}
						control={<Checkbox name="delivered" style={{ color: '#EC3514', padding: '10px 7px 10px 9px' }} />}
						label="Delivered"
					/>
					<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<div
							style={{ display: 'flex', marginLeft: '10px' }}
							role="presentation"
							onClick={() => setIsNewContactClicked(false)}
						>
							<DoneAllIcon className={classes.icon} />
							<p className="notifications-card-title-delete" style={{ marginLeft: '5px' }}>
								Confirm
							</p>
						</div>
						<div
							style={{ display: 'flex', marginLeft: '10px' }}
							role="presentation"
							onClick={() => setIsNewContactClicked(false)}
						>
							<CancelIcon className={classes.icon} />
							<p className="notifications-card-title-delete" style={{ marginLeft: '5px' }}>
								Cancel
							</p>
						</div>
					</div>
				</div>
			)}
			<div className="notifications-card-icon-title" role="presentation" onClick={() => setIsNewContactClicked(true)}>
				<AddOutlinedIcon color="secondary" />
				<p className="notifications-card-title-new-contact">Add New Contact</p>
			</div>
		</div>
	)
}

NotificationsCard.propTypes = {
	contactData: PropTypes.arrayOf(PropTypes.any).isRequired,
}

export default NotificationsCard
