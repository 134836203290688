import React, { useState, forwardRef, useRef, useImperativeHandle, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, FormHelperText } from '@material-ui/core'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import PropTypes from 'prop-types'
import useStyles from './styles'
import { detailsPackageTypeOptions } from '../../../../../../utils/dropDownOptions'
import { capitalizeFirstLetter } from '../../../../../../utils/helpers'

const DetailsForm = forwardRef(({ detailItem, itemToUpdate, setItemToUpdate, deleteDetailForm }, ref) => {
	const classes = useStyles()
	const { handleSubmit, register, errors, control, getValues, watch } = useForm()

	const [detailPackageType, setDetailPackageType] = useState('')
	const [detailDescription, setDetailDescription] = useState('')
	const [detailPackageTypeError, setDetailPackageTypeError] = useState(false)
	const [descError, setDescError] = useState(false)
	const descErrorMessage = 'Maximum 100 characters allowed'
	const formRef = useRef(null)

	const addItemDetail = () => {
		const newItem = JSON.parse(JSON.stringify(itemToUpdate))
		if (!newItem.item_details) newItem.item_details = []
		const detailExists =
			itemToUpdate.item_details?.find(
				(element) =>
					element.package_type === detailPackageType &&
					element.quantity === getValues('quantity') &&
					element.description === detailDescription,
			) ?? {}

		if (Object.entries(detailExists).length === 0) {
			newItem.item_details.push({
				package_type: detailPackageType,
				description: detailDescription,
				quantity: getValues('quantity'),
			})
			setItemToUpdate(newItem)
		}
	}
	useEffect(() => {
		setDescError(detailDescription.length > 100)
	}, [detailDescription])

	useImperativeHandle(ref, () => ({
		submitForm() {
			formRef.current.dispatchEvent(new Event('submit', { cancelable: true }))

			if (
				!watch('detailPackageType') ||
				!watch('quantity') ||
				watch('quantity') < 1 ||
				watch('itemDescription').length > 100
			) {
				return 'error'
			}
			return 'success'
		},
	}))

	return (
		<form onSubmit={handleSubmit(addItemDetail)} ref={formRef} className={classes.formContainer}>
			<Grid container spacing={1} style={{ marginLeft: '15px', maxWidth: '100%' }}>
				<Grid item xs={6} sm={6} lg={2} xl={2}>
					<FormControl
						style={{ width: '100%' }}
						variant="outlined"
						error={detailPackageTypeError || !!errors.detailPackageType}
					>
						<InputLabel className={classes.root}>Package type *</InputLabel>
						<Controller
							render={({ onChange, value, name }) => (
								<Select
									name={name}
									value={value}
									label="Package Type *"
									onChange={(e) => {
										onChange(e.target.value)
										setDetailPackageType(e.target.value)
										setDetailPackageTypeError(false)
									}}
								>
									{detailsPackageTypeOptions.map((option, index) => (
										<MenuItem value={option} key={`id-${index + 1}`}>
											{capitalizeFirstLetter(option)}
										</MenuItem>
									))}
								</Select>
							)}
							name="detailPackageType"
							defaultValue={detailPackageType}
							control={control}
							rules={{ required: true }}
						/>
						{detailPackageTypeError || !!errors.detailPackageType ? (
							<FormHelperText>Please add the detail item before saving</FormHelperText>
						) : null}
					</FormControl>
				</Grid>
				<Grid item xs={6} sm={6} lg={1} xl={1}>
					<TextField
						name="quantity"
						className={classes.inputNumber}
						InputProps={{ inputProps: { min: 0 } }}
						inputRef={register({
							required: { value: true, message: 'Please add the detail item before saving' },
							pattern: {
								value: /^(?:[1-9]|[1-9][0-9]|[1-8][0-9][0-9]|9[0-8][0-9]|99[0-9])$/,
								message: 'Invalid Format',
							},
						})}
						error={!!errors.quantity}
						helperText={errors.quantity ? errors.quantity.message : null}
						FormHelperTextProps={{ classes: { root: classes.helperText } }}
						type="number"
						label="Quantity *"
						variant="outlined"
					/>
				</Grid>
				<Grid item xs={8} sm={6} lg={7} xl={7}>
					<TextField
						name="itemDescription"
						value={detailDescription}
						onChange={(e) => setDetailDescription(e.target.value)}
						style={{ width: '100%' }}
						label="Product description"
						variant="outlined"
						inputRef={register({ maxLength: { value: 100, message: descErrorMessage } })}
						error={!!errors.itemDescription || descError}
						helperText={errors.itemDescription || descError ? descErrorMessage : null}
						FormHelperTextProps={{ classes: { root: classes.helperText } }}
					/>
				</Grid>
				<Grid item lg={2} xl={2} style={{ display: 'flex', alignItems: 'center' }}>
					<Button
						type="button"
						aria-label="Remove item detail"
						onClick={() => deleteDetailForm(detailItem.id)}
						className={classes.ediItemModalActionButton}
					>
						<DeleteOutlineIcon color="error" />
						<span>Remove Detail</span>
					</Button>
				</Grid>
			</Grid>
		</form>
	)
})

DetailsForm.propTypes = {
	detailItem: PropTypes.objectOf(PropTypes.any).isRequired,
	itemToUpdate: PropTypes.objectOf(PropTypes.any).isRequired,
	setItemToUpdate: PropTypes.func.isRequired,
	deleteDetailForm: PropTypes.func.isRequired,
}

export default DetailsForm
