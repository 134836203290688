import { createSlice } from '@reduxjs/toolkit'
import {
	reqBookShipment,
	reqShipmentList,
	reqSendEmailForBookingConfirmation,
	reqRemoveShipment,
	reqUploadDocument,
	reqDownloadDocumentLabel,
	reqDownloadDocumentBlob,
	reqAddNotification,
	reqRemoveNotification,
	reqDownloadShipmentDocument,
	reqImportShipmentToMG,
} from '../requests/Shipment.requests'

/* eslint no-param-reassign: ["error", { "props": false }] */

const shipmentSlice = createSlice({
	name: 'shipmentSlice',
	initialState: {
		isLoading: false,
		error: false,
		createShipmentInfo: {},
		list: [],
		filterApplied: '',
		pagSkip: 0,
	},
	reducers: {
		startLoading: (state) => {
			state.isLoading = true
		},
		hasError: (state, action) => {
			state.error = action.payload
			state.isLoading = false
		},
		saveCreateShipmentInfo: (state, action) => {
			state.createShipmentInfo = action.payload
		},
		saveShipmentList: (state, action) => {
			state.list = action.payload.shipments
			state.totalRecords = action.payload.records
			state.isLoading = false
		},
		updateFilterApplied: (state, action) => {
			state.filterApplied = action.payload
		},
		updatePagSkip: (state, action) => {
			state.pagSkip = action.payload
		},
	},
})

const { actions, reducer } = shipmentSlice

export const { startLoading, hasError, saveCreateShipmentInfo, saveShipmentList, updateFilterApplied, updatePagSkip } =
	actions

export const bookShipment = (params) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqBookShipment(params)

	if (error) {
		return { status: 'error', resp: data }
	}

	return { status: 'success', resp: data.data.data }
}

export const getShipmentList = (params) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqShipmentList(params)
	if (error) {
		dispatch(hasError(error))
		return { status: 'error', resp: error }
	}
	dispatch(saveShipmentList(data.data.data))
	return { status: 'success' }
}

export const sendEmailForConfirmation = (shipmentId, params) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqSendEmailForBookingConfirmation(shipmentId, params)
	if (error) {
		dispatch(hasError(error))
		return { status: 'error', resp: error }
	}
	return { status: 'success', resp: data }
}

export const updateFilter = (params) => async (dispatch) => {
	dispatch(updateFilterApplied(params))
}

export const saveCreateShipmentData = (params) => async (dispatch) => {
	dispatch(saveCreateShipmentInfo(params))
}

export const removeShipment = (shipmentId) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqRemoveShipment(shipmentId)
	if (error) {
		dispatch(hasError(error))
		return { status: 'error', resp: error }
	}
	return { status: 'success', resp: data }
}

export const updatePaginator = (params) => async (dispatch) => {
	dispatch(updatePagSkip(params))
}

export const uploadShipmentDocument = (shipmentId, payload) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqUploadDocument(shipmentId, payload)

	if (error) {
		dispatch(hasError(error))
		return { status: 'error', resp: error }
	}

	return { status: 'success', resp: data }
}

export const downloadDocumentLabel = (shipmentId) => async () => {
	const [error, data] = await reqDownloadDocumentLabel(shipmentId)

	if (error) {
		return { data: null }
	}
	return { data }
}

export const downloadShipmentDocument = (shipmentId, params) => async () => {
	const [error, data] = await reqDownloadShipmentDocument(shipmentId, params)

	if (error) {
		return { data: null }
	}
	return { data }
}

export const downloadDocumentBlob = (shipmentId) => async () => {
	const [error, data] = await reqDownloadDocumentBlob(shipmentId)

	if (error) {
		return { data: null }
	}
	return { data }
}

export const addNotification = (shipmentId, params) => async (dispatch) => {
	const [error, data] = await reqAddNotification(shipmentId, params)

	if (error) {
		dispatch(hasError(error))
		return { status: 'error', resp: data.message || data.err.message }
	}

	return { status: 'success', resp: data }
}

export const removeNotification = (shipmentId, notificationId) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqRemoveNotification(shipmentId, notificationId)
	if (error) {
		dispatch(hasError(error))
		return { status: 'error', resp: data.message || data.err.message }
	}
	return { status: 'success', resp: data }
}

export const importShipmentToMG = (shipmentId) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqImportShipmentToMG(shipmentId)
	if (error) {
		dispatch(hasError(error))
		return { status: 'error', resp: data.message || data.err.message }
	}
	return { status: 'success', resp: data.data.data }
}

export default reducer
