import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	newShipmentItems: {
		width: '200px',
		fontWeight: '700',
		fontSize: '14px',
		color: theme.palette.secondary.main,
	},
	detailsColumn: {
		display: 'flex',
		alignItems: 'center',
		columnGap: '10px',
	},
	newShipmentItemsUnderlined: {
		textDecoration: 'underline',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		WebkitLineClamp: 1,
		WebkitBoxOrient: 'vertical',
	},
	table: {
		minWidth: 700,
		border: '1px solid',
		borderColor: theme.palette.neutral.lightGray,
	},
	tableCell: {
		fontSize: '14px',
	},
	tableCellHead: {
		backgroundColor: theme.palette.neutral.whiteGray,
		fontSize: 12,
		fontWeight: theme.typography.fontWeightBold,
	},
	tableCellNumber: {
		textDecoration: 'underline',
		fontSize: '14px',
	},
	shipmentItemAddedId: {
		color: '#FF3333',
		fontWeight: 700,
		textDecoration: 'underline',
		cursor: 'pointer',
	},
}))

export default useStyles
