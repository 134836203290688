import { axiosQuotes } from '../../utils/apiClient'

const reqGetTemplateItems = async (params) => {
	const [error, data] = await axiosQuotes
		.get(`/template/item${params}`)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export const reqGetTemplateItemById = async (params) => {
	const [error, data] = await axiosQuotes
		.get(`/template/item${params}`)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export const reqCreateTemplateItem = async (body) => {
	const [error, data] = await axiosQuotes
		.post('/template/item', body)
		.then((response) => [null, response])
		.catch((err) => [err, err])
	return [error, data]
}

export const reqEditTemplateItem = async (params, body) => {
	const [error, data] = await axiosQuotes
		.put(`/template/item/${params}`, body)
		.then((response) => [null, response])
		.catch((err) => [err, err])
	return [error, data]
}

export const reqEditTemplateItemDefault = async (templateItemId, params) => {
	const [error, data] = await axiosQuotes
		.put(`/template/item/${templateItemId}/default?value=${params}`)
		.then((response) => [null, response])
		.catch((err) => [err, err])
	return [error, data]
}

export const reqDeleteTemplateItem = async (templateItemId) => {
	const [error, data] = await axiosQuotes
		.delete(`/template/item/${templateItemId}`)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export const reqCreateTemplateItemDetail = async (templateItemId, body) => {
	const [error, data] = await axiosQuotes
		.post(`/template/item/${templateItemId}/detail`, body)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export const reqDeleteTemplateItemDetail = async (templateItemId, templateItemDetailId) => {
	const [error, data] = await axiosQuotes
		.delete(`/template/item/${templateItemId}/detail/${templateItemDetailId}`)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export default reqGetTemplateItems
