import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	toolbar: {
		position: 'sticky',
		top: 0,
		minHeight: 57,
		marginBottom: 12,
		backgroundColor: theme.palette.primary.main,
		borderBottom: `1px solid ${theme.palette.primary.light}`,
		zIndex: 1,
		display: 'flex',
		width: '100%',
		alignItems: 'center',
	},
	sidebarLogo: {
		display: 'block',
		height: '100%',
		transition: theme.transitions.create('height', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		objectFit: 'contain',
		width: '60%',
	},
	sidebarClosedLogo: {
		padding: '10px',
		marginLeft: '5px',
		display: 'flex',
		height: '100%',
		transition: theme.transitions.create('height', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		objectFit: 'contain',
		width: '90%',
	},
	sidebarItemList: {
		...theme.typography.h6,
		padding: '10px 20px',
		color: theme.palette.neutral.darkGray,
		boxSizing: 'border-box',
		'&:hover': {
			backgroundColor: theme.palette.primary.lighter,
		},
		'&.Mui-selected': {
			backgroundColor: theme.palette.primary.lighter,
			color: theme.palette.neutral.white,
			boxShadow: `inset 4px 0 0 ${theme.palette.semantic.yellow.primary}`,
		},
		[theme.breakpoints.up('md')]: {
			height: 44,
			padding: '10px 37px',
			transition: theme.transitions.create('padding', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			'.isOpen &': {
				padding: '10px 13px 10px 20px',
			},
		},
	},
	listIcon: {
		fontSize: 24,
		minWidth: 'unset',
		marginRight: 11,
		color: 'inherit',
	},
	sidebarText: {
		color: 'inherit',
		[theme.breakpoints.up('md')]: {
			width: 0,
			overflow: 'hidden',
			opacity: 0,
			'.isOpen &': {
				transition: `width ${theme.transitions.duration.leavingScreen}ms ${theme.transitions.easing.sharp}, opacity ${theme.transitions.duration.leavingScreen}ms ${theme.transitions.easing.sharp} ${theme.transitions.duration.leavingScreen}ms`,
				width: '100%',
				opacity: 1,
			},
		},
	},
	sidebarSubtitle: {
		padding: '10px 20px',
		fontSize: 11,
		fontWeight: theme.typography.fontWeightRegular,
		textTransform: 'uppercase',
		color: theme.palette.neutral.darkGray,
		lineHeight: 'normal',
		'& div': {
			display: 'none',
		},
		'& div.visible': {
			display: 'block',
		},
		[theme.breakpoints.up('md')]: {
			'& div': {
				textAlign: 'center',
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
			},
			'.isOpen & div': {
				textAlign: 'left',
			},
		},
	},
	collapseButton: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'block',
		},
	},
}))

export default useStyles
