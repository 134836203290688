import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { resetFilters } from '../../../../redux/actions/CarriersFilters.actions'
import CarriersListContainer from '../containers/CarriersListContainer'

const CarriersList = () => {
	const dispatch = useDispatch()
	useEffect(
		() => async () => {
			await dispatch(resetFilters())
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	)
	return <CarriersListContainer />
}

export default CarriersList
