/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, forwardRef, useImperativeHandle } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, Grid } from '@material-ui/core'
import { SupervisorAccount, Add, NoteAdd, BackupOutlined } from '@material-ui/icons'
import axiosUser from '../../../../../utils/apiClient'
import SessionContext from '../../../../../context/session'
import useStyles from './styles'
import MultiSelectedForm from './components/MultiSelectedForm/MultiSelectedForm'
import TableSelectedUSer from './components/TableSelectedUser/TableSelectedUser'
import SweetAlertModal from '../../../../../components/SweetAlertModal'
import NewUserModal from './components/NewUserModal'
import UploadLocationsModal from '../../../../locations/myLocations/containers/LocationsContainer/components/UploadLocationsModal/UploadLocationsModal'

const UsersStepper = forwardRef(
	({ setActiveStep, activeStep, isEditable, setIsPreviousStepTwo, handleCancelAccountProcess }, refGlobal) => {
		const currentTabs = useSelector((state) => state.multiTabs.tabs)
		const { getUserData } = useContext(SessionContext)
		const { actualCompany } = getUserData()
		const classes = useStyles()
		const [selected, setSelected] = useState([])
		const [confirmUsers, setConfirmUsers] = useState([])
		// const [removeSelected, setRemoveSelected] = useState([]);
		const [allUsers, setAllUsers] = useState([])
		const [loading, setLoading] = useState(true)
		const [disabledButtonNext, setDisabledButtonNext] = useState(false)
		const [disabledEditButtons, setDisabledEditButtons] = useState({
			next: false,
			save: false,
		})
		const [accountData, setAccountData] = useState({})
		const [openModal, setOpenModal] = useState(false)
		const [modalOpenMassUploadLocations, setModalOpenMassUploadLocations] = useState(false)

		const config = {
			headers: { 'X-Company-id': Number(actualCompany) },
		}
		const AccountsTabs = currentTabs.find((item) => item.active) ?? {}
		const handleConfirmUser = () => {
			const newData = [...confirmUsers, ...selected]
			const unique = [...new Set(newData.map((data) => data.id))].map((id) => newData.find((a) => a.id === id))
			setConfirmUsers(unique)
			setSelected([])
		}
		// const handleQuitSelected = () => {
		//   const result = [...confirmUsers].filter((origin) => {
		//     const exist = [...removeSelected].findIndex((quit) => origin.id === quit);
		//     if (exist === -1) {
		//       return true;
		//     }
		//     return false;
		//   });
		//   setConfirmUsers(result);
		//   setRemoveSelected([]);
		// };
		const usersAdd = () => {
			const listAdd = []
			confirmUsers.forEach((element) => {
				if (allUsers.length > 0) {
					allUsers.forEach((element2) => {
						if (!element.user_id) {
							if (element.id !== element2.user_id) {
								listAdd.push(+element.id)
							}
						}
					})
				} else {
					listAdd.push(+element.id)
				}
			})
			const distinct = [...new Set(listAdd)]
			return distinct
		}
		const userRemove = () => {
			const listRemove = []
			// skip and hardcode to empty list
			// allUsers.forEach((element) => {
			//   const isResult = confirmUsers.findIndex((item) => element.user_id === item.user_id);
			//   if (isResult === -1) listRemove.push(+element.user_id);
			// });
			return listRemove
		}
		const saveOrDeleteUsers = async () => {
			const { accounts } = AccountsTabs
			if (isEditable) {
				setDisabledEditButtons({ save: true, next: true })
			} else {
				setDisabledButtonNext(true)
			}
			const body = {
				childCompanies: accounts?.childrenCompaniesIds ?? [],
				usersToAdd: usersAdd(),
				usersToDelete: userRemove(),
			}
			const { status } = await axiosUser.post(`/company/${accounts.parentCompanyId}/users/`, body, config)
			if (isEditable) {
				setDisabledEditButtons({ save: false, next: false })
			} else {
				setDisabledButtonNext(false)
			}
			if (status === 200) {
				setActiveStep(activeStep + 1)
			} else {
				SweetAlertModal('error', 'There was an error updating the request', null, 'Ok', true, false, null)
			}
		}
		const saveEditAccount = async () => {
			const { accounts } = AccountsTabs
			setDisabledEditButtons({ save: true, next: true })
			const body = {
				childCompanies: accounts?.childrenCompaniesIds ?? [],
				usersToAdd: usersAdd(),
				usersToDelete: userRemove(),
			}
			const { status } = await axiosUser.post(`/company/${accounts.parentCompanyId}/users/`, body, config)
			setDisabledEditButtons({ save: false, next: false })
			if (status === 200) {
				SweetAlertModal('success', 'Users has been updated successfully!', null, 'Ok', true, false, null)
			} else {
				SweetAlertModal('error', 'There was an error updating the request', null, 'Ok', true, false, null)
			}
		}
		const fetchData = async () => {
			const { accounts } = AccountsTabs
			const { data, status } = await axiosUser.get(`/company/${accounts.parentCompanyId}/users`, config)
			if (status === 200) {
				const newOptions = data?.data ?? []
				setConfirmUsers(newOptions)
				setAllUsers(newOptions)
				setAccountData(data?.data[0])
				setLoading(false)
			}
		}
		useEffect(() => {
			fetchData()
		}, [actualCompany])

		useImperativeHandle(refGlobal, () => ({
			onSubmit() {
				saveOrDeleteUsers()
			},
		}))

		return (
			<>
				{!loading && (
					<>
						<div className={classes.container}>
							<div className={classes.containerTitle}>
								<div className={classes.containerTitleUsersAndNewUsers}>
									<SupervisorAccount color="primary" />
									<span className={classes.textTitle}>Users</span>
								</div>
								<div className={classes.containerButtonModal}>
									<Button
										className={classes.headerActionButton}
										startIcon={<BackupOutlined color="secondary" />}
										size="small"
										color="primary"
										onClick={() => setModalOpenMassUploadLocations(true)}
									>
										Upload Multiple Locations
									</Button>
									<div
										className={`${classes.containerTitleUsersAndNewUsers} ${classes.containerClick}`}
										onClick={() => setOpenModal(true)}
										role="button"
										onKeyDown={() => {}}
										onKeyPress={() => {}}
										onKeyUp={() => {}}
										tabIndex={0}
									>
										<NoteAdd color="secondary" />
										<span>Create New User</span>
									</div>
								</div>
							</div>
							<span className={classes.textSubTitle}>Select the users you want to add to this account</span>
							<div className={classes.containerUsers}>
								<MultiSelectedForm
									nameKeyLabelOptions="ke1"
									labelOutline="User name"
									placesHolderText="Add user"
									setSelected={setSelected}
									selected={selected}
								/>
								<div
									className={classes.containerAddUser}
									onClick={handleConfirmUser}
									role="button"
									onKeyDown={() => {}}
									onKeyPress={() => {}}
									onKeyUp={() => {}}
									tabIndex={0}
								>
									<Add color="secondary" />
									<span>Add Users</span>
								</div>
							</div>
							<div className={classes.containerTable}>
								<TableSelectedUSer
									users={confirmUsers}
									// removeSelected={removeSelected}
									// setRemoveSelected={setRemoveSelected}
									// handleQuitSelected={handleQuitSelected}
								/>
							</div>
						</div>
					</>
				)}
				<Grid container className={classes.containerButton}>
					{isEditable && (
						<>
							<Button
								className={classes.saveAccountButtomEdit}
								variant="contained"
								color="secondary"
								onClick={saveEditAccount}
								disabled={disabledEditButtons.save}
							>
								Save Account
							</Button>
							<Button
								className={classes.nextButtomEdit}
								disabled={disabledEditButtons.next}
								onClick={saveOrDeleteUsers}
							>
								Next
							</Button>
						</>
					)}
					{!isEditable && (
						<Button
							className={classes.nextStepButton}
							onClick={saveOrDeleteUsers}
							variant="contained"
							color="secondary"
							disabled={disabledButtonNext}
						>
							Next Step
						</Button>
					)}
					<Button
						className={classes.previousButton}
						variant="contained"
						onClick={() => {
							setActiveStep(activeStep - 1)
							setIsPreviousStepTwo(true)
						}}
					>
						Previous
					</Button>
					<Button className={classes.cancelButton} onClick={() => handleCancelAccountProcess()}>
						Cancel
					</Button>
				</Grid>
				<NewUserModal
					isOpen={openModal}
					setIsOpen={setOpenModal}
					updateUsersTable={fetchData}
					accountData={accountData}
				/>
				{modalOpenMassUploadLocations && (
					<UploadLocationsModal
						open={modalOpenMassUploadLocations}
						handleClose={() => setModalOpenMassUploadLocations(false)}
					/>
				)}
			</>
		)
	},
)

UsersStepper.propTypes = {
	setActiveStep: PropTypes.func.isRequired,
	activeStep: PropTypes.number.isRequired,
	setIsPreviousStepTwo: PropTypes.func.isRequired,
	isEditable: PropTypes.bool.isRequired,
	handleCancelAccountProcess: PropTypes.func.isRequired,
}

export default UsersStepper
