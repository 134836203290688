/* eslint-disable no-param-reassign */
import { createSlice, Dispatch } from '@reduxjs/toolkit'
import {
	PatchQuoteRequest,
	CreateQuoteRequest,
	EditQuoteRequest,
	SendEmailQuoteRequest,
	UpdateItemHazmatInfoRequest,
	UpdateQuoteStatusRequest,
} from '../requests/Quotes.types'
import reqGetQuotes, {
	reqCreateQuote,
	reqUpdateQuoteStatus,
	reqValidateQuoteExpiration,
	reqEditQuote,
	reqUpdateItemHazmatInfo,
	reqSendEmailQuote,
	reqEditQuoteUpdateDate,
} from '../requests/Quotes.requests'
import type { SetQuoteInfo } from './Quotes.types'

const quotesSlice = createSlice({
	name: 'quotesSlice',
	initialState: {
		quotes: [],
		createQuoteInfo: {},
		filterApplied: '',
		isLoading: false,
		error: false,
	},
	reducers: {
		startLoading: (state) => {
			state.isLoading = true
		},
		hasError: (state, action) => {
			state.error = action.payload
			state.isLoading = false
		},
		quotesSuccess: (state, action) => {
			state.quotes = action.payload.quotes
			state.totalRecords = action.payload.records
			state.isLoading = false
		},
		quotesBody: (state, action) => {
			state.quotesBody = action.payload
		},
		crateQuoteInfoSuccess: (state, action) => {
			state.createQuoteInfo = action.payload
		},
		updatePagSkip: (state, action) => {
			state.pagSkip = action.payload
		},
		updateFilterApplied: (state, action) => {
			state.filterApplied = action.payload
		},
	},
})

const { actions, reducer } = quotesSlice

export const {
	startLoading,
	hasError,
	quotesSuccess,
	updatePagSkip,
	crateQuoteInfoSuccess,
	updateFilterApplied,
	quotesBody,
} = actions

export const fetchQuotes = (params: string) => async (dispatch: Dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqGetQuotes(params)
	if (error) {
		dispatch(hasError(error))
		return { status: 'error', resp: error }
	}
	dispatch(quotesSuccess(data.data.data))
	return { status: 'success' }
}

export const updatePaginator = (params: number) => async (dispatch: Dispatch) => {
	dispatch(updatePagSkip(params))
}

/** the real type here is a dynamically created Quote object in newQuoteStepsContainer.  */
export const setCreateQuoteInfo = (params: SetQuoteInfo) => async (dispatch: Dispatch) => {
	dispatch(crateQuoteInfoSuccess(params))
}

export const createQuote = (quoteBody: CreateQuoteRequest) => async (dispatch: Dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqCreateQuote(quoteBody)
	if (error) return { status: 'error', resp: error.message }
	return { status: 'success', resp: data.data.data }
}

export const editQuote = (quoteId: string | number, body: EditQuoteRequest) => async (dispatch: Dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqEditQuote(quoteId, body)

	if (error) return { status: 'error', resp: error }
	return { status: 'success', resp: data.data.data }
}

export function editQuoteDate(quoteId: number, patchRequest: PatchQuoteRequest): (dispatch: Dispatch) => Promise<any> {
	return async () => {
		const [error, data] = await reqEditQuoteUpdateDate(quoteId, patchRequest)
		if (error) return { status: 'error', resp: error }
		return { status: 'success', resp: data.data }
	}
}

export const updateQuoteStatus =
	(quoteId: string | number, body: UpdateQuoteStatusRequest) => async (dispatch: Dispatch) => {
		dispatch(startLoading())
		const [error, data] = await reqUpdateQuoteStatus(quoteId, body)

		if (error) return { status: 'error', resp: error }

		return { status: 'success', resp: data.data.message }
	}

export const validateQuoteExpiration =
	(quoteId: string | number, rateId: string | number) => async (dispatch: Dispatch) => {
		dispatch(startLoading())
		const [error, data] = await reqValidateQuoteExpiration(quoteId, rateId)

		if (error) return { statusExpiration: 'error', resp: data }

		return { statusExpiration: 'success', resp: data.data.message }
	}

export const updateFilter = (params: any) => async (dispatch: Dispatch) => {
	dispatch(updateFilterApplied(params))
}

export const updateItemHazmatInfo =
	(quoteId: string | number, itemId: string | number, body: UpdateItemHazmatInfoRequest) =>
	async (dispatch: Dispatch) => {
		dispatch(startLoading())
		const [error, data] = await reqUpdateItemHazmatInfo(quoteId, itemId, body)

		if (error) return { status: 'error', resp: data }

		return { status: 'success', resp: data.data.message }
	}

export const sendEmailQuote =
	(quoteId: string | number, rateId: string | number, body: SendEmailQuoteRequest) => async (dispatch: Dispatch) => {
		const [error, data] = await reqSendEmailQuote(quoteId, rateId, body)

		if (error) {
			dispatch(hasError(error))
			return { status: 'error', resp: error }
		}

		return { status: 'success', resp: data.data.data }
	}

export default reducer
