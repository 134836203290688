/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import reqGetInvoiceDetails from '../requests/InvoiceDetails.request'

const invoiceDetailsSlice = createSlice({
	name: 'invoiceDetailsSlice',
	initialState: {
		invoiceDetails: {},
		isLoading: false,
		error: false,
	},
	reducers: {
		startLoading: (state) => {
			state.isLoading = true
		},
		hasError: (state, action) => {
			state.error = action.payload
			state.isLoading = false
		},
		invoiceDetailsSuccess: (state, action) => {
			state.invoiceDetails = action.payload
			state.isLoading = false
		},
	},
})

const { actions, reducer } = invoiceDetailsSlice

export const { startLoading, hasError, invoiceDetailsSuccess } = actions

export const fetchInvoiceDetails = (params) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqGetInvoiceDetails(params)

	if (error) {
		dispatch(hasError(error))
	} else {
		dispatch(invoiceDetailsSuccess(data.data.data.invoice))
	}
}

export const clearInvoiceDetails = (params) => async (dispatch) => {
	dispatch(invoiceDetailsSuccess(params))
}

export default reducer
