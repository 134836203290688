import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
	title: {
		fontSize: 15,
		color: theme.palette.secondary.main,
		fontWeight: theme.typography.fontWeightMedium,
	},
	containerSearch: {
		marginTop: 16,
		width: '100%',
	},
}))
