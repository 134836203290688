const isPermit = (permissionList, permit) => {
	let localIsPermit = false
	const localPermission = Array.isArray(permissionList) ? permissionList : []
	localPermission.forEach((element) => {
		const name = element?.name ?? ''
		const isActive = element?.is_active ?? false
		if (name === permit && isActive) {
			localIsPermit = true
		}
	})
	return localIsPermit
}

export default isPermit
