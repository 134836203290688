import React from 'react'
import PropTypes from 'prop-types'

import { FormControlLabel, Checkbox } from '@material-ui/core'
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined'

import { setShipmentCheckboxesValues } from '../../../utils/helpers'

import useStyles from '../styles'

const ViewShipmentModule = (props) => {
	const { viewShipmentModule, setViewShipmentModule, setCheckboxesValues, checkboxesValues, onChangeCheckboxValue } =
		props
	const classes = useStyles()

	return (
		<div>
			<FormControlLabel
				className={classes.checkBoxLabel}
				control={
					<Checkbox
						indeterminate={viewShipmentModule}
						indeterminateIcon={<IndeterminateCheckBoxOutlinedIcon />}
						color="secondary"
						className={classes.checkBox}
						checked={viewShipmentModule}
						onChange={(event) => {
							const { checked } = event.target
							setViewShipmentModule(checked)
							setCheckboxesValues((prevState) => ({
								...prevState,
								...setShipmentCheckboxesValues({ checked }),
							}))
						}}
					/>
				}
				label="View Shipment Module"
			/>
			<div className={classes.checkBoxesColumn}>
				<FormControlLabel
					className={classes.checkBoxLabel}
					control={
						<Checkbox
							color="secondary"
							className={classes.checkBox}
							checked={checkboxesValues.viewShipmentDetails}
							onChange={(event) => onChangeCheckboxValue(event, 'viewShipmentDetails')}
						/>
					}
					label="View shipment details"
				/>
				<FormControlLabel
					className={classes.checkBoxLabel}
					control={
						<Checkbox
							color="secondary"
							className={classes.checkBox}
							checked={checkboxesValues.downloadBol}
							onChange={(event) => onChangeCheckboxValue(event, 'downloadBol')}
						/>
					}
					label="Download BOL"
				/>
				<FormControlLabel
					className={classes.checkBoxLabel}
					control={
						<Checkbox
							color="secondary"
							className={classes.checkBox}
							checked={checkboxesValues.downloadLabel}
							onChange={(event) => onChangeCheckboxValue(event, 'downloadLabel')}
						/>
					}
					label="Download the label"
				/>
				<FormControlLabel
					className={classes.checkBoxLabel}
					control={
						<Checkbox
							color="secondary"
							className={classes.checkBox}
							checked={checkboxesValues.contactSupport}
							onChange={(event) => onChangeCheckboxValue(event, 'contactSupport')}
						/>
					}
					label="Contact support"
				/>
				<FormControlLabel
					className={classes.checkBoxLabel}
					control={
						<Checkbox
							color="secondary"
							className={classes.checkBox}
							checked={checkboxesValues.cloneShipment}
							onChange={(event) => onChangeCheckboxValue(event, 'cloneShipment')}
						/>
					}
					label="Clone the shipment"
				/>
				<FormControlLabel
					className={classes.checkBoxLabel}
					control={
						<Checkbox
							color="secondary"
							className={classes.checkBox}
							checked={checkboxesValues.removeShipment}
							onChange={(event) => onChangeCheckboxValue(event, 'removeShipment')}
						/>
					}
					label="Remove shipment"
				/>
			</div>
		</div>
	)
}

ViewShipmentModule.propTypes = {
	viewShipmentModule: PropTypes.bool.isRequired,
	setViewShipmentModule: PropTypes.func.isRequired,
	setCheckboxesValues: PropTypes.func.isRequired,
	checkboxesValues: PropTypes.objectOf(PropTypes.any).isRequired,
	onChangeCheckboxValue: PropTypes.func.isRequired,
}

export default ViewShipmentModule
