import React, { useEffect, useState, useCallback, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as moment from 'moment'
import { Grid, IconButton, useTheme } from '@material-ui/core'
import { FilterList, Loop } from '@material-ui/icons'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import Button from '@material-ui/core/Button'

import {
	setCurrentPage,
	setRowsPerPage,
	setStartDate,
	setEndDate,
	setSearchBar,
	resetFilters,
	setStatus,
	setOrderByAndOrder,
} from '../../../redux/actions/InvoicesFilters.actions'
import useInvoicesFilters from '../../../hooks/useInvoicesFilters'
import { fetchInvoices } from '../../../redux/actions/Invoices.actions'

import { clearQuoteDetailsForReQuote } from '../../../redux/actions/QuoteDetails.actions'

import AutoCompleteInput from '../components/AutocompleteInput'
import RangeDatePicker from '../../../components/RangeDatePicker'
import SearchBar from '../../../components/SearchBar'
import InvoicesTable from '../components/TableList'

import { clearTab, setTabCurrentRoute, setTabTitle } from '../../../redux/actions/MultiTabs.actions'
import { fetchAccountSettings } from '../../../redux/actions/Accounts.actions'

import sweetAlertModal from '../../../components/SweetAlertModal/SweetAlertModal'
import { invoicesListRoute, makeAPaymentURL } from '../../../utils/constants'
import SessionContext from '../../../context/session'
import useStyles from './styles'
// eslint-disable-next-line import/no-unresolved
import ModuleHeader from '../../../components/ModuleHeader/index'

const getArrayFromValues = (values) => [values]

const InvoicesContainer = () => {
	const theme = useTheme()
	const classes = useStyles()
	const { getUserData } = useContext(SessionContext)
	const { actualCompany } = getUserData()
	const dispatch = useDispatch()
	const matches = useMediaQuery('(min-width:1024px)')
	const [buildParamsFromState] = useInvoicesFilters()
	const [startDate, setNewStartDate] = useState(null)
	const [endDate, setEndDates] = useState(null)
	const [open, setOpen] = useState(false)
	const { searchBar } = useSelector((state) => state.invoicesFilters)
	const [search, setSearch] = useState(decodeURIComponent(searchBar))
	const [autoCompleteValues, setAutoComplete] = useState([])
	const [pageReset, setPageReset] = useState('')
	const [orderByLabel, setOrderByLabel] = useState('number')
	const [order, setOrder] = useState('asc')
	const [enablePaymentService, setEnablePaymentService] = useState('')

	const InvoicesProvider = () => {
		const callback = useCallback(async (params) => {
			const { status } = await dispatch(fetchInvoices(params))
			if (status === 'error')
				sweetAlertModal('error', 'There was an error fetching the invoices', null, 'Ok', true, false)
		}, [])

		return { callback }
	}

	const filterParams = buildParamsFromState()
	const { callback } = InvoicesProvider()

	useEffect(() => {
		dispatch(clearTab())
		dispatch(setTabCurrentRoute(invoicesListRoute))
		dispatch(setTabTitle('Invoices List'))
		dispatch(clearQuoteDetailsForReQuote(''))
	}, [dispatch])

	useEffect(() => {
		const fetchSettings = async () => {
			const config = {
				headers: { 'X-Company-id': actualCompany },
			}
			const { status, resp } = await dispatch(fetchAccountSettings(config))
			if (status === 'success') {
				const { settings } = resp
				if (settings.enablePaymentService) {
					setEnablePaymentService(settings?.enablePaymentService?.value === 'true' ? 'visible' : 'hidden')
				} else setEnablePaymentService('visible')
			} else {
				sweetAlertModal('error', 'There was an error fetching the account settings', resp, 'Ok', true, false, null)
			}
		}
		fetchSettings()
	}, [dispatch, actualCompany])

	useEffect(() => {
		const preservedFilters = localStorage.getItem('preserved-invoice-filters')
		if (preservedFilters) {
			const filterObj = preservedFilters.split('&')
			filterObj.forEach((filter) => {
				const filterKeyValue = filter.split('=')
				switch (filterKeyValue[0]) {
					case 'status':
						dispatch(setStatus(getArrayFromValues(filterKeyValue[1])))
						setAutoComplete(filterKeyValue[1].split(',').map((status) => ({ title: status })))
						break
					case 'search_bar':
						dispatch(setSearchBar(filterKeyValue[1]))
						setSearch(decodeURIComponent(filterKeyValue[1]))
						break
					case 'start_date':
						dispatch(setStartDate(filterKeyValue[1]))
						setNewStartDate(filterKeyValue[1])
						break
					case 'end_date':
						dispatch(setEndDate(filterKeyValue[1]))
						setEndDates(filterKeyValue[1])
						break
					case 'order_by':
						dispatch(setOrderByAndOrder({ orderBy: filterKeyValue[1], order }))
						setOrderByLabel(filterKeyValue[1])
						break
					case 'order':
						dispatch(setOrderByAndOrder({ orderBy: orderByLabel, order }))
						setOrder(filterKeyValue[1])
						break
					default:
						dispatch(setCurrentPage(filterKeyValue[0].split(/[/?]/)[1]))
						dispatch(setRowsPerPage(filterKeyValue[0].split(/[/?]/)[2]))
						break
				}
			})
		}
	}, [dispatch, orderByLabel, order])

	useEffect(() => {
		callback(filterParams)
	}, [callback, filterParams])

	const toggleDrawer = (openDrawer) => (event) => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return
		}
		setOpen(openDrawer)
	}

	const showFilters = () => {
		setOpen(true)
	}

	const handleStartDate = (selectedDate) => {
		const newStartDate = moment(selectedDate).format('YYYY-MM-DD')
		if (moment(newStartDate).isAfter(new Date())) {
			sweetAlertModal('error', 'Please select a valid date range', null, 'Ok', true, false)
			dispatch(setStartDate(moment(new Date()).format('YYYY-MM-DD')))
			setNewStartDate(new Date())
			setPageReset(new Date())
		} else {
			setNewStartDate(selectedDate)
			dispatch(setStartDate(newStartDate))
			dispatch(setCurrentPage(0))
			setPageReset(selectedDate)
		}
	}

	const handleEndDate = async (selectedDate) => {
		const newEndDate = moment(selectedDate).format('YYYY-MM-DD')
		if (moment(startDate).isAfter(selectedDate)) {
			sweetAlertModal('error', 'Please select a valid date range', null, 'Ok', true, false)
			setEndDates(new Date())
			setPageReset(new Date())
		} else {
			setEndDates(selectedDate)
			dispatch(setEndDate(newEndDate))
			dispatch(setCurrentPage(0))
			setPageReset(newEndDate)
		}
	}

	const handleSearchBarChanged = (event) => {
		setSearch(event.target.value)
		if (event.target.value.length >= 3) {
			dispatch(setSearchBar(encodeURIComponent(event.target.value)))
			setPageReset(event.target.value)
		} else if (event.target.value.length === 0) {
			dispatch(setSearchBar(''))
			setPageReset(event.target.value)
		}
	}

	const handleSortColumn = (column) => {
		setOrderByLabel(column)
		const newOrder = order === 'asc' ? 'desc' : 'asc'
		setOrder(newOrder)
		dispatch(setOrderByAndOrder({ orderBy: column, order: newOrder }))
	}

	const handleResetFilters = () => {
		dispatch(resetFilters())
		dispatch(setEndDate(''))
		dispatch(setStartDate(''))
		setSearch('')
		setEndDates(null)
		setNewStartDate(null)
		setAutoComplete([])
		setPageReset(false)
		localStorage.removeItem('preserved-invoice-filters')
	}

	const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

	return (
		<>
			<Grid container alignItems="center" justifyContent="space-between">
				<Grid item xs={12} md={10}>
					<ModuleHeader
						title={
							<div className={classes.invoiceHistoryTitleButton}>
								<h1 className={classes.invoiceHistoryTitle}>Invoices</h1>
								<IconButton aria-label="Toggle Filters" onClick={showFilters} className={classes.filterTriggerButton}>
									<FilterList />
								</IconButton>
							</div>
						}
						subtitle="Consult the invoices created in the system."
					/>
				</Grid>
				<Grid item xs={12} md={2}>
					{enablePaymentService === 'visible' && (
						<Button
							startIcon={<AttachMoneyIcon htmlColor={theme.palette.success.main} />}
							target="_blank"
							href={makeAPaymentURL}
							className={classes.makeAPaymentButton}
						>
							Make a Payment
						</Button>
					)}
				</Grid>
			</Grid>
			<SwipeableDrawer
				className={classes.drawer}
				anchor="top"
				open={open}
				onClose={toggleDrawer(false)}
				disableBackdropTransition={!iOS}
				disableDiscovery={iOS}
			>
				<div className={classes.filterFormWrapper} role="presentation">
					<Grid container spacing={2} alignItems="center">
						<Grid item xs={12}>
							<AutoCompleteInput
								values={autoCompleteValues}
								setUpdate={(value) => {
									setPageReset(value)
									dispatch(setCurrentPage(0))
									setAutoComplete(value)
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<RangeDatePicker
								label="Filter by invoice initial date"
								valueDate={startDate}
								setValueDate={handleStartDate}
							/>
						</Grid>
						<Grid item xs={12}>
							<RangeDatePicker label="Filter by invoice final date" valueDate={endDate} setValueDate={handleEndDate} />
						</Grid>
						<Grid item xs={12}>
							<Button
								onClick={handleResetFilters}
								color="primary"
								startIcon={<Loop />}
								className={classes.filterButtonModal}
							>
								<span className={classes.filterButtonModalText}>Reset Filters</span>
							</Button>
						</Grid>
					</Grid>
				</div>
			</SwipeableDrawer>
			<Grid container spacing={2} alignItems="center">
				{matches ? (
					<>
						<Grid item xs={12} md={4}>
							<AutoCompleteInput
								values={autoCompleteValues}
								setUpdate={(value) => {
									setPageReset(value)
									dispatch(setCurrentPage(0))
									setAutoComplete(value)
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<RangeDatePicker
								label="Filter by invoice initial date"
								valueDate={startDate}
								setValueDate={handleStartDate}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<RangeDatePicker label="Filter by invoice final date" valueDate={endDate} setValueDate={handleEndDate} />
						</Grid>
					</>
				) : null}
				<Grid item xs={12} md={12} lg={10}>
					<SearchBar
						value={search}
						placeholder="Search by Invoice Number or BOL Number…"
						onChangeHandler={handleSearchBarChanged}
					/>
					{enablePaymentService === 'visible' && (
						<Button
							startIcon={<AttachMoneyIcon htmlColor={theme.palette.success.main} />}
							target="_blank"
							href={makeAPaymentURL}
							className={classes.makeAPaymentButton2}
						>
							Make a Payment
						</Button>
					)}
				</Grid>
				{matches ? (
					<Grid item xs={2}>
						<Button
							onClick={handleResetFilters}
							color="secondary"
							startIcon={<Loop color="secondary" />}
							className={classes.filterButton}
						>
							<span className={classes.filterButtonText}>Reset Filters</span>
						</Button>
					</Grid>
				) : null}
			</Grid>
			<InvoicesTable tableType="invoices" pageReset={pageReset} handleSortColumn={handleSortColumn} />
		</>
	)
}

export default InvoicesContainer
