/* eslint-disable prefer-destructuring */
import React from 'react'
import PropTypes from 'prop-types'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import useStyles from './styles'
import destructuringName from '../../../../../../../../../utils/destructuringName'
import { capitalizeFirstLetter, removeUnderScore } from '../../../../../../../../../utils/helpers'

const UsersListRows = (props) => {
	const classes = useStyles()
	const {
		// isItemSelected,
		data,
		// labelId,
		// setSelected, selected,
	} = props

	const formatField = (field) => capitalizeFirstLetter(removeUnderScore(field))

	return (
		<TableRow
			className={classes.tableRow}
			hover
			// role="checkbox"
			// aria-checked={isItemSelected}
			tabIndex={-1}
			key={data.id}
			// selected={isItemSelected}
		>
			{/* <TableCell>
        <Checkbox
          inputProps={{ 'aria-labelledby': labelId }}
          color="secondary"
          checked={isItemSelected}
          onChange={(e) => {
            if (e.target.checked) {
              setSelected([...selected, data.id]);
            } else {
              setSelected([...selected].filter((all) => all !== data.id));
            }
          }}
        />
      </TableCell> */}
			<TableCell className={classes.tableCell}>{data?.name ? destructuringName(data.name).name : ''}</TableCell>
			<TableCell className={classes.tableCell}>{data?.name ? destructuringName(data.name).lastName : ''}</TableCell>
			<TableCell className={classes.tableCell}>{data?.user_id ?? data?.id ?? ''}</TableCell>
			<TableCell className={classes.tableCell}>{data?.email ?? ''}</TableCell>
			<TableCell className={classes.tableCell}>{formatField(data?.profile ?? data?.role ?? '')}</TableCell>
		</TableRow>
	)
}

UsersListRows.propTypes = {
	// labelId: PropTypes.string.isRequired,
	// isItemSelected: PropTypes.bool.isRequired,
	data: PropTypes.arrayOf(PropTypes.any).isRequired,
	// setSelected: PropTypes.func.isRequired,
	// selected: PropTypes.arrayOf(PropTypes.any).isRequired,
}

export default UsersListRows
