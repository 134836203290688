import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	root: {
		'& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
			display: 'none',
		},
	},
	button: {
		height: '48px',
		fontSize: '14px',
		fontWeight: '500',
		color: '#141313',
		margin: theme.spacing(0, 0, 0),
		justifyContent: 'flex-end',
		textTransform: 'none',
		'&:hover': {},
		'@media only screen and (max-width: 768px)': {
			width: '140px',
		},
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginBottom: '10px',
			marginLeft: '0em',
		},
	},
	desktopTable: {
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},
	table: {
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginBottom: '10px',
			minWidth: 100,
		},
		[theme.breakpoints.down('sm')]: {
			marginLeft: '0px',
		},
	},
	tableContainer: {
		margin: '20px 0px 30px 10px',
		width: '90%',
		'&::-webkit-scrollbar': {
			width: '5px',
		},
		'&::-webkit-scrollbar-track': {
			'-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
			borderRadius: '10px',
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: '10px',
			'-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.5)',
		},
	},
	referencesMessageContainer: {
		margin: '0 0 30px 0',
		color: theme.palette.neutral.darkGray,
	},
	referencesMessageTitle: {
		textAlign: 'center',
		margin: '10px 0',
		fontSize: 17,
		fontWeight: theme.typography.fontWeightBold,
	},
	referencesMessage: {
		textAlign: 'center',
		margin: '10px 0',
		fontSize: 14,
		fontWeight: theme.typography.fontWeightRegular,
	},
	formContainer: {
		width: '700px',
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
	tableRow: {
		'&.Mui-selected, &.Mui-selected:hover': {
			backgroundColor: theme.palette.neutral.secondaryGrey,
		},
	},
	tableCell: {
		fontSize: '14px',
		fontWeight: theme.typography.fontWeightRegular,
		borderBottomStyle: 'none',
		padding: '5px',
		width: '30%',
	},
	tableCellIndex: {
		fontSize: '14px',
		fontWeight: theme.typography.fontWeightMedium,
		borderBottomStyle: 'none',
		padding: '5px',
		width: '10%',
	},
	tableCellHead: {
		borderBottomStyle: 'none',
		fontSize: '14px',
		fontWeight: theme.typography.fontWeightBold,
		width: '30%',
		padding: '5px',
	},
	tableCellHeadIndex: {
		borderBottomStyle: 'none',
		fontSize: '14px',
		fontWeight: theme.typography.fontWeightBold,
		width: '10%',
		padding: '5px',
	},
	tableCellNumber: {
		textDecoration: 'underline',
		fontSize: '14px',
		fontWeight: '600',
	},
	title: {
		fontSize: '16px',
		fontWeight: '600',
		color: '#2C2C2C',
		margin: '33px 0 12px 0',
	},
	newShipmentIcon: {
		color: theme.palette.secondary.main,
		fontSize: '25px',
	},
	tableIconReferenceCheck: {
		color: theme.palette.secondary.main,
	},
	tableIconReferenceX: {
		color: theme.palette.error.main,
	},
	newAccountTitleTable: {
		color: theme.palette.secondary.main,
		fontSize: '17px',
		marginTop: '2%',
		fontWeight: 700,
	},
}))

export default useStyles
