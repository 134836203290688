import { axiosQuotes } from '../../utils/apiClient'

const reqGetQuoteDetails = async (params) => {
	const [error, data] = await axiosQuotes
		.get(`/quote${params}`)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export default reqGetQuoteDetails
