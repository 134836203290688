import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	shipmentItemFormControl: {
		fontSize: '16px',
		backgroundColor: 'white',
	},
	shipmentItemsActionsContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-around',
	},
	shipmentItemContainer: {
		padding: '0 15px',
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			padding: '0',
		},
	},
	deleteButton: {
		fontWeight: theme.typography.fontWeightRegular,
		textTransform: 'capitalize',
		marginBottom: theme.spacing(1),
	},
	rowContainer: {
		marginTop: '2em',
	},
	deleteButtonContainer: {
		width: '98%',
		borderBottom: '1px solid #C9CFE4',
	},
	actionButton: {
		background: 'none',
		border: 'none',
		cursor: 'pointer',
		padding: 0,
		minWidth: '25px',
		'&:focus': {
			outline: 'none',
		},
		'&:hover': {
			backgroundColor: 'transparent',
		},
		'& span': {
			backgroundColor: 'transparent',
		},
	},
	actionIcon: {
		width: '25px',
		height: '25px',
	},
	root: {
		fontSize: '14px',
		backgroundColor: 'white',
		'& .MuiFormLabel-root': {
			fontSize: '14px',
		},
		'& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
			display: 'none',
			margin: 80,
		},
	},
	helperText: {
		fontSize: '11px',
		fontWeight: '400',
		color: '#A1A1A1',
		marginBottom: '8px',
	},
	popper: {
		zIndex: 2,
	},
	hazmatStackableLabel: {
		margin: '-2px 0 10px 0',
		padding: 0,
		fontSize: '11px',
		fontWeight: 400,
		color: 'rgba(0, 0, 0, 0.54)',
		lineHeight: 1,
		textAlign: 'center',
	},
}))

export default useStyles
