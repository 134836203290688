import React, { useState, useEffect, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import {
	Table,
	CircularProgress,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import moment from 'moment'
import MultiTabsContext from '../../../../context/multiTabs'
import { sortRates } from '../../../../redux/actions/Rates.actions'
import { fetchAccountSettings } from '../../../../redux/actions/Accounts.actions'

import TableRatesRow from '../../../../components/TableRates'
import AccordionElements from '../AccordionElements'

import { scrollToElement } from '../../../../utils/ScrollToElement'
import SessionContext from '../../../../context/session'

import { capitalizeFirstLetter, insuranceSettingsAreValid } from '../../../../utils/helpers'
import QuoteSavedDialog from '../QuoteSavedDialog/QuoteSavedDialog'
import SweetAlertModal from '../../../../components/SweetAlertModal'
import RatesSlider from '../RatesSlider'
import isPermit from '../../../../utils/permission'
import { DEFAULT_ADMIN_FEE, permissionName } from '../../../../utils/constants'
import NoResultsFound from '../../../../components/NoResultsFound'

import useStyles from './styles'
import InfoItems from './components/InfoItemsValues/InfoItemsValues'
import GoogleMapLoader from '../../../../components/GoogleMap/index'

const columns = [
	{ id: 'carrier', label: 'CARRIER', width: 250 },
	{ id: 'service', label: 'SERVICE INFORMATION', width: 200 },
	{
		id: 'liability',
		label: 'ESTIMATED CARRIER LIABILITY',
		width: 160,
		toltip:
			'This is the carrier’s estimated maximum liability in the event of a claim. ' +
			'We always recommend adding third party insurance to every shipment.',
	},
	{ id: 'rate', label: 'RATE', width: 190 },
	{ id: 'actions', label: 'ACTIONS', width: 200 },
]

const columnsRate = [
	{ id: 'carrier', label: 'CARRIER', width: 250 },
	{ id: 'service', label: 'SERVICE INFORMATION', width: 200 },
	{
		id: 'liability',
		label: 'ESTIMATED CARRIER LIABILITY',
		width: 160,
		toltip:
			'This is the carrier’s estimated maximum liability in the event of a claim. ' +
			'We always recommend adding third party insurance to every shipment.',
	},
	{ id: 'carrierRate', label: 'CARRIER RATE', width: 160 },
	{ id: 'rate', label: 'CUSTOMER RATE', width: 190 },
	{ id: 'actions', label: 'ACTIONS', width: 200 },
]

const NewQuoteShipmentStepTwo = ({
	originData,
	markers,
	destinationData,
	quoteCreatedId,
	totalWeight,
	accessorialsOrigin,
	accessorialsDestination,
	creationDate,
	shipmentItems,
	references,
	siteTypeOrigin,
	siteTypeDestination,
	pickUpDate,
	selectedRateId,
	setSelectedRateId,
	openQuoteSavedConfirm,
	onNextStepThree,
	linearFeet,
	openConfirmInsurance,
	setOpenConfirmInsurance,
	enableInsuranceOptIn,
	defaultDeclaredValue,
	declaredFreightValue,
	setDeclaredFreightValue,
	withInsurance,
	setWithInsurance,
	setInsuranceCost,
	setInsuranceValue,
}) => {
	const classes = useStyles()
	const { getUserData } = useContext(SessionContext)
	const { actualCompany, permissions } = getUserData()
	const { getQuoteData } = useContext(MultiTabsContext)
	const quoteRates = useSelector((state) => state.quoteRates)
	const [validRates, setValidRates] = useState([])
	const [selectedSort, setSelectedSort] = useState('totalCustomerRate')
	const dispatch = useDispatch()
	const [typeAmount, setTypeAmount] = useState('')
	const [typeAmountValue, setTypeAmountValue] = useState('0')
	const hasCarrierRateAuth = isPermit(permissions, permissionName.FP_ADMIN_CARRIERS_SHOW_RATE)
	const localColumns = hasCarrierRateAuth ? columnsRate : columns
	const [maxReplacementValue, setMaxReplacementValue] = useState(null)

	const globalConfig = useMemo(() => {
		const newConfig = {
			headers: {
				'X-Company-id': Number(actualCompany),
			},
		}
		return newConfig
	}, [actualCompany])

	const [invalidRates, setInvalidRates] = useState([])
	useEffect(() => {
		if (quoteRates.quoteRates) {
			// Filter the rate array to ensure the customer rate is a number
			// And that the customer rate is greater than zero
			const newInvalidRates = []
			const newValidRates = quoteRates.quoteRates.rates?.reduce((previousRates, currentRate) => {
				const invalidRate =
					currentRate.totalCustomerRate !== Number(currentRate.totalCustomerRate) || currentRate.totalCustomerRate <= 0

				if (invalidRate) {
					newInvalidRates.push({
						...currentRate,
						id: currentRate.rateId,
					})
				}

				return invalidRate ? previousRates : [...previousRates, currentRate]
			}, [])

			setValidRates(newValidRates)
			setInvalidRates(newInvalidRates)
		}
	}, [quoteRates, getQuoteData])

	useEffect(() => {
		;(async () => {
			const config = {
				headers: { 'X-Company-id': actualCompany },
			}
			const { status, resp } = await dispatch(fetchAccountSettings(config))
			if (status === 'success') {
				const { settings } = resp
				// Validate and set insurance settings
				if (
					insuranceSettingsAreValid({
						typeAmount: settings?.typeAmount?.value,
						typeAmountValue: settings?.typeAmountValue?.value,
					})
				) {
					setTypeAmount(settings.typeAmount.value)
					setTypeAmountValue(settings.typeAmountValue.value)
				} else {
					// Fallback to default settings when the given settings are invalid
					setTypeAmount('ADMIN_FEE')
					setTypeAmountValue(DEFAULT_ADMIN_FEE)
				}
			} else {
				SweetAlertModal('error', 'There was an error fetching the account settings', resp, 'Ok', true, false, null)
			}
		})()
	}, [dispatch, actualCompany])

	useEffect(() => {
		;(async () => {
			const { status, resp } = await dispatch(fetchAccountSettings(globalConfig))
			if (status === 'success') {
				const { settings } = resp
				const newMaxReplacementValue = settings?.maxReplacementValue?.value ?? null
				if (newMaxReplacementValue) {
					setMaxReplacementValue(Number(newMaxReplacementValue.replace(/[^0-9.]/g, '')))
				}
			} else {
				SweetAlertModal(
					'error',
					'There was an error fetching the maximum replacement value',
					resp,
					'Ok',
					true,
					false,
					null,
				)
			}
		})()
	}, [globalConfig, dispatch, setMaxReplacementValue])

	const mapZoom = 4.8
	const carouselResponsive = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 1680 },
			items: 22,
		},
		largeDesktop: {
			breakpoint: { max: 1680, min: 1330 },
			items: 18,
		},
		desktop: {
			breakpoint: { max: 1475, min: 768 },
			items: 11,
		},
		tablet: {
			breakpoint: { max: 768, min: 480 },
			items: 8,
		},
		mobile: {
			breakpoint: { max: 480, min: 0 },
			items: 6,
		},
	}

	const handleRateSelected = (rateId) => {
		setSelectedRateId(rateId)
		scrollToElement(`rate-${rateId}`, -64)
	}

	const latitudeCenter = (originData.latitude + destinationData.latitude) / 2
	const longitudeCenter = (originData.longitude + destinationData.longitude) / 2

	const sortRatesFunction = (ratesArray, orderBy) => {
		const ratesArraySorted = ratesArray.sort((a, b) => {
			if (a[orderBy] < b[orderBy]) return -1
			if (a[orderBy] > b[orderBy]) return 1
			return 0
		})
		return ratesArraySorted
	}

	const handleLowestPrice = (index) => selectedSort === 'totalCustomerRate' && !index

	const handleChangeSortSelected = (orderBy) => {
		setSelectedSort(orderBy)
		const newRatesArray = [...quoteRates.quoteRates.rates]
		const ratesSorted = sortRatesFunction(newRatesArray, orderBy)
		const newQuoteRates = { ...quoteRates.quoteRates, rates: ratesSorted }
		dispatch(sortRates(newQuoteRates))
	}

	return (
		<div>
			<Grid style={{ padding: '20px' }} container alignItems="flex-start">
				<div className={classes.mapContainer}>
					<GoogleMapLoader
						center={{
							lat: latitudeCenter,
							lng: longitudeCenter,
						}}
						zoom={mapZoom}
						origin={{
							coordinate: {
								lat: originData.latitude,
								lng: originData.longitude,
							},
						}}
						destination={{
							coordinate: {
								lat: destinationData.latitude,
								lng: destinationData.longitude,
							},
						}}
						markers={markers}
						heightMap={40}
						draggable
					/>
					<p className={classes.mapDescription}>Map and route data is estimated, actual route in transit may vary.</p>
					<InfoItems items={shipmentItems} linearFeet={linearFeet} activeStep={2} />
					<Alert variant="outlined" severity="warning" className={classes.alertContainer}>
						<AlertTitle>Contact NTG To Verify Your Quote If Your Shipment Is:</AlertTitle>
						<ul className={classes.alertList}>
							<li>Over 7000 lbs</li>
							<li>Over 6 standard pallets</li>
							<li>Occupies more than 12 feet of a truck</li>
						</ul>
					</Alert>
				</div>
				<div className={classes.mainInformation}>
					<div className={classes.mainInformationBody}>
						<div className={classes.infoItem}>
							<span className={classes.companyName}>FreightPros Quote # {quoteCreatedId}</span>
						</div>
						<div className={classes.infoItem}>
							<div className={classes.itemDate}>{`Created ${moment(creationDate).format('MM/DD/YY')}`}</div>
						</div>
					</div>
					<div className={classes.quoteDataContainer}>
						<div className={classes.pickupDeliveryRows}>
							<div>
								<strong>Pickup</strong>
							</div>
							<div>{`${originData.city}, ${originData.state}, ${originData.postalCode}, ${originData.country}`}</div>
							<div className={classes.siteType}>{siteTypeOrigin}</div>
							<div>
								<span>
									<strong>Pickup Date: </strong>
								</span>
								<span>{moment(pickUpDate).format('MMM DD, YYYY')}</span>
							</div>
							<div>
								<span>
									<strong>
										<strong>Services:</strong>
									</strong>
								</span>{' '}
								{accessorialsOrigin.length > 0 || accessorialsDestination.length > 0
									? [...accessorialsOrigin, ...accessorialsDestination]
											.map((element) => capitalizeFirstLetter(element))
											.join(', ')
									: 'None'}
							</div>
						</div>
						<div className={classes.pickupDeliveryRows}>
							<div>
								<strong>Delivery</strong>
							</div>
							<div>
								{`${destinationData.city}, ${destinationData.state}, ${destinationData.postalCode}, ${destinationData.country}`}
							</div>
							<div className={classes.siteType}>{siteTypeDestination}</div>
						</div>
					</div>
					<div className="new-quote-main-information-body">
						<AccordionElements shipmentItems={shipmentItems} references={references} totalWeight={totalWeight} />
					</div>
				</div>
			</Grid>
			<div className={classes.sort}>
				<div className={classes.sortInfoSection}>
					<div className={classes.sortInfoSectionIcon}>
						<AttachMoneyIcon color="primary" fontSize="small" />
					</div>
					<h5 className={classes.sortInfoSectionName}>Carrier Rates</h5>
				</div>
				{validRates && !quoteRates.isLoading && validRates.length !== 0 && (
					<div className={classes.dropDown}>
						<FormControl size="small" variant="outlined" className={classes.sortSelect}>
							<InputLabel id="demo-simple-select-outlined-label">Sort by</InputLabel>
							<Select
								labelId="demo-simple-select-outlined-label"
								id="demo-simple-select-outlined"
								value={selectedSort}
								onChange={(e) => handleChangeSortSelected(e.target.value)}
								label="RateSort"
							>
								<MenuItem value="totalCustomerRate">Lowest Price</MenuItem>
								<MenuItem value="estimateTransitDays">Quickest Delivery</MenuItem>
								<MenuItem value="carrier">Carrier Name</MenuItem>
								<MenuItem value="serviceType">Guaranteed Services</MenuItem>
							</Select>
						</FormControl>
					</div>
				)}
			</div>
			<div style={{ padding: '0px 20px' }} className="carrousel-responsive">
				<Carousel responsive={carouselResponsive} infinite={false} itemClass="new-quote-carrousel-item">
					{validRates ? (
						validRates
							.filter((item) => !item.error)
							.map((rate, index) => (
								<Grid
									component="button"
									container
									direction="column"
									// eslint-disable-next-line react/no-array-index-key
									key={`${index}-button-mini-rate`}
									className={`${classes.itemShortCarousel} ${rate.rateId === selectedRateId ? 'new-quote-carrousel-items-item-selected' : ''}`}
									onClick={() => {
										handleRateSelected(rate.rateId)
									}}
								>
									<Grid item>
										<img src={`${rate.carrierLogo}`} alt="" />
									</Grid>
									<Grid item>
										<span>{hasCarrierRateAuth ? `$ ${rate.totalCarrierRate}` : `$ ${rate.totalCustomerRate}`}</span>
									</Grid>
								</Grid>
							))
					) : (
						<br />
					)}
				</Carousel>
			</div>
			{hasCarrierRateAuth && invalidRates.filter((rate) => !rate.errorMessage?.includes('mercuryGate')).length > 0 && (
				<Alert variant="outlined" severity="error" className={classes.invalidRatesAlertContainer}>
					<AlertTitle>Invalid Rates Returned:</AlertTitle>
					<ul className={classes.alertList}>
						{invalidRates
							.filter((rate) => !rate.errorMessage?.includes('mercuryGate'))
							.map((invalidRate) => (
								<li key={invalidRate.errorMessage}>
									{invalidRate.carrier ? `${invalidRate.carrier} - ` : ''}
									{invalidRate.errorMessage}
								</li>
							))}
					</ul>
				</Alert>
			)}
			{validRates && !quoteRates.isLoading ? (
				<RatesSlider
					rates={validRates}
					selectedRateId={selectedRateId}
					setSelectedRateId={setSelectedRateId}
					selectedSort={selectedSort}
					handleLowestPrice={handleLowestPrice}
					typeAmount={typeAmount}
					typeAmountValue={typeAmountValue}
					onNextStepThree={onNextStepThree}
					quoteCreatedId={quoteCreatedId}
					maxReplacementValue={maxReplacementValue}
					openConfirmInsurance={openConfirmInsurance}
					enableInsuranceOptIn={enableInsuranceOptIn}
					setOpenConfirmInsurance={setOpenConfirmInsurance}
					defaultDeclaredValue={defaultDeclaredValue}
					declaredFreightValue={declaredFreightValue}
					setDeclaredFreightValue={setDeclaredFreightValue}
					withInsurance={withInsurance}
					setWithInsurance={setWithInsurance}
					setInsuranceCost={setInsuranceCost}
					setInsuranceValue={setInsuranceValue}
				/>
			) : (
				<div className={classes.ratesSliderLoading}>
					<CircularProgress />
				</div>
			)}
			{validRates && !quoteRates.isLoading && validRates.length === 0 && <NoResultsFound type="Rates" />}
			<Grid className={classes.ratesTable} direction="column" container justify="center">
				<TableContainer>
					<Table stickyHeader aria-label="sticky table" className={classes.table}>
						<TableHead>
							<TableRow>
								{localColumns.map((column) => {
									const RenderCell = () => (
										<TableCell key={column.id} align="center" className={classes.headerCell}>
											{column.label}
										</TableCell>
									)
									return column?.toltip ? (
										<Tooltip key={column.id} title={column.toltip} interactive>
											{RenderCell()}
										</Tooltip>
									) : (
										RenderCell()
									)
								})}
							</TableRow>
						</TableHead>
						<TableBody>
							{quoteRates.isLoading ? (
								<TableRow>
									<TableCell colSpan={Object.keys(localColumns).length} align="center">
										<CircularProgress />
									</TableCell>
								</TableRow>
							) : null}
							{validRates && validRates.length === 0 ? (
								<TableRow>
									<TableCell align="center" colSpan={Object.keys(localColumns).length}>
										<NoResultsFound type="Rates" />
									</TableCell>
								</TableRow>
							) : null}
							{validRates && validRates.length > 0
								? validRates.map((rate, index) =>
										rate.error ? (
											<slot key={rate.rateId} />
										) : (
											<TableRatesRow
												key={rate.rateId}
												row={rate}
												quoteCreatedId={quoteCreatedId}
												selectedRateId={selectedRateId}
												setSelectedRateId={setSelectedRateId}
												elementId={`rate-${rate.rateId}`}
												isLowestPrice={handleLowestPrice(index)}
												typeAmount={typeAmount}
												typeAmountValue={typeAmountValue}
												onNextStepThree={onNextStepThree}
												maxReplacementValue={maxReplacementValue}
												openConfirmInsurance={openConfirmInsurance}
												enableInsuranceOptIn={enableInsuranceOptIn}
												setOpenConfirmInsurance={setOpenConfirmInsurance}
												defaultDeclaredValue={defaultDeclaredValue}
												declaredFreightValue={declaredFreightValue}
												setDeclaredFreightValue={setDeclaredFreightValue}
												withInsurance={withInsurance}
												setWithInsurance={setWithInsurance}
												setInsuranceCost={setInsuranceCost}
												setInsuranceValue={setInsuranceValue}
											/>
										),
									)
								: null}
							{openQuoteSavedConfirm && <QuoteSavedDialog />}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
		</div>
	)
}

NewQuoteShipmentStepTwo.propTypes = {
	originData: PropTypes.shape({
		city: PropTypes.string,
		latitude: PropTypes.number,
		longitude: PropTypes.number,
		postalCode: PropTypes.string,
		country: PropTypes.string,
		state: PropTypes.string,
	}).isRequired,
	destinationData: PropTypes.shape({
		city: PropTypes.string,
		latitude: PropTypes.number,
		longitude: PropTypes.number,
		postalCode: PropTypes.string,
		country: PropTypes.string,
		state: PropTypes.string,
	}).isRequired,
	markers: PropTypes.arrayOf(PropTypes.any).isRequired,
	quoteCreatedId: PropTypes.number.isRequired,
	totalWeight: PropTypes.number.isRequired,
	accessorialsOrigin: PropTypes.arrayOf(PropTypes.string).isRequired,
	accessorialsDestination: PropTypes.arrayOf(PropTypes.string).isRequired,
	creationDate: PropTypes.instanceOf(Date).isRequired,
	shipmentItems: PropTypes.arrayOf(PropTypes.any).isRequired,
	references: PropTypes.arrayOf(PropTypes.any).isRequired,
	siteTypeOrigin: PropTypes.string.isRequired,
	siteTypeDestination: PropTypes.string.isRequired,
	pickUpDate: PropTypes.instanceOf(Date).isRequired,
	selectedRateId: PropTypes.number.isRequired,
	setSelectedRateId: PropTypes.func.isRequired,
	openQuoteSavedConfirm: PropTypes.bool.isRequired,
	onNextStepThree: PropTypes.func.isRequired,
	linearFeet: PropTypes.number.isRequired,
	openConfirmInsurance: PropTypes.bool.isRequired,
	setOpenConfirmInsurance: PropTypes.func.isRequired,
	enableInsuranceOptIn: PropTypes.bool.isRequired,
	defaultDeclaredValue: PropTypes.number.isRequired,
	declaredFreightValue: PropTypes.number.isRequired,
	setDeclaredFreightValue: PropTypes.func.isRequired,
	withInsurance: PropTypes.bool.isRequired,
	setWithInsurance: PropTypes.func.isRequired,
	setInsuranceCost: PropTypes.func.isRequired,
	setInsuranceValue: PropTypes.func.isRequired,
}

export default NewQuoteShipmentStepTwo
