import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TablePagination,
	TableRow,
	Paper,
	CircularProgress,
} from '@material-ui/core'

import { useDispatch, useSelector } from 'react-redux'

import { updatePaginator } from '../../redux/actions/Quotes.actions'
import { setCurrentPage, setRowsPerPage } from '../../redux/actions/QuotesFilters.actions'
import useQuotesFilters from '../../hooks/useQuotesFilters'
import { savedQuotesCells } from '../../utils/headCells'

import TableToolbar from '../TableToolbar'
import TableHeader from '../TableHeader'
import QuoteRow from '../QuoteRow'
import NoResultsFound from '../NoResultsFound'

import useStyles from './styles'

const TableList = (props) => {
	const { tableType, pageReset, search, currentSort, setCurrentSort } = props
	const quotesReducer = useSelector((state) => state.quotes)
	const quotesFilters = useSelector((state) => state.quotesFilters)
	const classes = useStyles()
	const dispatch = useDispatch()
	const [selected, setSelected] = useState([])
	const [page, setPage] = useState(Number(quotesFilters.currentPage / quotesFilters.rowsPerPage))
	const [buildParamsFromState] = useQuotesFilters()

	const [openDetails, setOpenDetails] = useState(false)
	const [idQuoteOpened, setIdQuoteOpened] = useState(0)

	const isSelected = (name) => selected.indexOf(name) !== -1

	const handleSelectAllClick = (event) => {
		let data

		if (tableType === 'savedQuotes') data = quotesReducer.quotes

		if (event.target.checked) {
			const newSelecteds = data.map((n) => n.id)
			setSelected(newSelecteds)
			return
		}
		setSelected([])
	}

	const handleChangePage = (event, newPage) => {
		dispatch(setCurrentPage(newPage * quotesFilters.rowsPerPage))
		dispatch(updatePaginator(newPage * quotesFilters.rowsPerPage))
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		dispatch(setRowsPerPage(Number(event.target.value)))
		dispatch(setCurrentPage(0))
		setPage(0)
	}

	useEffect(() => {
		if (pageReset) setPage(0)
	}, [pageReset])

	const renderTableBody = () => {
		let data = []
		if (tableType === 'savedQuotes') {
			if (quotesReducer.isLoading) {
				return (
					<TableRow>
						<TableCell align="center" colSpan={Object.keys(savedQuotesCells).length + 1}>
							<CircularProgress />
						</TableCell>
					</TableRow>
				)
			}
			data = quotesReducer.quotes.map((row, index) => {
				const isItemSelected = isSelected(row.id)
				const labelId = `enhanced-table-checkbox-${index}`

				return (
					<QuoteRow
						key={row.id}
						labelId={labelId}
						isItemSelected={isItemSelected}
						data={row}
						selected={selected}
						setSelected={setSelected}
						search={search}
						openDetails={openDetails}
						setOpenDetails={setOpenDetails}
						idQuoteOpened={idQuoteOpened}
						setIdQuoteOpened={setIdQuoteOpened}
					/>
				)
			})
			if (data.length === 0) {
				const quoteFiltersParams = buildParamsFromState()
				return (
					<TableRow>
						<TableCell align="center" colSpan={Object.keys(savedQuotesCells).length + 1}>
							<NoResultsFound type="Quotes" filters={quoteFiltersParams} />
						</TableCell>
					</TableRow>
				)
			}
		}
		return data
	}

	return (
		<div className={classes.root}>
			<TableToolbar numSelected={selected.length} />
			<Paper className={classes.paper} elevation={0}>
				<TableContainer>
					<Table className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
						<TableHeader
							numSelected={selected.length}
							onSelectAllClick={handleSelectAllClick}
							rowCount={quotesReducer.quotes.length}
							tableType={tableType}
							currentSort={currentSort}
							setCurrentSort={setCurrentSort}
						/>
						<TableBody>{renderTableBody()}</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					classes={{
						root: classes.tablePaginator,
						toolbar: classes.tablePaginatorToolbar,
						actions: classes.tablePaginatorActions,
					}}
					rowsPerPageOptions={[10, 20, 25, 100]}
					component="div"
					count={quotesReducer.totalRecords}
					rowsPerPage={Number(quotesFilters.rowsPerPage)}
					page={page}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
				/>
			</Paper>
		</div>
	)
}

TableList.propTypes = {
	tableType: PropTypes.string.isRequired,
	pageReset: PropTypes.number.isRequired,
	search: PropTypes.string.isRequired,
	currentSort: PropTypes.objectOf(PropTypes.any).isRequired,
	setCurrentSort: PropTypes.func.isRequired,
}

export default TableList
