import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	tableRow: {
		height: 60,
		margin: 100,
	},
	tableCell: {
		height: 'auto',
		fontSize: 14,
		fontWeight: theme.typography.fontWeightRegular,
		padding: '10px 16px',
	},
	tableCellCenter: {
		textAlign: 'center',
	},
	tableCellLocationID: {
		height: 'auto',
		fontSize: 14,
		fontWeight: theme.typography.fontWeightRegular,
		textDecoration: 'underline',
	},
}))

export default useStyles
