import React from 'react'
import PropTypes from 'prop-types'

import Carousel from 'react-multi-carousel'

import { makeStyles } from '@material-ui/core'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'

import { removeUnderscoreAndUppercase } from '../../utils/helpers'

import 'react-multi-carousel/lib/styles.css'
import './ShipmentReferencesSlider.scss'

const useStyles = makeStyles(() => ({
	icon: {
		width: '24px',
		height: '24px',
	},
	references: {
		padding: '0 16px',
		margin: 0,
	},
}))

const ShipmentReferencesSlider = (props) => {
	const { references } = props
	const classes = useStyles()

	const responsive = {
		tablet: {
			breakpoint: { max: 1024, min: 500 },
			items: 1,
			paritialVisibilityGutter: 50,
		},
		mobile: {
			breakpoint: { max: 500, min: 0 },
			items: 1,
			paritialVisibilityGutter: 30,
		},
	}

	return (
		<div className="shipment-references-slider">
			<Carousel
				ssr
				arrows={false}
				responsive={responsive}
				showDots
				draggable
				renderDotsOutside
				dotListClass="shipment-items-slider-dots"
			>
				{references?.length > 0 ? (
					references.map((item, index) => (
						<div key={`id-${index + 1}`} className="shipment-references-slider-container">
							<div>
								<p className="shipment-references-slider-title" id="shipment-references-slider-title-id">
									<span>{`#${index + 1}`}</span>
								</p>
								<p className="shipment-references-slider-title">
									<span>Reference type:</span>
									{` ${removeUnderscoreAndUppercase(item.type || item.referenceType)}`}
								</p>
								<p className="shipment-references-slider-title">
									<span>Reference number:</span>
									{` ${item.value}`}
								</p>
								<div className="shipment-references-slider-delete">
									<DeleteOutlineOutlinedIcon color="error" className={classes.icon} />
									<p className="shipment-references-slider-delete-title">Delete</p>
								</div>
							</div>
						</div>
					))
				) : (
					<p className={classes.references}>Not references added</p>
				)}
			</Carousel>
		</div>
	)
}

ShipmentReferencesSlider.propTypes = {
	references: PropTypes.arrayOf(PropTypes.any).isRequired,
}

export default ShipmentReferencesSlider
