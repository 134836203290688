import React, { createContext } from 'react'
import propTypes from 'prop-types'

export const MultiTabsContext = createContext({
	quoteModule: {
		origin: '',
		destination: '',
		quoteRates: [],
		originData: {},
		destinationData: {},
		originAccessorials: [],
		destinationAccessorials: [],
		shipmentItems: [],
		references: [],
	},
	shipmentModule: {
		shippeerData: {},
		consigneeData: {},
	},
	getQuoteData: () => {},
	setQuoteData: () => {},
})

const MultiTabsProvider = ({ children }) => {
	const quoteModuleData = {
		origin: '',
		destination: '',
		quoteRates: [],
		originData: {},
		destinationData: {},
		originAccessorials: [],
		destinationAccessorials: [],
		shipmentItems: [],
		references: [],
	}

	const shipmentModuleData = {
		shippeerData: [],
		consigneeData: [],
	}

	const getQuoteData = (propertyName) => quoteModuleData[propertyName]
	const setQuoteData = (propertyName, data) => {
		quoteModuleData[propertyName] = data
	}

	return (
		<MultiTabsContext.Provider
			value={{
				quoteModule: quoteModuleData,
				shipmentModule: shipmentModuleData,
				getQuoteData,
				setQuoteData,
			}}
		>
			{children}
		</MultiTabsContext.Provider>
	)
}

MultiTabsProvider.defaultProps = {
	children: propTypes.node,
}

MultiTabsProvider.propTypes = {
	children: propTypes.node,
}

export default MultiTabsProvider
