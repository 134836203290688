import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core'

import Carousel from 'react-multi-carousel'

import RateCard from '../RateCard'

import 'react-multi-carousel/lib/styles.css'

const useStyles = makeStyles(() => ({
	ratesSlider: {
		padding: '20px',
		'@media (min-width: 1025px)': {
			display: 'none',
		},
	},
}))

const RatesSlider = (props) => {
	const {
		rates,
		selectedRateId,
		setSelectedRateId,
		handleLowestPrice,
		typeAmount,
		typeAmountValue,
		onNextStepThree,
		quoteCreatedId,
		maxReplacementValue,
		openConfirmInsurance,
		setOpenConfirmInsurance,
		enableInsuranceOptIn,
		declaredFreightValue,
		setDeclaredFreightValue,
		withInsurance,
		setWithInsurance,
		setInsuranceCost,
		setInsuranceValue,
		defaultDeclaredValue,
	} = props

	const classes = useStyles()

	const responsive = {
		tablet: {
			breakpoint: { max: 1024, min: 500 },
			items: 1,
			paritialVisibilityGutter: 50,
		},
		mobile: {
			breakpoint: { max: 500, min: 0 },
			items: 1,
			paritialVisibilityGutter: 30,
		},
	}

	return (
		<div className={classes.ratesSlider}>
			<Carousel
				ssr
				arrows={false}
				responsive={responsive}
				showDots
				draggable
				renderDotsOutside
				dotListClass="shipment-items-slider-dots"
				focusOnSelect
			>
				{rates.map((rate, index) =>
					rate.error ? (
						<slot />
					) : (
						<RateCard
							key={`id-${index + 1}`}
							rate={rate}
							selectedRateId={selectedRateId}
							setSelectedRateId={setSelectedRateId}
							elementId={`rate-${rate.rateId}`}
							isLowestPrice={handleLowestPrice(index)}
							typeAmount={typeAmount}
							typeAmountValue={typeAmountValue}
							onNextStepThree={onNextStepThree}
							quoteCreatedId={quoteCreatedId}
							maxReplacementValue={maxReplacementValue}
							openConfirmInsurance={openConfirmInsurance}
							setOpenConfirmInsurance={setOpenConfirmInsurance}
							enableInsuranceOptIn={enableInsuranceOptIn}
							defaultDeclaredValue={defaultDeclaredValue}
							declaredFreightValue={declaredFreightValue}
							setDeclaredFreightValue={setDeclaredFreightValue}
							withInsurance={withInsurance}
							setWithInsurance={setWithInsurance}
							setInsuranceCost={setInsuranceCost}
							setInsuranceValue={setInsuranceValue}
						/>
					),
				)}
			</Carousel>
		</div>
	)
}

RatesSlider.defaultProps = {
	onNextStepThree: () => {},
	maxReplacementValue: null,
}

RatesSlider.propTypes = {
	rates: PropTypes.arrayOf(PropTypes.any).isRequired,
	selectedRateId: PropTypes.number.isRequired,
	setSelectedRateId: PropTypes.func.isRequired,
	handleLowestPrice: PropTypes.func.isRequired,
	typeAmount: PropTypes.string.isRequired,
	typeAmountValue: PropTypes.string.isRequired,
	onNextStepThree: PropTypes.func,
	quoteCreatedId: PropTypes.number.isRequired,
	maxReplacementValue: PropTypes.number,
	openConfirmInsurance: PropTypes.bool.isRequired,
	setOpenConfirmInsurance: PropTypes.func.isRequired,
	enableInsuranceOptIn: PropTypes.bool.isRequired,
	defaultDeclaredValue: PropTypes.number.isRequired,
	declaredFreightValue: PropTypes.number.isRequired,
	setDeclaredFreightValue: PropTypes.func.isRequired,
	withInsurance: PropTypes.bool.isRequired,
	setWithInsurance: PropTypes.func.isRequired,
	setInsuranceCost: PropTypes.func.isRequired,
	setInsuranceValue: PropTypes.func.isRequired,
}

export default RatesSlider
