/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import requestWithHeader from '../requests/ReportsWithHeader.request'

const reportsSlice = createSlice({
	name: 'reportsSlice',
	initialState: {
		carrierUsage: {
			data: [],
			isLoading: false,
			error: false,
		},
		perMonthAndYear: {
			data: [],
			isLoading: false,
			error: false,
		},
		averageWeight: {
			data: [],
			isLoading: false,
			error: false,
		},
		averageDistance: {
			data: [],
			isLoading: false,
			error: false,
		},
		shipments: {
			data: [],
			isLoading: false,
			error: false,
		},
		invoices: {
			data: [],
			isLoading: false,
			error: false,
		},
	},
	reducers: {
		shipmentsStartLoading: (state) => {
			state.shipments.isLoading = true
		},
		shipmentsHasError: (state) => {
			state.shipments.isLoading = false
			state.shipments.error = true
		},
		shipmentsReportSuccess: (state, action) => {
			state.shipments.data = action.payload
			state.shipments.isLoading = false
		},
		invoicesStartLoading: (state) => {
			state.invoices.isLoading = true
		},
		invoicesHasError: (state) => {
			state.invoices.isLoading = false
			state.invoices.error = true
		},
		invoicesReportSuccess: (state, action) => {
			state.invoices.data = action.payload
			state.invoices.isLoading = false
		},
	},
})

const { actions, reducer } = reportsSlice

export const {
	shipmentsStartLoading,
	shipmentsHasError,
	shipmentsReportSuccess,
	invoicesStartLoading,
	invoicesHasError,
	invoicesReportSuccess,
} = actions

export const fetchShipmentsReportsByCompany = (companyId) => async (dispatch) => {
	dispatch(shipmentsStartLoading())
	const [error, data] = await requestWithHeader('/dashboard/shipment/cards/by_company', {
		'x-company-id': companyId,
	})
	if (error) {
		dispatch(shipmentsHasError(error))
		return { status: 'error', resp: error }
	}
	dispatch(shipmentsReportSuccess(data.data.data.shipmentCardsData))
	return { status: 'success', resp: data.data.data.shipmentCardsData }
}

export const fetchInvoiceReportsByCompany = (companyId) => async (dispatch) => {
	dispatch(invoicesStartLoading())
	const [error, data] = await requestWithHeader('/dashboard/invoices/cards/by_company', {
		'x-company-id': companyId,
	})
	if (error) {
		dispatch(invoicesHasError(error))
		return { status: 'error', resp: error }
	}
	dispatch(invoicesReportSuccess(data.data.data.invoiceCardsData))
	return { status: 'success', resp: data.data.data.invoiceCardsData }
}

export default reducer
