import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select, SwipeableDrawer } from '@material-ui/core'

import { FilterList, Loop } from '@material-ui/icons'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Button from '@material-ui/core/Button'

import {
	setCurrentPage,
	setRowsPerPage,
	setCarrierGroup,
	setContractType,
	setSearchBar,
	resetFilters,
	setOrderByAndOrder,
} from '../../../../../redux/actions/CarriersFilters.actions'
import useCarriersFilters from '../../../../../hooks/useCarriersFilters'
import { fetchCarriersList } from '../../../../../redux/actions/Carriers.actions'

import SearchBar from '../../../../../components/SearchBar'
import CarriersTable from '../../components/CarriersTableList'

import sweetAlertModal from '../../../../../components/SweetAlertModal/SweetAlertModal'
import useStyles from './styles'
import CarriersSlider from '../../components/CarriersSlider'

const CarrierListContainer = () => {
	const params = useParams()
	const classes = useStyles()
	const dispatch = useDispatch()
	const matches = useMediaQuery('(min-width:1024px)')
	const [buildParamsFromState] = useCarriersFilters()
	const { searchBar } = useSelector((state) => state.carriersFilters)
	const { isLoading } = useSelector((state) => state.carriers)
	const [search, setSearch] = useState(decodeURIComponent(searchBar))
	const [pageReset, setPageReset] = useState('')
	const [orderByLabel, setOrderByLabel] = useState('number')
	const [open, setOpen] = useState(false)
	const [order, setOrder] = useState('asc')
	const [carrierGroup, setCarrierGroupState] = useState('STANDARD')
	const [contractType, setContractTypeState] = useState('LTL_STANDARD')
	const [carriersList, setCarriersList] = useState([])

	const CarriersListProvider = () => {
		const callback = useCallback(async (paramsCall) => {
			const config = {
				headers: { 'X-Company-id': +params?.id ?? '' },
			}
			const { status, resp } = await dispatch(fetchCarriersList(config, paramsCall))
			if (status === 'error') {
				sweetAlertModal('error', 'There was an error fetching the carriers', null, 'Ok', true, false)
			} else {
				setCarriersList(resp)
			}
		}, [])

		return { callback }
	}

	const { callback } = CarriersListProvider()
	const filterParams = buildParamsFromState()

	useEffect(() => {
		callback(filterParams)
	}, [callback, filterParams])

	useEffect(() => {
		const preservedFilters = localStorage.getItem('preserved-carriers-filters')
		if (preservedFilters) {
			const filterObj = preservedFilters.split('&')
			filterObj.forEach((filter) => {
				const filterKeyValue = filter.split('=')
				switch (filterKeyValue[0]) {
					case 'search_bar':
						dispatch(setSearchBar(filterKeyValue[1]))
						setSearch(decodeURIComponent(filterKeyValue[1]))
						break
					case 'carrier_group':
						dispatch(setCarrierGroup(filterKeyValue[1]))
						setCarrierGroupState(filterKeyValue[1])
						break
					case 'contract_type':
						dispatch(setContractType(filterKeyValue[1]))
						setContractTypeState(filterKeyValue[1])
						break
					case 'order_by':
						dispatch(setOrderByAndOrder({ orderBy: filterKeyValue[1], order }))
						setOrderByLabel(filterKeyValue[1])
						break
					case 'order':
						dispatch(setOrderByAndOrder({ orderBy: orderByLabel, order }))
						setOrder(filterKeyValue[1])
						break
					case 'page':
						dispatch(setCurrentPage(filterKeyValue[0]))
						dispatch(setRowsPerPage(filterKeyValue[0]))
						break
					default:
						break
				}
			})
		}
	}, [dispatch, orderByLabel, order])

	const showFilters = () => {
		setOpen(true)
	}

	const handleSearchBarChanged = (event) => {
		setSearch(event.target.value)
		if (event.target.value.length >= 3) {
			dispatch(setSearchBar(encodeURIComponent(event.target.value)))
			setPageReset(event.target.value)
		} else if (event.target.value.length === 0) {
			dispatch(setSearchBar(''))
			setPageReset(event.target.value)
		}
	}

	const handleSortColumn = (column) => {
		setOrderByLabel(column)
		const newOrder = order === 'asc' ? 'desc' : 'asc'
		setOrder(newOrder)
		dispatch(setOrderByAndOrder({ orderBy: column, order: newOrder }))
	}

	const handleResetFilters = () => {
		dispatch(resetFilters())
		setSearch('')
		setPageReset('pageResetFilters')
	}

	const toggleDrawer = (openDrawer) => (event) => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return
		}
		setOpen(openDrawer)
	}

	const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

	return (
		<>
			<Grid container spacing={2} alignItems="flex-end" className={classes.carriersListContainer}>
				<Grid item xs={11} md={11} lg={6} xl={6}>
					<SearchBar value={search} placeholder="Search" onChangeHandler={handleSearchBarChanged} />
				</Grid>
				{!matches && (
					<Grid item xs={1} md={1}>
						<IconButton aria-label="Toggle Filters" onClick={showFilters} className={classes.filterTriggerButton}>
							<FilterList />
						</IconButton>
					</Grid>
				)}
				<SwipeableDrawer
					className={classes.drawer}
					anchor="top"
					open={open}
					onClose={toggleDrawer(false)}
					disableBackdropTransition={!iOS}
					disableDiscovery={iOS}
				>
					<div className={classes.filterFormWrapper} role="presentation">
						<Grid container spacing={2} alignItems="center">
							<Grid item xs={12}>
								<FormControl style={{ width: '100%' }} variant="outlined" size="small">
									<InputLabel className={classes.field}>Carrier Group</InputLabel>
									<Select
										name="carrier-group"
										value={carrierGroup}
										label="Carrier Group"
										variant="outlined"
										placeholder="Carrier Group"
										className={classes.root}
										onChange={(e) => {
											setCarrierGroupState(e.target.value)
											dispatch(setCarrierGroup(e.target.value))
										}}
									>
										<MenuItem value="STANDARD">Standard</MenuItem>
										<MenuItem value="ECONOMY">Economy</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<FormControl style={{ width: '100%' }} variant="outlined" size="small">
									<InputLabel className={classes.field}>Contract Type</InputLabel>
									<Select
										name="contract-type"
										value={contractType}
										label="Contract Type"
										variant="outlined"
										placeholder="Contract Type"
										className={classes.root}
										onChange={(e) => {
											setContractTypeState(e.target.value)
											dispatch(setContractType(e.target.value))
										}}
									>
										<MenuItem value="CSP">Customer-Specific</MenuItem>
										<MenuItem value="BLANKET">Blanket</MenuItem>
										<MenuItem value="VOLUME">Volume</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<Button
									onClick={handleResetFilters}
									color="primary"
									startIcon={<Loop color="secondary" />}
									className={classes.filterButtonModal}
								>
									<span className={classes.filterButtonModalText}>Reset Filters</span>
								</Button>
							</Grid>
						</Grid>
					</div>
				</SwipeableDrawer>
				{matches && (
					<>
						<Grid item xs={12} md={2} lg={2} xl={2}>
							<FormControl style={{ width: '100%' }} variant="outlined" size="small">
								<InputLabel className={classes.field}>Carrier Group</InputLabel>
								<Select
									name="carrier-group"
									value={carrierGroup}
									label="Carrier Group"
									variant="outlined"
									placeholder="Carrier Group"
									className={classes.root}
									onChange={(e) => {
										setCarrierGroupState(e.target.value)
										dispatch(setCarrierGroup(e.target.value))
									}}
								>
									<MenuItem value="STANDARD">Standard</MenuItem>
									<MenuItem value="ECONOMY">Economy</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} md={2} lg={2} xl={2}>
							<FormControl style={{ width: '100%' }} variant="outlined" size="small">
								<InputLabel className={classes.field}>Contract Type</InputLabel>
								<Select
									name="contract-type"
									value={contractType}
									label="Contract Type"
									variant="outlined"
									placeholder="Contract Type"
									className={classes.root}
									onChange={(e) => {
										setContractTypeState(e.target.value)
										dispatch(setContractType(e.target.value))
									}}
								>
									<MenuItem value="LTL_STANDARD">LTL Standard</MenuItem>
									<MenuItem value="VOLUME">Volume</MenuItem>
									<MenuItem value="GUARANTEED_5PM">Guaranteed 5PM</MenuItem>
									<MenuItem value="GUARANTEED_NOON">Guaranteed Noon</MenuItem>
									<MenuItem value="GUARANTEED_9AM">Guaranteed 9AM</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					</>
				)}
				{matches && (
					<Grid item xs={2} lg={2} xl={2}>
						<Button
							onClick={handleResetFilters}
							color="primary"
							startIcon={<Loop color="secondary" />}
							className={classes.filterButton}
						>
							<span className={classes.filterButtonText}>Reset Filters</span>
						</Button>
					</Grid>
				)}
			</Grid>
			<Grid container alignItems="center" className={classes.carriersTableContainer}>
				<CarriersTable
					carriersList={carriersList}
					isLoading={isLoading}
					isSelectableList={false}
					tableType="carriers"
					pageReset={pageReset}
					handleSortColumn={handleSortColumn}
				/>
			</Grid>
			<Grid container alignItems="center" className={classes.carriersSliderContainer}>
				<CarriersSlider carriersList={carriersList} />
			</Grid>
		</>
	)
}

export default CarrierListContainer
