import { createSlice } from '@reduxjs/toolkit'
import reqGetTemplateItems, {
	reqGetTemplateItemById,
	reqCreateTemplateItem,
	reqEditTemplateItem,
	reqDeleteTemplateItem,
	reqDeleteTemplateItemDetail,
	reqCreateTemplateItemDetail,
	reqEditTemplateItemDefault,
} from '../requests/TemplateItems.request'

/* eslint no-param-reassign: ["error", { "props": false }] */

const templateItemsSlice = createSlice({
	name: 'templateItemsSlice',
	initialState: {
		templateItems: [],
		templateItemById: {},
		defaultItems: [],
		totalRecords: 0,
		pagSkip: 0,
		isLoading: false,
		error: false,
	},
	reducers: {
		startLoading: (state) => {
			state.isLoading = true
		},
		hasError: (state, action) => {
			state.error = action.payload
			state.isLoading = false
		},
		templateItemsSuccess: (state, action) => {
			state.templateItems = action.payload.items
			state.totalRecords = action.payload.records
			state.isLoading = false
		},
		defaultItemsSuccess: (state, action) => {
			state.defaultItems = action.payload.items
			state.isLoading = false
		},
		templateItemByIdSuccess: (state, action) => {
			state.templateItemById = action.payload
			state.isLoading = false
		},
		updatePagSkip: (state, action) => {
			state.pagSkip = action.payload
		},
		cleanTemplateItems: (state) => {
			state.templateItems = []
		},
	},
})

const { actions, reducer } = templateItemsSlice

export const {
	startLoading,
	hasError,
	templateItemsSuccess,
	defaultItemsSuccess,
	updatePagSkip,
	templateItemByIdSuccess,
	cleanTemplateItems,
} = actions

export const fetchTemplateItems = (params) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqGetTemplateItems(params)

	if (error) {
		return { status: 'error' }
	}
	dispatch(templateItemsSuccess(data.data.data))
	return { status: 'success' }
}

export const fetchDefaultItems = () => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqGetTemplateItems('?page=0&limit=10&default=TRUE')

	if (error) {
		return { status: 'error' }
	}
	dispatch(defaultItemsSuccess(data.data.data))
	return { status: 'success', resp: data.data.data }
}

export const fetchTemplateItemById = (itemTemplateId) => async (dispatch) => {
	dispatch(startLoading())
	return reqGetTemplateItemById(`/${itemTemplateId}`).then(([error, data]) => {
		if (error) {
			dispatch(hasError(error))
			return { status: 'error', resp: error }
		}
		dispatch(templateItemByIdSuccess(data.data.data))
		return { status: 'success', resp: data.data.data }
	})
}

export const createTemplateItem = (body) => async (dispatch) => {
	dispatch(startLoading())
	return reqCreateTemplateItem(body).then(([error, data]) => {
		if (error) {
			dispatch(hasError(error))
			return { status: 'error', resp: error }
		}

		return { status: 'success', resp: data }
	})
}

export const editTemplateItem = (params, body) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqEditTemplateItem(params, body)

	if (error) {
		dispatch(hasError(data))
		return { status: 'error', resp: data }
	}

	return { status: 'success', resp: data }
}

export const editTemplateItemDefault = (templateItemId, params) => async (dispatch) => {
	const [error, data] = await reqEditTemplateItemDefault(templateItemId, params)
	if (error) {
		dispatch(hasError(data))
		return { status: 'error', resp: data }
	}

	return { status: 'success', resp: data }
}

export const updatePaginator = (params) => async (dispatch) => {
	dispatch(updatePagSkip(params))
}

export const CleanTemplateItems = () => async (dispatch) => {
	dispatch(cleanTemplateItems())
}

export const deleteTemplateItem = (templateItemId) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqDeleteTemplateItem(templateItemId)
	if (error) {
		dispatch(hasError(error))
		return { status: 'error', resp: error }
	}
	return { status: 'success', resp: data }
}

export const createTemplateItemDetail = (params, body) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqCreateTemplateItemDetail(params, body)
	if (error) {
		dispatch(hasError(error))
		return { status: 'error', resp: error }
	}
	return { status: 'success', resp: data }
}

export const deleteTemplateItemDetail = (templateItemId, ItemDetailId) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqDeleteTemplateItemDetail(templateItemId, ItemDetailId)
	if (error) {
		dispatch(hasError(error))
		return { status: 'error', resp: error }
	}
	return { status: 'success', resp: data }
}

export default reducer
