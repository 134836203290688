import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
	helperText: {
		fontSize: '12px',
		fontWeight: '400',
		color: '#A1A1A1',
	},
}))

export default useStyles
