import React from 'react'
import PropTypes from 'prop-types'

import {
	TableContainer,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	makeStyles,
	IconButton,
	Tooltip,
} from '@material-ui/core'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'

const useRowStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			borderBottom: 'unset',
		},
	},
	tableCellHead: {
		fontWeight: theme.typography.fontWeightMedium,
	},
	tableCell: {
		borderBottom: 'none',
	},
	icon: {
		width: '24px',
	},
}))

const DocumentsTable = ({ documents, setDocuments }) => {
	const classes = useRowStyles()

	const deleteDocument = (documentIndex) => {
		const doc = [...documents]
		doc.splice(documentIndex, 1)
		setDocuments(doc)
	}

	return (
		<div className="documents-table-container">
			<TableContainer>
				<Table size="small" aria-label="purchases">
					<TableHead>
						<TableRow className={classes.root}>
							<TableCell className={classes.tableCellHead}>#</TableCell>
							<TableCell className={classes.tableCellHead}>Document Type</TableCell>
							<TableCell className={classes.tableCellHead}>Document Name</TableCell>
							<TableCell className={classes.tableCellHead}>Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{documents.map((document, index) => (
							<TableRow key={`id-${index + 1}`}>
								<TableCell style={{ borderBottom: 'none', fontWeight: 700 }}>
									{String.fromCharCode(65 + index)}
								</TableCell>
								<TableCell className={classes.tableCell}>{document.type}</TableCell>
								<TableCell className={classes.tableCell}>{document.name}</TableCell>
								<TableCell style={{ borderBottom: 'none' }}>
									<Tooltip title="Delete">
										<IconButton
											onClick={() => deleteDocument(index)}
											aria-label="toggle password visibility"
											onMouseDown={null}
											edge="end"
										>
											<DeleteOutlineOutlinedIcon fontSize="small" color="error" className={classes.icon} />
										</IconButton>
									</Tooltip>
								</TableCell>
							</TableRow>
						))}
						{documents.length === 0 && (
							<TableRow>
								<TableCell style={{ borderBottom: 'none', fontWeight: 700 }}>A</TableCell>
								<TableCell className={classes.tableCell}>-</TableCell>
								<TableCell className={classes.tableCell}>-</TableCell>
								<TableCell style={{ borderBottom: 'none' }}>-</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	)
}

DocumentsTable.propTypes = {
	documents: PropTypes.arrayOf(PropTypes.any).isRequired,
	setDocuments: PropTypes.func.isRequired,
}

export default DocumentsTable
