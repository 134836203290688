import { configureStore } from '@reduxjs/toolkit'
import QuotesReducer from './actions/Quotes.actions'
import QuoteDetailsReducer from './actions/QuoteDetails.actions'
import QuotesFiltersReducer from './actions/QuotesFilters.actions'
import InvoicesReducer from './actions/Invoices.actions'
import InvoiceDetailsReducer from './actions/InvoiceDetails.actions'
import InvoicesFiltersReducer from './actions/InvoicesFilters.actions'
import ShipmentsFiltersReducer from './actions/ShipmentsFilters.actions'
import TemplateItemsFiltersReducer from './actions/TemplateItemsFilters.actions'
import QuoteRatesReducer from './actions/Rates.actions'
import MultiTabsReducer from './actions/MultiTabs.actions'
import CompaniesReducer from './actions/Company.actions'
import ShipmentReducer from './actions/Shipment.actions'
import LocationsReducer from './actions/Locations.actions'
import LocationsFiltersReducer from './actions/LocationsFilters.action'
import TemplateItemsReducer from './actions/TemplateItems.actions'
import UserReducer from './actions/Users.actions'
import UsersFiltersReducer from './actions/UsersFilters.actions'
import ShiplifySuggestionReducer from './actions/Shiplify.actions'
import TrackingReducer from './actions/Tracking.actions'
import ReportsReducer from './actions/Reports.actions'
import CarriersFiltersReducer from './actions/CarriersFilters.actions'
import CarriersReducer from './actions/Carriers.actions'
import AccountsReducer from './actions/Accounts.actions'
import AccountsFiltersReducer from './actions/AccountsFilters.actions'
import SupportModalReducer from './actions/SupportModal.actions'
import AnnouncementModalReducer from './actions/AnnouncementModal.actions'
import UserProfileReducer from './actions/Profile.actions'
import TabShipmentDetailReducer from './actions/TabShipmentDetail.actions'
import AccesorialsReducer from './actions/Accesorial.actions'
import CompaniesByUser from './actions/CompanyByUser.actions'

export default configureStore({
	reducer: {
		quotes: QuotesReducer,
		quoteDetails: QuoteDetailsReducer,
		quoteRates: QuoteRatesReducer,
		quotesFilters: QuotesFiltersReducer,
		invoices: InvoicesReducer,
		invoiceDetails: InvoiceDetailsReducer,
		invoicesFilters: InvoicesFiltersReducer,
		shipmentsFilters: ShipmentsFiltersReducer,
		multiTabs: MultiTabsReducer,
		companies: CompaniesReducer,
		shipments: ShipmentReducer,
		locations: LocationsReducer,
		locationsFilters: LocationsFiltersReducer,
		templateItems: TemplateItemsReducer,
		templateItemsFilters: TemplateItemsFiltersReducer,
		users: UserReducer,
		usersFilters: UsersFiltersReducer,
		shiplifySuggestions: ShiplifySuggestionReducer,
		tracking: TrackingReducer,
		reports: ReportsReducer,
		carriersFilters: CarriersFiltersReducer,
		carriers: CarriersReducer,
		accounts: AccountsReducer,
		accountsFilters: AccountsFiltersReducer,
		supportModal: SupportModalReducer,
		announcementModal: AnnouncementModalReducer,
		profile: UserProfileReducer,
		tabShipmentDetail: TabShipmentDetailReducer,
		accesorial: AccesorialsReducer,
		companiesByUser: CompaniesByUser,
	},
})
