import { axiosQuotes } from '../../utils/apiClient'

const reqShiplify = async (body, config) => {
	const [error, data] = await axiosQuotes
		.post('/shiplify', body, config)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export default reqShiplify
