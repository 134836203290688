import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	formContainer: {
		marginBottom: '20px',
	},
	ediItemModalActionButton: {
		fontSize: '14px',
		fontWeight: theme.typography.fontWeightRegular,
		textTransform: 'none',
	},
	helperText: {
		fontSize: '11px',
	},
	inputNumber: {
		'& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
			display: 'none',
			margin: 80,
		},
	},
}))

export default useStyles
