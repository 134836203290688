import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	root: {
		[theme.breakpoints.up('sm')]: {
			height: '100%',
		},
		'&[role="button"]': {
			cursor: 'pointer',
		},
	},
	cardHeader: {
		alignItems: 'flex-start',
		padding: '20px 20px 0',
		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(2, 2, 0),
		},
	},
	cardNumber: {
		marginBottom: 5,
		fontSize: 22,
		fontWeight: theme.typography.fontWeightMedium,
		color: theme.palette.neutral.black,
		lineHeight: 1,
		[theme.breakpoints.up('sm')]: {
			fontSize: 34,
		},
		[theme.breakpoints.up('md')]: {
			lineHeight: 28 / 34,
		},
		'&.currency': {
			[theme.breakpoints.up('sm')]: {
				fontSize: 22,
			},
			[theme.breakpoints.up('lg')]: {
				fontSize: 34,
			},
		},
	},
	cardTitle: {
		color: theme.palette.neutral.darkGray,
		textTransform: 'uppercase',
		fontSize: 12,
		lineHeight: 1,
		fontWeight: theme.typography.fontWeightBold,
		[theme.breakpoints.up('sm')]: {
			lineHeight: 'normal',
		},
		[theme.breakpoints.up('lg')]: {
			fontSize: 17,
		},
	},
	cardHeaderAction: {
		margin: '-6px -6px 0 0',
		'& .MuiSvgIcon-root': {
			fontSize: 16,
			[theme.breakpoints.up('md')]: {
				fontSize: 20,
			},
		},
	},
	cardHeaderActionButton: {
		color: theme.palette.neutral.darkGray,
	},
	cardMedia: {
		padding: '0 20px 20px',
		marginTop: -14,
		[theme.breakpoints.up('sm')]: {
			marginTop: 0,
		},
		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(0, 3, 3),
		},
	},
	chartWrapper: {
		display: 'flex',
		'& .MuiBox-root': {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'flex-start',
		},
		[theme.breakpoints.up('sm')]: {
			display: 'block',
			textAlign: 'center',
			'& .MuiBox-root': {
				display: 'block',
			},
		},
	},
	iconWrapper: {
		position: 'relative',
		height: 54,
		width: 54,
		minWidth: 54,
		marginRight: 10,
		color: theme.palette.neutral.black,
		borderRadius: 32,
		backgroundColor: theme.palette.neutral.mainGray,
		'& .MuiSvgIcon-root': {
			position: 'absolute',
			top: '50%',
			left: '50%',
			fontSize: 30,
			transform: 'translate(-50%, -50%)',
		},
		'&.main': {
			backgroundColor: theme.palette.secondary.light,
			color: theme.palette.secondary.main,
		},
		'&.green': {
			backgroundColor: theme.palette.success.main,
			color: theme.palette.success.main,
		},
		[theme.breakpoints.up('sm')]: {
			height: 64,
			width: 64,
			margin: '0 auto 24px',
		},
	},
}))

export default useStyles
