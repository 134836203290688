import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	button: {
		width: '175px',
		height: '48px',
		fontSize: '17px',
		fontWeight: '600',
		color: '#FFFFFF',
		margin: theme.spacing(0, 0, 0),
		marginLeft: '1em',
		alignSelf: 'end',
		textTransform: 'none',
		'@media only screen and (max-width: 768px)': {
			width: '140px',
		},
		[theme.breakpoints.down('md')]: {
			width: '80%',
			marginTop: '10px',
			marginBottom: '10px',
			marginLeft: '0em',
			alignSelf: 'center',
		},
	},
	cancelQuoteButton: {
		height: '48px',
		backgroundColor: '#FAFBFC',
		textTransform: 'none',
	},
	buttonsRow: {
		justifyContent: 'flex-end',
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		padding: '20px',
		backgroundColor: theme.palette.neutral.white,
		position: 'fixed',
		bottom: 0,
		left: 0,
		right: 0,
		zIndex: 100,
		[theme.breakpoints.down('md')]: {
			justifyContent: 'center',
			flexDirection: 'column-reverse',
			padding: '20px 0',
		},
	},
	buttonQuote: {
		width: '266px',
		height: '48px',
		fontSize: '17px',
		fontWeight: '600',
		margin: theme.spacing(0, 0, 0),
		alignSelf: 'center',
		textTransform: 'none',
	},
	buttonShipment: {
		width: '286px',
		height: '48px',
		fontSize: '17px',
		fontWeight: '600',
		margin: theme.spacing(0, 0, 0),
		alignSelf: 'center',
		textTransform: 'none',
	},
	loadingWrapper: {
		position: 'absolute',
		top: 0,
		backgroundColor: 'rgba(255,255,255,0.9)',
		textAlign: 'center',
		height: '100vh',
		zIndex: 200,
		left: 0,
		right: 0,
		paddingTop: '150px',
	},
	bookItLater: {
		backgroundColor: '#FAFBFC',
		height: '48px',
		textTransform: 'none',
		boxShadow: 'none',
		margin: '0 0 0 1em',
		'@media only screen and (max-width: 1023px)': {
			width: '100%',
			margin: '0',
		},
	},
	previousButton: {
		width: '175px',
		height: '48px',
		fontSize: '17px',
		boxShadow: 'none',
		fontWeight: '600',
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.neutral.mainGray,
		margin: theme.spacing(0, 0, 0),
		marginLeft: '1em',
		alignSelf: 'end',
		textTransform: 'none',
		'@media only screen and (max-width: 768px)': {
			width: '140px',
		},
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginTop: '10px',
			marginBottom: '10px',
			marginLeft: '0em',
		},
	},
}))

export default useStyles
