/* eslint no-param-reassign: ["error", { "props": false }] */
import { createSlice } from '@reduxjs/toolkit'
import axiosUser from '../../utils/apiClient'

const companysByUserSlice = createSlice({
	name: 'companysByUser',
	initialState: {
		companies: [],
	},
	reducers: {
		loadCompanysByUser: (state, action) => {
			state.companies = action.payload
		},
	},
})

const { actions, reducer } = companysByUserSlice

export const { loadCompanysByUser } = actions

const getFullChildCompanies = async () => {
	const [error, data] = await axiosUser
		.get('/company/companies/tree')
		.then((response) => [null, response])
		.catch((err) => [err, err])
	return [error, data]
}

export const getListCompaniesByUser = () => async (dispatch) => {
	const [error, data] = await getFullChildCompanies()
	if (!error) {
		let options = []
		const localChildrenCompany = data?.data?.companiesTree ?? []
		if (data?.data?.principalCompany) {
			const principalCompany = data?.data?.principalCompany[0]
			options.push(principalCompany) // level 1
		}
		localChildrenCompany.forEach((element) => {
			options.push(element) // level 2
			if (element?.children?.length !== 0) {
				const childrenLevel3 = element.children
				childrenLevel3.forEach((elemLv3) => {
					options.push(elemLv3) // level 3
					if (elemLv3?.children?.length !== 0) {
						options = [...options, ...elemLv3.children] // level 4
					}
				})
			}
		})
		dispatch(loadCompanysByUser(options))
	}
}

export default reducer
