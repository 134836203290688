import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import removeZDate from '../../utils/removeZDate'

const useStyles = makeStyles((theme) => ({
	datePicker: {
		fontSize: 16,
		padding: 0,
		'& label': {
			color: theme.palette.neutral.black,
			fontSize: 16,
		},
		'& .MuiIconButton-root': {
			marginRight: -12,
		},
		width: '100%',
	},
	icon: {
		color: 'action',
		paddingRight: 0,
	},
	disabled: {
		backgroundColor: theme.palette.neutral.mainGray,
		color: theme.palette.neutral.black,
	},
}))

const RangeDatePicker = (props) => {
	const classes = useStyles()
	const { label, setValueDate, valueDate, isDisabled, minDate, style, isDisableWeekends, isError, setDateError } = props
	const [isOpen, setIsOpen] = useState(false)
	const [date, setDate] = useState(removeZDate(valueDate))

	useEffect(() => {
		setDate(removeZDate(valueDate))
	}, [valueDate])

	const disableWeekends = (disableDate) => disableDate.day() === 0 || disableDate.day() === 6

	return (
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<KeyboardDatePicker
				className={classes.datePicker}
				value={date}
				variant="inline"
				inputVariant="outlined"
				style={style}
				size="small"
				label={label}
				keyboardIcon={<CalendarTodayOutlinedIcon className={classes.icon} />}
				disabled={isDisabled}
				onChange={(event, value) => {
					const valueWioutZ = removeZDate(value)
					const dateForPickup = new Date(valueWioutZ)
					setDate(valueWioutZ)
					setValueDate(dateForPickup)
					setDateError(false)
					setIsOpen(false)
				}}
				KeyboardButtonProps={{
					onFocus: () => {
						setIsOpen(true)
					},
				}}
				PopoverProps={{
					disableRestoreFocus: true,
					onClose: () => {
						setIsOpen(false)
					},
				}}
				InputProps={{
					onFocus: () => {
						setIsOpen(true)
					},
					className: isDisabled ? classes.disabled : null,
				}}
				open={isOpen}
				minDate={minDate}
				shouldDisableDate={isDisableWeekends ? (disableDate) => disableWeekends(disableDate) : null}
				format="MM/DD/YYYY"
				error={isError}
			/>
		</MuiPickersUtilsProvider>
	)
}

RangeDatePicker.propTypes = {
	label: PropTypes.string.isRequired,
	setValueDate: PropTypes.func.isRequired,
	valueDate: PropTypes.instanceOf(Date),
	isDisabled: PropTypes.bool,
	minDate: PropTypes.instanceOf(Date),
	style: PropTypes.objectOf(PropTypes.string),
	isDisableWeekends: PropTypes.bool,
	isError: PropTypes.bool,
	setDateError: PropTypes.func,
}

RangeDatePicker.defaultProps = {
	minDate: undefined,
	valueDate: null,
	isDisabled: false,
	style: null,
	isDisableWeekends: false,
	isError: false,
	setDateError: () => {},
}

export default RangeDatePicker
