import React from 'react'
import PropTypes from 'prop-types'

import { removeUnderscoreAndUppercase } from '../../utils/helpers'

import ShipmentCarrierRatesMobile from '../ShipmentCarrierRatesMobile'
import ShipmentCarrierRatesWeb from '../ShipmentCarrierRatesWeb'

import './ShipmentCarrierInformation.scss'
import useStyles from './styles'

const ShipmentCarrierInformation = (props) => {
	const { quoteId, carrierData } = props
	const classes = useStyles()

	return (
		<div className="shipment-carrier-information-container">
			<div>
				<p className={classes.subtitle}>General</p>
				<div className="shipment-carrier-information-data">
					<div className="shipment-carrier-information-logo-and-name">
						<img className={classes.logo} src={carrierData.carrier_logo} alt="carrier" />
						<h5 className={classes.subtitle}>{carrierData.carrier_name}</h5>
					</div>
					<p className={classes.item}>
						<strong>Estimated Days in Transit: </strong>
						{carrierData.estimate_transit_days}
					</p>
					<p className={classes.item}>
						<strong>Quote #: </strong>
						{quoteId}
					</p>
					<p className={classes.item}>
						<strong>Service Type: </strong>
						{carrierData?.service_type ? removeUnderscoreAndUppercase(carrierData?.service_type) : '-'}
					</p>
				</div>
			</div>
			<div>
				<p className={classes.subtitle}>Rate</p>
				<ShipmentCarrierRatesMobile rates={carrierData} />
				<ShipmentCarrierRatesWeb rates={carrierData} />
			</div>
		</div>
	)
}

ShipmentCarrierInformation.propTypes = {
	quoteId: PropTypes.number.isRequired,
	carrierData: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default ShipmentCarrierInformation
