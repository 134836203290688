import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
	confirmation: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: '1em',
		padding: '10px',
	},
	title: {
		fontSize: '34px',
		color: '#F30000',
		textAlign: 'center',
		margin: '0 auto',
	},
	divider: {
		width: '100%',
		height: '1px',
		background: 'gray',
	},
	text: {
		marginTop: '1em',
		marginBottom: '1em',
		padding: '0 20px',
		textAlign: 'center',
	},
	textField: {
		width: '80%',
		margin: '1em auto',
	},
	goButton: {
		marginTop: '1em',
		width: '74%',
		height: '48px',
		alignSelf: 'center',
		textTransform: 'unset',
		fontWeight: '500',
		'&:hover': {
			backgroundColor: '#F30000',
		},
		'&:first-of-type': {
			marginBottom: '1em',
		},
	},
}))

export default useStyles
