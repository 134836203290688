import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { shipmentDetails, quotesListRoute } from '../../../../../../utils/constants'
import createNewTab from '../../../../../../utils/helpers'
import { addTab, setTabs } from '../../../../../../redux/actions/MultiTabs.actions'
import useStyles from './styles'

const InfoItem = ({ info: { title, id, origin, destination, bol } }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const history = useHistory()
	const currentTabs = useSelector((state) => state.multiTabs.tabs)

	const shipmentDetailsTab = () => {
		if (title.toUpperCase() === 'QUOTES') {
			const principalTab = [...currentTabs].find((item) => !item.route.includes(shipmentDetails.replace(':id', '')))
			if (principalTab) {
				const newTabs = currentTabs.map((tab) => ({
					...tab,
					active: tab.id === principalTab.id,
					route: tab.id === principalTab.id ? `${quotesListRoute}?id=${id}` : tab.route,
					title: tab.id === principalTab.id ? 'Saved Quotes' : tab.title,
				}))
				dispatch(setTabs(newTabs))
			}
		} else {
			const tabs = [...currentTabs]
			const newTab = createNewTab(
				tabs.length,
				'Shipment Details',
				shipmentDetails.replace(':id', id),
				'',
				'',
				null,
				true,
				true,
			)
			const newTabs = tabs.map((tab) => ({
				...tab,
				active: false,
			}))
			dispatch(addTab(newTab))
			newTabs.push(newTab)
			dispatch(setTabs(newTabs))
			history.push(newTab.route)
		}
	}
	return (
		<Grid container className={classes.container}>
			<Grid item xs={12}>
				<span className={classes.title}>{title.toUpperCase() ?? 'N/A'}</span>
			</Grid>
			<Grid item xs={12}>
				<span className={classes.label}>{`# ${title.substring(0, title.length - 1)}: `.toLowerCase()}</span>
				<span>{bol ?? id ?? 'N/A'}</span>
			</Grid>
			<Grid container xs={12} spacing={2}>
				<Grid item className={classes.border}>
					<span className={classes.label}>{'Origin: '}</span>
					<span>{origin ?? 'N/A'}</span>
				</Grid>
				<Grid item>
					<span className={classes.label}>{'Destination: '}</span>
					<span>{destination ?? 'N/A'}</span>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<div onClick={shipmentDetailsTab} onKeyPress={() => {}} role="button" tabIndex="0" className={classes.link}>
					View more
				</div>
			</Grid>
		</Grid>
	)
}

InfoItem.propTypes = {
	info: PropTypes.shape({
		title: PropTypes.string.isRequired,
		id: PropTypes.number.isRequired,
		origin: PropTypes.string.isRequired,
		destination: PropTypes.string.isRequired,
		bol: PropTypes.string,
	}).isRequired,
}

export default InfoItem
