import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	TextField,
	Typography,
} from '@material-ui/core'
import { setDisabledSubject, setOpen, setSubject } from '../../redux/actions/SupportModal.actions'
import useStyles from './styles'
import { postTicketToSupport } from '../../redux/requests/Accounts.request'
import SessionContext from '../../context/session'
import sweetAlertModal from '../SweetAlertModal/SweetAlertModal'

const SupportModal = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const { getUserData } = useContext(SessionContext)
	const { open, subject, disabledSubject } = useSelector((state) => state.supportModal)
	const [values, setValues] = useState({ subject: '', body: '' })
	const [isLoading, setIsLoading] = useState(false)
	const [hasAllFields, setHasAllFields] = useState(false)

	const handleClose = () => {
		dispatch(setOpen(false))
		dispatch(setDisabledSubject(false))
	}

	const handleChange = (prop) => (event) => {
		setValues({ ...values, [prop]: event.target.value })
	}

	const handleSendTicket = async () => {
		const { actualCompany } = getUserData()
		const config = { headers: { 'X-Company-id': actualCompany } }
		if (!hasAllFields) {
			setIsLoading(true)
			const [error] = await postTicketToSupport({ subject: values.subject, body: values.body }, config)
			if (error === null) {
				setIsLoading(false)
				sweetAlertModal(
					'success',
					'Your support request was sent successfully',
					'Thanks for reaching out!  We’ll get back to you within 2 business hours. If you need immediate assistance, please call 888-297-6968. Thanks!',
					'Ok',
					null,
					null,
					null,
				)
				handleClose()
			} else {
				setIsLoading(false)
				sweetAlertModal(
					'warning',
					'Something went wrong',
					'Something went wrong, please send your ticket again',
					'Ok',
					null,
					null,
					null,
				)
			}
		}
	}

	useEffect(() => {
		if (subject !== '') {
			setValues((prevState) => ({ ...prevState, subject }))
		}
	}, [subject])

	useEffect(() => {
		setHasAllFields(Object.keys(values).filter((key) => values[key] === '').length !== 0)
	}, [values])

	return (
		<Dialog
			classes={{ paper: classes.paper }}
			aria-labelledby="support-dialog-title"
			open={open}
			maxWidth="lg"
			onBackdropClick={handleClose}
			onEscapeKeyDown={handleClose}
			onExited={() => {
				dispatch(setSubject(''))
				setValues({ subject: '', body: '' })
			}}
		>
			<DialogTitle id="support-dialog-title" className={classes.dialogTitle} disableTypography>
				Contact Support
			</DialogTitle>
			<DialogContent className={classes.dialogContent}>
				<Typography align="center" variant="body1">
					Contact support for assistance
				</Typography>
				<Grid container>
					<Grid item xs={12} />
					<Grid item xs={12}>
						<TextField
							className={classes.inputSubject}
							id="support-mail-subject"
							label="Subject"
							variant="outlined"
							color="secondary"
							fullWidth
							size="small"
							placeholder="[BOL#] / [Account Name] / Support Request"
							value={values.subject}
							onChange={handleChange('subject')}
							disabled={disabledSubject}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="support-mail-body"
							label="Message"
							variant="outlined"
							color="secondary"
							fullWidth
							size="small"
							multiline
							rows={6}
							placeholder="How can we help?  Please provide as much detail as possible, and we’ll get back to you within 2 business hours.  If you need immediate assistance, please call 888-297-6968.  Thanks!"
							value={values.body}
							onChange={handleChange('body')}
							InputLabelProps={{ shrink: true }}
							InputProps={{
								classes: { input: classes.inputMessage },
							}}
							onInput={(e) => {
								e.target.value = e.target.value.slice(0, 255)
							}}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions className={classes.dialogActions} disableSpacing>
				<Button
					className={classes.dialogButton}
					color="secondary"
					variant="contained"
					disableElevation
					disabled={isLoading || hasAllFields}
					onClick={handleSendTicket}
				>
					{!isLoading ? 'Send Support Ticket' : <CircularProgress />}
				</Button>
				<Button className={classes.dialogButton} variant="contained" disableElevation onClick={handleClose}>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default SupportModal
