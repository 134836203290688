import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	filterButtonText: {
		color: theme.palette.neutral.black,
		fontWeight: theme.typography.fontWeightRegular,
		textTransform: 'none',
	},
	filterTriggerButton: {
		color: theme.palette.neutral.black,
		padding: 0,
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	filterButtonModalText: {
		color: theme.palette.neutral.black,
		fontWeight: theme.typography.fontWeightRegular,
		textTransform: 'none',
	},
	filterFormWrapper: {
		padding: 15,
	},
	quotesHistoryTitleButton: {
		alignItems: 'center',
		display: 'grid',
		gridAutoFlow: 'column',
		justifyContent: 'space-between',
	},
	quoteHistoryDescription: {
		margin: '0 0 12px 0',
		color: theme.palette.neutral.black,
	},
}))

export default useStyles
