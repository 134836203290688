import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	tableCell: {
		fontSize: '14px',
		fontWeight: theme.typography.fontWeightRegular,
		borderBottomStyle: 'none',
		padding: '5px',
	},
	tableInput: {
		backgroundColor: theme.palette.background.default,
	},
}))

export default useStyles
