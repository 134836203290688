import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { resetFilters } from '../../../../redux/actions/CarriersFilters.actions'
import NewCarrierContainer from '../containers/NewCarrierContainer'
import useStyles from './styles'

const NewCarrier = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	useEffect(
		() => async () => {
			await dispatch(resetFilters())
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	)
	return (
		<div className={classes.newCarrierContainer}>
			<NewCarrierContainer />
		</div>
	)
}

export default NewCarrier
