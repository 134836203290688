import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { TableCell, TableRow, Radio, useTheme } from '@material-ui/core'
import useStyles from '../styles'

const TableRowModal = (props) => {
	const theme = useTheme()
	const classes = useStyles()
	const locationsByIdReducer = useSelector((state) => state.locations.locationById)
	const { labelId, isSelected, data, setSelected } = props

	const handleClick = (event, location) => {
		setSelected({ ...location, accessorials: locationsByIdReducer[location.id] })
	}

	const getAccesorialsConcatenatedByType = (accesorials) =>
		accesorials && accesorials.length > 0
			? accesorials.reduce((acum, accesorial) => (accesorial ? acum.concat(accesorial, ', ') : ''), '')
			: ''

	return (
		<>
			<TableRow
				className={classes.tableRow}
				hover
				role="checkbox"
				aria-checked={isSelected}
				tabIndex={-1}
				key={data.id}
				selected={isSelected}
				onClick={(event) => handleClick(event, data)}
			>
				<TableCell padding="checkbox">
					<Radio
						inputProps={{ 'aria-labelledby': labelId }}
						checked={isSelected}
						style={{
							color: isSelected ? theme.palette.secondary.main : theme.palette.primary.light,
						}}
					/>
				</TableCell>
				<TableCell align="left" className={classes.tableCell}>
					{data.location_id ? data.location_id : '-'}
				</TableCell>
				<TableCell align="left" className={classes.tableCell}>
					{data.name}
				</TableCell>
				<TableCell align="left" className={classes.tableCell}>
					{data.address}
				</TableCell>
				<TableCell align="left" className={classes.tableCell}>
					{data.address2}
				</TableCell>
				<TableCell align="left" className={classes.tableCell}>
					{data.city}
				</TableCell>
				<TableCell align="left" className={classes.tableCell}>
					{data.state}
				</TableCell>
				<TableCell align="left" className={classes.tableCell}>
					{data.zip_code}
				</TableCell>
				<TableCell align="left" className={classes.tableCell}>
					{data.country}
				</TableCell>
				<TableCell align="left" className={classes.tableCell}>
					{data.contact}
				</TableCell>
				<TableCell align="left" className={classes.tableCell}>
					{data.phone}
				</TableCell>
				<TableCell align="left" className={classes.tableCell}>
					{data.accesorials ? getAccesorialsConcatenatedByType(data.accesorials) : ''}
				</TableCell>
			</TableRow>
		</>
	)
}

TableRowModal.propTypes = {
	labelId: PropTypes.string.isRequired,
	isSelected: PropTypes.bool.isRequired,
	data: PropTypes.objectOf(PropTypes.any).isRequired,
	setSelected: PropTypes.func.isRequired,
}

export default TableRowModal
