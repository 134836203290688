import React from 'react'
import PropTypes from 'prop-types'
import {
	Grid,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
} from '@material-ui/core'

import AddIcon from '@material-ui/icons/Add'
import omit from 'lodash/omit'
import useStyles from './style'
import CarrierLiabilitiesRow from '../CarrierLiabilitesRow/CarrierLiabilitiesRow'
import { allowedClassValuesSorted } from '../shipmentClassSorted'

const CarrierLiabilitiesTable = (props) => {
	const { liabilities, setLiabilities, editMode } = props

	const classes = useStyles()

	// this happens when you confirm edit on a liability row
	const setLiability = (index, newValues) => {
		const updatedLiability = newValues

		const liabilityClone = [...liabilities]
		liabilityClone[index].class = updatedLiability.class
		liabilityClone[index].liability_per_pound = updatedLiability.liability_per_pound
		liabilityClone[index].min = updatedLiability.min
		liabilityClone[index].max = updatedLiability.max
		setLiabilities(liabilityClone)
	}

	const handleDeleteClick = (index) => {
		const inputList = [...liabilities]
		inputList.splice(index, 1)
		setLiabilities(inputList)
	}

	const addLiability = () => {
		const availableClass = allowedClassValuesSorted.find((classValue) => {
			const foundIndex = liabilities.findIndex((liability) => liability.class === classValue)
			return foundIndex === -1
		})
		const newLiability = { class: availableClass, new: true }
		const liabilityClone = [...liabilities, newLiability]
		setLiabilities(liabilityClone)
	}

	return (
		<>
			{liabilities.length ? (
				<>
					<Grid container className={classes.desktopTable}>
						<TableContainer className={classes.tableContainer}>
							<Table className={classes.table} aria-label="child accounts details" padding="none">
								<TableHead>
									<TableRow>
										<TableCell align="center" className={classes.tableCellHead}>
											#
										</TableCell>
										<TableCell align="center" className={classes.tableCellHead}>
											Class
										</TableCell>
										<TableCell align="center" className={classes.tableCellHead}>
											Liability Per Pound
										</TableCell>
										<TableCell align="center" className={classes.tableCellHead}>
											Minimum Liability
										</TableCell>
										<TableCell align="center" className={classes.tableCellHead}>
											Maximum Liability
										</TableCell>
										{editMode && (
											<TableCell align="center" className={classes.tableCellHead}>
												Actions
											</TableCell>
										)}
									</TableRow>
								</TableHead>
								<TableBody>
									{liabilities.map((item, index) => (
										<CarrierLiabilitiesRow
											key={item.class}
											liability={omit(item, 'new')}
											index={index}
											editMode={editMode}
											startEditable={item.new}
											deleteLiability={handleDeleteClick}
											setLiability={setLiability}
											allLiabilities={liabilities}
										/>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
				</>
			) : (
				<div className={classes.noLiabilitiesMessageContainer}>
					<h3 className={classes.noLiabilitiesMessageTitle}>No Carrier Liabilities Found</h3>
				</div>
			)}
			{editMode && liabilities.length < allowedClassValuesSorted.length && (
				<Grid container>
					<Grid item xs={12} sm={6} md={8} />
					<Grid item xs={12} sm={6} md={4}>
						<Tooltip title="Add">
							<IconButton aria-label="add" color="secondary" onClick={() => addLiability()}>
								<AddIcon fontSize="small" /> Add new Carrier Liability
							</IconButton>
						</Tooltip>
					</Grid>
				</Grid>
			)}
		</>
	)
}

const liabilityProp = PropTypes.shape({
	id: PropTypes.number,
	integration_carrier_id: PropTypes.number,
	class: PropTypes.string,
	liability_per_pound: PropTypes.number,
	max: PropTypes.number,
	min: PropTypes.number,
	audit_creation_date: PropTypes.string, // '2022-01-24T17:06:10.809Z',
	audit_update_date: PropTypes.string, // '2022-01-24T17:06:10.809Z',
	create_user_id: PropTypes.number,
	update_user_id: PropTypes.number,
})

CarrierLiabilitiesTable.propTypes = {
	editMode: PropTypes.bool.isRequired,
	setLiabilities: PropTypes.func.isRequired,
	liabilities: PropTypes.arrayOf(liabilityProp).isRequired,
}

export default CarrierLiabilitiesTable
