import React from 'react'
import PropTypes from 'prop-types'

import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Tooltip,
	withStyles,
	IconButton,
} from '@material-ui/core'

import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import VisibilityIcon from '@material-ui/icons/Visibility'

import useStyles from './styles'
import { HazIcon, StackIcon } from '../../../../assets/Icons/Icons'

import { displayDetails, displayPackageType } from '../../utils/helpers'

const CustomizedTooltip = withStyles({
	tooltip: {
		color: 'black',
		backgroundColor: '#EBEEF1',
		fontWeight: 400,
		margin: '2px',
		fontSize: '0.9rem',
	},
})(Tooltip)

const ShipmentItemsDetails = (props) => {
	const classes = useStyles()
	const { shipmentItems, setEditItemData } = props

	return (
		<TableContainer component={Paper}>
			<Table className={classes.table} aria-label="customized table">
				<TableHead>
					<TableRow>
						<TableCell align="center" className={classes.tableCellHead}>
							#
						</TableCell>
						<TableCell className={classes.tableCellHead}>Item information</TableCell>
						<TableCell className={classes.tableCellHead}>NMFC Code</TableCell>
						<TableCell className={classes.tableCellHead}>Product Description</TableCell>
						<TableCell className={classes.tableCellHead}>Details</TableCell>
						<TableCell align="center" className={classes.tableCellHead}>
							{' '}
						</TableCell>
						<TableCell align="center" className={classes.tableCellHead}>
							Actions
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{shipmentItems.map((item, index) => (
						<TableRow key={`id-${index + 1}`}>
							<TableCell align="center" component="th" scope="row">
								{String.fromCharCode(65 + index)}
							</TableCell>
							<TableCell>
								{item
									? `${item.pieces_value} ${displayPackageType(item.pieces_value, item.pieces_unit)}, ${item.weight_value} lbs, ${item.dimensions_lenght} x ${item.dimensions_width} x ${item.dimensions_height} in, Freight Class ${item.class}`
									: '-'}
							</TableCell>
							<TableCell>
								{item.nmfc ? item.nmfc : '-'}
								{item.sub_nmfc ? `-${item.sub_nmfc}` : ''}
							</TableCell>
							<TableCell>{item.description}</TableCell>
							<TableCell className={classes.newShipmentItems}>
								<div className={classes.detailsColumn}>
									<span className={classes.newShipmentItemsUnderlined}>
										{item.item_details && item.item_details.length > 0 ? displayDetails(item.item_details) : null}
									</span>
									{item.item_details && item.item_details.length > 0 ? (
										<CustomizedTooltip title={displayDetails(item.item_details)}>
											<VisibilityIcon color="secondary" />
										</CustomizedTooltip>
									) : null}
								</div>
							</TableCell>
							<TableCell align="center">
								{item.is_haz_mat ? (
									<Tooltip title="Hazmat such as chemicals, acid, waste with a UN number">
										<span>
											<HazIcon color="secondary" />
										</span>
									</Tooltip>
								) : null}
								{item.stackable ? (
									<Tooltip title="Items that can be stacked to reduce linear space">
										<span>
											<StackIcon color="secondary" />
										</span>
									</Tooltip>
								) : null}
							</TableCell>
							<TableCell align="center">
								<IconButton size="small" onClick={() => setEditItemData(item)}>
									<EditOutlinedIcon color="secondary" />
								</IconButton>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

ShipmentItemsDetails.propTypes = {
	shipmentItems: PropTypes.arrayOf(PropTypes.any).isRequired,
	setEditItemData: PropTypes.func.isRequired,
}

export default ShipmentItemsDetails
