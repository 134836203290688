/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TablePagination,
	TableRow,
	Paper,
	Button,
} from '@material-ui/core'
import { fetchLocations, updatePaginator, resetLocations } from '../../../../redux/actions/Locations.actions'
import SearchBar from '../../../../components/SearchBar'
import TableHeader from '../../../../components/TableHeader'
import TableRowModal from './ModalSavedLocationsTableRow/TableRowModal'
import NoResultsFoundModal from '../../../../components/NoResultsFoundModal'
import sweetAlertModal from '../../../../components/SweetAlertModal'
import useStyles from './styles'
import './SuggestionsModal.scss'
import { originAccessorials, destinationAccessorials } from '../../../../utils/dropDownOptions'

const buildParamsFromState = (searchParams) => {
	let queryParams = ''
	if (searchParams !== '') {
		queryParams += `&search_bar=${searchParams}`
	}
	return queryParams
}

const SuggestionsModal = ({
	open,
	onClose,
	type,
	fetchDataFromZipCode,
	setOriginData,
	setValueOrigin,
	setDestinationData,
	setValueDestination,
	setHereMapsResponseOrigin,
	setHereMapsResponseDestination,
	setOriginAccessorialsSelected,
	setDefaultValueOriginAccs,
	setDestinationAccessorialsSelected,
	setDefaultValueDestinationAccs,
	setShipper,
	setConsignee,
	dispatchSetOriginAccessorials,
	dispatchSetDestinationAccessorials,
	dispatchSetOrigin,
	dispatchSetOriginData,
	dispatchSetDestination,
	dispatchSetDestinationData,
	setResidenceAccessorials,
	siteTypeOrigin,
	siteTypeDestination,
	setSiteTypeOrigin,
	setSiteTypeDestination,
}) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const [searchBar, setSearchBar] = useState('')
	const [search, setSearch] = useState(searchBar)
	const [selected, setSelected] = useState({ id: null, zipCode: null, accessorials: [] })
	const [page, setPage] = useState(0)
	const [isSearching, setIsSearching] = useState(false)
	const [backupPage, setBackupPage] = useState(0)
	const locationsReducer = useSelector((state) => state.locations)
	const locationsListReducer = useSelector((state) => state.locations.locations)
	const isLoadingReducer = useSelector((state) => state.locations.isLoading)
	const totalRecordsReducer = useSelector((state) => state.locations.totalRecords)
	const pagSkipReducer = useSelector((state) => state.locations.pagSkip)
	const isSelected = (id) => selected.id === id
	const tableType = 'savedLocations'
	const idleTime = 0.5
	const rowsPerPage = 10
	const LocationsTypes = { ORIGIN: 'ORIGIN', DESTINATION: 'DESTINATION' }

	const cleanParams = (params) =>
		params && typeof params === 'string' && params.endsWith('//?') ? params.replace('//?', '/?') : params

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (search.length >= 2) {
				setSearchBar(encodeURIComponent(search))
			} else if (search.length === 0) {
				setSearchBar('')
			}
		}, idleTime * 1000)
		return () => clearTimeout(timeOutId)
	}, [search])

	useEffect(() => {
		const getLocations = async (offset, limit) => {
			const filterParams = buildParamsFromState(searchBar)
			const params = `/${offset}/${limit}?${filterParams}`
			const { status } = await dispatch(fetchLocations(cleanParams(params)))
			if (status === 'error')
				sweetAlertModal('error', 'There was an error fetching the locations', null, 'Ok', true, false)
		}
		getLocations(pagSkipReducer, rowsPerPage)
	}, [searchBar, dispatch, pagSkipReducer, open])

	const checkResidentialAccessorials = (residentialType, selectedAccessorials) => {
		const residentialOriginIsSelected = selectedAccessorials.find((element) => element.accessorialId === 5) ?? {}
		const residentialDestinationIsSelected = selectedAccessorials.find((element) => element.accessorialId === 2) ?? {}
		if (residentialType === 'origin' && Object.keys(residentialOriginIsSelected).length > 0) {
			setSiteTypeOrigin('residence')
		}
		if (residentialType === 'destination' && Object.keys(residentialDestinationIsSelected).length > 0) {
			setSiteTypeDestination('residence')
		}
	}

	const setAccessorials = (accessorials, accessorialType) => {
		const accessorialList = accessorialType === 'ORIGIN' ? originAccessorials : destinationAccessorials
		const fullAccessorials = accessorialList.filter((element) => accessorials.includes(element.name))
		const { city, state, zip_code: zipCode, latitude, longitude } = selected
		if (fullAccessorials) {
			let selectedAccessorials = []
			fullAccessorials.forEach((accesorial) => {
				if (accessorialType.toUpperCase() === LocationsTypes.ORIGIN) {
					selectedAccessorials = [
						...selectedAccessorials,
						{
							...accesorial,
						},
					]
				} else if (accessorialType.toUpperCase() === LocationsTypes.DESTINATION) {
					selectedAccessorials = [
						...selectedAccessorials,
						{
							...accesorial,
						},
					]
				}
			})
			if (accessorialType.toUpperCase() === LocationsTypes.ORIGIN) {
				setOriginAccessorialsSelected(selectedAccessorials)
				setDefaultValueOriginAccs(selectedAccessorials)
				dispatchSetOriginAccessorials(selectedAccessorials)
				const label = `${city}, ${state} ${zipCode}`
				if (siteTypeOrigin === 'residence') setResidenceAccessorials('origin', 'residence', selectedAccessorials)
				if (siteTypeOrigin === 'business') checkResidentialAccessorials('origin', selectedAccessorials)
				dispatchSetOrigin(label)
				dispatchSetOriginData({
					...selected,
					label: `${city}, ${state} ${zipCode}`,
					latitude: latitude ? Number(latitude) : null,
					longitude: longitude ? Number(longitude) : null,
				})
				setShipper({ ...selected, accessorials: selectedAccessorials })
			} else if (accessorialType.toUpperCase() === LocationsTypes.DESTINATION) {
				setDestinationAccessorialsSelected(selectedAccessorials)
				setDefaultValueDestinationAccs(selectedAccessorials)
				dispatchSetDestinationAccessorials(selectedAccessorials)
				if (siteTypeDestination === 'residence')
					setResidenceAccessorials('destination', 'residence', selectedAccessorials)
				if (siteTypeDestination === 'business') checkResidentialAccessorials('destination', selectedAccessorials)
				const label = `${selected.city}, ${selected.state} ${selected.zip_code}`
				dispatchSetDestination(label)
				dispatchSetDestinationData({
					...selected,
					label: `${selected.city}, ${selected.state} ${selected.zip_code}`,
					latitude: latitude ? Number(latitude) : null,
					longitude: longitude ? Number(longitude) : null,
				})
				setConsignee({ ...selected, accessorials: selectedAccessorials })
			}
		} else if (accessorialType.toUpperCase() === LocationsTypes.ORIGIN) {
			const label = `${selected.city}, ${selected.state} ${selected.zip_code}`
			dispatchSetOrigin(label)
			dispatchSetOriginData({
				...selected,
				label: `${selected.city}, ${selected.state} ${selected.zip_code}`,
				latitude: latitude ? Number(latitude) : null,
				longitude: longitude ? Number(longitude) : null,
			})
			setShipper({ ...selected, accessorials: [] })
		} else if (accessorialType.toUpperCase() === LocationsTypes.DESTINATION) {
			const label = `${selected.city}, ${selected.state} ${selected.zip_code}`
			dispatchSetDestination(label)
			dispatchSetDestinationData({
				...selected,
				label: `${selected.city}, ${selected.state} ${selected.zip_code}`,
				latitude: latitude ? Number(latitude) : null,
				longitude: longitude ? Number(longitude) : null,
			})
			setConsignee({ ...selected, accessorials: [] })
		}
	}

	const getDataFromZipCode = async (zipCode, locationType, accessorials) => {
		if (zipCode.length > 4 && !selected.latitude && !selected.longitude) {
			const [status, resp] = await fetchDataFromZipCode(zipCode)
			if (status === 'error') {
				sweetAlertModal(
					'error',
					'There was an error fetching the data related to the zip code!',
					null,
					'Ok',
					true,
					false,
				)
			} else if (!resp || resp.length === 0) {
				sweetAlertModal('error', 'The zip code does not exist', null, 'Ok', true, false)
			} else {
				const zipcodeData = resp[0]
				if (locationType.toUpperCase() === LocationsTypes.ORIGIN) {
					setHereMapsResponseOrigin(resp)
					setOriginData(zipcodeData)
					setValueOrigin(zipcodeData)
					dispatchSetOriginData(zipcodeData)
					setAccessorials(accessorials, LocationsTypes.ORIGIN)
				}
				if (locationType.toUpperCase() === LocationsTypes.DESTINATION) {
					setHereMapsResponseDestination(resp)
					setDestinationData(zipcodeData)
					setValueDestination(zipcodeData)
					dispatchSetDestinationData(zipcodeData)
					setAccessorials(accessorials, LocationsTypes.DESTINATION)
				}
				return true
			}
		} else {
			const label = `${selected.zip_code}, ${selected.city}, ${selected.state}, ${selected.country}`
			const locationData = {
				...selected,
				postalCode: selected.zip_code,
				latitude: selected.latitude ? Number(selected.latitude) : null,
				longitude: selected.longitude ? Number(selected.longitude) : null,
				label,
			}
			if (locationType.toUpperCase() === LocationsTypes.ORIGIN) {
				setOriginData(locationData)
				setValueOrigin(locationData)
				dispatchSetOriginData(locationData)
				setAccessorials(accessorials, LocationsTypes.ORIGIN)
			}
			if (locationType.toUpperCase() === LocationsTypes.DESTINATION) {
				setDestinationData(locationData)
				setValueDestination(locationData)
				dispatchSetDestinationData(locationData)
				setAccessorials(accessorials, LocationsTypes.DESTINATION)
			}
		}
		return true
	}

	const renderTableBody = () => {
		const data = locationsReducer.locations.map((row, index) => (
			<TableRowModal
				key={row.id}
				labelId={`enhanced-table-checkbox-${index}`}
				isSelected={isSelected(row.id)}
				data={row}
				setSelected={setSelected}
				accessorialType={type}
			/>
		))

		if (isLoadingReducer && data.length === 0) {
			return <div className={classes.circularProgressTemplateItemsModal} />
		}
		if (!isLoadingReducer && data.length === 0) {
			const locationFiltersParams = buildParamsFromState(searchBar)
			return <NoResultsFoundModal type="Locations" filters={locationFiltersParams} />
		}
		return data
	}

	const handleSearchBarChangedTimeout = (event) => {
		const { value } = event.target
		if (!value && value.length === 0 && isSearching) {
			setPage(backupPage)
			setIsSearching(false)
			dispatch(updatePaginator(backupPage * rowsPerPage))
		}
		if (value && value.length !== 0 && !isSearching) {
			setPage(0)
			setIsSearching(true)
			dispatch(updatePaginator(0))
		}
		setSearch(value)
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
		if (!isSearching) {
			setBackupPage(newPage)
		}
		dispatch(updatePaginator(newPage * rowsPerPage))
	}

	const handleCancel = () => {
		setSelected({ id: null, zipCode: null, accessorials: [] })
		setSearch('')
		setPage(0)
		setBackupPage(0)
		setIsSearching(false)
		onClose()
		dispatch(updatePaginator(0))
		dispatch(resetLocations())
	}

	const handleSelect = async () => {
		const zipCode = selected.zip_code
		if (zipCode && (await getDataFromZipCode(zipCode, type, selected.accesorials))) {
			handleCancel()
		} else {
			if (!selected || !selected.id) {
				sweetAlertModal('error', 'No location has been selected', null, 'Ok', true, false)
			}
			if (selected.id) {
				sweetAlertModal('error', 'The zip code was not found', null, 'Ok', true, false)
			}
		}
	}

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			handleSelect()
		} else if (event.key === 'Escape') {
			handleCancel()
		}
	}

	const emptyRows =
		!isLoadingReducer && locationsListReducer.length === 0
			? 0
			: rowsPerPage - Math.min(rowsPerPage, locationsListReducer.length * rowsPerPage)
	return (
		<Dialog
			className={classes.modalDialog}
			classes={{ paper: classes.modalDialog }}
			onClose={handleCancel}
			open={open}
			maxWidth="lg"
			onKeyDown={handleKeyDown}
		>
			<DialogTitle disableTypography className={classes.titleDialog}>
				<h2>Search Saved Locations</h2>
			</DialogTitle>
			<form>
				<div className={classes.searchBarContainer}>
					<SearchBar
						value={search}
						autoFocus
						placeholder="Search for location id, company name, address, zip code, city, state, country..."
						onChangeHandler={handleSearchBarChangedTimeout}
					/>
				</div>
				<Paper className={classes.paper}>
					<TableContainer className="tableContainer modalDialog">
						<Table className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
							<TableHeader
								classes={classes}
								numSelected={1}
								rowCount={locationsReducer.locations.length}
								tableType={tableType}
							/>
							<TableBody>
								{renderTableBody()}
								{emptyRows > 0 && (
									<TableRow style={{ height: 48 * emptyRows }}>
										<TableCell colSpan={6} style={{ borderBottom: 'none' }} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
				<TablePagination
					style={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}
					rowsPerPageOptions={[]}
					component="div"
					count={totalRecordsReducer}
					rowsPerPage={rowsPerPage}
					page={page}
					onChangePage={handleChangePage}
					backIconButtonProps={{ disabled: isLoadingReducer || pagSkipReducer === 0 }}
					nextIconButtonProps={{
						disabled: isLoadingReducer || locationsListReducer.length !== rowsPerPage,
					}}
				/>
				<Grid className={classes.gridMenu} container justify="flex-end" alignItems="center">
					<Button
						style={{ marginLeft: '1em' }}
						className={classes.previousButton}
						variant="contained"
						onClick={handleCancel}
					>
						Cancel
					</Button>
					<Button
						style={{ marginLeft: '1em' }}
						className={classes.button}
						variant="contained"
						color="primary"
						onClick={handleSelect}
					>
						Select Location
					</Button>
				</Grid>
			</form>
		</Dialog>
	)
}

SuggestionsModal.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	type: PropTypes.string.isRequired,
	fetchDataFromZipCode: PropTypes.func.isRequired,
	setOriginData: PropTypes.func.isRequired,
	setValueOrigin: PropTypes.func.isRequired,
	setDestinationData: PropTypes.func.isRequired,
	setValueDestination: PropTypes.func.isRequired,
	setHereMapsResponseOrigin: PropTypes.func.isRequired,
	setHereMapsResponseDestination: PropTypes.func.isRequired,
	setOriginAccessorialsSelected: PropTypes.func.isRequired,
	setDefaultValueOriginAccs: PropTypes.func.isRequired,
	setDestinationAccessorialsSelected: PropTypes.func.isRequired,
	setDefaultValueDestinationAccs: PropTypes.func.isRequired,
	setShipper: PropTypes.func.isRequired,
	setConsignee: PropTypes.func.isRequired,
	dispatchSetOriginAccessorials: PropTypes.func.isRequired,
	dispatchSetDestinationAccessorials: PropTypes.func.isRequired,
	dispatchSetOrigin: PropTypes.func.isRequired,
	dispatchSetOriginData: PropTypes.func.isRequired,
	dispatchSetDestination: PropTypes.func.isRequired,
	dispatchSetDestinationData: PropTypes.func.isRequired,
	setResidenceAccessorials: PropTypes.func.isRequired,
	siteTypeOrigin: PropTypes.string.isRequired,
	siteTypeDestination: PropTypes.string.isRequired,
	setSiteTypeOrigin: PropTypes.func.isRequired,
	setSiteTypeDestination: PropTypes.func.isRequired,
}

export default SuggestionsModal
