import { capitalize } from 'lodash'

export const serviceTypesToUse = ['VOLUME', 'LTL_STANDARD']
export const contractTypesToUse = ['BLANKET', 'CSP']
/** call handler with (contractType,serviceType) */
export function rateAccessLoop(handler) {
	contractTypesToUse.forEach((contractType) => {
		serviceTypesToUse.forEach((serviceType) => {
			handler(contractType, serviceType)
		})
	})
}

export function getRateAccessSectionTitle(contractType, serviceType) {
	const contractTypeDisplay = contractType === 'CSP' ? contractType : capitalize(contractType)
	const serviceTypeDisplay = serviceType === 'VOLUME' ? 'Volume' : 'LTL Standard'
	const fieldPrefix = `${contractType}_${serviceType}_`
	const sectionTitle = `${contractTypeDisplay}, ${serviceTypeDisplay} Contracts`

	return { fieldPrefix, sectionTitle }
}
