import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { IconButton, MenuItem, TableCell, TableRow, TextField, Tooltip } from '@material-ui/core'

import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import CheckMarkIcon from '@material-ui/icons/Check'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'

import { isNumber } from 'lodash'
import { displayDollarValue } from '../../../../../utils/helpers'
import useStyles from './style'
import { allowedClassValuesSorted } from '../shipmentClassSorted'

const convertToNumber = (value) => {
	const numeric = Number(value)
	return value && isNumber(numeric) && !Number.isNaN(numeric) ? numeric : null
}

const validateLiabilityPerPound = (liabilityPerPound) => {
	const convertedValue = convertToNumber(liabilityPerPound)
	return convertedValue ? undefined : 'Liability Per Pound is required.'
}

const CarrierLiabilitiesRow = (props) => {
	const { liability, setLiability, editMode, index, allLiabilities, startEditable, deleteLiability } = props

	const [editing, setEditing] = useState(editMode && startEditable)
	const [editValues, setEditValues] = useState({ ...liability })
	const [errors, setErrors] = useState({
		class: undefined,
		liability_per_pound: undefined,
		min: undefined,
		max: undefined,
	})

	const classes = useStyles()

	const handleEditClick = () => {
		if (editMode) {
			setEditValues({ ...liability })
			setErrors({
				class: undefined,
				liability_per_pound: undefined,
				min: undefined,
				max: undefined,
			})
			setEditing(true)
		}
	}

	const handleSaveClick = () => {
		const errorClone = { ...errors }
		errorClone.liability_per_pound = validateLiabilityPerPound(editValues.liability_per_pound)
		if (!Object.keys(errorClone).some((key) => errorClone[key])) {
			setEditing(false)
			setLiability(index, { ...editValues })
		} else {
			setErrors(errorClone)
		}
	}

	const handleDeleteClick = () => {
		deleteLiability(index)
	}

	const validateClass = (value) => {
		if (!value) {
			return 'Class is required'
		}
		const conflict = allLiabilities?.find((existingLiability, liabilityIndex) => {
			// purposely verbose to avoid auto-formatting into a linter exception
			if (liabilityIndex !== index && existingLiability.class === value) {
				return true
			}
			return false
		})
		return conflict ? `A liability already exists with the class ${value}` : undefined
	}

	const updateLiabilityClass = (value) => {
		const errorClone = { ...errors }
		errorClone.class = validateClass(value)
		setErrors(errorClone)
		setEditValues({ ...editValues, class: value })
	}

	const updateLiabilityPerPound = (value) => {
		const convertedValue = convertToNumber(value)
		const errorClone = { ...errors }
		errorClone.liability_per_pound = validateLiabilityPerPound(convertedValue)
		setErrors(errorClone)
		setEditValues({ ...editValues, liability_per_pound: convertToNumber(value) })
	}

	const updateLiabilityMin = (value) => {
		setEditValues({ ...editValues, min: convertToNumber(value) })
	}

	const updateLiabilityMax = (value) => {
		setEditValues({ ...editValues, max: convertToNumber(value) })
	}

	return (
		<>
			<TableRow key={`id-${index + 1}`}>
				<TableCell align="center" className={classes.tableCell}>
					{index + 1}
				</TableCell>
				{editing || (
					<>
						<TableCell align="center" className={classes.tableCell}>
							{liability.class}
						</TableCell>
						<TableCell align="center" className={classes.tableCell}>
							{displayDollarValue(liability.liability_per_pound)}
						</TableCell>
						<TableCell align="center" className={classes.tableCell}>
							{displayDollarValue(liability.min)}
						</TableCell>
						<TableCell align="center" className={classes.tableCell}>
							{displayDollarValue(liability.max)}
						</TableCell>
					</>
				)}
				{editing && (
					<>
						<TableCell align="center" className={classes.tableCell}>
							<TextField
								className={classes.tableInput}
								style={{ textAlign: 'left' }}
								variant="outlined"
								select
								name="class"
								value={editValues.class}
								onChange={(e) => updateLiabilityClass(e.target.value)}
								error={!!errors.class}
								helperText={errors.class ? errors.class : null}
							>
								{allowedClassValuesSorted.map((classValue, classIndex) => (
									<MenuItem value={classValue} key={`id-${index}-${classIndex + 1}`}>
										{classValue}
									</MenuItem>
								))}
							</TextField>
						</TableCell>
						<TableCell align="center" className={classes.tableCell}>
							<TextField
								className={classes.tableInput}
								name="liability_per_pound"
								type="number"
								required
								value={editValues.liability_per_pound}
								onChange={(e) => updateLiabilityPerPound(e.target.value)}
								error={!!errors.liability_per_pound}
								helperText={errors.liability_per_pound ? errors.liability_per_pound : null}
								label="Liability Per Pound"
								variant="outlined"
							/>
						</TableCell>
						<TableCell align="center" className={classes.tableCell}>
							<TextField
								className={classes.tableInput}
								name="min"
								type="number"
								value={editValues.min}
								onChange={(e) => updateLiabilityMin(e.target.value)}
								error={!!errors.min}
								helperText={errors.min ? errors.min : null}
								label="Minimum Liability"
								variant="outlined"
							/>
						</TableCell>
						<TableCell align="center" className={classes.tableCell}>
							<TextField
								className={classes.tableInput}
								name="max"
								type="number"
								value={editValues.max}
								onChange={(e) => updateLiabilityMax(e.target.value)}
								error={!!errors.max}
								helperText={errors.max ? errors.max : null}
								label="Maximum Liability"
								variant="outlined"
							/>
						</TableCell>
					</>
				)}
				{editMode && (
					<TableCell align="center" className={classes.tableCell}>
						{editing || (
							<Tooltip title="Edit">
								<IconButton aria-label="edit" color="secondary" onClick={() => handleEditClick()}>
									<EditOutlinedIcon fontSize="small" />
								</IconButton>
							</Tooltip>
						)}
						{editing && (
							<Tooltip title="Save">
								<IconButton aria-label="edit" color="secondary" onClick={() => handleSaveClick()}>
									<CheckMarkIcon fontSize="small" />
								</IconButton>
							</Tooltip>
						)}
						<Tooltip title="Delete">
							<IconButton aria-label="delete" onClick={() => handleDeleteClick()}>
								<DeleteOutlineOutlinedIcon color="error" fontSize="small" />
							</IconButton>
						</Tooltip>
					</TableCell>
				)}
			</TableRow>
		</>
	)
}

const liabilityProp = PropTypes.shape({
	id: PropTypes.number,
	integration_carrier_id: PropTypes.number,
	class: PropTypes.string,
	liability_per_pound: PropTypes.number,
	max: PropTypes.number,
	min: PropTypes.number,
	audit_creation_date: PropTypes.string, // '2022-01-24T17:06:10.809Z',
	audit_update_date: PropTypes.string, // '2022-01-24T17:06:10.809Z',
	create_user_id: PropTypes.number,
	update_user_id: PropTypes.number,
})

CarrierLiabilitiesRow.propTypes = {
	editMode: PropTypes.bool.isRequired,
	startEditable: PropTypes.bool,
	index: PropTypes.number.isRequired,
	deleteLiability: PropTypes.func.isRequired,
	setLiability: PropTypes.func.isRequired,
	allLiabilities: PropTypes.arrayOf(liabilityProp).isRequired,
	liability: liabilityProp.isRequired,
}

CarrierLiabilitiesRow.defaultProps = { startEditable: false }

export default CarrierLiabilitiesRow
