import { axiosUserCompany } from '../../utils/apiClient'

export const reqActiveDeactivateCarrier = async (params) => {
	const [error, data] = await axiosUserCompany
		.patch('/carrier/update-status', params)
		.then((response) => [null, response])
		.catch((err) => [err, err])
	return [error, data]
}

export const reqBulkCarrierStatus = async (params) => {
	const [error, data] = await axiosUserCompany
		.patch('/carrier/update-bulk-status', params)
		.then((response) => [null, response.data.data])
		.catch((err) => [err, err.response.data.message])

	return [error, data]
}

export const reqGetConfigCarriers = async (params) => {
	const [error, data] = await axiosUserCompany
		.get(`/config-carrier/list${params}`)
		.then((response) => [null, response])
		.catch((err) => [err, err.response.data.err.message])

	return [error, data]
}

export const reqCreateCarrier = async (params) => {
	const [error, data] = await axiosUserCompany
		.post('/config-carrier/carrier', params)
		.then((response) => [null, response])
		.catch((err) => [err, err.response.data.err ? err.response.data.err.message : err.response.data.message])
	return [error, data]
}

export const reqGetCarrierByCompany = async (params) => {
	const [error, data] = await axiosUserCompany
		.get(`/config-carrier/carrier${params}`)
		.then((response) => [null, response])
		.catch((err) => [err, err])
	return [error, data]
}

export const reqEditCarrier = async (carrierId, params) => {
	const [error, data] = await axiosUserCompany
		.put(`/config-carrier/carrier/${carrierId}`, params)
		.then((response) => [null, response])
		.catch((err) => [err, err.response.data.err ? err.response.data.err.message : err.response.data.message])
	return [error, data]
}

export default reqActiveDeactivateCarrier
