/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { TextField, FormControl, InputAdornment } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import useStyles from './styles'
import TableList from './components/TableList/TableList'

const TableSelectedUser = ({
	users,
	// removeSelected,
	// setRemoveSelected,
	// handleQuitSelected,
}) => {
	const classes = useStyles()
	const [list, setList] = useState([])
	const [page, setPage] = useState(0)
	const [numberForPage, setNumberForPage] = useState(10)
	const handleChangeRowsPerPage = (event) => {
		setNumberForPage(+event.target.value)
		setPage(0)
	}
	const [search, setSearch] = useState('')
	const [allItems, setAllItems] = useState([])
	useEffect(() => {
		const searchFilter = [...users].filter((fil) => {
			const localSearch = search.toLowerCase()
			return ['id', 'name', 'email', 'role', 'user_id', 'profile'].some((key) =>
				String(fil[key]).toLowerCase().includes(localSearch),
			)
		})
		setAllItems(searchFilter)
		const getDataPaginated = searchFilter.slice(page * numberForPage, (page + 1) * numberForPage)
		setList(getDataPaginated)
	}, [users, page, numberForPage, search])
	return (
		<>
			<div className={classes.title}>Added</div>
			<FormControl className={classes.containerSearch}>
				<TextField
					fullWidth
					variant="outlined"
					placeholder="Search..."
					value={search}
					onChange={(e) => setSearch(e.target.value)}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Search color="action" />
							</InputAdornment>
						),
					}}
				/>
			</FormControl>
			{/* <TableToolbar
        removeSelected={removeSelected}
        handleQuitSelected={handleQuitSelected}
      /> */}
			<TableList
				list={list}
				// setRemoveSelected={setRemoveSelected}
				// removeSelected={removeSelected}
				page={page}
				setPage={setPage}
				handleChangeRowsPerPage={handleChangeRowsPerPage}
				numberForPage={numberForPage}
				allItems={allItems}
			/>
		</>
	)
}

TableSelectedUser.propTypes = {
	users: PropTypes.arrayOf(PropTypes.any).isRequired,
	// removeSelected: PropTypes.arrayOf(PropTypes.any).isRequired,
	// setRemoveSelected: PropTypes.func.isRequired,
	// handleQuitSelected: PropTypes.func.isRequired,
}

export default TableSelectedUser
