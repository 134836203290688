import { makeStyles, withStyles, Tooltip } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			borderBottom: 'unset',
		},
	},
	tableCellHead: {
		fontWeight: theme.typography.fontWeightMedium,
	},
	icon: {
		width: '24px',
	},
	newShipmentItems: {
		width: '200px',
		fontWeight: '700',
		fontSize: '14px',
		color: theme.palette.secondary.main,
		display: 'flex',
		alignItems: 'center',
		columnGap: '5px',
	},
	newShipmentItemsUnderlined: {
		textDecoration: 'underline',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		WebkitLineClamp: 1,
		WebkitBoxOrient: 'vertical',
	},
}))

export const CustomizedTooltip = withStyles({
	tooltip: {
		color: 'black',
		backgroundColor: '#EBEEF1',
		fontWeight: 400,
		margin: '2px',
		fontSize: '0.9rem',
	},
})(Tooltip)

export default useStyles
