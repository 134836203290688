import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { Radio, CircularProgress, Tooltip, Grid, useTheme } from '@material-ui/core'
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined'
import { CheckCircle } from '@material-ui/icons'
import SendIcon from '@material-ui/icons/Send'
import GetAppIcon from '@material-ui/icons/GetApp'
import AddRemoveInsurance from '../AddRemoveInsurance'
import SweetAlertModal from '../../../../components/SweetAlertModal'
import { downloadDocumentRate } from '../../../../redux/actions/Rates.actions'
import 'react-multi-carousel/lib/styles.css'
import useStyles from './styles'
import SendByMail from '../../../../components/TableRates/components/SendByMail/SendByMail'
import { serviceTypeHelpText } from '../../../../utils/constants'
import { removeUnderscoreAndUppercase } from '../../../../utils/helpers'

const RateCard = (props) => {
	const {
		rate,
		selectedRateId,
		setSelectedRateId,
		elementId,
		isLowestPrice,
		typeAmount,
		typeAmountValue,
		onNextStepThree,
		quoteCreatedId,
		maxReplacementValue,
		openConfirmInsurance,
		setOpenConfirmInsurance,
		declaredFreightValue,
		setDeclaredFreightValue,
		withInsurance,
		setWithInsurance,
		setInsuranceCost,
		setInsuranceValue,
		defaultDeclaredValue,
	} = props
	const dispatch = useDispatch()
	const theme = useTheme()
	const classes = useStyles()
	const [isSelected, setIsSelected] = useState(false)
	const [openSendEmail, setOpenEmail] = useState(false)
	const [disabled, setDisabled] = useState(false)
	const [loadingDownload, setLoadingDownload] = useState(false)

	useEffect(() => {
		if (selectedRateId === rate.rateId) {
			setIsSelected(true)
		} else {
			setIsSelected(false)
		}
	}, [rate, selectedRateId])

	const downloadRatePdf = async () => {
		setDisabled(true)
		setLoadingDownload(true)
		const result = await dispatch(downloadDocumentRate(rate?.rateId ?? ''))
		setDisabled(false)
		try {
			const {
				data: { data: blob },
			} = result
			const file = new Blob([blob], { type: 'application/pdf' })
			const fileUrl = URL.createObjectURL(file)
			const anchor = document.createElement('a')
			anchor.href = fileUrl
			anchor.download = `FreightPros Quote #${quoteCreatedId ?? ''}.pdf`
			anchor.click()
			URL.revokeObjectURL(fileUrl)
		} catch (error) {
			SweetAlertModal('error', 'An error has occurred', error, 'Ok', true, false)
		}
		setLoadingDownload(false)
	}
	const format = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		maximumFractionDigits: 0,
	})
	const isNumberValid = (number) => {
		// eslint-disable-next-line no-restricted-globals
		if (!isNaN(number)) {
			return true
		}
		return false
	}

	const formatServiceType = (serviceType) => {
		if (serviceType === 'LTL_STANDARD') {
			return 'LTL Standard'
		}
		return removeUnderscoreAndUppercase(serviceType)
	}

	const renderCustomTooltip = () => (
		<>
			<p>{serviceTypeHelpText.standard}</p> <p>{serviceTypeHelpText.volume}</p> <p>{serviceTypeHelpText.guaranteed}</p>
		</>
	)

	const isGuaranteedService = (serviceType) => serviceType.split('_')[0] === 'GUARANTEED'

	return (
		<div className={classes.rateCardContainer}>
			<div>
				<div className={classes.logoContainer}>
					<Radio
						inputProps={{ 'aria-labelledby': elementId }}
						checked={isSelected}
						style={{
							color: isSelected ? theme.palette.secondary.main : theme.palette.primary.light,
							paddingLeft: 0,
						}}
						onClick={() => {
							if (isSelected) {
								setSelectedRateId(0)
							} else {
								setSelectedRateId(rate.rateId)
							}
							setIsSelected(!isSelected)
						}}
					/>
					<div className={classes.logoContainer}>
						<img src={rate.carrierLogo} alt="" />
					</div>
					<div className={classes.logoName}>
						<span>{rate.carrier}</span>
					</div>
				</div>
			</div>
			<hr />
			<div className={classes.itemsContainer}>
				<div>
					<p className={classes.itemTitle}>SERVICE INFORMATION</p>
					<div className={classes.serviceData}>
						<span>{`Estimated ${rate.estimateTransitDays} day transit`}</span>
						<span>{`${moment(rate.estimateDeliveryDays).format('ddd')}, ${moment(rate.estimateDeliveryDays).format('LL')}`}</span>
						<div>
							<Tooltip title={renderCustomTooltip()} className={classes.helpCursor}>
								<span>
									<i>{formatServiceType(rate.serviceType)}</i>
								</span>
							</Tooltip>
							{isGuaranteedService(rate.serviceType) ? (
								<VerifiedUserOutlinedIcon className={classes.verifiedIcon} />
							) : null}
						</div>
					</div>
				</div>
				<div>
					<p className={classes.itemTitle}>MAXIMUM CARRIER LIABILITY</p>
					<div className={classes.liability}>
						<span>
							<strong style={{ fontWeight: 600, fontSize: '16px' }}>
								{`${isNumberValid(rate?.maxCarrierLiability ?? 'NOT') ? format.format(rate.maxCarrierLiability) : ''} New`}
							</strong>
						</span>
						<span>
							<strong style={{ fontWeight: 600, fontSize: '16px' }}>
								{`${isNumberValid(rate?.usedMaxCarrierLiability ?? 'NOT') ? format.format(rate.usedMaxCarrierLiability) : ''} Used`}
							</strong>
						</span>
					</div>
				</div>
				<div>
					<div className={classes.rateContainer}>
						<p className={classes.itemTitle}>CUSTOMER RATE</p>
						{isLowestPrice ? <p className={classes.lowestPrice}>LOWEST PRICE</p> : null}
					</div>
					<div className={classes.priceData}>
						<AddRemoveInsurance
							rateId={rate.rateId}
							customerRate={rate.totalCustomerRate}
							defaultDeclaredValue={defaultDeclaredValue}
							declaredFreightValue={declaredFreightValue}
							setDeclaredFreightValue={setDeclaredFreightValue}
							withInsurance={withInsurance}
							setWithInsurance={setWithInsurance}
							setInsuranceCost={setInsuranceCost}
							setInsuranceValue={setInsuranceValue}
							typeAmount={typeAmount}
							typeAmountValue={typeAmountValue}
							maxReplacementValue={maxReplacementValue}
							selectedRateId={selectedRateId}
							openConfirmInsurance={openConfirmInsurance}
							setOpenConfirmInsurance={setOpenConfirmInsurance}
							onNextStepThree={onNextStepThree}
						/>
					</div>
				</div>
			</div>
			<Grid container justifyContent="center" spacing={2} className={classes.actions}>
				<Grid item container justifyContent="center" xs={12}>
					<Grid item xs={6}>
						<button
							className={classes.convertToShipmentButton}
							onMouseDown={() => setSelectedRateId(rate.rateId)}
							onClick={(event) => {
								event.stopPropagation()
								onNextStepThree()
							}}
							type="button"
							onFocus={(event) => event.stopPropagation()}
						>
							<CheckCircle style={{ color: theme.palette.success.main }} fontSize="small" />
							<span className={classes.labelActionButton}>Convert to Shipment</span>
						</button>
					</Grid>
					<Grid item xs={6}>
						<button
							className={classes.convertToShipmentButton}
							type="button"
							onClick={() => downloadRatePdf()}
							onFocus={(event) => event.stopPropagation()}
							disabled={disabled}
						>
							<GetAppIcon color="secondary" fontSize="small" />
							<span className={classes.labelActions}>Download</span>
							{loadingDownload ? <CircularProgress size={15} /> : null}
						</button>
					</Grid>
				</Grid>
				<Grid item container justifyContent="center" xs={12}>
					<Grid item xs={6}>
						<button
							className={classes.convertToShipmentButton}
							type="button"
							onClick={() => setOpenEmail(true)}
							onFocus={(event) => event.stopPropagation()}
						>
							<SendIcon color="secondary" fontSize="small" />
							<span className={classes.labelActions}>Send by mail</span>
						</button>
					</Grid>
				</Grid>
			</Grid>
			{openSendEmail && (
				<SendByMail open={openSendEmail} handleClose={() => setOpenEmail(false)} idRate={rate?.rateId} />
			)}
		</div>
	)
}
RateCard.defaultProps = {
	onNextStepThree: () => {},
	maxReplacementValue: null,
}

RateCard.propTypes = {
	rate: PropTypes.objectOf(PropTypes.any).isRequired,
	selectedRateId: PropTypes.number.isRequired,
	setSelectedRateId: PropTypes.func.isRequired,
	elementId: PropTypes.string.isRequired,
	isLowestPrice: PropTypes.bool.isRequired,
	typeAmount: PropTypes.string.isRequired,
	typeAmountValue: PropTypes.string.isRequired,
	onNextStepThree: PropTypes.func,
	quoteCreatedId: PropTypes.number.isRequired,
	maxReplacementValue: PropTypes.number,
	openConfirmInsurance: PropTypes.bool.isRequired,
	setOpenConfirmInsurance: PropTypes.func.isRequired,
	defaultDeclaredValue: PropTypes.number.isRequired,
	declaredFreightValue: PropTypes.number.isRequired,
	setDeclaredFreightValue: PropTypes.func.isRequired,
	withInsurance: PropTypes.bool.isRequired,
	setWithInsurance: PropTypes.func.isRequired,
	setInsuranceCost: PropTypes.func.isRequired,
	setInsuranceValue: PropTypes.func.isRequired,
}

export default RateCard
