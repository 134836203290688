import React from 'react'
import OktaNavBar from '../../NavBar/OktaNavBar'
import useStyles from '../PrivacyStatement/styles'

const Terms = () => {
	const classes = useStyles()
	return (
		<>
			<OktaNavBar />
			<div className={classes.navbar}>
				<h1>Nolan Transportation Group Terms of Use</h1>
				<p>
					<b>Effective Date:</b> January 1, 2022
				</p>
				<p>
					<b>
						These Terms of Use (“Terms”) set forth a legally binding agreement between you and Nolan Transportation
						Group, LLC (“NTG”, “we”, “our”, or “us”) and govern your use of our online web application, Transportation
						Management System Lite (the “Services”).
					</b>
				</p>

				<p>
					In some instances, both these Terms and separate terms setting forth additional conditions may apply to a
					service or product offered via the Services (“
					<b>Additional Terms</b>
					”). To the extent there is a conflict between these Terms and any Additional Terms, the Additional Terms will
					control unless the Additional Terms expressly state otherwise.
				</p>

				<p>
					By using the Services, you acknowledge and accept these Terms and any Additional Terms, and consent to the
					collection, use, and sharing of your information and other activities as described in our
					<a href="/privacy">Privacy Policy</a>. If you do not agree to these Terms and any Additional Terms, do not use
					the Services.
				</p>

				<p>
					These Terms may change over time, in which case we will post the modified Terms on this page and change the
					“Effective Date”. Your use of the Services following a change to these Terms signals your acceptance of the
					modification(s).
				</p>

				<h2>Use of the Services</h2>
				<p>
					<b>Content and Ownership.</b> The Services may contain: (i) materials and other items relating to NTG and its
					services including, without limitation, layout, information, databases, text, data, files, images, scripts,
					designs, graphics, instructions, illustrations, photographs, sounds, pictures, URLs, technology, software,
					interactive features, the “look and feel” of the Services; (ii) trademarks, logos, trade names, trade dress,
					service marks, and trade identities of various parties, including those of NTG; and (iii) other forms of
					intellectual property (all of the foregoing, collectively “<b>Content</b>
					”). All rights, title, and interest in and to the Services and the Content is the property of NTG, our
					licensors, or certain other third parties, and is protected by U.S. and international copyright, trademark,
					trade dress, patent, and/or other intellectual property and unfair competition rights and laws to the fullest
					extent possible.
				</p>

				<p>
					<b>Limited License.</b> Subject to your strict compliance with these Terms and any Additional Terms, NTG
					grants you a limited, non-exclusive, revocable, non-assignable, personal, and non-transferable license to
					display, view, and use the Services. The foregoing limited license (i) does not give you any ownership of, or
					any other intellectual property interest in, the Services or any Content, and (ii) may be immediately
					suspended or terminated for any reason, in NTG’S sole discretion, and without advance notice or liability.
				</p>

				<p>
					<b>Restrictions.</b> You agree to comply with all local, state, and federal laws, statutes, ordinances, and
					regulations that apply to your use of the Services and Content. You further agree that you will not: (i)
					engage in any activity in connection with the Services or Content that is unlawful, harmful, offensive,
					obscene, violent, threatening, harassing, abusive, or otherwise objectionable to NTG; (ii) harvest any
					information from the Services or Content; (iii) reverse engineer or modify the Services or Content; (iv)
					interfere with the proper operation of the Services or its security features; (v) infringe any intellectual
					property or other right of any third party; (vi) use the Services or Content in a manner that suggests an
					unauthorized association with NTG or any other party, or is beyond the scope of the limited license granted to
					you; or (vii) otherwise violate these Terms or any Additional Terms.
				</p>
				<p>
					<b>Availability and Termination.</b> NTG may immediately suspend or terminate the availability of the
					Services, in whole or in part, to any individual user or all users, for any reason, in NTG’S sole discretion,
					and without advance notice or liability. Upon suspension or termination of your access to the Services, or
					upon notice from NTG, all rights granted to you under these Terms or any Additional Terms will cease
					immediately, and you agree that you will immediately discontinue use of the Services.
				</p>
				<p>
					<b>Export Controls.</b> Your use of the Services may be subject to the export control laws of the United
					States, including the Export Control Reform Act and its associated regulations. You are responsible for
					complying with all application trade regulations and laws both foreign and domestic.
				</p>
				<p>
					<b>Requests and Notifications.</b> You agree to cooperate with all reasonable requests of NTG and to notify
					NTG promptly upon learning of any actual or suspected unauthorized use or abuse of the Services or Content, or
					if you breach these Terms or any Additional Terms.
				</p>
				<p>
					<b>Reservation of All Rights.</b> All rights not expressly granted to you are reserved by NTG and its
					licensors and other third parties. No right or license may be construed, under any legal theory, by
					implication, estoppel, industry custom, or otherwise. Any unauthorized use of the Services or Content for any
					purpose is prohibited.
				</p>

				<h2>User Content</h2>
				<p>
					Except as provided in our
					<a href="/privacy">Privacy Policy</a>, any content that you submit through the Services or to NTG, whether
					directly or through a third-party website or platform (“
					<b>User Content</b>
					”) will be deemed to be non-confidential and may be disclosed through the Services to other parties to deliver
					the Services. You agree by providing User Content in connection with the Services, you warrant and represent
					that you own the rights to the User Content or are otherwise authorized to transmit the User Content.
				</p>
				<p>
					You will submit only User Content that is functionally and technically compatible with this Services. You will
					not attempt to damage, corrupt, tamper with, or infect the Services, the Content, or any information or
					telecommunication system of NTG with a virus or other malicious computer program. You are prohibited from
					using any services or facilities provided in connection with this Services to compromise security or tamper
					with system resources and/or accounts. The use or distribution of tools designed for compromising security
					(e.g., network probing tools) is strictly prohibited. If you become involved in any violation of system
					security, NTG reserves the right to release your details to system administrators of other sites and services
					and law enforcement authorities in order to assist them in resolving security incidents.
				</p>
				<p>
					You agree that NTG shall have, and hereby grant to NTG, a perpetual, irrevocable, sublicensable, non-exclusive
					right and license to translate, reproduce, sell, publish, distribute, modify, adapt, display, perform,
					promote, link to your User Content.
				</p>

				<h2>Account Registration for Services</h2>
				<p>
					<b>Account Registration.</b> Certain features and functionality of the Services may require the creation of an
					account by entering your email address, selecting a valid username, selecting a valid password, and providing
					other required registration information (collectively, the “<b>Registration Information</b>
					”). You agree that, at the time of submission to us, your Registration Information is true, accurate, current
					and complete. You are solely responsible for updating and maintain your Registration Information. You must
					complete the registration process to create a Services account (“
					<b>Your Account</b>
					”).
				</p>
				<p>
					<b>Restrictions and Limitations.</b> We will have no liability associated with, or arising from, your failure
					to maintain accurate, current, and complete Registration Information, including liability arising out of your
					failure to receive critical information about the Services or Your Account. We will not be responsible for
					verifying your Registration Information. You agree that your Registration Information will not violate the
					rights of any person. We reserve the right, at our discretion, to refuse registration of Your Account; cancel
					or deactivate Your Account, including due to inactivity; and delete all related information and files in, or
					relating to, Your Account.
				</p>
				<p>
					<b>Confidentiality and Security.</b> You are solely responsible and liable for the confidentiality and
					security of Your Account. You will immediately notify us at{' '}
					<a href="mailto:legal@ntgfreight.com">legal@ntgfreight.com</a> of any unauthorized use of your account,
					password, or username, or any other breach of security.
				</p>
				<h2>Communications</h2>
				<p>
					We may communicate with you electronically regarding the Services, including by email. You agree that all
					notices, disclosures, agreements, and other communications that we provide to you electronically satisfy any
					legal requirement that such communications be in writing.
				</p>
				<p>
					You may cancel or modify our email marketing communications you receive from us by following the instructions
					contained within our promotional emails. Please note that we reserve the right to send you certain
					communications relating to your use of our Services, such as administrative and service announcements, and
					these transactional account messages may be unaffected if you choose to opt-out from receiving our marketing
					communications.
				</p>
				<h2>Access to the Services</h2>
				<p>
					The Services may be unavailable from time to time for any reason including, without limitation, routine
					maintenance. You understand and acknowledge that due to circumstances both within and outside of the control
					of NTG, access to the Services may be interrupted, suspended, or terminated from time to time. NTG shall have
					the right at any time to change or discontinue any aspect or feature of the Services.
				</p>
				<h2>Class Action Waiver</h2>
				<p>
					If a dispute arises between you and NTG, you and NTG agree, as permitted by applicable law, to resolve any
					claim or controversy at law or equity arising out of, relating to, or connected in any way with the Services,
					these Terms, or additional terms (collectively, “<b>Dispute</b>
					”). As permitted by applicable law, both you and NTG waive the right to bring any Dispute as a Class,
					consolidated, representative, collective, or private attorney general action regarding any Dispute brought by
					anyone else.
				</p>
				<h2>Warranty Disclaimer</h2>
				<p>
					THE SERVICES, ITS CONTENT, AND ITS LINKS AND COMMUNICATIONS ARE PROVIDED ON AN “AS IS” BASIS AND ARE USED ONLY
					AT YOUR SOLE RISK, TO THE FULLEST EXTENT PERMISSIBLE BY LAW. NTG DISCLAIMS ALL WARRANTIES, REPRESENTATIONS,
					AND CONDITIONS, EXPRESS OR IMPLIED, OF ANY KIND, REGARDING THE SERVICES (INCLUDING ITS CONTENT, HARDWARE,
					SOFTWARE, AND LINKS), INCLUDING ANY IMPLIED WARRANTIES AS TO FITNESS FOR A PARTICULAR PURPOSE,
					MERCHANTABILITY, TITLE, NONINFRINGEMENT, RESULTS, ACCURACY, COMPLETENESS, ACCESSIBILITY, COMPATIBILITY,
					SECURITY, AND FREEDOM FROM COMPUTER VIRUS. IF APPLICABLE LAW DOES NOT ALLOW THE EXCLUSION OF SOME OR ALL OF
					THE ABOVE IMPLIED WARRANTIES TO APPLY TO YOU, THE ABOVE EXCLUSIONS WILL APPLY TO YOU TO THE FULLEST EXTENT
					PERMITTED BY APPLICABLE LAW.
				</p>
				<h2>Limitations on Liability and Remedies</h2>
				<p>
					NTG’S ENTIRE LIABILITY AND YOUR EXCLUSIVE REMEDY WITH RESPECT TO ANY DISPUTE WITH NTG (INCLUDING WITHOUT
					LIMITATION YOUR USE OF THE SERVICES OR RECEIPT OF ANY COMMUNICATIONS) IS TO DISCONTINUE YOUR USE OF THE
					SERVICES. NTG AND ITS VENDORS SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
					CONSEQUENTIAL, OR EXEMPLARY DAMAGE ARISING FROM YOUR USE OF THE SERVICES, RECEIPT OF ANY COMMUNICATION, OR FOR
					ANY OTHER CLAIM RELATED IN ANY WAY TO YOUR USE OF THE SERVICES. THESE EXCLUSIONS FOR DIRECT, INDIRECT,
					SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES INCLUDE, WITHOUT LIMITATION, DAMAGES FOR LOST
					PROFITS, LOST DATA, LOSS OF GOODWILL, WORK STOPPAGE, COMPUTER FAILURE OR MALFUNCTION, OR ANY OTHER COMMERCIAL
					DAMAGES OR LOSSES, EVEN IF NTG HAD BEEN ADVISED OF THE POSSIBILITY THEREOF AND REGARDLESS OF THE LEGAL OR
					EQUITABLE THEORY UPON WHICH THE CLAIM IS BASED. BECAUSE SOME STATES OR JURISDICTIONS DO NOT ALLOW THE
					EXCLUSION OR THE LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH STATES OR
					JURISDICTIONS, NTG’S AND ITS VENDORS’ LIABILITY IN SUCH STATE OR JURISDICTION SHALL BE LIMITED TO THE EXTENT
					PERMITTED BY LAW. IN ANY EVENT, IF ANY OF THE ABOVE PROVISIONS IN THIS SECTION ARE NOT ENFORCEABLE IN AN
					APPLICABLE JURISDICTION, THE MAXIMUM LIABILITY OF NTG WILL BE LIMITED TO $500.
				</p>
				<h2>Indemnification</h2>
				<p>
					You agree to defend, indemnify, and hold harmless NTG from and against any and all claims, liabilities,
					damages, losses, costs, and expenses (including reasonable attorneys’ fees and costs) arising out of or in
					connection with any of the following: (i) your breach or alleged breach of these Terms or any Additional
					Terms; (ii) your use of the Services; (iii) your violation of any laws, rules, regulations, codes, statutes,
					ordinances, or orders of any governmental or quasi-governmental authorities; (iv) your violation of the rights
					of any third party including any intellectual property, publicity, confidentiality, property, or privacy
					right; or (v) any misrepresentation made by you. NTG reserves the right to assume, at your expense, the
					exclusive defense and control of any matter subject to indemnification by you. You agree to cooperate with
					NTG’S defense of any claim. You will not in any event settle any claim without the prior written consent of
					NTG.
				</p>
				<h2>Third-Party Interactions</h2>
				<p>
					We reserve the right to transfer any information we obtain through the Services in the event of a joint
					venture, partnership, merger, or other collaboration with another organization, or in the event we sell or
					transfer all or a portion of our business or assets (e.g., further to a reorganization, liquidation, or any
					other business transaction including negotiations of such transactions).
				</p>
				<h2>General Provisions</h2>
				<p>
					<b>Governing Law and Venue.</b> These Terms will be governed by, and construed and resolved in accordance
					with, United States federal law, as applicable, and by the laws of the State of Georgia. All lawsuits or
					proceedings arising out of, or relating to, these Terms will be brought in the state or federal courts located
					in Fulton County, Georgia, and we and you irrevocably submit to the exclusive personal jurisdiction of such
					courts for such purpose.
				</p>
				<p>
					<b>Severability; Interpretation; Assignment.</b> If any provision of these Terms or any Additional Terms is
					for any reason deemed invalid, unlawful, void, or unenforceable, then that provision will be deemed severable
					from these Terms or any Additional Terms, and the invalidity of the provision will not affect the validity or
					enforceability of the remainder of these Terms or any Additional Terms. The summaries of provisions and
					Section headings are provided for convenience only and shall not limit the full Terms. NTG may assign its
					rights and obligations under these Terms or any Additional Terms, in whole or in part, to any party at any
					time without any notice. These Terms and any Additional Terms may not be assigned by you, and you may not
					delegate your duties under them, without the prior written consent of an officer of NTG.
				</p>
				<p>
					<b>Complete Agreement; No Waiver.</b> These Terms and any Additional Terms reflect our complete agreement
					regarding the Services and supersede any prior agreements, representations, warranties, assurances, or
					discussion related to the Services. Except as expressly set forth in these Terms or any Additional Terms, (i)
					no failure or delay by NTG in exercising any of the rights, powers, or remedies hereunder will operate as a
					waiver of that or any other right, power, or remedy, and (ii) no waiver or modification of any term of these
					Terms or any Additional Terms will be effective unless in writing and signed by NTG.
				</p>
				<p>
					<b>Investigations; Cooperation with Law Enforcement.</b> NTG reserves the right to investigate and prosecute
					any suspected or actual violations of these Terms or any Additional Terms. NTG may disclose any information as
					necessary or appropriate to satisfy any law, regulation, legal process, or government request.
				</p>
				<p>
					<b>International Issues.</b> NTG controls and operates the Services from its offices in the United States. If
					you use the Services from other locations, you are doing so on your own initiative and are responsible for
					compliance with applicable local laws regarding your online conduct and acceptable content if and to the
					extent local laws apply. We reserve the right to limit the availability of the Services and/or the provision
					of any content, program, product, service, or other feature described or available through the Services to any
					person, entity, geographic area, or jurisdiction at any time and in our sole discretion and to limit the
					quantities of any content, program, product, service, or other feature that we provide.
				</p>
				<p>
					<b>Survival.</b> The provisions of these Terms and any applicable additional terms (which by their nature
					should survive the suspension/termination of your access to the Services or the termination of these Terms, as
					well as the indemnitees, releases, disclaimers, and limitations on liability, and the provisions regarding
					jurisdiction, choice of law, jury waiver, and mandatory arbitration.
				</p>
				<h2>Contact Information</h2>
				<p>
					If you have any questions regarding these Terms or the Services, you may contact us by email at &nbsp;
					<a href="mailto:legal@ntgfreight.com">legal@ntgfreight.com</a>.
				</p>
			</div>
		</>
	)
}

export default Terms
