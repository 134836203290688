import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Select, MenuItem, FormControl, Grid, useMediaQuery, InputLabel, useTheme } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { SpecialInstructionsIcon } from '../../../../../assets/Icons/Icons'

import FPModule from './FPModule'
import AdminCustomerSettings from './AdminCustomerSettings'
import ViewQuoteModule from './ViewQuoteModule'
import ViewShipmentModule from './ViewShipmentModule'
import ViewInvoiceModule from './ViewInvoiceModule'
import ViewReportModule from './ViewReportModule'
import sweetAlertModal from '../../../../../components/SweetAlertModal'

import {
	profileOptions,
	defaultCheckboxesValues,
	fPAdminCheckboxes,
	allAccessCheckboxes,
	vendorCheckboxes,
	quotingCheckboxes,
	accountingCheckboxes,
	defineSelectedPermissions,
	profileOptionsNoFP,
} from '../../utils/helpers'

import useStyles from './styles'

const RolesAndPermissions = (props) => {
	const {
		setRolesPermissions,
		resetRolesPermissions,
		setResetRolesPermissions,
		isEdit,
		editUserRolesPermissions,
		setUserRole,
		userRole,
	} = props
	const classes = useStyles()
	const theme = useTheme()
	const matches = useMediaQuery(theme.breakpoints.down('md'))
	const parentAccount = useSelector((state) => state.companies.parents)
	const [fPAdminModule, setFPAdminModule] = useState(false)
	const [adminCustomer, setAdminCustomer] = useState(false)
	const [viewQuoteModule, setViewQuoteModule] = useState(false)
	const [viewShipmentModule, setViewShipmentModule] = useState(false)
	const [viewInvoiceModule, setViewInvoiceModule] = useState(false)
	const [viewReportModule, setViewReportModule] = useState(false)
	const userProfile = useSelector((state) => state.profile.profile)

	const [profile, setProfile] = useState('')
	const [checkboxesValues, setCheckboxesValues] = useState(
		parentAccount.name === 'FreightPros' ? fPAdminCheckboxes : allAccessCheckboxes,
	)

	useEffect(() => {
		if (isEdit) {
			const selectedRolesAndPermissions = defineSelectedPermissions(editUserRolesPermissions)
			setCheckboxesValues(selectedRolesAndPermissions)
		}
	}, [isEdit, editUserRolesPermissions])

	useEffect(() => {
		if (JSON.stringify(checkboxesValues) === JSON.stringify(fPAdminCheckboxes)) {
			setProfile('FREIGHT_PROS_ADMIN')
			setUserRole('FREIGHT_PROS_ADMIN')
			setFPAdminModule(true)
			setAdminCustomer(true)
			setViewQuoteModule(true)
			setViewShipmentModule(true)
			setViewInvoiceModule(true)
			setViewReportModule(true)
		} else if (JSON.stringify(checkboxesValues) === JSON.stringify(allAccessCheckboxes)) {
			setProfile('ALL_ACCESS_CUSTOMER_PROFILE')
			setUserRole('ALL_ACCESS_CUSTOMER_PROFILE')
			setFPAdminModule(false)
			setAdminCustomer(true)
			setViewQuoteModule(true)
			setViewShipmentModule(true)
			setViewInvoiceModule(true)
			setViewReportModule(true)
		} else if (JSON.stringify(checkboxesValues) === JSON.stringify(vendorCheckboxes)) {
			setProfile('VENDOR_PROFILE')
			setUserRole('VENDOR_PROFILE')
			setFPAdminModule(false)
			setAdminCustomer(false)
			setViewQuoteModule(true)
			setViewShipmentModule(true)
			setViewInvoiceModule(false)
			setViewReportModule(false)
		} else if (JSON.stringify(checkboxesValues) === JSON.stringify(quotingCheckboxes)) {
			setProfile('QUOTING_ONLY_PROFILE')
			setUserRole('QUOTING_ONLY_PROFILE')
			setFPAdminModule(false)
			setAdminCustomer(false)
			setViewQuoteModule(true)
			setViewShipmentModule(false)
			setViewInvoiceModule(false)
			setViewReportModule(false)
		} else if (JSON.stringify(checkboxesValues) === JSON.stringify(accountingCheckboxes)) {
			setProfile('ACCOUNTING_USER_PROFILE')
			setUserRole('ACCOUNTING_USER_PROFILE')
			setFPAdminModule(false)
			setAdminCustomer(false)
			setViewQuoteModule(false)
			setViewShipmentModule(false)
			setViewInvoiceModule(true)
			setViewReportModule(true)
		} else if (
			checkboxesValues.createAccounts ||
			checkboxesValues.createAccounts ||
			checkboxesValues.deactivateAccounts ||
			checkboxesValues.createUsers ||
			checkboxesValues.editUsers ||
			checkboxesValues.deactivateUsers ||
			checkboxesValues.createCarriers ||
			checkboxesValues.editCarriers ||
			checkboxesValues.deactivateCarriers ||
			checkboxesValues.ShowCarriersRate
		) {
			setProfile('FREIGHT_PROS_ADMIN')
		} else {
			setProfile('CUSTOM_PROFILE')
			setUserRole('CUSTOM_PROFILE')
		}
		setRolesPermissions(checkboxesValues)
		setResetRolesPermissions(false)
	}, [checkboxesValues, setRolesPermissions, setResetRolesPermissions, setUserRole])

	const handleSelectProfile = (event) => {
		const { value } = event.target
		setProfile(value)
		setCheckboxesValues(defaultCheckboxesValues)
		if (value === 'FREIGHT_PROS_ADMIN') {
			sweetAlertModal(
				'warning',
				'This profile is for NTG employees only. Are you sure you want to assign this profile?',
				null,
				'Ok',
				true,
				false,
				null,
			)
			setFPAdminModule(true)
			setAdminCustomer(false)
			setViewQuoteModule(false)
			setViewShipmentModule(false)
			setViewInvoiceModule(false)
			setViewReportModule(false)
			setCheckboxesValues((prevState) => ({
				...prevState,
				...fPAdminCheckboxes,
			}))
		} else if (value === 'ALL_ACCESS_CUSTOMER_PROFILE') {
			setFPAdminModule(false)
			setAdminCustomer(true)
			setViewQuoteModule(true)
			setViewShipmentModule(true)
			setViewInvoiceModule(true)
			setViewReportModule(true)
			setCheckboxesValues((prevState) => ({
				...prevState,
				...allAccessCheckboxes,
			}))
		} else if (value === 'VENDOR_PROFILE') {
			setFPAdminModule(false)
			setAdminCustomer(false)
			setViewQuoteModule(true)
			setViewShipmentModule(true)
			setViewInvoiceModule(false)
			setViewReportModule(false)
			setCheckboxesValues((prevState) => ({
				...prevState,
				...vendorCheckboxes,
			}))
		} else if (value === 'QUOTING_ONLY_PROFILE') {
			setFPAdminModule(false)
			setAdminCustomer(false)
			setViewQuoteModule(true)
			setViewShipmentModule(false)
			setViewInvoiceModule(false)
			setViewReportModule(false)
			setCheckboxesValues((prevState) => ({
				...prevState,
				...quotingCheckboxes,
			}))
		} else if (value === 'ACCOUNTING_USER_PROFILE') {
			setFPAdminModule(false)
			setAdminCustomer(false)
			setViewQuoteModule(false)
			setViewShipmentModule(false)
			setViewInvoiceModule(true)
			setViewReportModule(true)
			setCheckboxesValues((prevState) => ({
				...prevState,
				...accountingCheckboxes,
			}))
		} else {
			setFPAdminModule(false)
			setAdminCustomer(false)
			setViewQuoteModule(false)
			setViewShipmentModule(false)
			setViewInvoiceModule(false)
			setViewReportModule(false)
			setCheckboxesValues(defaultCheckboxesValues)
		}
	}

	useEffect(() => {
		if (resetRolesPermissions) {
			setCheckboxesValues(
				parentAccount.name === 'FreightPros' && userProfile?.role === 'FREIGHT_PROS_ADMIN'
					? fPAdminCheckboxes
					: allAccessCheckboxes,
			)
			setFPAdminModule(false)
			setAdminCustomer(false)
			setViewQuoteModule(false)
			setViewShipmentModule(false)
			setViewInvoiceModule(false)
			setViewReportModule(false)
		}
	}, [resetRolesPermissions, parentAccount.name, userProfile?.role])

	const onChangeCheckboxValue = (event, checkbox) => {
		const { checked } = event.target
		setCheckboxesValues((prevState) => ({ ...prevState, [checkbox]: checked }))
	}

	return (
		<div className={classes.container}>
			<div className={classes.newUserIconTitle}>
				<div className={classes.newUserIconContainer}>
					<SpecialInstructionsIcon />
				</div>
				<h3 className={classes.newUserSectionTitle}>Roles and Permissions</h3>
			</div>
			<div className={classes.innerContainer}>
				<FormControl variant="outlined" className={classes.formControl}>
					<InputLabel className={classes.profileInput} htmlFor="company-dialog-select">
						Profile *
					</InputLabel>
					<Select
						value={profile}
						onChange={(event) => {
							handleSelectProfile(event)
							setUserRole(event.target.value)
						}}
					>
						{userProfile?.role === 'FREIGHT_PROS_ADMIN' &&
							profileOptions.map((profileItem, index) => (
								<MenuItem key={`id-${index + 1}`} value={profileItem.value}>
									{profileItem.label}
								</MenuItem>
							))}
						{userProfile?.role !== 'FREIGHT_PROS_ADMIN' &&
							profileOptionsNoFP.map((profileItem, index) => (
								<MenuItem key={`id-${index + 1}`} value={profileItem.value}>
									{profileItem.label}
								</MenuItem>
							))}
					</Select>
				</FormControl>
				<Grid container direction={matches ? 'column' : 'row'} justify="space-between" style={{ rowGap: '24px' }}>
					<FPModule
						fPAdminModule={fPAdminModule}
						setFPAdminModule={setFPAdminModule}
						setCheckboxesValues={setCheckboxesValues}
						checkboxesValues={checkboxesValues}
						onChangeCheckboxValue={onChangeCheckboxValue}
						userRole={userRole}
					/>
					<AdminCustomerSettings
						adminCustomer={adminCustomer}
						setAdminCustomer={setAdminCustomer}
						setCheckboxesValues={setCheckboxesValues}
						checkboxesValues={checkboxesValues}
						onChangeCheckboxValue={onChangeCheckboxValue}
					/>
					<ViewQuoteModule
						viewQuoteModule={viewQuoteModule}
						setViewQuoteModule={setViewQuoteModule}
						setCheckboxesValues={setCheckboxesValues}
						checkboxesValues={checkboxesValues}
						onChangeCheckboxValue={onChangeCheckboxValue}
					/>
					<ViewShipmentModule
						viewShipmentModule={viewShipmentModule}
						setViewShipmentModule={setViewShipmentModule}
						setCheckboxesValues={setCheckboxesValues}
						checkboxesValues={checkboxesValues}
						onChangeCheckboxValue={onChangeCheckboxValue}
					/>
				</Grid>
				<Grid container direction={matches ? 'column' : 'row'} style={{ rowGap: '24px' }}>
					<ViewInvoiceModule
						viewInvoiceModule={viewInvoiceModule}
						setViewInvoiceModule={setViewInvoiceModule}
						setCheckboxesValues={setCheckboxesValues}
						checkboxesValues={checkboxesValues}
						onChangeCheckboxValue={onChangeCheckboxValue}
					/>
					<ViewReportModule
						viewReportModule={viewReportModule}
						setViewReportModule={setViewReportModule}
						setCheckboxesValues={setCheckboxesValues}
						checkboxesValues={checkboxesValues}
						onChangeCheckboxValue={onChangeCheckboxValue}
					/>
				</Grid>
			</div>
		</div>
	)
}

RolesAndPermissions.propTypes = {
	setRolesPermissions: PropTypes.func.isRequired,
	resetRolesPermissions: PropTypes.bool.isRequired,
	setResetRolesPermissions: PropTypes.func.isRequired,
	isEdit: PropTypes.bool.isRequired,
	editUserRolesPermissions: PropTypes.arrayOf(PropTypes.any).isRequired,
	setUserRole: PropTypes.func.isRequired,
	userRole: PropTypes.func.isRequired,
}

export default RolesAndPermissions
