import React, { memo, useEffect, useState, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Wrapper } from '@googlemaps/react-wrapper'
import useStyles from './styles'

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_GEOCODE_API_KEY

const iconBlue = '005FB5'

const MapComponent = ({ center, zoom, markers, draggable, origin, destination, heightMap }) => {
	const classes = useStyles({ height: `${heightMap}vh` })

	const directionService = useMemo(() => new window.google.maps.DirectionsService(), [])

	const [map, setMap] = useState()
	const [directionResult, setDirectionResult] = useState()
	const mapRef = useRef()

	useEffect(() => {
		const newMap = new window.google.maps.Map(mapRef.current, {
			zoom,
			center,
			gestureHandling: draggable ? 'cooperative' : 'none',
			zoomControl: draggable,
			streetViewControl: false, // little person icon
			mapTypeControl: false, // satellite vs street
			fullscreenControl: false,
		})
		setMap(newMap)
		markers.forEach((marker) => {
			// eslint-disable-next-line no-new
			new window.google.maps.Marker({
				position: marker.coordinate,
				map: newMap,
				icon: marker.icon,
				anchorPoint: { x: 1, y: 0 },
			})
		})
	}, [center, zoom, markers, draggable])

	useEffect(() => {
		directionService.route(
			{
				origin: origin.coordinate,
				destination: destination.coordinate,
				travelMode: 'DRIVING',
				provideRouteAlternatives: false,
			},
			(result) => {
				setDirectionResult(result)
			},
		)
	}, [origin, destination, directionService])

	useEffect(() => {
		// eslint-disable-next-line no-new
		new window.google.maps.DirectionsRenderer({
			map,
			directions: directionResult,
			suppressBicyclingLayer: true,
			suppressMarkers: true,
			suppressInfoWindows: true,
			polylineOptions: {
				strokeColor: `#${iconBlue}`,
			},
		})
	}, [map, directionResult])

	return (
		<>
			<div ref={mapRef} className={classes.map} />
		</>
	)
}

const GoogleMapLoader = ({ center, zoom, markers, draggable, origin, destination, heightMap }) => (
	<Wrapper apiKey={googleMapsApiKey} libraries={['marker']}>
		<MapComponent
			center={center}
			zoom={zoom}
			markers={markers}
			draggable={draggable}
			origin={origin}
			destination={destination}
			heightMap={heightMap}
		/>
	</Wrapper>
)
GoogleMapLoader.defaultProps = {
	markers: [],
	draggable: true,
}

GoogleMapLoader.propTypes = {
	center: PropTypes.objectOf(PropTypes.any).isRequired,
	zoom: PropTypes.number.isRequired,
	markers: PropTypes.arrayOf(PropTypes.any),
	draggable: PropTypes.bool,
	origin: PropTypes.shape({
		coordinate: PropTypes.shape.isRequired,
	}).isRequired,
	destination: PropTypes.shape({
		coordinate: PropTypes.shape.isRequired,
	}).isRequired,
	heightMap: PropTypes.number.isRequired,
}

MapComponent.defaultProps = GoogleMapLoader.defaultProps
MapComponent.propTypes = GoogleMapLoader.propTypes

const comparisonFn = (prevProps, nextProps) =>
	!(
		prevProps.origin.coordinate.lat !== nextProps.origin.coordinate.lat ||
		prevProps.origin.coordinate.lng !== nextProps.origin.coordinate.lng ||
		prevProps.destination.coordinate.lat !== nextProps.destination.coordinate.lat ||
		prevProps.destination.coordinate.lng !== nextProps.destination.coordinate.lng
	)

export default memo(GoogleMapLoader, comparisonFn)
