import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	listItemIcon: {
		minWidth: 32,
		'& .MuiSvgIcon-root': {
			fontSize: 24,
		},
	},
	iconRed: {
		color: theme.palette.error.main,
	},
	iconGreen: {
		color: theme.palette.secondary.main,
	},
	menuText: {
		fontSize: 13,
	},
}))

export default useStyles
