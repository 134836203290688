import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { makeStyles, Menu, MenuItem, withStyles, ListItemIcon, ListItemText } from '@material-ui/core'

import AutorenewIcon from '@material-ui/icons/Autorenew'

import SessionContext from '../../../../../context/session'
import { DownloadIcon, HelpCenterIcon } from '../../../../../assets/Icons/Icons'
import { setOpen, setSubject } from '../../../../../redux/actions/SupportModal.actions'
import { permissionName } from '../../../../../utils/constants'
import isPermit from '../../../../../utils/permission'

const useStyles = makeStyles(() => ({
	icon: {
		width: '24px',
		color: '#EC3514',
	},
	listItemIcon: {
		minWidth: 0,
		marginRight: 7,
		color: '#2C2C2C',
	},
}))

const StyledMenuItem = withStyles(() => ({
	root: {
		'&:hover': {
			backgroundColor: '#F9D2CA',
		},
	},
}))(MenuItem)

const MenuList = (props) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const { getUserData, getTokenUser, getSession } = useContext(SessionContext)
	const { anchorEl, open, handleClose, shipmentId, bolIdentifier, handleCloneShipment } = props

	const { actualCompany, permissions } = getUserData()
	const token = getTokenUser()

	const handleDownloadBOL = () => {
		const url = `${process.env.REACT_APP_BACKEND_QUOTES_BASE_URL}/shipment/bol/${shipmentId}`

		const oReq = new XMLHttpRequest()
		oReq.open('GET', url, true)
		oReq.responseType = 'arraybuffer'
		oReq.setRequestHeader('X-Company-id', Number(actualCompany))
		oReq.setRequestHeader('Authorization', `Bearer ${token}`)

		oReq.onload = function download() {
			const blob = new Blob([oReq.response], { type: 'application/pdf' })
			const URL = window.URL || window.webkitURL
			const dataUrl = URL.createObjectURL(blob)
			const downloadLink = document.createElement('a')
			const fileName = `BOL-${bolIdentifier}.pdf`

			downloadLink.href = dataUrl
			downloadLink.download = fileName
			downloadLink.click()
		}
		oReq.send()
		handleClose()
	}

	const handleDownloadLabels = () => {
		const url = `${process.env.REACT_APP_BACKEND_QUOTES_BASE_URL}/shipment/labels/${shipmentId}`

		const oReq = new XMLHttpRequest()
		oReq.open('GET', url, true)
		oReq.responseType = 'arraybuffer'
		oReq.setRequestHeader('X-Company-id', Number(actualCompany))
		oReq.setRequestHeader('Authorization', `Bearer ${token}`)

		oReq.onload = function download() {
			const blob = new Blob([oReq.response], { type: 'application/pdf' })
			const URL = window.URL || window.webkitURL
			const dataUrl = URL.createObjectURL(blob)
			const downloadLink = document.createElement('a')
			const fileName = `LABEL-${bolIdentifier}.pdf`

			downloadLink.href = dataUrl
			downloadLink.download = fileName
			downloadLink.click()
		}
		oReq.send()
		handleClose()
	}

	const handleSupport = () => {
		const { name } = getSession()
		dispatch(setSubject(`${bolIdentifier} / ${name} / Support Request`))
		dispatch(setOpen(true))
		handleClose()
	}

	return (
		<Menu id="long-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
			{isPermit(permissions, permissionName.SHIPMENTS_DOWNLOAD_BOL) && (
				<StyledMenuItem dense onClick={() => handleDownloadBOL()}>
					<ListItemIcon className={classes.listItemIcon}>
						<DownloadIcon fontSize="small" className={classes.icon} />
					</ListItemIcon>
					<ListItemText primary="Download BOL" />
				</StyledMenuItem>
			)}
			{isPermit(permissions, permissionName.SHIPMENTS_DOWNLOAD_LABEL) && (
				<StyledMenuItem dense onClick={() => handleDownloadLabels()}>
					<ListItemIcon className={classes.listItemIcon}>
						<DownloadIcon fontSize="small" className={classes.icon} />
					</ListItemIcon>
					<ListItemText primary="Download the label" />
				</StyledMenuItem>
			)}
			{isPermit(permissions, permissionName.SHIPMENTS_CONTACT_SUPPORT) && (
				<StyledMenuItem dense onClick={handleSupport}>
					<ListItemIcon className={classes.listItemIcon}>
						<HelpCenterIcon fontSize="small" className={classes.icon} />
					</ListItemIcon>
					<ListItemText primary="Contact support" />
				</StyledMenuItem>
			)}
			{isPermit(permissions, permissionName.SHIPMENTS_CLONE) && (
				<StyledMenuItem
					dense
					onClick={() => {
						handleClose()
						handleCloneShipment()
					}}
				>
					<ListItemIcon className={classes.listItemIcon}>
						<AutorenewIcon fontSize="small" className={classes.icon} />
					</ListItemIcon>
					<ListItemText primary="Clone the shipment" />
				</StyledMenuItem>
			)}
		</Menu>
	)
}

MenuList.propTypes = {
	anchorEl: PropTypes.objectOf(PropTypes.any),
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	shipmentId: PropTypes.number.isRequired,
	bolIdentifier: PropTypes.string.isRequired,
	handleCloneShipment: PropTypes.func,
}

MenuList.defaultProps = {
	anchorEl: null,
	handleCloneShipment: () => {},
}

export default MenuList
