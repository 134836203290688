/* eslint-disable react/require-default-props */
import React from 'react'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import useStyles from './styles'

const ModuleHeader = ({ title, subtitle }) => {
	const classes = useStyles()

	return (
		<Grid container className={classes.container}>
			<Grid item container direction="column">
				<Grid item className={classes.title}>
					{typeof title === 'string' ? <h1>{title}</h1> : title}
				</Grid>
				<Grid item>
					<p style={{ margin: 0 }}>{subtitle}</p>
				</Grid>
			</Grid>
		</Grid>
	)
}

ModuleHeader.defaultProps = {
	subtitle: '',
}

ModuleHeader.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	title: PropTypes.any.isRequired,
	subtitle: PropTypes.string,
}

export default ModuleHeader
