import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	icon: {
		fontSize: 18,
		color: theme.palette.secondary.main,
	},
	iconDeactivate: {
		fontSize: 18,
		color: theme.palette.error.main,
	},
	iconActivate: {
		fontSize: 18,
		color: theme.palette.semantic.green.primary,
	},
	iconYellow: {
		fontSize: 24,
		color: theme.palette.semantic.yellow.primary,
	},
	listItemIcon: {
		minWidth: 'auto',
		marginRight: 7,
	},
	listItemText: {
		'& span': {
			fontSize: theme.typography.body2.fontSize,
			fontWeight: theme.typography.body2.fontWeight,
		},
	},
}))

export default useStyles
