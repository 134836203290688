import { oktaSettings } from '../utils/constants'

const oktaAuthConfig = {
	// Note: If your app is configured to use the Implicit flow
	// instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
	// you will need to add `pkce: false`
	issuer: `${oktaSettings.domain}/oauth2/default`,
	clientId: `${oktaSettings.client}`,
	redirectUri: `${window.location.origin}/login/callback`,
	postLogoutRedirectUri: `${window.location.origin}${process.env.REACT_APP_OKTA_LOGIN_PATH}`,
	scopes: ['openid', 'profile', 'email', 'offline_access'],
	storageManager: {
		token: {
			storageTypes: ['localStorage', 'sessionStorage', 'cookie'],
			useMultipleCookies: true, // puts each token in its own cookie
		},
		cache: {
			storageTypes: ['localStorage', 'sessionStorage', 'cookie'],
		},
		transaction: {
			storageTypes: ['sessionStorage', 'localStorage', 'cookie'],
		},
	},
	tokenManager: {
		autoRenew: true,
	},
}

const baseOktaConfig = {
	baseUrl: `${oktaSettings.domain}/`,
	clientId: `${oktaSettings.client}`,
	redirectUri: `${window.location.origin}/login/callback`,
	postLogoutRedirectUri: `${window.location.origin}${process.env.REACT_APP_OKTA_LOGIN_PATH}`,
	authParams: {
		issuer: `${oktaSettings.domain}/oauth2/default`,
		scopes: ['openid', 'profile', 'email', 'offline_access'],
	},
	// Additional documentation on config options can be found at https://github.com/okta/okta-signin-widget#basic-config-options
	logoText: 'Beon LTL',
	i18n: {
		en: {
			'primaryauth.title': 'Sign in with your email address',
		},
	},
}

const configWithIdpDiscovery = {
	...baseOktaConfig,
	features: { idpDiscovery: true },
	idpDiscovery: {
		requestContext: `${oktaSettings.requestContext}`,
	},
}

const getOktaConfig = () => (oktaSettings.requestContext !== '' ? configWithIdpDiscovery : baseOktaConfig)

const oktaSignInConfig = getOktaConfig()

export { oktaAuthConfig, oktaSignInConfig }
