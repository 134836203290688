/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import requets from '../requests/Accesorials.request'

const { reqListAccesorial } = requets

const accesorial = createSlice({
	name: 'accesorial',
	initialState: {
		accesorial: [],
	},
	reducers: {
		accesorialSuccess: (state, action) => {
			state.accesorial = action.payload
		},
	},
})

const { actions, reducer } = accesorial

export const { accesorialSuccess } = actions

export const fetchListAccesorials = () => async (dispatch) => {
	const [error, data] = await reqListAccesorial()
	if (!error && data?.data?.data?.accesorials) {
		dispatch(accesorialSuccess(data.data.data.accesorials))
	}
}

export default reducer
