import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	confirmation: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: '1em',
		padding: '10px 0',
	},
	title: {
		fontSize: '1em',
		color: 'gray',
	},
	divider: {
		width: '100%',
		height: '1px',
		background: 'gray',
	},
	text: {
		padding: '0 2em',
		marginTop: '1em',
		marginBottom: '1.5em',
		textAlign: 'center',
	},
	goButton: {
		width: '74%',
		height: '48px',
		alignSelf: 'center',
		textTransform: 'unset',
		fontWeight: '500',
		'&:first-of-type': {
			marginBottom: '1em',
		},
	},
	quoteButton: {
		width: '74%',
		height: '48px',
		alignSelf: 'center',
		textTransform: 'unset',
		fontWeight: '500',
	},
	dialogPaper: {
		height: '300px',
		width: '400px',
		borderBottom: '6px solid',
		borderBottomColor: theme.palette.secondary.main,
	},
}))

export default useStyles
