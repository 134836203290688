import {
	setQuoteConfig,
	setUserConfig,
	setAuditConfig,
	setInvoiceConfig,
	setReportsConfig,
	setUserCompanyConfig,
} from '../../../../utils/setInterceptors'
import reqPrincipalPermission from '../../../../redux/requests/App.request'
import sweetAlertModal from '../../../../components/SweetAlertModal/SweetAlertModal'
import { fetchChildsCompanies, fetchParentCompany } from '../../../../redux/actions/Company.actions'
import { fetchListAccesorials } from '../../../../redux/actions/Accesorial.actions'
import axiosUser from '../../../../utils/apiClient'

const postLoginProcess = async (
	isOkta,
	accessToken,
	oktaAuth,
	createSession,
	saveUserData,
	cleanStorage,
	saveActualCompanyId,
	dispatch,
	updatePostLoginFinished,
	logout,
	getUserData,
) => {
	console.log('called postLoginProcess')
	if (accessToken !== null) {
		const existingUserData = getUserData()
		await setUserConfig(accessToken)
		const getCompanyInfo = async (id, defaultId) => {
			console.log('called: getCompanyInfo')
			const { status } = await dispatch(fetchParentCompany(id))
			// const status = 'success';
			if (status === 'error') {
				sweetAlertModal('error', 'There was an error fetching the companies', null, 'Ok', true, false).then(() => {
					;(async () => {
						saveActualCompanyId(defaultId)
						window.location.reload()
					})()
				})
			}
		}
		const getCompanies = async (id, defaultId) => {
			console.log('called: getCompanies')
			const { status } = await dispatch(fetchChildsCompanies(id))
			// const status = 'success';
			if (status === 'error') {
				sweetAlertModal('error', 'There was an error fetching the children companies', null, 'Ok', true, false).then(
					() => {
						;(async () => {
							saveActualCompanyId(defaultId)
							window.location.reload()
						})()
					},
				)
			}
		}
		const [error, principalData] = await reqPrincipalPermission()
		if (error === 'No valid user') {
			return sweetAlertModal('error', 'Not a valid user', null, 'Ok', true, false).then((isConfirm) => {
				if (isConfirm) {
					cleanStorage()
					if (isOkta) oktaAuth.signOut()
					else logout({ returnTo: process.env.REACT_APP_AUTH0_REDIRECT_URI })
				}
			})
		}
		if (error) {
			return sweetAlertModal('error', 'Error with the server', null, 'Ok', true, false).then((isConfirm) => {
				if (isConfirm) {
					cleanStorage()
					if (isOkta) oktaAuth.signOut()
					else logout({ returnTo: process.env.REACT_APP_AUTH0_REDIRECT_URI })
				}
			})
		}
		const saveAuditData = async (companyId) => {
			const config = {
				headers: {
					'X-Company-id': companyId,
				},
			}
			const [resError, resData] = await axiosUser
				.post('/authentication/private/audit', {}, config)
				.then((response) => [null, response])
				.catch((err) => [err, err])
			return [resError, resData]
		}
		if (
			!!existingUserData &&
			existingUserData.actualCompany &&
			existingUserData.actualCompany !== null &&
			existingUserData.actualCompany !== principalData.principalCompanyId
		) {
			principalData.principalCompanyId = existingUserData.actualCompany
		}
		setUserCompanyConfig({ token: accessToken, companyId: principalData.principalCompanyId })
		setQuoteConfig({ token: accessToken, companyId: principalData.principalCompanyId })
		setInvoiceConfig({ token: accessToken, companyId: principalData.principalCompanyId })
		setReportsConfig({ token: accessToken, companyId: principalData.principalCompanyId })
		setAuditConfig({ token: accessToken, companyId: principalData.principalCompanyId })
		saveUserData(principalData)
		createSession(isOkta ? 'Okta' : 'Auth0')
		await getCompanyInfo(principalData.principalCompanyId)
		await getCompanies(principalData.principalCompanyId)
		dispatch(fetchListAccesorials())
		await saveAuditData(principalData.principalCompanyId)
		updatePostLoginFinished(true)

		return [error, principalData]
	}
	return {}
}
export default postLoginProcess
