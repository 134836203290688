import { axiosInvoices } from '../../utils/apiClient'

const reqGetInvoiceDetails = async (params) => {
	const [error, data] = await axiosInvoices
		.get(`/invoice/${params}`)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export default reqGetInvoiceDetails
