import React from 'react'
import PropTypes from 'prop-types'

import Carousel from 'react-multi-carousel'
import { Grid, IconButton } from '@material-ui/core'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'

import 'react-multi-carousel/lib/styles.css'
import useStyles from './styles'

const ChildAccountsSlider = (props) => {
	const { childAccounts, handleEditClick, handleDeleteClick, isDetail } = props
	const classes = useStyles()

	const responsive = {
		tablet: {
			breakpoint: { max: 1024, min: 500 },
			items: 1,
			paritialVisibilityGutter: 50,
		},
		mobile: {
			breakpoint: { max: 500, min: 0 },
			items: 1,
			paritialVisibilityGutter: 30,
		},
	}

	return (
		<div className={classes.childAccountsSlider}>
			<Carousel
				ssr
				arrows={false}
				responsive={responsive}
				showDots
				draggable
				renderDotsOutside
				dotListClass="shipment-items-slider-dots"
			>
				{childAccounts.map((item, index) => (
					<div key={`id-${index + 1}`} className={classes.childAccountsSliderContainer}>
						<Grid container spacing={1}>
							<Grid item xs={12} sm={12} md={12}>
								<p className={classes.childAccountsSliderTitle} id="childAccountsSliderTitleId">
									<span>{`#${index + 1}`}</span>
								</p>
							</Grid>
							<Grid item xs={12} sm={6} md={6}>
								<p className={classes.childAccountsSliderTitle}>
									<span>Child Name:</span>
									{` ${item.name}`}
								</p>
							</Grid>
							<Grid item xs={12} sm={6} md={6}>
								<p className={classes.childAccountsSliderTitle}>
									<span>Sales Force Number:</span>
									{` ${item.salesForceNumber}`}
								</p>
							</Grid>
							<Grid item xs={12} sm={6} md={6}>
								<p className={classes.childAccountsSliderTitle}>
									<span>TMS Account Number:</span>
									{` ${item.accountNumber}`}
								</p>
							</Grid>
							<Grid item xs={12} sm={6} md={6}>
								<p className={classes.childAccountsSliderTitle}>
									<span>Contact Name:</span>
									{` ${item.contact}`}
								</p>
							</Grid>
							<Grid item xs={12} sm={6} md={6}>
								<p className={classes.childAccountsSliderTitle}>
									<span>Contact Phone:</span>
									{` ${item.phone}`}
								</p>
							</Grid>
							<Grid item xs={12} sm={6} md={6}>
								<p className={classes.childAccountsSliderTitle}>
									<span>Contact Email:</span>
									{` ${item.email}`}
								</p>
							</Grid>
							{!isDetail && (
								<div className={classes.childAccountsSliderActionContainer}>
									<IconButton aria-label="delete" onClick={() => handleDeleteClick(index)}>
										<DeleteOutlineOutlinedIcon fontSize="small" color="error" />
										<p className={classes.childAccountsSliderActionTitle}>Delete</p>
									</IconButton>
									<IconButton aria-label="edit" color="secondary" onClick={() => handleEditClick(index)}>
										<EditOutlinedIcon fontSize="small" />
										<p className={classes.childAccountsSliderActionTitle}>Edit</p>
									</IconButton>
								</div>
							)}
						</Grid>
					</div>
				))}
			</Carousel>
		</div>
	)
}

ChildAccountsSlider.defaultProps = {
	handleEditClick: null,
	handleDeleteClick: null,
	isDetail: false,
}

ChildAccountsSlider.propTypes = {
	childAccounts: PropTypes.arrayOf(PropTypes.any).isRequired,
	handleEditClick: PropTypes.func,
	handleDeleteClick: PropTypes.func,
	isDetail: PropTypes.bool,
}

export default ChildAccountsSlider
