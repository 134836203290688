/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import reqReports from '../requests/Reports.request'

const reportsSlice = createSlice({
	name: 'reportsSlice',
	initialState: {
		carrierUsage: {
			data: [],
			isLoading: false,
			error: false,
		},
		perMonthAndYear: {
			data: [],
			isLoading: false,
			error: false,
		},
		averageWeight: {
			data: [],
			isLoading: false,
			error: false,
		},
		averageDistance: {
			data: [],
			isLoading: false,
			error: false,
		},
		shipments: {
			data: [],
			isLoading: false,
			error: false,
		},
		invoices: {
			data: [],
			isLoading: false,
			error: false,
		},
	},
	reducers: {
		carrierUsageStartLoading: (state) => {
			state.carrierUsage.isLoading = true
		},
		carrierUsageHasError: (state) => {
			state.carrierUsage.isLoading = false
			state.carrierUsage.error = true
		},
		carrierUsageReportSuccess: (state, action) => {
			state.carrierUsage.data = action.payload
			state.carrierUsage.isLoading = false
		},
		perMonthAndYearStartLoading: (state) => {
			state.perMonthAndYear.isLoading = true
		},
		perMonthAndYearHasError: (state) => {
			state.perMonthAndYear.isLoading = false
			state.perMonthAndYear.error = true
		},
		perMonthAndYearReportSuccess: (state, action) => {
			state.perMonthAndYear.data = action.payload
			state.perMonthAndYear.isLoading = false
		},
		perMonthAndYearReset: (state) => {
			state.perMonthAndYear.data = []
		},
		averageWeightStartLoading: (state) => {
			state.averageWeight.isLoading = true
		},
		averageWeightHasError: (state) => {
			state.averageWeight.isLoading = false
			state.averageWeight.error = true
		},
		averageWeightSuccess: (state, action) => {
			state.averageWeight.data = action.payload
			state.averageWeight.isLoading = false
		},
		averageWeightReset: (state) => {
			state.averageWeight.data = []
		},
		averageDistanceStartLoading: (state) => {
			state.averageDistance.isLoading = true
		},
		averageDistanceHasError: (state) => {
			state.averageDistance.isLoading = false
			state.averageDistance.error = true
		},
		averageDistanceSuccess: (state, action) => {
			state.averageDistance.data = action.payload
			state.averageDistance.isLoading = false
		},
		averageDistanceReset: (state) => {
			state.averageDistance.data = []
		},
		shipmentsStartLoading: (state) => {
			state.shipments.isLoading = true
		},
		shipmentsHasError: (state) => {
			state.shipments.isLoading = false
			state.shipments.error = true
		},
		shipmentsReportSuccess: (state, action) => {
			state.shipments.data = action.payload
			state.shipments.isLoading = false
		},
		invoicesStartLoading: (state) => {
			state.invoices.isLoading = true
		},
		invoicesHasError: (state) => {
			state.invoices.isLoading = false
			state.invoices.error = true
		},
		invoicesReportSuccess: (state, action) => {
			state.invoices.data = action.payload
			state.invoices.isLoading = false
		},
	},
})

const { actions, reducer } = reportsSlice

export const {
	carrierUsageStartLoading,
	carrierUsageHasError,
	carrierUsageReportSuccess,
	perMonthAndYearStartLoading,
	perMonthAndYearHasError,
	perMonthAndYearReportSuccess,
	perMonthAndYearReset,
	averageWeightStartLoading,
	averageWeightHasError,
	averageWeightSuccess,
	averageWeightReset,
	averageDistanceStartLoading,
	averageDistanceHasError,
	averageDistanceSuccess,
	averageDistanceReset,
	shipmentsStartLoading,
	shipmentsHasError,
	shipmentsReportSuccess,
	invoicesStartLoading,
	invoicesHasError,
	invoicesReportSuccess,
} = actions

export const fetchCarrierUsageReports = (params) => async (dispatch) => {
	dispatch(carrierUsageStartLoading())
	const [error, data] = await reqReports(`/dashboard/shipment/count/carrier-usage${params}`)
	if (error) {
		dispatch(carrierUsageHasError(error))
		return { status: 'error', resp: error }
	}
	dispatch(carrierUsageReportSuccess(data.data.data.carrierUsage))
	return { status: 'success', resp: data.data.data.carrierUsage }
}

export const fetchPerMonthAndYearRreports = (params) => async (dispatch) => {
	dispatch(perMonthAndYearStartLoading())
	const [error, data] = await reqReports(`/dashboard/shipment/count/per-month-and-year${params}`)
	if (error) {
		dispatch(perMonthAndYearHasError(error))
		return { status: 'error', resp: error }
	}
	dispatch(perMonthAndYearReportSuccess(data.data.data.shipmentsCount))
	return { status: 'success', resp: data.data.data.shipmentsCount }
}

export const fetchAverageWeightReport = (params) => async (dispatch) => {
	dispatch(averageWeightStartLoading())
	const [error, data] = await reqReports(`/dashboard/shipment/count/weight${params}`)
	if (error) {
		dispatch(averageWeightHasError(error))
		return { status: 'error', resp: error }
	}
	dispatch(averageWeightSuccess(data.data.data.averageWeight))
	return { status: 'success', resp: data.data.data.averageWeight }
}

export const fetchAverageDistanceReport = (params) => async (dispatch) => {
	dispatch(averageDistanceStartLoading())
	const [error, data] = await reqReports(`/dashboard/shipment/count/distance${params}`)
	if (error) {
		dispatch(averageDistanceHasError(error))
		return { status: 'error', resp: error }
	}
	dispatch(averageDistanceSuccess(data.data.data.averageDistance))
	return { status: 'success', resp: data.data.data.averageDistance }
}

export const fetchShipmentsReports = () => async (dispatch) => {
	dispatch(shipmentsStartLoading())
	const [error, data] = await reqReports('/dashboard/shipment/cards')
	if (error) {
		dispatch(shipmentsHasError(error))
		return { status: 'error', resp: error }
	}
	dispatch(shipmentsReportSuccess(data.data.data.shipmentCardsData))
	return { status: 'success', resp: data.data.data.shipmentCardsData }
}

export const fetchShipmentsReportsByCompany = () => async (dispatch) => {
	dispatch(shipmentsStartLoading())
	const [error, data] = await reqReports('/dashboard/shipment/cards/by_company')
	if (error) {
		dispatch(shipmentsHasError(error))
		return { status: 'error', resp: error }
	}
	dispatch(shipmentsReportSuccess(data.data.data.shipmentCardsData))
	return { status: 'success', resp: data.data.data.shipmentCardsData }
}

export const fetchInvoiceReports = () => async (dispatch) => {
	dispatch(invoicesStartLoading())
	const [error, data] = await reqReports('/dashboard/invoices/cards')
	if (error) {
		dispatch(invoicesHasError(error))
		return { status: 'error', resp: error }
	}
	dispatch(invoicesReportSuccess(data.data.data.invoiceCardsData))
	return { status: 'success', resp: data.data.data.invoiceCardsData }
}

export default reducer
