import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import TablePagination from '@material-ui/core/TablePagination'
import { CircularProgress } from '@material-ui/core'

import useTemplateItemsFilters from '../../../../../hooks/useTemplateItemsFilters'
import { productItemCells } from '../../../../../utils/headCells'
import { updatePaginator } from '../../../../../redux/actions/TemplateItems.actions'
import { setCurrentPage, setRowsPerPage } from '../../../../../redux/actions/TemplateItemsFilters.actions'
import NoResultsFound from '../../../../../components/NoResultsFound'

import useStyles from './style'
import TemplateItemsRow from '../TemplateItemsRow'

const TableList = (props) => {
	const { pageReset } = props
	const templateItems = useSelector((state) => state.templateItems)
	const templateItemsFilters = useSelector((state) => state.templateItemsFilters)
	const classes = useStyles()
	const dispatch = useDispatch()
	const [page, setPage] = useState(0)
	const [buildParamsFromState] = useTemplateItemsFilters()

	const handleChangePage = (event, newPage) => {
		dispatch(setCurrentPage(newPage * templateItemsFilters.rowsPerPage))
		dispatch(updatePaginator(newPage * templateItemsFilters.rowsPerPage))
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		dispatch(setRowsPerPage(Number(event.target.value)))
		dispatch(setCurrentPage(0))
		setPage(0)
	}

	useEffect(() => {
		if (pageReset) setPage(0)
	}, [pageReset])

	return (
		<div className={classes.root}>
			<Paper className={classes.paper} elevation={0}>
				<TableContainer>
					<Table className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
						<TableHead>
							<TableRow>
								{productItemCells.map((cell, index) => (
									<TableCell key={`id-${index + 1}`} className={classes.tableCell}>
										{cell.label}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{templateItems.isLoading && (
								<TableRow>
									<TableCell align="center" colSpan={Object.keys(productItemCells).length}>
										<CircularProgress />
									</TableCell>
								</TableRow>
							)}
							{templateItems?.templateItems.length === 0 && (
								<TableRow>
									<TableCell align="center" colSpan={Object.keys(productItemCells).length}>
										<NoResultsFound type="Products" filters={buildParamsFromState()} />
									</TableCell>
								</TableRow>
							)}
							{templateItems.isLoading === false &&
								templateItems?.templateItems.length > 0 &&
								templateItems.templateItems.map((row) => <TemplateItemsRow key={row.id} data={row} />)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					classes={{
						root: classes.tablePaginator,
						toolbar: classes.tablePaginatorToolbar,
						actions: classes.tablePaginatorActions,
					}}
					rowsPerPageOptions={[10, 20, 25, 100]}
					component="div"
					count={templateItems.totalRecords}
					rowsPerPage={Number(templateItemsFilters.rowsPerPage)}
					page={page}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
				/>
			</Paper>
		</div>
	)
}

TableList.propTypes = {
	pageReset: PropTypes.string.isRequired,
}

export default TableList
