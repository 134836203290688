import { useSelector } from 'react-redux'

const useQuotesFilters = () => {
	const { currentPage, rowsPerPage, searchBar, startDate, endDate, status, order, orderBy } = useSelector(
		(state) => state.quotesFilters,
	)

	const buildParamsFromState = () => {
		let queryParams = ''

		if (currentPage !== '') {
			queryParams += `/${currentPage}/`
		}

		if (rowsPerPage !== '') {
			queryParams += `${rowsPerPage}?`
		}

		if (searchBar !== '') {
			queryParams += `&search_bar=${searchBar}`
		}

		if (startDate && endDate) {
			queryParams += `&start_date=${startDate}`
			queryParams += `&end_date=${endDate}`
		}

		if (status.length > 0) {
			queryParams += `&status=${status.join(',')}`
		}

		if (orderBy !== '') {
			queryParams += `&order_by=${orderBy}&order=${order}`
		}

		// Save the filters into the localStorage for future loads (When refreshing, changing tabs, etc)
		if (queryParams !== '' && queryParams !== '&') localStorage.setItem('preserved-quote-filters', queryParams)

		return queryParams
	}

	return [buildParamsFromState]
}

export default useQuotesFilters
