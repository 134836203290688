import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	headerTitle: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	subtitleText: {
		margin: '0 0 12px',
		[theme.breakpoints.up('md')]: {
			margin: '0 0 24px',
		},
	},
	titleText: {
		margin: '0 10px 0px 0',
		fontSize: theme.typography.h1.fontSize,
	},
	filterTriggerButton: {
		// @ts-ignore it doesn't like palette.neutral
		color: theme.palette.neutral.black,
		padding: 0,
		[theme.breakpoints.up('lg')]: {
			display: 'none',
		},
	},
	headerActionButton: {
		textTransform: 'initial',
		fontWeight: theme.typography.body2.fontWeight,
		fontSize: theme.typography.body2.fontSize,
	},
	filterButtonText: {
		color: '#000',
		textTransform: 'initial',
		fontWeight: theme.typography.body2.fontWeight,
		fontSize: theme.typography.body2.fontSize,
	},
	filterButtonModal: {
		marginBottom: '20px',
	},
	filterFormWrapper: {
		padding: '5px',
	},
	formGroupSelect: {
		width: '100%',
		marginBottom: '0',
		'& .MuiInputLabel-outlined': {
			backgroundColor: '#ffffff',
		},
	},
	sectionCarrierTitle: {
		// fontWeight: theme.typography.fontWeightRegular,
		color: theme.palette.primary.main,
		margin: 0,
		lineHeight: '28px',
	},
	icon: {
		width: '20px',
		height: '20px',
		borderRadius: '20px',
		padding: '3px',
		alignSelf: 'center',

		'@media only screen and (min-width: 768px)': {
			width: '24px',
			height: '24px',
		},
	},
	iconTitle: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: '16px',
		'& svg': {
			fontSize: '32px',
			marginRight: '10px',
		},
	},
	CarrierDetails: {
		fontSize: '18px',
		fontWeight: 'bold',
	},
	inputFileContainer: {
		position: 'relative',
		top: '-5px',
	},
	carrierImg: {
		width: 'auto',
		height: 'auto',
		maxWidth: '45px',
		maxHeight: '80px',
	},

	childAccountsInformationContainer: {
		margin: '0px 0px 0px -24px',
		padding: '16px 24px 16px 48px',
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: '#F7F8F8',
	},
	childAccountInformationSectionTitle: {
		display: 'flex',
		paddingBottom: '16px',
		columnGap: '12px',
		marginBottom: '5px',
		alignItems: 'center',
	},
	accountBasicInformationSectionTitle: {
		...theme.typography.subtitle1,
		color: theme.palette.primary.main,
		margin: 0,
		[theme.breakpoints.down('md')]: {
			fontSize: '15px',
		},
	},
}))

export default useStyles
