import React from 'react'
import PropTypes from 'prop-types'

import Carousel from 'react-multi-carousel'

import Tooltip from '@material-ui/core/Tooltip'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import { HazIcon, StackIcon } from '../../../../assets/Icons/Icons'

import { displayPackageType, displayDetails } from '../../utils/helpers'

import 'react-multi-carousel/lib/styles.css'
import './ShipmentItemsSliderNew.scss'

const ShipmentItemsSliderNew = (props) => {
	const { items, setEditItemData } = props
	const responsive = {
		tablet: {
			breakpoint: { max: 1024, min: 500 },
			items: 1,
			partialVisibilityGutter: 50,
			slidesToSlide: 1,
		},
		mobile: {
			breakpoint: { max: 500, min: 0 },
			items: 1,
			partialVisibilityGutter: 50,
			slidesToSlide: 1,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 1,
			partialVisibilityGutter: 50,
			slidesToSlide: 1,
		},
	}

	return (
		<div className="shipment-items-slider">
			<Carousel
				ssr
				arrows={false}
				responsive={responsive}
				showDots
				draggable
				renderDotsOutside
				dotListClass="shipment-items-slider-dots"
			>
				{items.map((item, index) => (
					<div key={`id-${index + 1}`} className="shipment-items-slider-container">
						<div>
							<p className="shipment-items-slider-title" id="shipment-items-slider-title-id">
								<span>{`#${String.fromCharCode(65 + index)}`}</span>
							</p>
							<p className="shipment-items-slider-title">
								<span>Item Information:</span>
								{` ${item.pieces_value} ${displayPackageType(item.pieces_value, item.pieces_unit)}, ${item.weight_value} lbs, ${item.dimensions_lenght} x ${item.dimensions_width} x ${item.dimensions_height} in, Freight Class ${item.class}`}
							</p>
							<p className="shipment-items-slider-title">
								<span>NMFC code:</span>
								{item.nmfc ? ` ${item.nmfc}` : '-'}
								{item.sub_nmfc ? `- ${item.sub_nmfc}` : ''}
							</p>
							<p className="shipment-items-slider-title">
								<span>Product Description:</span>
								{item.item_details && item.item_details.length > 0 && displayDetails(item.item_details)}
							</p>
							<div className="stepTwo-shipment-items-icons-container" id="shipment-items-slider-icons">
								{item.is_haz_mat && (
									<Tooltip title="Hazmat such as chemicals, acid, waste with a UN number">
										<span>
											<HazIcon color="secondary" />
										</span>
									</Tooltip>
								)}
								{item.stackable && (
									<Tooltip title="Items that can be stacked to reduce linear space">
										<span>
											<StackIcon color="secondary" />
										</span>
									</Tooltip>
								)}
							</div>
							<div
								className="stepTwo-shipment-items-icons-container"
								id="shipment-items-slider-edit-icons"
								role="presentation"
								onClick={() => setEditItemData(item)}
							>
								<EditOutlinedIcon color="secondary" style={{ width: '0.8 rem', height: '0.8 rem' }} />
								<span className="shipment-items-subtitle">Edit</span>
							</div>
						</div>
					</div>
				))}
			</Carousel>
		</div>
	)
}

ShipmentItemsSliderNew.propTypes = {
	items: PropTypes.arrayOf(PropTypes.any).isRequired,
	setEditItemData: PropTypes.func.isRequired,
}

export default ShipmentItemsSliderNew
