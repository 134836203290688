import axiosUser from '../../utils/apiClient'

const reqCompanyUserInfo = async (id) => {
	const [error, data] = await axiosUser
		.get(`/company/${id}`)
		.then((company) => [null, company.data.data])
		.catch((err) => {
			if (err.message?.data?.err?.message) {
				return [err.response.data.err.message, err.response.data.err.message]
			}
			return [err, err]
		})

	return [error, data]
}

export const reqCompanyChildren = async (id) => {
	const [error, data] = await axiosUser
		.get(`/company/children/${id}`)
		.then((companys) => [null, companys.data.data])
		.catch((err) => {
			if (err.message?.data?.err?.message) {
				return [err.response.data.err.message, err.response.data.err.message]
			}
			return [err, err]
		})

	return [error, data]
}

export default reqCompanyUserInfo
