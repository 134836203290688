import React from 'react'
import PropTypes from 'prop-types'

import { FormControlLabel, Checkbox } from '@material-ui/core'
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined'

import { setReportCheckboxesValues } from '../../../utils/helpers'

import useStyles from '../styles'

const ViewReportModule = (props) => {
	const { viewReportModule, setViewReportModule, setCheckboxesValues, checkboxesValues, onChangeCheckboxValue } = props
	const classes = useStyles()

	return (
		<div className={classes.checkBoxColumn}>
			<FormControlLabel
				className={classes.checkBoxLabel}
				control={
					<Checkbox
						indeterminate={viewReportModule}
						indeterminateIcon={<IndeterminateCheckBoxOutlinedIcon />}
						color="secondary"
						className={classes.checkBox}
						checked={viewReportModule}
						onChange={(event) => {
							const { checked } = event.target
							setViewReportModule(checked)
							setCheckboxesValues((prevState) => ({
								...prevState,
								...setReportCheckboxesValues({ checked }),
							}))
						}}
					/>
				}
				label="View Report Module"
			/>
			<div className={classes.checkBoxesColumn}>
				<FormControlLabel
					className={classes.checkBoxLabel}
					control={
						<Checkbox
							color="secondary"
							className={classes.checkBox}
							checked={checkboxesValues.viewReportDetails}
							onChange={(event) => onChangeCheckboxValue(event, 'viewReportDetails')}
						/>
					}
					label="View reports details"
				/>
				<FormControlLabel
					className={classes.checkBoxLabel}
					control={
						<Checkbox
							color="secondary"
							className={classes.checkBox}
							checked={checkboxesValues.createReport}
							onChange={(event) => onChangeCheckboxValue(event, 'createReport')}
						/>
					}
					label="Create new reports"
				/>
				<FormControlLabel
					className={classes.checkBoxLabel}
					control={
						<Checkbox
							color="secondary"
							className={classes.checkBox}
							checked={checkboxesValues.sendReportByEmail}
							onChange={(event) => onChangeCheckboxValue(event, 'sendReportByEmail')}
						/>
					}
					label="Send by email"
				/>
			</div>
		</div>
	)
}

ViewReportModule.propTypes = {
	viewReportModule: PropTypes.bool.isRequired,
	setViewReportModule: PropTypes.func.isRequired,
	setCheckboxesValues: PropTypes.func.isRequired,
	checkboxesValues: PropTypes.objectOf(PropTypes.any).isRequired,
	onChangeCheckboxValue: PropTypes.func.isRequired,
}

export default ViewReportModule
