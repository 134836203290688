import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	shipmentReferencesSlider: {
		width: '100%',
		marginBottom: '1em',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	shipmentReferencesSliderContainer: {
		maxwidth: '400px',
		border: '1px solid #c9cfe4',
		borderRadius: '4px',
		padding: '15px',
		backgroundColor: '#fafbfc',
	},
	shipmentReferencesSliderTitle: {
		margin: '0 0 8px 0',
		fontWeight: '500',
		fontSize: '14px',
	},
	shipmentReferencesSliderTitleId: {
		margin: '0 0 16px 0',
	},
	shipmentReferencesSliderDelete: {
		display: 'flex',
		justifyContent: 'flexend',
	},
	shipmentReferencesSliderDeleteTitle: {
		fontWeight: '500',
		fontSize: '12px',
		color: '#A1A1A1',
		margin: '5px 0 0 4px',
	},
	icon: {
		width: '24px',
		height: '24px',
		color: '#CCD2D8',
	},
	newAccountTitleTable: {
		color: '#EC3514',
		fontSize: '15px',
		fontWeight: 600,
		marginBottom: '4%',
	},
}))

export default useStyles
