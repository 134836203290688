import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Box, Grid } from '@material-ui/core'
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import useStyles from './styles'

const FileDropZone = ({ setHasValidFile, setFile }) => {
	const classes = useStyles()
	const drop = useRef(null)
	const input = useRef(null)
	const [defaultLabel, setDefaultLabel] = useState(true)
	const [maxNumOfFiles, setMaxNumOfFiles] = useState(false)
	const [invalidType, setInvalidType] = useState(false)
	const [validFile, setValidFile] = useState([])
	const [error, setError] = useState(false)

	const handleDragOver = (e) => {
		const validTypes = [
			'application/vnd.ms-excel',
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			'.csv',
		]
		e.preventDefault()
		e.stopPropagation()
		const { items } = e.dataTransfer
		const allFiles = Array.from(items).map((file) => file)
		if (allFiles.length > 1) {
			setError(true)
			setMaxNumOfFiles(true)
			setInvalidType(false)
			setDefaultLabel(false)
			setValidFile([])
		} else if (allFiles[0].kind !== 'file' || !validTypes.some((type) => allFiles[0].type === type)) {
			setError(true)
			setInvalidType(true)
			setMaxNumOfFiles(false)
			setDefaultLabel(false)
			setValidFile([])
		} else {
			setError(false)
		}
	}

	const handleDrop = useCallback(
		(e) => {
			e.preventDefault()
			e.stopPropagation()
			const { files } = e.dataTransfer
			const allFiles = Array.from(files).map((file) => file)
			if (!error) {
				setValidFile(allFiles)
				setDefaultLabel(false)
				setHasValidFile(true)
				setFile(allFiles[0])
			} else {
				setError(false)
				setInvalidType(false)
				setMaxNumOfFiles(false)
				setDefaultLabel(true)
				setValidFile([])
				setFile({})
				setHasValidFile(false)
			}
		},
		[error, setFile, setHasValidFile],
	)

	const handleDragOut = (e) => {
		e.preventDefault()
		e.stopPropagation()
		setError(false)
		setDefaultLabel(true)
		setMaxNumOfFiles(false)
		setInvalidType(false)
		setValidFile([])
	}

	const triggerInputFile = () => {
		input.current.click()
	}

	const handleSelectFile = (e) => {
		const { files } = e.target
		const allFiles = Array.from(files).map((file) => file)
		setValidFile([])
		if (allFiles.length > 1) {
			e.preventDefault()
			setMaxNumOfFiles(true)
			setDefaultLabel(false)
		} else {
			setDefaultLabel(false)
			setValidFile(allFiles)
			setFile(allFiles[0])
			setHasValidFile(true)
		}
	}

	useEffect(() => {
		const dropRef = drop.current
		dropRef.addEventListener('dragover', handleDragOver)
		dropRef.addEventListener('drop', handleDrop)
		dropRef.addEventListener('dragleave', handleDragOut)

		return () => {
			dropRef.removeEventListener('dragover', handleDragOver)
			dropRef.removeEventListener('drop', handleDrop)
			dropRef.removeEventListener('dragleave', handleDragOut)
		}
	}, [handleDrop])

	return (
		<Box
			padding={2}
			className={clsx(classes.container, {
				hasError: error,
				isValid: Object.keys(validFile).length > 0,
			})}
			ref={drop}
			onClick={triggerInputFile}
		>
			<Grid className={classes.grid} container spacing={1} alignItems="center" justify="center">
				{defaultLabel && (
					<>
						<Grid item>
							<InsertDriveFileOutlinedIcon color="secondary" fontSize="small" />
						</Grid>
						<Grid item>Drag & Drop your file here</Grid>
					</>
				)}
				{maxNumOfFiles && (
					<>
						<Grid item>
							<NotInterestedIcon color="primary" fontSize="small" />
						</Grid>
						<Grid item>Only one file allowed</Grid>
					</>
				)}
				{invalidType && (
					<>
						<Grid item>
							<NotInterestedIcon color="primary" fontSize="small" />
						</Grid>
						<Grid item>Not a valid type of file</Grid>
					</>
				)}
				{validFile.length > 0 && (
					<>
						<Grid item>
							<InsertDriveFileOutlinedIcon className={classes.success} fontSize="small" />
						</Grid>
						<Grid item>{validFile[0].name}</Grid>
					</>
				)}
			</Grid>
			<input
				className={classes.hiddenInput}
				ref={input}
				type="file"
				accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
				onChange={handleSelectFile}
			/>
		</Box>
	)
}

FileDropZone.propTypes = {
	setHasValidFile: PropTypes.func.isRequired,
	setFile: PropTypes.func.isRequired,
}

export default FileDropZone
