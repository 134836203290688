import axios from 'axios'
import axiosUser, { axiosUserCompany } from '../../utils/apiClient'
import { auth0Settings } from '../../utils/constants'

const reqCreateUser = async (params, config) => {
	const [error, data] = await axiosUser
		.post('/user', params, config)
		.then((response) => [null, response])
		.catch((err) => [err, err.response.data.err ? err.response.data.err.message : err.response.data.message])

	return [error, data]
}

export const reqGetUsersByCompany = async (params, config) => {
	const [error, data] = await axiosUser
		.get(`/user/list${params}`, config)
		.then((response) => [null, response])
		.catch((err) => [err, err])
	return [error, data]
}

export const reqGetUsers = async (params) => {
	const [error, data] = await axiosUserCompany
		.get(`/user/list${params}`)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export const reqGetUserById = async (userId) => {
	const [error, data] = await axiosUser
		.get(`/user/${userId}`)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export const reqEditUser = async (userId, params, config) => {
	const [error, data] = await axiosUser
		.put(`/user/${userId}`, params, config)
		.then((response) => [null, response])
		.catch((err) => [err, err.response.data.err ? err.response.data.err.message : err.response.data.message])
	return [error, data]
}

export const reqGetCarriers = async (config) => {
	const [error, data] = await axiosUser
		.get('/carrier', config)
		.then((response) => [null, response])
		.catch((err) => [err, err.response.data.err.message])

	return [error, data]
}

export const reqGetAccountCarriers = async (config, params) => {
	const [error, data] = await axiosUser
		.get(`/carrier/list${params}`, config)
		.then((response) => [null, response])
		.catch((err) => [err, err.response.data.err ? err.response.data.err.message : err.response.data.message])

	return [error, data]
}

export const reqSaveAccountCarriersConfiguration = async (body) => {
	const [error, data] = await axiosUserCompany
		.post('/config-carrier', body)
		.then((response) => [null, response])
		.catch((err) => [err, err.response.data.err.message])

	return [error, data]
}

export const reqUpdateAccountCarriersConfiguration = async (body, id) => {
	const [error, data] = await axiosUserCompany
		.put(`/config-carrier/${id}/company`, body)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export const reqDeactiveUser = async (userId) => {
	const [error, data] = await axiosUserCompany
		.delete(`/user/${userId}`)
		.then((response) => [null, response])
		.catch((err) => [err, err])
	return [error, data]
}

export const reqActiveUser = async (userId) => {
	const [error, data] = await axiosUserCompany
		.put(`/user/${userId}/unblock`)
		.then((response) => [null, response])
		.catch((err) => [err, err])
	return [error, data]
}

export const reqBulkUserStatus = async (params) => {
	const [error, data] = await axiosUserCompany
		.patch('/user/bulk/status', params)
		.then((response) => [null, response.data.data])
		.catch((err) => [err, err.response.data.message])

	return [error, data]
}

export const reqResetPassword = async (params) => {
	const [error, data] = await axios
		.post(`https://${auth0Settings.domain}/dbconnections/change_password`, params)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export const reqSendEmailPassword = async (id, flag) => {
	const [error, data] = await axiosUserCompany
		.patch(`/user/${id}/status-email?status=${flag}`)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export const reqAudit = async (config) => {
	const [error, data] = await axiosUser
		.post('/authentication/private/audit', null, config)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export default reqCreateUser
