import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { TableCell, TableRow, IconButton, Checkbox, Tooltip, useTheme } from '@material-ui/core'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import DoneAllIcon from '@material-ui/icons/DoneAll'

import { useDispatch } from 'react-redux'

import { addNotification } from '../../../../../redux/actions/Shipment.actions'

import { formatPhoneNumber } from '../../../utils/helpers'

import sweetAlertModal from '../../../../../components/SweetAlertModal/SweetAlertModal'

import useStyles from '../styles'

const ContactInfoRow = (props) => {
	const { contactInformation, index, setContactData, allContactData, deleteShipmentNotification, shipmentId } = props
	const theme = useTheme()
	const classes = useStyles()
	const dispatch = useDispatch()

	const [notificationSendBol, setNotificationSendBol] = useState(contactInformation.notificationSendBol)
	const [notificationByEmail, setNotificationByEmail] = useState(contactInformation.notificationByEmail)
	const [notificationBySms, setNotificationBySms] = useState(contactInformation.notificationBySms)
	const [notificationBooked, setNotificationBooked] = useState(contactInformation.notificationBooked)
	const [notificationInTransit, setNotificationInTransit] = useState(contactInformation.notificationInTransit)
	const [notificationDelivered, setNotificationDelivered] = useState(contactInformation.notificationDelivered)
	const [isNotificationAdded, setIsNotificationAdded] = useState(
		contactInformation.isAdded ? contactInformation.isAdded : false,
	)

	const setNewData = (data) => {
		setNotificationSendBol(data.notificationSendBol)
		setNotificationByEmail(data.notificationByEmail)
		setNotificationBySms(data.notificationBySms)
		setNotificationBooked(data.notificationBooked)
		setNotificationInTransit(data.notificationInTransit)
		setNotificationDelivered(data.notificationDelivered)
	}

	useEffect(() => {
		const isAdded = contactInformation.isAdded ?? false
		setNewData(contactInformation)
		setIsNotificationAdded(isAdded)
	}, [contactInformation])

	const addShipmentNotification = async () => {
		const newCellphone =
			contactInformation?.phone?.replace('(', '').replace(')', '').replace('-', '').replace(' ', '') ?? ''
		const params = {
			contact_name: contactInformation.name,
			email_address: contactInformation.email,
			contact_phone: newCellphone ? `+1${newCellphone}` : '',
			send_bol_email: notificationSendBol,
			notification_method_email: notificationByEmail,
			notification_method_sms: notificationBySms,
			tracking_notification_booked: notificationBooked,
			tracking_notification_in_transit: notificationInTransit,
			tracking_notification_delivered: notificationDelivered,
		}

		const { status, resp } = await dispatch(addNotification(shipmentId, params))
		if (status === 'error') {
			sweetAlertModal(status, 'There was a problem adding the notification information!', resp, 'Ok', true, false, null)
		} else {
			setIsNotificationAdded(true)

			const newContact = {
				name: contactInformation.name,
				email: contactInformation.email,
				phone: contactInformation?.phone ?? '',
				notificationSendBol,
				notificationByEmail,
				notificationBySms,
				notificationBooked,
				notificationInTransit,
				notificationDelivered,
				isAdded: true,
				notificationId: resp.data.data.contactNotification.id,
			}
			const newContactDataList = [...allContactData]
			newContactDataList.splice(index, 1, newContact)
			setContactData(newContactDataList)
		}
	}

	return (
		<TableRow>
			<TableCell align="center" className={classes.tableCell}>
				{contactInformation.name}
			</TableCell>
			<TableCell align="center" className={classes.tableCell}>
				{contactInformation.email ? contactInformation.email : '-'}
			</TableCell>
			<TableCell align="center" className={`${classes.tableCell} ${classes.tableVerticalLineRight}`}>
				{contactInformation.phone ? formatPhoneNumber(contactInformation.phone) : '-'}
			</TableCell>
			<TableCell align="center">
				<Checkbox
					color="secondary"
					className={classes.checkBox}
					disabled={isNotificationAdded}
					checked={notificationSendBol}
					onChange={(event) => {
						setNotificationSendBol(event.target.checked)
					}}
				/>
			</TableCell>
			<TableCell align="center">
				<Checkbox
					color="secondary"
					className={classes.checkBox}
					disabled={isNotificationAdded}
					checked={notificationByEmail}
					onChange={(event) => {
						setNotificationByEmail(event.target.checked)
					}}
				/>
			</TableCell>
			<TableCell align="center" className={classes.tableVerticalLineRight}>
				<Checkbox
					color="secondary"
					className={classes.checkBox}
					disabled={isNotificationAdded}
					checked={notificationBySms}
					onChange={(event) => {
						setNotificationBySms(event.target.checked)
					}}
				/>
			</TableCell>
			<TableCell align="center">
				<Checkbox
					color="secondary"
					className={classes.checkBox}
					disabled={isNotificationAdded}
					checked={notificationBooked}
					onChange={(event) => {
						setNotificationBooked(event.target.checked)
					}}
				/>
			</TableCell>
			<TableCell align="center">
				<Checkbox
					color="secondary"
					className={classes.checkBox}
					disabled={isNotificationAdded}
					checked={notificationInTransit}
					onChange={(event) => {
						setNotificationInTransit(event.target.checked)
					}}
				/>
			</TableCell>
			<TableCell align="center" className={classes.tableVerticalLineRight}>
				<Checkbox
					color="secondary"
					className={classes.checkBox}
					disabled={isNotificationAdded}
					checked={notificationDelivered}
					onChange={(event) => {
						setNotificationDelivered(event.target.checked)
					}}
				/>
			</TableCell>
			<TableCell align="center">
				{isNotificationAdded ? (
					<Tooltip title="Delete">
						<IconButton
							aria-label="toggle password visibility"
							onMouseDown={null}
							edge="end"
							onClick={() => deleteShipmentNotification(index)}
						>
							<DeleteOutlineOutlinedIcon color="error" className={classes.icon} />
						</IconButton>
					</Tooltip>
				) : (
					<Tooltip title="Confirm">
						<IconButton
							aria-label="toggle password visibility"
							onMouseDown={null}
							edge="end"
							onClick={() => addShipmentNotification()}
						>
							<DoneAllIcon htmlColor={theme.palette.success.main} className={classes.icon} />
						</IconButton>
					</Tooltip>
				)}
			</TableCell>
		</TableRow>
	)
}

ContactInfoRow.propTypes = {
	index: PropTypes.number.isRequired,
	contactInformation: PropTypes.objectOf(PropTypes.any).isRequired,
	shipmentId: PropTypes.number.isRequired,
	deleteShipmentNotification: PropTypes.func.isRequired,
	setContactData: PropTypes.func.isRequired,
	allContactData: PropTypes.arrayOf(PropTypes.any).isRequired,
}

export default ContactInfoRow
