import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	tmstopnav: {
		backgroundColor: theme.palette.neutral.black,
		color: 'white',
		display: 'flex',
		flexDirection: 'row',
		zIndex: '1000',
		position: 'fixed',
		height: '3rem',
		top: '0',
		justifyContent: 'center',
		alignItems: 'center',
		alignContent: 'center',
		boxShadow: 'none',
	},
	tmstopnavbarlogo: {
		paddingLeft: '0',
		paddingRight: '0',
		color: 'white',
		textDecoration: 'none',
	},
	tmstopnavbar: {
		display: 'flex',
	},
	tmstopnavbarspacer: {
		flex: '1 1 auto',
	},
	linkStyle: {
		fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
		color: 'white',
		cursor: 'pointer',
		margin: '12px 15px 10px -20px',
		padding: '6px 20px 6px 20px',
		lineHeight: '36px',
		borderRadius: '4px',
		'&:hover': {
			textDecoration: 'none',
			backgroundColor: '#212121',
		},
	},
	typography: {
		fontSize: ['0.75rem', '!important'],
		fontWeight: '400',
	},
	sidebarLogo: {
		display: 'block',
		height: '15.5px',
		marginLeft: '-4px',
		cursor: 'pointer',
		transition: theme.transitions.create('height', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		objectFit: 'contain',
	},
}))

export default useStyles
