/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initState = {
	currentPage: 0,
	rowsPerPage: 10,
	status: [],
	searchBar: '',
	filterByStatus: '',
	orderBy: '',
	order: '',
}

const usersFilters = createSlice({
	name: 'usersFilters',
	initialState: {
		...initState,
	},
	reducers: {
		setCurrentPage: (state, action) => {
			state.currentPage = action.payload
		},
		setRowsPerPage: (state, action) => {
			state.rowsPerPage = action.payload
		},
		setStatus: (state, action) => {
			state.status = action.payload
		},
		setSearchBar: (state, action) => {
			state.searchBar = action.payload
		},
		setFilterByStatus: (state, action) => {
			state.filterByStatus = action.payload
		},
		setOrderByAndOrder: (state, action) => {
			const { orderBy, order } = action.payload
			state.orderBy = orderBy
			state.order = order
		},
		resetFilters: (state) => {
			state.currentPage = initState.currentPage
			state.rowsPerPage = initState.rowsPerPage
			state.status = initState.status
			state.searchBar = initState.searchBar
			state.filterByStatus = initState.filterByStatus
			state.orderBy = initState.orderBy
			state.order = initState.order
		},
	},
})

export const {
	setCurrentPage,
	setRowsPerPage,
	setStatus,
	setSearchBar,
	setFilterByStatus,
	setOrderByAndOrder,
	resetFilters,
} = usersFilters.actions

const { reducer } = usersFilters
export default reducer
