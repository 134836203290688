import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	mainTitleSection: {
		backgroundColor: theme.palette.neutral.whiteGray,
		padding: theme.spacing(2, 3),
		'& h1': {
			margin: 0,
		},
	},
	shipmentTitleRow: {
		display: 'flex',
		alignItems: 'center',
	},
	chip: {
		fontSize: 11,
		fontWeight: theme.typography.fontWeightRegular,
		color: theme.palette.success.main,
		padding: '0 14px',
		marginLeft: 8,
		height: 19,
		backgroundColor: theme.palette.success.light,
	},
}))

export default useStyles
