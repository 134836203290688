import React from 'react'

import LocationsContainer from '../containers/LocationsContainer'

const MyLocations = () => (
	<div>
		<LocationsContainer />
	</div>
)

export default MyLocations
