import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Grid, IconButton, useTheme } from '@material-ui/core'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'

import { capitalizeFirstLetter } from '../../../../../utils/helpers'
import 'react-multi-carousel/lib/styles.css'
import useStyles from './styles'

const CarrierCard = ({
	carrierData,
	selected,
	setSelected,
	isItemSelected,
	handleDeleteClick,
	handleEditClick,
	key,
	isAccountCarrierList,
}) => {
	const classes = useStyles()
	const theme = useTheme()
	const index = Number(key?.split('-').pop())

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name)
		let newSelected = []

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name)
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1))
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1))
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
		}
		setSelected(newSelected)
	}

	return (
		<div className={classes.carrierCardContainer}>
			<div>
				<div className={classes.logoContainer}>
					<Checkbox
						inputProps={{ 'aria-labelledby': 'labelId' }}
						checked={isItemSelected}
						className={classes.tableCheckbox}
						onClick={(event) => handleClick(event, carrierData.id)}
						size="small"
						style={{
							color: isItemSelected ? theme.palette.secondary.light : theme.palette.primary.main,
						}}
					/>
					<div className={classes.logoContainer}>
						<img src={carrierData.carrier_logo} alt="" />
					</div>
					<div className={classes.logoName}>
						<span>{carrierData.name}</span>
					</div>
				</div>
			</div>
			<hr />
			<Grid container className={classes.itemsContainer}>
				<Grid item xs={12} sm={6} className={classes.itemContainer}>
					<span className={classes.itemTitle}>SCAC</span>
					<span>{carrierData.scac}</span>
				</Grid>
				<Grid item xs={12} sm={6} className={classes.itemContainer}>
					<span className={classes.itemTitle}>CARRIER GROUP</span>
					<span>{capitalizeFirstLetter(carrierData.carrier_group)}</span>
				</Grid>
				<Grid item xs={12} sm={6} className={classes.itemContainer}>
					<span className={classes.itemTitle}>CONTRACT TYPE</span>
					<span>{capitalizeFirstLetter(carrierData.contract_type)}</span>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Grid container>
						<Grid item xs={6} className={classes.itemContainer}>
							<span className={classes.itemTitle}>MARGIN</span>
							<span>{`${carrierData.uplift_net_charge} %`}</span>
						</Grid>
						<Grid item xs={6} className={classes.itemContainer}>
							<span className={classes.itemTitle}>MINIMUM</span>
							<span>{`$${carrierData.min_margin}`}</span>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} sm={6} className={classes.itemContainer}>
					<span className={classes.itemTitle}>ACCESSORIALS</span>
					{carrierData.accessorials.length ? (
						<>
							{carrierData.accessorials.map((accesorial) => (
								<span>
									{`${capitalizeFirstLetter(accesorial.name)}: ${accesorial.uplift_type_accessorial === 'FLAT' ? '$' : ''}${accesorial.uplift_accessorial}${accesorial.uplift_type_accessorial === 'PERCENTAGE' ? '%' : ''}`}
								</span>
							))}
						</>
					) : (
						<span>No accessorials</span>
					)}
				</Grid>
				<Grid item xs={12} sm={5} className={classes.itemContainer}>
					<span className={classes.itemTitle}>FUEL</span>
					<span>{`${carrierData.min_margin} % margin`}</span>
				</Grid>
			</Grid>
			<Grid container className={classes.actionsContainer}>
				<IconButton
					aria-label="edit"
					color="secondary"
					onClick={() => handleEditClick(isAccountCarrierList ? carrierData.id : index)}
				>
					<EditOutlinedIcon fontSize="small" />
					<p className={classes.actionTitle}>Edit</p>
				</IconButton>
				<IconButton aria-label="delete" color="secondary" onClick={() => handleDeleteClick(index)}>
					<DeleteOutlineOutlinedIcon fontSize="small" />
					<p className={classes.actionTitle}>Delete</p>
				</IconButton>
			</Grid>
		</div>
	)
}

CarrierCard.propTypes = {
	carrierData: PropTypes.objectOf(PropTypes.any).isRequired,
	isItemSelected: PropTypes.bool.isRequired,
	key: PropTypes.string.isRequired,
	isAccountCarrierList: PropTypes.bool.isRequired,
	selected: PropTypes.instanceOf(Array).isRequired,
	setSelected: PropTypes.func.isRequired,
	handleDeleteClick: PropTypes.func.isRequired,
	handleEditClick: PropTypes.func.isRequired,
}

export default CarrierCard
