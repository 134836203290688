import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
	navbar: {
		display: 'flex',
		flexDirection: 'column',
		maxWidth: '88ex',
		marginTop: '55px',
		marginLeft: '55px',
	},
}))

export default useStyles
