import { axiosReports } from '../../utils/apiClient'

const reqReports = async (params) => {
	const [error, data] = await axiosReports
		.get(params)
		.then((response) => [null, response])
		.catch((err) => [err, err])
	return [error, data]
}

export default reqReports
