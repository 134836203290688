import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import moment from 'moment'
import { Button, Fade } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import useStyles from './styles'

const UserAlert = ({ settings, dismissAlert }) => {
	const classes = useStyles()
	const [open, setOpen] = useState(false)

	useEffect(() => {
		if (Object.keys(settings).length > 0) {
			if (localStorage.getItem('userAlert') === null) {
				localStorage.setItem('userAlert', JSON.stringify(settings))
				setOpen(true)
			} else {
				const { last_update_date: prevDate } = JSON.parse(localStorage.getItem('userAlert'))
				const { last_update_date: newDate } = settings
				if (!moment(prevDate).isSame(newDate)) setOpen(true)
			}
		}
	}, [settings])

	return (
		<Fade in={open}>
			<Alert
				className={clsx(classes.root, { isOpen: open })}
				classes={{
					action: classes.action,
					message: classes.message,
				}}
				icon={false}
				color="error"
				action={
					<Button
						color="inherit"
						size="small"
						classes={{ textSizeSmall: classes.button }}
						onClick={() => {
							localStorage.setItem('userAlert', JSON.stringify(settings))
							dismissAlert()
							setOpen(false)
						}}
					>
						dismiss
					</Button>
				}
			>
				{settings.value}
			</Alert>
		</Fade>
	)
}

UserAlert.propTypes = {
	settings: PropTypes.objectOf(PropTypes.any).isRequired,
	dismissAlert: PropTypes.func.isRequired,
}

export default UserAlert
