import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	container: {
		padding: 24,
	},
	content: {
		backgroundColor: theme.palette.background.paper,
	},
	containerMap: {
		marginBottom: 16,
	},
	containerActivities: {
		maxHeight: '600px',
		overflowY: 'auto',
	},
	documentsMessageTitle: {
		fontSize: 17,
		fontWeight: theme.typography.fontWeightBold,
		color: theme.palette.neutral.darkGray,
	},
	proNumberMessage: {
		fontSize: 17,
		fontWeight: theme.typography.fontWeightBold,
		color: theme.palette.neutral.black,
	},
}))

export default useStyles
