import React from 'react'
import PropTypes from 'prop-types'

import { NoDataIcon } from '../../assets/Icons/Icons'

import useStyles from './styles'

const NoResultsFoundModel = (props) => {
	const { type, filters } = props
	const classes = useStyles()

	return (
		<tr className={classes.noDataTableRowModel}>
			<td colSpan="100%" className={classes.noDataTableCellModel}>
				<div className={classes.noDataDivModel}>
					<div className={classes.logoContainer}>
						<NoDataIcon style={{ width: 180, height: 180 }} />
					</div>
					<h1 className={classes.noDataTitle}>{`No ${type} Found`}</h1>
					{filters ? (
						<>
							<h1 className={classes.noDataSubtitleModel}>No results were found in the search.</h1>
							<h1 className={classes.noDataSubtitleModel}>Try again with a new search.</h1>
						</>
					) : null}
				</div>
			</td>
		</tr>
	)
}

NoResultsFoundModel.propTypes = {
	type: PropTypes.string.isRequired,
	filters: PropTypes.string,
}

NoResultsFoundModel.defaultProps = { filters: null }

export default NoResultsFoundModel
