import React, { useState, useContext } from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import i18n from 'i18next'
import { languages } from '../../utils/constants'
import SessionContext from '../../context/session'
import useStyles from './styles'

const LanguageSelector = () => {
	const { getCurrentLng, saveUserLng } = useContext(SessionContext)
	const [language, setLanguage] = useState({ value: getCurrentLng() })
	const classes = useStyles()

	const handleChange = (event) => {
		i18n.changeLanguage(event.target.value)
		setLanguage({ value: event.target.value })
		saveUserLng(event.target.value)
	}

	return (
		<Select
			classes={{
				icon: classes.white,
				select: classes.white,
			}}
			value={language.value}
			onChange={handleChange}
			renderValue={(value) => {
				const lng = languages.find((lang) => lang.value === value)
				return `${lng.icon} ${lng.value}`
			}}
		>
			{languages.map((lang) => (
				<MenuItem value={lang.value} key={`lang-${lang.value}`}>
					{lang.name}
				</MenuItem>
			))}
		</Select>
	)
}

export default LanguageSelector
