import { useSelector } from 'react-redux'

const useAccountsFilters = () => {
	const { currentPage, rowsPerPage, searchBar, status, orderBy, orderType } = useSelector(
		(state) => state.accountsFilters,
	)

	const buildParamsFromState = () => {
		let queryParams = '?'

		if (currentPage !== '') {
			queryParams += `offset=${currentPage}`
		}

		if (rowsPerPage !== '') {
			queryParams += `&limit=${rowsPerPage}`
		}

		if (searchBar !== '') {
			queryParams += `&search_value=${searchBar}`
		}

		if (status !== '') {
			queryParams += `&is_active=${status}`
		}

		if (orderBy !== '') {
			queryParams += `&order_by=${orderBy}`
		}

		if (orderType !== '') {
			queryParams += `&order_type=${orderType}`
		}

		if (queryParams !== '' && queryParams !== '?') localStorage.setItem('preserved-accounts-filters', queryParams)

		return queryParams
	}

	return [buildParamsFromState]
}

export default useAccountsFilters
