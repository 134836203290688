import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Grid } from '@material-ui/core'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import { useParams } from 'react-router-dom'
import clsx from 'clsx'
import CarrierLiabilitiesTable from '../components/CarrierLiabilitiesTable/CarrierLiabilitiesTable'
import { classComparator } from '../components/shipmentClassSorted'
import { SpecialInstructionsIcon } from '../../../../assets/Icons/Icons'
import { fetchCarrierById } from '../../../../redux/actions/Carriers.actions'
import ModuleHeader from '../../../../components/ModuleHeader'
import sweetAlertModal from '../../../../components/SweetAlertModal/SweetAlertModal'
import {
	contractTypesToUse,
	rateAccessLoop,
	serviceTypesToUse,
	getRateAccessSectionTitle,
} from '../../utils/carrierDetailsRateAccessHelpers'

import useStyles from './styles'
import rateAccessUseStyles from '../../newCarrier/containers/components/EditRateAccessForm/styles'

const CarriersDetailsContainer = () => {
	const classes = useStyles()
	const rateAccessClasses = rateAccessUseStyles()
	const dispatch = useDispatch()
	const { id } = useParams()
	const [setCarrierPhoto] = useState([])
	const [CarrierData, setCarrierData] = useState({})
	const [rateAccessData, setRateAccessData] = useState({})
	const [liabilities, setLiabilities] = useState([])

	useEffect(() => {
		const fetchData = async () => {
			const { status, resp } = await dispatch(fetchCarrierById(`/${id}`))
			if (status === 'error') {
				sweetAlertModal(status, 'There was an error fetching the carrier information!', null, 'Ok', true, false, null)
			} else {
				const res = resp?.carrierIntegrations?.[0] ?? {}
				setCarrierData(res)
				const rateAccess = {}
				rateAccessLoop((contractType, serviceType) => {
					const currentRateAccess = resp?.carrierIntegrations.find((integration) => {
						const contractTypeMatch = integration.contract_type === contractType
						return contractTypeMatch && integration.service_type === serviceType
					})
					rateAccess[contractType] = rateAccess[contractType] ?? {}
					rateAccess[contractType][serviceType] = currentRateAccess ?? {}
				})
				setRateAccessData(rateAccess)

				const liabilityResponse = resp?.liability_values ?? []
				const sortedLiability = liabilityResponse.sort(classComparator)
				setLiabilities(sortedLiability)
			}
		}
		fetchData()
	}, [dispatch, id, setCarrierPhoto])

	return (
		<>
			<form autoComplete="off">
				<Grid item className={classes.headerContainer}>
					<ModuleHeader title="View Carrier" />
				</Grid>
				<div className={classes.sectionContainer}>
					<div className={classes.sectionTitle}>
						<h3 className={classes.newUserSectionTitle}>Basic Information</h3>
					</div>
					<div className={classes.innerContainer}>
						<Grid container spacing={2}>
							<Grid item xs={12} md={2}>
								<div className={classes.inputFileContainer}>
									<img src={CarrierData.carrier_logo} alt={CarrierData.scac} className={classes.carrierImg} />
								</div>
							</Grid>
							<Grid item xs={12} md={4}>
								<span className={classes.CarrierDetails}>{CarrierData.name}</span>
							</Grid>
							<Grid item xs={12} md={3}>
								<span className={classes.CarrierDetails}>{CarrierData.scac}</span>
							</Grid>
							<Grid item xs={12} md={3}>
								<span className={classes.CarrierDetails}>{CarrierData.carrier_group}</span>
							</Grid>
						</Grid>

						{/* carrier rules && regulations */}
						<div>
							<div className={classes.iconTitle}>
								<SpecialInstructionsIcon />
								<h4>Carrier Rules and Restrictions</h4>
							</div>
							{contractTypesToUse.map((contractType) => (
								<Grid container spacing={2} alignItems="center" key={contractType}>
									{serviceTypesToUse.map((serviceType) => (
										<Grid item xs={12} md={6} key={`${contractType}_${serviceType}`}>
											<div className={rateAccessClasses.outerDiv}>
												<h4 className={rateAccessClasses.sectionCarrierTitle}>
													{getRateAccessSectionTitle(contractType, serviceType).sectionTitle}
												</h4>
												<div className={classes.iconTitle}>
													<AssignmentOutlinedIcon color="primary" className={classes.icon} />
													<h5 className={classes.sectionCarrierTitle}>Cubic Capacity Rules</h5>
												</div>
												<div className={clsx(classes.iconTitle, rateAccessClasses.iconDivMargin)}>
													<AssignmentOutlinedIcon color="primary" className={classes.icon} />
													<h6 className={classes.sectionCarrierTitle}>
														Don&apos;t display the carrier if Cubic Footage is over&nbsp;
														<span className={classes.CarrierDetails}>
															{rateAccessData?.[contractType]?.[serviceType]?.cubic_capacity}
														</span>
														ft and Total Density in Under&nbsp;
														<span className={classes.CarrierDetails}>
															{rateAccessData?.[contractType]?.[serviceType]?.density}
														</span>
														PCF
													</h6>
												</div>
												<div className={classes.iconTitle}>
													<AssignmentOutlinedIcon color="primary" className={classes.icon} />
													<h5 className={classes.sectionCarrierTitle}> Linear Footage Rules</h5>
												</div>
												<div className={clsx(classes.iconTitle, rateAccessClasses.iconDivMargin)}>
													<AssignmentOutlinedIcon color="primary" className={classes.icon} />
													<h6 className={classes.sectionCarrierTitle}>
														{/* eslint-disable-next-line max-len */}
														Don&apos;t display the carrier if Total Linear Footage is greater than &nbsp;
														<span className={classes.CarrierDetails}>
															{rateAccessData?.[contractType]?.[serviceType]?.linear_footage_max}
														</span>
														ft
													</h6>
												</div>
												<div className={classes.iconTitle}>
													<AssignmentOutlinedIcon color="primary" className={classes.icon} />
													<h5 className={classes.sectionCarrierTitle}>Maximum Total Weight</h5>
												</div>
												<div className={clsx(classes.iconTitle, rateAccessClasses.iconDivMargin)}>
													<AssignmentOutlinedIcon color="primary" className={classes.icon} />
													<h6 className={classes.sectionCarrierTitle}>
														Don&apos;t display the carrier if Total Weight is greater than &nbsp;
														<span className={classes.CarrierDetails}>
															{rateAccessData?.[contractType]?.[serviceType]?.weight_max}
														</span>
														lbs
													</h6>
												</div>
											</div>
										</Grid>
									))}
								</Grid>
							))}
						</div>

						<div className={classes.childAccountsInformationContainer}>
							<Grid container className={classes.childAccountInformationSectionTitle}>
								<p className={classes.accountBasicInformationSectionTitle}>Carrier Liabilities</p>
							</Grid>

							<CarrierLiabilitiesTable liabilities={liabilities} setLiabilities={setLiabilities} />
						</div>
					</div>
				</div>
			</form>
		</>
	)
}

export default CarriersDetailsContainer
