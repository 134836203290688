import { useSelector } from 'react-redux'

const useCarriersFilters = () => {
	const { currentPage, rowsPerPage, searchBar, carrierGroup, contractType, order, orderBy } = useSelector(
		(state) => state.carriersFilters,
	)

	const buildParamsFromState = () => {
		let queryParams = ''

		if (currentPage !== '') {
			queryParams += `?page=${currentPage}`
		}

		if (rowsPerPage !== '') {
			queryParams += `&limit=${rowsPerPage}`
		}

		if (searchBar !== '') {
			queryParams += `&search=${searchBar}`
		}

		if (carrierGroup) {
			queryParams += `&carrierGroup=${carrierGroup}`
		}

		if (contractType) {
			queryParams += `&contractType=${contractType}`
		}

		if (orderBy !== '') {
			queryParams += `&order_by=${orderBy}&order=${order}`
		}

		// Save the filters into the localStorage for future loads (When refreshing, changing tabs, etc)
		if (queryParams !== '' && queryParams !== '&') localStorage.setItem('preserved-carriers-filters', queryParams)

		return queryParams
	}

	return [buildParamsFromState]
}

export default useCarriersFilters
