import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	root: { padding: 24 },
	detailsContainer: {
		margin: '-16px -24px',
		overflowX: 'hidden',
		overflowY: 'hidden',
		[theme.breakpoints.down(768)]: {
			margin: '-16px -16px',
		},
	},
	rootGray: { padding: 24, background: theme.palette.neutral.whiteGray },
	twoGrids: { display: 'grid', gridTemplateColumns: '1fr 1fr' },
	sectionTitle: {
		fontWeight: 'bold',
		fontSize: 17,
		color: theme.palette.secondary.main,
		marginLeft: 10,
	},
	spanSubTitle: {
		fontWeight: 'bold',
		fontSize: 15,
		color: theme.palette.secondary.main,
	},
	alignCenter: { display: 'flex', alignItems: 'center' },
	iconMargin: { marginRight: 12 },
	linkButton: {
		backgroundColor: theme.palette.secondary.main,
		borderRadius: '4px',
		padding: '13px 50px',
		color: '#FFF',
		fontSize: '17px',
		fontWeight: theme.typography.fontWeightRegular,
		textDecoration: 'none',
		textAlign: 'center',
		lineHeight: '30px',
		[theme.breakpoints.down(768)]: {
			marginBottom: '10px',
		},
	},
	linkButtonIcon: {
		margin: '0 24px',
		textTransform: 'inherit',
		fontWeight: 600,
	},
	buttons: {
		display: 'flex',
		justifyContent: 'end',
		flexDirection: 'column',
		padding: '20px',
		width: '100%',
		background: theme.palette.neutral.white,
		[theme.breakpoints.up(768)]: {
			flexDirection: 'row-reverse',
		},
		[theme.breakpoints.down('md')]: {
			padding: '15px',
		},
	},
	accountDetailContainer: {
		padding: '0px 24px 0px 10px',
		width: '100%',
		height: '100px',
		background: '#F4F6F6',
	},
	accountHeader: {
		display: 'flex',
		justifyContent: 'space-between',
		marginLeft: 20,
		[theme.breakpoints.down('md')]: {
			marginLeft: 10,
		},
	},
	accountHeaderInformation: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: '0.75rem',
	},
	accountTitleText: {
		fontSize: '34px',
		[theme.breakpoints.down('md')]: {
			fontSize: '22px',
		},
	},
	accountSubtitle: {
		fontSize: 16,
		height: '50%',
	},
	accountTitleRow: {
		display: 'flex',
		alignItems: 'center',
		height: '50%',
	},
	accountOptions: {
		alignSelf: 'center',
		marginBottom: '20px',
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},
	chipActive: {
		backgroundColor: theme.palette.success.light,
		color: theme.palette.success.dark,
		marginLeft: 8,
		fontSize: 11,
		height: 19,
	},
	chipInactive: {
		backgroundColor: theme.palette.semantic.red.primary,
		color: theme.palette.neutral.white,
		marginLeft: 8,
		fontSize: 11,
		height: 19,
	},
}))

export default useStyles
