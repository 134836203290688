import React, { useState, useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { CircularProgress, Checkbox, TablePagination } from '@material-ui/core'

import { updatePaginator } from '../../../../../redux/actions/Invoices.actions'
import { setCurrentPage, setRowsPerPage } from '../../../../../redux/actions/UsersFilters.actions'

import { usersTableCells } from '../../../../../utils/headCells'
import NoResultsFound from '../../../../../components/NoResultsFound'
import TableToolbar from '../TableToolbar'
import useStyles from './styles'
import UsersListRow from '../UsersListRow'
import SessionContext from '../../../../../context/session'
import { permissionName } from '../../../../../utils/constants'
import isPermit from '../../../../../utils/permission'

const TableList = (props) => {
	const { pageReset } = props
	const classes = useStyles()
	const dispatch = useDispatch()
	const users = useSelector((state) => state.users)
	const usersFilters = useSelector((state) => state.usersFilters)
	const [selected, setSelected] = useState([])
	const [page, setPage] = useState(0)
	const isSelected = (name) => selected.indexOf(name) !== -1
	const [noActions, setNoActions] = useState(false)
	const { getUserData } = useContext(SessionContext)
	const { permissions } = getUserData()

	const handleChangePage = (event, newPage) => {
		dispatch(setCurrentPage(newPage * usersFilters.rowsPerPage))
		dispatch(updatePaginator(newPage * usersFilters.rowsPerPage))
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		dispatch(setRowsPerPage(Number(event.target.value)))
		dispatch(setCurrentPage(0))
		setPage(0)
	}

	const handleSelectAllClick = (event) => {
		const data = users.users

		if (event.target.checked) {
			const newSelecteds = data.map((item) => item.id)
			setSelected(newSelecteds)
			return
		}
		setSelected([])
	}

	useEffect(() => {
		if (pageReset) setPage(0)
	}, [pageReset])

	useEffect(() => {
		setNoActions(
			!!(
				isPermit(permissions, permissionName.FP_ADMIN_USERS_DEACTIVATE) ||
				isPermit(permissions, permissionName.FP_ADMIN_USERS_UPDATE) ||
				isPermit(permissions, permissionName.ADMIN_USERS_UPDATE)
			),
		)
	}, [permissions, noActions])

	const renderTableBody = () => {
		let data = []
		if (users.isLoading) {
			return (
				<TableRow>
					<TableCell align="center" colSpan={Object.keys(usersTableCells).length}>
						<CircularProgress />
					</TableCell>
				</TableRow>
			)
		}
		data =
			users.users &&
			users.users.map((row, index) => {
				const isItemSelected = isSelected(row.id)
				const labelId = `enhanced-table-checkbox-${index}`

				return (
					<UsersListRow
						key={row.id}
						labelId={labelId}
						isItemSelected={isItemSelected}
						data={row}
						selected={selected}
						setSelected={setSelected}
						noActions={noActions}
						canActivate={isPermit(permissions, permissionName.FP_ADMIN_USERS_DEACTIVATE)}
					/>
				)
			})
		if (data.length === 0) {
			return (
				<TableRow>
					<TableCell align="center" colSpan={Object.keys(usersTableCells).length}>
						<NoResultsFound type="Users" />
					</TableCell>
				</TableRow>
			)
		}
		return data
	}
	return (
		<div className={classes.root}>
			<TableToolbar selected={selected} />
			<Paper className={classes.paper} elevation={0}>
				<TableContainer>
					<Table className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
						<TableHead>
							<TableRow className={classes.tableRow}>
								<TableCell padding="checkbox" className={classes.tableCell}>
									{isPermit(permissions, permissionName.FP_ADMIN_USERS_DEACTIVATE) && (
										<Checkbox
											indeterminate={selected.length > 0 && selected.length < users.users.length}
											checked={selected.length > 0 && selected.length === users.users.length}
											onChange={handleSelectAllClick}
											inputProps={{ 'aria-label': 'select all items' }}
											style={{
												color:
													(users.users.length > 0 && selected.length === users.users.length) ||
													(selected.length > 0 && selected.length < users.users.length)
														? '#007a8a'
														: '#818181',
											}}
										/>
									)}
								</TableCell>
								{usersTableCells.map((cell, index) => (
									<TableCell key={`id-${index + 1}`} className={classes.tableCell}>
										{cell.label}
									</TableCell>
								))}
								{noActions && <TableCell className={classes.tableCell}>ACTIONS</TableCell>}
							</TableRow>
						</TableHead>
						<TableBody>{renderTableBody()}</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					classes={{
						root: classes.tablePaginator,
						toolbar: classes.tablePaginatorToolbar,
						actions: classes.tablePaginatorActions,
					}}
					rowsPerPageOptions={[10, 20, 50, 100]}
					component="div"
					count={users.totalUsers}
					rowsPerPage={Number(usersFilters.rowsPerPage)}
					page={page}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
				/>
			</Paper>
		</div>
	)
}

TableList.propTypes = {
	pageReset: PropTypes.string.isRequired,
}

export default TableList
