import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import TablePagination from '@material-ui/core/TablePagination'
import { CircularProgress } from '@material-ui/core'
import { SortListIcon, AscendingIcon, DescendingIcon } from '../../../../../assets/Icons/Icons'

import { locationTableCells } from '../../../../../utils/headCells'
import { updatePaginator } from '../../../../../redux/actions/Locations.actions'
import { setCurrentPage, setRowsPerPage } from '../../../../../redux/actions/LocationsFilters.action'
import NoResultsFound from '../../../../../components/NoResultsFound'

import useStyles from './style'
import LocationTableRow from '../LocationTableRow'

const TableList = (props) => {
	const { pageReset, handleSortColumn } = props
	const locations = useSelector((state) => state.locations)
	const locationsFilters = useSelector((state) => state.locationsFilters)
	const classes = useStyles()
	const dispatch = useDispatch()
	const [page, setPage] = useState(Number(locationsFilters.currentPage / locationsFilters.rowsPerPage))

	const handleChangePage = (event, newPage) => {
		dispatch(setCurrentPage(newPage * locationsFilters.rowsPerPage))
		dispatch(updatePaginator(newPage * locationsFilters.rowsPerPage))
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		dispatch(setRowsPerPage(Number(event.target.value)))
		dispatch(setCurrentPage(0))
		setPage(0)
	}

	useEffect(() => {
		if (pageReset) setPage(0)
	}, [pageReset])

	const renderTableBody = () => {
		let data = []
		if (locations.isLoading) {
			return (
				<TableRow>
					<TableCell align="center" colSpan={Object.keys(locationTableCells).length}>
						<CircularProgress />
					</TableCell>
				</TableRow>
			)
		}
		data = locations.locations.map((row, index) => <LocationTableRow key={`id-${index + 1}`} data={row} />)
		if (data.length === 0) {
			return (
				<TableRow>
					<TableCell align="center" colSpan={Object.keys(locationTableCells).length}>
						<NoResultsFound type="Locations" />
					</TableCell>
				</TableRow>
			)
		}
		return data
	}
	const showIcon = (cell) => {
		if (cell.id === locationsFilters.orderBy && locationsFilters.order === 'ASC') {
			return <AscendingIcon onClick={() => handleSortColumn(cell.id)} style={{ alignSelf: 'center' }} />
		}
		if (cell.id === locationsFilters.orderBy && locationsFilters.order === 'DESC') {
			return <DescendingIcon onClick={() => handleSortColumn(cell.id)} style={{ alignSelf: 'center' }} />
		}
		return <SortListIcon onClick={() => handleSortColumn(cell.id)} style={{ alignSelf: 'center' }} />
	}

	const sortableColumns = (cell) => {
		if (cell.sortable) {
			return (
				<div
					onClick={() => handleSortColumn(cell.id)}
					onKeyPress={() => handleSortColumn(cell.id)}
					style={{ display: 'flex', alignItems: 'flex-end', cursor: 'pointer' }}
					aria-hidden="true"
				>
					{cell.label}
					{showIcon(cell)}
				</div>
			)
		}
		return <div style={{ display: 'flex', alignItems: 'flex-end', cursor: 'pointer' }}>{cell.label}</div>
	}

	return (
		<div className={classes.root}>
			<Paper className={classes.paper} elevation={0}>
				<TableContainer>
					<Table className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
						<TableHead>
							<TableRow>
								{locationTableCells.map((cell, index) => (
									<TableCell
										className={classes.tableCell}
										key={`id-${index + 1}`}
										style={{ paddingLeft: index === 0 && '70px' }}
									>
										{locationTableCells.length !== index + 1 ? sortableColumns(cell) : cell.label}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>{renderTableBody()}</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					classes={{
						root: classes.tablePaginator,
						toolbar: classes.tablePaginatorToolbar,
						actions: classes.tablePaginatorActions,
					}}
					rowsPerPageOptions={[10, 20, 25, 100]}
					component="div"
					count={locations.totalRecords}
					rowsPerPage={Number(locationsFilters.rowsPerPage)}
					page={page}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
				/>
			</Paper>
		</div>
	)
}

TableList.propTypes = {
	pageReset: PropTypes.string.isRequired,
	handleSortColumn: PropTypes.func.isRequired,
}

export default TableList
