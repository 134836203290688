import React from 'react'

import NewLocationContainer from '../containers/NewLocationContainer'

const NewLocation = () => (
	<div>
		<NewLocationContainer />
	</div>
)

export default NewLocation
