import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	subtitle: {
		fontWeight: theme.typography.fontWeightMedium,
		margin: '5px 0 20px 0',
	},
	logo: {
		width: 50,
		objectFit: 'contain',
	},
	item: {
		margin: 0,
	},
}))

export default useStyles
