/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import reqGetQuoteDetails from '../requests/QuoteDetails.request'

const quoteDetailsSlice = createSlice({
	name: 'quoteDetailsSlice',
	initialState: {
		quoteDetails: {},
		quoteDetailsToShipment: {},
		quoteDetailsCopy: {},
		quoteDetailsReQuote: {},
		isLoading: false,
		error: false,
		cloneShipmentData: {},
	},
	reducers: {
		startLoading: (state) => {
			state.isLoading = true
		},
		hasError: (state, action) => {
			state.error = action.payload
			state.isLoading = false
		},
		quoteDetailsSuccess: (state, action) => {
			state.quoteDetails = action.payload
			state.isLoading = false
		},
		quoteDetailsToShipmentSuccess: (state, action) => {
			state.quoteDetailsToShipment = action.payload
			state.isLoading = false
		},
		quoteDetailsCopySuccess: (state, action) => {
			state.quoteDetailsCopy = action.payload
			state.isLoading = false
		},
		quoteForReQuote: (state, action) => {
			state.quoteDetailsReQuote = action.payload
			state.isLoading = false
		},
		addAccessorials: (state, action) => {
			state.quoteDetailsReQuote.accesorials = action.payload
		},
		setDataForCloneShipment: (state, action) => {
			state.cloneShipmentData = action.payload
		},
	},
})

const { actions, reducer } = quoteDetailsSlice

export const {
	startLoading,
	hasError,
	quoteDetailsSuccess,
	quoteDetailsToShipmentSuccess,
	quoteDetailsCopySuccess,
	quoteForReQuote,
	addAccessorials,
	setDataForCloneShipment,
} = actions

export const fetchQuoteDetails = (params, isConverToShipment) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqGetQuoteDetails(params)

	if (error) {
		dispatch(hasError(error))
		return { status: 'error', resp: error }
	}

	if (isConverToShipment) {
		dispatch(quoteDetailsToShipmentSuccess(data.data.data.quote))
	} else {
		dispatch(quoteDetailsSuccess(data.data.data.quote))
	}
	return { status: 'success', resp: data.data.data.quote }
}

export const fetchQuoteDetailsCopy = (params) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqGetQuoteDetails(params)

	if (error) {
		dispatch(hasError(error))
		return { status: 'error', resp: error }
	}
	dispatch(quoteDetailsCopySuccess(data.data.data.quote))
	return { status: 'success', data: data.data.data.quote }
}

export const clearQuoteDetailsForReQuote = (params) => async (dispatch) => {
	dispatch(quoteForReQuote(params))
}

export const clearQuoteDetailsForShipment = () => async (dispatch) => {
	dispatch(quoteDetailsToShipmentSuccess(''))
}

export const clearDetailsCopyForQuote = () => async (dispatch) => {
	dispatch(quoteDetailsCopySuccess({}))
}

export const clearQuoteDetails = (params) => async (dispatch) => {
	dispatch(quoteDetailsSuccess(params))
}

export const saveQuoteDetailsForReQuote = (params) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqGetQuoteDetails(params)

	if (error) {
		dispatch(hasError(error))
		return { status: 'error', resp: error }
	}
	dispatch(quoteForReQuote(data.data.data.quote))
	return { status: 'success', resp: data.data.data.quote }
}

export default reducer
