import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/core/styles'

import { removeUnderscoreAndUppercase } from '../../utils/helpers'

const useRowStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			borderBottom: 'unset',
		},
	},
	tableCellHead: {
		fontWeight: theme.typography.fontWeightMedium,
	},
	references: {
		padding: '0 16px',
		margin: 0,
	},
}))

const ShipmentReferences = ({ references }) => {
	const classes = useRowStyles()
	const [referencesList, setReferencesList] = useState([])

	useEffect(() => {
		const referencesPayload = references.map((reference) => ({
			type: reference.referenceType ? reference.referenceType : reference.type,
			value: reference.value,
		}))
		setReferencesList(referencesPayload)
	}, [references])

	const formatReferenceType = (referenceType) => {
		if (referenceType === 'PO_NUMBER') {
			return 'PO Number'
		}
		return removeUnderscoreAndUppercase(referenceType)
	}

	return (
		<div className="shipment-references-container">
			{references?.length > 0 ? (
				<Table size="small" aria-label="purchases">
					<TableHead>
						<TableRow className={classes.root}>
							<TableCell className={classes.tableCellHead}>#</TableCell>
							<TableCell className={classes.tableCellHead}>Reference type</TableCell>
							<TableCell className={classes.tableCellHead}>Reference Number</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{referencesList.map((reference, index) => (
							<TableRow key={uuidv4()}>
								<TableCell style={{ borderBottom: 'none', paddingTop: 0 }}>{index + 1}</TableCell>
								<TableCell style={{ borderBottom: 'none', paddingTop: 0 }}>
									{formatReferenceType(reference.type)}
								</TableCell>
								<TableCell style={{ borderBottom: 'none', paddingTop: 0 }}>{reference.value}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			) : (
				<p className={classes.references}>Not references added</p>
			)}
		</div>
	)
}

ShipmentReferences.propTypes = {
	references: PropTypes.arrayOf(PropTypes.any).isRequired,
}

export default ShipmentReferences
