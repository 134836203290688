import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	rateCardContainer: {
		border: '1px solid #c9cfe4',
		borderRadius: '4px',
		padding: '16px',
		backgroundColor: '#fafbfc',
	},
	logoNameContainer: {
		display: 'flex',
		alignitems: 'center',
	},
	logoContainer: {
		display: 'flex',
		marginRight: '1em',
		'& img': {
			width: '50px',
			height: '50px',
			objectFit: 'contain',
		},
	},
	logoName: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'center',
		'& span': {
			marginBottom: '0.1em',
			fontWeight: 600,
			fontSize: '14px',
			marginLeft: 0,
		},
		'& span.add': {
			color: '#EC3514',
			textDecoration: 'underline',
			marginBottom: '1.5em',
		},
	},
	itemsContainer: {
		display: 'grid',
		gridAutoFlow: 'row',
		'@media (min-width: 768px)': {
			gridAutoFlow: 'column',
		},
	},
	itemTitle: {
		fontWeight: theme.typography.fontWeightMedium,
		fontSize: '12px',
		marginBottom: '5px',
		'@media (min-width: 768px)': {
			marginBottom: '16px',
		},
	},
	serviceData: {
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'start',
		'& span': {
			fontWeight: 600,
			fontSize: '14px',
		},
		'& span.estimate': {
			fontSize: '11px',
			marginRight: '2em',
		},
		'& div': {
			display: 'flex',
			alignItems: 'center',
			'& span': {
				fontWeight: 600,
				fontSize: '16px',
				marginTop: '5px',
			},
		},
	},
	liability: {
		fontWeight: 600,
		fontSize: '16px',
		margin: 0,
		display: 'flex',
		flexDirection: 'column',
	},
	rateContainer: {
		display: 'flex',
	},
	lowestPrice: {
		width: '101px',
		fontWeight: 600,
		fontSize: '12px',
		color: '#00C48C',
		backgroundColor: '#D5F2EA',
		padding: '2px',
		textAlign: 'center',
		marginLeft: '8px',
		marginTop: '10px',
		borderRadius: '5px',
	},
	priceData: {
		margin: 0,
		display: 'flex',
		flexDirection: 'column',
		'& span': {
			fontWeight: 400,
			fontSize: '13px',
		},
		'& span.without-insurance': {
			fontSize: '11px',
			color: '#A1A1A1',
		},
		'& span.price': {
			fontSize: '16px',
		},
	},
	actions: {
		display: 'grid',
		gridAutoFlow: 'column',
		marginTop: '16px',
		'@media (min-width: 768px)': {
			justifyContent: 'end',
			columnGap: '15px',
		},
	},
	convertToShipmentButton: {
		display: 'flex',
		alignItems: 'center',
		backgroundColor: 'transparent',
		border: 'none',
		outline: 'unset',
		margin: '0',
		padding: '0',
		cursor: 'pointer',
		[theme.breakpoints.up('md')]: {
			columnGap: '5px',
		},
	},
	labelActionButton: {
		color: theme.palette.neutral.black,
	},
	disabled: {
		color: theme.palette.neutral.mainGray,
	},
	helpCursor: {
		'&:hover': {
			cursor: 'help',
		},
	},
	itemContainer: {
		background: '#fafbfc',
		width: '100%',
		paddingTop: theme.spacing(2),
	},
	divider: {
		padding: theme.spacing(1, 0),
	},
	guaranteedService: {
		marginLeft: '5px',
		color: '#00C48C',
		height: '17px',
	},
	summary: {
		maxHeight: theme.spacing(4),
		'&> div': {
			margin: theme.spacing(1, 0),
		},
	},
	containerTerminals: {
		paddingLeft: theme.spacing(2),
	},
	verifiedIcon: {
		marginLeft: '5px',
		height: '17px',
		color: theme.palette.success.main,
	},
}))

export default useStyles
