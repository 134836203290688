import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import {
	Menu,
	MenuItem,
	ListItemIcon,
	ListItemText,
	Grid,
	Typography,
	Box,
	Button,
	useMediaQuery,
	IconButton,
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import NotInterested from '@material-ui/icons/NotInterested'
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from '@material-ui/core/styles'
import useStyles from './style'
import SessionContext from '../../../../../context/session'
import { editAccountEstatusBulk, fetchAccounts } from '../../../../../redux/actions/Accounts.actions'
import sweetAlertModal from '../../../../../components/SweetAlertModal/SweetAlertModal'
import { permissionName } from '../../../../../utils/constants'
import isPermit from '../../../../../utils/permission'

const TableToolbar = ({ selected, setSelected }) => {
	const classes = useStyles()
	const theme = useTheme()
	const matchesMD = useMediaQuery(theme.breakpoints.up('md'))
	const dispatch = useDispatch()
	const { getUserData } = useContext(SessionContext)
	const { actualCompany, permissions } = getUserData()
	const { accountsFilters } = useSelector((state) => state)
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)

	const handleOpenMenu = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleAccountStatusEdit = async (newStatus) => {
		const config = {
			headers: { 'X-Company-id': Number(actualCompany) },
		}
		const { status } = await dispatch(
			editAccountEstatusBulk({ status: newStatus, childCompanies: selected.map((id) => Number(id)) }, config),
		)
		if (status === 'error') {
			sweetAlertModal('error', 'There was an error updating the accounts', null, 'Ok', true, false)
			return
		}
		setSelected([])
		const params = {
			offset: accountsFilters.currentPage,
			limit: accountsFilters.rowsPerPage,
			search_value: accountsFilters.searchBar,
			is_active: accountsFilters.status,
			order_by: accountsFilters.orderBy,
			order_type: accountsFilters.orderType,
		}
		Object.keys(params).forEach((key) => params[key] === '' && delete params[key])
		const qs = Object.keys(params)
			.map((key) => `${key}=${params[key]}`)
			.join('&')
		dispatch(fetchAccounts(`?${qs}`, config))
	}

	return (
		<>
			{matchesMD && isPermit(permissions, permissionName.FP_ADMIN_ACCOUNTS_DEACTIVATE) && (
				<Box className={classes.toolBar}>
					{selected.length > 1 ? (
						<Grid container alignItems="center" spacing={3}>
							<Grid item>
								<Typography className={classes.toolBarText}>{`(${selected.length} selected)`}</Typography>
							</Grid>
							<Grid item>
								<Button
									classes={{
										root: classes.toolBarBurton,
										iconSizeSmall: [classes.toolBarButtonIcon, classes.iconGreen].join(' '),
									}}
									startIcon={<CheckCircleOutlineOutlinedIcon />}
									size="small"
									onClick={() => {
										handleAccountStatusEdit('true')
									}}
								>
									Activate Accounts
								</Button>
							</Grid>
							<Grid item>
								<Button
									classes={{
										root: classes.toolBarBurton,
										iconSizeSmall: [classes.toolBarButtonIcon, classes.iconRed].join(' '),
									}}
									startIcon={<NotInterested />}
									size="small"
									onClick={() => {
										handleAccountStatusEdit('false')
									}}
								>
									Deactivate Accounts
								</Button>
							</Grid>
						</Grid>
					) : (
						<Grid container>
							<Grid item>
								<Typography className={classes.toolBarText}>(Select accounts for bulk actions)</Typography>
							</Grid>
						</Grid>
					)}
				</Box>
			)}
			{!matchesMD && (
				<Box className={classes.toolBar}>
					{selected.length > 1 ? (
						<Grid container alignItems="center" justify="space-between">
							<Grid item>
								<Typography className={classes.toolBarText}>{`(${selected.length} selected)`}</Typography>
							</Grid>
							<Grid item>
								<IconButton
									className={[classes.toolBarBurton, classes.toolBarButtonIconMore]}
									size="small"
									edge="end"
									onClick={handleOpenMenu}
								>
									<MoreVertIcon />
								</IconButton>
								<Menu id="accounts-bulk-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
									<MenuItem
										dense
										onClick={() => {
											handleAccountStatusEdit('true')
										}}
									>
										<ListItemIcon className={classes.listItemIcon}>
											<CheckCircleOutlineOutlinedIcon className={classes.iconGreen} size="small" />
										</ListItemIcon>
										<ListItemText primary="Activate Accounts" />
									</MenuItem>
									<MenuItem
										dense
										onClick={() => {
											handleAccountStatusEdit('false')
										}}
									>
										<ListItemIcon className={classes.listItemIcon}>
											<NotInterested className={classes.iconRed} size="small" />
										</ListItemIcon>
										<ListItemText primary="Deactivate Accounts" />
									</MenuItem>
								</Menu>
							</Grid>
						</Grid>
					) : (
						<Grid container>
							<Grid item>
								<Typography className={classes.toolBarText}>(Select accounts for bulk actions)</Typography>
							</Grid>
						</Grid>
					)}
				</Box>
			)}
		</>
	)
}

TableToolbar.propTypes = {
	selected: PropTypes.arrayOf(PropTypes.any).isRequired,
	setSelected: PropTypes.func.isRequired,
}

export default TableToolbar
