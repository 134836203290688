/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useContext } from 'react'
import { makeStyles, Paper, Grid, Box } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { useOktaAuth } from '@okta/okta-react'
import layoutBackground from '../../../../assets/img/beon-background-right.png'
import BeonLogo from './svg/BeonLogo'
import OktaNavBar from './NavBar/OktaNavBar'
import OktaSignInWidget from './OktaSignInWidget'
import SessionContext from '../../../../context/session/index'
import postLoginProcess from './postLoginHelper'

const useStyles = makeStyles(() => ({
	oktaLoginLayout: {
		fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
		backgroundImage: `url(${layoutBackground})`,
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		zIndex: '-1',
		position: 'fixed',
		left: '0',
		overflow: 'hidden',
		top: '0',
		backgroundPosition: 'top right',
		backgroundRepeat: 'no-repeat',
		backgroundColor: '#262626',
	},
	oktaLoginOuterContainer: {
		margin: '14vh auto auto',
		padding: '40px 30px',
		backgroundColor: '#fff',
		maxWidth: '405px',
	},
	divider: {
		width: '100%',
	},
	beonLogo: {
		height: ' 42px',
		width: 'auto',
		maxHeight: 'unset',
		maxWidth: 'unset',
		margin: '0 0 15px 0',
		padding: 'unset',
	},
}))

const OktaLoginPage = ({ config }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const { createSession, saveUserData, getUserData, saveActualCompanyId, cleanStorage, updatePostLoginFinished } =
		useContext(SessionContext)

	const { oktaAuth, authState } = useOktaAuth()

	const onSuccess = async (tokens) => {
		// console.log('called: oktaWidget -> onSuccess');
		oktaAuth.tokenManager.setTokens(tokens)
		oktaAuth.start()
		const { accessToken } = tokens.accessToken
		await postLoginProcess(
			true,
			accessToken,
			oktaAuth,
			createSession,
			saveUserData,
			cleanStorage,
			saveActualCompanyId,
			dispatch,
			updatePostLoginFinished,
			null,
			getUserData,
		)
	}

	const onError = () => {
		console.log('ERROR LOGGING IN')
	}

	if (!authState) return null

	return (
		<div id="okta-layout" className={classes.oktaLoginLayout}>
			<div id="credentials-container">
				<OktaNavBar />
				<Paper className={classes.oktaLoginOuterContainer}>
					<Grid container direction="column">
						<Box className={classes.beonLogo}>
							<Grid container direction="row">
								<Grid item>
									<BeonLogo />
								</Grid>
							</Grid>
						</Box>
						<OktaSignInWidget onSuccess={onSuccess} onError={onError} config={config} />
					</Grid>
				</Paper>
			</div>
		</div>
	)
}

export default OktaLoginPage
