import Swal from 'sweetalert2'

import './SweetAlertModal.scss'

const sweetAlertModal = (icon, title, text, confirmButtonText, showCloseButton, showCancelButton, cancelButtonText) =>
	Swal.fire({
		icon,
		title,
		text,
		confirmButtonColor: '#FF3333',
		confirmButtonText,
		showCloseButton,
		showCancelButton,
		cancelButtonColor: '#CCD2D8',
		cancelButtonText,
		allowOutsideClick: false,
	})

export default sweetAlertModal
