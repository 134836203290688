/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const announcementStatusSlice = createSlice({
	name: 'announcementStatusSlice',
	initialState: {
		openAnnouncementModal: false,
		announcementMessage: '',
	},
	reducers: {
		setOpenAnnouncementModal: (state, action) => {
			state.openAnnouncementModal = action.payload
		},
		setAnnouncement: (state, action) => {
			state.announcementMessage = action.payload
		},
	},
})

const { actions, reducer } = announcementStatusSlice

export const { setOpenAnnouncementModal, setAnnouncement } = actions

export default reducer
