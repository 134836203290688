import { createSlice } from '@reduxjs/toolkit'
import reqGetLocations, {
	reqGetLocationById,
	reqGetDefaultLocations,
	reqGetLocationByZipCode,
	reqCreateLocation,
	reqEditLocation,
	reqDeleteLocation,
	reqEditLocationType,
	reqBulkLocationsCreation,
} from '../requests/Locations.request'

/* eslint no-param-reassign: ["error", { "props": false }] */

const locationsSlice = createSlice({
	name: 'locationsSlice',
	initialState: {
		locations: [],
		locationsZipCode: [],
		locationById: {},
		totalRecords: 0,
		totalRecordsZipCode: 0,
		pagSkip: 0,
		isLoading: false,
		error: false,
	},
	reducers: {
		startLoading: (state) => {
			state.isLoading = true
		},
		hasError: (state, action) => {
			state.error = action.payload
			state.isLoading = false
		},
		locationsSuccess: (state, action) => {
			state.locations = action.payload.locations
			state.totalRecords = action.payload.records
			state.locationById = {}
			state.isLoading = false
		},
		locationByIdSuccess: (state, action) => {
			state.locationById = {
				...state.locationById,
				[action.payload.location.id]: action.payload.locationAccessorials,
			}
			state.isLoading = false
		},
		locationsZipCodeSuccess: (state, action) => {
			state.locationsZipCode = action.payload.locations
			state.totalRecordsZipCode = action.payload.records
			state.locationById = {}
			state.isLoading = false
		},
		locationDefaultSuccess: (state, action) => {
			state.defaultLocations = {
				...state.defaultLocations,
				[action.payload.location.type.toLowerCase()]: action.payload,
			}
			state.isLoading = false
		},
		locationDefaultSuccessEmpty: (state) => {
			state.isLoading = false
		},
		updatePagSkip: (state, action) => {
			state.pagSkip = action.payload
		},
		reset: (state) => {
			state.locations = []
			state.locationsZipCode = []
			state.locationById = {}
			state.totalRecords = 0
			state.totalRecordsZipCode = 0
			state.pagSkip = 0
			state.isLoading = false
			state.error = false
		},
		cleanLocationsList: (state) => {
			state.locations = []
		},
	},
})

const { actions, reducer } = locationsSlice

export const {
	startLoading,
	hasError,
	locationsSuccess,
	updatePagSkip,
	locationByIdSuccess,
	locationsZipCodeSuccess,
	locationDefaultSuccess,
	locationDefaultSuccessEmpty,
	totalRecords,
	reset,
	cleanLocationsList,
} = actions

export const fetchLocations = (params) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqGetLocations(params)

	if (error) {
		return { status: 'error' }
	}
	dispatch(locationsSuccess(data.data.data))
	return { status: 'success' }
}

export const fetchLocationsByZipCode = (params) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqGetLocationByZipCode(params)

	if (error) {
		return { status: 'error' }
	}
	dispatch(locationsZipCodeSuccess(data.data.data))
	return { status: 'success' }
}

export const fetchLocationById = (params) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqGetLocationById(params)

	if (error) {
		dispatch(hasError(error))
		return { status: 'error', resp: error }
	}
	dispatch(locationByIdSuccess(data.data.data))
	return { status: 'success', resp: data.data.data }
}

export const fetchDefaultLocations = (type) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqGetDefaultLocations(type)
	if (error) {
		dispatch(hasError(error))
		return { status: 'error', resp: data }
	}
	const { type: locationType = null } = data.data?.data?.location ?? {}
	if (locationType) {
		dispatch(locationDefaultSuccess(data.data.data))
		return { status: 'success', resp: data.data.data }
	}
	dispatch(locationDefaultSuccessEmpty())
	return { status: 'success' }
}

export const updatePaginator = (params) => async (dispatch) => {
	dispatch(updatePagSkip(params))
}

export const resetLocations = (params) => async (dispatch) => {
	dispatch(reset(params))
}

export const cleanLocations = () => async (dispatch) => {
	dispatch(cleanLocationsList())
}

export const createLocation = (params) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqCreateLocation(params)

	if (error) {
		dispatch(hasError(data))
		return { status: 'error', resp: data }
	}
	return { status: 'success', resp: data }
}

export const editLocation = (params, body) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqEditLocation(params, body)

	if (error) {
		dispatch(hasError(data))
		return { status: 'error', resp: data }
	}

	return { status: 'success', resp: data }
}

export const editLocationType = (locationId, params, status) => async (dispatch) => {
	const [error, data] = await reqEditLocationType(locationId, params, status)

	if (error) {
		dispatch(hasError(data))
		return { status: 'error', resp: data }
	}

	return { status: 'success', resp: data }
}

export const deleteLocation = (locationId) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqDeleteLocation(locationId)
	if (error) {
		dispatch(hasError(error))
		return { status: 'error', resp: error }
	}
	return { status: 'success', resp: data }
}

export const createLocationsBulk = (body, config) => async () => {
	const [error, data] = await reqBulkLocationsCreation(body, config)
	if (error) {
		return { status: 'error', resp: data }
	}
	return { status: 'success', resp: data }
}

export default reducer
