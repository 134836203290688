import React, { useEffect, useState, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'

import { useDispatch, useSelector } from 'react-redux'

import { Button, useTheme, useMediaQuery, Grid, Checkbox, FormControlLabel } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

import { setTabCurrentRoute, setTabTitle } from '../../../../../redux/actions/MultiTabs.actions'
import { clearQuoteDetailsForReQuote } from '../../../../../redux/actions/QuoteDetails.actions'

import { createUserRoute, usersListRoute, AZURE_IMG } from '../../../../../utils/constants'
import { createUser, fetchUserById, editUser, updateUserPhoto } from '../../../../../redux/actions/Users.actions'
import { fetchAccountInformation } from '../../../../../redux/actions/Accounts.actions'

import SessionContext from '../../../../../context/session'

import ModuleHeader from '../../../../../components/ModuleHeader/index'
import BasicInformation from '../../components/BasicInformation'
import NotificationSettingsTable from '../../components/NotificationSettingsTable'
import NotificationSettingsCard from '../../components/NotificationSettingsCard'
import RolesAndPermissions from '../../components/RolesAndPermissions'
import sweetAlertModal from '../../../../../components/SweetAlertModal'

import useStyles from './styles'

const NewUserContainer = (props) => {
	const { isFromEditAccount, handleClose, updateUsersTable } = props
	const classes = useStyles()
	const theme = useTheme()
	const history = useHistory()
	const matches = useMediaQuery(theme.breakpoints.down('md'))
	const { handleSubmit, register, errors, control, setValue, reset, getValues } = useForm()
	const dispatch = useDispatch()
	const location = useLocation()
	const { id } = useParams()
	const isEdit = !!id && !isFromEditAccount
	// const currentTabs = useSelector((state) => state.multiTabs.tabs);
	const parentCompany = useSelector((state) => state.companies.parents)
	const [userPhoto, setUserPhoto] = useState([])
	const [phoneNumber, setPhoneNumber] = useState('')
	const [childrenCompanies, setChildrenCompanies] = useState([])
	const [editUserChildrenCompanies, setEditUserChildrenCompanies] = useState([])
	const [sendBolByEmail, setSendBolByEmail] = useState(false)
	const [sendByEmail, setSendByEmail] = useState(false)
	const [sendBySms, setSendBySms] = useState(false)
	const [sendWhenBooked, setSendWhenBooked] = useState(false)
	const [sendWhenInTransit, setSendWhenInTransit] = useState(false)
	const [sendWhenDelivered, setSendWhenDelivered] = useState(false)
	const [rolesPermissions, setRolesPermissions] = useState({})
	const [resetRolesPermissions, setResetRolesPermissions] = useState(false)
	const [editUserRolesPermissions, setEditUserRolesPermissions] = useState([])
	const [userRole, setUserRole] = useState('FREIGHT_PROS_ADMIN')
	const [errorPhoneNumber, setErrorPhoneNumber] = useState('')
	const [infoTableFirstName, setInfoTableFirstName] = useState('')
	const [infoTableLastName, setInfoTableLastName] = useState('')
	const [infoTableEmail, setInfoTableEmail] = useState('')
	const [sendEmailPassword, setSendEmailPassword] = useState(true)
	const [accountData, setAccountData] = useState({})
	const [account, setAccount] = useState(null)

	const { getUserData, getTokenUser } = useContext(SessionContext)
	const token = getTokenUser()
	const config = useMemo(() => {
		const { actualCompany } = getUserData()
		const newConfig = {
			headers: {
				'X-Company-id': Number(account || actualCompany),
				Authorization: `Bearer ${token}`,
			},
		}
		return newConfig
	}, [getUserData, account, token])
	// const AccountsTabs = currentTabs.find((item) => item.active) ?? {};
	// const { accounts } = AccountsTabs;

	useEffect(() => {
		if (!isFromEditAccount) {
			const splittedPath = location.pathname.split('/')
			const currentRoute = isEdit ? `/${splittedPath[1]}/${id}` : createUserRoute
			dispatch(setTabCurrentRoute(currentRoute))
			dispatch(setTabTitle(isEdit ? 'Edit user' : 'New user'))
			dispatch(clearQuoteDetailsForReQuote(''))
		}
	}, [isFromEditAccount, isEdit, dispatch, id, location])

	const formTexts = {
		title: isEdit ? 'Edit User' : 'New User',
		subtitle: isEdit ? 'Change user information.' : 'Insert new user information.',
		submitButtonText: isEdit ? 'Update User' : 'Save New User',
		errorSubmitText: isEdit
			? 'There was an error in the user update process!'
			: 'There was an error in the create user process!',
		successSubmitText: isEdit ? 'User has been updated successfully!' : 'User has been saved successfully!',
	}

	useEffect(() => {
		const fetchData = async () => {
			const { status, resp } = await dispatch(fetchUserById(id))
			if (status === 'error') {
				sweetAlertModal('error', 'There was an error fetching the user information!', null, 'Ok', true, false, null)
			} else {
				// console.log('config', config);
				const { status: accountStatus, resp: accountResp } = await dispatch(
					fetchAccountInformation(
						{
							...config,
							headers: { 'X-Company-id': resp.user.account },
						},
						resp.user.account,
					),
				)
				if (accountStatus === 'error') {
					sweetAlertModal(
						'error',
						'There was an error fetching the account information!',
						null,
						'Ok',
						true,
						false,
						null,
					)
				} else {
					// console.log('account resp', accountResp);
					setAccountData(accountResp)
				}
				if (resp.user.photo) {
					try {
						const response = await fetch(`${resp.user.photo}?${AZURE_IMG.AZURE_KEYS_USER}`, {
							method: 'GET',
							cache: 'no-cache',
						})
						if (response.type && response.status !== 200) throw new Error()
						const data = await response.blob()
						const photo = new File([data], 'photo.jpg', {
							type: response.headers.get('content-type') || 'image/jpeg',
						})
						setUserPhoto([photo])
					} catch (error) {
						sweetAlertModal(
							'error',
							'An error occurred while trying to load the user image.',
							null,
							'Ok',
							true,
							false,
							null,
						)
					}
				}
				const userFirstName = resp.user.name.split(' ').slice(0, -1).join(' ')
				const userLastName = resp.user.name.split(' ').slice(-1).join(' ')
				setUserRole(resp.user.role)
				setValue('firstName', userFirstName)
				setValue('lastName', userLastName)
				setValue('email', resp.user.email)
				setInfoTableFirstName(userFirstName)
				setInfoTableLastName(userLastName)
				setInfoTableEmail(resp.user.email)
				setPhoneNumber(resp.user.phone_number ? resp.user.phone_number : '')
				setValue('phone_number', resp.user.phone_number ? resp.user.phone_number : '')
				setEditUserChildrenCompanies(resp.user.children_companies)
				setSendBolByEmail(resp.user.notification_send_bol ? resp.user.notification_send_bol : false)
				setSendByEmail(resp.user.notification_by_email ? resp.user.notification_by_email : false)
				setSendBySms(resp.user.notification_by_sms ? resp.user.notification_by_sms : false)
				setSendWhenBooked(resp.user.notification_booked ? resp.user.notification_booked : false)
				setSendWhenInTransit(resp.user.notification_intransit ? resp.user.notification_intransit : false)
				setSendWhenDelivered(resp.user.notification_delivered ? resp.user.notification_delivered : false)
				setEditUserRolesPermissions(resp.roles_permissions)
			}
		}
		if (isEdit && id) fetchData()
		else {
			setValue('firstName', '')
			setValue('lastName', '')
			setPhoneNumber('')
			setValue('email', '')
			setChildrenCompanies([])
			setSendBolByEmail(false)
			setSendByEmail(false)
			setSendBySms(false)
			setSendWhenBooked(false)
			setSendWhenInTransit(false)
			setSendWhenDelivered(false)
			setResetRolesPermissions(true)
			reset()
		}
	}, [dispatch, setValue, isEdit, id, reset, config])

	const handleSaveNewUser = async (data) => {
		// console.log('user data', data);
		const localDataPhoneNumber = data?.phone_number ?? ''
		// console.log('child companies', childrenCompanies);
		if (
			!localDataPhoneNumber.match(/^([(]([0-9]{3})[)] ([0-9]{3})(-)([0-9]{4}))$/) &&
			localDataPhoneNumber.length > 0
		) {
			setErrorPhoneNumber('Please enter a valid phone number')
			return
		}

		const checkedPermissions = Object.keys(rolesPermissions).map((key) => ({
			name: key,
			is_active: rolesPermissions[key],
		}))

		const rolePermissionsArray = checkedPermissions.map((key) => {
			const rolePermissionObj = {}
			if (key.name.includes('Quote')) {
				rolePermissionObj.name = 'QUOTES'
			} else if (
				key.name.includes('Shipment') ||
				key.name.includes('Bol') ||
				key.name.includes('Label') ||
				key.name.includes('Support')
			) {
				rolePermissionObj.name = 'SHIPMENTS'
			} else if (key.name === 'createUsers' || key.name === 'deactivateUsers' || key.name === 'editUsers') {
				rolePermissionObj.name = 'FP_ADMIN_USERS'
			} else if (key.name === 'editAccounts' || key.name === 'createAccounts' || key.name === 'deactivateAccounts') {
				rolePermissionObj.name = 'FP_ADMIN_ACCOUNTS'
			} else if (key === 'showCarriersRate') {
				rolePermissionObj.name = 'FP_ADMIN_CARRIERS'
			} else if (key.name.includes('Carrier')) {
				rolePermissionObj.name = 'FP_ADMIN_CARRIERS'
			} else if (key.name.includes('UsersAdmin')) {
				rolePermissionObj.name = 'ADMIN_USERS'
			} else if (key.name.includes('AccountsAdmin')) {
				rolePermissionObj.name = 'ADMIN_ACCOUNTS'
			} else if (key.name.includes('Invoice')) {
				rolePermissionObj.name = 'INVOICES'
			} else if (key.name.includes('Report')) {
				rolePermissionObj.name = 'REPORTS'
			}

			if (key.name.includes('create')) {
				rolePermissionObj.action = 'CREATE'
			} else if (key.name.includes('view')) {
				rolePermissionObj.action = 'READ'
			} else if (key.name.includes('edit')) {
				rolePermissionObj.action = 'UPDATE'
			} else if (key.name.includes('showCarriersRate')) {
				rolePermissionObj.action = 'SHOW_RATE'
			} else if (key.name.includes('delete')) {
				rolePermissionObj.action = 'DELETE'
			} else if (key.name.includes('clone')) {
				rolePermissionObj.action = 'CLONE'
			} else if (key.name.includes('remove')) {
				rolePermissionObj.action = 'DELETE'
			} else if (key.name.includes('contact')) {
				rolePermissionObj.action = 'CONTACT_SUPPORT'
			} else if (key.name.includes('convert')) {
				rolePermissionObj.action = 'CONVERT_TO_SHIPMENT'
			} else if (key.name.includes('deactivate')) {
				rolePermissionObj.action = 'DEACTIVATE'
			} else if (key.name.includes('download')) {
				if (key.name.includes('Bol')) {
					rolePermissionObj.action = 'DOWNLOAD_BOL'
				} else if (key.name.includes('Label')) {
					rolePermissionObj.action = 'DOWNLOAD_LABEL'
				} else {
					rolePermissionObj.action = 'DOWNLOAD'
				}
			} else if (key.name.includes('reQuote')) {
				rolePermissionObj.action = 'RE_QUOTE'
			} else if (key.name.includes('send')) {
				rolePermissionObj.action = 'SEND_BY_EMAIL'
			}
			rolePermissionObj.is_active = key.is_active
			return rolePermissionObj
		})
		const childrenCompanyIds = childrenCompanies.map((company) => company.id)
		const formData = new FormData()
		if (userPhoto.length) {
			formData.append('file', userPhoto[0])
		}
		formData.append('user[role]', userRole)
		formData.append('user[name]', `${data.firstName} ${data.lastName}`)
		formData.append('user[account]', data.account)
		if (!isEdit) {
			formData.append('user[email]', data.email)
		}
		if (!isEdit) {
			formData.append('user[password_email_sent]', sendEmailPassword)
		}
		formData.append('user[phone_number]', data.phone_number)
		if (childrenCompanyIds && childrenCompanyIds.length > 0) {
			childrenCompanyIds.forEach((childCompany, index) => {
				formData.append(`user[children_companies][${index}]`, childCompany)
			})
		}
		formData.append('user[notification_send_bol]', sendBolByEmail)
		formData.append('user[notification_by_email]', sendByEmail)
		formData.append('user[notification_by_sms]', sendBySms)
		formData.append('user[notification_booked]', sendWhenBooked)
		formData.append('user[notification_intransit]', sendWhenInTransit)
		formData.append('user[notification_delivered]', sendWhenDelivered)
		rolePermissionsArray.forEach((permissions, index) => {
			Object.keys(permissions).forEach((key) => {
				formData.append(`roles_permissions[${index}][${key}]`, permissions[key])
			})
		})
		// console.log('config', config);
		const { status, resp } = isEdit
			? await dispatch(editUser(id, formData, config))
			: await dispatch(createUser(formData, config))
		if (status === 'error') {
			const usernameError = 'The user already exists. | auth0_idp_error'
			const emailError = 'Email already exists in the DB'
			if (resp === usernameError || resp === emailError) {
				sweetAlertModal(
					status,
					formTexts.errorSubmitText,
					'The email provided is in use already',
					'Ok',
					true,
					false,
					null,
				)
			} else {
				sweetAlertModal(status, formTexts.errorSubmitText, resp, 'Ok', true, false, null)
			}
		} else {
			sweetAlertModal(status, formTexts.successSubmitText, null, 'Ok', true, false, null)
			setValue('firstName', '')
			setValue('lastName', '')
			setValue('email', '')
			setPhoneNumber('')
			setChildrenCompanies([])
			setSendBolByEmail(false)
			setSendByEmail(false)
			setSendBySms(false)
			setSendWhenBooked(false)
			setSendWhenInTransit(false)
			setSendWhenDelivered(false)
			setResetRolesPermissions(true)
			if (isEdit) {
				if (parentCompany.id === Number(id)) {
					if (userPhoto[0]) dispatch(updateUserPhoto(URL.createObjectURL(userPhoto[0])))
				}
				history.push(usersListRoute)
			}
			if (isFromEditAccount) {
				handleClose()
				updateUsersTable()
			}
			setInfoTableFirstName('')
			setInfoTableLastName('')
			setInfoTableEmail('')
			setSendEmailPassword(true)
			reset()
		}
	}

	const handleCancelNewUser = () => {
		sweetAlertModal(
			'warning',
			'Are you sure you want to cancel the user creation process?',
			null,
			'Yes',
			true,
			true,
			'No',
		).then((result) => {
			if (result.isConfirmed) {
				if (isFromEditAccount) handleClose()
				else history.push(usersListRoute)
			}
		})
	}
	useEffect(() => {
		if (phoneNumber.length === 0) {
			setErrorPhoneNumber('')
		}
	}, [phoneNumber])

	return (
		<div className={classes.createUserWrapper}>
			<form
				className="new-user-form"
				onSubmit={handleSubmit(handleSaveNewUser)}
				onChange={() => {
					const values = getValues()
					setInfoTableFirstName(values?.firstName ?? '')
					setInfoTableLastName(values?.lastName ?? '')
					setInfoTableEmail(values?.email ?? '')
				}}
			>
				{!isFromEditAccount ? (
					<div className={classes.newUserHeader}>
						<ModuleHeader title={formTexts.title} subtitle={formTexts.subtitle} />
					</div>
				) : (
					<h1 className={classes.newUserTitle} style={{ textAlign: 'center', marginTop: '25px' }}>
						<ModuleHeader title={formTexts.title} />
					</h1>
				)}
				<div>
					<div className={classes.sectionContainer}>
						<BasicInformation
							register={register}
							errors={errors}
							control={control}
							setValue={setValue}
							userPhoto={userPhoto}
							setUserPhoto={setUserPhoto}
							phoneNumber={phoneNumber}
							setPhoneNumber={setPhoneNumber}
							childrenCompanies={childrenCompanies}
							setChildrenCompanies={setChildrenCompanies}
							isEdit={isEdit}
							editUserChildrenCompanies={editUserChildrenCompanies}
							isFromEditAccount={isFromEditAccount}
							errorPhoneNumber={errorPhoneNumber}
							accountData={accountData}
							setCompanyAccount={setAccount}
						/>
						<Grid xs={12} className={classes.containerCheckbox}>
							{!isEdit && (
								<FormControlLabel
									className={classes.checkBoxLabel}
									control={
										<Checkbox
											color="secondary"
											className={classes.checkBox}
											onChange={(event) => setSendEmailPassword(event.target.checked)}
											checked={sendEmailPassword}
										/>
									}
									label="Email login info to user"
								/>
							)}
						</Grid>
					</div>
					<div className={classes.notificationsContainer}>
						<div className={classes.notificationsIconTitle}>
							<div className={classes.notificationsIconContainer}>
								<InfoOutlinedIcon className={classes.notificationsIcon} />
							</div>
							<h3 className={classes.notificationsSectionTitle}>Notification Settings</h3>
						</div>
						{matches ? (
							<NotificationSettingsCard
								firstName={infoTableFirstName}
								lastName={infoTableLastName}
								phoneNumber={phoneNumber}
								email={infoTableEmail}
								sendBolByEmail={sendBolByEmail}
								setSendBolByEmail={setSendBolByEmail}
								sendByEmail={sendByEmail}
								setSendByEmail={setSendByEmail}
								sendBySms={sendBySms}
								setSendBySms={setSendBySms}
								sendWhenBooked={sendWhenBooked}
								setSendWhenBooked={setSendWhenBooked}
								sendWhenInTransit={sendWhenInTransit}
								setSendWhenInTransit={setSendWhenInTransit}
								sendWhenDelivered={sendWhenDelivered}
								setSendWhenDelivered={setSendWhenDelivered}
							/>
						) : (
							<NotificationSettingsTable
								firstName={infoTableFirstName}
								lastName={infoTableLastName}
								phoneNumber={phoneNumber}
								email={infoTableEmail}
								sendBolByEmail={sendBolByEmail}
								setSendBolByEmail={setSendBolByEmail}
								sendByEmail={sendByEmail}
								setSendByEmail={setSendByEmail}
								sendBySms={sendBySms}
								setSendBySms={setSendBySms}
								sendWhenBooked={sendWhenBooked}
								setSendWhenBooked={setSendWhenBooked}
								sendWhenInTransit={sendWhenInTransit}
								setSendWhenInTransit={setSendWhenInTransit}
								sendWhenDelivered={sendWhenDelivered}
								setSendWhenDelivered={setSendWhenDelivered}
							/>
						)}
					</div>
					<div className={classes.sectionContainer}>
						<RolesAndPermissions
							setRolesPermissions={setRolesPermissions}
							resetRolesPermissions={resetRolesPermissions}
							setResetRolesPermissions={setResetRolesPermissions}
							isEdit={isEdit}
							editUserRolesPermissions={editUserRolesPermissions}
							setUserRole={setUserRole}
							userRole={userRole}
						/>
					</div>
					<Grid container className={classes.buttonsRow}>
						<Button className={classes.cancelQuoteButton} onClick={handleCancelNewUser}>
							Cancel
						</Button>
						<Button
							className={classes.button}
							variant="contained"
							color="secondary"
							type="submit"
							onMouseDown={() => {
								setErrorPhoneNumber('')
							}}
						>
							{formTexts.submitButtonText}
						</Button>
					</Grid>
				</div>
			</form>
		</div>
	)
}

NewUserContainer.propTypes = {
	isFromEditAccount: PropTypes.bool,
	handleClose: PropTypes.func,
	updateUsersTable: PropTypes.func,
}

NewUserContainer.defaultProps = {
	isFromEditAccount: false,
	handleClose: () => {},
	updateUsersTable: () => {},
}

export default NewUserContainer
