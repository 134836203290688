import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
	checkBoxLabel: {
		fontFamily: 'Open Sans',
		fontSize: '14px',
		fontWeight: 600,
		color: '#2C2C2C',
	},
	checkBox: {
		color: '#CCD2D8',
	},
	notificationsCardContainer: {
		display: 'grid',
		rowGap: '8px',
	},
	card: {
		border: '1px solid #c9cfe4',
		borderradius: '4px',
		padding: '15px',
		backgroundcolor: '#fafbfc',
	},
	title: {
		fontWeight: '700',
		fontSize: '16px',
		color: '#2c2c2c',
		margin: '0 0 16px 0',
	},
	info: {
		margin: '0 0 8px 0',
		fontWeight: 400,
		fontSize: '14px',
		'& span': {
			fontWeight: 700,
			fontSize: '14px',
		},
	},
	hr: {
		border: '1px solid #c9cfe4',
		margin: '10px 0',
	},
	row: {
		display: 'grid',
		gridAutoFlow: 'column',
		gridTemplateColumns: 'repeat(2, 1fr)',
	},
}))

export default useStyles
