import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment'
import { TableRow, TableCell, Tooltip } from '@material-ui/core'
import { Visibility } from '@material-ui/icons'
import useStyles from './style'
import { addTab, setTabs } from '../../../../../redux/actions/MultiTabs.actions'
import createNewTab, { removeUnderScore } from '../../../../../utils/helpers'

const InvoiceDetailsRow = (props) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const history = useHistory()
	const currentTabs = useSelector((state) => state.multiTabs.tabs)
	const [tabsState, setTabsState] = useState(currentTabs)
	const { data } = props

	const dataOriginCity = data.origin_city !== '' ? `${data.origin_city},` : ''
	const dataOriginState = data.origin_state !== '' ? `${data.origin_state},` : ''
	const dataOriginZipCode = data.origin_zip_code !== '' ? `${data.origin_zip_code},` : ''

	const dataDestinationCity = data.destination_city !== '' ? `${data.destination_city},` : ''
	const dataDestinationState = data.destination_state !== '' ? `${data.destination_state},` : ''
	const dataDestinationZipCode = data.destination_zip_code !== '' ? `${data.destination_zip_code},` : ''

	const dataProNumber = data.pro_number !== undefined ? `${data.pro_number},` : ''

	function currencyFormat(num) {
		return `$${num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
	}

	const openInvoiceDetails = () => {
		const tabs = [...tabsState]
		const newTab = createNewTab(
			tabs.length,
			'Shipment Details',
			`/shipment-details/${data.shipment_id}`,
			'',
			'',
			{ shipment: data },
			true,
			true,
		)
		const newTabs = tabs.map((tab) => ({
			...tab,
			active: false,
		}))
		dispatch(addTab(newTab))
		newTabs.push(newTab)
		setTabsState(newTabs)
		dispatch(setTabs(newTabs))
		history.push(newTab.route)
	}

	return (
		<TableRow className={classes.tableRow} role="checkbox" tabIndex={-1} key={data.id}>
			<TableCell className={classes.tableCell}>
				<div className={classes.tableCellItems}>
					<span>BOL</span>
					{` ${data.bol}`}
				</div>
				<div className={classes.tableCellItems}>
					<span>PRO</span>
					{` ${dataProNumber}`}
				</div>
				<Tooltip
					title={
						<div className={classes.containerToltip}>
							<span>
								{removeUnderScore('creation_date: ')}
								<span>
									{data?.audit_creation_date ? moment(data.audit_creation_date).format('MM/DD/YYYY HH:mm:ss') : 'N/A'}
								</span>
							</span>
							<span>
								{removeUnderScore('update_date: ')}
								<span>
									{data?.audit_update_date ? moment(data.audit_update_date).format('MM/DD/YYYY HH:mm:ss') : 'N/A'}
								</span>
							</span>
							<span>
								{removeUnderScore('freight_charges: ')}
								<span>{data?.freight_charges ? `${currencyFormat(data.freight_charges)} USD` : 'N/A'}</span>
							</span>
							<span>
								{removeUnderScore('insurance_charges: ')}
								<span>{data?.insurance_charges ? `${currencyFormat(data.insurance_charges)} USD` : 'N/A'}</span>
							</span>
							<span>
								{removeUnderScore('shipment_number: ')}
								<span>{data?.shipment_number ?? 'N/A'}</span>
							</span>
						</div>
					}
					placement="bottom"
					enterTouchDelay={0}
				>
					<div className={classes.tableCellItems}>(Other references)</div>
				</Tooltip>
			</TableCell>
			<TableCell className={classes.tableCell}>
				{data.shipment_date && <span>{moment(data.shipment_date).format('MM/DD/YYYY')}</span>}
			</TableCell>
			<TableCell align="left" className={classes.tableCell}>
				{data.carrier_logo ? <img className={classes.invoiceCarrierImg} src={data.carrier_logo} alt="Carrier" /> : ''}
			</TableCell>
			<TableCell align="left" className={classes.tableCell}>
				<p className={classes.blockInfo}>{data.origin_company !== null ? <span>{data.origin_company}</span> : ''}</p>
				<p className={classes.blockInfo}>{`${dataOriginCity} ${dataOriginState} ${dataOriginZipCode}`}</p>
			</TableCell>
			<TableCell align="left" className={classes.tableCell}>
				<p className={classes.blockInfo}>{data.destination_company}</p>
				<p className={classes.blockInfo}>
					{`${dataDestinationCity} ${dataDestinationState} ${dataDestinationZipCode}`}
				</p>
			</TableCell>
			<TableCell align="left" className={classes.tableCell}>
				<p className={classes.blockInfo}>{`${data.shipment_total_weight} lb`}</p>
				<p className={classes.blockInfo}>
					{data.shipment_total_pieces}{' '}
					{data?.package_types?.length > 0 && (
						<span className={classes.packageType}>{`${data?.package_types[0]}`}</span>
					)}
				</p>
			</TableCell>
			<TableCell align="left" className={classes.tableCell}>
				<strong>{`${currencyFormat(data.total_amount)} USD`}</strong>
			</TableCell>
			<TableCell className={classes.tableCell}>
				<Visibility className={classes.viewIcon} onClick={openInvoiceDetails} />
			</TableCell>
		</TableRow>
	)
}

InvoiceDetailsRow.propTypes = {
	data: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default InvoiceDetailsRow
