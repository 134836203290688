import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	paper: {
		marginBottom: theme.spacing(2),
		border: `1px solid ${theme.palette.neutral.whiteLightGray}`,
		borderRadius: '6px 6px 0 0',
		overflow: 'hidden',
	},
	table: {
		minWidth: 750,
		'& tr:last-child td': {
			borderBottom: 0,
		},
	},
	tableRow: {
		'& th:first-child': {
			textAlign: 'center',
		},
		'& th:nth-child(2)': {
			paddingLeft: '9px',
		},
	},
	tableCell: {
		backgroundColor: theme.palette.neutral.whiteGray,
		fontSize: 12,
		fontWeight: theme.typography.fontWeightBold,
		lineHeight: 'normal',
	},
	tablePaginator: {
		fontSize: 12,
		color: theme.palette.neutral.black,
	},
	tablePaginatorToolbar: {
		minHeight: 38,
	},
	tablePaginatorActions: {
		'& .MuiIconButton-root': {
			padding: 8,
			'&:first-child': {
				paddingRight: 6,
			},
			'&:last-child': {
				paddingLeft: 6,
			},
			'& .MuiSvgIcon-root': {
				fontSize: 24,
			},
		},
	},
	tablePaginatorSelectIcon: {
		top: 'unset',
	},
	cursorPointer: {
		cursor: 'pointer',
	},
}))

export default useStyles
