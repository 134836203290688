import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	headerContainer: {
		padding: '16px 24px',
		background: theme.palette.neutral.whiteGray,
	},
	sectionContainer: {
		padding: '24px',
		display: 'block',
		gridAutoFlow: 'row',
		rowGap: '30px',
		[theme.breakpoints.down('md')]: {
			padding: '16px',
		},
	},
	sectionContainerLarge: {
		padding: '24px',
		display: 'block',
		gridAutoFlow: 'row',
		rowGap: '30px',
		minHeight: '70vh',
		[theme.breakpoints.down('md')]: {
			padding: '16px',
		},
	},
	sectionContainerGray: {
		backgroundColor: theme.palette.neutral.whiteGray,
	},
	listIcon: {
		borderRadius: '20px',
		padding: '4px',
		alignSelf: 'center',
	},
	sectionTitle: {
		display: 'flex',
		columnGap: '12px',
		alignItems: 'center',
		marginBottom: '26px',
		[theme.breakpoints.down('md')]: {
			alignItems: 'flex-start',
		},
	},
	sectionTitleContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '28px',
		height: '28px',
		borderRadius: '20px',
		color: theme.palette.primary.main,
	},
	sectionTitleIcon: {
		width: '20px',
		height: '20px',
		colorPrimary: theme.palette.primary.orange,
	},
	filterSection: {
		marginBottom: '16px',
	},
	newUserSectionTitle: {
		fontSize: '18px',
		fontWeight: theme.typography.fontWeightRegular,
		color: theme.palette.primary.main,
		margin: 0,
		lineHeight: '22px',
		[theme.breakpoints.down('md')]: {
			fontSize: '15px',
		},
	},
	innerContainer: {
		paddingLeft: '16px',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: 0,
		},
	},
	inputFileContainer: {
		position: 'relative',
		top: '-5px',
	},
	dragAndDropUploader: {
		width: '100%',
		height: '100%',
		opacity: 0,
		position: 'absolute',
		zIndex: 1,
		cursor: 'pointer',
	},
	uploadPhotoTitle: {
		fontSize: '12px',
		fontWeight: '400',
		marginRight: '8px',
		cursor: 'pointer',
	},
	avatar: {
		color: theme.palette.secondary.main,
		fontWeight: 700,
		backgroundColor: theme.palette.neutral.white,
		height: '50px',
		width: '50px',
	},
	avatarBorder: {
		border: '1px solid',
		borderColor: theme.palette.secondary.main,
	},
	badgePhoto: {
		'& .MuiBadge-badge': {
			width: '20px',
			height: '20px',
			transform: 'scale(1) translate(50%, 0%)',
			backgroundColor: theme.palette.secondary.light,
			color: theme.palette.neutral.white,
		},
	},
	formControl: {
		width: '100%',
		'& .MuiInputLabel-outlined': {
			backgroundColor: '#ffffff',
		},
	},
	groupSelect: {
		height: '40px',
		width: '100%',
	},
	textField: {
		fontSize: '14px',
		backgroundColor: 'white',
		height: 'auto',
		width: '100%',
		'& .MuiFormLabel-root': {
			fontSize: '14px',
		},
		'& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
			display: 'none',
			margin: 80,
		},
	},
	buttonsRow: {
		justifyContent: 'flex-end',
		padding: '20px 24px 20px 110px',
		backgroundColor: theme.palette.neutral.white,
		position: 'fixed',
		bottom: 0,
		left: 0,
		right: 0,
		zIndex: 100,
		[theme.breakpoints.down('md')]: {
			padding: '16px 16px 16px 114px',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '16px 16px',
		},
	},
	actionButton: {
		textTransform: 'inherit',
		height: '50px',
		fontWeight: theme.typography.fontWeightMedium,
		minWidth: '170px',
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
	cancelButton: {
		backgroundColor: '#FAFBFC',
		marginRight: '16px',
		[theme.breakpoints.down('md')]: {
			marginRight: 0,
			marginLeft: 0,
			marginBottom: '10px',
		},
	},
	saveButton: {
		padding: '0 40px',
	},
	sectionCarrierTitle: {
		fontWeight: theme.typography.fontWeightRegular,
		color: theme.palette.primary.main,
		margin: 0,
		lineHeight: '28px',
	},
	iconTitle: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: '16px',
		'& svg': {
			fontSize: '32px',
			marginRight: '10px',
		},
	},
	contractServiceBox: { border: '1px solid black', borderRadius: '5px', padding: '15px' },
	contractServiceFormGroupContainer: { display: 'flex' },
}))

export default useStyles
