import React, { useState } from 'react'
import ChipInput from 'material-ui-chip-input'
import PropTypes from 'prop-types'
import { Dialog, Button, DialogTitle } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import useStyles from './styles'
import { sendEmailQuote } from '../../../../redux/actions/Quotes.actions'
import sweetAlertModal from '../../../../components/SweetAlertModal/SweetAlertModal'

const SendQuotesModal = (props) => {
	const { quoteId, rateId, showEmailModal, setShowEmailModal } = props
	const classes = useStyles()
	const dispatch = useDispatch()
	const [emails, setEmails] = useState([])

	const sendEmail = async () => {
		const payload = {
			emails,
		}

		const { status } = await dispatch(sendEmailQuote(quoteId, rateId, payload))
		if (status === 'error') {
			sweetAlertModal(status, 'There was an error sending the emails!', null, 'Ok', true, false, null)
		} else {
			sweetAlertModal(status, 'The quote PDF was sent successfully!', null, 'Ok', true, false, null)
			setEmails([])
			setShowEmailModal(false)
		}
	}

	return (
		<Dialog
			open={showEmailModal}
			disableBackdropClick
			disableEscapeKeyDown
			onClick={(event) => event.stopPropagation()}
			aria-labelledby="form-dialog-title"
			PaperProps={{
				className: classes.paper,
			}}
		>
			<DialogTitle disableTypography className={classes.title}>
				<h1>Send Quote</h1>
			</DialogTitle>
			<span className={classes.text}>Enter an email to send the selected quote</span>
			<ChipInput
				label="Emails"
				variant="outlined"
				color="secondary"
				classes={{ chipContainer: classes.chipContainer }}
				className={classes.chipInput}
				newChipKeys={['Enter', 'Tab', ' ', ',']}
				defaultValue={emails}
				onChange={(newEmails) => setEmails(newEmails)}
			/>
			<Button className={classes.goButton} onClick={sendEmail} color="secondary" variant="contained">
				Send Email
			</Button>
			<Button
				className={classes.cancelButton}
				onClick={() => {
					setEmails([])
					setShowEmailModal(false)
				}}
				color="primary"
				variant="contained"
			>
				Cancel
			</Button>
		</Dialog>
	)
}

SendQuotesModal.propTypes = {
	showEmailModal: PropTypes.bool.isRequired,
	setShowEmailModal: PropTypes.func.isRequired,
	quoteId: PropTypes.number.isRequired,
	rateId: PropTypes.number.isRequired,
}

export default SendQuotesModal
