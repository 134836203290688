import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	container: {
		borderWidth: 1,
		borderStyle: 'dashed',
		borderColor: theme.palette.neutral.mainGray,
		backgroundColor: theme.palette.neutral.secondaryGrey,
		borderRadius: 2,
		fontSize: theme.typography.body2.fontSize,
		color: theme.palette.neutral.black,
		transition: 'border ease-in 300ms',
		cursor: 'pointer',
		'&.hasError': {
			borderWidth: 1,
			borderStyle: 'solid',
			borderColor: theme.palette.primary.main,
		},
		'&.isValid': {
			borderWidth: 1,
			borderStyle: 'solid',
			borderColor: theme.palette.semantic.green.primary,
		},
	},
	grid: {
		pointerEvents: 'none',
	},
	success: {
		color: theme.palette.semantic.green.primary,
	},
	hiddenInput: {
		display: 'none',
	},
}))

export default useStyles
