/* eslint-disable prefer-destructuring */
const destructuringName = (name) => {
	const separateName = name.split(' ')
	const fullName = {
		name: '',
		lastName: '',
	}
	switch (separateName.length) {
		case 2:
			fullName.name = separateName[0]
			fullName.lastName = separateName[1]
			break
		case 3:
			fullName.name = separateName[0]
			fullName.lastName = `${separateName[1]} ${separateName[2]}`
			break
		case 4:
			fullName.name = `${separateName[0]} ${separateName[1]}`
			fullName.lastName = `${separateName[2]} ${separateName[3]}`
			break
		default:
			fullName.name = name
			fullName.lastName = ''
			break
	}
	return fullName
}

export default destructuringName
