import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, TextField, IconButton, InputAdornment } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

const SearchBar = ({ placeholder, onChangeHandler, value, autoFocus, size }) => {
	const sizeBox = {
		small: '42px',
		default: '56px',
	}
	const useStyles = makeStyles((theme) => ({
		searchBar: {
			width: '100%',
			fontSize: '16px',
			height: sizeBox[size],
			padding: '0',
			background: 'white',
			// border: '1px solid red',
			'&::placeholder': {
				...theme.typography.body1,
				opacity: 0.5,
			},
		},
		icon: {
			color: theme.palette.secondary.main,
		},
	}))
	const classes = useStyles()

	return (
		<TextField
			label=" "
			className={classes.searchBar}
			placeholder={placeholder}
			variant="outlined"
			size="small"
			autoFocus={autoFocus}
			value={value}
			onChange={onChangeHandler}
			InputProps={{
				notched: false,
				classes: { input: classes.searchBar },
				startAdornment: (
					<InputAdornment>
						<IconButton edge="start">
							<SearchIcon color="action" />
						</IconButton>
					</InputAdornment>
				),
			}}
		/>
	)
}

SearchBar.propTypes = {
	placeholder: PropTypes.string.isRequired,
	onChangeHandler: PropTypes.func,
	value: PropTypes.string.isRequired,
	autoFocus: PropTypes.bool,
	size: PropTypes.string,
}

SearchBar.defaultProps = {
	size: 'small',
	autoFocus: false,
	onChangeHandler: () => {},
}

export default SearchBar
