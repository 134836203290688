/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined'
import VisibilityIcon from '@material-ui/icons/Visibility'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined'
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import SessionContext from '../../../../../context/session'
import useStyles from './style'
import { accountDetailsRoute, accountEditRoute, permissionName } from '../../../../../utils/constants'
import isPermit from '../../../../../utils/permission'
import { editAccountStatus, fetchAccounts } from '../../../../../redux/actions/Accounts.actions'
import sweetAlertModal from '../../../../../components/SweetAlertModal/SweetAlertModal'
import { getListCompaniesByUser } from '../../../../../redux/actions/CompanyByUser.actions'

const MenuList = ({ anchorEl, open, handleClose, accountId, isActive }) => {
	const classes = useStyles()
	const history = useHistory()
	const dispatch = useDispatch()
	const { getUserData } = useContext(SessionContext)
	const { actualCompany, permissions } = getUserData()
	const { accountsFilters } = useSelector((state) => state)

	const goToAccountDetails = () => {
		history.push(`${accountDetailsRoute.replace(':id', accountId)}`)
	}
	const goToAccountEdit = () => {
		history.push(`${accountEditRoute.replace(':id', accountId)}`)
	}

	const handleAccountStatusEdit = async () => {
		const config = {
			headers: { 'X-Company-id': Number(actualCompany) },
		}
		const { status } = await dispatch(editAccountStatus({ id: Number(accountId), status: !isActive }, config))
		if (status === 'error') {
			sweetAlertModal('error', 'There was an error updating the company status', null, 'Ok', true, false)
			handleClose()
			return
		}
		const params = {
			offset: accountsFilters.currentPage,
			limit: accountsFilters.rowsPerPage,
			search_value: accountsFilters.searchBar,
			is_active: accountsFilters.status,
			order_by: accountsFilters.orderBy,
			order_type: accountsFilters.orderType,
		}
		Object.keys(params).forEach((key) => params[key] === '' && delete params[key])
		const qs = Object.keys(params)
			.map((key) => `${key}=${params[key]}`)
			.join('&')
		dispatch(fetchAccounts(`?${qs}`, config))
		await dispatch(getListCompaniesByUser())
	}

	return (
		<Menu id="accounts-item-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
			<MenuItem dense onClick={goToAccountDetails}>
				<ListItemIcon className={classes.listItemIcon}>
					<VisibilityIcon color="secondary" size="small" />
				</ListItemIcon>
				<ListItemText primary="View account information" />
			</MenuItem>
			{isPermit(permissions, permissionName.FP_ADMIN_ACCOUNTS_UPDATE) && (
				<MenuItem dense onClick={goToAccountEdit}>
					<ListItemIcon className={classes.listItemIcon}>
						<CreateOutlinedIcon color="secondary" size="small" />
					</ListItemIcon>
					<ListItemText primary="Edit account information" className={classes.listItemText} />
				</MenuItem>
			)}
			{isActive ? (
				isPermit(permissions, permissionName.FP_ADMIN_ACCOUNTS_DEACTIVATE) && (
					<MenuItem dense onClick={handleAccountStatusEdit}>
						<ListItemIcon className={classes.listItemIcon}>
							<NotInterestedIcon color="error" size="small" />
						</ListItemIcon>
						<ListItemText primary="Deactivate account" className={classes.listItemText} />
					</MenuItem>
				)
			) : (
				<MenuItem dense onClick={handleAccountStatusEdit}>
					<ListItemIcon className={classes.listItemIcon}>
						<CheckCircleOutlinedIcon className={classes.iconGreen} size="small" />
					</ListItemIcon>
					<ListItemText primary="Activate account" className={classes.listItemText} />
				</MenuItem>
			)}
		</Menu>
	)
}

MenuList.propTypes = {
	anchorEl: PropTypes.objectOf(PropTypes.any),
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	accountId: PropTypes.string.isRequired,
	isActive: PropTypes.bool.isRequired,
}

MenuList.defaultProps = { anchorEl: null }

export default MenuList
