import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ResponsivePie } from '@nivo/pie'
import {
	useTheme,
	Box,
	Button,
	Card,
	CardHeader,
	CardMedia,
	IconButton,
	Tooltip,
	useMediaQuery,
} from '@material-ui/core'

import SessionContext from '../../../../context/session'
import useStyles from './styles'
import { DownloadIcon } from '../../../../assets/Icons/Icons'
import NoResultsFound from '../../../../components/NoResultsFound/NoResultsFound'

const PieChart = ({ data, title, filters }) => {
	const classes = useStyles()
	const [chartData, setChartData] = useState([])
	const theme = useTheme()
	const { getUserData, getTokenUser } = useContext(SessionContext)
	const { actualCompany } = getUserData()
	const token = getTokenUser()
	const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))

	const chartOptions = {
		animate: true,
		activeInnerRadiusOffset: 0,
		activeOuterRadiusOffset: 8,
		borderWidth: 0,
		colors: [
			theme.palette.semantic.green.primary,
			theme.palette.semantic.yellow.primary,
			theme.palette.semantic.orange.primary,
			theme.palette.semantic.orange.light,
			theme.palette.semantic.purple.primary,
			theme.palette.neutral.mainGray,
		],
		cornerRadius: 0,
		enableArcLabels: false,
		enableArcLinkLabels: false,
		innerRadius: 0.65,
		isInteractive: true,
		margin: {
			top: 8,
			right: 8,
			bottom: 8,
			left: 8,
		},
		motionConfig: 'gentle',
		sortByValue: true,
		startAngle: -180,
		transitionMode: 'innerRadius',
		tooltip: (e) => {
			const { datum } = e
			return (
				<div className={classes.tooltip}>
					<strong>{`${datum.label.toUpperCase()}: `}</strong>
					<span>{`${datum.value}%`}</span>
				</div>
			)
		},
	}

	const handleDownloadCSV = () => {
		const p = {
			initial_date: filters.initialDate,
			end_date: filters.endDate,
			status: 'DELIVERED',
		}
		const qs = Object.keys(p)
			.map((key) => `${key}=${p[key]}`)
			.join('&')
		const url = `${process.env.REACT_APP_BACKEND_REPORTS_BASE_URL}/report/shipment/dashboard-graphs?${qs}`

		const oReq = new XMLHttpRequest()
		oReq.open('GET', url, true)
		oReq.responseType = 'arraybuffer'
		oReq.setRequestHeader('X-Company-id', Number(actualCompany))
		oReq.setRequestHeader('Authorization', `Bearer ${token}`)

		oReq.onload = function dowload() {
			const blob = new Blob([oReq.response], { type: 'text/csv' })
			const URL = window.URL || window.webkitURL
			const dataUrl = URL.createObjectURL(blob)
			const downloadLink = document.createElement('a')
			const fileName = 'carrier-usage-report.csv'

			downloadLink.href = dataUrl
			downloadLink.download = fileName
			downloadLink.click()
		}

		oReq.send()
	}

	useEffect(() => {
		setChartData([])
		if (data.length) {
			const remainingValue =
				100 -
				data
					.map(
						// eslint-disable-next-line camelcase
						({ percentage_use }) => Number(percentage_use),
					)
					.reduce((a, b) => a + b)
			// eslint-disable-next-line camelcase
			const computedData = data.map(({ carrier_scac, percentage_use }) => ({
				id: carrier_scac,
				label: carrier_scac,
				value: parseFloat(Number(percentage_use)),
			}))
			if (remainingValue > 0) {
				computedData.push({
					id: 'others',
					label: 'others',
					value: remainingValue % 1 !== 0 ? remainingValue.toFixed(2) : remainingValue,
				})
			}
			setChartData(computedData)
		}
	}, [data])

	return (
		<Card>
			<CardHeader
				classes={{
					root: classes.cardHeader,
					title: classes.cardHeaderTitle,
					action: classes.cardHeaderAction,
				}}
				title={title}
				action={
					!matchesSM ? (
						<Button
							size="small"
							className={classes.cardHeaderActionButton}
							startIcon={<DownloadIcon />}
							onClick={() => handleDownloadCSV()}
							disabled={!data.length}
						>
							Download as CSV
						</Button>
					) : (
						<Tooltip title="Download as CSV">
							<IconButton arial-label="Download CSV" size="small" className={classes.cardHeaderActionButton}>
								<DownloadIcon />
							</IconButton>
						</Tooltip>
					)
				}
			/>
			{chartData.length > 0 && (
				<CardMedia className={classes.cardMedia}>
					<Box className={classes.chartWrapper}>
						<ResponsivePie
							data={chartData}
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...chartOptions}
						/>
					</Box>
					<Box className={classes.legendWrapper}>
						<ul className={classes.legendList}>
							{chartData.map(({ label, value, id }, i) => (
								<li key={id} className={classes.legendListItem}>
									<div className={classes.legendListBox} style={{ backgroundColor: chartOptions.colors[i] }} />
									<div className={classes.legendListLabel}>{label}</div>
									<div className={classes.legendListValue}>{`${value}%`}</div>
								</li>
							))}
						</ul>
					</Box>
				</CardMedia>
			)}
			{chartData.length === 0 && <NoResultsFound type="Information" />}
		</Card>
	)
}

PieChart.propTypes = {
	data: PropTypes.arrayOf(PropTypes.any).isRequired,
	title: PropTypes.string.isRequired,
	filters: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default PieChart
