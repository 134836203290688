import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'
import { Grid, IconButton } from '@material-ui/core'
import { FilterList, Loop, BackupOutlined } from '@material-ui/icons'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'

import useLocationsFilters from '../../../../../hooks/useLocationsFilters'
import {
	setCurrentPage,
	setRowsPerPage,
	setSearchBar,
	resetFilters,
	setSearchByLabel,
	setOrderByAndOrder,
} from '../../../../../redux/actions/LocationsFilters.action'
import { fetchLocations } from '../../../../../redux/actions/Locations.actions'
import { setTabCurrentRoute, setTabTitle } from '../../../../../redux/actions/MultiTabs.actions'
import { clearQuoteDetailsForReQuote } from '../../../../../redux/actions/QuoteDetails.actions'
import { locationsListRoute } from '../../../../../utils/constants'
import { searchByOptions } from '../../../../../utils/dropDownOptions'

import SearchBar from '../../components/SearchBar'
import TableList from '../../components/TableList'
import sweetAlertModal from '../../../../../components/SweetAlertModal/SweetAlertModal'
import UploadLocationsModal from './components/UploadLocationsModal/UploadLocationsModal'

import useStyles from './styles'

const MyLocations = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const matches = useMediaQuery('(min-width:1024px)')
	const [buildParamsFromState] = useLocationsFilters()
	const [open, setOpen] = useState(false)
	const searchBy = useSelector((state) => state.locationsFilters.searchByLabel)
	const [searchByValue, setSearchByValue] = useState(searchBy)
	const [orderByLabel, setOrderByLabel] = useState('DESCRIPTION')
	const [order, setOrder] = useState('ASC')
	const [pageReset, setPageReset] = useState(false)
	const [search, setSearch] = useState('')
	const [modalOpenMassUploadLocations, setModalOpenMassUploadLocations] = useState(false)

	const LocationsProvider = () => {
		const callback = useCallback(async (params) => {
			const { status } = await dispatch(fetchLocations(params))
			if (status === 'error')
				sweetAlertModal('error', 'There was an error fetching the locations', null, 'Ok', true, false)
		}, [])

		return { callback }
	}

	const { callback } = LocationsProvider()
	const filterParams = buildParamsFromState()

	useEffect(() => {
		dispatch(setTabCurrentRoute(locationsListRoute))
		dispatch(setTabTitle('Locations List'))
	}, [dispatch])

	useEffect(() => {
		callback(filterParams)
	}, [callback, filterParams])

	useEffect(() => {
		const preservedFilters = localStorage.getItem('preserved-location-filters')
		if (preservedFilters) {
			const filterObj = preservedFilters.split('&')
			filterObj.forEach((filter) => {
				const filterKeyValue = filter.split('=')
				switch (filterKeyValue[0]) {
					case 'search_bar':
						dispatch(setSearchBar(filterKeyValue[1]))
						setSearch(decodeURIComponent(filterKeyValue[1]))
						break
					case 'search_label':
						dispatch(setSearchByLabel(filterKeyValue[1]))
						setSearchByValue(filterKeyValue[1])
						break
					case 'order_label':
						dispatch(setOrderByAndOrder({ orderBy: filterKeyValue[1], order }))
						setOrderByLabel(filterKeyValue[1])
						break
					case 'order_type':
						dispatch(setOrderByAndOrder({ orderBy: orderByLabel, order }))
						setOrder(filterKeyValue[1])
						break
					default:
						dispatch(setCurrentPage(filterKeyValue[0].split(/[/?]/)[1]))
						dispatch(setRowsPerPage(filterKeyValue[0].split(/[/?]/)[2]))
						break
				}
			})
		}
	}, [dispatch, orderByLabel, order])

	useEffect(() => {
		dispatch(clearQuoteDetailsForReQuote(''))
	}, [dispatch])

	const toggleDrawer = (openDrawer) => (event) => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return
		}
		setOpen(openDrawer)
	}

	const showFilters = () => {
		setOpen(true)
	}

	const onKeyDown = (event) => {
		if (event.keyCode === 13) {
			if (event.target.value) {
				dispatch(setSearchBar(encodeURIComponent(event.target.value)))
				setSearch(event.target.value)
				setPageReset(true)
				dispatch(setCurrentPage(0))
			}
		} else if (!event.target.value) {
			dispatch(setSearchBar(''))
		}
	}

	const handleResetFilters = () => {
		dispatch(resetFilters())
		setPageReset(true)
		setSearch('')
		setSearchByValue('')
		localStorage.removeItem('preserved-location-filters')
	}

	const handleSortColumn = (column) => {
		setOrderByLabel(column)
		const newOrder = order === 'ASC' ? 'DESC' : 'ASC'
		setOrder(newOrder)
		dispatch(setOrderByAndOrder({ orderBy: column, order: newOrder }))
	}

	const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

	return (
		<>
			<div className={classes.locationsHistoryTitleButton}>
				<h1 className={classes.locationsHistoryTitle}>Locations</h1>
				<Button
					className={classes.headerActionButton}
					startIcon={<BackupOutlined color="secondary" />}
					size="small"
					color="primary"
					onClick={() => setModalOpenMassUploadLocations(true)}
				>
					Upload Multiple Locations
				</Button>
				<IconButton aria-label="Toggle Filters" onClick={showFilters} className={classes.filterTriggerButton}>
					<FilterList color="secondary" />
				</IconButton>
			</div>
			<Button
				className={classes.headerActionButton2}
				startIcon={<BackupOutlined />}
				size="small"
				color="primary"
				onClick={() => setModalOpenMassUploadLocations(true)}
			>
				Upload Multiple Locations
			</Button>
			<p className={classes.locationsHistoryDescription}>Consult the locations created in the system.</p>
			<SwipeableDrawer
				className={classes.drawer}
				anchor="top"
				open={open}
				onOpen={toggleDrawer(open, true)}
				onClose={toggleDrawer(false)}
				disableBackdropTransition={!iOS}
				disableDiscovery={iOS}
			>
				<div className={classes.filterFormWrapper} role="presentation">
					<Grid container spacing={2} alignItems="center" style={{ padding: '15px 8px 0 10px' }}>
						<FormControl variant="outlined" style={{ width: '100%' }}>
							<InputLabel className={classes.selectChildInput} htmlFor="company-dialog-select">
								Search by
							</InputLabel>
							<Select
								value={searchByValue}
								label="searchBy"
								onChange={(e) => {
									setSearchByValue(e.target.value)
									dispatch(setSearchByLabel(e.target.value))
								}}
							>
								{searchByOptions.map((item, index) => (
									<MenuItem value={item.value} key={`id-${index + 1}`}>
										{item.label}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<Grid item xs={12} style={{ padding: '20px 0 0 0' }}>
							<Button
								onClick={handleResetFilters}
								color="primary"
								startIcon={<Loop color="secondary" />}
								style={{ textTransform: 'none' }}
								className={classes.filterButtonModal}
							>
								<span className={classes.filterButtonModalText}>Reset Search</span>
							</Button>
						</Grid>
					</Grid>
				</div>
			</SwipeableDrawer>
			<Grid container spacing={2} alignItems="center">
				{matches && (
					<>
						<Grid item xs={12} md={3}>
							<FormControl size="small" variant="outlined" style={{ width: '100%', marginBottom: '0' }}>
								<InputLabel className={classes.selectChildInput} htmlFor="company-dialog-select">
									Search by
								</InputLabel>
								<Select
									value={searchByValue}
									label="searchBy"
									onChange={(e) => {
										setSearchByValue(e.target.value)
										dispatch(setSearchByLabel(e.target.value))
									}}
								>
									{searchByOptions.map((item, index) => (
										<MenuItem value={item.value} key={`id-${index + 1}`}>
											{item.label}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					</>
				)}
				<Grid item xs={12} md={12} lg={7}>
					<SearchBar value={search} autoFocus placeholder="Search..." onKeyDown={onKeyDown} />
				</Grid>
				{matches && (
					<Grid item xs={2} md={2}>
						<Button
							onClick={handleResetFilters}
							color="primary"
							startIcon={<Loop color="secondary" />}
							className={classes.filterButton}
						>
							<span className={classes.filterButtonText}>Reset Search</span>
						</Button>
					</Grid>
				)}
			</Grid>
			<TableList pageReset={pageReset} handleSortColumn={handleSortColumn} />
			{modalOpenMassUploadLocations && (
				<UploadLocationsModal
					open={modalOpenMassUploadLocations}
					handleClose={() => setModalOpenMassUploadLocations(false)}
					isViewLocations
				/>
			)}
		</>
	)
}

export default MyLocations
