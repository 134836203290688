import React from 'react'
import PropTypes from 'prop-types'

import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TablePagination,
	TableRow,
	Paper,
	CircularProgress,
} from '@material-ui/core'

import { usersNewAccountTableCells } from '../../../../../../../utils/headCells'

import TableHeader from '../TableHeader/TableHeader'
import TableRowBody from '../TableRow/TableRow'
import NoResultsFound from '../../../../../../../components/NoResultsFound'
import TableSlider from '../TableSlider/TableSlider'

import useStyles from './styles'

const TableList = ({ data, isLoading, config, handleConfig, totalData }) => {
	const classes = useStyles()

	const renderTableBody = () => {
		let rows = []
		if (isLoading) {
			return (
				<TableRow>
					<TableCell align="center" colSpan={Object.keys(usersNewAccountTableCells).length}>
						<CircularProgress />
					</TableCell>
				</TableRow>
			)
		}
		rows = data.map((row) => <TableRowBody key={row.id} data={row} />)
		if (data.length === 0) {
			return (
				<TableRow>
					<TableCell align="center" colSpan={Object.keys(usersNewAccountTableCells).length + 1}>
						<NoResultsFound type="Users" />
					</TableCell>
				</TableRow>
			)
		}
		return rows
	}

	return (
		<div className={classes.root}>
			<Paper className={classes.paper} elevation={0}>
				<TableContainer>
					<Table className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
						<TableHeader />
						<TableBody>{renderTableBody()}</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					classes={{
						root: classes.tablePaginator,
						toolbar: classes.tablePaginatorToolbar,
						actions: classes.tablePaginatorActions,
					}}
					rowsPerPageOptions={[10, 20, 50, 100]}
					component="div"
					count={totalData}
					rowsPerPage={config.rowsPerPage}
					page={config.page}
					onChangePage={(e, num) => handleConfig(num, 'page')}
					onChangeRowsPerPage={(e) => handleConfig(+e.target.value, 'rowsPerPage')}
				/>
			</Paper>
			<TableSlider childAccounts={data} isLoading={isLoading} />
		</div>
	)
}

TableList.propTypes = {
	totalData: PropTypes.number.isRequired,
	data: PropTypes.arrayOf(PropTypes.any).isRequired,
	isLoading: PropTypes.bool.isRequired,
	config: PropTypes.objectOf(PropTypes.any).isRequired,
	handleConfig: PropTypes.func.isRequired,
}

export default TableList
