import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	sectionTitle: {
		fontWeight: theme.typography.fontWeightRegular,
		color: theme.palette.primary.main,
		margin: 0,
		lineHeight: '22px',
	},
	icon: {
		width: '20px',
		height: '20px',
		borderRadius: '20px',
		padding: '4px',
		alignSelf: 'start',

		'@media only screen and (min-width: 768px)': {
			width: '24px',
			height: '24px',
			alignSelf: 'center',
		},
	},
	iconDownload: {
		width: '24px',
		marginRight: '10px',
	},
	instructions: {
		margin: '0 0 5px 0',
		'& span': {
			fontWeight: theme.typography.fontWeightMedium,
		},
	},
	downloadButton: {
		marginTop: '16px',

		[theme.breakpoints.up('sm')]: {
			display: 'flex',
			justifyContent: 'flex-end',
			marginTop: '9px',
			padding: '0 23px 0 61px',
		},
	},
	buttonLabel: {
		margin: 0,
		textTransform: 'none',
	},
	buttonDownloadBOL: {
		marginTop: '16px',

		[theme.breakpoints.up('sm')]: {
			marginLeft: '30px',
			marginTop: 0,
		},
	},
	notificationsContainer: {
		backgroundColor: theme.palette.neutral.whiteGray,
		padding: '18px 16px 20px 16px',

		[theme.breakpoints.up('sm')]: {
			padding: 24,
		},
	},
}))

export default useStyles
