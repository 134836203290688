import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
	FormControl,
	InputLabel,
	Select,
	RadioGroup,
	FormControlLabel,
	MenuItem,
	TextField,
	Grid,
	Radio,
	Button,
	Tooltip,
	IconButton,
	Collapse,
} from '@material-ui/core'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import AddNewIcon from '@material-ui/icons/Add'
import { detailsPackageTypeOptions } from '../../../../../utils/dropDownOptions'
import {
	deleteTemplateItemDetail,
	fetchTemplateItemById,
	createTemplateItemDetail,
} from '../../../../../redux/actions/TemplateItems.actions'
import { capitalizeFirstLetter } from '../../../../../utils/helpers'
import { HelpIcon } from '../../../../../assets/Icons/Icons'
import sweetAlertModal from '../../../../../components/SweetAlertModal'
import useStyles from './styles'

const NewItemDetails = (props) => {
	const { detailsList, setDetailsList, isDetailed, setIsDetailed, templateItemId } = props
	const classes = useStyles()
	const [commodity, setCommodity] = useState('')
	const [quantity, setQuantity] = useState('')
	const [quantityError, setQuantityError] = useState(false)
	const [packageTypeError, setPackageTypeError] = useState(false)
	const [packageType, setPackageType] = useState('')
	const [isDetailedLabel, setIsDetailedLabel] = useState('no')

	const dispatch = useDispatch()

	useEffect(() => {
		if (isDetailed) {
			setIsDetailedLabel('yes')
		} else {
			setIsDetailedLabel('no')
		}
	}, [isDetailed])

	const { register, errors, control } = useForm()

	const handleAddDetails = async () => {
		if (!packageType && !quantity) {
			setPackageTypeError(true)
			setQuantityError(true)
			return
		}
		if (!quantity) {
			setQuantityError(true)
			setPackageTypeError(false)
			return
		}
		if (!packageType) {
			setQuantityError(false)
			setPackageTypeError(true)
			return
		}

		const newItem = {
			packageType,
			quantity,
			description: commodity,
		}

		if (templateItemId) {
			const { status, resp } = await dispatch(createTemplateItemDetail(templateItemId, newItem))
			if (status === 'error') {
				sweetAlertModal('error', 'There was an error adding the item detail', resp.err.message, 'Ok', true, false)
			} else {
				sweetAlertModal(status, resp.data.message, null, 'Ok', true, false, null)
				const { status: state, resp: response } = await dispatch(fetchTemplateItemById(templateItemId))
				if (state === 'error') {
					sweetAlertModal(state, 'There was an error fetching the item detail', null, 'Ok', true, false, null)
				} else {
					const { item_details: itemDetails } = response
					const newitemDetails = itemDetails.map((itemDetail) => {
						const newItemDetail = {
							id: itemDetail.id,
							package_type: itemDetail.package_type,
							quantity: itemDetail.quantity,
							description: itemDetail.description,
						}
						return newItemDetail
					})
					setDetailsList(newitemDetails)
				}
			}
		} else {
			setDetailsList(detailsList.concat(newItem))
		}
		setCommodity('')
		setQuantity('')
		setPackageType('')
	}

	const deleteItem = async (itemIndex, itemId) => {
		if (templateItemId) {
			const message = 'Are you sure you want to delete this item detail?'
			sweetAlertModal(
				null,
				'<p class="custom-title"><span class="icon-title"></span><span>Delete item?</span></p>',
				message,
				'Delete item',
				false,
				true,
				'Go back',
			).then((result) => {
				if (result.isConfirmed) {
					;(async () => {
						const { status, resp } = await dispatch(deleteTemplateItemDetail(templateItemId, itemId))
						if (status === 'error') {
							sweetAlertModal('error', 'There was an error removing the item detail', null, 'Ok', true, false)
						} else {
							sweetAlertModal(status, resp.data.message, null, 'Ok', true, false, null)
							const { status: state, resp: response } = await dispatch(fetchTemplateItemById(templateItemId))
							if (state === 'error') {
								sweetAlertModal(state, 'There was an error fetching the item details', null, 'Ok', true, false, null)
							} else {
								const { item_details: itemDetails } = response
								const newitemDetails = itemDetails.map((itemDetail) => {
									const newItemDetail = {
										id: itemDetail.id,
										packageType: itemDetail.package_type,
										quantity: itemDetail.quantity,
										description: itemDetail.description,
									}
									return newItemDetail
								})
								setDetailsList(newitemDetails)
							}
						}
					})()
				}
			})
		} else {
			const newInputs = [...detailsList]
			newInputs.splice(itemIndex, 1)
			setDetailsList(newInputs)
			sweetAlertModal('success', 'Item was deleted successfully', null, 'Ok', true, false)
		}
	}

	const handleChangeDetails = (value) => {
		setIsDetailedLabel(value)
		if (value === 'yes') {
			setIsDetailed(true)
			return
		}
		setIsDetailed(false)
		setDetailsList([])
		setCommodity('')
		setQuantity('')
		setPackageType('')
	}

	return (
		<>
			<div className={classes.newItemDetailContainer}>
				<Grid className={classes.newItemHeaderDetailContainer}>
					<div className={classes.newItemHeaderLabelContainer}>
						<span className={classes.newItemDetailLabel}>Do you want to add details for this item:</span>
						<Tooltip
							title="To specify package contents, enter details below. For example, if you’re shipping 1 pallet of widgets, you could indicate below that the pallet contains 10 boxes of orange widgets and 15 boxes of green widgets. This can be helpful in the event of loss or damage."
							placement="bottom-start"
						>
							<IconButton size="small">
								<HelpIcon fontSize="small" style={{ color: 'black' }} />
							</IconButton>
						</Tooltip>
					</div>
					<RadioGroup
						className={classes.newItemRadioContainer}
						aria-label="details"
						name="details"
						value={isDetailedLabel}
						onChange={(event) => handleChangeDetails(event.target.value)}
					>
						<FormControlLabel value="yes" control={<Radio color="secondary" size="small" />} label="Yes" />
						<FormControlLabel value="no" control={<Radio color="secondary" size="small" />} label="No" />
					</RadioGroup>
				</Grid>
				<Collapse in={isDetailed} timeout="auto" unmountOnExit>
					{detailsList.map((item, itemIndex) => (
						<Grid
							container
							spacing={2}
							key={`detail-item-${itemIndex + 1}`}
							className={classes.newItemDetailsInputContainer}
						>
							<Grid item xs={12} sm={6} md={12} lg={6} xl={6}>
								<TextField
									name="newDetailCommodity"
									className={classes.root}
									style={{ width: '100%' }}
									value={item.description}
									label="Detailed Product Description"
									variant="outlined"
									disabled
								/>
							</Grid>
							<Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
								<TextField
									name="newDetailQuantity"
									className={classes.root}
									type="number"
									value={item.quantity}
									label="Quantity *"
									variant="outlined"
									style={{ width: '100%' }}
									disabled
								/>
							</Grid>
							<Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
								<TextField
									className={classes.root}
									value={item?.packageType ?? item?.package_type ?? ''}
									label="Package Type *"
									variant="outlined"
									style={{ width: '100%' }}
									disabled
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={4} lg={2} xl={2} className={classes.newItemButtonContainer}>
								<Button
									className={classes.newItemDetailButton}
									onClick={() => {
										deleteItem(itemIndex, item.id)
									}}
								>
									<DeleteOutlineIcon color="error" style={{ padding: '0px' }} />
									<span className={classes.newItemActionLabel}>Remove item</span>
								</Button>
							</Grid>
						</Grid>
					))}
					<Grid container spacing={2} className={classes.newItemDetailsInputContainer}>
						<Grid item xs={12} sm={6} md={12} lg={6} xl={6}>
							<TextField
								name="newDetailCommodity"
								className={classes.root}
								style={{ width: '100%' }}
								value={commodity}
								onChange={(e) => setCommodity(e.target.value)}
								inputRef={register({ maxLength: { value: 45, message: 'Maximum 45 characters allowed' } })}
								error={!!errors.newDetailCommodity}
								helperText={errors.newDetailCommodity ? errors.newDetailCommodity.message : null}
								FormHelperTextProps={{ classes: { root: classes.helperText } }}
								label="Detailed Product Description"
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
							<TextField
								name="newDetailQuantity"
								className={classes.root}
								type="number"
								value={quantity}
								onChange={(e) => setQuantity(e.target.value)}
								inputRef={register({
									required: true,
									pattern: /^(?:[1-9]|[1-9][0-9]|[1-8][0-9][0-9]|9[0-8][0-9]|99[0-9])$/,
								})}
								error={quantityError || !!errors.newDetailQuantity}
								FormHelperTextProps={{ classes: { root: classes.helperText } }}
								label="Quantity *"
								variant="outlined"
								style={{ width: '100%' }}
							/>
						</Grid>
						<Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
							<FormControl
								style={{ width: '100%' }}
								variant="outlined"
								error={packageTypeError || !!errors.newDetailPackageType}
							>
								<InputLabel className={classes.root}>Package type *</InputLabel>
								<Controller
									render={({ onChange, name }) => (
										<Select
											name={name}
											value={packageType}
											onChange={(e) => {
												onChange(e.target.value)
												setPackageType(e.target.value)
												setPackageTypeError(false)
											}}
											label="Package Type *"
											className={classes.root}
										>
											{detailsPackageTypeOptions.map((option, index) => (
												<MenuItem value={option} key={`id-${index + 1}`}>
													{capitalizeFirstLetter(option)}
												</MenuItem>
											))}
										</Select>
									)}
									name="newDetailPackageType"
									control={control}
									defaultValue={packageType}
									rules={{ required: true }}
								/>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={12} md={4} lg={2} xl={2} className={classes.newItemButtonContainer}>
							<Button
								className={classes.newItemDetailButton}
								onClick={() => {
									handleAddDetails()
								}}
							>
								<AddNewIcon color="secondary" />
								<span className={classes.newItemActionLabel}>Add New Detail</span>
							</Button>
						</Grid>
					</Grid>
				</Collapse>
			</div>
		</>
	)
}

NewItemDetails.propTypes = {
	detailsList: PropTypes.instanceOf(Array).isRequired,
	setDetailsList: PropTypes.func.isRequired,
	isDetailed: PropTypes.bool.isRequired,
	setIsDetailed: PropTypes.func.isRequired,
	templateItemId: PropTypes.string.isRequired,
}

export default NewItemDetails
