import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'
import { Grid, IconButton, FormControl, Select, InputLabel, MenuItem } from '@material-ui/core'
import { FilterList, Loop } from '@material-ui/icons'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'

import SearchBar from '../components/SearchBar'
import TableList from '../components/TableList/TableList'
import { setTabCurrentRoute, setTabTitle } from '../../../../redux/actions/MultiTabs.actions'

import useTemplateItemsFilters from '../../../../hooks/useTemplateItemsFilters'
import {
	setCurrentPage,
	setRowsPerPage,
	setSearchByLabel,
	setSearchBar,
	resetFilters,
} from '../../../../redux/actions/TemplateItemsFilters.actions'
import { fetchTemplateItems } from '../../../../redux/actions/TemplateItems.actions'
import sweetAlertModal from '../../../../components/SweetAlertModal/SweetAlertModal'
import { itemsListRoute } from '../../../../utils/constants'
import { searchItemsByOptions } from '../../../../utils/dropDownOptions'
import useStyles from './styles'

const MyItems = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const matches = useMediaQuery('(min-width:1024px)')
	const [buildParamsFromState] = useTemplateItemsFilters()
	const [open, setOpen] = useState(false)
	const searchBy = useSelector((state) => state.templateItemsFilters.searchByLabel)
	const [searchByValue, setSearchByValue] = useState(searchBy)
	const [pageReset, setPageReset] = useState('resetPage')
	const [search, setSearch] = useState('')

	const MyItemsProvider = () => {
		const callback = useCallback(async (params) => {
			const { status } = await dispatch(fetchTemplateItems(params))
			if (status === 'error') sweetAlertModal('error', 'There was an error fetching items', null, 'Ok', true, false)
		}, [])

		return { callback }
	}

	const { callback } = MyItemsProvider()
	const filterParams = buildParamsFromState()

	useEffect(() => {
		dispatch(setTabCurrentRoute(itemsListRoute))
		dispatch(setTabTitle('Product Book'))
	}, [dispatch])

	useEffect(() => {
		callback(filterParams)
	}, [callback, filterParams])

	useEffect(() => {
		const preservedFilters = localStorage.getItem('preserved-item-filters')
		if (preservedFilters) {
			const filterObj = preservedFilters.split('&')
			filterObj.forEach((filter) => {
				const filterKeyValue = filter.split('=')
				switch (filterKeyValue[0]) {
					case 'search_label':
						dispatch(setSearchByLabel(filterKeyValue[1]))
						setSearchByValue(filterKeyValue[1])
						break
					case 'search_bar':
						dispatch(setSearchBar(filterKeyValue[1]))
						setSearch(decodeURIComponent(filterKeyValue[1]))
						break
					case 'page':
						dispatch(setCurrentPage(filterKeyValue[1]))
						break
					case 'limit':
						dispatch(setRowsPerPage(filterKeyValue[1]))
						break
					default:
						break
				}
			})
		}
	}, [dispatch])

	const toggleDrawer = (openDrawer) => (event) => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return
		}
		setOpen(openDrawer)
	}

	const showFilters = () => {
		setOpen(true)
	}

	const onKeyDown = (event) => {
		if (event.keyCode === 13) {
			if (event.target.value) {
				dispatch(setSearchBar(encodeURIComponent(event.target.value)))
				setSearch(event.target.value)
				setPageReset('resetPageSearch')
				dispatch(setCurrentPage(0))
			}
		} else if (!event.target.value) {
			dispatch(setSearchBar(''))
		}
	}

	const handleResetFilters = () => {
		dispatch(resetFilters())
		setPageReset('resetPageClear')
		setSearch('')
		setSearchByValue('')
		localStorage.removeItem('preserved-item-filters')
	}

	const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

	return (
		<>
			<div className={classes.quotesHistoryTitleButton}>
				<h1 className={classes.quoteHistoryTitle}>Product Book</h1>
				<IconButton aria-label="Toggle Filters" onClick={showFilters} className={classes.filterTriggerButton}>
					<FilterList color="secondary" />
				</IconButton>
			</div>
			<p className={classes.quoteHistoryDescription}>Consult the items created in the system.</p>
			<SwipeableDrawer
				className={classes.drawer}
				anchor="top"
				open={open}
				onOpen={toggleDrawer(open, true)}
				onClose={toggleDrawer(false)}
				disableBackdropTransition={!iOS}
				disableDiscovery={iOS}
			>
				<div className={classes.filterFormWrapper} role="presentation">
					<Grid container spacing={2} alignItems="center" style={{ padding: '15px 8px 0 10px' }}>
						<FormControl variant="outlined" style={{ width: '100%', marginBottom: '0' }}>
							<InputLabel className={classes.selectChildInput} htmlFor="company-dialog-select">
								Search by
							</InputLabel>
							<Select
								value={searchByValue}
								label="searchBy"
								onChange={(e) => {
									setSearchByValue(e.target.value)
									dispatch(setSearchByLabel(e.target.value))
								}}
							>
								{searchItemsByOptions.map((item, index) => (
									<MenuItem value={item.value} key={`id-${index + 1}`}>
										{item.label}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<Grid item xs={12} style={{ padding: '20px 0 0 0' }}>
							<Button
								onClick={handleResetFilters}
								color="primary"
								startIcon={<Loop color="secondary" />}
								style={{ textTransform: 'none' }}
								className={classes.filterButtonModal}
							>
								<span className={classes.filterButtonModalText}>Reset Search</span>
							</Button>
						</Grid>
					</Grid>
				</div>
			</SwipeableDrawer>
			<Grid container spacing={2} alignItems="center">
				{matches && (
					<>
						<Grid item xs={12} md={3}>
							<FormControl size="small" variant="outlined" style={{ width: '100%', marginBottom: '0' }}>
								<InputLabel className={classes.selectChildInput} htmlFor="company-dialog-select">
									Search by
								</InputLabel>
								<Select
									value={searchByValue}
									label="searchBy"
									onChange={(e) => {
										setSearchByValue(e.target.value)
										dispatch(setSearchByLabel(e.target.value))
									}}
								>
									{searchItemsByOptions.map((item, index) => (
										<MenuItem value={item.value} key={`id-${index + 1}`}>
											{item.label}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					</>
				)}
				<Grid item xs={12} md={12} lg={7}>
					<SearchBar value={search} autoFocus placeholder="Search..." onKeyDown={onKeyDown} />
				</Grid>
				{matches && (
					<Grid item xs={2} md={2}>
						<Button
							onClick={handleResetFilters}
							color="primary"
							startIcon={<Loop color="secondary" />}
							className={classes.filterButton}
						>
							<span className={classes.filterButtonText}>Reset Search</span>
						</Button>
					</Grid>
				)}
			</Grid>
			<TableList pageReset={pageReset} />
		</>
	)
}

export default MyItems
