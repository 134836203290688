import React, { useState } from 'react'
import { Grid, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import PropTypes from 'prop-types'
import useStyles from '../styles'
import { documentsOptions } from '../../../../../utils/dropDownOptions'

const DocumentType = ({ document, setAllDocuments }) => {
	const classes = useStyles()

	const [documentType, setDocumentType] = useState('')

	const handleSelectDocumentType = (event) => {
		setDocumentType(event.target.value)
		const doc = {
			file: document,
			name: document.name,
			type: event.target.value,
		}
		const copyDocuments = []
		copyDocuments.push(doc)
		setAllDocuments(copyDocuments)
	}

	return (
		<Grid container spacing={1} alignItems="center" style={{ maxWidth: '100%', marginBottom: '15px' }}>
			<Grid item lg={6} xl={6} sm={6} md={6} xs={6}>
				<p className={classes.documentName}>{document.name}</p>
			</Grid>
			<Grid item lg={6} xl={6} sm={6} md={6} xs={6}>
				<FormControl size="small" style={{ width: '100%' }} variant="outlined">
					<InputLabel className={classes.root}>Document Type *</InputLabel>
					<Select
						name="FreightClass"
						value={documentType}
						onChange={(event) => handleSelectDocumentType(event)}
						label="Document Type *"
						variant="outlined"
						placeholder="Document Type *"
					>
						{documentsOptions.map((option, index) => (
							<MenuItem value={option.value} key={`id-${index + 1}`}>
								{option.label}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
		</Grid>
	)
}

DocumentType.propTypes = {
	document: PropTypes.objectOf(PropTypes.any).isRequired,
	setAllDocuments: PropTypes.func.isRequired,
}

export default DocumentType
