import React, { useContext } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import SavedQuotes from '../../quotes/savedQuotes/pages/SavedQuotes'
import NewQuoteShipment from '../../newQuoteShipment/pages/NewQuoteShipment'
import MyShipments from '../../shipments/myShipments/pages/myShipments'
import NewUser from '../../users/newUser/pages/NewUser'
import UsersList from '../../users/UsersList/pages/UsersList'
import NewLocation from '../../locations/newLocation/pages/NewLocation'
import NewShipment from '../../shipments/newShipment/pages/NewShipment'
import ShipmentDetails from '../../shipments/shipmentDetails/pages'
import MyItems from '../../items/myItems/pages/myItems'
import NewItem from '../../items/newItem/pages/NewItem'
import MyLocations from '../../locations/myLocations/pages/myLocations'
import MyInvoices from '../../invoices/pages/MyInvoices'
import InvoiceDetails from '../../invoices/invoiceDetails/pages/InvoiceDetails'
import NewAccount from '../../admin/newAccount/pages/NewAccount'
import Dashboard from '../../dashboard/pages/Dashboard'
import AccountList from '../../admin/accountList/pages/AccountList'
import MyProfile from '../../profile/myProfile/pages/MyProfile'
import CarrierList from '../../carriers/CarriersList/pages/CarriersList'
import CarrierDetails from '../../carriers/CarriersList/pages/CarriersDetails'
import NewCarrier from '../../carriers/newCarrier/pages/NewCarrier'
import {
	createQuotesRoute,
	createShipmentsRoute,
	quotesListRoute,
	shipmentsListRoute,
	shipmentDetails,
	createUserRoute,
	createLocationRoute,
	locationsListRoute,
	createItemRoute,
	itemsListRoute,
	updateLocationRoute,
	updateItemRoute,
	invoicesListRoute,
	invoiceDetailsRoute,
	createAccountRoute,
	accountsListRoute,
	accountDetailsRoute,
	usersListRoute,
	accountEditRoute,
	updateUserRoute,
	reportsRoute,
	updateProfileRoute,
	carriersListRoute,
	createCarrierRoute,
	updateCarrierRoute,
	permissionName,
	carrierDetailsRoute,
} from '../../../utils/constants'
import AccountDetails from '../../admin/accountDetails/pages'
import ReportsContainer from '../../reports/containers/ReportsContainer'

import SessionContext from '../../../context/session'
import isPermit from '../../../utils/permission'

function DashboardRoutes() {
	const { getUserData } = useContext(SessionContext)
	const { permissions } = getUserData()
	return (
		<Switch>
			<Route exact path={quotesListRoute}>
				<SavedQuotes />
			</Route>
			{isPermit(permissions, permissionName.QUOTES_CREATE) && (
				<Route exact path={createQuotesRoute}>
					<NewQuoteShipment />
				</Route>
			)}
			<Route exact path={createShipmentsRoute}>
				<NewQuoteShipment />
			</Route>
			<Route exact path={shipmentsListRoute}>
				<MyShipments />
			</Route>
			{(isPermit(permissions, permissionName.FP_ADMIN_USERS_CREATE) ||
				isPermit(permissions, permissionName.ADMIN_USERS_CREATE)) && (
				<Route exact path={createUserRoute}>
					<NewUser />
				</Route>
			)}
			{(isPermit(permissions, permissionName.FP_ADMIN_USERS_UPDATE) ||
				isPermit(permissions, permissionName.ADMIN_USERS_UPDATE)) && (
				<Route exact path={updateUserRoute}>
					<NewUser />
				</Route>
			)}
			<Route exact path={usersListRoute}>
				<UsersList />
			</Route>
			<Route exact path={updateLocationRoute}>
				<NewLocation />
			</Route>
			<Route exact path={createLocationRoute}>
				<NewLocation />
			</Route>
			<Route exact path={locationsListRoute}>
				<MyLocations />
			</Route>
			<Route exact path={createItemRoute}>
				<NewItem />
			</Route>
			<Route exact path={updateItemRoute}>
				<NewItem />
			</Route>
			<Route exact path={itemsListRoute}>
				<MyItems />
			</Route>
			<Route exact path={shipmentDetails}>
				<ShipmentDetails />
			</Route>
			<Route exact path="/shipment-old">
				<NewShipment />
			</Route>
			<Route exact path={invoicesListRoute}>
				<MyInvoices />
			</Route>
			<Route exact path={invoiceDetailsRoute}>
				<InvoiceDetails />
			</Route>
			{isPermit(permissions, permissionName.FP_ADMIN_ACCOUNTS_UPDATE) && (
				<Route exact path={accountEditRoute} key="create-account">
					<NewAccount />
				</Route>
			)}
			{isPermit(permissions, permissionName.FP_ADMIN_ACCOUNTS_CREATE) && (
				<Route exact path={createAccountRoute} key="update-account">
					<NewAccount />
				</Route>
			)}
			<Route exact path={accountDetailsRoute}>
				<AccountDetails />
			</Route>
			<Route exact path={accountsListRoute}>
				<AccountList />
			</Route>
			{isPermit(permissions, permissionName.REPORTS_READ) && (
				<Route exact path={reportsRoute}>
					<ReportsContainer />
				</Route>
			)}
			<Route exact path={updateProfileRoute}>
				<MyProfile />
			</Route>
			<Route exact path={carriersListRoute}>
				<CarrierList />
			</Route>
			<Route exact path={carrierDetailsRoute}>
				<CarrierDetails />
			</Route>
			{isPermit(permissions, permissionName.FP_ADMIN_CARRIERS_CREATE) && (
				<Route exact path={createCarrierRoute}>
					<NewCarrier />
				</Route>
			)}
			{isPermit(permissions, permissionName.FP_ADMIN_CARRIERS_UPDATE) && (
				<Route exact path={updateCarrierRoute}>
					<NewCarrier />
				</Route>
			)}
			<Route exact path="/">
				<Dashboard />
			</Route>
			<Redirect to="/" />
		</Switch>
	)
}

export default DashboardRoutes
