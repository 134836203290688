import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import isPermit from '../../../../utils/permission'
import { permissionName } from '../../../../utils/constants'
import SessionContext from '../../../../context/session'
import { displayFormattedValue } from '../../../../utils/helpers'

const ShipmentCarrierRatesMobile = (props) => {
	const { getUserData } = useContext(SessionContext)
	const { permissions } = getUserData()
	const isRatePermission = isPermit(permissions, permissionName.FP_ADMIN_CARRIERS_SHOW_RATE)
	const { rates } = props

	return (
		<div className="shipment-carrier-rates-mobile">
			<div className="shipment-carrier-rates">
				<div className="shipment-carrier-rates-titles">
					<p className="shipment-carrier-rates-title">Details</p>
					<p className="shipment-carrier-rates-title" id="shipment-carrier-rate-title">
						Rates
					</p>
				</div>
				<hr className="shipment-carrier-rates-items-separator" />
				<div className="shipment-carrier-rate-item">
					<p className="shipment-rate-title">Freight Quote</p>
					<div>
						{isRatePermission && (
							<p className="shipment-rate-value">
								<span>Carrier: </span>
								{`$${rates.total_carrier?.toFixed(2).replace(/[.,]00$/, '')} USD`}
							</p>
						)}
						<p className="shipment-rate-value">
							<span>Customer: </span>
							{`$${rates.total_customer?.toFixed(2).replace(/[.,]00$/, '')} USD`}
						</p>
					</div>
				</div>
				<hr className="shipment-carrier-rates-items-separator" />
				<div className="shipment-carrier-rate-item">
					<p className="shipment-rate-title" id="rate-insurance">
						3rd Party Insurance
						<span>{` ($${displayFormattedValue(rates.declared_freight_value)} USD)`}</span>
					</p>
					<div>
						{isRatePermission && (
							<p className="shipment-rate-value">
								<span>Carrier: </span>
								{`$${rates.insurance_cost} USD`}
							</p>
						)}
						<p className="shipment-rate-value">
							<span>Customer: </span>
							{`$${rates.insurance_value} USD`}
						</p>
					</div>
				</div>
				<hr className="shipment-carrier-rates-items-separator" />
				<div className="shipment-carrier-rate-item">
					<p className="shipment-rate-title-total">Total:</p>
					<div>
						{isRatePermission && (
							<p className="shipment-rate-value">
								<span>Carrier: </span>
								{`$${(Number(rates.total_carrier) + Number(rates.insurance_cost)).toFixed(2).replace(/[.,]00$/, '')} USD`}
							</p>
						)}
						<p className="shipment-rate-value">
							<span>Customer: </span>
							{`$${(Number(rates.total_customer) + Number(rates.insurance_value)).toFixed(2).replace(/[.,]00$/, '')} USD`}
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}

ShipmentCarrierRatesMobile.propTypes = {
	rates: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default ShipmentCarrierRatesMobile
