import React from 'react'
import PropTypes from 'prop-types'

import { Dialog, Button, useTheme } from '@material-ui/core'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'

import { useDispatch } from 'react-redux'
import useLocationsFilters from '../../../../../hooks/useLocationsFilters'

import { deleteLocation, fetchLocations } from '../../../../../redux/actions/Locations.actions'

import sweetAlertModal from '../../../../../components/SweetAlertModal/SweetAlertModal'

import useStyles from './styles'

const DeleteLocationModal = (props) => {
	const { openConfirmationModal, setOpenConfirmationModal, locationId } = props
	const classes = useStyles()
	const theme = useTheme()
	const dispatch = useDispatch()
	const [buildParamsFromState] = useLocationsFilters()

	const handleDeleteLocation = async () => {
		const filterParams = buildParamsFromState()
		const { status, resp } = await dispatch(deleteLocation(locationId))
		if (status === 'error') {
			sweetAlertModal('error', 'There was an error deleting the location', resp, 'Ok', true, false)
		} else {
			sweetAlertModal(status, 'Location has been deleted successfully!', null, 'Ok', true, false, null)
			await dispatch(fetchLocations(filterParams))
		}
	}

	return (
		<Dialog
			open={openConfirmationModal}
			disableBackdropClick
			disableEscapeKeyDown
			onClick={(event) => event.stopPropagation()}
			aria-labelledby="form-dialog-title"
			PaperProps={{
				style: {
					width: '360px',
					height: '268px',
					borderBottom: `6px solid ${theme.palette.secondary.main}`,
				},
			}}
		>
			<div className={classes.confirmation}>
				<div className={classes.iconBg}>
					<DeleteOutlineIcon className={classes.icon} fontSize="small" />
				</div>
				<h1 className={classes.title}>Delete location?</h1>
			</div>
			<div className={classes.divider} />
			<span className={classes.text}>Are you sure you want to delete Company name’s location?</span>
			<Button
				className={classes.deleteLocationButton}
				onClick={handleDeleteLocation}
				color="secondary"
				variant="contained"
			>
				Delete location
			</Button>
			<Button className={classes.goBack} onClick={() => setOpenConfirmationModal(false)} variant="contained">
				Go back
			</Button>
		</Dialog>
	)
}

DeleteLocationModal.propTypes = {
	openConfirmationModal: PropTypes.bool.isRequired,
	setOpenConfirmationModal: PropTypes.func.isRequired,
	locationId: PropTypes.number.isRequired,
}

export default DeleteLocationModal
