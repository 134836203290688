import { axiosAudit } from '../../utils/apiClient'

const reqGetAuditData = async (id) => {
	const [error, data] = await axiosAudit
		.get(`/audit/shipment/${id}`)
		.then((item) => [null, item?.data?.data?.audit])
		.catch((err) => [true, err?.response?.data?.err?.message])

	return [error, data]
}

export default reqGetAuditData
