/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initState = {
	currentPage: 0,
	rowsPerPage: 10,
	searchBar: '',
	searchByLabel: '',
	orderBy: '',
	order: '',
}

const locationsFilters = createSlice({
	name: 'locationsFilters',
	initialState: {
		...initState,
	},
	reducers: {
		setSearchBar: (state, action) => {
			state.searchBar = action.payload
		},
		setSearchByLabel: (state, action) => {
			state.searchByLabel = action.payload
		},
		setCurrentPage: (state, action) => {
			state.currentPage = action.payload
		},
		setRowsPerPage: (state, action) => {
			state.rowsPerPage = action.payload
		},
		resetFilters: (state) => {
			state.currentPage = initState.currentPage
			state.rowsPerPage = initState.rowsPerPage
			state.searchBar = initState.searchBar
			state.searchByLabel = initState.searchByLabel
			state.orderBy = initState.orderBy
			state.order = initState.order
		},
		setOrderByAndOrder: (state, action) => {
			const { orderBy, order } = action.payload
			state.orderBy = orderBy
			state.order = order
		},
	},
})

export const { setSearchBar, setSearchByLabel, setCurrentPage, setRowsPerPage, setOrderByAndOrder, resetFilters } =
	locationsFilters.actions

const { reducer } = locationsFilters
export default reducer
