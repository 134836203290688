import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { TextField, FormControlLabel, Checkbox, InputAdornment, IconButton } from '@material-ui/core'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined'

import Autocomplete from '@material-ui/lab/Autocomplete'

import { useSelector } from 'react-redux'

import useStyles from '../../pages/styles'

import { originAccessorials } from '../../../../../utils/dropDownOptions'
import RangeDatePicker from '../../../../../components/RangeDatePicker'
import PhoneNumberFormat from '../../../../../components/PhoneNumberFormat'

const ShipperInformation = (props) => {
	const { shipperData, register, errors, isPreviousClicked } = props
	const createShipmentInfo = useSelector((state) => state.shipments.createShipmentInfo)

	const classes = useStyles()
	const [pickUpDate, setPickUpDate] = useState(new Date())
	const [accessorials, setAccessorials] = useState([])
	const [confirmationEmail, setConfirmationEmail] = useState(
		isPreviousClicked ? createShipmentInfo.shipper.sendConfirmationEmail : false,
	)

	useEffect(() => {
		if (Object.keys(shipperData).length !== 0) {
			const dropOffDate = new Date(shipperData.dropOffDate)
			setPickUpDate(dropOffDate)

			const accessorialsOrigin = shipperData.accessorials.map((accessorial) => ({ name: accessorial.name }))
			setAccessorials(accessorialsOrigin)
		}
	}, [shipperData])

	return (
		<div className="new-shipment-item-container">
			<div className="new-shipment-icon-title">
				<AssignmentOutlinedIcon className={classes.mainIcon} color="primary" />
				<h3 className="new-shipment-item-title">Shipper Information</h3>
			</div>
			<div className="new-shipment-inputs" id="new-shipment-inputs-row-1">
				<TextField
					name="shipperCompanyName"
					type="text"
					label="Company name *"
					variant="outlined"
					defaultValue={isPreviousClicked ? createShipmentInfo.shipper.companyName : null}
					inputRef={register({ required: true, maxLength: { value: 35, message: 'Maximum 35 characters allowed' } })}
					error={!!errors.shipperCompanyName}
					InputLabelProps={{ classes: { root: classes.textFieldLabel } }}
					helperText={errors.shipperCompanyName ? errors.shipperCompanyName.message : null}
					FormHelperTextProps={{ classes: { root: classes.helperText } }}
				/>
				<TextField
					name="shipperAddress"
					type="text"
					label="Address 1 *"
					variant="outlined"
					defaultValue={isPreviousClicked ? createShipmentInfo.shipper.address1 : null}
					inputRef={register({ required: true, maxLength: { value: 35, message: 'Maximum 35 characters allowed' } })}
					error={!!errors.shipperAddress}
					InputLabelProps={{ classes: { root: classes.textFieldLabel } }}
					helperText={errors.shipperAddress ? errors.shipperAddress.message : null}
					FormHelperTextProps={{ classes: { root: classes.helperText } }}
				/>
				<TextField
					name="shipperAddress2"
					type="text"
					label="Address 2 *"
					variant="outlined"
					defaultValue={isPreviousClicked ? createShipmentInfo.shipper.address2 : null}
					inputRef={register({ required: true, maxLength: { value: 35, message: 'Maximum 35 characters allowed' } })}
					error={!!errors.shipperAddress2}
					InputLabelProps={{ classes: { root: classes.textFieldLabel } }}
					helperText={errors.shipperAddress2 ? errors.shipperAddress2.message : null}
					FormHelperTextProps={{ classes: { root: classes.helperText } }}
				/>
				<TextField
					className={classes.disabled}
					name="shipperZipCode"
					type="text"
					label="Zip code *"
					variant="outlined"
					value={shipperData.zipCode || ''}
					disabled
					InputLabelProps={{ classes: { root: classes.textFieldLabel } }}
					InputProps={{
						startAdornment: (
							<InputAdornment>
								<IconButton>
									<RoomOutlinedIcon className={classes.icon} />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</div>
			<div className="new-shipment-inputs" id="new-shipment-inputs-row-2">
				<TextField
					className={classes.disabled}
					name="shipperCity"
					type="text"
					label="City"
					variant="outlined"
					value={shipperData.city || ''}
					disabled
					InputLabelProps={{ classes: { root: classes.textFieldLabel } }}
				/>
				<TextField
					className={classes.disabled}
					name="shipperState"
					type="text"
					label="State"
					variant="outlined"
					value={shipperData.state || ''}
					disabled
					InputLabelProps={{ classes: { root: classes.textFieldLabel } }}
				/>
				<TextField
					className={classes.disabled}
					name="shipperCountry"
					type="text"
					label="Country *"
					variant="outlined"
					value={shipperData.country || ''}
					disabled
					InputLabelProps={{ classes: { root: classes.textFieldLabel } }}
					InputProps={{
						startAdornment: (
							<InputAdornment>
								<img
									className="new-shipment-country-flag"
									src="https://i.postimg.cc/cCQwpqXN/flag-removebg-preview.png"
									border="0"
									alt="flag"
								/>
							</InputAdornment>
						),
					}}
				/>
				<TextField
					name="shipperContactName"
					type="text"
					label="Contact name *"
					variant="outlined"
					defaultValue={isPreviousClicked ? createShipmentInfo.shipper.contactName : null}
					inputRef={register({ required: true, maxLength: { value: 35, message: 'Maximum 35 characters allowed' } })}
					error={!!errors.shipperContactName}
					InputLabelProps={{ classes: { root: classes.textFieldLabel } }}
					helperText={errors.shipperContactName ? errors.shipperContactName.message : null}
					FormHelperTextProps={{ classes: { root: classes.helperText } }}
				/>
				<TextField
					name="shipperContactPhone"
					type="text"
					label="Contact phone"
					variant="outlined"
					value={isPreviousClicked ? createShipmentInfo.shipper.contactPhone : null}
					inputRef={register({ pattern: /^([(]([0-9]{3})[)] ([0-9]{3})(-)([0-9]{4}))$/ })}
					error={!!errors.shipperContactPhone}
					InputLabelProps={{ classes: { root: classes.textFieldLabel } }}
					InputProps={{
						inputComponent: PhoneNumberFormat,
					}}
				/>
			</div>
			<div className="new-shipment-inputs" id="new-shipment-inputs-row-3">
				<TextField
					name="shipperContactEmail"
					type="text"
					label="Contact email"
					variant="outlined"
					defaultValue={isPreviousClicked ? createShipmentInfo.shipper.contactEmail : null}
					inputRef={register({
						pattern:
							/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
					})}
					error={!!errors.shipperContactEmail}
					helperText={errors.shipperContactEmail ? 'Enter a valid email' : null}
					FormHelperTextProps={{ classes: { root: classes.helperText } }}
					InputLabelProps={{ classes: { root: classes.textFieldLabel } }}
				/>
				<FormControlLabel
					className={classes.checkBoxLabel}
					control={
						<Checkbox
							name="confirmationEmail"
							inputRef={register()}
							style={{ color: '#EC3514' }}
							checked={confirmationEmail}
							onChange={(event) => setConfirmationEmail(event.target.checked)}
						/>
					}
					label="Sending confirmation email"
				/>
				<RangeDatePicker
					name="shipperPickupDate"
					label="Pickup date"
					setPickUpDate={setPickUpDate}
					valueDate={pickUpDate}
					isDisabled
				/>
			</div>
			<div className="new-shipment-inputs" id="new-shipment-inputs-row-4">
				<Autocomplete
					disabled
					multiple
					options={originAccessorials}
					getOptionLabel={(option) => option.name}
					value={accessorials}
					renderInput={(params) => (
						<TextField
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...params}
							className={classes.disabledAutocomplete}
							InputLabelProps={{ classes: { root: classes.textFieldLabel } }}
							label="Origin accessorials"
							margin="normal"
							variant="outlined"
						/>
					)}
				/>
			</div>
		</div>
	)
}

ShipperInformation.propTypes = {
	shipperData: PropTypes.objectOf(PropTypes.any).isRequired,
	register: PropTypes.func.isRequired,
	errors: PropTypes.objectOf(PropTypes.any).isRequired,
	isPreviousClicked: PropTypes.bool.isRequired,
}

export default ShipperInformation
