import { makeStyles, fade } from '@material-ui/core'

const appBarHeight = 57
const drawerExpandedWidth = 213
const drawerCollapsedWidth = 98

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	appBar: {
		height: appBarHeight,
		backgroundColor: theme.palette.primary.main,
		borderBottom: `1px solid ${theme.palette.primary.light}`,
		boxShadow: 'none',
		[theme.breakpoints.up('md')]: {
			paddingLeft: drawerCollapsedWidth,
		},
		'& .eleMuiBackdrop-root': {
			top: appBarHeight,
		},
	},
	toolbar: {
		padding: theme.spacing(0, 2, 0, 0),
		minHeight: appBarHeight,
	},
	headerActions: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'flex-end',
	},
	headerIcon: {
		color: theme.palette.neutral.darkGray,
		transition: 'background-color 500ms',
		'&:hover': {
			backgroundColor: `${theme.palette.neutral.darkGray}05`,
		},
	},
	companyButton: {
		height: '38px',
		color: theme.palette.neutral.darkGray,
		fontWeight: theme.typography.h4.fontWeight,
		boxShadow: 'none',
		transition: 'background-color 500ms, color 500ms',
		'&:hover': {
			backgroundColor: theme.palette.neutral.mainGray,
			color: theme.palette.primary.main,
		},
	},
	companyName: {
		paddingLeft: '5px',
		maxWidth: '170px',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},
	menuButton: {
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
		color: theme.palette.neutral.darkGray,
	},
	tabsMobileWrapper: {
		display: 'flex',
		justifyContent: 'flex-start',
		width: '100%',
		color: theme.palette.primary.main,
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	tabsMobileButton: {
		alignItems: 'center',
		border: 'none',
		backgroundColor: 'transparent',
		display: 'flex',
		fontSize: 17,
		fontWeight: theme.typography.fontWeightBold,
		outline: 'none',
		color: theme.palette.neutral.white,
		cursor: 'pointer',
		padding: 0,
		minWidth: '120px',
	},
	drawer: {
		flexShrink: 0,
		[theme.breakpoints.up('md')]: {
			position: 'absolute',
			width: drawerCollapsedWidth,
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			'&.isOpen': {
				width: drawerExpandedWidth,
			},
		},
	},
	drawerPaper: {
		backgroundColor: theme.palette.primary.light,
		width: drawerExpandedWidth,
		borderRight: `1px solid ${theme.palette.primary.light}`,
		[theme.breakpoints.up('md')]: {
			width: drawerCollapsedWidth,
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			'&.isOpen': {
				width: drawerExpandedWidth,
			},
		},
		'&::-webkit-scrollbar': {
			width: '0.5em',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.palette.neutral.mainGray,
			borderRadius: '10px',
		},
	},
	content: {
		backgroundColor: theme.palette.neutral.white,
		flexGrow: 1,
		height: '100vh',
		overflow: 'auto',
		scrollBehavior: 'smooth',
		[theme.breakpoints.up('md')]: {
			marginLeft: drawerCollapsedWidth,
		},
	},
	container: {
		padding: theme.spacing(2),
		maxWidth: '100%',
		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(2, 3),
		},
	},
	appBarSpaccer: {
		height: appBarHeight,
	},
	formControl: {
		margin: '1rem 0',
		width: '100%',
	},
	dialogPaper: {
		width: 500,
		borderBottom: '6px solid',
		borderBottomColor: theme.palette.secondary.main,
		padding: theme.spacing(4),
		alignItems: 'center',
	},
	dialogTitle: {
		'& h2': {
			fontWeight: theme.typography.fontWeightBold,
			color: theme.palette.secondary.main,
			margin: 0,
			lineHeight: 'normal',
			textAlign: 'center',
		},
	},
	dialogSubtitle: {
		margin: '0 15%',
		textAlign: 'center',
	},
	dialogContent: {
		padding: 0,
		width: '90%',
		overflowY: 'unset',
	},
	dialogActions: {
		flexDirection: 'column',
		justifyContent: 'unset',
		width: '90%',
	},
	buttonSwitchAccount: {
		width: '100%',
		height: 48,
		fontWeight: theme.typography.fontWeightMedium,
		color: theme.palette.neutral.white,
		backgroundColor: theme.palette.secondary.main,
		margin: theme.spacing(2, 0, 0),
		textTransform: 'none',
		'&:hover': {
			backgroundColor: theme.palette.secondary.main,
		},
	},
	buttonGrey: {
		width: '100%',
		height: 48,
		fontWeight: theme.typography.fontWeightMedium,
		color: theme.palette.neutral.black,
		backgroundColor: theme.palette.neutral.mainGray,
		margin: theme.spacing(4, 0, 0, 0),
		textTransform: 'none',
		'&:hover': {
			backgroundColor: '#bac0c5',
		},
	},
	messageDiv: {
		padding: '1rem',
		background: '#FFA26B',
		borderRadius: '5px',
		margin: '1rem 0',
		color: '#FFFFFF',
		display: 'grid',
		gridTemplateColumns: '15% 75%',
		[theme.breakpoints.down('md')]: {
			gridTemplateColumns: '25% 75%',
		},
	},
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.neutral.secondaryGrey, 0.15),
		'&:hover': {
			backgroundColor: fade(theme.palette.neutral.secondaryGrey, 0.25),
		},
		marginRight: theme.spacing(2),
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(3),
			width: 'auto',
		},
	},
	sectionDesktop: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	sectionMobile: {
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	'@global': {
		'.MuiDialogActions-spacing > :not(:first-child)': {
			marginLeft: 0,
		},
		'#company-dialog-select': {
			background: '#CCD2D8',
		},
	},
	pl12: {
		marginLeft: '12px',
	},
	avatar: {
		width: '25px',
		height: '25px',
		color: theme.palette.neutral.darkGray,
		fontWeight: 700,
		backgroundColor: theme.palette.neutral.white,
	},
}))

export default useStyles
