import { createTheme } from '@material-ui/core/styles'

// Following the Freight Pros style guide: https://www.sketch.com/s/6f755669-45ce-4c91-ab9c-712828ae4381

export const freightProsTheme = createTheme({
	palette: {
		primary: {
			main: '#161616',
			dark: '#000000',
			orange: '#F3846F', // TODO: should this change to 'cyan'
			light: '#262626',
			lighter: '#393939',
		},
		secondary: {
			main: '#007a8a',
			dark: '#005d6b',
			purple: '#5F6FB0', // TODO: look to remove it from the app
			light: '#009AAB',
		},
		neutral: {
			black: '#161616',
			mainGray: '#CCD2D8',
			secondaryGrey: '#EBEEF1',
			darkGray: '#C6C6C6',
			lightGray: '#E0E0E0',
			whiteLightGray: '#E0E0E0', // TODO: move components using this to 'lightGray'
			whiteGray: '#F4F4F4',
			white: '#FFFFFF',
		},
		semantic: {
			yellow: {
				primary: '#FFEB00',
				secondary: '#EBD300',
				dark: '#EBD300',
				light: '#FFFABF',
			},
			orange: {
				primary: '#CF700A',
				secondary: '#F2973D',
				light: '#FFC48C',
			},
			blue: {
				primary: '#007A8A',
				// secondary: '#66B5F8',
				// light: '#D5E9FA',
			},
			green: {
				primary: '#00A303',
				secondary: '#39CC39',
				light: '#72E670',
			},
			red: {
				primary: '#E00013',
				secondary: '#F73E4D',
				light: '#FFBAC0',
			},
			purple: {
				primary: '#9873FF',
				secondary: '#B8A1FF',
				light: '#D3C7FF',
			},
		},
		error: {
			main: '#e00013',
			secondary: '#AB000E',
			light: '#FFDEE1',
		},
		warning: {
			main: '#cf700a',
			secondary: '#824500',
			light: '#FFE3CC',
		},
		success: {
			main: '#00a303',
			dark: '#006103',
			light: '#B5FFB3',
		},
	},
	typography: {
		fontFamily: 'Roboto, sans-serif',
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 600,
		fontWeightBold: 700,
		fontWeightExtraBold: 800,
		htmlFontSize: 16,
		fontSize: 16,
		h1: {
			fontSize: '2.5rem',
			fontWeight: 300,
			lineHeight: '3rem',
			letterSpacing: 0,
		},
		h2: {
			fontSize: '2rem',
			fontWeight: 300,
			lineHeight: '2.5rem',
			letterSpacing: 0,
		},
		h3: {
			fontSize: '1.75rem',
			fontWeight: 400,
			lineHeight: '2.25rem',
			letterSpacing: 0,
		},
		h4: {
			fontSize: '1.25rem',
			fontWeight: 400,
			lineHeight: '1.75rem',
			letterSpacing: 0,
		},
		h5: {
			fontSize: '1rem',
			fontWeight: 500,
			lineHeight: '1.25rem',
			letterSpacing: 0,
		},
		h6: {
			fontSize: '.875rem',
			fontWeight: 500,
			lineHeight: '1.125rem',
			letterSpacing: 0.2,
		},
		subtitle1: {
			fontSize: '1rem',
			fontWeight: 500,
			lineHeight: '1.25rem',
			letterSpacing: 0,
		},
		subtitle2: {
			fontSize: '.875rem',
			fontWeight: 500,
			lineHeight: '1.125rem',
			letterSpacing: 0.2,
		},
		body1: {
			fontSize: '1rem',
			fontWeight: 400,
			lineHeight: '1.5rem',
			letterSpacing: 0,
		},
		body2: {
			fontSize: '.875rem',
			fontWeight: 400,
			lineHeight: '1.25rem',
			letterSpacing: 0.2,
		},
		button: {
			fontSize: '.875rem',
			fontWeight: 400,
			lineHeight: '1.25rem',
			letterSpacing: 0.2,
		},
		caption: {
			fontSize: '.75rem',
			fontWeight: 400,
			lineHeight: '1rem',
			letterSpacing: 0.4,
		},
		overline: {
			fontSize: '.75rem',
			fontWeight: 400,
			lineHeight: '1rem',
			letterSpacing: 0.4,
		},
		caption2: {
			fontWeight: 600,
			fontSize: 11,
		},
		smallText: {
			fontWeight: 400,
			fontSize: 11,
		},
		helpInputErrorCaption: {
			fontWeight: 400,
			fontSize: 12,
		},
	},
	breakpoints: {
		values: {
			xs: 320,
			sm: 480,
			md: 768,
			lg: 1024,
			xl: 1200,
		},
	},
	overrides: {
		MuiCssBaseline: {
			'@global': {
				h1: {
					fontSize: '2.5rem',
					fontWeight: 300,
					lineHeight: '3rem',
					letterSpacing: 0,
				},
				h2: {
					fontSize: '2rem',
					fontWeight: 300,
					lineHeight: '2.5rem',
					letterSpacing: 0,
				},
				h3: {
					fontSize: '1.75rem',
					fontWeight: 400,
					lineHeight: '2.25rem',
					letterSpacing: 0,
				},
				h4: {
					fontSize: '1.25rem',
					fontWeight: 400,
					lineHeight: '1.75rem',
					letterSpacing: 0,
				},
				h5: {
					fontSize: '1rem',
					fontWeight: 500,
					lineHeight: '1.25rem',
					letterSpacing: 0,
				},
				h6: {
					fontSize: '.875rem',
					fontWeight: 500,
					lineHeight: '1.125rem',
					letterSpacing: 0.2,
				},
				p: {
					fontSize: '1rem',
					fontWeight: 400,
					lineHeight: '1.5rem',
					letterSpacing: 0,
				},
				span: {
					fontWeight: 400,
					letterSpacing: 0,
				},
			},
		},
	},
	props: {
		MuiTextField: {
			color: 'secondary',
		},
		MuiSelect: {
			color: 'secondary',
		},
		MuiRadio: {
			color: 'secondary',
		},
		MuiCheckbox: {
			color: 'secondary',
		},
		MuiSwitch: {
			color: 'secondary',
		},
		MuiLinearProgress: {
			color: 'secondary',
		},
		MuiCircularProgress: {
			color: 'secondary',
		},
	},
})

export default freightProsTheme
