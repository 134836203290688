import axiosUser from '../../utils/apiClient'

export const reqUserCompanies = async (params, config) => {
	const [error, data] = await axiosUser
		.get(`/company${params}`, config)
		.then((setting) => [null, setting.data.data])
		.catch((err) => [err, err])
	return [error, data]
}

export const reqUserChildrenCompanies = async (params, config) => {
	const [error, data] = await axiosUser
		.get(`/company/children/${params}`, config)
		.then((setting) => [null, setting.data.data])
		.catch((err) => [err, err])
	return [error, data]
}

export const reqCreateAccounts = async (body, config) => {
	const [error, data] = await axiosUser
		.post('/company/', body, config)
		.then((response) => [null, response.data.data])
		.catch((err) => [err, err.response.data.message])
	return [error, data]
}

export const reqCreateAccountSettings = async (params, config) => {
	const [error, data] = await axiosUser
		.post('/company/component/settings', params, config)
		.then((response) => [null, response.data.data])
		.catch((err) => [err, err.response.data.message])
	return [error, data]
}

export const reqGetAccountSettings = async (config) => {
	const [error, data] = await axiosUser
		.get('/company/component/settings', config)
		.then((response) => [null, response.data.data])
		.catch((err) => [err, err.response.data.message])
	return [error, data]
}

export const reqGetAccountInformation = async (config, id) => {
	// console.log('config', config);
	// console.log('id', id);
	const [error, data] = await axiosUser
		.get(`/company/parent-children/${id}`, config)
		.then((response) => [null, response.data.data])
		.catch((err) => [err, err.response.data.message])
	// console.log('data from user lookup', data);
	return [error, data]
}

export const reqEditAccount = async (params, id) => {
	const [error, data] = await axiosUser
		.put(`/company/${id}`, params)
		.then((response) => [null, response.data.data])
		.catch((err) => [err, err.response.data.message])
	return [error, data]
}

export const reqEditAccountStatus = async (params, config) => {
	const [error, data] = await axiosUser
		.patch('/company', params, config)
		.then((response) => [null, response])
		.catch((err) => [err, err])
	return [error, data]
}

export const reqEditAccountStepStatus = async (params, config) => {
	const [error, data] = await axiosUser
		.patch(`/company/complete-step${params}`, config)
		.then((response) => [null, response])
		.catch((err) => [err, err])
	return [error, data]
}

export const reqBulkAccountsCreation = async (body, config) => {
	const [error, data] = await axiosUser
		.post('/company/bulk', body, config)
		.then((response) => [null, response.data.data])
		.catch((err) => [err, err.response.data.err])
	return [error, data]
}

export const reqBulkAccountsStatusUpdate = async (body, config) => {
	const [error, data] = await axiosUser
		.patch('/company/bulk/status', body, config)
		.then((response) => [null, response.data.data])
		.catch((err) => [err, err.response.data.message])
	return [error, data]
}

export const postTicketToSupport = async (body, config) => {
	const [error, data] = await axiosUser
		.post('/support', body, config)
		.then((response) => [null, response.data.data])
		.catch((err) => [err, err.response.data.err])
	return [error, data]
}

export const getFullChildCompanies = async (config) => {
	const [error, data] = await axiosUser
		.get('/company/companies/below', config)
		.then((response) => [null, response])
		.catch((err) => [err, err])
	return [error, data]
}

export default {
	reqUserCompanies,
	reqCreateAccounts,
	reqCreateAccountSettings,
	reqGetAccountSettings,
	reqEditAccount,
	reqEditAccountStatus,
	reqBulkAccountsCreation,
	reqBulkAccountsStatusUpdate,
	getFullChildCompanies,
}
