import React, { useEffect, useContext } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { LinearProgress } from '@material-ui/core'
import PrivateRoutes from './privateRoutes/privateRoutes'
import SessionContext from '../context/session/index'
import PublicRoutes from './publicRoutes/publicRoutes'
import postLoginProcess from '../modules/baseLayout/pages/OktaLoginWidget/postLoginHelper'
import { AUTH0_SESSION_STRING } from '../utils/constants'

const FreightProsRouter = () => {
	const dispatch = useDispatch()
	const {
		createSession,
		saveUserData,
		getTokenUser,
		getUserData,
		saveActualCompanyId,
		cleanStorage,
		authenticated,
		postLoginFinished,
		updatePostLoginFinished,
		oktaAuth,
	} = useContext(SessionContext)
	const { isAuthenticated, getIdTokenClaims, loginWithRedirect, isLoading, logout } = useAuth0()

	// new stuff
	useEffect(() => {
		let token
		let existingToken
		// console.log('in router useeffect');
		// isAuthenticated will be true if user signed in with Auth0
		if (
			isAuthenticated ||
			localStorage.getItem(AUTH0_SESSION_STRING) ||
			window.location.pathname === process.env.REACT_APP_AUTH_LOGIN_PATH
		) {
			console.log('post login attempted auth0')
			const storedToken = oktaAuth.tokenManager.getTokensSync()
			if ((!postLoginFinished && isAuthenticated) || storedToken !== undefined) {
				oktaAuth.tokenManager.clear()
				existingToken = getTokenUser(true)
				postLoginProcess(
					false,
					existingToken,
					oktaAuth,
					createSession,
					saveUserData,
					cleanStorage,
					saveActualCompanyId,
					dispatch,
					updatePostLoginFinished,
					logout,
					getUserData,
				)
			}
		} else {
			console.log('post login attempted okta auth')
			const startAuth = async () => {
				await oktaAuth.start()
				const oktaAuthenticated = await oktaAuth.isAuthenticated()
				const oktaSession = await oktaAuth.session.get()

				// If the user is not yet authenticated in Okta,
				// check for an active session and attempt to get tokens from said session.
				if (!oktaAuthenticated && !!oktaSession && oktaSession.status === 'ACTIVE') {
					await oktaAuth.token
						.getWithoutPrompt()
						.then((tokens) => {
							oktaAuth.tokenManager.setTokens(tokens?.tokens)
						})
						.catch(() => {})
				}
			}
			token = oktaAuth.getAccessToken()
			// console.log('okta token', token);
			const postLogin = async (renewedToken) => {
				const isOktaAuthenticated = await oktaAuth.isAuthenticated()
				console.log('post login attempted okta')
				if (!postLoginFinished || renewedToken) {
					postLoginProcess(
						isOktaAuthenticated,
						renewedToken || token,
						oktaAuth,
						createSession,
						saveUserData,
						cleanStorage,
						saveActualCompanyId,
						dispatch,
						updatePostLoginFinished,
						logout,
						getUserData,
					)
				}
			}
			startAuth().then(() => {
				console.log('start auth finished')
				if (!postLoginFinished && token) {
					postLogin(token)
				}
				oktaAuth.tokenManager.on('expired', () => {
					oktaAuth.tokenManager.renew('accessToken').then((newToken) => {
						const renewedToken = newToken.accessToken
						console.log('okta token renewed')
						postLogin(renewedToken)
					})
				})
			})
		}
	}, [
		createSession,
		getTokenUser,
		authenticated,
		getIdTokenClaims,
		isAuthenticated,
		cleanStorage,
		saveUserData,
		dispatch,
		saveActualCompanyId,
		getUserData,
		oktaAuth,
		postLoginFinished,
		updatePostLoginFinished,
		logout,
	])

	if (isLoading && window.location.pathname === process.env.REACT_APP_AUTH_LOGIN_PATH) {
		return <LinearProgress />
	}
	// new stuff
	if (authenticated && postLoginFinished) {
		// console.log('Loading Private Routes...');
		return <PrivateRoutes oktaAuth={oktaAuth} />
	}
	if (!isAuthenticated && window.location.pathname === process.env.REACT_APP_AUTH_LOGIN_PATH) {
		loginWithRedirect()
	}
	if (!isAuthenticated && !authenticated) {
		return (
			<BrowserRouter>
				<PublicRoutes />
			</BrowserRouter>
		)
	}
	return null
}

export default FreightProsRouter
