import React from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import useStyles from './styles'

const InvoiceInfo = () => {
	const classes = useStyles()
	const invoiceDetails = useSelector((state) => state.invoiceDetails.invoiceDetails)

	function currencyFormat(num) {
		return num !== undefined && num !== null ? `$${num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}` : num
	}

	return (
		<div className={classes.invoiceInfo}>
			<div className={classes.invoiceInfoColumn}>
				<div className={classes.invoiceInfoRow}>
					<b>Total Weight:</b>
					<span>{`${invoiceDetails.total_weight} lb`}</span>
				</div>
				<div className={classes.invoiceInfoRow}>
					<b>Total Piece Count:</b>
					<span>{invoiceDetails.total_pieces}</span>
				</div>
			</div>
			<div className={classes.invoiceInfoColumn}>
				<div className={`${classes.invoiceInfoRow} ${classes.invoiceInfoRowSpace}`}>
					<b>Last Payment Date:</b>
					<span>
						{invoiceDetails?.last_payment_date ? moment(invoiceDetails.last_payment_date).format('MM/DD/YYYY') : ''}
					</span>
				</div>
				<div className={classes.invoiceInfoRow}>
					<b>Total Charges:</b>
					<span>{currencyFormat(invoiceDetails.total_amount)}</span>
				</div>
				<div className={classes.invoiceInfoRow}>
					<b>Applied Payments:</b>
					<span>{currencyFormat(invoiceDetails.applied_payments)}</span>
				</div>
				<div className={`${classes.invoiceInfoRow} ${classes.invoiceInfoRowBalance}`}>
					<b>Balance Due:</b>
					<span>{currencyFormat(invoiceDetails.balance_due_amount)}</span>
				</div>
			</div>
		</div>
	)
}

InvoiceInfo.propTypes = {}

export default InvoiceInfo
