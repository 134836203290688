import React, { useState, useEffect, useRef } from 'react'
import {
	Button,
	Dialog,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	TextField,
	Tooltip,
	useTheme,
} from '@material-ui/core'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import AddIcon from '@material-ui/icons/Add'
import PropTypes from 'prop-types'
import useStyles from './styles'
import { HazIcon, StackIcon } from '../../../../assets/Icons/Icons'
import packageTypeOptions, { freightClassOptions } from '../../../../utils/dropDownOptions'
import { capitalizeFirstLetter } from '../../../../utils/helpers'
import DetailsForm from './components/DetailsForm'

const EditItemModal = ({ isOpen, itemData, updateItem, setIsOpen, setNmfcCode, setProductDescription, nmfcCode }) => {
	const theme = useTheme()
	const classes = useStyles()

	const [itemToUpdate, setItemToUpdate] = useState(itemData)
	const [errorNFMC, setErrorNFMC] = useState('')

	const [detailForm, setDetailForm] = useState([])
	const detailsInputsRef = useRef([])
	const detailsRef = useRef(itemToUpdate)

	useEffect(() => {
		setItemToUpdate(itemData)
	}, [itemData])

	useEffect(() => {
		detailsRef.current = itemToUpdate
	}, [itemToUpdate])

	const handleClose = () => {
		setIsOpen(false)
	}

	const deleteItemDetail = (itemDetailIndex) => {
		const newItem = JSON.parse(JSON.stringify(itemToUpdate))
		newItem.item_details.splice(itemDetailIndex, 1)
		setItemToUpdate(newItem)
	}
	const onSave = () => {
		setErrorNFMC('')
		updateItem(detailsRef.current)
		setDetailForm([])
	}
	useEffect(() => {
		if (nmfcCode.length === 0) {
			setErrorNFMC('')
		}
	}, [nmfcCode])

	const validateDetailForms = async () => {
		if (detailsInputsRef.current) {
			const detailsResponse = []
			detailsInputsRef.current.forEach((ref, index) => {
				if (ref) {
					setTimeout(() => {
						const response = ref.submitForm()
						detailsResponse[index] = response
					}, 1000)
				}
			})
			setTimeout(() => {
				if (detailsResponse.find((element) => element === 'error')) {
					return
				}
				onSave()
			}, 1000)
		}
	}

	const deleteDetailForm = (accountId) => {
		const newDetail = [...detailForm]
		const filteredDetails = newDetail.filter((item) => item.id !== accountId)
		setDetailForm(filteredDetails)
	}

	return (
		<Dialog
			disableBackdropClick
			disableEscapeKeyDown
			open={isOpen}
			onClose={handleClose}
			onClick={(event) => event.stopPropagation()}
			PaperProps={{
				className: classes.dialogPaper,
			}}
		>
			<div className={classes.editItemModalContainer}>
				<h2 className={classes.editItemModalTitle}>{`Edit Item - ${String.fromCharCode(66)}`}</h2>
				<Grid container spacing={1} style={{ maxWidth: '100%' }}>
					<Grid item xs={6} sm={6} lg={2} xl={2}>
						<FormControl style={{ width: '100%' }} variant="outlined">
							<InputLabel className={classes.root}>Package type *</InputLabel>
							<Select name="PackageType" defaultValue={itemToUpdate.pieces_unit} disabled label="Package Type">
								{packageTypeOptions.map((option, index) => (
									<MenuItem value={option} key={`id-${index + 1}`}>
										{capitalizeFirstLetter(option)}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={6} sm={6} lg={1} xl={1}>
						<TextField
							name="quantity"
							className={classes.root}
							type="number"
							disabled
							defaultValue={itemToUpdate.pieces_value}
							FormHelperTextProps={{ classes: { root: classes.helperText } }}
							label="Quantity"
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={6} sm={6} lg={2} xl={2}>
						<FormControl style={{ width: '100%' }} variant="outlined">
							<InputLabel className={classes.root}>FreightClass *</InputLabel>
							<Select name="FreightClass" defaultValue={itemToUpdate.class} disabled label="FreightClass">
								{freightClassOptions.map((option, index) => (
									<MenuItem value={option} key={`id-${index + 1}`}>
										{option}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={6} sm={6} lg={1} xl={1}>
						<TextField
							name="NMFC"
							type="text"
							value={nmfcCode}
							onChange={(event) => setNmfcCode(event.target.value)}
							className={classes.inputNumber}
							label="NMFC"
							variant="outlined"
							error={!!errorNFMC.length > 0}
							helperText={errorNFMC.length > 0 ? errorNFMC : null}
						/>
					</Grid>
					<Grid item xs={6} sm={6} lg={1} xl={1}>
						<TextField
							name="weight"
							type="number"
							disabled
							className={classes.root}
							defaultValue={itemToUpdate.weight_value}
							label="Total Weight(Lb)"
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={6} sm={6} lg={2} xl={2}>
						<FormControl style={{ width: '100%' }} variant="outlined">
							<InputLabel className={classes.root} htmlFor="shipment-dimesions">
								Dimensions(In) *
							</InputLabel>
							<OutlinedInput
								type="text"
								name="shipmentDimensions"
								id="shipment-dimesions"
								labelWidth={150}
								disabled
								readOnly
								value={`${itemToUpdate.dimensions_lenght}" x ${itemToUpdate.dimensions_width}" x ${itemToUpdate.dimensions_height}"`}
							/>
							<FormHelperText className={classes.helperText}>Length x Width x Height</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={8} sm={6} lg={2} xl={2}>
						<TextField
							name="commodity"
							className={classes.root}
							style={{ width: '100%' }}
							defaultValue={itemToUpdate.description}
							onChange={(event) => setProductDescription(event.target.value)}
							label="Product description"
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={4} lg={1} xl={1}>
						<div className={classes.editItemModalIconsContainer}>
							<Tooltip title="Hazmat such as chemicals, acid, waste with a UN number">
								<span>
									<HazIcon
										className={classes.actionIcon}
										htmlColor={itemToUpdate.is_haz_mat ? theme.palette.secondary.main : theme.palette.neutral.darkGray}
									/>
								</span>
							</Tooltip>
							<Tooltip title="Items that can be stacked to reduce linear space">
								<span>
									<StackIcon
										className={classes.actionIcon}
										htmlColor={itemToUpdate.stackable ? theme.palette.secondary.main : theme.palette.neutral.darkGray}
									/>
								</span>
							</Tooltip>
						</div>
					</Grid>
				</Grid>
				<Grid item>
					<h4>Item Details</h4>
					{itemToUpdate.item_details?.map((itemDetail, itemDetailIndex) => (
						<Grid container spacing={1} style={{ marginLeft: '15px', maxWidth: '100%', marginBottom: '1em' }}>
							<Grid item xs={6} sm={6} lg={2} xl={2}>
								<FormControl style={{ width: '100%' }} variant="outlined">
									<InputLabel className={classes.root}>Package type *</InputLabel>
									<Select
										name="PackageType"
										label="Package Type *"
										className={classes.shipmentItemFormControl}
										value={itemDetail.package_type}
										disabled
									>
										<MenuItem value={itemDetail.package_type}>
											{capitalizeFirstLetter(itemDetail.package_type)}
										</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={6} sm={6} lg={1} xl={1}>
								<TextField
									value={itemDetail.quantity}
									className={classes.inputNumber}
									type="number"
									disabled
									FormHelperTextProps={{ classes: { root: classes.helperText } }}
									label="Quantity *"
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={8} sm={6} lg={7} xl={7}>
								<TextField
									value={itemDetail.description}
									className={classes.root}
									disabled
									style={{ width: '100%' }}
									label="Product description"
									variant="outlined"
								/>
							</Grid>
							<Grid item lg={2} xl={2} style={{ display: 'flex', alignItems: 'center' }}>
								<Button
									type="button"
									aria-label="Remove item detail"
									onClick={() => {
										deleteItemDetail(itemDetailIndex)
									}}
									className={classes.ediItemModalActionButton}
								>
									<DeleteOutlineIcon color="secondary" />
									<span>Remove Detail</span>
								</Button>
							</Grid>
						</Grid>
					))}
				</Grid>
				{detailForm.map((item, index) => (
					<DetailsForm
						key={`id-${index + 1}`}
						itemData={itemData}
						detailItem={item}
						itemToUpdate={itemToUpdate}
						setItemToUpdate={setItemToUpdate}
						deleteDetailForm={deleteDetailForm}
						setDetailForm={setDetailForm}
						ref={(el) => {
							detailsInputsRef.current[index] = el
						}}
					/>
				))}
				<Grid container spacing={1} style={{ marginLeft: '15px', maxWidth: '100%' }}>
					<Grid
						container
						style={{
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<Button
							type="button"
							className={classes.ediItemModalActionButton}
							aria-label="Add new detail"
							onClick={() => {
								setDetailForm((prevState) => [
									...prevState,
									prevState.length > 0 ? { id: Number(prevState.slice(-1)[0].id) + 1 } : { id: 0 },
								])
							}}
						>
							<AddIcon color="secondary" />
							<span>Add New Detail</span>
						</Button>
					</Grid>
				</Grid>
				<Grid item style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '2em' }}>
					<Button
						onClick={() => {
							setIsOpen(false)
							setDetailForm([])
						}}
						className={classes.editItemModalCancelButton}
						variant="contained"
					>
						Cancel
					</Button>
					<Button
						className={classes.editItemModalAddButton}
						type="button"
						color="secondary"
						variant="contained"
						onClick={() => validateDetailForms()}
					>
						Update Item
					</Button>
				</Grid>
			</div>
		</Dialog>
	)
}

EditItemModal.propTypes = {
	itemData: PropTypes.objectOf(PropTypes.any).isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.bool.isRequired,
	updateItem: PropTypes.func.isRequired,
	setNmfcCode: PropTypes.func.isRequired,
	setProductDescription: PropTypes.func.isRequired,
	nmfcCode: PropTypes.string.isRequired,
}

export default EditItemModal
