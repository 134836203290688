import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	paper: {
		borderBottomWidth: 6,
		borderBottomStyle: 'solid',
		borderBottomColor: theme.palette.secondary.main,
	},
	dialogTitle: {
		padding: theme.spacing(3, 4, 2),
		textAlign: 'center',
		color: theme.palette.secondary.main,

		'& h2': {
			margin: 0,
		},
	},
	dialogContent: {
		padding: theme.spacing(0, 4, 3),
	},
	dialogActions: {
		display: 'block',
		padding: theme.spacing(0, 4, '15px'),
	},
	dialogButton: {
		display: 'block',
		padding: '13px 16px',
		width: '100%',
		marginBottom: theme.spacing(2),
		fontSize: 17,
		textTransform: 'none',
		fontWeight: theme.typography.fontWeightMedium,
		'&:last-of-type': {
			marginBottom: 0,
		},
	},
	dialogLabel: {
		fontSize: 14,
		fontWeight: theme.typography.fontWeightBold,
		color: theme.palette.neutral.dark,
	},
	chipList: {
		padding: theme.spacing(2, 0, 0),
		margin: 0,
		listStyle: 'none',
	},
	chip: {
		margin: theme.spacing(0, 0, 1, 0),
		backgroundColor: theme.palette.neutral.secondaryGrey,
		border: `1px solid ${theme.palette.neutral.mainGray}`,
		fontSize: 14,
		textTransform: 'capitalize',
		'li:last-child &': {
			marginBottom: 0,
		},
	},
	chipDeleteIcon: {
		color: theme.palette.primary.main,
	},
}))

export default useStyles
