import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import { loadTracking, cleanTracking } from '../../../../../redux/actions/Tracking.actions'
import GoogleMapLoader from '../../../../../components/GoogleMap/index'
import useStyles from './styles'
import theme from '../../../../../themes/freightpros'
import useWindowDimensions from '../../../../../hooks/useWindowDimensions'
import Activities from './components/Activities'
import PingBlueOrigin from '../../../../../assets/svg/p2blue.svg'
import PingBlueDestination from '../../../../../assets/svg/pblue.svg'

const markersData = (origin, destination) => [
	{
		coordinate: {
			lat: origin?.latitude ?? 0,
			lng: origin?.longitude ?? 0,
		},
		content: {
			type: 'Origin',
			city: '',
		},
		icon: PingBlueOrigin,
		iconOffset: { x: 13.5, y: 0 },
	},
	{
		coordinate: {
			lat: destination?.latitude ?? 0,
			lng: destination?.longitude ?? 0,
		},
		content: {
			type: 'Destination',
			city: '',
		},
		icon: PingBlueDestination,
		iconOffset: { x: 13.5, y: 0 },
	},
]
const getCenterMap = (origin, destination) => {
	const getSum = (item1, item2) => (item1 + item2) / 2
	const centerCoordinate = {
		latitudeCenter: getSum(origin?.latitude ?? 0, destination?.latitude ?? 0),
		longitudeCenter: getSum(origin?.longitude ?? 0, destination?.longitude ?? 0),
	}
	return centerCoordinate
}

const Tracking = ({ shipmentDetail }) => {
	const { data, error } = useSelector((state) => state.tracking)
	const dispatch = useDispatch()
	const classes = useStyles()
	const { width } = useWindowDimensions()
	const getHeightMap = () => {
		let height = 23.68 // mobile size
		if (width >= theme.breakpoints.values.md && width < theme.breakpoints.values.lg) {
			height = 27.68 // tablet
		} else if (width >= theme.breakpoints.values.lg) {
			height = 34.68 // desktop
		}
		return height
	}
	useEffect(() => {
		dispatch(loadTracking(shipmentDetail?.bol_identifier ?? ''))
		return () => {
			dispatch(cleanTracking())
		}
	}, [dispatch, shipmentDetail])
	const getProNumber = () => {
		const proNumber = shipmentDetail?.references?.find((reference) => reference.type === 'PRO')
		if (proNumber !== undefined) {
			return proNumber?.value ?? ''
		}
		return 'Not assigned yet.'
	}
	return (
		<Grid container className={classes.container}>
			<Grid container className={classes.content}>
				<Grid item xs={12} className={classes.containerMap}>
					<GoogleMapLoader
						center={{
							lat: getCenterMap(shipmentDetail?.shipperCoordinates, shipmentDetail?.consigneeCoordinates)
								.latitudeCenter,
							lng: getCenterMap(shipmentDetail?.shipperCoordinates, shipmentDetail?.consigneeCoordinates)
								.longitudeCenter,
						}}
						zoom={4.8}
						origin={{
							coordinate: {
								lat: shipmentDetail?.shipperCoordinates?.latitude ?? 0,
								lng: shipmentDetail?.shipperCoordinates?.longitude ?? 0,
							},
						}}
						destination={{
							coordinate: {
								lat: shipmentDetail?.consigneeCoordinates?.latitude ?? 0,
								lng: shipmentDetail?.consigneeCoordinates?.longitude ?? 0,
							},
						}}
						draggable={false}
						heightMap={getHeightMap()}
						markers={markersData(shipmentDetail?.shipperCoordinates, shipmentDetail?.consigneeCoordinates)}
					/>
				</Grid>
				<Grid item xs={12}>
					<h3 className={classes.proNumberMessage}>Pronumber: {getProNumber()}</h3>
				</Grid>
				{Object.keys(data).length > 0 && (
					<Grid item xs={12} className={classes.containerActivities}>
						{[...data?.activities]
							.sort((item, next) => moment(next.date).unix() - moment(item.date).unix())
							.map((activity, i) => (
								<Activities key={activity?.id} isFinal={i === 0} info={activity} />
							))}
						{[...data?.activities].length === 0 && (
							<h3 className={classes.documentsMessageTitle}>No activities have been registered yet</h3>
						)}
					</Grid>
				)}
				{error && (
					<h3 className={classes.documentsMessageTitle}>
						{error ===
						`Tracking data for shipment with BOL number: ${shipmentDetail?.bol_identifier ?? ''} was not found`
							? 'Tracking details will be available after the shipment picks up.'
							: error}
					</h3>
				)}
			</Grid>
		</Grid>
	)
}

Tracking.propTypes = {
	shipmentDetail: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default Tracking
