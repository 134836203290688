/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import { ReactComponent as Download } from '../svg/downloadIcon.svg'
import { ReactComponent as Help } from '../svg/Icon_help.svg'
import { ReactComponent as Origin } from '../svg/Icon_Origin.svg'
import { ReactComponent as Information } from '../svg/Icon_information.svg'
import { ReactComponent as Destination } from '../svg/Icon_destination.svg'
import { ReactComponent as ShipmentItem } from '../svg/Icon_items.svg'
import { ReactComponent as SpecialInstructions } from '../svg/SpecialInstructions.svg'
import { ReactComponent as Support } from '../svg/supportIcon.svg'
import { ReactComponent as Haz } from '../svg/Haz.svg'
import { ReactComponent as Stack } from '../svg/Stack.svg'
import { ReactComponent as OriginPoint } from '../svg/Origin_Point.svg'
import { ReactComponent as DestinationPoint } from '../svg/Destination_Point.svg'
import { ReactComponent as SortList } from '../svg/Sort_List.svg'
import { ReactComponent as Ascending } from '../svg/Ascending.svg'
import { ReactComponent as Descending } from '../svg/Descending.svg'
import { ReactComponent as Truck } from '../svg/Truck.svg'
import { ReactComponent as Invoice } from '../svg/Invoice.svg'
import { ReactComponent as PastDue } from '../svg/PastDue.svg'
import { ReactComponent as OutstandingBalance } from '../svg/OutstandingBalance.svg'
import { ReactComponent as PikingUp } from '../svg/PikingUp.svg'
import { ReactComponent as Delivered } from '../svg/Delivered.svg'
import { ReactComponent as Announce } from '../svg/Announce.svg'
import { ReactComponent as IconLaneInfo } from '../svg/IconLaneInfo.svg'
import { ReactComponent as HelpCenter } from '../svg/HelpCenter.svg'
import { ReactComponent as NoData } from '../svg/noDataIcon.svg'
import { ReactComponent as AppSwitcher } from '../svg/Apps.svg'
import { ReactComponent as BeonLogo } from '../svg/BeonLogo.svg'

export const NoDataIcon = (props) => (
	<SvgIcon {...props}>
		<NoData />
	</SvgIcon>
)

export const DownloadIcon = (props) => (
	<SvgIcon {...props}>
		<Download />
	</SvgIcon>
)

export const HelpIcon = (props) => (
	<SvgIcon {...props}>
		<Help />
	</SvgIcon>
)

export const InformationIcon = (props) => (
	<SvgIcon {...props}>
		<Information />
	</SvgIcon>
)

export const OriginIcon = (props) => (
	<SvgIcon {...props}>
		<Origin />
	</SvgIcon>
)

export const DestinationIcon = (props) => (
	<SvgIcon {...props}>
		<Destination />
	</SvgIcon>
)

export const ShipmentItemsIcon = (props) => (
	<SvgIcon {...props}>
		<ShipmentItem />
	</SvgIcon>
)

export const SpecialInstructionsIcon = (props) => (
	<SvgIcon {...props}>
		<SpecialInstructions />
	</SvgIcon>
)

export const SupportIcon = (props) => (
	<SvgIcon {...props}>
		<Support />
	</SvgIcon>
)

export const HazIcon = (props) => (
	<SvgIcon {...props}>
		<Haz />
	</SvgIcon>
)

export const StackIcon = (props) => (
	<SvgIcon {...props}>
		<Stack />
	</SvgIcon>
)

export const OriginPointIcon = (props) => (
	<SvgIcon {...props}>
		<OriginPoint />
	</SvgIcon>
)

export const DestinationPointIcon = (props) => (
	<SvgIcon {...props}>
		<DestinationPoint />
	</SvgIcon>
)

export const SortListIcon = (props) => (
	<SvgIcon {...props}>
		<SortList />
	</SvgIcon>
)

export const AscendingIcon = (props) => (
	<SvgIcon {...props}>
		<Ascending />
	</SvgIcon>
)

export const DescendingIcon = (props) => (
	<SvgIcon {...props}>
		<Descending />
	</SvgIcon>
)

export const TruckIcon = (props) => (
	<SvgIcon {...props}>
		<Truck />
	</SvgIcon>
)

export const InvoiceIcon = (props) => (
	<SvgIcon {...props}>
		<Invoice />
	</SvgIcon>
)

export const PastDueIcon = (props) => (
	<SvgIcon {...props}>
		<PastDue />
	</SvgIcon>
)

export const OutStandingBalanceIcon = (props) => (
	<SvgIcon {...props}>
		<OutstandingBalance />
	</SvgIcon>
)

export const PikingUpIcon = (props) => (
	<SvgIcon {...props}>
		<PikingUp />
	</SvgIcon>
)

export const DeliveredIcon = (props) => (
	<SvgIcon {...props}>
		<Delivered />
	</SvgIcon>
)

export const AnnounceIcon = (props) => (
	<SvgIcon {...props}>
		<Announce />
	</SvgIcon>
)

export const LaneInfoIcon = (props) => (
	<SvgIcon {...props}>
		<IconLaneInfo />
	</SvgIcon>
)

export const HelpCenterIcon = (props) => (
	<SvgIcon {...props}>
		<HelpCenter />
	</SvgIcon>
)

export const AppSwitcherIcon = (props) => (
	<SvgIcon {...props}>
		<AppSwitcher />
	</SvgIcon>
)

export const BeonLogoIcon = (props) => (
	<SvgIcon {...props}>
		<BeonLogo />
	</SvgIcon>
)
