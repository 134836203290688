import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { ResponsivePie } from '@nivo/pie'
import { Box, Card, CardHeader, CardMedia, useTheme } from '@material-ui/core'
import useStyles from './styles'
import { reportsRoute } from '../../../../utils/constants'

const PieChart = ({ data, title }) => {
	const theme = useTheme()
	const classes = useStyles()
	const [chartData, setChartData] = useState([])

	const chartOptions = {
		animate: true,
		activeInnerRadiusOffset: 0,
		activeOuterRadiusOffset: 8,
		borderWidth: 0,
		colors: [
			theme.palette.semantic.green.primary,
			theme.palette.semantic.yellow.primary,
			theme.palette.semantic.orange.primary,
			theme.palette.semantic.orange.light,
			theme.palette.semantic.purple.primary,
			theme.palette.neutral.mainGray,
		],
		cornerRadius: 0,
		enableArcLabels: false,
		enableArcLinkLabels: false,
		innerRadius: 0.65,
		isInteractive: true,
		margin: {
			top: 8,
			right: 8,
			bottom: 8,
			left: 8,
		},
		motionConfig: 'gentle',
		sortByValue: true,
		startAngle: -180,
		transitionMode: 'innerRadius',
		tooltip: (e) => {
			const { datum } = e
			return (
				<div className={classes.tooltip}>
					<p>
						<strong>{`${datum.label.toUpperCase()}: `}</strong>
						<span>{`${datum.value}%`}</span>
					</p>
				</div>
			)
		},
	}

	useEffect(() => {
		if (data.length) {
			const remainingValue =
				100 -
				data
					.map(
						// eslint-disable-next-line camelcase
						({ percentage_use }) => Number(percentage_use),
					)
					.reduce((a, b) => a + b)
			// eslint-disable-next-line camelcase
			const computedData = data.map(({ carrier_scac, percentage_use }) => ({
				id: carrier_scac,
				label: carrier_scac,
				value: parseFloat(Number(percentage_use)),
			}))
			if (remainingValue > 0) {
				computedData.push({
					id: 'others',
					label: 'others',
					value: remainingValue % 1 !== 0 ? remainingValue.toFixed(2) : remainingValue,
				})
			}
			setChartData(computedData)
		}
	}, [data])

	return (
		<Link to={`${reportsRoute}?report=carrier-usage`} style={{ textDecoration: 'none' }}>
			<Card>
				<CardHeader
					classes={{
						root: classes.cardHeader,
						title: classes.cardHeaderTitle,
					}}
					title={title}
				/>
				<CardMedia className={classes.cardMedia}>
					<Box className={classes.chartWrapper}>
						<ResponsivePie
							data={chartData}
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...chartOptions}
						/>
					</Box>
					<Box className={classes.legendWrapper}>
						<ul className={classes.legendList}>
							{chartData.map(({ label, value, id }, i) => (
								<li key={id} className={classes.legendListItem}>
									<div className={classes.legendListBox} style={{ backgroundColor: chartOptions.colors[i] }} />
									<p className={classes.legendListLabel}>{label}</p>
									<p className={classes.legendListValue}>{`${value}%`}</p>
								</li>
							))}
						</ul>
					</Box>
				</CardMedia>
			</Card>
		</Link>
	)
}

PieChart.propTypes = {
	data: PropTypes.arrayOf(PropTypes.any).isRequired,
	title: PropTypes.string.isRequired,
}

export default PieChart
