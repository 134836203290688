import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Controller, useWatch } from 'react-hook-form'

import {
	FormControl,
	InputLabel,
	OutlinedInput,
	Popper,
	Grow,
	Paper,
	ClickAwayListener,
	List,
	ListItem,
	TextField,
	FormHelperText,
} from '@material-ui/core'

import useStyles from './styles'

const DimensionsInput = ({ itemIndex, errors, control, item }) => {
	const length = useWatch({ control, name: `items.${itemIndex}.length` })
	const width = useWatch({ control, name: `items.${itemIndex}.width` })
	const height = useWatch({ control, name: `items.${itemIndex}.height` })
	const [open, setOpen] = useState(false)
	const anchorRef = useRef(null)
	const classes = useStyles()

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) return
		setOpen(false)
	}

	return (
		<>
			<FormControl size="small" style={{ width: '100%' }} variant="outlined" error={!!errors?.item}>
				<InputLabel className={classes.root} htmlFor={`shipment-dimensions-${itemIndex}`}>
					Dimensions (in) *
				</InputLabel>
				<OutlinedInput
					type="text"
					name="shipmentDimensions"
					id={`shipment-dimensions-${itemIndex}`}
					label="Dimensions (in) *"
					ref={anchorRef}
					onClick={() => setOpen(true)}
					onFocus={() => setOpen(true)}
					className={classes.shipmentItemFormControl}
					labelWidth={150}
					readOnly
					value={length && width && height ? `${length}" x ${width}" x ${height}"` : ''}
					error={!!errors?.length || !!errors?.width || !!errors?.height}
				/>
				<FormHelperText className={classes.helperText}>Length x Width x Height</FormHelperText>
			</FormControl>
			<Popper
				className={classes.popper}
				style={{ display: open ? 'inherit' : 'none' }}
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				keepMounted
				disablePortal
			>
				{({ TransitionProps, placement }) => (
					<Grow
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...TransitionProps}
						style={{
							transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
						}}
					>
						<Paper elevation={4}>
							<ClickAwayListener onClickAway={handleClose}>
								<List dense>
									<ListItem>
										<Controller
											control={control}
											name={`items.${itemIndex}.length`}
											defaultValue={item.length}
											render={({ name, onChange, value }) => (
												<TextField
													name={name}
													size="small"
													type="number"
													autoFocus
													className={classes.root}
													value={value}
													InputProps={{ inputProps: { min: 0 } }}
													onChange={(e) => {
														const inputValue = Number(e.target.value)
														onChange(inputValue)
													}}
													error={!!errors?.length}
													helperText="Max. 288"
													FormHelperTextProps={{ classes: { root: classes.helperText } }}
													label="Length(In) *"
													variant="outlined"
												/>
											)}
											rules={{
												required: { value: true, message: 'This value is required!' },
												min: { value: 0, message: 'Min. 0' },
												max: { value: 288, message: 'Max. 288' },
											}}
										/>
									</ListItem>
									<ListItem>
										<Controller
											control={control}
											name={`items.${itemIndex}.width`}
											defaultValue={item.width}
											render={({ value, name, onChange }) => (
												<TextField
													name={name}
													type="number"
													size="small"
													className={classes.root}
													value={value}
													onChange={(e) => {
														const inputValue = Number(e.target.value)
														onChange(inputValue)
													}}
													error={!!errors?.width}
													helperText="Max. 96"
													FormHelperTextProps={{ classes: { root: classes.helperText } }}
													label="Width(In) *"
													variant="outlined"
												/>
											)}
											rules={{
												required: { value: true, message: 'This value is required!' },
												min: { value: 0, message: 'Min. 0' },
												max: { value: 96, message: 'Max. 96' },
											}}
										/>
									</ListItem>
									<ListItem>
										<Controller
											control={control}
											name={`items.${itemIndex}.height`}
											defaultValue={item.height}
											render={({ value, name, onChange }) => (
												<TextField
													name={name}
													type="number"
													size="small"
													className={classes.root}
													value={value}
													InputProps={{ inputProps: { min: 0 } }}
													onChange={(e) => {
														const inputValue = Number(e.target.value)
														onChange(inputValue)
													}}
													error={!!errors?.height}
													helperText="Max. 96"
													FormHelperTextProps={{ classes: { root: classes.helperText } }}
													label="Height(In) *"
													variant="outlined"
												/>
											)}
											rules={{
												required: { value: true, message: 'This value is required!' },
												min: { value: 0, message: 'Min. 0' },
												max: { value: 96, message: 'Max. 96' },
											}}
										/>
									</ListItem>
								</List>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	)
}

DimensionsInput.propTypes = {
	itemIndex: PropTypes.number.isRequired,
	control: PropTypes.func.isRequired,
	errors: PropTypes.objectOf(PropTypes.any).isRequired,
	item: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default DimensionsInput
