import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import moment from 'moment'
import { Box, Card, CardHeader, CardMedia, IconButton, Tooltip, Typography } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { DownloadIcon } from '../../../../assets/Icons/Icons'
import useStyles from './styles'
import SessionContext from '../../../../context/session'
import { invoicesListRoute, shipmentsListRoute } from '../../../../utils/constants'
import { setStatus as setInvoiceStatus } from '../../../../redux/actions/InvoicesFilters.actions'
import {
	setStatus as setShipmentStatus,
	setEndDate,
	setStartDate,
} from '../../../../redux/actions/ShipmentsFilters.actions'

const DataChart = ({ data, title, icon, color, currency, reportStatus }) => {
	const classes = useStyles(color)
	const dispatch = useDispatch()
	const history = useHistory()
	const { getUserData, getTokenUser } = useContext(SessionContext)
	const { actualCompany } = getUserData()
	const token = getTokenUser()
	const goToReport = () => {
		const useShipment = ['IN TRANSIT', 'PICKING UP TODAY', 'DELIVERED']
		// const invoicesStatus = ['Unpaid', 'Outstanding', 'Past'];
		const invoicesStatus = ['UNPAID']
		if (useShipment.includes(reportStatus)) {
			if (reportStatus === 'DELIVERED') {
				dispatch(setEndDate(moment().format('YYYY-MM-DD')))
				dispatch(setStartDate(moment().subtract(30, 'days').format('YYYY-MM-DD')))
			}
			dispatch(setShipmentStatus([reportStatus]))
			history.push(shipmentsListRoute)
		}
		if (invoicesStatus.includes(reportStatus)) {
			dispatch(
				setInvoiceStatus([[...reportStatus].map((letter, i) => (i !== 0 ? letter.toLowerCase() : letter)).join('')]),
			)
			history.push(invoicesListRoute)
		}
	}

	const handleDownloadCSV = (event) => {
		event.stopPropagation()
		const invoicesStatus = ['UNPAID', 'OUTSTANDING', 'PAST']
		const p = {
			initial_date: moment(new Date()).subtract(365, 'days').format('YYYY-MM-DD'),
			end_date: moment(new Date()).format('YYYY-MM-DD'),
			status: reportStatus,
		}

		let qs = Object.keys(p)
			.map((key) => `${key}=${p[key]}`)
			.join('&')
		let url = ''
		let file = ''
		if (invoicesStatus.includes(reportStatus)) {
			url = `${process.env.REACT_APP_BACKEND_REPORTS_BASE_URL}/report/invoice/dashboard-cards?type=${reportStatus}`
			file = `${reportStatus.toLowerCase()}-invoices.csv`
		} else if (reportStatus === 'TODAY') {
			url = `${process.env.REACT_APP_BACKEND_REPORTS_BASE_URL}/report/shipment/picking-up-today`
			file = 'shipments-picking-up-today.csv'
		} else {
			if (reportStatus === 'DELIVERED') {
				p.initial_date = moment().subtract(30, 'days').format('YYYY-MM-DD')
				p.end_date = moment().add(1, 'day').format('YYYY-MM-DD')
			}
			qs = Object.keys(p)
				.map((key) => `${key}=${p[key]}`)
				.join('&')
			url = `${process.env.REACT_APP_BACKEND_REPORTS_BASE_URL}/report/shipment/dashboard-graphs?${qs}`
			file = `shipments-${reportStatus.toLowerCase().split(' ').join('-')}.csv`
		}

		const oReq = new XMLHttpRequest()
		oReq.open('GET', url, true)
		oReq.responseType = 'arraybuffer'
		oReq.setRequestHeader('X-Company-id', Number(actualCompany))
		oReq.setRequestHeader('Authorization', `Bearer ${token}`)

		oReq.onload = function dowload() {
			const blob = new Blob([oReq.response], { type: 'text/csv' })
			const URL = window.URL || window.webkitURL
			const dataUrl = URL.createObjectURL(blob)
			const downloadLink = document.createElement('a')
			const fileName = file

			downloadLink.href = dataUrl
			downloadLink.download = fileName
			downloadLink.click()
		}

		oReq.send()
	}

	return (
		<Card className={classes.root} role="button" onClick={goToReport}>
			<CardHeader
				classes={{
					root: classes.cardHeader,
					action: classes.cardHeaderAction,
				}}
				action={
					<>
						<Tooltip title="Card Info">
							<IconButton
								aria-label="Card Info"
								size="small"
								className={classes.cardHeaderActionButton}
								onClick={(e) => e.stopPropagation()}
							>
								<InfoOutlinedIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title="Download as CSV">
							<IconButton
								arial-label="Download CSV"
								size="small"
								className={classes.cardHeaderActionButton}
								disabled={data === 0}
								onClick={handleDownloadCSV}
							>
								<DownloadIcon />
							</IconButton>
						</Tooltip>
					</>
				}
			/>
			<CardMedia className={classes.cardMedia}>
				<Box className={classes.chartWrapper}>
					<Box className={clsx(classes.iconWrapper, { [color]: color })}>{icon}</Box>
					<Box>
						<Typography className={clsx(classes.cardNumber, { currency })} noWrap={currency}>
							{currency && data
								? `${data.toLocaleString('en-US', {
										style: 'currency',
										currency: 'USD',
									})} USD`
								: data}
						</Typography>
						<Typography variant="h5" className={classes.cardTitle}>
							{title}
						</Typography>
					</Box>
				</Box>
			</CardMedia>
		</Card>
	)
}

DataChart.propTypes = {
	data: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	icon: PropTypes.node,
	color: PropTypes.string,
	currency: PropTypes.bool,
	reportStatus: PropTypes.string,
}

DataChart.defaultProps = {
	icon: <></>,
	color: '',
	currency: false,
	reportStatus: '',
}

export default DataChart
