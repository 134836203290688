import React, { useState, createRef, useRef } from 'react'
import PropTypes from 'prop-types'

import { Chip } from '@material-ui/core'

import { useHistory } from 'react-router-dom'

import { accountsListRoute } from '../../../../../utils/constants'

import sweetAlertModal from '../../../../../components/SweetAlertModal'
import SimpleStepper from '../../../../../components/SimpleStepper'
import AccountInformationStepper from '../AccountInformationStepper/AccountInformationStepper'
import SettingsStepper from '../SettingsStepper/SettingsStepper'
import UsersStepper from '../UsersStepper/UsersStepper'
import CarriersStepper from '../CarriersStepper/CarriersStepper'

import useStyles from './styles'

const steps = ['Account information', 'Settings', 'Users', 'Carriers']

const StepperContainer = (props) => {
	const classes = useStyles()
	const { isEditable } = props
	const history = useHistory()
	const [activeStep, setActiveStep] = useState(1)
	const [isEdit, setIsEdit] = useState(false)
	const [isPreviousStepTwo, setIsPreviousStepTwo] = useState(false)
	const [accountNameTitle, setAccountNameTitle] = useState('')
	const mainRef = useRef(null)
	const formTexts = {
		title: isEditable ? 'Edit Account' : 'New Account',
		subtitle: isEditable
			? 'Review and edit information about this account'
			: 'Insert information about the new account',
	}
	const onSubmitStep1 = createRef()
	const onSubmitStep2 = createRef()
	const onSubmitStep3 = createRef()

	const handleCancelAccountProcess = () => {
		const processType = isEditable ? 'edition' : 'creation'
		sweetAlertModal(
			'warning',
			`Are you sure you want to cancel the account ${processType} process?`,
			null,
			'Yes',
			true,
			true,
			'No',
		).then((result) => {
			if (result.isConfirmed) {
				history.push(accountsListRoute)
			}
		})
	}
	const clickStep = (number) => {
		if (isEditable) {
			setActiveStep(number) // edit account
		} else if (activeStep > number) {
			// new account
			switch (number) {
				case 1:
					setIsEdit(true)
					break
				case 2:
					setIsPreviousStepTwo(true)
					break
				default:
					break
			}
			setActiveStep(number)
		} else if (number > activeStep) {
			// new account
			switch (activeStep) {
				case 1:
					onSubmitStep1.current.onSubmit()
					break
				case 2:
					onSubmitStep2.current.onSubmit()
					break
				case 3:
					onSubmitStep3.current.onSubmit()
					break
				default:
					break
			}
		}
	}

	return (
		<div ref={mainRef} className={classes.newAccountStepperContainer}>
			<div className={classes.newAccountStepperTitleContainer}>
				<div className={classes.newAccountStepperTitle}>
					<h1 className={classes.newAccountStepperLabel}>
						{formTexts.title}
						{accountNameTitle ? `: ${accountNameTitle}` : ''}
					</h1>
					{isEditable && <Chip label="Active" className={classes.newAccountStepperChip} />}
				</div>
				<span className={classes.newAccountStepperBody}>{formTexts.subtitle}</span>
				<SimpleStepper activeStep={activeStep} steps={steps} onClick={clickStep} />
			</div>
			{activeStep === 1 && (
				<AccountInformationStepper
					ref={onSubmitStep1}
					setActiveStep={setActiveStep}
					activeStep={activeStep}
					isEdit={isEdit}
					setIsEdit={setIsEdit}
					isEditable={isEditable}
					handleCancelAccountProcess={handleCancelAccountProcess}
					setAccountNameTitle={setAccountNameTitle}
				/>
			)}
			{activeStep === 2 && (
				<SettingsStepper
					ref={onSubmitStep2}
					setActiveStep={setActiveStep}
					activeStep={activeStep}
					isPreviousStepTwo={isPreviousStepTwo}
					isEditable={isEditable}
					setIsEdit={setIsEdit}
					handleCancelAccountProcess={handleCancelAccountProcess}
				/>
			)}
			{activeStep === 3 && (
				<UsersStepper
					ref={onSubmitStep3}
					setActiveStep={setActiveStep}
					activeStep={activeStep}
					setIsPreviousStepTwo={setIsPreviousStepTwo}
					isEditable={isEditable}
					handleCancelAccountProcess={handleCancelAccountProcess}
				/>
			)}
			{activeStep === 4 && (
				<CarriersStepper
					setActiveStep={setActiveStep}
					activeStep={activeStep}
					isEditable={isEditable}
					handleCancelAccountProcess={handleCancelAccountProcess}
					mainRef={mainRef}
				/>
			)}
		</div>
	)
}

StepperContainer.propTypes = {
	isEditable: PropTypes.bool.isRequired,
}

export default StepperContainer
