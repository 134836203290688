/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import reqShiplify from '../requests/Shiplify.request'

const initState = {
	dataForm: {},
	suggestions: {},
	isLoading: false,
	error: false,
	ignoredByUser: false,
	acceptedByUser: false,
}

const shiplifySlice = createSlice({
	name: 'shiplifySlice',
	initialState: {
		...initState,
	},
	reducers: {
		startLoading: (state) => {
			state.isLoading = true
		},
		hasError: (state, action) => {
			state.error = action.payload
			state.isLoading = false
		},
		suggestionsSuccess: (state, action) => {
			state.suggestions = action.payload
			state.isLoading = false
		},
		setIgnoredByUser: (state, action) => {
			state.ignoredByUser = action.payload
		},
		setAcceptedByUser: (state, action) => {
			state.acceptedByUser = action.payload
		},
		setDataForm: (state, action) => {
			state.dataForm = action.payload
		},
		clearSuggestions: (state) => {
			state.suggestions = initState.suggestions
			state.isLoading = initState.isLoading
			state.error = initState.error
		},
		clearModalState: (state) => {
			state.ignoredByUser = initState.ignoredByUser
			state.acceptedByUser = initState.acceptedByUser
			state.suggestions = initState.suggestions
		},
	},
})

const { actions, reducer } = shiplifySlice

export const {
	startLoading,
	hasError,
	suggestionsSuccess,
	setIgnoredByUser,
	setAcceptedByUser,
	setDataForm,
	clearSuggestions,
	clearModalState,
} = actions

export const fetchShiplifySuggestions = (body, config) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqShiplify(body, config)
	if (error) {
		dispatch(hasError(error))
		return { status: 'error', resp: data }
	}
	dispatch(suggestionsSuccess(data.data.data))
	return { status: 'success' }
}

export const updateUserIgnored = (params) => async (dispatch) => {
	dispatch(setIgnoredByUser(params))
}

export const updateUserAccepted = (params) => async (dispatch) => {
	dispatch(setAcceptedByUser(params))
}

export const updateDataForm = (data) => async (dispatch) => {
	dispatch(setDataForm(data))
}

export const resetShiplify = () => async (dispatch) => {
	dispatch(clearSuggestions())
}

export const resetShiplifyModalState = () => async (dispatch) => {
	dispatch(clearModalState())
}

export default reducer
