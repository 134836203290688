import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useSelector, useDispatch } from 'react-redux'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from '@material-ui/core/AppBar'
import Container from '@material-ui/core/Container'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { Search } from '@material-ui/icons'
import Toolbar from '@material-ui/core/Toolbar'
import MenuIcon from '@material-ui/icons/Menu'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
	Dialog,
	DialogContent,
	DialogTitle,
	Select,
	InputLabel,
	FormControl,
	DialogActions,
	Button,
	Avatar,
	TextField,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { useAuth0 } from '@auth0/auth0-react'
import { useHistory } from 'react-router-dom'

import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

import { updateProfileRoute } from '../../../../utils/constants'

import SessionContext from '../../../../context/session'
import Sidebar from '../../../../components/Sidebar/index'
import MultiTab from '../../../../components/MultiTab'
import sweetAlertModal from '../../../../components/SweetAlertModal'
import LanguageSelector from '../../../../components/LanguageSelector'
import MenuSearch from '../../components/MenuSearch/MenuSearch'
import useStyles from './styles'
import { AnnounceIcon } from '../../../../assets/Icons/Icons'
import { fetchProfile } from '../../../../redux/actions/Profile.actions'
import { setOpenAnnouncementModal, setAnnouncement } from '../../../../redux/actions/AnnouncementModal.actions'
import { getListCompaniesByUser } from '../../../../redux/actions/CompanyByUser.actions'

const Layout = ({ children, oktaAuth }) => {
	const { getUserData, getExpirationTime, authenticated, cleanStorage, saveActualCompanyId, deleteSession } =
		useContext(SessionContext)
	const dispatch = useDispatch()
	const classes = useStyles()
	const theme = useTheme()
	const history = useHistory()
	const parent = useSelector((state) => state.companies.parents)
	const userPhoto = useSelector((state) => state.users.userPhoto)
	const userProfile = useSelector((state) => state.profile.profile)
	const getListCompaniesByUSer = useSelector((state) => state.companiesByUser)
	const matches = useMediaQuery(theme.breakpoints.up('md'))
	const [anchorEl, setAnchorEl] = useState(false)
	const [mobileOpen, setMobileOpen] = useState(false)
	const [userPermi, setPermissions] = useState([])
	const [openTabsMobile, setOpenTabsMobile] = useState(false)
	const [open, setOpen] = useState(false)
	const [companyParent, setCompanyParent] = useState('')
	const [profilePhoto, setProfilePhoto] = useState(userPhoto)
	const { logout, isAuthenticated } = useAuth0()
	const [anchorElSearch, setAnchorElSearch] = useState(null)
	const [accountSelected, setSelectedAccount] = useState(null)
	const [minLevelPrincipalCompany, setMinLevelPrincipalCompany] = useState(-1)

	const openSearch = Boolean(anchorElSearch)

	useEffect(() => {
		let timer
		if (authenticated) {
			console.log('setCompanyParent', parent)
			setCompanyParent(parent.name)
			const { permissions } = getUserData()
			setPermissions(permissions)
		}

		// Okta will auto refresh the token, and only Auth0 needs to handle session experation.
		if (isAuthenticated) {
			// getExperationTime returns seconds till experation
			// console.log('exp date', new Date(getExpirationTime() * 1000));
			const expiresIn = new Date(getExpirationTime() * 1000) - Date.now()
			// console.log('expiresIn', expiresIn);
			// console.log('expiresIn (hours)', expiresIn / 1000 / 3600);
			timer = setTimeout(() => {
				sweetAlertModal('error', 'Your session has expired!', null, 'Ok', true, false, null).then(() => {
					deleteSession()
					if (isAuthenticated) {
						logout({ returnTo: process.env.REACT_APP_AUTH0_REDIRECT_URI })
					}
				})
			}, expiresIn)
		}

		return () => clearTimeout(timer)
	}, [
		getExpirationTime,
		getUserData,
		authenticated,
		isAuthenticated,
		logout,
		cleanStorage,
		oktaAuth,
		parent,
		deleteSession,
	])

	useEffect(() => {
		const getProfile = async () => {
			const { actualCompany } = getUserData()
			const config = { headers: { 'X-Company-id': actualCompany } }
			const { status, resp } = await dispatch(fetchProfile(config))
			if (status === 'success') {
				setProfilePhoto(resp.photo ? resp.photo : '')
			}
		}
		getProfile()
	}, [dispatch, getUserData])

	useEffect(() => {
		if (userPhoto) setProfilePhoto(userPhoto)
	}, [userPhoto])

	useEffect(() => {
		dispatch(getListCompaniesByUser())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (getListCompaniesByUSer?.companies) {
			let minLevel = getListCompaniesByUSer?.companies[0]?.level
			getListCompaniesByUSer?.companies.forEach((account) => {
				if (account.level < minLevel) {
					minLevel = account.level
				}
			})
			if (minLevel) setMinLevelPrincipalCompany(minLevel)
		}
	}, [getListCompaniesByUSer?.companies])

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen)
	}

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(false)
	}

	const handleOpenTabsMobile = () => {
		setOpenTabsMobile(!openTabsMobile)
	}

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleCloseModal = () => {
		setOpen(false)
	}

	const confirmAccount = () => {
		if (accountSelected?.id) {
			saveActualCompanyId(accountSelected?.id, true)
			window.location.reload()
		}
	}

	const isFPAdmin = () => userProfile.role === 'FREIGHT_PROS_ADMIN'

	const handleEditProfile = () => {
		const splittedRoute = updateProfileRoute.split('/')
		history.push({
			pathname: `/${splittedRoute[1]}/${userProfile.id}`,
		})
		handleClose()
	}

	const menuId = 'primary-search-account-menu'
	const container = window.document.body

	return (
		<>
			<div className={classes.root}>
				<CssBaseline />
				<AppBar position="fixed" className={classes.appBar}>
					<Toolbar className={classes.toolbar}>
						<IconButton
							className={classes.menuButton}
							color="secondary"
							onClick={handleDrawerToggle}
							aria-label="Toggle Menu"
						>
							<MenuIcon />
						</IconButton>
						<div className={classes.tabsMobileWrapper}>
							<button type="button" className={classes.tabsMobileButton} onClick={handleOpenTabsMobile}>
								My Tasks
								{openTabsMobile ? <ExpandLessIcon /> : <ExpandMoreIcon />}
							</button>
						</div>
						<MultiTab openTabsMobile={openTabsMobile} />
						<div className={classes.headerActions}>
							{isFPAdmin && (
								<IconButton
									className={classes.headerIcon}
									aria-label="Announce"
									onClick={() => {
										dispatch(setAnnouncement(''))
										dispatch(setOpenAnnouncementModal(true))
									}}
								>
									<AnnounceIcon />
								</IconButton>
							)}
							<Button className={classes.companyButton} onClick={handleClickOpen}>
								<SupervisorAccountOutlinedIcon />
								<span className={classes.companyName}>{companyParent}</span>
							</Button>
							<IconButton
								className={classes.headerIcon}
								aria-label="Search"
								color="secondary"
								onClick={(e) => setAnchorElSearch(e.currentTarget)}
							>
								<Search />
							</IconButton>
							<Dialog
								classes={{
									paper: classes.dialogPaper,
								}}
								open={open}
								onClose={handleCloseModal}
								onClick={(event) => event.stopPropagation()}
								aria-labelledby="form-dialog-title"
							>
								<DialogTitle id="form-dialog-title" disableTypography className={classes.dialogTitle}>
									<h2>Choose an account</h2>
									<p className={classes.dialogSubtitle}>Choose an account that you want to use to create a quote</p>
								</DialogTitle>
								<DialogContent className={classes.dialogContent}>
									<form className={classes.container}>
										<FormControl variant="outlined" className={classes.formControl}>
											<InputLabel htmlFor="company-dialog-select">Current Account</InputLabel>
											<Select id="company-dialog-select" value={companyParent} disabled label="Current Account">
												<MenuItem value={companyParent}>{companyParent}</MenuItem>
											</Select>
										</FormControl>
										<Autocomplete
											options={getListCompaniesByUSer.companies}
											getOptionLabel={(option) => option.name}
											value={accountSelected}
											onChange={(_, newValue) => {
												setSelectedAccount(newValue)
											}}
											renderOption={(option) => (
												<div
													style={{
														marginLeft: `${option.level - minLevelPrincipalCompany}em`,
													}}
												>
													{option.name}
												</div>
											)}
											renderInput={(params) => (
												<TextField
													// eslint-disable-next-line react/jsx-props-no-spreading
													{...params}
													label="Change Account"
													margin="normal"
													variant="outlined"
													value={accountSelected}
												/>
											)}
										/>
									</form>
								</DialogContent>
								<DialogActions className={classes.dialogActions}>
									<Button
										className={classes.buttonSwitchAccount}
										onClick={confirmAccount}
										color="primary"
										variant="contained"
										disabled={!accountSelected}
									>
										Switch Account
									</Button>
									<Button className={classes.buttonGrey} onClick={handleCloseModal} color="primary">
										Cancel
									</Button>
								</DialogActions>
							</Dialog>
							<div className={classes.sectionDesktop}>
								<IconButton
									edge="end"
									aria-label="account of current user"
									aria-controls={menuId}
									onClick={handleClick}
									aria-haspopup="true"
									color="inherit"
								>
									<Avatar className={classes.avatar} src={profilePhoto} />
								</IconButton>
								<Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
									<MenuItem onClick={() => handleEditProfile()}>Profile</MenuItem>
									<MenuItem
										onClick={() => {
											cleanStorage()
											if (isAuthenticated) {
												logout({ returnTo: process.env.REACT_APP_AUTH0_REDIRECT_URI })
											} else {
												oktaAuth.signOut()
												oktaAuth.closeSession()
											}
										}}
									>
										Logout
									</MenuItem>
								</Menu>
							</div>
							<div className={classes.sectionMobile}>
								<IconButton
									aria-label="account of current user"
									aria-controls={menuId}
									onClick={handleClick}
									aria-haspopup="true"
									color="inherit"
								>
									<Avatar className={classes.avatar} src={profilePhoto} />
								</IconButton>
								<Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
									<MenuItem onClick={() => handleEditProfile()}>Profile</MenuItem>
									<MenuItem
										onClick={() => {
											cleanStorage()
											if (isAuthenticated) {
												logout({ returnTo: process.env.REACT_APP_AUTH0_REDIRECT_URI })
											} else {
												oktaAuth.signOut()
												oktaAuth.closeSession()
											}
										}}
									>
										Logout
									</MenuItem>
								</Menu>
							</div>
							<div className={classes.pl12}>
								<LanguageSelector />
							</div>
						</div>
					</Toolbar>
				</AppBar>
				<nav className={clsx(classes.drawer, { isOpen: mobileOpen })} aria-label="mailbox folders">
					{!matches ? (
						<Drawer
							container={container}
							variant="temporary"
							anchor={theme.direction === 'rtl' ? 'right' : 'left'}
							open={mobileOpen}
							onClose={handleDrawerToggle}
							classes={{
								paper: classes.drawerPaper,
							}}
							ModalProps={{
								keepMounted: true,
							}}
						>
							<Sidebar items={userPermi} mobileOpen={mobileOpen} />
						</Drawer>
					) : (
						<Drawer
							classes={{
								paper: clsx(classes.drawerPaper, { isOpen: mobileOpen }),
							}}
							variant="permanent"
							open
							onMouseOver={() => setMobileOpen(true)}
							onMouseLeave={() => setMobileOpen(false)}
						>
							<Sidebar items={userPermi} mobileOpen={mobileOpen} />
						</Drawer>
					)}
				</nav>
				<main className={classes.content}>
					<div className={classes.appBarSpaccer} />
					<Container className={classes.container}>{children}</Container>
				</main>
			</div>
			<MenuSearch open={openSearch} anchorEl={anchorElSearch} handleClose={() => setAnchorElSearch(null)} />
		</>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	oktaAuth: PropTypes.object.isRequired,
}

export default Layout
