import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	newUserHeader: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: '#F4F6F6',
		padding: '16px 23px',
		[theme.breakpoints.down('md')]: {
			padding: '16px',
		},
	},
	newUserTitle: {
		textAlign: 'start',
		margin: '0',
		fontSize: '34px',
		fontWeight: '700',
		color: '#2c2c2c',
		[theme.breakpoints.down('md')]: {
			fontSize: '22px',
		},
	},
	newUserSubtitle: {
		margin: '0',
		fontSize: '16px',
		[theme.breakpoints.down('md')]: {
			textAlign: 'justify',
		},
	},
	sectionContainer: {
		padding: '24px',
		display: 'grid',
		gridAutoFlow: 'row',
		rowGap: '30px',
		[theme.breakpoints.down('md')]: {
			padding: '16px',
		},
	},
	notificationsContainer: {
		backgroundColor: '#F7F8F8',
		padding: '24px',
		display: 'grid',
		gridAutoFlow: 'row',
		[theme.breakpoints.down('md')]: {
			padding: '16px',
		},
	},
	notificationsIconTitle: {
		display: 'flex',
		columnGap: '12px',
		alignItems: 'center',
		marginBottom: '21px',
		[theme.breakpoints.down('md')]: {
			alignItems: 'flex-start',
		},
	},
	notificationsIconContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '28px',
		height: '28px',
		borderRadius: '20px',
		color: theme.palette.neutral.black,
	},
	notificationsIcon: {
		width: '20px',
		height: '20px',
	},
	notificationsSectionTitle: {
		fontSize: '18px',
		fontWeight: theme.typography.fontWeightRegular,
		color: theme.palette.primary.main,
		margin: 0,
		lineHeight: '22px',
		[theme.breakpoints.down('md')]: {
			fontSize: '15px',
		},
	},
	buttonsRow: {
		justifyContent: 'flex-end',
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		padding: '0 25px 25px 0',
		marginTop: '200px',
		backgroundColor: theme.palette.neutral.white,
		[theme.breakpoints.down('md')]: {
			justifyContent: 'center',
			flexDirection: 'column-reverse',
			padding: '0 16px 16px 16px',
			marginTop: '20px',
		},
	},
	cancelQuoteButton: {
		width: '170px',
		height: '48px',
		textTransform: 'none',
		fontSize: '17px',
		fontWeight: '600',
		marginLeft: '1em',
		'@media only screen and (max-width: 1023px)': {
			width: '100%',
			marginLeft: '0',
		},
	},
	button: {
		width: '222px',
		height: '48px',
		fontSize: '17px',
		fontWeight: '600',
		color: theme.palette.neutral.white,
		margin: theme.spacing(0, 0, 0),
		marginLeft: '1em',
		alignSelf: 'end',
		textTransform: 'none',
		'@media only screen and (max-width: 768px)': {
			width: '140px',
		},
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginBottom: '16px',
			marginLeft: '0em',
		},
	},
}))

export default useStyles
