import { permissionName } from '../../../../utils/constants'

const checkEmail = (emailAddress) => {
	if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(emailAddress) && emailAddress) {
		return emailAddress
	}
	return '-'
}

export const formatPhoneNumber = (number) => {
	const cleaned = ` ${number}`.replace(/\D/g, '')
	const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
	if (match) {
		return `(${match[1]}) ${match[2]}-${match[3]}`
	}
	return '-'
}

export const profileOptions = [
	{
		label: 'FreightPros Admin',
		value: 'FREIGHT_PROS_ADMIN',
	},
	{
		label: 'All Access Customer Profile',
		value: 'ALL_ACCESS_CUSTOMER_PROFILE',
	},
	{
		label: 'Vendor Profile',
		value: 'VENDOR_PROFILE',
	},
	{
		label: 'Quoting Only Profile',
		value: 'QUOTING_ONLY_PROFILE',
	},
	{
		label: 'Accounting User Profile',
		value: 'ACCOUNTING_USER_PROFILE',
	},
	{
		label: 'Custom Profile',
		value: 'CUSTOM_PROFILE',
	},
]

export const profileOptionsNoFP = [
	{
		label: 'All Access Customer Profile',
		value: 'ALL_ACCESS_CUSTOMER_PROFILE',
	},
	{
		label: 'Vendor Profile',
		value: 'VENDOR_PROFILE',
	},
	{
		label: 'Quoting Only Profile',
		value: 'QUOTING_ONLY_PROFILE',
	},
	{
		label: 'Accounting User Profile',
		value: 'ACCOUNTING_USER_PROFILE',
	},
	{
		label: 'Custom Profile',
		value: 'CUSTOM_PROFILE',
	},
]

export const defaultCheckboxesValues = {
	createAccounts: false,
	editAccounts: false,
	deactivateAccounts: false,
	createUsers: false,
	editUsers: false,
	deactivateUsers: false,
	createCarriers: false,
	editCarriers: false,
	deactivateCarriers: false,
	showCarriersRate: false,
	createAccountsAdminCustomer: false,
	editAccountsAdminCustomer: false,
	createUsersAdminCustomer: false,
	editUsersAdminCustomer: false,
	createQuote: false,
	viewQuoteDetails: false,
	convertQuote: false,
	reQuote: false,
	sendQuoteByEmail: false,
	downloadQuote: false,
	deleteQuote: false,
	viewShipmentDetails: false,
	downloadBol: false,
	downloadLabel: false,
	contactSupport: false,
	cloneShipment: false,
	removeShipment: false,
	viewInvoiceDetails: false,
	downloadInvoice: false,
	sendInvoiceByEmail: false,
	viewReportDetails: false,
	createReport: false,
	sendReportByEmail: false,
}

export const fPAdminCheckboxes = {
	createAccounts: true,
	editAccounts: true,
	deactivateAccounts: true,
	createUsers: true,
	editUsers: true,
	deactivateUsers: true,
	createCarriers: true,
	editCarriers: true,
	deactivateCarriers: true,
	showCarriersRate: true,
	createAccountsAdminCustomer: true,
	editAccountsAdminCustomer: true,
	createUsersAdminCustomer: true,
	editUsersAdminCustomer: true,
	createQuote: true,
	viewQuoteDetails: true,
	convertQuote: true,
	reQuote: true,
	sendQuoteByEmail: true,
	downloadQuote: true,
	deleteQuote: true,
	viewShipmentDetails: true,
	downloadBol: true,
	downloadLabel: true,
	contactSupport: true,
	cloneShipment: true,
	removeShipment: true,
	viewInvoiceDetails: true,
	downloadInvoice: true,
	sendInvoiceByEmail: true,
	viewReportDetails: true,
	createReport: true,
	sendReportByEmail: true,
}

export const allAccessCheckboxes = {
	createAccounts: false,
	editAccounts: false,
	deactivateAccounts: false,
	createUsers: false,
	editUsers: false,
	deactivateUsers: false,
	createCarriers: false,
	editCarriers: false,
	deactivateCarriers: false,
	showCarriersRate: false,
	createAccountsAdminCustomer: true,
	editAccountsAdminCustomer: true,
	createUsersAdminCustomer: true,
	editUsersAdminCustomer: true,
	createQuote: true,
	viewQuoteDetails: true,
	convertQuote: true,
	reQuote: true,
	sendQuoteByEmail: true,
	downloadQuote: true,
	deleteQuote: true,
	viewShipmentDetails: true,
	downloadBol: true,
	downloadLabel: true,
	contactSupport: true,
	cloneShipment: true,
	removeShipment: true,
	viewInvoiceDetails: true,
	downloadInvoice: true,
	sendInvoiceByEmail: true,
	viewReportDetails: true,
	createReport: true,
	sendReportByEmail: true,
}

export const vendorCheckboxes = {
	createAccounts: false,
	editAccounts: false,
	deactivateAccounts: false,
	createUsers: false,
	editUsers: false,
	deactivateUsers: false,
	createCarriers: false,
	editCarriers: false,
	deactivateCarriers: false,
	showCarriersRate: false,
	createAccountsAdminCustomer: false,
	editAccountsAdminCustomer: false,
	createUsersAdminCustomer: false,
	editUsersAdminCustomer: false,
	createQuote: true,
	viewQuoteDetails: true,
	convertQuote: true,
	reQuote: true,
	sendQuoteByEmail: true,
	downloadQuote: true,
	deleteQuote: true,
	viewShipmentDetails: true,
	downloadBol: true,
	downloadLabel: true,
	contactSupport: true,
	cloneShipment: true,
	removeShipment: true,
	viewInvoiceDetails: false,
	downloadInvoice: false,
	sendInvoiceByEmail: false,
	viewReportDetails: false,
	createReport: false,
	sendReportByEmail: false,
}

export const quotingCheckboxes = {
	createAccounts: false,
	editAccounts: false,
	deactivateAccounts: false,
	createUsers: false,
	editUsers: false,
	deactivateUsers: false,
	createCarriers: false,
	editCarriers: false,
	deactivateCarriers: false,
	showCarriersRate: false,
	createAccountsAdminCustomer: false,
	editAccountsAdminCustomer: false,
	createUsersAdminCustomer: false,
	editUsersAdminCustomer: false,
	createQuote: true,
	viewQuoteDetails: true,
	convertQuote: true,
	reQuote: true,
	sendQuoteByEmail: true,
	downloadQuote: true,
	deleteQuote: true,
	viewShipmentDetails: false,
	downloadBol: false,
	downloadLabel: false,
	contactSupport: false,
	cloneShipment: false,
	removeShipment: false,
	viewInvoiceDetails: false,
	downloadInvoice: false,
	sendInvoiceByEmail: false,
	viewReportDetails: false,
	createReport: false,
	sendReportByEmail: false,
}

export const accountingCheckboxes = {
	createAccounts: false,
	editAccounts: false,
	deactivateAccounts: false,
	createUsers: false,
	editUsers: false,
	deactivateUsers: false,
	createCarriers: false,
	editCarriers: false,
	deactivateCarriers: false,
	showCarriersRate: false,
	createAccountsAdminCustomer: false,
	editAccountsAdminCustomer: false,
	createUsersAdminCustomer: false,
	editUsersAdminCustomer: false,
	createQuote: false,
	viewQuoteDetails: false,
	convertQuote: false,
	reQuote: false,
	sendQuoteByEmail: false,
	downloadQuote: false,
	deleteQuote: false,
	viewShipmentDetails: false,
	downloadBol: false,
	downloadLabel: false,
	contactSupport: false,
	cloneShipment: false,
	removeShipment: false,
	viewInvoiceDetails: true,
	downloadInvoice: true,
	sendInvoiceByEmail: true,
	viewReportDetails: true,
	createReport: true,
	sendReportByEmail: true,
}

export const setFPAdminCheckboxesValues = ({ checked }) => {
	const values = {
		createAccounts: checked,
		editAccounts: checked,
		deactivateAccounts: checked,
		createUsers: checked,
		editUsers: checked,
		deactivateUsers: checked,
		createCarriers: checked,
		editCarriers: checked,
		deactivateCarriers: checked,
		showCarriersRate: checked,
	}

	return values
}

export const setAdminCustomerCheckboxesValues = ({ checked }) => {
	const values = {
		createAccountsAdminCustomer: checked,
		editAccountsAdminCustomer: checked,
		createUsersAdminCustomer: checked,
		editUsersAdminCustomer: checked,
	}

	return values
}

export const setQuoteCheckboxesValues = ({ checked }) => {
	const values = {
		createQuote: checked,
		viewQuoteDetails: checked,
		convertQuote: checked,
		reQuote: checked,
		sendQuoteByEmail: checked,
		downloadQuote: checked,
		deleteQuote: checked,
	}

	return values
}

export const setShipmentCheckboxesValues = ({ checked }) => {
	const values = {
		viewShipmentDetails: checked,
		downloadBol: checked,
		downloadLabel: checked,
		contactSupport: checked,
		cloneShipment: checked,
		removeShipment: checked,
	}

	return values
}

export const setInvoiceCheckboxesValues = ({ checked }) => {
	const values = {
		viewInvoiceDetails: checked,
		downloadInvoice: checked,
		sendInvoiceByEmail: checked,
	}

	return values
}

export const setReportCheckboxesValues = ({ checked }) => {
	const values = {
		viewReportDetails: checked,
		createReport: checked,
		sendReportByEmail: checked,
	}

	return values
}

export const defineSelectedPermissions = (permissionsArray) => {
	const selectedRolesAndPermissions = { ...defaultCheckboxesValues }
	permissionsArray.forEach((item) => {
		if (item.name === permissionName.FP_ADMIN_ACCOUNTS_CREATE && item.is_active) {
			selectedRolesAndPermissions.createAccounts = true
		} else if (item.name === permissionName.FP_ADMIN_ACCOUNTS_UPDATE && item.is_active) {
			selectedRolesAndPermissions.editAccounts = true
		} else if (item.name === permissionName.FP_ADMIN_ACCOUNTS_DEACTIVATE && item.is_active) {
			selectedRolesAndPermissions.deactivateAccounts = true
		} else if (item.name === permissionName.FP_ADMIN_USERS_CREATE && item.is_active) {
			selectedRolesAndPermissions.createUsers = true
		} else if (item.name === permissionName.FP_ADMIN_USERS_UPDATE && item.is_active) {
			selectedRolesAndPermissions.editUsers = true
		} else if (item.name === permissionName.FP_ADMIN_USERS_DEACTIVATE && item.is_active) {
			selectedRolesAndPermissions.deactivateUsers = true
		} else if (item.name === permissionName.FP_ADMIN_CARRIERS_CREATE && item.is_active) {
			selectedRolesAndPermissions.createCarriers = true
		} else if (item.name === permissionName.FP_ADMIN_CARRIERS_UPDATE && item.is_active) {
			selectedRolesAndPermissions.editCarriers = true
		} else if (item.name === permissionName.FP_ADMIN_CARRIERS_DEACTIVATE && item.is_active) {
			selectedRolesAndPermissions.deactivateCarriers = true
		} else if (item.name === permissionName.FP_ADMIN_CARRIERS_SHOW_RATE && item.is_active) {
			selectedRolesAndPermissions.showCarriersRate = true
		} else if (item.name === permissionName.ADMIN_ACCOUNTS_CREATE && item.is_active) {
			selectedRolesAndPermissions.createAccountsAdminCustomer = true
		} else if (item.name === permissionName.ADMIN_ACCOUNTS_UPDATE && item.is_active) {
			selectedRolesAndPermissions.editAccountsAdminCustomer = true
		} else if (item.name === permissionName.ADMIN_USERS_CREATE && item.is_active) {
			selectedRolesAndPermissions.createUsersAdminCustomer = true
		} else if (item.name === permissionName.ADMIN_USERS_UPDATE && item.is_active) {
			selectedRolesAndPermissions.editUsersAdminCustomer = true
		} else if (item.name === permissionName.QUOTES_CREATE && item.is_active) {
			selectedRolesAndPermissions.createQuote = true
		} else if (item.name === permissionName.QUOTES_READ && item.is_active) {
			selectedRolesAndPermissions.viewQuoteDetails = true
		} else if (item.name === permissionName.QUOTES_CONVERT_TO_SHIPMENT && item.is_active) {
			selectedRolesAndPermissions.convertQuote = true
		} else if (item.name === permissionName.QUOTES_RE_QUOTE && item.is_active) {
			selectedRolesAndPermissions.reQuote = true
		} else if (item.name === permissionName.QUOTES_SEND_BY_EMAIL && item.is_active) {
			selectedRolesAndPermissions.sendQuoteByEmail = true
		} else if (item.name === permissionName.QUOTES_DOWNLOAD && item.is_active) {
			selectedRolesAndPermissions.downloadQuote = true
		} else if (item.name === permissionName.QUOTES_DELETE && item.is_active) {
			selectedRolesAndPermissions.deleteQuote = true
		} else if (item.name === permissionName.SHIPMENTS_READ && item.is_active) {
			selectedRolesAndPermissions.viewShipmentDetails = true
		} else if (item.name === permissionName.SHIPMENTS_DOWNLOAD_BOL && item.is_active) {
			selectedRolesAndPermissions.downloadBol = true
		} else if (item.name === permissionName.SHIPMENTS_DOWNLOAD_LABEL && item.is_active) {
			selectedRolesAndPermissions.downloadLabel = true
		} else if (item.name === permissionName.SHIPMENTS_CONTACT_SUPPORT && item.is_active) {
			selectedRolesAndPermissions.contactSupport = true
		} else if (item.name === permissionName.SHIPMENTS_CLONE && item.is_active) {
			selectedRolesAndPermissions.cloneShipment = true
		} else if (item.name === permissionName.SHIPMENTS_DELETE && item.is_active) {
			selectedRolesAndPermissions.removeShipment = true
		} else if (item.name === permissionName.INVOICES_READ && item.is_active) {
			selectedRolesAndPermissions.viewInvoiceDetails = true
		} else if (item.name === permissionName.INVOICES_DOWNLOAD && item.is_active) {
			selectedRolesAndPermissions.downloadInvoice = true
		} else if (item.name === permissionName.INVOICES_SEND_BY_EMAIL && item.is_active) {
			selectedRolesAndPermissions.sendInvoiceByEmail = true
		} else if (item.name === permissionName.REPORTS_READ && item.is_active) {
			selectedRolesAndPermissions.viewReportDetails = true
		} else if (item.name === permissionName.REPORTS_CREATE && item.is_active) {
			selectedRolesAndPermissions.createReport = true
		} else if (item.name === permissionName.REPORTS_SEND_BY_EMAIL && item.is_active) {
			selectedRolesAndPermissions.sendReportByEmail = true
		}
	})
	return selectedRolesAndPermissions
}

export default checkEmail
