import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TablePagination,
	TableRow,
	Paper,
	CircularProgress,
} from '@material-ui/core'

import { useSelector, useDispatch } from 'react-redux'
import { carrierTableCells } from '../../../../../utils/headCells'
import { updatePaginator } from '../../../../../redux/actions/Carriers.actions'
import { setCurrentPage, setRowsPerPage } from '../../../../../redux/actions/CarriersFilters.actions'

import TableHeader from '../TableHeader'
import CarrierTableRow from '../CarrierTableRow'
import NoResultsFound from '../../../../../components/NoResultsFound'
import TableToolbar from '../TableToolbar'
import sweetAlertModal from '../../../../../components/SweetAlertModal'

import useStyles from './styles'

const CarriersTable = (props) => {
	const {
		tableType,
		pageReset,
		handleSortColumn,
		isLoading,
		isSelectableList,
		isMainCarrierList,
		isAccountCarrierList,
		completeCarriersList,
		setCompleteCarriersList,
		carriersList,
		handleUpdateCarrier,
		handleUpdateCarrierStatus,
		handleBulkUpdateStatus,
		selected,
		setSelected,
		showRadios,
		carriersDeleted,
		setCarriersDeleted,
	} = props
	const classes = useStyles(props)
	const dispatch = useDispatch()
	const totalCarriers = useSelector((state) => state.carriers.totalCarriers)
	const carriersFilters = useSelector((state) => state.carriersFilters)

	const [page, setPage] = useState(0)
	const isSelected = (name) => selected.indexOf(name) !== -1
	const handleChangePage = (event, newPage) => {
		dispatch(setCurrentPage(newPage * carriersFilters.rowsPerPage))
		dispatch(updatePaginator(newPage * carriersFilters.rowsPerPage))
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		dispatch(setRowsPerPage(Number(event.target.value)))
		dispatch(setCurrentPage(0))
		setPage(0)
	}

	const handleSelectAllClick = (event) => {
		const tempData = carriersList

		if (event.target.checked) {
			const newSelecteds = tempData.map((item) => item.id)
			setSelected(newSelecteds)
			return
		}
		setSelected([])
	}

	const handleBulkDelete = () => {
		const inputList = isAccountCarrierList ? [...completeCarriersList] : [...carriersList]
		const newCarriersDeleted = [...carriersDeleted]
		selected.forEach((idSelected) => {
			const carrierIndex = inputList.findIndex((carrier) => carrier.id === idSelected)
			const newCarrierDeleted = inputList.splice(carrierIndex, 1)[0]

			if (newCarrierDeleted.carrier_integration_id !== undefined) {
				const newCarrierFormatedDeleted = {
					...newCarrierDeleted,
					action: 'DELETE',
				}
				if (newCarrierFormatedDeleted.accessorialsDeleted !== undefined) {
					delete newCarrierFormatedDeleted.accessorialsDeleted
				}
				newCarriersDeleted.push(newCarrierFormatedDeleted)
			}
		})

		setCarriersDeleted(newCarriersDeleted)
		setCompleteCarriersList(inputList)
		setSelected([])
		sweetAlertModal('success', 'The carriers were removed successfully', null, 'Ok', true, false)
	}

	const handleDeleteClick = (carrierId) => {
		const index = completeCarriersList.findIndex((carrier) => carrier.id === carrierId)
		const inputList = isAccountCarrierList ? [...completeCarriersList] : [...carriersList]
		const newCarrierDeleted = inputList.splice(index, 1)[0]
		if (newCarrierDeleted?.carrier_integration_id !== undefined) {
			if (newCarrierDeleted.accessorialsDeleted !== undefined) {
				delete newCarrierDeleted.accessorialsDeleted
			}
			const newCarriersDeleted = [
				...carriersDeleted,
				{
					...newCarrierDeleted,
					action: 'DELETE',
				},
			]
			setCarriersDeleted(newCarriersDeleted)
		}
		setCompleteCarriersList(inputList)
		setSelected([])
	}

	useEffect(() => {
		if (pageReset) setPage(0)
	}, [pageReset])

	const renderTableBody = () => {
		let data = []
		if (isLoading) {
			return (
				<TableRow>
					<TableCell align="center" colSpan={Object.keys(carrierTableCells).length}>
						<CircularProgress />
					</TableCell>
				</TableRow>
			)
		}
		data =
			carriersList &&
			carriersList.map((row, index) => {
				const isItemSelected = isSelected(row.id)
				const labelId = `enhanced-table-checkbox-${index}`
				return (
					<CarrierTableRow
						key={`${row.id}-${row.carrier_integration_id}`}
						labelId={labelId}
						isItemSelected={isItemSelected}
						data={row}
						selected={selected}
						setSelected={setSelected}
						isSelectableList={isSelectableList}
						isMainCarrierList={isMainCarrierList}
						isAccountCarrierList={isAccountCarrierList}
						handleEditClick={handleUpdateCarrier}
						handleDeleteClick={handleDeleteClick}
						handleUpdateCarrierStatus={handleUpdateCarrierStatus}
						showRadios={showRadios}
					/>
				)
			})
		if (data.length === 0) {
			return (
				<TableRow>
					<TableCell align="center" colSpan={Object.keys(carrierTableCells).length + 1}>
						<NoResultsFound type="Carriers" />
					</TableCell>
				</TableRow>
			)
		}
		return data
	}

	return (
		<div className={classes.root}>
			{isSelectableList && (
				<TableToolbar
					selected={selected}
					handleBulkDelete={handleBulkDelete}
					isMainCarrierList={isMainCarrierList}
					handleBulkUpdateStatus={handleBulkUpdateStatus}
				/>
			)}
			<Paper className={classes.paper} elevation={0}>
				<TableContainer className={classes.tableContainer}>
					<Table className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
						<TableHeader
							rowCount={carriersList.length}
							tableType={tableType}
							handleSortColumn={handleSortColumn}
							selected={selected}
							handleSelectAllClick={handleSelectAllClick}
							isSelectableList={isSelectableList}
							isMainCarrierList={isMainCarrierList}
							showRadios={showRadios}
						/>
						<TableBody>{renderTableBody()}</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					classes={{
						root: classes.tablePaginator,
						toolbar: classes.tablePaginatorToolbar,
						actions: classes.tablePaginatorActions,
					}}
					rowsPerPageOptions={[10, 20, 50, 100]}
					component="div"
					count={totalCarriers}
					rowsPerPage={Number(carriersFilters.rowsPerPage)}
					page={page}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
				/>
			</Paper>
		</div>
	)
}

CarriersTable.defaultProps = {
	completeCarriersList: [],
	setCompleteCarriersList: () => {},
	handleUpdateCarrier: () => {},
	handleUpdateCarrierStatus: () => {},
	handleBulkUpdateStatus: () => {},
	isMainCarrierList: false,
	isAccountCarrierList: false,
	handleSortColumn: () => {},
	selected: [],
	setSelected: () => {},
	showRadios: false,
	carriersDeleted: [],
	setCarriersDeleted: () => {},
}

CarriersTable.propTypes = {
	tableType: PropTypes.string.isRequired,
	pageReset: PropTypes.string.isRequired,
	handleSortColumn: PropTypes.func,
	isLoading: PropTypes.bool.isRequired,
	isSelectableList: PropTypes.bool.isRequired,
	isMainCarrierList: PropTypes.bool,
	isAccountCarrierList: PropTypes.bool,
	completeCarriersList: PropTypes.arrayOf(PropTypes.any),
	setCompleteCarriersList: PropTypes.func,
	carriersList: PropTypes.arrayOf(PropTypes.any).isRequired,
	handleUpdateCarrier: PropTypes.func,
	handleUpdateCarrierStatus: PropTypes.func,
	handleBulkUpdateStatus: PropTypes.func,
	selected: PropTypes.arrayOf(PropTypes.any),
	setSelected: PropTypes.func,
	showRadios: PropTypes.bool,
	carriersDeleted: PropTypes.arrayOf(PropTypes.any),
	setCarriersDeleted: PropTypes.func,
}

export default CarriersTable
