import { createSlice } from '@reduxjs/toolkit'
import reqGetQuoteRates, { reqSelectRate, reqDownloadRate } from '../requests/Rates.request'

/* eslint no-param-reassign: ["error", { "props": false }] */

const quoteRatesSlice = createSlice({
	name: 'quoteRatesSlice',
	initialState: {
		quoteRates: {},
		isLoading: false,
		error: false,
	},
	reducers: {
		startLoading: (state) => {
			state.isLoading = true
		},
		hasError: (state, action) => {
			state.error = action.payload
			state.isLoading = false
		},
		ratesSuccess: (state, action) => {
			state.quoteRates = action.payload
			state.isLoading = false
		},
	},
})

const { actions, reducer } = quoteRatesSlice

export const { startLoading, hasError, ratesSuccess } = actions

export const fetchQuoteRates = (params) => async (dispatch) => {
	dispatch(startLoading())
	const [error, data] = await reqGetQuoteRates(params)

	if (error) {
		dispatch(hasError(error))
		return { status: 'error', resp: error }
	}
	dispatch(ratesSuccess(data.data.data))
	return { statusRates: 'success', respRates: data.data.data }
}

export const selectRate = (params, insurancePayload) => async () => {
	if (insurancePayload.insurance.withInsurance === false) {
		// causes some process issues for Salesforce team if this is set when not using insurance
		insurancePayload.insurance.declaredFreightValue = 0
	}
	const [error, data] = await reqSelectRate(params, insurancePayload)

	if (error) {
		return { statusSelectRate: 'error', resp: error.err?.message }
	}
	return { statusSelectRate: 'success', resp: data.data.message }
}

export const clearRates = () => async (dispatch) => {
	dispatch(ratesSuccess({}))
}

export const sortRates = (ratesSorted) => async (dispatch) => {
	dispatch(ratesSuccess(ratesSorted))
}

export const downloadDocumentRate = (id) => async () => {
	const [error, data] = await reqDownloadRate(id)

	if (error) {
		return { data: null }
	}
	return { data }
}

export default reducer
