import React from 'react'
import StepperContainer from '../containers/StepperContainer/StepperContainer'
// import NewQuoteShipmentContainer from
// '../containers/NewQuoteShipmentContainer/NewQuoteShipmentContainer';
import useStyles from './styles'

const NewQuoteShipment = () => {
	const classes = useStyles()
	return (
		<div className={classes.newQuoteContainer}>
			<StepperContainer />
		</div>
	)
}

export default NewQuoteShipment
