import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	hazmatModalTitle: {
		color: theme.palette.secondary.main,
		textAlign: 'center',
		margin: '40px 0 16px 0',
	},
	hazmatModalSubtitle: {
		textAlign: 'center',
		margin: '0 0 25px 0',
	},
	addButton: {
		width: '100%',
		height: '48px',
		alignSelf: 'center',
		textTransform: 'unset',
		lineHeight: '21px',
		'&:hover': {
			backgroundColor: theme.palette.secondary.dark,
		},
		'@media only screen and (min-width: 768px) and (max-width: 1023px)': {
			fontSize: '11px',
		},
	},
	cancelButton: {
		width: '100%',
		height: '48px',
		alignSelf: 'center',
		backgroundColor: theme.palette.neutral.mainGray,
		color: theme.palette.primary.main,
		textTransform: 'unset',
		'&:hover': {
			backgroundColor: '#b1b7bd',
			color: theme.palette.primary.main,
		},
		'@media only screen and (min-width: 768px) and (max-width: 1023px)': {
			fontSize: '11px',
		},
	},
	helperText: {
		fontWeight: '400',
		color: theme.palette.secondary.main,
	},
	field: {
		color: theme.palette.primary.main,
		'@media only screen and (min-width: 768px) and (max-width: 1023px)': {
			fontSize: '11px',
		},
		'@media only screen and (min-width: 1023px) and (max-width: 1280px)': {
			fontSize: '15px',
		},
		'@media only screen and (min-width: 1280px) and (max-width: 1440px)': {
			fontSize: '17px',
		},
	},
	paperDialog: {
		minHeight: '330px',
		minWidth: '72%',
		borderBottom: '6px solid',
		borderBottomColor: theme.palette.secondary.main,
		overflowY: 'clip',
	},
}))

export default useStyles
