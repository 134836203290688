import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	tableCell: {
		padding: theme.spacing(0, 2),
		height: 52,
		fontSize: 14,
		fontWeight: theme.typography.fontWeightRegular,
	},
	cellAccountInfo: {
		display: 'flex',
		alignItems: 'center',
		'& > div:nth-child(2)': {
			width: 'max-content',
		},
	},
	tableRow: {
		'& td:nth-child(2)': {
			paddingLeft: '2px',
		},
		'&.Mui-selected, &.Mui-selected:hover': {
			backgroundColor: theme.palette.neutral.secondaryGrey,
		},
	},
	avatar: {
		width: 38,
		height: 38,
		marginRight: 8,
		objectFit: 'contain',
		'&.MuiAvatar-root': {
			backgroundColor: 'transparent',
			color: theme.palette.neutral.black,
		},
	},
	viewIcon: {
		color: theme.palette.primary.main,
		cursor: 'pointer',
	},
	invoiceCarrierImg: {
		display: 'inline-block',
		maxWidth: '40px',
	},
	blockInfo: {
		margin: '0',
	},
	packageType: {
		textTransform: 'lowercase',
	},
	statusActive: {
		color: theme.palette.neutral.black,
		backgroundColor: theme.palette.success.light,
		fontSize: '11px',
		padding: '0 6px',
	},
	statusInactive: {
		color: theme.palette.neutral.white,
		backgroundColor: theme.palette.semantic.red.primary,
		fontSize: '11px',
		padding: '0 6px',
	},
	statusContainer: {
		display: 'flex',
		justifyContent: 'start',
		alignItems: 'center',
		padding: '0px',
	},
	stepStatusIncomplete: {
		color: theme.palette.neutral.black,
		backgroundColor: theme.palette.warning.light,
		fontSize: '11px',
		padding: '0 3px',
		marginLeft: '6px',
	},
	statusAccount: {
		padding: '0px',
		textAlign: 'left',
	},
	accountName: {
		color: '#000',
		marginLeft: '5px',
		textDecoration: 'none',
	},
}))

export default useStyles
