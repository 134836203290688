/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
	Button,
	Collapse,
	Divider,
	FormControl,
	FormControlLabel,
	Grid,
	InputLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	TableContainer,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	useMediaQuery,
	useTheme,
} from '@material-ui/core'
import { BackupOutlined } from '@material-ui/icons'
import Loop from '@material-ui/icons/Loop'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/DeleteOutline'
import { Autocomplete } from '@material-ui/lab'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import CreateIcon from '@material-ui/icons/CreateOutlined'
import { axiosUserCompany } from '../../../../../utils/apiClient'
import { TruckIcon } from '../../../../../assets/Icons/Icons'
import useStyles from './styles'
import CarriersTable from '../../../accountDetails/components/CarriersTableList/TableList'
import SearchBar from '../../../../../components/SearchBar'
import sweetAlertModal from '../../../../../components/SweetAlertModal/SweetAlertModal'
import { capitalizeFirstLetter, removeUnderScore } from '../../../../../utils/helpers'
import {
	setCarrierGroup,
	setCurrentPage,
	setSearchBar,
	resetFilters,
} from '../../../../../redux/actions/CarriersFilters.actions'
import { editAccountStepStatus } from '../../../../../redux/actions/Accounts.actions'
import {
	updateTotalCarriers,
	fetchCarriersList,
	saveAccountCarriersConfiguration,
	updateAccountCarriersConfiguration,
} from '../../../../../redux/actions/Carriers.actions'
import CarriersSlider from '../../../accountDetails/components/CarriersSlider'
import AccessorialsSlider from '../../../components/AccessorialsSlider/AccessorialsSlider'
import { accountsListRoute } from '../../../../../utils/constants'
import SweetAlertModal from '../../../../../components/SweetAlertModal'
import UploadLocationsModal from '../../../../locations/myLocations/containers/LocationsContainer/components/UploadLocationsModal/UploadLocationsModal'

const CarriersStepper = ({ setActiveStep, activeStep, handleCancelAccountProcess, isEditable, mainRef }) => {
	const currentTabs = useSelector((state) => state.multiTabs.tabs)
	const accountTabs = currentTabs.find((item) => item.active) ?? {}
	const { parentCompanyId, childrenCompaniesIds } = accountTabs?.accounts ?? {}
	const [carrierGroup, setCarrierGroupState] = useState('STANDARD')
	const [serviceType, setServiceType] = useState('ALL')
	const [carrierGroupTable, setCarrierGroupTable] = useState('ALL')
	const [serviceTypeTable, setServiceTypeTable] = useState('ALL')
	const [search, setSearch] = useState('')

	const [selectedCarriers, setSelectedCarriers] = useState([])

	const [customSurcharge, setCustomSurcharge] = useState('no')
	const [customAccessorials, setCustomAccessorials] = useState('no')
	const [fuelSurchargeMargin, setFuelSurchargeMargin] = useState()

	const [availableCarriers, setAvailableCarriers] = useState([])

	const [availableAccessorials, setAvailableAccessorials] = useState([])
	const [accessorialToAdd, setAccessorialToAdd] = useState(1)
	const [accessorialUpliftType, setAccessorialUpliftType] = useState('PERCENTAGE')
	const [customCarriersAccessorials, setCustomCarriersAccessorials] = useState([])
	const [selected, setSelected] = useState([])

	const { isLoading } = useSelector((state) => state.carriers)
	const [carriersList, setCarriersList] = useState([])

	const [pageReset, setPageReset] = useState('')

	const [carrierToUpdate, setCarrierToUpdate] = useState(0)
	const [isCarrierUpdate, setIsCarrierUpdate] = useState(false)

	const [accessorialToUpdate, setAccessorialToUpdate] = useState(0)
	const initialAllOthersAccessorials = {
		uplift_type_accessorial: 'PERCENTAGE',
		uplift_accessorial: '0',
		name: 'ALL OTHERS ACCESSORIALS',
		integration_accessorial_id: -1,
	}
	const [allOthersAccessorials, setAllOthersAccessorials] = useState(initialAllOthersAccessorials)
	const [isAccessorialUpdate, setIsAccessorialUpdate] = useState(false)
	const [accessorialMarginSymbol, setAccessorialMarginSymbol] = useState('%')

	const [accessorialsDeleted, setAccessorialsDeleted] = useState([])
	const [carriersDeleted, setCarriersDeleted] = useState([])
	const classes = useStyles()
	const dispatch = useDispatch()
	const theme = useTheme()
	const history = useHistory()
	const matches = useMediaQuery(theme.breakpoints.down('md'))

	const [list, setList] = useState([])
	const { currentPage, rowsPerPage } = useSelector((state) => state.carriersFilters)
	const [modalOpenMassUploadLocations, setModalOpenMassUploadLocations] = useState(false)

	const carriersPayload = {
		configurations_carriers: [],
		parent_company_id: parentCompanyId,
		child_companies: childrenCompaniesIds,
	}

	const { handleSubmit, register, errors, reset, setValue, getValues } = useForm()

	const {
		handleSubmit: handleSubmitAccss,
		register: registerAccss,
		errors: errorsAccss,
		reset: resetAccss,
		getValues: getValuesAccss,
		setValue: setValuesAccss,
	} = useForm({
		defaultValues: {
			accessorialMargin: '60',
		},
	})

	useEffect(() => {
		const params = `?carrierGroup=${carrierGroup}&type=${serviceType}`
		;(async () => {
			const { data } = await axiosUserCompany(`/config-carrier${params}`)
			if (data.status) {
				const newAvailableCarriers = data.data.map((availableCarrier) => ({
					...availableCarrier,
					name: `${availableCarrier.name} (${capitalizeFirstLetter(removeUnderScore(availableCarrier.service_type))})`,
				}))
				setAvailableCarriers(newAvailableCarriers)
			}
		})()
	}, [serviceType, carrierGroup])

	const handleCarriersFilters = () => {
		const carriersToAdd = availableCarriers.filter(
			(carrier) =>
				!carriersList.find(
					(c) =>
						c.scac === carrier.scac &&
						c.contract_type === carrier.contract_type &&
						c.service_type === carrier.service_type,
				),
		)
		setSelectedCarriers(carriersToAdd)
	}

	useEffect(() => {
		;(async () => {
			const { data } = await axiosUserCompany('/config-carrier/accessorials')
			if (data.status) {
				const newAvailableAccessorials = data.data
				setAvailableAccessorials(newAvailableAccessorials)
			}
		})()
	}, [isEditable])

	const CarriersListProvider = () => {
		const callback = useCallback(async () => {
			const params = ''
			const config = {
				headers: { 'X-Company-id': Number(parentCompanyId) },
			}
			const { status, resp } = await dispatch(fetchCarriersList(config, params))
			if (status === 'error') {
				sweetAlertModal('error', 'There was an error fetching the carriers', null, 'Ok', true, false)
			} else {
				const newCarriersList = [
					// These two properties can't go to BE
					...resp.map(({ category, category_pu, ...rest }) => ({ ...rest })),
				]
				setCarriersList(newCarriersList)
			}
			dispatch(setCurrentPage(0))
		}, [])

		return { callback }
	}

	const { callback } = CarriersListProvider()

	useEffect(() => {
		if (isEditable) {
			callback()
		}
	}, [callback, isEditable])

	useEffect(() => {
		dispatch(updateTotalCarriers(carriersList.length))
	}, [dispatch, carriersList])

	const getAccessorialName = useCallback(
		(accessorialId) => {
			const accessorial = availableAccessorials.find((access) => access.accessorial_id === accessorialId)
			return accessorial.name
		},
		[availableAccessorials],
	)

	const getAccessorialId = useCallback(
		(accessorialName) => {
			const accessorial = availableAccessorials.find((access) => access.name === accessorialName)
			return accessorial.accessorial_id
		},
		[availableAccessorials],
	)

	useEffect(() => {
		if (accessorialUpliftType === 'FLAT') {
			setAccessorialMarginSymbol('$')
		} else {
			setAccessorialMarginSymbol('%')
		}
	}, [accessorialUpliftType])

	const handleUpdateCarrier = (carrierId) => {
		mainRef.current.scrollIntoView()
		const index = carriersList.findIndex((carrier) => carrier.id === carrierId)
		setIsCarrierUpdate(true)
		setCarrierToUpdate(index)
		setCarrierGroupState(carriersList[index].carrier_group)
		setServiceType(carriersList[index].service_type)
		setValue('carrierMargin', carriersList[index].uplift_net_charge)
		setSelectedCarriers([carriersList[index]])
		// Todo: Why does this exist? can we delete this safely? It looks like min_margin is set in the line below it
		// I don't think this line does anything
		setValue('minimunProfit', carriersList[index].min_margin)
		setValue('minimumProfit', Number(carriersList[index].min_margin))

		const newCustomAccessorials = [...carriersList[index].accessorials]
		if (!isEditable) {
			const allOthersAccessorialsIndex = newCustomAccessorials.findIndex(
				(accessorial) => accessorial.integration_accessorial_id === -1,
			)
			if (allOthersAccessorialsIndex !== -1) {
				const newAllOthersAccessorials = newCustomAccessorials.splice(allOthersAccessorialsIndex, 1)[0]
				setAllOthersAccessorials(newAllOthersAccessorials)
			} else {
				setAllOthersAccessorials(initialAllOthersAccessorials)
			}
		}
		setCustomCarriersAccessorials(newCustomAccessorials)

		setFuelSurchargeMargin(carriersList[index].uplift_fuel_surcharge)
		setAccessorialsDeleted([])
		if (Number(carriersList[index].uplift_fuel_surcharge)) {
			setCustomSurcharge('yes')
		}
		if (carriersList[index].accessorials.length) {
			setCustomAccessorials('yes')
		}
	}

	const handleUpdateAccessorial = (accessorialIndex) => {
		if (accessorialIndex !== -1) {
			setIsAccessorialUpdate(true)
			setAccessorialToUpdate(accessorialIndex)
			const newAccessorialToUpdate = customCarriersAccessorials[accessorialIndex]
			setAccessorialUpliftType(newAccessorialToUpdate.uplift_type_accessorial)
			setAccessorialToAdd(getAccessorialId(newAccessorialToUpdate.name))
			setValuesAccss('accessorialMargin', newAccessorialToUpdate.uplift_accessorial)
		} else {
			setIsAccessorialUpdate(true)
			setAccessorialToUpdate(-1)
			const isEmptyAllOthersAccessorials = !Number(allOthersAccessorials.uplift_accessorial)
			if (isEmptyAllOthersAccessorials) {
				setAccessorialUpliftType('PERCENTAGE')
				setValuesAccss('accessorialMargin', '60')
			} else {
				setAccessorialUpliftType(allOthersAccessorials.uplift_type_accessorial)
				setValuesAccss('accessorialMargin', allOthersAccessorials.uplift_accessorial)
			}
			setAccessorialToAdd(-1)
		}
	}

	const handleDeleteAccessorial = (accessorialIndex) => {
		const newAccessorials = [...customCarriersAccessorials]
		const newAccessorialDeleted = newAccessorials.splice(accessorialIndex, 1)[0]
		if (newAccessorialDeleted.id !== undefined) {
			setAccessorialsDeleted([...accessorialsDeleted, newAccessorialDeleted])
		}
		setCustomCarriersAccessorials(newAccessorials)
	}

	const resetAccessorialForm = () => {
		resetAccss()
		setAccessorialUpliftType('PERCENTAGE')
		setValuesAccss('accessorialMargin', '60')
		setAccessorialToAdd(1)
		setIsAccessorialUpdate(false)
	}

	const resetForm = () => {
		setValue('carrierMargin', '')
		setValue('minimumProfit', '')
		setSelectedCarriers([])
		setCustomSurcharge('no')
		setFuelSurchargeMargin('')
		setCustomAccessorials('no')
		setIsCarrierUpdate(false)
		setCarrierToUpdate(0)
		setCustomCarriersAccessorials([])
		reset()
	}

	const handleResetFilters = () => {
		dispatch(resetFilters())
		setSearch('')
		setPageReset('pageResetFilters')
		setCarrierGroupTable('ALL')
		setServiceTypeTable('ALL')
	}

	const resetAll = () => {
		resetForm()
		resetAccessorialForm()
		handleResetFilters()

		setCarriersDeleted([])
	}

	const buildCarriersSummary = (currentSelectedCarriers, carriersCurrentSetup) => {
		const carriersSummary = currentSelectedCarriers.map((carrier) => {
			const carrierSetup = carriersCurrentSetup.find((crr) => crr.id === carrier.id)
			const carrierSummary = { ...carrier }
			carrierSummary.name = carrier.name.split('(')[0].trim()
			carrierSummary.uplift_fuel_surcharge = carrierSetup.uplift_fuel_surcharge
			carrierSummary.uplift_net_charge = carrierSetup.uplift_net_charge
			carrierSummary.uplift_type_net_charge = carrierSetup.uplift_type_net_charge
			carrierSummary.uplift_type_fuel_surcharge = carrierSetup.uplift_type_fuel_surcharge
			carrierSummary.min_margin = carrierSetup.min_margin
			carrierSummary.max_margin = carrierSetup.max_margin
			carrierSummary.accessorials = carrierSetup.accessorials
			if (isEditable) {
				carrierSummary.action = 'ADD'
			}
			return carrierSummary
		})

		return carriersSummary
	}

	const handleSaveCarriersConfiguration = () => {
		if (selectedCarriers.length > 0) {
			const carriersConfigurations = []
			selectedCarriers.forEach((selectedCarrier) => {
				const newCustomAccessorials = customAccessorials === 'yes' ? [...customCarriersAccessorials] : []
				if (customCarriersAccessorials.length < availableAccessorials.length) {
					const newAllOthersAccessorials = { ...allOthersAccessorials }
					const isEmptyAllOthersAccessorials = !Number(newAllOthersAccessorials.uplift_accessorial)
					if (isEmptyAllOthersAccessorials) {
						newAllOthersAccessorials.uplift_accessorial = Number(getValues('carrierMargin'))
					}
					newCustomAccessorials.push(newAllOthersAccessorials)
				}
				const carrierPayload = {
					id: selectedCarrier.id,
					scac: selectedCarrier.scac,
					contract_type: selectedCarrier.contract_type,
					service_type: selectedCarrier.service_type,
					min_margin: Number(getValues('minimumProfit')),
					max_margin: null,
					uplift_type_net_charge: 'PERCENTAGE',
					uplift_net_charge: Number(getValues('carrierMargin')),
					uplift_type_fuel_surcharge: 'PERCENTAGE',
					uplift_fuel_surcharge: customSurcharge === 'yes' ? fuelSurchargeMargin : 0,
					accessorials: newCustomAccessorials,
				}
				carriersConfigurations.push(carrierPayload)
			})
			const carriersSummary = buildCarriersSummary(selectedCarriers, carriersConfigurations)
			const newCarriersList = [...carriersList, ...carriersSummary]
			setCarriersList(newCarriersList)

			setAllOthersAccessorials(initialAllOthersAccessorials)
			resetForm()
		} else {
			sweetAlertModal('error', 'Select one carrier from the list', null, 'OK', true, false)
		}
	}

	const handleUpdateCarrierConfiguration = () => {
		if (selectedCarriers.length > 0) {
			const newAction = selectedCarriers[0].carrier_integration_id !== undefined ? 'UPDATE' : 'ADD'
			const newCustomSurcharge = customSurcharge === 'yes' ? fuelSurchargeMargin : ''
			const newCustomAccessorials = customAccessorials === 'yes' ? [...customCarriersAccessorials] : []

			const totalAccessorials = availableAccessorials.length
			const isCustomAccessorialsFull = customCarriersAccessorials.length >= totalAccessorials
			const isConfigured = customCarriersAccessorials.some(
				(accessorial) => accessorial.integration_accessorial_id === -1,
			)

			if (!isCustomAccessorialsFull && !isConfigured) {
				const newAllOthersAccessorials = { ...allOthersAccessorials }
				const isEmptyAllOthersAccessorials = !Number(newAllOthersAccessorials.uplift_accessorial)
				if (isEmptyAllOthersAccessorials) {
					newAllOthersAccessorials.uplift_accessorial = Number(getValues('carrierMargin'))
				}
				newCustomAccessorials.push(newAllOthersAccessorials)
			}

			let carrierConfiguration = {
				...selectedCarriers[0],
				min_margin: Number(getValues('minimumProfit')),
				uplift_net_charge: Number(getValues('carrierMargin')),
				uplift_fuel_surcharge: Number(newCustomSurcharge),
				accessorials: newCustomAccessorials,
			}
			if (isEditable) {
				const newAccesorialsDeleted = [...accessorialsDeleted]
				if (customAccessorials === 'no') {
					customCarriersAccessorials.forEach((customAccessorial) => {
						if (customAccessorial.id !== undefined) {
							newAccesorialsDeleted.push(customAccessorial)
						}
					})
				}
				const accesorialsDeletedFormated = newAccesorialsDeleted.map((accessorial) => ({
					...accessorial,
					action: 'DELETE',
				}))
				carrierConfiguration = {
					...carrierConfiguration,
					action: newAction,
					accessorialsDeleted: accesorialsDeletedFormated,
				}
				setAccessorialsDeleted([])
			}

			const carriersListUpdated = [...carriersList]
			carriersListUpdated[carrierToUpdate] = carrierConfiguration
			setCarriersList(carriersListUpdated)
			setAllOthersAccessorials(initialAllOthersAccessorials)
			resetForm()
		}
	}

	const handleSaveOrUpdateCarrier = () => {
		if (isCarrierUpdate) {
			handleUpdateCarrierConfiguration()
		} else {
			handleSaveCarriersConfiguration()
		}
	}

	const handleUpdateCustomAccessorial = () => {
		if (accessorialToUpdate !== -1) {
			const accessorialConfiguration = {
				...customCarriersAccessorials[accessorialToUpdate],
				uplift_type_accessorial: accessorialUpliftType,
				uplift_accessorial: Number(getValuesAccss('accessorialMargin')),
				integration_accessorial_id: accessorialToAdd,
				name: getAccessorialName(accessorialToAdd),
			}
			const accessorialsListUpdated = [...customCarriersAccessorials]
			if (isEditable && accessorialsListUpdated[accessorialToUpdate].id !== undefined) {
				accessorialConfiguration.action = 'UPDATE'
			}
			accessorialsListUpdated[accessorialToUpdate] = accessorialConfiguration
			setCustomCarriersAccessorials(accessorialsListUpdated)
		} else {
			const accessorialConfiguration = {
				...allOthersAccessorials,
				uplift_type_accessorial: accessorialUpliftType,
				uplift_accessorial: Number(getValuesAccss('accessorialMargin')),
				integration_accessorial_id: accessorialToAdd,
			}
			setAllOthersAccessorials(accessorialConfiguration)
		}
		resetAccessorialForm()
	}

	const handleAddCustomAccessorial = () => {
		const accessorial = {
			uplift_type_accessorial: accessorialUpliftType,
			uplift_accessorial: Number(getValuesAccss('accessorialMargin')),
			integration_accessorial_id: accessorialToAdd,
			name: getAccessorialName(accessorialToAdd),
		}
		if (isEditable) {
			accessorial.action = 'ADD'
		}

		setCustomCarriersAccessorials([...customCarriersAccessorials, accessorial])
		resetAccessorialForm()
	}

	const handleSaveOrUpdateAccessorial = () => {
		if (isAccessorialUpdate) {
			handleUpdateCustomAccessorial()
		} else {
			handleAddCustomAccessorial()
		}
	}

	const preparePayload = () => {
		const payload = {
			...carriersPayload,
		}
		payload.configurations_carriers = carriersList.map((carrier) => {
			delete carrier.id
			delete carrier.name
			delete carrier.carrier_logo
			delete carrier.integration_name
			delete carrier.carrier_group
			delete carrier.status

			if (Number(carrier.uplift_fuel_surcharge) === 0) {
				carrier.uplift_fuel_surcharge = carrier.uplift_net_charge
			}

			const AllOtherAccessorials = carrier.accessorials.find(
				(accessorial) => accessorial.integration_accessorial_id === -1,
			)
			if (AllOtherAccessorials !== undefined) {
				const newAvailableAccessorials = [...availableAccessorials]
				const newAccessorials = newAvailableAccessorials.map((availableAccessorial) => {
					const accessorialConfiguredId = carrier.accessorials.findIndex(
						(acc) => acc.integration_accessorial_id === availableAccessorial.accessorial_id,
					)
					const isConfigured = accessorialConfiguredId !== -1
					if (isConfigured) {
						const newAccessorial = carrier.accessorials[accessorialConfiguredId]
						delete newAccessorial.name
						return newAccessorial
					}
					const newAccessorialConfigured = {
						uplift_type_accessorial: AllOtherAccessorials.uplift_type_accessorial,
						integration_accessorial_id: availableAccessorial.accessorial_id,
						uplift_accessorial: AllOtherAccessorials.uplift_accessorial,
					}
					delete newAccessorialConfigured.name
					return newAccessorialConfigured
				})
				carrier.accessorials = newAccessorials
			}

			const accessorials = carrier.accessorials.map((accessorial) => {
				delete accessorial.name
				return accessorial
			})
			carrier.accessorials = accessorials
			return carrier
		})
		return payload
	}
	const getFilteredCarriers = (arr1, arr2) => {
		const filteredCarriers = arr1.filter((c) =>
			arr2.some(
				(carrier) =>
					carrier.scac === c.scac &&
					carrier.service_type === c.service_type &&
					carrier.contract_type === c.contract_type,
			),
		)
		return filteredCarriers
	}
	const findCarriers = (listData, item, action) => {
		const carrierWithId = listData.find((carrier) => {
			const carrierChk =
				carrier.scac === item.scac &&
				carrier.service_type === item.service_type &&
				carrier.contract_type === item.contract_type
			const result = action === 'payloadFilter' ? carrierChk && carrier.action === undefined : carrierChk
			return result
		})
		return carrierWithId
	}
	const preparePayloadToUpdate = () => {
		const payload = {}
		const payloadFiltered = carriersList.filter((carrier) => carrier.action !== undefined)
		let AllOtherAccessorials
		const newPayload = payloadFiltered.map((carrier) => {
			AllOtherAccessorials = carrier.accessorials.find((accessorial) => accessorial.integration_accessorial_id === -1)
			const newAccessorials = carrier.accessorials.filter((acc) => acc.action !== undefined)
			return {
				...carrier,
				accessorials: newAccessorials,
			}
		})

		/* CASE: when you try to delete and then add the same carrier without saving in the middle */

		// We get the array of duplicate carrier from the deleted list
		const duplicateCarrier = {}
		const duplicateCarrierArr = []
		const carrierDel = [...carriersDeleted]
		carrierDel.forEach((val) => {
			const carrierType = val.scac + val.service_type + val.contract_type
			if (duplicateCarrier[carrierType]) {
				duplicateCarrierArr.push(val)
			} else {
				duplicateCarrier[carrierType] = true
			}
		})
		duplicateCarrierArr.forEach((c) => {
			const index = carrierDel.indexOf(c)
			if (index > -1) {
				carrierDel.splice(index, 1)
			}
		})
		// From the deleted list, we filter the ones that are repeated (aka, deleted an re-added)
		const matchingCarriers = getFilteredCarriers(carrierDel, newPayload)
		// We get the array of SCACs of the matching carriers
		const matchingSCACs = matchingCarriers.map((c) => c.scac)

		// We get the original version from the BE of the carriers and we fine the ones repeated
		const carriersToReAdd = getFilteredCarriers(carriersList, matchingCarriers)
		carriersToReAdd.forEach((c) => {
			/* Each carrier must update their action and return their original id */

			const carrierWithId = findCarriers(carrierDel, c)
			if (carrierWithId !== undefined) {
				c.id = carrierWithId.id
				c.action = 'UPDATE'
			}
		})
		// We only delete the carriers that are not in the payload
		const definitiveCarriersToDelete = [...carriersDeleted]
		matchingCarriers.forEach((c) => {
			const index = definitiveCarriersToDelete.indexOf(c)
			if (index > -1) {
				definitiveCarriersToDelete.splice(index, 1)
			}
		})
		newPayload.push(...definitiveCarriersToDelete)
		newPayload.forEach((c) => {
			/* Each carrier must update their action and return their original id */
			if (c.action === 'ADD') {
				const existingcarrierWithId = findCarriers(carriersList, c, 'payloadFilter')
				if (existingcarrierWithId !== undefined) {
					c.id = existingcarrierWithId.id
					c.action = 'UPDATE'
				}
			}
		})
		// We filter the carriers that are not in the repeated
		const filteredDoubleCarriers = [...newPayload]
		matchingCarriers.forEach((c) => {
			const index = filteredDoubleCarriers.findIndex(
				(carrier) =>
					carrier.scac === c.scac &&
					carrier.service_type === c.service_type &&
					carrier.contract_type === c.contract_type,
			)
			if (index > -1) {
				filteredDoubleCarriers.splice(index, 1)
			}
		})
		payload.configurations_carriers = [...filteredDoubleCarriers, ...carriersToReAdd].map((c) => {
			const carrier = JSON.parse(JSON.stringify(c))
			delete carrier.carrier_integration_id
			delete carrier.name
			delete carrier.carrier_logo
			delete carrier.integration_name
			delete carrier.carrier_group
			delete carrier.status
			if (Number(carrier.uplift_fuel_surcharge) === 0) {
				carrier.uplift_fuel_surcharge = carrier.uplift_net_charge
			}
			if (carrier.action === 'DELETE') {
				carrier.accessorials = []
			} else {
				if (carrier.action === 'ADD') {
					carrier.id = null
				}

				/* We update the list of accessorials */
				if (carrier.accessorialsDeleted !== undefined) {
					carrier.accessorials.push(...carrier.accessorialsDeleted)
					delete carrier.accessorialsDeleted
				}

				if (AllOtherAccessorials !== undefined) {
					const newAvailableAccessorials = [...availableAccessorials]
					const newAccessorials = newAvailableAccessorials.map((availableAccessorial) => {
						const isRepeatedCarrier = matchingSCACs.includes(carrier.scac)

						const accessorialConfiguredId = carrier.accessorials.findIndex(
							(acc) => acc.integration_accessorial_id === availableAccessorial.accessorial_id,
						)
						const isConfigured = accessorialConfiguredId !== -1
						if (isConfigured) {
							const newAccessorial = carrier.accessorials[accessorialConfiguredId]
							delete newAccessorial.name
							return newAccessorial
						}
						const newAccessorialConfigured = {
							uplift_type_accessorial: AllOtherAccessorials.uplift_type_accessorial,
							integration_accessorial_id: availableAccessorial.accessorial_id,
							uplift_accessorial: AllOtherAccessorials.uplift_accessorial,
							action: 'ADD',
						}

						/* If carrier is being re-added, then we update all accesorials */
						if (isRepeatedCarrier) {
							const carrierDeleted = carriersDeleted.filter((cd) => cd.scac === carrier.scac)
							const accessorialDeleted = carrierDeleted?.[0].accessorials.find(
								(acc) => acc.name === availableAccessorial.name,
							)
							if (accessorialDeleted !== undefined) {
								newAccessorialConfigured.action = 'UPDATE'
								newAccessorialConfigured.id = accessorialDeleted.id
							}
						}

						delete newAccessorialConfigured.name
						return newAccessorialConfigured
					})
					carrier.accessorials = newAccessorials
				}

				const accessorials = carrier.accessorials.map((accessorial) => {
					const newAccessorial = { ...accessorial }
					if (newAccessorial.action === 'ADD') {
						newAccessorial.id = null
					}
					delete newAccessorial.name
					if (newAccessorial.code !== undefined) {
						delete newAccessorial.code
					}
					return newAccessorial
				})
				carrier.accessorials = accessorials
			}
			return carrier
		})
		return payload
	}

	const updateStepStatus = async () => {
		const params = `?companyId=${parentCompanyId}&step=4&status=TRUE`
		const config = {
			headers: { 'X-Company-id': Number(parentCompanyId) },
		}
		const { status: stepStatus } = await dispatch(editAccountStepStatus(params, config))
		if (stepStatus === 'error')
			SweetAlertModal(stepStatus, 'There was an error updating the account step status!', null, 'Ok', true, false, null)
	}

	const saveAccountCarriers = async () => {
		if (isEditable) {
			const payload = preparePayloadToUpdate()
			if (payload.configurations_carriers.length) {
				const { status, resp } = await dispatch(updateAccountCarriersConfiguration(payload, parentCompanyId))
				if (status === 'error') {
					sweetAlertModal('error', 'There was an error updating the carriers', resp, 'Ok', true, false)
				} else {
					await updateStepStatus()
					sweetAlertModal('success', 'Carrier list was updated successfully', null, 'Ok', true, false)
					resetAll()
					let isRefreshCarrierList = false
					const updatedCarriers = carriersList.map((carrier) => {
						if (carrier.action !== undefined) {
							isRefreshCarrierList = true
							delete carrier.action
						}
						return carrier
					})
					if (isRefreshCarrierList) {
						setCarriersList(updatedCarriers)
					}
				}
			}
		} else {
			const payload = preparePayload()
			if (payload.configurations_carriers.length) {
				const { status } = await dispatch(saveAccountCarriersConfiguration(payload))
				if (status === 'success') {
					await updateStepStatus()
					sweetAlertModal('success', 'Carriers were added successfully', null, 'Ok', true, false)
					history.push(accountsListRoute)
				}
			}
		}
	}

	useEffect(() => {
		const carriersFiltered = [...carriersList].filter((carrier) => {
			const localSearch = search.toLowerCase()
			const fieldsToFilter = ['name']

			const searchBarFilter = fieldsToFilter.some((key) => String(carrier[key]).toLowerCase().includes(localSearch))

			let carrierGroupFilter = true
			if (carrierGroupTable !== 'ALL') {
				carrierGroupFilter = carrierGroupTable === carrier.carrier_group
			}

			let serviceTypeFilter = true
			if (serviceTypeTable !== 'ALL') {
				if (serviceTypeTable === 'GUARANTEED') {
					serviceTypeFilter = carrier.service_type.startsWith('GUARANTEED')
				} else {
					serviceTypeFilter = carrier.service_type === serviceTypeTable
				}
			}
			return searchBarFilter && carrierGroupFilter && serviceTypeFilter
		})

		dispatch(updateTotalCarriers(carriersFiltered.length))

		const carriersPaginated = carriersFiltered.slice(currentPage, (currentPage / rowsPerPage + 1) * rowsPerPage)
		setList(carriersPaginated)
	}, [dispatch, carriersList, search, carrierGroupTable, serviceTypeTable, currentPage, rowsPerPage])

	const handleChangeCarrierGroup = (carrierGroupSelected) => {
		setCarrierGroupTable(carrierGroupSelected)
		dispatch(setCarrierGroup(carrierGroupSelected))
	}

	const handleChangeServiceType = (serviceTypeSelected) => {
		setServiceTypeTable(serviceTypeSelected)
	}

	const handleSearchBarChanged = (event) => {
		setSearch(event.target.value)
		if (event.target.value.length >= 3) {
			dispatch(setSearchBar(encodeURIComponent(event.target.value)))
			setPageReset(event.target.value)
		} else if (event.target.value.length === 0) {
			dispatch(setSearchBar(''))
			setPageReset('pageResetFilters')
		}
	}

	const handleChangeAccessorialUpliftType = (upliftType) => {
		setAccessorialUpliftType(upliftType)
		switch (upliftType) {
			case 'FLAT':
				setValuesAccss('accessorialMargin', '')
				break
			case 'PERCENTAGE':
				setValuesAccss('accessorialMargin', '60')
				break
			default:
				break
		}
	}

	const allOthersAccessorialsLabel = (fieldOption) => {
		let label = ''
		if (fieldOption === 'PERCENTAGE') {
			label =
				allOthersAccessorials?.uplift_type_accessorial === 'PERCENTAGE'
					? `${allOthersAccessorials.uplift_accessorial}%`
					: '0%'
		}
		if (fieldOption === 'FLAT') {
			label =
				allOthersAccessorials?.uplift_type_accessorial === 'FLAT'
					? `$${allOthersAccessorials.uplift_accessorial}`
					: '$0'
		}
		return label
	}

	const onHandleChangeCarrierMargin = (marginCarrier) => {
		const isEmptyAllOthersAccessorials = !Number(allOthersAccessorials.uplift_accessorial)
		if (isEmptyAllOthersAccessorials) {
			const newAllOthersAccessorials = {
				...allOthersAccessorials,
				uplift_type_accessorial: 'PERCENTAGE',
				uplift_accessorial: `${marginCarrier}`,
			}
			setAllOthersAccessorials(newAllOthersAccessorials)
		}
	}

	return (
		<div className={classes.accountCarrierWrapper}>
			<Grid container className={classes.accountCarriersContainer}>
				<div className={classes.containerTitle}>
					<div className={classes.accountCarriersTitleContainer}>
						<TruckIcon className={classes.accountCarriersTitleIcon} />
						<span className={classes.accountCarriersTitleTex}>Carriers</span>
					</div>
					<Button
						className={classes.headerActionButton}
						startIcon={<BackupOutlined color="secondary" />}
						size="small"
						color="primary"
						onClick={() => setModalOpenMassUploadLocations(true)}
					>
						Upload Multiple Locations
					</Button>
				</div>
				<span className={classes.accountCarriersTitleDescription}>
					Select the carriers you want to add to this account and apply an uplift
				</span>
				<div className={classes.accountCarriersSelectBy}>
					<span>Select by</span>
					<FormControl className={classes.accountCarriersCarrierGroup} variant="outlined" size="small">
						<InputLabel className={classes.field}>Carrier Group</InputLabel>
						<Select
							name="carrier-group"
							value={carrierGroup}
							label="Carrier Group"
							variant="outlined"
							placeholder="Carrier Group"
							className={classes.root}
							onChange={(e) => {
								setCarrierGroupState(e.target.value)
							}}
						>
							<MenuItem value="STANDARD">Standard</MenuItem>
							<MenuItem value="ECONOMY">Economy</MenuItem>
						</Select>
					</FormControl>
					<FormControl className={classes.accountCarriersContractType} variant="outlined" size="small">
						<InputLabel className={classes.field}>Service Type</InputLabel>
						<Select
							name="contract-type"
							value={serviceType}
							label="Service Type"
							variant="outlined"
							placeholder="Service Type"
							className={classes.root}
							onChange={(e) => {
								setServiceType(e.target.value)
							}}
						>
							<MenuItem value="ALL">All</MenuItem>
							<MenuItem value="LTL_STANDARD">LTL Standard</MenuItem>
							<MenuItem value="VOLUME">Volume</MenuItem>
							<MenuItem value="GUARANTEED_5PM">Guaranteed 5PM</MenuItem>
							<MenuItem value="GUARANTEED_NOON">Guaranteed Noon</MenuItem>
							<MenuItem value="GUARANTEED_9AM">Guaranteed 9AM</MenuItem>
						</Select>
					</FormControl>
					<div className={classes.accountCarriersApplyFiltersContainer}>
						<Button type="button" onClick={handleCarriersFilters} className={classes.accountCarriersApplyFilters}>
							<DoneAllIcon color="secondary" />
							<span>Apply filters</span>
						</Button>
					</div>
				</div>
				<form onSubmit={handleSubmit(handleSaveOrUpdateCarrier)}>
					<Grid className={classes.accountCarriersConfigForm} item xs={12} md={12} lg={12} xl={12}>
						<Autocomplete
							multiple
							size="small"
							options={availableCarriers}
							getOptionLabel={(option) => option.name}
							placeholder="Add carriers"
							value={selectedCarriers}
							disabled={isCarrierUpdate}
							onChange={(event, newValue) => {
								setSelectedCarriers(newValue)
							}}
							getOptionDisabled={(option) =>
								!!carriersList.find(
									(c) =>
										c.scac === option.scac &&
										c.contract_type === option.contract_type &&
										c.service_type === option.service_type,
								)
							}
							getOptionSelected={(option, value) => option.id === value.id}
							renderInput={(props) => (
								<TextField
									// eslint-disable-next-line react/jsx-props-no-spreading
									{...props}
									placeholder="Add carriers"
									label="Carriers"
									margin="normal"
									disabled={isCarrierUpdate}
									variant="outlined"
								/>
							)}
						/>
					</Grid>
					<Grid container className={classes.accountCarriersCustomizableInputs}>
						<TextField
							size="small"
							className={classes.accountCarriersConfigInputs}
							name="carrierMargin"
							type="number"
							inputRef={register({ required: true })}
							error={!!errors.carrierMargin}
							label="Margin *"
							variant="outlined"
							onBlur={(e) => onHandleChangeCarrierMargin(e.target.value)}
							InputProps={{
								endAdornment: <span>%</span>,
							}}
							InputLabelProps={{
								shrink: true,
							}}
						/>
						<TextField
							size="small"
							className={classes.accountCarriersConfigInputs}
							name="minimumProfit"
							type="number"
							inputRef={register({ required: true })}
							label="Minimum Profit *"
							variant="outlined"
							InputProps={{
								startAdornment: <span>$</span>,
							}}
							InputLabelProps={{
								shrink: true,
							}}
						/>
						<div className={classes.accountCarrierAddCarrierButtonContainer}>
							<Button type="submit" className={classes.accountCarriersAddCarrier}>
								<AddIcon color="secondary" />
								<span>{isCarrierUpdate ? 'Update Carrier' : 'Add Carrier(s)'}</span>
							</Button>
						</div>
					</Grid>
				</form>
				<Divider className={classes.accountCarriersDivider} />
				<Grid style={{ marginLeft: !matches ? '15px' : 0 }} item xs={12} md={12} sm={12} lg={12} xl={12}>
					<div className={classes.accountCarriersCustomization}>
						<span className={classes.accountCarriersCustomizableInputsTitle}>
							Do you want to customize fuel surcharge:
						</span>
						<RadioGroup
							style={{ fontSize: '14px', flexDirection: 'row' }}
							aria-label="customSurcharge"
							name="customSurcharge"
							value={customSurcharge}
							onChange={(event) => setCustomSurcharge(event.target.value)}
						>
							<FormControlLabel
								style={{ fontSize: '14px' }}
								value="no"
								control={<Radio size="small" color="secondary" />}
								label="No"
							/>
							<FormControlLabel
								style={{ fontSize: '14px' }}
								value="yes"
								control={<Radio size="small" color="secondary" />}
								label="Yes"
							/>
						</RadioGroup>
					</div>
					<div className={classes.accountCarriersCustomization}>
						<span className={classes.accountCarriersCustomizableInputsTitle}>
							Do you want to customize accessorials:
						</span>
						<RadioGroup
							style={{ flexDirection: 'row' }}
							aria-label="customAccessorials"
							name="customAccessorials"
							value={customAccessorials}
							onChange={(event) => setCustomAccessorials(event.target.value)}
						>
							<FormControlLabel value="no" control={<Radio size="small" color="secondary" />} label="No" />
							<FormControlLabel value="yes" control={<Radio size="small" color="secondary" />} label="Yes" />
						</RadioGroup>
					</div>
				</Grid>
			</Grid>
			<Collapse style={{ marginLeft: '20px' }} in={customSurcharge === 'yes'} timeout="auto" unmountOnExit>
				<Grid
					style={{ marginTop: '1em', display: 'flex', flexDirection: 'column' }}
					item
					md={12}
					xs={12}
					lg={12}
					xl={12}
				>
					<span className={classes.accountCarriersTitleTex}>Custom Fuel Surcharge</span>
					<TextField
						size="small"
						className={classes.accountCarriersFuelSurchargeMargin}
						name="fuelSurchargeMargin"
						type="number"
						value={fuelSurchargeMargin}
						onChange={(e) => setFuelSurchargeMargin(e.target.value)}
						label="Margin *"
						InputLabelProps={{
							style: {
								paddingLeft: matches ? '0px' : '25px',
							},
						}}
						variant="outlined"
						InputProps={{
							endAdornment: <span>%</span>,
						}}
					/>
				</Grid>
			</Collapse>
			<Collapse style={{ marginLeft: '20px' }} in={customAccessorials === 'yes'} timeout="auto" unmountOnExit>
				<span className={classes.accountCarriersTitleTex}>Custom Accessorials</span>
				<form style={{ width: '100%' }} onSubmit={handleSubmitAccss(handleSaveOrUpdateAccessorial)}>
					<Grid
						alignItems="center"
						style={{ maxWidth: '98%', marginTop: '5px', marginLeft: matches ? 0 : '15px' }}
						container
						spacing={2}
					>
						<Grid style={{ maxWidth: matches ? '98%' : '90%' }} item md={6} lg={3} xl={3} sm={12} xs={12}>
							<FormControl style={{ width: '100%' }} variant="outlined" size="small">
								<InputLabel>Accessorial *</InputLabel>
								<Select
									name="accessorialToAdd"
									value={accessorialToAdd}
									label="Accessorial +"
									variant="outlined"
									placeholder="Accessorial"
									className={classes.root}
									disabled={isAccessorialUpdate}
									onChange={(e) => {
										setAccessorialToAdd(e.target.value)
									}}
								>
									{availableAccessorials &&
										availableAccessorials.map((accessorial, index) => (
											<MenuItem key={`id-${index + 1}-available-accessorial`} value={accessorial.accessorial_id}>
												{capitalizeFirstLetter(accessorial.name)}
											</MenuItem>
										))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item md={6} lg={3} xs={12} sm={12} xl={4}>
							<span>Type of uplift</span>
							<div className={classes.accountCarriersUpliftOptions}>
								<RadioGroup
									style={{ flexDirection: 'row' }}
									aria-label="marginOption"
									name="marginOption"
									value={accessorialUpliftType}
									onChange={(event) => handleChangeAccessorialUpliftType(event.target.value)}
								>
									<FormControlLabel
										value="PERCENTAGE"
										control={<Radio size="small" color="secondary" />}
										label="Accessorial Margin"
									/>
									<FormControlLabel
										value="FLAT"
										control={<Radio size="small" color="secondary" />}
										label="Accessorial Flat Fee"
									/>
								</RadioGroup>
							</div>
						</Grid>
						<Grid style={{ maxWidth: matches ? '98%' : '90%' }} item md={6} lg={3} xs={12} sm={12} xl={2}>
							<TextField
								size="small"
								name="accessorialMargin"
								type="number"
								label="Accessorial uplift amount *"
								inputRef={registerAccss({ required: true })}
								error={!!errorsAccss.accessorialMargin}
								variant="outlined"
								style={{ width: '100%' }}
								InputLabelProps={{ shrink: true }}
								InputProps={{
									endAdornment: <span>{accessorialMarginSymbol}</span>,
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item md={6} lg={2} xs={12} sm={12} xl={2}>
							<div className={classes.accountCarriersApplyFiltersContainerTwo}>
								<Button type="submit" className={classes.accountCarriersApplyFilters}>
									<AddIcon color="secondary" />
									<span>{isAccessorialUpdate ? 'Update Accessorial' : 'Add Accessorial'}</span>
								</Button>
							</div>
						</Grid>
					</Grid>
				</form>
				<Grid style={{ maxWidth: '98%', margin: matches ? '1em 0' : '1em 0 0 25px' }} container direction="column">
					<span style={{ marginBottom: '1em' }} className={classes.accountCarriersTitleTex}>
						Accessorials Added
					</span>
					<AccessorialsSlider accessorialsList={customCarriersAccessorials} />
					<TableContainer className={classes.tableContainer}>
						<Table className={classes.accountCarriersAccessorialsTable} size="small" aria-label="a dense table">
							<TableHead>
								<TableRow>
									<TableCell className={classes.accountCarriresTableHeads}>Accessorial</TableCell>
									<TableCell className={classes.accountCarriresTableHeads}>Margin</TableCell>
									<TableCell className={classes.accountCarriresTableHeads}>Flat Fee</TableCell>
									<TableCell className={classes.accountCarriresTableHeads}>Actions</TableCell>
								</TableRow>
							</TableHead>
							<TableBody style={{ maxHeight: '100px' }}>
								{customCarriersAccessorials &&
									customCarriersAccessorials.map((customAccss, index) => (
										<TableRow>
											<TableCell className={classes.accountCarriersTableCell} component="th" scope="row">
												{capitalizeFirstLetter(customAccss.name !== undefined ? customAccss.name : '')}
											</TableCell>
											<TableCell className={classes.accountCarriersTableCell}>
												{customAccss.uplift_type_accessorial === 'PERCENTAGE'
													? `${customAccss.uplift_accessorial}%`
													: '0%'}
											</TableCell>
											<TableCell className={classes.accountCarriersTableCell}>
												{customAccss.uplift_type_accessorial === 'FLAT' ? `$${customAccss.uplift_accessorial}` : '$0'}
											</TableCell>
											<TableCell className={classes.accountCarriersTableCell}>
												<CreateIcon
													style={{ cursor: 'pointer' }}
													onClick={() => {
														handleUpdateAccessorial(index)
													}}
													color="secondary"
												/>
												<DeleteIcon
													style={{ cursor: 'pointer' }}
													onClick={() => {
														handleDeleteAccessorial(index)
													}}
													color="error"
												/>
											</TableCell>
										</TableRow>
									))}
								{customCarriersAccessorials.length < availableAccessorials.length ? (
									<TableRow>
										<TableCell
											style={{ fontWeight: '600' }}
											className={classes.accountCarriersTableCell}
											component="th"
											scope="row"
										>
											All others accessorials
										</TableCell>
										<TableCell className={classes.accountCarriersTableCell}>
											{allOthersAccessorialsLabel('PERCENTAGE')}
										</TableCell>
										<TableCell className={classes.accountCarriersTableCell}>
											{allOthersAccessorialsLabel('FLAT')}
										</TableCell>
										<TableCell className={classes.accountCarriersTableCell}>
											<CreateIcon
												style={{ cursor: 'pointer' }}
												onClick={() => {
													handleUpdateAccessorial(-1)
												}}
												color="secondary"
											/>
										</TableCell>
									</TableRow>
								) : null}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			</Collapse>
			<Grid
				container
				spacing={2}
				style={{
					padding: '16px 24px',
					maxWidth: '100%',
					margin: '20px 0px 0px',
					background: matches ? '#FFFFFF' : '#F7F8F8',
				}}
				alignItems="flex-end"
				className={classes.carriersListContainer}
			>
				<Grid container>
					<span className={classes.accountCarriersTitleTex}>Carriers Added</span>
				</Grid>
				<Grid container style={{ paddingTop: '17px' }} alignItems="center" spacing={2}>
					<Grid item xs={12} lg={5} xl={4}>
						<SearchBar value={search} size="small" placeholder="Search..." onChangeHandler={handleSearchBarChanged} />
					</Grid>
					<Grid item xs={12} md={4} lg={2} xl={2}>
						<FormControl style={{ width: '100%', background: 'white' }} variant="outlined" size="small">
							<InputLabel className={classes.field}>Carrier Group</InputLabel>
							<Select
								name="carrier-group"
								value={carrierGroupTable}
								label="Carrier Group"
								variant="outlined"
								placeholder="Carrier Group"
								className={classes.root}
								onChange={(e) => {
									handleChangeCarrierGroup(e.target.value)
								}}
							>
								<MenuItem value="ALL">All</MenuItem>
								<MenuItem value="STANDARD">Standard</MenuItem>
								<MenuItem value="ECONOMY">Economy</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12} md={5} lg={3} xl={3}>
						<FormControl style={{ width: '100%', background: 'white' }} variant="outlined" size="small">
							<InputLabel className={classes.field}>Service Type</InputLabel>
							<Select
								name="service-type-table"
								value={serviceTypeTable}
								label="Service Type"
								variant="outlined"
								placeholder="Service Type"
								className={classes.root}
								onChange={(e) => {
									handleChangeServiceType(e.target.value)
								}}
							>
								<MenuItem value="ALL">All</MenuItem>
								<MenuItem value="LTL_STANDARD">LTL Standard</MenuItem>
								<MenuItem value="VOLUME">Volume</MenuItem>
								<MenuItem value="GUARANTEED">Guaranteed</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid style={{ padding: '4px' }} item xs={6} md={3} lg={2} xl={2}>
						<Button
							onClick={handleResetFilters}
							color="primary"
							startIcon={<Loop color="secondary" style={{ marginRight: '-4px' }} />}
							style={{ textTransform: 'none' }}
							className={classes.filterButton}
						>
							<span className={classes.filterButtonText}>Reset filters</span>
						</Button>
					</Grid>
				</Grid>
				<Grid container alignItems="center">
					<Grid item xs={12}>
						<CarriersTable
							carriersList={list}
							setCarriersList={setCarriersList}
							completeCarriersList={carriersList}
							setCompleteCarriersList={setCarriersList}
							isLoading={isLoading}
							isSelectableList
							isAccountCarrierList
							tableType="carriers"
							pageReset={pageReset}
							handleSortColumn={() => {}}
							handleUpdateCarrier={handleUpdateCarrier}
							selected={selected}
							setSelected={setSelected}
							carriersDeleted={carriersDeleted}
							setCarriersDeleted={setCarriersDeleted}
						/>
					</Grid>
					<Grid item xs={12}>
						<CarriersSlider
							carriersList={carriersList}
							setCarriersList={setCarriersList}
							selected={selected}
							isAccountCarrierList
							setSelected={setSelected}
							handleEditClick={handleUpdateCarrier}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid container className={classes.containerButton}>
				<Button className={classes.nextStepButton} variant="contained" color="secondary" onClick={saveAccountCarriers}>
					Save account
				</Button>
				<Button className={classes.previousButton} variant="contained" onClick={() => setActiveStep(activeStep - 1)}>
					Previous
				</Button>
				<Button className={classes.cancelButton} onClick={() => handleCancelAccountProcess()}>
					Cancel
				</Button>
			</Grid>
			{modalOpenMassUploadLocations && (
				<UploadLocationsModal
					open={modalOpenMassUploadLocations}
					handleClose={() => setModalOpenMassUploadLocations(false)}
				/>
			)}
		</div>
	)
}

CarriersStepper.defaultProps = {
	isEditable: false,
}

CarriersStepper.propTypes = {
	setActiveStep: PropTypes.func.isRequired,
	activeStep: PropTypes.number.isRequired,
	handleCancelAccountProcess: PropTypes.func.isRequired,
	isEditable: PropTypes.bool,
	mainRef: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default CarriersStepper
