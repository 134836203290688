import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	root: {
		'& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
			display: 'none',
		},
	},
	newLocationForm: {
		display: 'flex',
		flexDirection: 'column',
		margin: '-16px -24px',
		paddingBottom: '160px',
		[theme.breakpoints.down('md')]: {
			margin: '-16px',
			paddingBottom: '220px',
		},
	},
	newLocationHeader: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: theme.palette.neutral.whiteGray,
		padding: '16px 23px',
		[theme.breakpoints.down('md')]: {
			padding: '16px',
		},
	},
	newLocationTitleRow: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginRight: '2%',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
			marginRight: '0%',
			marginBottom: '2%',
		},
	},
	newLocationType: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '33%',
		[theme.breakpoints.down('md')]: {
			width: '99%',
			marginTop: '2%',
			marginBottom: '2%',
			marginLeft: '1%',
		},
	},
	newLocationTitle: {
		textAlign: 'start',
		margin: '0',
		fontSize: '34px',
		fontWeight: '700',
		[theme.breakpoints.down('md')]: {
			fontSize: '22px',
		},
	},
	newLocationSubtitle: {
		margin: '0',
		fontSize: '16px',
		[theme.breakpoints.down('md')]: {
			textAlign: 'justify',
		},
	},
	newLocationSection: {
		display: 'flex',
		flexDirection: 'column',
		padding: '1%',
		[theme.breakpoints.down('md')]: {
			marginLeft: '3%',
			marginRight: '3%',
			marginTop: '3%',
			marginBottom: '1%',
		},
	},
	newLocationContactSection: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: theme.palette.neutral.whiteGray,
		padding: '24px 1% 0',
	},
	newNotificationSection: {
		display: 'flex',
		flexDirection: 'column',
		padding: '1%',
	},
	newLocationIconTitle: {
		display: 'flex',
		columnGap: '12px',
		alignItems: 'center',
		marginBottom: '1.5%',
		[theme.breakpoints.down('md')]: {
			marginTop: '2%',
			alignItems: 'flex-start',
			marginLeft: '2%',
		},
	},
	newLocationSectionTitle: {
		fontSize: '18px',
		fontWeight: theme.typography.fontWeightRegular,
		color: theme.palette.primary.main,
		margin: 0,
		lineHeight: '22px',
		[theme.breakpoints.down('md')]: {
			fontSize: '15px',
		},
	},
	newLocationTypeLabel: {
		fontSize: '16px',
		color: theme.palette.primary.dark,
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},
	newLocationFormSection: {},
	newLocationFormRow: {
		display: 'flex',
		flexDirection: 'row',
		marginLeft: '1%',
		marginBottom: '1.5%',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
		},
	},
	newLocationFormSubtitle: {
		fontSize: '16px',
		color: theme.palette.common.black,
		fontWeight: theme.typography.fontWeightBold,
		marginBottom: '1%',
		[theme.breakpoints.down('md')]: {
			fontSize: '14px',
		},
	},
	helperText: {
		fontSize: '12px',
		fontWeight: '400',
		color: theme.palette.neutral.darkGray,
	},
	newLocationCompanyName: {
		width: '48%',
		marginRight: '2%',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginTop: '4%',
			marginBottom: '4%',
		},
	},
	newLocationAddressOne: {
		width: '23%',
		marginRight: '2%',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginTop: '4%',
			marginBottom: '4%',
		},
	},
	newLocationAddressTwo: {
		width: '23%',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginTop: '4%',
			marginBottom: '4%',
		},
	},
	newLocationId: {
		width: '23%',
		marginRight: '2%',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginTop: '4%',
			marginBottom: '4%',
		},
	},
	newLocationZipCode: {
		width: '23%',
		marginRight: '2%',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginTop: '4%',
			marginBottom: '4%',
		},
	},
	newLocationFormMultiControl: {
		display: 'flex',
		flexDirection: 'row',
		width: '23%',
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
	newLocationCity: {
		width: '90%',
		marginRight: '7%',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginTop: '4%',
			marginBottom: '4%',
			marginRight: '3%',
		},
	},
	newLocationCountry: {
		width: '100%',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginTop: '4%',
			marginBottom: '4%',
		},
	},
	newLocationContactName: {
		width: '23%',
		marginRight: '2%',
		[theme.breakpoints.down('md')]: {
			width: '94%',
			marginTop: '3%',
			marginBottom: '3%',
			marginLeft: '3%',
			marginRight: '3%',
		},
	},
	newLocationContactPhone: {
		width: '23%',
		marginRight: '2%',
		[theme.breakpoints.down('md')]: {
			width: '94%',
			marginTop: '3%',
			marginBottom: '3%',
			marginLeft: '3%',
			marginRight: '3%',
		},
	},
	newLocationContactEmail: {
		width: '23%',
		marginRight: '2%',
		[theme.breakpoints.down('md')]: {
			width: '94%',
			marginTop: '3%',
			marginBottom: '3%',
			marginLeft: '3%',
			marginRight: '3%',
		},
	},
	newLocationNotifications: {
		width: '15%',
		[theme.breakpoints.down('md')]: {
			width: '70%',
			marginLeft: '3%',
			marginRight: '3%',
		},
	},
	newLocationNotificationsSubtitle: {
		margin: 0,
		fontSize: '14px',
		[theme.breakpoints.down('md')]: {
			fontSize: '12px',
		},
	},
	newLocationCheckboxContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	checkbox: {
		color: theme.palette.primary.main,
	},
	newLocationCheckboxSMS: {},
	newLocationAccessorials: {
		width: '99%',
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
	newLocationDefault: {
		width: '50%',
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
	newLocationRowButtons: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		padding: '1% 3%',
		marginTop: '3%',
		backgroundColor: theme.palette.neutral.white,
		position: 'fixed',
		bottom: 0,
		left: 0,
		right: 0,
		zIndex: 1,
		[theme.breakpoints.down('md')]: {
			padding: '16px 16px 16px 114px',
			flexDirection: 'column-reverse',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '16px 16px',
		},
	},
	newLocationIconContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '28px',
		height: '28px',
	},
	newLocationIcon: {
		width: '20px',
		height: '20px',
	},
	cancelButton: {
		width: '170px',
		height: '50px',
		textTransform: 'none',
		backgroundColor: theme.palette.neutral.whiteGray,
		marginRight: '1em',
		fontWeight: theme.typography.fontWeightMedium,
		fontSize: '17px',
		[theme.breakpoints.down('md')]: {
			width: '97%',
			marginTop: '2.5%',
			marginLeft: '2%',
			marginRight: '2%',
		},
	},
	saveButton: {
		width: 'auto',
		height: '50px',
		textTransform: 'none',
		color: theme.palette.neutral.white,
		fontWeight: theme.typography.fontWeightMedium,
		fontSize: '17px',
		[theme.breakpoints.down('md')]: {
			width: '97%',
			marginLeft: '2%',
			marginRight: '2%',
		},
	},
	timePicker: {
		width: '100%',
		height: '40px',
		padding: '0',
		'& .rc-time-picker-input': {
			height: '100%',
			fontSize: '16px',
			fontWeight: '500',
			border: 'none',
			outline: 'none',
			padding: '18.5px 14px',
		},
		'& .rc-time-picker-clear': {
			top: '9px',
		},
		'& .rc-time-picker-clear-icon::after': {
			fontSize: '20px',
		},
	},
	popupTimePicker: {
		'& .rc-time-picker-panel-input': {
			fontSize: '16px',
			fontWeight: '500',
		},
		'& .rc-time-picker-panel-inner': {
			marginTop: '20%',
		},
		'& .rc-time-picker-panel-select': {
			width: '50%',
			fontSize: '16px',
			fontWeight: '500',
			'&::-webkit-scrollbar': {
				width: '5px',
			},
			'&::-webkit-scrollbar-track': {
				'-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
				borderRadius: '10px',
			},
			'&::-webkit-scrollbar-thumb': {
				borderRadius: '10px',
				'-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.5)',
			},
		},
	},
	newLocationOpenTimePicker: {
		[theme.breakpoints.down('md')]: {
			marginBottom: '20px',
			width: '100%',
			marginRight: '0%',
			marginLeft: '0%',
		},
	},
	newLocationCloseTimePicker: {
		marginLeft: '2%',
		[theme.breakpoints.down('md')]: {
			marginBottom: '20px',
			marginTop: '20px',
			width: '100%',
			marginLeft: '0%',
		},
	},
}))

export default useStyles
