/* eslint-disable import/no-unresolved */
import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { TableCell, TableHead, TableRow, Checkbox, useTheme } from '@material-ui/core'

import { invoiceTableCells } from '../../../../utils/headCells'
import useStyles from './styles'
import SortableTableHeader from '../../../../components/SortableTableHeader'

const TableHeader = (props) => {
	const theme = useTheme()
	const classes = useStyles()
	const { onSelectAllClick, numSelected, rowCount, handleSortColumn, showActions } = props
	const invoicesFilters = useSelector((state) => state.invoicesFilters)

	return (
		<TableHead>
			<TableRow>
				<TableCell padding="checkbox" className={classes.tableCell}>
					{showActions ? (
						<Checkbox
							indeterminate={numSelected > 0 && numSelected < rowCount}
							checked={rowCount > 0 && numSelected === rowCount}
							onChange={onSelectAllClick}
							inputProps={{ 'aria-label': 'select all invoices' }}
							style={{
								color:
									(rowCount > 0 && numSelected === rowCount) || (numSelected > 0 && numSelected < rowCount)
										? theme.palette.secondary.main
										: '#818181',
							}}
						/>
					) : null}
				</TableCell>
				<SortableTableHeader
					filters={invoicesFilters}
					handleSortColumn={handleSortColumn}
					columns={invoiceTableCells}
				/>
				{showActions ? <TableCell className={classes.tableCell}>ACTIONS</TableCell> : null}
			</TableRow>
		</TableHead>
	)
}

TableHeader.defaultProps = {
	showActions: false.isRequired,
}

TableHeader.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	rowCount: PropTypes.number.isRequired,
	handleSortColumn: PropTypes.func.isRequired,
	showActions: PropTypes.bool,
}

export default TableHeader
