import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	tableRow: {
		minHeight: 80,
		'&.Mui-selected, &.Mui-selected.MuiTableRow-hover': {
			backgroundColor: theme.palette.neutral.secondaryGrey,
		},
	},
	tableCell: {
		height: 60,
		padding: '0 16px',
		fontSize: 14,
		fontWeight: theme.typography.fontWeightRegular,
	},
	tableCellCarrier: {
		display: 'flex',
		alignItems: 'center',
		columnGap: '10px',
	},
	carrierImg: {
		width: 'auto',
		height: 'auto',
		maxWidth: '45px',
		maxHeight: '80px',
	},
	boxCarrier: {
		width: '95px',
		margin: 'auto',
	},
	status: {
		fontSize: 11,
		fontWeight: theme.typography.fontWeightRegular,
		color: theme.palette.neutral.white,
		padding: '0 14px',
	},
	statusRed: {
		backgroundColor: theme.palette.semantic.red.primary,
	},
	statusOrange: {
		backgroundColor: theme.palette.semantic.orange.primary,
	},
	statusGreen: {
		backgroundColor: theme.palette.success.light,
		color: theme.palette.success.dark,
	},
	linkPointer: {
		cursor: 'pointer',
	},
	formRadio: {
		margin: 0,
	},
	cellAccountInfo: {
		display: 'flex',
		alignItems: 'center',
		'& > div:nth-child(2)': {
			width: 'max-content',
		},
	},
	carrierName: {
		color: '#000',
		marginLeft: '5px',
		textDecoration: 'none',
	},
}))

export default useStyles
