import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	root: {
		'& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
			display: 'none',
		},
		backgroundColor: theme.palette.neutral.white,
	},
	desktopTable: {
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},
	table: {
		width: '97%',
		marginLeft: '20px',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginBottom: '10px',
			minWidth: 100,
		},
		[theme.breakpoints.down('sm')]: {
			marginLeft: '0px',
		},
	},
	tableContainer: {
		margin: '20px 0px 30px 0px',
		maxHeight: '125px',
		'&::-webkit-scrollbar': {
			width: '5px',
		},
		'&::-webkit-scrollbar-track': {
			'-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
			borderRadius: '10px',
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: '10px',
			'-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.5)',
		},
	},
	childAccountsMessageContainer: {
		margin: '0 0 30px 0',
		color: theme.palette.neutral.darkGray,
	},
	childAccountsMessageTitle: {
		textAlign: 'center',
		margin: '10px 0',
		fontSize: 17,
		fontWeight: theme.typography.fontWeightBold,
	},
	childAccountsMessage: {
		textAlign: 'center',
		margin: '10px 0',
		fontSize: 14,
		fontWeight: theme.typography.fontWeightRegular,
	},
	formContainer: {
		width: '100%',
	},
	tableRow: {
		'&.Mui-selected, &.Mui-selected:hover': {
			backgroundColor: theme.palette.neutral.white,
			border: '1.5px solid #F3846F',
			boxShadow: '0 0 10px 0px #F9D2CA',
		},
	},
	tableCell: {
		fontFamily: 'Open Sans',
		fontSize: '12px',
		fontWeight: theme.typography.fontWeightMedium,
		borderBottomStyle: 'none',
		padding: '0px',
	},
	tableCellHead: {
		borderBottomStyle: 'none',
		fontSize: '12px',
		fontWeight: theme.typography.fontWeightBold,
	},
	childAccountTitle: {
		fontSize: '17px',
		fontWeight: theme.typography.fontWeightBold,
		color: theme.palette.primary.main,
		margin: 0,
		lineHeight: '22px',
	},
}))

export default useStyles
