import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	IconButton,
	Checkbox,
	Tooltip,
	TextField,
	useTheme,
} from '@material-ui/core'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import CancelIcon from '@material-ui/icons/Cancel'

import { useDispatch } from 'react-redux'

import { addNotification, removeNotification } from '../../../../redux/actions/Shipment.actions'

import PhoneNumberFormat from '../../../../components/PhoneNumberFormat'
import sweetAlertModal from '../../../../components/SweetAlertModal/SweetAlertModal'
import ContactInfoRow from './ContactInfoRow'

import useStyles from './styles'
import './NotificationsTable.scss'

const NotificationsTable = ({ contactData, setContactData, shipmentId }) => {
	const classes = useStyles()
	const theme = useTheme()
	const dispatch = useDispatch()

	const [isNewContactClicked, setIsNewContactClicked] = useState(false)
	const [contactName, setContactName] = useState('')
	const [contactNameError, setContactNameError] = useState(false)
	const [emailAddress, setEmailAddress] = useState('')
	const [emailAddressError, setEmailAddressError] = useState(false)
	const [cellPhone, setCellPhone] = useState('')
	const [cellPhoneError, setCellPhoneError] = useState(false)
	const [notificationSendBol, setNotificationSendBol] = useState(false)
	const [notificationByEmail, setNotificationByEmail] = useState(false)
	const [notificationBySms, setNotificationBySms] = useState(false)
	const [notificationBooked, setNotificationBooked] = useState(false)
	const [notificationInTransit, setNotificationInTransit] = useState(false)
	const [notificationDelivered, setNotificationDelivered] = useState(false)

	const handleConfirm = async () => {
		if (contactName) {
			if (emailAddress && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(emailAddress)) {
				setEmailAddressError(true)
				return
			}

			if (cellPhone && !cellPhone.match(/^(\d{3})(\d{3})(\d{4})$/)) {
				setCellPhoneError(true)
				return
			}

			const params = {
				contact_name: contactName,
				email_address: emailAddress,
				contact_phone: cellPhone ? `+1${cellPhone}` : '',
				send_bol_email: notificationSendBol,
				notification_method_email: notificationByEmail,
				notification_method_sms: notificationBySms,
				tracking_notification_booked: notificationBooked,
				tracking_notification_in_transit: notificationInTransit,
				tracking_notification_delivered: notificationDelivered,
			}

			const { status, resp } = await dispatch(addNotification(shipmentId, params))
			if (status === 'error') {
				sweetAlertModal(
					status,
					'There was a problem adding the notification information!',
					resp,
					'Ok',
					true,
					false,
					null,
				)
			} else {
				const newContact = {
					name: contactName,
					email: emailAddress,
					phone: cellPhone,
					notificationSendBol,
					notificationByEmail,
					notificationBySms,
					notificationBooked,
					notificationInTransit,
					notificationDelivered,
					isAdded: true,
					notificationId: resp.data.data.contactNotification.id,
				}

				setContactData((prevState) => [...prevState, newContact])
				setContactName('')
				setEmailAddress('')
				setEmailAddress('')
				setCellPhone(false)
				setNotificationSendBol(false)
				setNotificationByEmail(false)
				setNotificationBySms(false)
				setNotificationBooked(false)
				setNotificationInTransit(false)
				setNotificationDelivered(false)
				setIsNewContactClicked(false)
			}
		} else {
			setContactNameError(true)
		}
	}

	const deleteShipmentNotification = async (contactIndex) => {
		const { notificationId } = contactData[contactIndex]
		const { status, resp } = await dispatch(removeNotification(shipmentId, notificationId))
		if (status === 'error') {
			sweetAlertModal(
				status,
				'There was a problem removing the notification information!',
				resp,
				'Ok',
				true,
				false,
				null,
			)
		} else {
			const contactInfo = [...contactData]
			contactInfo.splice(contactIndex, 1)
			setContactData(contactInfo)
		}
	}

	return (
		<div className={classes.tableContainer}>
			<TableContainer component={Paper} className={classes.paper}>
				<Table className={classes.table} aria-label="customized table">
					<TableHead>
						<TableRow>
							<TableCell
								align="center"
								colSpan={3}
								className={`${classes.tableCellHead} ${classes.tableVerticalLineRight}`}
							>
								Contact Information
							</TableCell>
							<TableCell
								align="center"
								colSpan={3}
								className={`${classes.tableCellHead} ${classes.tableVerticalLineRight}`}
							>
								Method
							</TableCell>
							<TableCell
								align="center"
								colSpan={3}
								className={`${classes.tableCellHead} ${classes.tableVerticalLineRight}`}
							>
								Tracking Notifications
							</TableCell>
							<TableCell align="center" colSpan={1} rowSpan={2} className={classes.tableCellHead}>
								Actions
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align="center" className={classes.tableCellSecondHead}>
								Contact Name
							</TableCell>
							<TableCell align="center" className={classes.tableCellSecondHead}>
								Email Address
							</TableCell>
							<TableCell align="center" className={`${classes.tableCellSecondHead} ${classes.tableVerticalLineRight}`}>
								Cell Phone
							</TableCell>
							<TableCell align="center" className={classes.tableCellSecondHead}>
								Send BOL by Email
							</TableCell>
							<TableCell align="center" className={classes.tableCellSecondHead}>
								Email
							</TableCell>
							<TableCell align="center" className={`${classes.tableCellSecondHead} ${classes.tableVerticalLineRight}`}>
								SMS
							</TableCell>
							<TableCell align="center" className={classes.tableCellSecondHead}>
								Booked
							</TableCell>
							<TableCell align="center" className={classes.tableCellSecondHead}>
								In Transit
							</TableCell>
							<TableCell align="center" className={`${classes.tableCellSecondHead} ${classes.tableVerticalLineRight}`}>
								Delivered
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{contactData.map((item, index) => (
							<ContactInfoRow
								key={`id-${index + 1}`}
								index={index}
								contactInformation={item}
								allContactData={contactData}
								setContactData={setContactData}
								shipmentId={shipmentId}
								deleteShipmentNotification={deleteShipmentNotification}
							/>
						))}
						{isNewContactClicked && (
							<TableRow>
								<TableCell align="center" className={classes.tableCell}>
									<TextField
										value={contactName}
										onChange={(event) => {
											setContactName(event.target.value)
											setContactNameError(false)
										}}
										error={contactNameError}
										helperText={contactNameError && 'This value is required'}
									/>
								</TableCell>
								<TableCell align="center" className={classes.tableCell}>
									<TextField
										value={emailAddress}
										onChange={(event) => {
											setEmailAddress(event.target.value)
											setEmailAddressError(false)
										}}
										InputProps={{
											className: classes.input,
										}}
										error={emailAddressError}
										helperText={emailAddressError && 'Please enter a valid email address'}
									/>
								</TableCell>
								<TableCell align="center" className={`${classes.tableCell} ${classes.tableVerticalLineRight}`}>
									<TextField
										value={cellPhone}
										onChange={(event) => {
											setCellPhone(event.target.value)
											setCellPhoneError(false)
										}}
										name="phone"
										InputProps={{
											inputComponent: PhoneNumberFormat,
											className: classes.input,
										}}
										error={cellPhoneError}
										helperText={cellPhoneError && 'Please enter a valid phone number'}
									/>
								</TableCell>
								<TableCell align="center">
									<Checkbox
										color="primary"
										className={classes.checkBox}
										checked={notificationSendBol}
										onChange={(event) => setNotificationSendBol(event.target.checked)}
									/>
								</TableCell>
								<TableCell align="center">
									<Checkbox
										color="primary"
										className={classes.checkBox}
										checked={notificationByEmail}
										onChange={(event) => setNotificationByEmail(event.target.checked)}
									/>
								</TableCell>
								<TableCell align="center" className={classes.tableVerticalLineRight}>
									<Checkbox
										color="primary"
										className={classes.checkBox}
										checked={notificationBySms}
										onChange={(event) => setNotificationBySms(event.target.checked)}
									/>
								</TableCell>
								<TableCell align="center">
									<Checkbox
										color="primary"
										className={classes.checkBox}
										checked={notificationBooked}
										onChange={(event) => setNotificationBooked(event.target.checked)}
									/>
								</TableCell>
								<TableCell align="center">
									<Checkbox
										color="primary"
										className={classes.checkBox}
										checked={notificationInTransit}
										onChange={(event) => setNotificationInTransit(event.target.checked)}
									/>
								</TableCell>
								<TableCell align="center" className={classes.tableVerticalLineRight}>
									<Checkbox
										color="primary"
										className={classes.checkBox}
										checked={notificationDelivered}
										onChange={(event) => setNotificationDelivered(event.target.checked)}
									/>
								</TableCell>
								<TableCell align="center">
									<div style={{ display: 'grid', gridAutoFlow: 'column' }}>
										<Tooltip title="Confirm">
											<IconButton
												aria-label="toggle password visibility"
												onMouseDown={null}
												edge="end"
												onClick={() => handleConfirm()}
											>
												<DoneAllIcon htmlColor={theme.palette.success.main} className={classes.icon} />
											</IconButton>
										</Tooltip>
										<Tooltip title="Cancel">
											<IconButton
												aria-label="toggle password visibility"
												onMouseDown={null}
												edge="end"
												onClick={() => setIsNewContactClicked(false)}
											>
												<CancelIcon color="error" className={classes.icon} />
											</IconButton>
										</Tooltip>
									</div>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<div className="notifications-table-icon-title">
				<div
					className="notifications-table-new-contact"
					role="presentation"
					onClick={() => setIsNewContactClicked(true)}
				>
					<AddOutlinedIcon color="secondary" className={classes.icon} />
					<p className="notifications-table-title">Add New Contact</p>
				</div>
			</div>
		</div>
	)
}

NotificationsTable.propTypes = {
	contactData: PropTypes.arrayOf(PropTypes.any).isRequired,
	setContactData: PropTypes.func.isRequired,
	shipmentId: PropTypes.number.isRequired,
}

export default NotificationsTable
