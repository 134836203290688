/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initState = {
	currentPage: 0,
	rowsPerPage: 10,
	searchBar: '',
	carrierGroup: '',
	contractType: '',
	orderBy: '',
	order: '',
}

const carriersFilters = createSlice({
	name: 'carriersFilters',
	initialState: {
		...initState,
	},
	reducers: {
		setSearchBar: (state, action) => {
			state.searchBar = action.payload
		},
		setCarrierGroup: (state, action) => {
			state.carrierGroup = action.payload
		},
		setContractType: (state, action) => {
			state.contractType = action.payload
		},
		setCurrentPage: (state, action) => {
			state.currentPage = action.payload
		},
		setRowsPerPage: (state, action) => {
			state.rowsPerPage = action.payload
		},
		resetFilters: (state) => {
			state.currentPage = initState.currentPage
			state.rowsPerPage = initState.rowsPerPage
			state.searchBar = initState.searchBar
			state.carrierGroup = initState.carrierGroup
			state.contractType = initState.contractType
			state.orderBy = initState.orderBy
			state.order = initState.order
		},
		setOrderByAndOrder: (state, action) => {
			const { orderBy, order } = action.payload
			state.orderBy = orderBy
			state.order = order
		},
	},
})

export const {
	setSearchBar,
	setCarrierGroup,
	setContractType,
	setCurrentPage,
	setRowsPerPage,
	setOrderByAndOrder,
	resetFilters,
} = carriersFilters.actions

const { reducer } = carriersFilters
export default reducer
