import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
	autoComplete: {
		'& .MuiChip-deleteIcon': {
			color: theme.palette.secondary.main,
		},
		'& .MuiChip-label': {
			color: theme.palette.neutral.black,
			fontSize: 16,
		},
	},
	textFiled: {
		borderColor: theme.palette.secondary.light,
	},
}))
