import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { TableCell, TableRow, Radio, CircularProgress, Tooltip, useTheme } from '@material-ui/core'
import { CheckCircle } from '@material-ui/icons'
import SendIcon from '@material-ui/icons/Send'
import GetAppIcon from '@material-ui/icons/GetApp'
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined'
import AddRemoveInsurance from '../../modules/newQuoteShipment/components/AddRemoveInsurance'
import SendByMail from './components/SendByMail/SendByMail'
import SweetAlertModal from '../SweetAlertModal'
import { downloadDocumentRate } from '../../redux/actions/Rates.actions'
import { permissionName, serviceTypeHelpText } from '../../utils/constants'
import { removeUnderscoreAndUppercase } from '../../utils/helpers'
import isPermit from '../../utils/permission'
import SessionContext from '../../context/session'
import useStyles from './styles'

const TableRatesRow = ({
	row,
	selectedRateId,
	setSelectedRateId,
	elementId,
	isLowestPrice,
	typeAmount,
	typeAmountValue,
	onNextStepThree,
	quoteCreatedId,
	maxReplacementValue,
	openConfirmInsurance,
	setOpenConfirmInsurance,
	enableInsuranceOptIn,
	defaultDeclaredValue,
	declaredFreightValue,
	setDeclaredFreightValue,
	withInsurance,
	setWithInsurance,
	setInsuranceCost,
	setInsuranceValue,
}) => {
	const dispatch = useDispatch()
	const [isSelected, setIsSelected] = useState(false)
	const theme = useTheme()
	const classes = useStyles()
	const [openSendEmail, setOpenEmail] = useState(false)
	const [disabled, setDisabled] = useState(false)
	const [loadingDownload, setLoadingDownload] = useState(false)

	const { getUserData } = useContext(SessionContext)
	const { permissions } = getUserData()
	const isRatePermission = isPermit(permissions, permissionName.FP_ADMIN_CARRIERS_SHOW_RATE)

	useEffect(() => {
		if (selectedRateId === row.rateId) {
			setIsSelected(true)
		} else {
			setIsSelected(false)
		}
	}, [row, selectedRateId])
	const downloadRatePdf = async () => {
		setDisabled(true)
		setLoadingDownload(true)
		const result = await dispatch(downloadDocumentRate(row?.rateId ?? ''))
		setDisabled(false)
		try {
			const {
				data: { data: blob },
			} = result
			const file = new Blob([blob], { type: 'application/pdf' })
			const fileUrl = URL.createObjectURL(file)
			const anchor = document.createElement('a')
			anchor.href = fileUrl
			anchor.download = `FreightPros Quote #${quoteCreatedId ?? ''}.pdf`
			anchor.click()
			URL.revokeObjectURL(fileUrl)
		} catch (error) {
			SweetAlertModal('error', 'An error has occurred', error, 'Ok', true, false)
		}
		setLoadingDownload(false)
	}
	const format = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		maximumFractionDigits: 0,
	})
	const isNumberValid = (number) => {
		// eslint-disable-next-line no-restricted-globals
		if (!isNaN(number)) {
			return true
		}
		return false
	}

	const formatServiceType = (serviceType) => {
		if (serviceType === 'LTL_STANDARD') {
			return 'LTL Standard'
		}
		return removeUnderscoreAndUppercase(serviceType)
	}

	const renderCustomTooltip = () => (
		<>
			<p>{serviceTypeHelpText.standard}</p> <p>{serviceTypeHelpText.volume}</p> <p>{serviceTypeHelpText.guaranteed}</p>
		</>
	)

	const isGuaranteedService = (serviceType) => serviceType.split('_')[0] === 'GUARANTEED'

	return (
		<>
			<TableRow id={elementId} className={classes.rows}>
				<TableCell component="th" scope="row" style={{ borderRight: '1px ridge #C9CFE4' }}>
					<div className="new-quote-carrier-information-card-main-logo-name">
						<div className="new-quote-carrier-information-card-main-logo-name-container">
							<Radio
								inputProps={{ 'aria-labelledby': elementId }}
								checked={isSelected}
								style={{
									color: isSelected ? theme.palette.secondary.main : theme.palette.primary.light,
								}}
								onClick={() => {
									if (isSelected) {
										setSelectedRateId(0)
									} else {
										setSelectedRateId(row.rateId)
									}
									setIsSelected(!isSelected)
								}}
							/>
							<div className="new-quote-carrier-information-card-main-logo-name-container-left">
								<img src={row.carrierLogo} alt="" />
							</div>
							<div className="new-quote-carrier-information-card-main-logo-name-container-right">
								<span>{row.carrier}</span>
							</div>
						</div>
					</div>
				</TableCell>
				<TableCell style={{ borderRight: '1px ridge #C9CFE4' }}>
					<div className="new-quote-carrier-information-card-main-service">
						<span>{`Estimated ${row.estimateTransitDays} day transit`}</span>
						<div>
							<Tooltip title={renderCustomTooltip()} className={classes.helpCursor}>
								<span>
									<i>{formatServiceType(row.serviceType)}</i>
								</span>
							</Tooltip>
							{isGuaranteedService(row.serviceType) ? (
								<VerifiedUserOutlinedIcon style={{ marginLeft: '5px', color: '#00C48C', height: '17px' }} />
							) : null}
						</div>
					</div>
				</TableCell>
				<TableCell style={{ borderRight: '1px ridge #C9CFE4' }}>
					<div className="new-quote-carrier-information-card-maximum-carrier">
						<span className="price">
							<strong>
								{`${isNumberValid(row?.maxCarrierLiability ?? 'NOT') ? format.format(row.maxCarrierLiability) : ''} New`}
							</strong>
							<br />
							<strong>
								{`${isNumberValid(row?.usedMaxCarrierLiability ?? 'NOT') ? format.format(row.usedMaxCarrierLiability) : ''} Used`}
							</strong>
						</span>
					</div>
				</TableCell>
				{isRatePermission && (
					<TableCell style={{ borderRight: '1px ridge #C9CFE4' }}>
						<div className="new-quote-carrier-information-card-rate">
							<span className="price">
								<strong>{`$ ${row.totalCarrierRate.toFixed(2).replace(/[.,]00$/, '')}`}</strong>
							</span>
						</div>
					</TableCell>
				)}
				<TableCell style={{ borderRight: '1px ridge #C9CFE4' }}>
					<div className="new-quote-carrier-information-card-customer-rate">
						{isLowestPrice && <p>LOWEST PRICE</p>}
						<AddRemoveInsurance
							rateId={row.rateId}
							customerRate={row.totalCustomerRate}
							defaultDeclaredValue={defaultDeclaredValue}
							declaredFreightValue={declaredFreightValue}
							setDeclaredFreightValue={setDeclaredFreightValue}
							withInsurance={withInsurance}
							setWithInsurance={setWithInsurance}
							setInsuranceCost={setInsuranceCost}
							setInsuranceValue={setInsuranceValue}
							typeAmount={typeAmount}
							typeAmountValue={typeAmountValue}
							maxReplacementValue={maxReplacementValue}
							selectedRateId={selectedRateId}
							openConfirmInsurance={openConfirmInsurance}
							enableInsuranceOptIn={enableInsuranceOptIn}
							setOpenConfirmInsurance={setOpenConfirmInsurance}
							onNextStepThree={onNextStepThree}
						/>
					</div>
				</TableCell>
				<TableCell>
					<div className={classes.containerButtonActions}>
						<button
							className={classes.buttonActionItem}
							onClick={(event) => {
								event.stopPropagation()
								onNextStepThree()
							}}
							onMouseDown={() => setSelectedRateId(row.rateId)}
							type="button"
							onFocus={(event) => event.stopPropagation()}
						>
							<CheckCircle className={classes.icon} fontSize="small" />
							<span className={classes.labelActionButton}>Convert to Shipment</span>
						</button>
						<button
							className={classes.buttonActionItem}
							type="button"
							onClick={() => downloadRatePdf()}
							onFocus={(event) => event.stopPropagation()}
							disabled={disabled}
						>
							<GetAppIcon color="secondary" fontSize="small" />
							<span className={classes.labelActionButton}>Download</span>
							{loadingDownload && <CircularProgress size={20} />}
						</button>
						<button
							className={classes.buttonActionItem}
							type="button"
							onClick={() => setOpenEmail(true)}
							onFocus={(event) => event.stopPropagation()}
						>
							<SendIcon color="secondary" fontSize="small" />
							<span className={classes.labelActionButton}>Email Quote</span>
						</button>
					</div>
				</TableCell>
			</TableRow>
			{openSendEmail && (
				<SendByMail open={openSendEmail} handleClose={() => setOpenEmail(false)} idRate={row?.rateId} />
			)}
		</>
	)
}
TableRatesRow.defaultProps = {
	maxReplacementValue: null,
}

TableRatesRow.propTypes = {
	row: PropTypes.objectOf(PropTypes.any).isRequired,
	selectedRateId: PropTypes.number.isRequired,
	setSelectedRateId: PropTypes.func.isRequired,
	elementId: PropTypes.string.isRequired,
	isLowestPrice: PropTypes.bool.isRequired,
	typeAmount: PropTypes.string.isRequired,
	typeAmountValue: PropTypes.string.isRequired,
	onNextStepThree: PropTypes.func.isRequired,
	quoteCreatedId: PropTypes.number.isRequired,
	maxReplacementValue: PropTypes.number,
	openConfirmInsurance: PropTypes.bool.isRequired,
	setOpenConfirmInsurance: PropTypes.func.isRequired,
	enableInsuranceOptIn: PropTypes.bool.isRequired,
	defaultDeclaredValue: PropTypes.number.isRequired,
	declaredFreightValue: PropTypes.number.isRequired,
	setDeclaredFreightValue: PropTypes.func.isRequired,
	withInsurance: PropTypes.bool.isRequired,
	setWithInsurance: PropTypes.func.isRequired,
	setInsuranceCost: PropTypes.func.isRequired,
	setInsuranceValue: PropTypes.func.isRequired,
}

export default TableRatesRow
