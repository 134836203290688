import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import PropTypes from 'prop-types'
import BaseLayout from '../../modules/baseLayout/pages/Layout'
import PrivacyStatement from '../../modules/baseLayout/pages/OktaLoginWidget/ExternalPages/PrivacyStatement/PrivacyStatement'
import Terms from '../../modules/baseLayout/pages/OktaLoginWidget/ExternalPages/Terms/Terms'

const privateRoutes = ({ oktaAuth }) => (
	<BrowserRouter>
		<Switch>
			<Route path="/privacy">
				<PrivacyStatement />
			</Route>
			<Route path="/terms">
				<Terms />
			</Route>
			<Route path="/">
				<BaseLayout oktaAuth={oktaAuth} />
			</Route>
		</Switch>
	</BrowserRouter>
)

privateRoutes.propTypes = {
	oktaAuth: PropTypes.object.isRequired,
}

export default privateRoutes
