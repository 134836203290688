import React from 'react'
import PropTypes from 'prop-types'

import Carousel from 'react-multi-carousel'

import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'

import 'react-multi-carousel/lib/styles.css'
import useStyles from './styles'

const TableElementsSlider = (props) => {
	const { elements, isDeletable } = props
	const classes = useStyles()

	const responsive = {
		tablet: {
			breakpoint: { max: 1024, min: 500 },
			items: 1,
			paritialVisibilityGutter: 50,
		},
		mobile: {
			breakpoint: { max: 500, min: 0 },
			items: 1,
			paritialVisibilityGutter: 30,
		},
	}

	return (
		<div className={classes.shipmentReferencesSlider}>
			<Carousel
				ssr
				arrows={false}
				responsive={responsive}
				showDots
				draggable
				renderDotsOutside
				dotListClass="shipment-items-slider-dots"
			>
				{elements.map((item, index) => (
					<div key={`id-${index + 1}`} className="shipment-references-slider-container">
						<div>
							<p className="shipment-references-slider-title" id="shipment-references-slider-title-id">
								<span>{`#${index + 1}`}</span>
							</p>
							<p className="shipment-references-slider-title">
								<span>Notification:</span>
								{` ${item.notification}`}
							</p>
							<p className="shipment-references-slider-title">
								<span>Email Address:</span>
								{` ${item.emailAddress && item.emailAddress.join(',')}`}
							</p>
							{isDeletable && (
								<div className="shipment-references-slider-delete">
									<DeleteOutlineOutlinedIcon color="primary" className={classes.icon} />
									<p className="shipment-references-slider-delete-title">Delete</p>
								</div>
							)}
						</div>
					</div>
				))}
			</Carousel>
		</div>
	)
}

TableElementsSlider.propTypes = {
	elements: PropTypes.arrayOf(PropTypes.any).isRequired,
	isDeletable: PropTypes.bool.isRequired,
}

export default TableElementsSlider
