/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'
import moment from 'moment'
import { Grid, IconButton } from '@material-ui/core'
import { FilterList, Loop } from '@material-ui/icons'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'

import AutoCompleteInput from '../components/AutocompleteInput/AutocompleteInput'
import RangeDatePicker from '../../../../components/RangeDatePicker'
import SearchBar from '../../../../components/SearchBar'
import TableList from '../components/TableList/TableList'

import useShipmentsFilters from '../../../../hooks/useShipmentsFilters'
import {
	setCurrentPage,
	setRowsPerPage,
	setStartDate,
	setEndDate,
	setSearchBar,
	resetFilters,
	setStatus,
} from '../../../../redux/actions/ShipmentsFilters.actions'
import { getShipmentList } from '../../../../redux/actions/Shipment.actions'
import { clearTab, setTabCurrentRoute, setTabTitle } from '../../../../redux/actions/MultiTabs.actions'
import { clearQuoteDetailsForReQuote } from '../../../../redux/actions/QuoteDetails.actions'
import sweetAlertModal from '../../../../components/SweetAlertModal/SweetAlertModal'
import { shipmentsListRoute } from '../../../../utils/constants'
import { capitalizeFirstLetter } from '../../../../utils/helpers'
import useStyles from './styles'
import useQuery from '../../../../hooks/useQuery'
import ModuleHeader from '../../../../components/ModuleHeader'

const formatDate = (time) => (time ? new Date(time.replace('-', '/')) : null)

const getCapitalizedStatus = (stringStatus) => {
	if (!stringStatus.includes(',')) {
		return [
			{
				title: capitalizeFirstLetter(stringStatus),
			},
		]
	}
	const statuses = stringStatus.split(',')
	const capitalizedStatus = statuses.map((status) => ({
		title: capitalizeFirstLetter(status),
	}))
	return capitalizedStatus
}

const getArrayFromValues = (values) => [values]

const MyShipments = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const matches = useMediaQuery('(min-width:768px)')
	const [buildParamsFromState] = useShipmentsFilters()
	const query = useQuery()
	const statusQuery = query.get('status')
	const [startDate, setNewStartDate] = useState(formatDate(null))
	const [endDate, setEndDates] = useState(formatDate(null))
	const [open, setOpen] = useState(false)
	const searchBar = useSelector((state) => state.shipmentsFilters.searchBar)
	const [search, setSearch] = useState(decodeURIComponent(searchBar))
	const [autoCompleteValues, setAutoComplete] = useState([])
	const [pageReset, setPageReset] = useState(false)

	const ShipmentsProvider = () => {
		const callback = useCallback(async (params) => {
			const { status } = await dispatch(getShipmentList(params))
			if (status === 'error')
				sweetAlertModal('error', 'There was an error fetching the shipments', null, 'Ok', true, false)
		}, [])

		return { callback }
	}

	const { callback } = ShipmentsProvider()
	const filterParams = buildParamsFromState()

	useEffect(() => {
		dispatch(clearTab())
		dispatch(setTabCurrentRoute(shipmentsListRoute))
		dispatch(setTabTitle('My Shipments'))
	}, [dispatch, statusQuery])

	useEffect(() => {
		const preservedFilters = localStorage.getItem('preserved-shipment-filters')
		if (preservedFilters) {
			const filterObj = preservedFilters.split('&')
			filterObj.forEach((filter) => {
				const filterKeyValue = filter.split('=')
				switch (filterKeyValue[0]) {
					case 'status':
						dispatch(setStatus(getArrayFromValues(filterKeyValue[1])))
						setAutoComplete(getCapitalizedStatus(filterKeyValue[1]))
						break
					case 'search_bar':
						dispatch(setSearchBar(filterKeyValue[1]))
						setSearch(decodeURIComponent(filterKeyValue[1]))
						break
					case 'start_date':
						dispatch(setStartDate(filterKeyValue[1]))
						setNewStartDate(new Date(filterKeyValue[1]))
						break
					case 'end_date':
						dispatch(setEndDate(filterKeyValue[1]))
						setEndDates(new Date(filterKeyValue[1]))
						break
					default:
						dispatch(setCurrentPage(filterKeyValue[0].split(/[/?]/)[1]))
						dispatch(setRowsPerPage(filterKeyValue[0].split(/[/?]/)[2]))
						break
				}
			})
		}
	}, [dispatch, statusQuery])

	useEffect(() => {
		callback(filterParams)
	}, [callback, filterParams])

	useEffect(() => {
		dispatch(clearQuoteDetailsForReQuote(''))
	}, [dispatch])

	const toggleDrawer = (openDrawer) => (event) => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return
		}
		setOpen(openDrawer)
	}

	const showFilters = () => {
		setOpen(true)
	}

	const handleStartDate = (selectedDate) => {
		const newStartDate = moment(selectedDate).format('YYYY-MM-DD')
		if (moment(newStartDate).isAfter(new Date())) {
			sweetAlertModal('error', 'Please select a valid date range', null, 'Ok', true, false)
			dispatch(setStartDate(moment(new Date()).format('YYYY-MM-DD')))
			setNewStartDate(new Date())
			setPageReset(true)
		} else {
			setNewStartDate(selectedDate)
			dispatch(setStartDate(newStartDate))
			dispatch(setCurrentPage(0))
			setPageReset(true)
		}
	}

	const handleEndDate = async (selectedDate) => {
		const newEndDate = moment(selectedDate).format('YYYY-MM-DD')
		if (moment(startDate).isAfter(selectedDate)) {
			sweetAlertModal('error', 'Please select a valid date range', null, 'Ok', true, false)
			setEndDates(new Date())
			setPageReset(true)
		} else {
			setEndDates(selectedDate)
			dispatch(setEndDate(newEndDate))
			dispatch(setCurrentPage(0))
			setPageReset(true)
		}
	}

	const handleSearchBarChanged = (event) => {
		const { value } = event.target
		setSearch(value)
		if (value.length >= 3) {
			dispatch(setSearchBar(encodeURIComponent(value)))
			setPageReset(true)
		} else if (value.length === 0) {
			dispatch(setSearchBar(''))
			setPageReset(true)
		}
	}

	const handleResetFilters = () => {
		dispatch(resetFilters())
		dispatch(setEndDate(''))
		dispatch(setStartDate(''))
		setEndDates(null)
		setNewStartDate(null)
		setSearch('')
		setAutoComplete([])
		setPageReset(true)
		localStorage.removeItem('preserved-shipment-filters')
	}

	useEffect(
		() => () => {
			handleResetFilters()
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	)

	const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

	return (
		<>
			<ModuleHeader
				title={
					<div className={classes.quotesHistoryTitleButton}>
						<h1>My Shipments</h1>
						<IconButton aria-label="Toggle Filters" onClick={showFilters} className={classes.filterTriggerButton}>
							<FilterList />
						</IconButton>
					</div>
				}
				subtitle="Consult the shipments created in the system."
			/>
			<SwipeableDrawer
				className={classes.drawer}
				anchor="top"
				open={open}
				onClose={toggleDrawer(false)}
				disableBackdropTransition={!iOS}
				disableDiscovery={iOS}
			>
				<div className={classes.filterFormWrapper} role="presentation">
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<AutoCompleteInput
								values={autoCompleteValues}
								setUpdate={(value) => {
									setPageReset(true)
									dispatch(setCurrentPage(0))
									setAutoComplete(value)
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<RangeDatePicker
								label="Filter by shipment initial date"
								valueDate={startDate}
								setValueDate={handleStartDate}
							/>
						</Grid>
						<Grid item xs={12}>
							<RangeDatePicker label="Filter by shipment final date" valueDate={endDate} setValueDate={handleEndDate} />
						</Grid>
						<Grid item xs={12}>
							<Button
								onClick={handleResetFilters}
								color="primary"
								startIcon={<Loop color="secondary" />}
								style={{ textTransform: 'none' }}
								className={classes.filterButtonModal}
							>
								<span className={classes.filterButtonModalText}>Reset Filters</span>
							</Button>
						</Grid>
					</Grid>
				</div>
			</SwipeableDrawer>
			<Grid container spacing={2}>
				{matches ? (
					<>
						<Grid item xs={12} md={4} lg={6}>
							<AutoCompleteInput
								values={autoCompleteValues}
								setUpdate={(value) => {
									setPageReset(true)
									dispatch(setCurrentPage(0))
									setAutoComplete(value)
								}}
							/>
						</Grid>
						<Grid item xs={12} md={4} lg={3}>
							<RangeDatePicker
								label="Filter by shipment initial date"
								valueDate={startDate}
								setValueDate={handleStartDate}
							/>
						</Grid>
						<Grid item xs={12} md={4} lg={3}>
							<RangeDatePicker label="Filter by shipment final date" valueDate={endDate} setValueDate={handleEndDate} />
						</Grid>
					</>
				) : null}
				<Grid item xs={12} md={10}>
					<SearchBar
						value={search}
						placeholder="Search for BOL#, PRO#, account, reference..."
						onChangeHandler={handleSearchBarChanged}
					/>
				</Grid>
				{matches ? (
					<Grid item xs={2}>
						<Button
							onClick={handleResetFilters}
							color="primary"
							startIcon={<Loop color="secondary" />}
							className={classes.filterButton}
						>
							<span className={classes.filterButtonText}>Reset Filters</span>
						</Button>
					</Grid>
				) : null}
			</Grid>
			<TableList pageReset={pageReset} />
		</>
	)
}

export default MyShipments
