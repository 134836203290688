import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { Menu, MenuItem, withStyles, ListItemIcon, ListItemText, useTheme } from '@material-ui/core'

import { useHistory } from 'react-router-dom'
import VisibilityIcon from '@material-ui/icons/Visibility'
import AutorenewIcon from '@material-ui/icons/Autorenew'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { DownloadIcon, HelpIcon } from '../../../../../assets/Icons/Icons'

import useShipmentsFilters from '../../../../../hooks/useShipmentsFilters'
import sweetAlertModal from '../../../../../components/SweetAlertModal/SweetAlertModal'
import SessionContext from '../../../../../context/session'
import { getShipmentList, removeShipment, importShipmentToMG } from '../../../../../redux/actions/Shipment.actions'
import useStyles from './style'
import { setTabIsNewQuote } from '../../../../../redux/actions/MultiTabs.actions'
import { setDisabledSubject, setOpen, setSubject } from '../../../../../redux/actions/SupportModal.actions'
import { saveQuoteDetailsForReQuote } from '../../../../../redux/actions/QuoteDetails.actions'
import { reqCloneShipment } from '../../../../../redux/requests/Shipment.requests'
import { createQuotesRoute, permissionName } from '../../../../../utils/constants'
import isPermit from '../../../../../utils/permission'

const StyledMenuItem = withStyles(() => ({
	root: {
		'&:hover': {
			backgroundColor: '#F9D2CA',
		},
	},
}))(MenuItem)

const MenuList = (props) => {
	const classes = useStyles()
	const theme = useTheme()
	const dispatch = useDispatch()
	const history = useHistory()

	const { getUserData, getTokenUser } = useContext(SessionContext)
	const { anchorEl, open, handleClose, shipmentId, shipmentStatus, openDetails, bolIdentifier } = props

	const { actualCompany, permissions } = getUserData()
	const [buildParamsFromState] = useShipmentsFilters()
	const token = getTokenUser()
	const filterParams = buildParamsFromState()

	const handleDownloadBOL = () => {
		const url = `${process.env.REACT_APP_BACKEND_QUOTES_BASE_URL}/shipment/bol/${shipmentId}`

		const oReq = new XMLHttpRequest()
		oReq.open('GET', url, true)
		oReq.responseType = 'arraybuffer'
		oReq.setRequestHeader('X-Company-id', Number(actualCompany))
		oReq.setRequestHeader('Authorization', `Bearer ${token}`)

		oReq.onload = function download() {
			const blob = new Blob([oReq.response], { type: 'application/pdf' })
			const URL = window.URL || window.webkitURL
			const dataUrl = URL.createObjectURL(blob)
			const downloadLink = document.createElement('a')
			const fileName = `BOL-${bolIdentifier}.pdf`

			downloadLink.href = dataUrl
			downloadLink.download = fileName
			downloadLink.click()
		}
		oReq.send()
	}

	const handleDownloadLabels = () => {
		const url = `${process.env.REACT_APP_BACKEND_QUOTES_BASE_URL}/shipment/labels/${shipmentId}`

		const oReq = new XMLHttpRequest()
		oReq.open('GET', url, true)
		oReq.responseType = 'arraybuffer'
		oReq.setRequestHeader('X-Company-id', Number(actualCompany))
		oReq.setRequestHeader('Authorization', `Bearer ${token}`)

		oReq.onload = function download() {
			const blob = new Blob([oReq.response], { type: 'application/pdf' })
			const URL = window.URL || window.webkitURL
			const dataUrl = URL.createObjectURL(blob)
			const downloadLink = document.createElement('a')
			const fileName = `LABEL-${bolIdentifier}.pdf`

			downloadLink.href = dataUrl
			downloadLink.download = fileName
			downloadLink.click()
		}
		oReq.send()
	}

	const handleRemoveShipment = async () => {
		sweetAlertModal('warning', 'Are you sure you want to remove this shipment?', null, 'Yes', true, true, 'No').then(
			(result) => {
				if (result.isConfirmed) {
					;(async () => {
						const { status, resp } = await dispatch(removeShipment(shipmentId))
						if (status === 'error') {
							sweetAlertModal('error', 'There was an error removing the shipment', null, 'Ok', true, false)
						} else {
							const bolNumber = resp.data.data.shipment.bol_identifier
							sweetAlertModal(status, `Shipment with BOL: ${bolNumber} was canceled`, null, 'Ok', true, false, null)
							await dispatch(getShipmentList(filterParams))
						}
					})()
				}
			},
		)
	}

	const handleSupport = () => {
		dispatch(setDisabledSubject(true))
		dispatch(setSubject(`${bolIdentifier}`))
		dispatch(setOpen(true))
	}

	const cloneShipmentToNewQuote = async () => {
		let quoteCloned = {}
		const [error, data] = await reqCloneShipment(shipmentId)
		if (!error) {
			const quoteId = data?.data?.data
			quoteCloned = {
				quoteId,
			}
		} else {
			sweetAlertModal('error', 'There was an error cloning the shipment', null, 'Ok', true, false)
		}
		return quoteCloned
	}

	const handleCloneShipment = async () => {
		const dataReady = await cloneShipmentToNewQuote()
		if (Object.keys(dataReady).length > 0) {
			const { status } = dispatch(saveQuoteDetailsForReQuote(`/${dataReady.quoteId}`))
			if (status === 'error') {
				sweetAlertModal(status, 'There was an error fetching the quote details!', null, 'Ok', true, false, null)
			} else {
				dispatch(setTabIsNewQuote(false))
				setTimeout(() => {
					history.push(createQuotesRoute)
				}, 200)
			}
		}
	}

	const onBookShipment = async () => {
		const { status } = await dispatch(importShipmentToMG(shipmentId))
		if (status === 'error') {
			sweetAlertModal('error', 'There was an error booking the shipment', null, 'Ok', true, false)
		} else {
			sweetAlertModal(status, 'Your shipment has been booked successfully!', null, 'Ok', true, false, null)
			await dispatch(getShipmentList(filterParams))
		}
	}

	return (
		<Menu id="long-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
			{isPermit(permissions, permissionName.SHIPMENTS_READ) && (
				<StyledMenuItem onClick={() => openDetails()}>
					<ListItemIcon className={classes.listItemIcon}>
						<VisibilityIcon color="secondary" className={classes.icon} />
					</ListItemIcon>
					<ListItemText primary="View details" />
				</StyledMenuItem>
			)}
			{shipmentStatus === 'ON HOLD' && isPermit(permissions, permissionName.QUOTES_CONVERT_TO_SHIPMENT) && (
				<StyledMenuItem
					onClick={() => {
						handleClose()
						onBookShipment()
					}}
				>
					<ListItemIcon className={classes.listItemIcon}>
						<FileCopyIcon htmlColor={theme.palette.success.main} className={classes.icon} />
					</ListItemIcon>
					<ListItemText primary="Book now" />
				</StyledMenuItem>
			)}
			{isPermit(permissions, permissionName.SHIPMENTS_DOWNLOAD_BOL) && (
				<StyledMenuItem
					onClick={() => {
						handleClose()
						handleDownloadBOL()
					}}
				>
					<ListItemIcon className={classes.listItemIcon}>
						<DownloadIcon color="secondary" className={classes.icon} />
					</ListItemIcon>
					<ListItemText primary="Download BOL" />
				</StyledMenuItem>
			)}
			{isPermit(permissions, permissionName.SHIPMENTS_DOWNLOAD_LABEL) && (
				<StyledMenuItem
					onClick={() => {
						handleClose()
						handleDownloadLabels()
					}}
				>
					<ListItemIcon className={classes.listItemIcon}>
						<DownloadIcon color="secondary" className={classes.icon} />
					</ListItemIcon>
					<ListItemText primary="Download the label" />
				</StyledMenuItem>
			)}
			{isPermit(permissions, permissionName.SHIPMENTS_CONTACT_SUPPORT) && (
				<StyledMenuItem
					onClick={() => {
						handleClose()
						handleSupport()
					}}
				>
					<ListItemIcon className={classes.listItemIcon}>
						<HelpIcon color="error" className={classes.icon} />
					</ListItemIcon>
					<ListItemText primary="Contact support" />
				</StyledMenuItem>
			)}
			{isPermit(permissions, permissionName.SHIPMENTS_CLONE) && (
				<StyledMenuItem
					onClick={() => {
						handleClose()
						handleCloneShipment()
					}}
				>
					<ListItemIcon className={classes.listItemIcon}>
						<AutorenewIcon htmlColor={theme.palette.success.main} className={classes.icon} />
					</ListItemIcon>
					<ListItemText primary="Clone the shipment" />
				</StyledMenuItem>
			)}
			{isPermit(permissions, permissionName.SHIPMENTS_DELETE) && shipmentStatus !== 'CANCELED' && (
				<StyledMenuItem
					onClick={() => {
						handleClose()
						handleRemoveShipment()
					}}
				>
					<ListItemIcon className={classes.listItemIcon}>
						<DeleteOutlineIcon color="error" className={classes.icon} />
					</ListItemIcon>
					<ListItemText primary="Cancel shipment" />
				</StyledMenuItem>
			)}
		</Menu>
	)
}

MenuList.propTypes = {
	anchorEl: PropTypes.objectOf(PropTypes.any),
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	shipmentId: PropTypes.number.isRequired,
	shipmentStatus: PropTypes.string.isRequired,
	bolIdentifier: PropTypes.string.isRequired,
	openDetails: PropTypes.func.isRequired,
}

MenuList.defaultProps = { anchorEl: null }

export default MenuList
