import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { makeStyles, TextField, withStyles } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { setStatus } from '../../../../../redux/actions/ShipmentsFilters.actions'

const options = [
	{ title: 'Pending' },
	{ title: 'Rated' },
	{ title: 'Booked' },
	{ title: 'In Transit' },
	{ title: 'Delivered' },
	{ title: 'Canceled' },
	{ title: 'Picking Up Today' },
	{ title: 'On hold' },
]

const useStyles = makeStyles(() => ({
	autocompleteInput: {
		fontSize: '16px',
		width: '100%',
		minHeight: '56px',
		padding: '0',
		'& label': {
			fontSize: '16px',
		},
	},
}))

const CustomAutocomplete = withStyles({
	tag: {
		backgroundColor: '#EBEEF1',
		border: '1px solid #CCD2D8',
		fontSize: '16px',
		fontWeight: '400',
		height: 24,
		position: 'relative',
		zIndex: 0,
	},
})(Autocomplete)

const AutocompleteInput = ({ values, setUpdate }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const handleRequestStatus = (newValue) => {
		let param = ''
		if (newValue.length > 0) {
			newValue.forEach((d, index) => {
				if (index === 0) {
					param += `${d.title.toUpperCase()}`
				} else {
					param += `,${d.title.toUpperCase()}`
				}
			})
			dispatch(setStatus([param]))
		} else {
			dispatch(setStatus([]))
		}
		setUpdate(newValue)
	}

	return (
		<CustomAutocomplete
			multiple
			id="size-small-outlined-multi"
			options={options}
			value={values}
			size="small"
			onChange={(event, newValue) => handleRequestStatus(newValue)}
			getOptionLabel={(option) => option.title}
			getOptionSelected={(option, value) => option.title === value.title}
			renderInput={(params) => (
				<TextField
					/* eslint-disable react/jsx-props-no-spreading */
					{...params}
					className={classes.autocompleteInput}
					variant="outlined"
					label="Filter by status"
				/>
			)}
		/>
	)
}

AutocompleteInput.propTypes = {
	values: PropTypes.arrayOf(PropTypes.any).isRequired,
	setUpdate: PropTypes.func.isRequired,
}

export default AutocompleteInput
