import React, { useState, useEffect } from 'react'

import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'

import { useParams } from 'react-router-dom'
import moment from 'moment'
import reqGetAuditData from '../../../../../redux/requests/AuditHistory.request'
import SweetAlertModal from '../../../../../components/SweetAlertModal/SweetAlertModal'

import useStyles from './styles'

const AuditHistory = () => {
	const classes = useStyles()
	const { id } = useParams()
	const [auditData, setAuditData] = useState([])

	useEffect(() => {
		const fetchData = async () => {
			const [status, data] = await reqGetAuditData(id)
			if (status) {
				SweetAlertModal('error', 'There was a problem fetching the history!', data, 'Ok', true, false, null)
			} else {
				setAuditData(data)
			}
		}
		fetchData()
	}, [id])

	const getInitials = (name) => {
		const splittedCharacter = name ? name.split(' ') : []
		const returnedInitials =
			splittedCharacter.length > 1 ? `${splittedCharacter[0].charAt(0)}${splittedCharacter[1].charAt(0)}` : 'NA'
		return returnedInitials
	}

	const getDifferenceTime = (timestamp) => {
		const momentDate = moment(parseInt(timestamp, 10)).format('DD MMM YYYY hh:mm a')
		const actualDate = moment()
		const minutes = actualDate.diff(momentDate, 'minutes')
		const hours = actualDate.diff(momentDate, 'hours')
		const days = actualDate.diff(momentDate, 'days')
		const months = actualDate.diff(momentDate, 'months')
		const year = actualDate.diff(momentDate, 'year')
		let differenceTime
		if (minutes < 59) {
			differenceTime = `${minutes} minutes ago`
			return differenceTime
		}
		if (hours < 24) {
			differenceTime = `${hours} hours ago`
			return differenceTime
		}
		if (days < 31) {
			differenceTime = `${days} days ago`
			return differenceTime
		}
		if (months < 12) {
			differenceTime = `${months} months ago`
			return differenceTime
		}
		differenceTime = `${year} years ago`
		return differenceTime
	}

	return (
		auditData.length > 0 &&
		auditData.map((item) => (
			<div className={classes.auditTabContainer}>
				<div className={classes.auditTabDivider}>
					<Avatar className={classes.auditTabAvatar}>{getInitials(item.userName)}</Avatar>
					<Divider orientation="vertical" flexItem />
				</div>
				<div className={classes.auditTabInformation}>
					<span className={classes.auditTabInformationName}>{item.userName || 'Not Available'}</span>
					<span className={classes.auditTabInformationHour}>{getDifferenceTime(item.timestamp)}</span>
					<div className={classes.auditTabActions}>
						<span className={classes.auditTabActionsElement}>{item.message}</span>
					</div>
				</div>
			</div>
		))
	)
}

export default AuditHistory
