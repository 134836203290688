import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Chip from '@material-ui/core/Chip'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { capitalizeFirstLetter, removeUnderScore } from '../../../../utils/helpers'
import useStyles from './styles'

import './AccordionElements.scss'

const AccordionElements = ({ shipmentItems, references, totalWeight }) => {
	const classes = useStyles()
	const [accordionExpanded, setAccordionExpanded] = useState({ items: false, references: false })
	const handleAccordionChange = (panel) => (event, isExpanded) => {
		setAccordionExpanded({ ...accordionExpanded, [panel]: isExpanded })
	}
	const totalItems = shipmentItems.flatMap((item) => +item.quantity).reduce((total, quantity) => total + quantity)
	const renderTypeReferences = (element) => {
		let result = element?.referenceType ?? element?.type ?? 'N/A'
		if (result !== 'N/A') {
			result = removeUnderScore(result)
		}
		return result
	}
	const renderPackageTypeS = (element) => {
		const quantity = element?.quantity ?? 0
		if (quantity > 1) {
			if (capitalizeFirstLetter(element.packageType) === 'Box') return 'es'
			return 's'
		}
		return ''
	}
	const displayFreightClass = (item) => {
		const cubicInchesToCubicFeet = 0.0005787
		const volume = item.length * item.width * item.height
		const volumeInCubicFeet = volume * item.quantity * cubicInchesToCubicFeet

		// If volume or weight are 0, null, or undefined return 0
		if (!volumeInCubicFeet || !item.weight) return 0
		const density = item.weight / volumeInCubicFeet
		if (density >= 30) return 60
		if (density >= 22.5) return 65
		if (density >= 15) return 70
		if (density >= 12) return 85
		if (density >= 10) return 92.5
		if (density >= 8) return 100
		if (density >= 6) return 125
		if (density >= 4) return 175
		if (density >= 2) return 250
		if (density >= 1) return 300
		if (density > 0) return 400
		return 0
	}
	return (
		<>
			<div className="new-quote-info-accordion">
				<Accordion onChange={handleAccordionChange('items')} className={classes.root}>
					<AccordionSummary expandIcon={<ExpandMoreIcon color="secondary" />}>
						<div className="new-quote-info-accordion-summary" style={{ display: 'grid', gridAutoFlow: 'column' }}>
							<div className={classes.items}>
								<div className="new-quote-info-accordion-summary-item" style={{ width: '100%' }}>
									<span className="new-quote-info-accordion-item-title">Total # of Items:&#160;</span>
									{totalItems}
								</div>
								<div>
									<span className="new-quote-info-accordion-item-title">Total Weight:&#160;</span>
									{`${totalWeight} lb`}
								</div>
							</div>
							<div className={classes.labelAccordion} style={{ alignSelf: 'center' }}>
								{accordionExpanded.items ? 'Hide Items' : 'Show Items'}
							</div>
						</div>
					</AccordionSummary>
					<AccordionDetails className={classes.accordionDetails} style={{ padding: '16px 0' }}>
						<div className="new-quote-info-accordion-details">
							{shipmentItems.length > 0 &&
								shipmentItems.map((element, index) => (
									<div key={`id-${index + 1}`} className="new-quote-info-accordion-details-item">
										{`${element.quantity}
                     ${capitalizeFirstLetter(element.packageType)}${renderPackageTypeS(element)},
                     ${element.weight}lbs,
                     ${element.length}'' x ${element.width}'' x ${element.height}'',
                     Freight Class ${element.freightClass === 'Not sure' ? displayFreightClass(element).toString() : element.freightClass}`}
									</div>
								))}
						</div>
					</AccordionDetails>
				</Accordion>
			</div>
			<div className="new-quote-info-accordion">
				<Accordion onChange={handleAccordionChange('references')} className={classes.root}>
					<AccordionSummary expandIcon={<ExpandMoreIcon color="secondary" />}>
						<div className={classes.labelAccordion} style={{ marginLeft: 'auto' }}>
							{accordionExpanded.references ? 'Hide References' : 'Show References'}
						</div>
					</AccordionSummary>
					<AccordionDetails className={classes.accordionDetails}>
						<div className="new-quote-info-accordion-references">
							{references.length > 0 &&
								references.map((element, index) => (
									<Chip
										key={`id-${index + 1}`}
										color="primary"
										variant="outlined"
										label={`${renderTypeReferences(element)} - ${element?.value ?? 'N/A'}`}
										className="new-quote-info-accordion-references-item"
									/>
								))}
						</div>
					</AccordionDetails>
				</Accordion>
			</div>
		</>
	)
}

AccordionElements.propTypes = {
	shipmentItems: PropTypes.arrayOf(PropTypes.any).isRequired,
	references: PropTypes.arrayOf(PropTypes.any).isRequired,
	totalWeight: PropTypes.number.isRequired,
}

export default AccordionElements
