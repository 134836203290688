import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	IconButton,
	Tooltip,
} from '@material-ui/core'

import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'

import TableElementsSlider from '../TableElementsSlider'

import useStyles from './styles'

const TableElements = (props) => {
	const classes = useStyles()

	const { previousElements, handleDeleteNotification, isDeletable } = props
	const [elements, setElements] = useState([])

	useEffect(() => {
		if (previousElements !== undefined) {
			setElements(previousElements)
		}
	}, [previousElements])

	const handleDeleteClick = (index) => {
		handleDeleteNotification(index)
	}

	return (
		<div>
			{elements.length ? (
				<>
					<div className={classes.desktopTable}>
						<TableContainer className={classes.tableContainer}>
							<Table aria-label="customized table" padding="none">
								<TableHead>
									<TableRow className={classes.tableRow}>
										<TableCell align="left" className={classes.tableCellHeadIndex}>
											#
										</TableCell>
										<TableCell align="left" className={classes.tableCellHead}>
											Notification
										</TableCell>
										<TableCell align="left" className={classes.tableCellHead}>
											Email Address
										</TableCell>
										{isDeletable && (
											<TableCell align="left" className={classes.tableCellHead}>
												Actions
											</TableCell>
										)}
									</TableRow>
								</TableHead>
								<TableBody>
									{elements.map((item, index) => (
										<TableRow key={`id-${index + 1}`} className={classes.tableRow}>
											<TableCell align="left" className={classes.tableCellIndex}>
												{index + 1}
											</TableCell>
											<TableCell align="left" className={classes.tableCell}>
												{item.notification}
											</TableCell>
											<TableCell align="left" className={classes.tableCell}>
												{item.emailAddress && item.emailAddress.join(', ')}
											</TableCell>
											{isDeletable && (
												<TableCell align="left" className={classes.tableCell}>
													<Tooltip title="Delete">
														<IconButton aria-label="delete" onClick={() => handleDeleteClick(index)}>
															<DeleteOutlineOutlinedIcon color="error" fontSize="small" />
														</IconButton>
													</Tooltip>
												</TableCell>
											)}
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</div>
					<TableElementsSlider elements={elements} isDeletable={isDeletable} />
				</>
			) : (
				<div className={classes.referencesMessageContainer}>
					<h3 className={classes.referencesMessageTitle}>No Elements added yet</h3>
					<h4 className={classes.referencesMessage}>Add new element here.</h4>
				</div>
			)}
		</div>
	)
}

TableElements.propTypes = {
	previousElements: PropTypes.arrayOf(PropTypes.any).isRequired,
	handleDeleteNotification: PropTypes.func,
	isDeletable: PropTypes.bool.isRequired,
}

TableElements.defaultProps = {
	handleDeleteNotification: () => {},
}

export default TableElements
