import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Menu, Button, Grid } from '@material-ui/core'
import SearchBar from '../../../../components/SearchBar'
import InfoItem from './components/InfoItem/InfoItem'
import { reqGetQuotesSearch } from '../../../../redux/requests/Quotes.requests'
import sweetAlertModal from '../../../../components/SweetAlertModal/SweetAlertModal'
import useStyles from './style'

const MenuSearch = ({ anchorEl, open, handleClose }) => {
	const classes = useStyles()
	const [options, setOptions] = useState([])
	const [search, setSearch] = useState('')
	const onClose = () => handleClose()

	useEffect(() => {
		const fetchData = async () => {
			const [error, data] = await reqGetQuotesSearch(
				`?quotesOffset=0&quotesLimit=3&shipmentsOffset=0&shipmentsLimit=3&search_bar=${search}`,
			)
			if (error) {
				sweetAlertModal(
					'error',
					'There was a problem searching the quote / shipment!',
					error.err?.message ?? '',
					'Ok',
					true,
					false,
					null,
				)
			} else {
				const quoteList = data?.data?.data?.quotes?.list ?? []
				const shipmentList = data?.data?.data?.shipments?.list ?? []
				const shipmentsTransform = shipmentList.map((item) => ({
					id: item?.id ?? null,
					title: 'Shipments',
					origin: `${item?.shipper_city ?? ''}, ${item?.shipper_state ?? ''}`,
					destination: `${item?.consignee_city ?? ''}, ${item?.consignee_state ?? ''}`,
					bol: item?.bol_identifier ?? null,
				}))
				const quotesTransform = quoteList.map((item) => ({
					id: item?.id ?? null,
					title: 'Quotes',
					origin: `${item?.city_origin ?? ''}, ${item?.state_origin ?? ''}`,
					destination: `${item?.city_destination ?? ''}, ${item?.state_destination ?? ''}`,
				}))
				setOptions([...quotesTransform, ...shipmentsTransform])
			}
		}
		if (search.length >= 3) {
			fetchData()
		}
		if (search.length === 0 && options.length > 0) {
			setOptions([])
		}
	}, [search, options.length])

	return (
		<Menu id="search-menu" anchorEl={anchorEl} open={open} onClose={onClose} className={classes.paper}>
			<Grid container spacing={2} alignItems="center" className={classes.containerSearch}>
				<Grid item xs={9} md={10} lg={9}>
					<SearchBar value={search} placeholder="Search..." onChangeHandler={(e) => setSearch(e.target.value)} />
				</Grid>
				<Grid item xs={3} md={2} lg={3}>
					<Button className={classes.cancelButton} onClick={onClose}>
						Cancel
					</Button>
				</Grid>
			</Grid>
			<Grid container className={classes.containerList}>
				{options.length > 0 && (
					<>
						{options.map((item) => (
							<InfoItem key={`${item.title} ${item.id}`} info={item} />
						))}
					</>
				)}
			</Grid>
		</Menu>
	)
}

MenuSearch.defaultProps = { anchorEl: null }

MenuSearch.propTypes = {
	anchorEl: PropTypes.objectOf(PropTypes.any),
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
}

export default MenuSearch
