import React from 'react'
import PropTypes from 'prop-types'
import { Dialog } from '@material-ui/core'
import NewUser from '../../../../../../users/newUser/containers/NewUserContainer'

const NewUserModal = (props) => {
	const { isOpen, setIsOpen, updateUsersTable, accountData } = props
	const handleClose = () => {
		setIsOpen(false)
	}

	return (
		<Dialog
			open={isOpen}
			onClose={handleClose}
			onClick={(event) => event.stopPropagation()}
			aria-labelledby="form-dialog-title"
			PaperProps={{
				style: {
					width: '100%',
					maxWidth: '100%',
					borderBottom: '6px solid #ff3333',
					padding: '20px 24px',
				},
			}}
		>
			<NewUser
				isFromEditAccount
				handleClose={handleClose}
				updateUsersTable={updateUsersTable}
				accountData={accountData}
			/>
		</Dialog>
	)
}

NewUserModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	updateUsersTable: PropTypes.func.isRequired,
	accountData: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default NewUserModal
