import React from 'react'
import PropTypes from 'prop-types'
import {
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
} from '@material-ui/core'
import { Send } from '@material-ui/icons'
import useStyles from './styles'
import { removeUnderScore } from '../../../../../utils/helpers'

const DocumentsTable = ({
	tableTitle,
	documents,
	actionIcon,
	actionName,
	actionHandler,
	secondActionIcon,
	secondActionName,
	secondActionHandler,
	isSendEmail,
	sendEmail,
}) => {
	const classes = useStyles()
	return (
		<>
			<h3 className={classes.documentTableTitle}>{tableTitle}</h3>
			{documents && documents.length ? (
				<div className={classes.desktopTable}>
					<TableContainer className={classes.tableContainer}>
						<Table className={classes.table} aria-label="customized table" padding="none">
							<TableHead>
								<TableRow className={classes.tableRow}>
									<TableCell align="center" className={classes.tableCellHead}>
										#
									</TableCell>
									<TableCell align="center" className={classes.tableCellHead}>
										Document Type
									</TableCell>
									<TableCell align="center" className={classes.tableCellHead}>
										Document Name
									</TableCell>
									<TableCell align="center" className={classes.tableCellHead}>
										Actions
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{documents.map((document, index) => (
									<TableRow key={`id-${index + 1}`} className={classes.tableRow}>
										<TableCell align="center" className={classes.tableCell}>
											{index + 1}
										</TableCell>
										{tableTitle === 'BOL & Pallet Label' ? (
											<TableCell align="center" className={classes.tableCell}>
												{document.name === 'BOL' ? 'BOL' : 'Pallet Label'}
											</TableCell>
										) : (
											<TableCell align="center" className={classes.tableCell}>
												{removeUnderScore(document.type)}
											</TableCell>
										)}
										<TableCell align="center" className={classes.tableCell}>
											{document.name}
										</TableCell>
										<TableCell align="center" className={classes.tableCell} justifyContent="center">
											<Tooltip title={actionName}>
												<IconButton
													aria-label={actionName}
													style={{ padding: '5px' }}
													color="secondary"
													onClick={() => actionHandler(document)}
												>
													{actionIcon}
												</IconButton>
											</Tooltip>
											{isSendEmail && (
												<IconButton
													style={{ padding: '5px' }}
													color="secondary"
													onClick={() => sendEmail(document.name)}
												>
													<Send color="secondary" fontSize="small" />
												</IconButton>
											)}
											{document.type !== 'BOL & Pallet Label' && (
												<Tooltip title={secondActionName}>
													<IconButton
														aria-label={secondActionName}
														style={{ padding: '5px' }}
														color="secondary"
														onClick={() => secondActionHandler(document)}
													>
														{secondActionIcon}
													</IconButton>
												</Tooltip>
											)}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</div>
			) : (
				<div className={classes.documentsMessageContainer}>
					<h3 className={classes.documentsMessageTitle}>No documents added yet</h3>
					<h4 className={classes.documentMessage}>Add new document here.</h4>
				</div>
			)}
		</>
	)
}

DocumentsTable.defaultProps = {
	secondActionName: '',
	secondActionHandler: null,
	secondActionIcon: '',
	isSendEmail: false,
	sendEmail: () => {},
}

DocumentsTable.propTypes = {
	actionIcon: PropTypes.node.isRequired,
	actionName: PropTypes.string.isRequired,
	actionHandler: PropTypes.func.isRequired,
	documents: PropTypes.arrayOf(PropTypes.any).isRequired,
	tableTitle: PropTypes.string.isRequired,
	secondActionName: PropTypes.string,
	secondActionHandler: PropTypes.func,
	secondActionIcon: PropTypes.string,
	isSendEmail: PropTypes.bool,
	sendEmail: PropTypes.func,
}

export default DocumentsTable
