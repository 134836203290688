import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	root: {
		borderRadius: '0px !important',
		boxShadow: 'none',
		borderTop: '2px solid',
		borderTopColor: theme.palette.neutral.darkGray,
	},
	accordionDetails: {
		borderTop: '2px solid',
		borderTopColor: theme.palette.neutral.darkGray,
	},
	items: {
		display: 'grid',
		rowGap: '8px',
		'@media (min-width: 768px)': {
			gridAutoFlow: 'column',
		},
	},
	labelAccordion: {
		color: theme.palette.secondary.main,
		fontSize: '12px',
		textAlign: 'right',
	},
}))

export default useStyles
