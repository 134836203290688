import { useSelector } from 'react-redux'

const useLocationsFilters = () => {
	const { searchBar, searchByLabel, currentPage, rowsPerPage, orderBy, order } = useSelector(
		(state) => state.locationsFilters,
	)
	const buildParamsFromState = () => {
		let queryParams = ''

		if ((currentPage || currentPage === 0) && currentPage !== '') {
			queryParams += `/${currentPage}/`
		}

		if (rowsPerPage && rowsPerPage !== '') {
			queryParams += `${rowsPerPage}?`
		}

		if (searchBar !== '') {
			queryParams += `&search_bar=${searchBar}`
		}

		if (searchByLabel !== '') {
			queryParams += `&search_label=${searchByLabel}`
		}

		if (orderBy && orderBy !== '') {
			queryParams += `&order_label=${orderBy}&order_type=${order}`
		}

		// Save the filters into the localStorage for future loads (When refreshing, changing tabs, etc)
		if (queryParams !== '' && queryParams !== '&') localStorage.setItem('preserved-location-filters', queryParams)

		return queryParams
	}

	return [buildParamsFromState]
}

export default useLocationsFilters
